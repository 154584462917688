import { useState } from 'react';

const useFormDirty = (initialValue = false) => {
  const [isDirty, setIsDirty] = useState(initialValue);

  const onValuesChange = () => {
    setIsDirty(true);
  };

  return { isDirty, setIsDirty, onValuesChange };
};

export default useFormDirty;
