import { mapObjIndexed } from 'ramda';
import { useMemo } from 'react';
import type { ColumnFilterItem } from 'antd/lib/table/interface';
import {
  GET_STORAGE_COMMON_COLUMNS,
  storageTables,
} from '~/constants/rankingTable';
import type { IndexedStorageRanking } from '~/components/Projects/storage/StorageModuleTable';

export type UseGetStorageColumnsWithFilterOptions = {
  redacted?: boolean;
  enableDownload?: boolean;
  storageEngineVersion?: string;
};

export const useGetStorageColumns = (
  data: IndexedStorageRanking[],
  options?: UseGetStorageColumnsWithFilterOptions,
  storageEngineVersion?: string
) => {
  const commonColumns = useMemo(
    () => GET_STORAGE_COMMON_COLUMNS(options),
    [data]
  );
  const storageTablesWithFilter = useMemo(
    () =>
      mapObjIndexed(
        (tableColumns) => [...commonColumns, ...tableColumns],
        storageTables(options, storageEngineVersion)
      ),
    [commonColumns]
  );
  return storageTablesWithFilter;
};
