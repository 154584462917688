import React from 'react';

import styled from '@emotion/styled';
import { TagAnt } from '~/UI';
import { colors } from '~/utils';

const StyledTagAnt = styled(TagAnt)`
  height: 40px;
  gap: 6px;
  border: solid 1px ${colors.primaryLight300};
  padding: 10px 8px 10px 12px;
`;

export type IconPosition = 'left' | 'right';

export interface FilterLabelProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  highlighted?: boolean;
}

const FilterLabel = ({ onClick, highlighted, children }: FilterLabelProps) => {
  return (
    <StyledTagAnt
      onClick={(e) => onClick?.(e)}
      color={highlighted ? 'primary' : 'transparent'}
    >
      {children}
    </StyledTagAnt>
  );
};

export default FilterLabel;
