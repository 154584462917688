import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';

import { AccountManagerInfo, theme } from '~/utils';
import { StyledCard } from '~/UI/Card';
import { anzaLogo2 } from '~/assets';

const InfoContainer = styled.div`
  display: flex;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
`;

const ImageContainer = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 16px;
`;

const commonFontStyles: React.CSSProperties = {
  fontSize: '18px',
  lineHeight: '100%',
  marginBottom: '8px',
  color: theme.colors.charcoal,
};

interface PersonnelInfoBannerProps {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  personnelInfo: Partial<AccountManagerInfo>;
  imagePath?: string;
}

const PersonnelInfoBanner: React.FC<PersonnelInfoBannerProps> = ({
  personnelInfo,
  title,
  description,
  imagePath = anzaLogo2,
}) => {
  return (
    <StyledCard>
      <div style={{ marginBottom: '24px' }}>
        <Typography.Paragraph
          style={{
            color: theme.colors.charcoal,
            fontWeight: 'bold',
            marginBottom: 8,
            fontSize: '18px',
          }}
        >
          {title}
        </Typography.Paragraph>
        {description && (
          <Typography.Paragraph
            style={{
              ...commonFontStyles,
              fontWeight: 400,
              marginBottom: 8,
              fontSize: '16px',
              lineHeight: '22px',
              whiteSpace: 'pre-line',
            }}
          >
            {description}
          </Typography.Paragraph>
        )}
      </div>

      <InfoContainer>
        <div>
          <ImageContainer src={imagePath} alt="Borrego Energy Logo" />
        </div>
        <DetailsContainer>
          {personnelInfo.name && (
            <Typography.Text
              style={{
                ...commonFontStyles,
                fontWeight: 'bold',
              }}
            >
              {personnelInfo.name}
            </Typography.Text>
          )}
          {personnelInfo.description && (
            <Typography.Text
              style={{ ...commonFontStyles, color: theme.colors.graphite }}
            >
              {personnelInfo.description}
            </Typography.Text>
          )}
          {personnelInfo.email && (
            <Typography.Text
              style={{
                ...commonFontStyles,
                color: theme.colors.primary,
                fontWeight: 'bold',
              }}
            >
              <a href={`mailto:${personnelInfo.email}`}>
                {personnelInfo.email}
              </a>
            </Typography.Text>
          )}
          {personnelInfo.phoneNumber && (
            <Typography.Text
              style={{
                ...commonFontStyles,
                color: theme.colors.primary,
                fontWeight: 'bold',
              }}
            >
              {personnelInfo.phoneNumber}
            </Typography.Text>
          )}
        </DetailsContainer>
      </InfoContainer>
    </StyledCard>
  );
};

export default PersonnelInfoBanner;
