import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';

import { ColumnGroupType } from 'antd/lib/table';
import { Overlay, Spinner, ModalConfirm as Modal, Table } from '~/UI';

import SearchFilterDropdown, {
  Filter,
} from '~/components/Tables/SearchFilterDropdown';
import { usePersistTableValues } from '~/hooks/usePersistTableValues';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { TypeOptions, rtkService } from '~/types/rtkApi';
import AdminTableActionColumn, {
  CustomActions,
} from './AdminTableActionColumn';

export type Column<T = any> = Omit<ColumnGroupType<T>, 'children'> & {
  title: string | JSX.Element;
  dataIndex: string;
  key: string;
  isFilterable?: boolean;
  isSortable?: boolean;
  defaultSortOrder?: string;
  filters?: Array<Filter>;
};

export type AdminTableProps<T = TypeOptions> = {
  refresh?: number;
  columns: Column[];
  service: rtkService<T>;
  onRow?: any;
  testIdPage?: string;
  testIdCategory?: string;
  onDataChange?: (data: T[]) => void;
  setColumnsWithData?: (data: T[]) => void;
  customActions?: CustomActions<T>;
};

const StyledTable = styled(Table)`
  .ant-table-row {
    ${(props) => props.onRow && `cursor: pointer;`}
  }
`;

const dataTestIdConfig: TestIdProps = {
  component: TEST_DATA_COMPONENTS.TABLE,
};

const AdminTable = <T,>({
  refresh,
  columns,
  service,
  customActions,
  onRow,
  setColumnsWithData,
  testIdPage = '',
  testIdCategory = '',
  onDataChange,
}: AdminTableProps<T>) => {
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    searchInput: null,
  });
  const [modalVisibleId, setModalVisibleId] = useState<number | string>(0);
  const { handleTableChange, sortKey, sortValue, filterMap, pagination } =
    usePersistTableValues();
  const { data = [], isLoading, refetch: refetchData } = service.getAll();
  const [deleteItem] = service.delete ? service.delete() : [() => {}];

  useEffect(() => {
    if (!modalVisibleId) {
      refetchData();
    }
  }, [refresh, modalVisibleId]);

  dataTestIdConfig.category = testIdCategory;
  dataTestIdConfig.page = testIdPage;

  useEffect(() => {
    if (data.length && setColumnsWithData) {
      setColumnsWithData!(data);
    }
    if (onDataChange) onDataChange(data);
  }, [data]);

  const handleSearch = (selectedKeys: string[], dataIndex: string) => {
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      searchInput: state.searchInput,
    });
  };

  const handleReset = () => {
    setState({ searchText: '', searchedColumn: '', searchInput: null });
  };

  const customColumns = columns.map((column: Column) => {
    let customColumn: any = R.pick(
      [
        'title',
        'dataIndex',
        'key',
        'width',
        'render',
        'fixed',
        'className',
        'filters',
      ],
      column
    );
    const {
      isSortable,
      isFilterable,
      dataIndex,
      defaultSortOrder,
      filters,
      sorter,
      key,
    } = column;
    if (isSortable) {
      if (defaultSortOrder) customColumn.defaultSortOrder = defaultSortOrder;
      if (sortKey === key) {
        customColumn.sortOrder = sortValue;
      }
      customColumn.sorter = sorter || null;
    }
    if (isFilterable) {
      customColumn = {
        ...customColumn,
        ...SearchFilterDropdown(
          { dataIndex, filters },
          {
            state,
            setState,
            handleReset,
            handleSearch,
          },
          customColumn.render ? customColumn.render : null
        ),
      };
    } else {
      customColumn.onFilter = column.onFilter;
    }
    if (filterMap[key]) {
      customColumn.defaultFilteredValue = filterMap[key];
    }
    return customColumn;
  });

  const handleCancelDelete = () => setModalVisibleId(0);

  const handleOk = async () => {
    await deleteItem({ id: modalVisibleId });
    setModalVisibleId(0);
  };

  if (!customActions || !R.isEmpty(customActions)) {
    customColumns.push(
      AdminTableActionColumn<(typeof data)[number]>({
        onDelete: (id) => setModalVisibleId(id),
        testIdCategory: dataTestIdConfig.category,
        testIdPage: dataTestIdConfig.page,
        customActions,
      })
    );
  }

  const renderPaginationItem = (
    page: number,
    type: string,
    originalElement: React.ReactNode
  ) => {
    const dataTestIdPaginationConfig = {
      ...dataTestIdConfig,
      component: TEST_DATA_COMPONENTS.PAGINATION,
      identifier: type,
    };
    dataTestIdPaginationConfig.indices = type === 'page' ? page.toString() : '';

    return (
      <div data-testid={generateTestId(dataTestIdPaginationConfig)}>
        {originalElement}
      </div>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {isLoading && (
        <Overlay style={{ position: 'absolute', zIndex: 9999 }}>
          <Spinner />
        </Overlay>
      )}
      <StyledTable
        rowKey="id"
        data-testid={generateTestId(dataTestIdConfig)}
        columns={customColumns}
        dataSource={data as any[]}
        scroll={{ x: 1000, y: 768 }}
        onChange={handleTableChange as any}
        pagination={{ ...pagination, itemRender: renderPaginationItem }}
        onRow={onRow}
      />
      <Modal
        title="Are you sure you want to delete this item?"
        open={Boolean(modalVisibleId)}
        onOk={handleOk}
        okButtonProps={{ type: 'primary', size: 'small' }}
        cancelButtonProps={{ size: 'small' }}
        onCancel={handleCancelDelete}
        confirmLoading={isLoading}
      >
        <p>
          Once you do it, all the information related to this item might be
          lost.
        </p>
      </Modal>
    </div>
  );
};

AdminTable.defaultProps = {
  refresh: 0,
  setColumnsWithData: () => {},
};

export default AdminTable;
