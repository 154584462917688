import React, { useEffect, useRef, useState, memo } from 'react';
import { Checkbox, notification } from 'antd';
import styled from '@emotion/styled';
import confirm from 'antd/lib/modal/confirm';
import { UploadFile } from 'antd/lib/upload/interface';

import { useHistory } from 'react-router-dom';
import { DatePicker, Form, FormGroup, FormInstance } from '~/UI';
import FileModal, { FileModalProps } from '~/UI/Modal/FileModal';
import FileGroup, { FileGroupProps } from '~/UI/FileGroup';
import { catchError, getPanFileTextRemarks, theme } from '~/utils';
import { fileTypes } from '~/utils/constants';
import { processPanFile } from '~/services/api/anza';
import { FileTypes, ModuleFile, ThirdPartyPanFile } from '~/types/file';
import { UnsavedChangesText } from '~/UI/Typography';

import { CheckInvalid } from '~/utils/getCheckInvalidFormFields';
import { useLazyGetModuleFileQuery } from '~/store/api';

interface FilesFormGroupProps {
  form: FormInstance;
  moduleUuid: string;
  checkIsInvalid: CheckInvalid;
  forcedState?: boolean;
  isFirstSolarSelected?: boolean;
}

type UploadCallback = (file: UploadFile, form: FormInstance) => void;

type ThirdPartyPanFileProp = Omit<FileGroupProps, 'supportingDocuments'>[];

const RedAsterisk = styled.span`
  font-size: 10px;
  color: ${theme.colors.error};
  vertical-align: text-top;
  margin-right: 4px;
`;

const RequiredFileTitle: React.FC<{ name: string }> = ({ name }) => {
  return (
    <>
      <RedAsterisk>*</RedAsterisk>
      {name}
    </>
  );
};

export const DATASHEET_CLASS_ID = 'datasheet-file-group';
export const ANZA_PAN_FILE_CLASS_ID = 'anzapanfile-file-group';

const FilesFormGroup: React.FC<FilesFormGroupProps> = ({
  form,
  moduleUuid,
  checkIsInvalid,
  forcedState,
  isFirstSolarSelected = false,
}) => {
  const [fileModalProps, setFileModalProps] =
    useState<Partial<FileModalProps> | null>(null);
  const { files, pan_file_data } = form.getFieldsValue(true);
  const is_anza_activated = Form.useWatch('is_anza_activated', form);
  const [, forceUpdate] = React.useState({});
  const [isProcessingPanFile, setIsProcessingPanFile] = useState(false);
  const history = useHistory();
  const [downloadFile, { data: filedata }] = useLazyGetModuleFileQuery();

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setHasChanged(false)
  }, [forcedState])

  useEffect(() => {
    if (filedata) {
      window.open(filedata.data.presignedUrl);
    }
  }, [filedata]);

  const [moduleDatasheet, setModuleDatasheet] = useState<
    ModuleFile | undefined
  >(undefined);
  const [anzaPanFile, setAnzaPanFile] = useState<ModuleFile | undefined>(
    undefined
  );
  const [anzaPanFilesZip, setAnzaPanFilesZip] = useState<
    ModuleFile | undefined
  >(undefined);
  const [anzaPanFileSupportingFiles, setAnzaPanFileSupportingFiles] = useState<
    Array<ThirdPartyPanFile>
  >([]);
  const [manufacturerPanFile, setManufacturerPanFile] = useState<
    ModuleFile | undefined
  >(undefined);
  const [manufacturerPanFilesZip, setManufacturerPanFilesZip] = useState<
    ModuleFile | undefined
  >(undefined);
  const [
    manufacturerPanFileSupportingFiles,
    setManufacturerPanFileSupportingFiles,
  ] = useState<Array<ThirdPartyPanFile>>([]);
  const [thirdPartyPanFile, setThirdPartyPanFile] = useState<
    ModuleFile | undefined
  >(undefined);
  const [thirdPartyPanFilesZip, setThirdPartyPanFilesZip] = useState<
    ModuleFile | undefined
  >(undefined);
  const [
    thirdPartyPanFileSupportingFiles,
    setThirdPartyPanFileSupportingFiles,
  ] = useState<Array<ThirdPartyPanFile>>([]);

  // TODO: improve code after big release
  let unblocked = () => {};
  useEffect(() => {
    unblocked();
    unblocked = history.block((router: { pathname: string }) => {
      if (hasChanged && moduleUuid) {
        confirm({
          width: '650px',
          centered: true,
          type: 'warning',
          okText: 'Discard',
          cancelButtonProps: {
            size: 'large',
            style: {
              width: '190px',
            },
          },
          okButtonProps: {
            size: 'large',
            style: {
              width: '190px',
            },
          },
          title: (
            <UnsavedChangesText>
              You have file(s) that has not been saved. Are you sure you want to
              discard your changes?
            </UnsavedChangesText>
          ),
          onOk: () => {
            form.resetFields();
            unblocked();
            history.push(router.pathname);
          },
          onCancel: () => {
            return false;
          },
        });
        return false;
      }
      return true;
    });
    return () => unblocked();
  }, [hasChanged, moduleUuid]);

  const handleFileDownload = async (fileUuid: string) => {
    downloadFile({ moduleUuid, fileUuid });
  };

  const onRemove = async (fileData: ModuleFile) => {
    const {
      uuid,
      file_type: fileType,
      file_location_path: fileLocationPath,
    } = fileData || {};
    const filesArray = files?.filter(
      (el: ModuleFile) =>
        (uuid
          ? el.uuid !== uuid
          : el.file_location_path !== fileLocationPath) ||
        el.file_type !== fileType
    );
    form.setFieldsValue({ files: filesArray });
    checkIsInvalid(['files']);
    forceUpdate({});
    setHasChanged(true);
  };

  useEffect(() => {
    if (!files) return;
    const dataSheet = files.find(
      (file: ModuleFile) => file.file_type === fileTypes.MODULE_DATASHEET.type
    );
    setModuleDatasheet(dataSheet);

    const anzaPanfile = files.find(
      (file: ModuleFile) => file.file_type === fileTypes.ANZA_PAN_FILE.type
    );
    setAnzaPanFile(anzaPanfile);

    const thirdPartyPanfile = files.find(
      (file: ModuleFile) =>
        file.file_type === fileTypes.THIRD_PARTY_PAN_FILE.type
    );
    setThirdPartyPanFile(thirdPartyPanfile);

    const manufacturerPanFile = files.find(
      (file: ModuleFile) =>
        file.file_type === fileTypes.MANUFACTURER_PAN_FILE.type
    );
    setManufacturerPanFile(manufacturerPanFile);

    const anzaPanzip = files.find(
      (file: ModuleFile) =>
        file.file_type === fileTypes.ANZA_PAN_SUPPORTING_FILES_ZIP.type
    );
    setAnzaPanFilesZip(anzaPanzip);

    const manyfacturerPanzip = files.find(
      (file: ModuleFile) =>
        file.file_type === fileTypes.MANUFACTURER_PAN_SUPPORTING_FILES_ZIP.type
    );
    setManufacturerPanFilesZip(manyfacturerPanzip);

    const thirdPartyzip = files.find(
      (file: ModuleFile) =>
        file.file_type === fileTypes.THIRD_PARTY_PAN_SUPPORTING_FILES_ZIP.type
    );
    setThirdPartyPanFilesZip(thirdPartyzip);

    const supportingDocumentsOfType = (fileType: FileTypes) =>
      files
        .filter((file: ModuleFile) => file.file_type === fileType)
        .map((file: ModuleFile) => {
          let title = '';
          switch (fileType) {
            case fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type:
              title = 'Anza Pan File';
              break;
            case fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type:
              title = 'Manufacturer Pan File';
              break;
            case fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type:
              title = 'Third Party Pan File';
              break;
            default:
              title = '';
              break;
          }
          return {
            fileId: file?.uuid,
            uploadDate: file?.updated_at || null,
            name: file?.file_title || null,
            sizeInMB: file?.file_size_kb || null,
            onDownload: () => handleFileDownload(file.uuid),
            onRemove: () => onRemove(file),
            onEdit: () => {
              setFileModalProps({
                fileType,
                searchFileTypes: [
                  fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
                  fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
                  fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
                ],
                title,
                ...file,
              });
            },
            removable: true,
            editable: true,
            isDownloadDisabled: file?.newlyAddedId || file?.searchFileUuid,
          };
        });

    const anzaSupporting = supportingDocumentsOfType(
      'anzaPanFileSupportingFile'
    );
    setAnzaPanFileSupportingFiles(anzaSupporting);

    const manufacturerSupporting = supportingDocumentsOfType(
      'manufacturerPanFileSupportingFile'
    );
    setManufacturerPanFileSupportingFiles(manufacturerSupporting);

    const thirdPartySupporting = supportingDocumentsOfType(
      'thirdPartyPanFileSupportingFile'
    );
    setThirdPartyPanFileSupportingFiles(thirdPartySupporting);
  }, [files]);

  const handleProcessPanFile = async ({
    file_type,
    file_location_path,
  }: ModuleFile) => {
    try {
      setIsProcessingPanFile(true);
      if (
        ['anzaPanFile', 'manufacturerPanFile', 'thirdPartyPanFile'].includes(
          file_type
        )
      ) {
        const ignoreLidLoss = isFirstSolarSelected;
        const response = await processPanFile(
          file_location_path,
          file_type,
          ignoreLidLoss
        );
        const { panFileJson, singleDiodeJson, iamProfileCalculationJson } =
          response.data;

        form.setFieldsValue({
          pan_file_data: [
            ...pan_file_data,
            {
              data: panFileJson,
              single_diode_params: singleDiodeJson,
              iam_interpolation: iamProfileCalculationJson,
              file_location_path,
            },
          ],
        });
      }
    } catch (error: any) {
      catchError({ error });
      notification.error({
        message: 'An error has occurred',
        description: error.response?.data?.message,
      });
      throw error;
    } finally {
      setIsProcessingPanFile(false);
    }
  };

  const handleOnCloseFileModal = () => {
    if (!isProcessingPanFile) {
      setFileModalProps(null);
    }
  };

  const onModalSave = async (fileFormData: ModuleFile) => {
    let filesArray: Partial<ModuleFile>[] = [];
    const {
      uuid,
      uuidExisting,
      searchFileUuid,
      isNewFile,
      newlyAddedId,
      ...other
    } = fileFormData;

    if ((fileFormData?.uuid || newlyAddedId) && fileFormData?.file_type) {
      filesArray =
        files?.map((item: ModuleFile) => {
          if (newlyAddedId && newlyAddedId === item.newlyAddedId) {
            if (searchFileUuid) {
              return { uuid: searchFileUuid, searchFileUuid, ...other };
            }
            if (isNewFile) {
              return { ...other };
            }

            return { newlyAddedId, ...other };
          }

          if (
            uuid &&
            uuid === item?.uuid &&
            fileFormData?.file_type === item?.file_type
          ) {
            if (searchFileUuid) {
              return { uuid: searchFileUuid, searchFileUuid, ...other };
            }
            if (isNewFile) {
              return { ...other };
            }
            return { uuid, ...other };
          }

          return item;
        }) || [];
    } else {
      let newFileFormData: Partial<ModuleFile> = {
        ...other,
        newlyAddedId: Math.floor(Math.random() * 99999999999 + 1),
      };
      if (searchFileUuid) {
        newFileFormData = { uuid: searchFileUuid, searchFileUuid, ...other };
      }

      if (files) {
        const isSupportingFile = [
          fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
          fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
          fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
        ].includes(fileFormData.file_type);

        // For supporting files, we can have multiple. For other files, we can only have one.
        filesArray = isSupportingFile
          ? [newFileFormData, ...files]
          : [
              newFileFormData,
              ...files.filter(
                (file: ModuleFile) => file.file_type !== fileFormData.file_type
              ),
            ];
      } else {
        filesArray = [newFileFormData];
      }
    }

    await handleProcessPanFile(fileFormData);
    form.setFieldsValue({ files: filesArray });
    checkIsInvalid(['files']);
    setFileModalProps(null);
    setHasChanged(true);
  };

  const onPanFileUploadCallback = (file: Blob, form: FormInstance) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileText = (e.target?.result as string) || '';
      const notes = getPanFileTextRemarks(fileText);
      form.setFieldValue('notes', notes);
    };
    reader.readAsText(file);
  };

  return (
    <FormGroup key="Files" title="Files" style={{ maxWidth: '100%' }}>
      <div style={{ maxWidth: '80%' }}>
        <FileGroup
          className={DATASHEET_CLASS_ID}
          name={
            is_anza_activated ? (
              <RequiredFileTitle name="Datasheet" />
            ) : (
              'Datasheet'
            )
          }
          isDownloadDisabled={moduleDatasheet === undefined}
          fileId={moduleDatasheet?.uuid as string}
          description={moduleDatasheet?.notes}
          sizeInMB={moduleDatasheet?.file_size_kb}
          uploadDate={
            moduleDatasheet === undefined
              ? ('' as unknown as Date)
              : moduleDatasheet?.updated_at || new Date()
          }
          onAddOrEdit={() => {
            setFileModalProps({
              fileType: fileTypes.MODULE_DATASHEET.type,
              acceptedFileType: fileTypes.MODULE_DATASHEET.extension,
              title: 'Add Datasheet File',
              ...moduleDatasheet,
            });
          }}
          onRemove={() => onRemove(moduleDatasheet as ModuleFile)}
          onDownload={() => handleFileDownload(moduleDatasheet?.uuid as string)}
          onEdit={() => {
            setFileModalProps({
              fileType: fileTypes.MODULE_DATASHEET.type,
              acceptedFileType: fileTypes.MODULE_DATASHEET.extension,
              title: 'Edit Datasheet File',
              ...moduleDatasheet,
            });
          }}
          removable
          editable
          canAdd
          newlyAddedId={moduleDatasheet?.newlyAddedId}
          searchFileUuid={moduleDatasheet?.searchFileUuid}
        />
        <br />
        <FileGroup
          className={ANZA_PAN_FILE_CLASS_ID}
          name={
            is_anza_activated ? (
              <RequiredFileTitle name="Anza PAN File" />
            ) : (
              'Anza PAN File'
            )
          }
          isDownloadDisabled={anzaPanFile === undefined}
          supportingDocuments={
            anzaPanFileSupportingFiles as unknown as ThirdPartyPanFileProp
          }
          fileId={anzaPanFile?.uuid as string}
          description={anzaPanFile?.notes}
          sizeInMB={anzaPanFile?.file_size_kb}
          uploadDate={
            anzaPanFile === undefined
              ? ('' as unknown as Date)
              : anzaPanFile?.updated_at || new Date()
          }
          onAddOrEdit={() => {
            setFileModalProps({
              fileType: fileTypes.ANZA_PAN_FILE.type,
              acceptedFileType: fileTypes.ANZA_PAN_FILE.extension,
              title: 'Add Pan File',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              onUploadCallback:
                onPanFileUploadCallback as unknown as UploadCallback,
              ...anzaPanFile,
            });
          }}
          onAddSupportingDocument={() => {
            setFileModalProps({
              fileType: fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
              title: 'Add Pan File Supporting Document',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
              ],
            });
          }}
          onRemove={() => onRemove(anzaPanFile as ModuleFile)}
          onDownload={() => handleFileDownload(anzaPanFile?.uuid as string)}
          onDownloadAll={() =>
            handleFileDownload(anzaPanFilesZip?.uuid as string)
          }
          removable
          onEdit={() => {
            setFileModalProps({
              fileType: fileTypes.ANZA_PAN_FILE.type,
              acceptedFileType: fileTypes.ANZA_PAN_FILE.extension,
              title: 'Edit Pan File',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              onUploadCallback:
                onPanFileUploadCallback as unknown as UploadCallback,
              ...anzaPanFile,
            });
          }}
          editable
          canAdd
          newlyAddedId={anzaPanFile?.newlyAddedId}
          searchFileUuid={anzaPanFile?.searchFileUuid}
          canAddExpectedDate
          expectedDateFormItem={
            <Form.Item
              label="Expected Availability"
              name="anza_pan_file_expected_availability_date"
            >
              <DatePicker
                onChange={(date) =>
                  form.setFieldsValue({
                    anza_pan_file_expected_availability_date: date,
                  })
                }
                picker="month"
                format="MMM YYYY"
                placeholder="Select date"
              />
            </Form.Item>
          }
          extraField={
            <Form.Item
              name="is_anza_pan_file_download_disabled"
              valuePropName="checked"
            >
              <Checkbox>Disable User Download</Checkbox>
            </Form.Item>
          }
        />
        <br />
        <FileGroup
          name="Third Party PAN File"
          isDownloadDisabled={thirdPartyPanFile === undefined}
          supportingDocuments={
            thirdPartyPanFileSupportingFiles as unknown as ThirdPartyPanFileProp
          }
          fileId={thirdPartyPanFile?.uuid as string}
          description={thirdPartyPanFile?.notes}
          sizeInMB={thirdPartyPanFile?.file_size_kb}
          uploadDate={
            thirdPartyPanFile === undefined
              ? ('' as unknown as Date)
              : thirdPartyPanFile?.updated_at || new Date()
          }
          onAddOrEdit={() => {
            setFileModalProps({
              fileType: fileTypes.THIRD_PARTY_PAN_FILE.type,
              acceptedFileType: fileTypes.THIRD_PARTY_PAN_FILE.extension,
              title: 'Add Third Party Pan File',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              ...thirdPartyPanFile,
            });
          }}
          onAddSupportingDocument={() => {
            setFileModalProps({
              fileType: fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
              title: 'Add Third Party Pan File Supporting Document',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
              ],
            });
          }}
          onRemove={() => onRemove(thirdPartyPanFile as ModuleFile)}
          onDownload={() =>
            handleFileDownload(thirdPartyPanFile?.uuid as string)
          }
          onDownloadAll={() =>
            handleFileDownload(thirdPartyPanFilesZip?.uuid as string)
          }
          onEdit={() => {
            setFileModalProps({
              fileType: fileTypes.THIRD_PARTY_PAN_FILE.type,
              acceptedFileType: fileTypes.THIRD_PARTY_PAN_FILE.extension,
              title: 'Edit Third Party Pan File',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              ...thirdPartyPanFile,
            });
          }}
          removable
          editable
          canAdd
          newlyAddedId={thirdPartyPanFile?.newlyAddedId}
          searchFileUuid={thirdPartyPanFile?.searchFileUuid}
          canAddExpectedDate
          expectedDateFormItem={
            <Form.Item
              label="Expected Availability"
              name="third_party_pan_file_expected_availability_date"
            >
              <DatePicker
                onChange={(date) =>
                  form.setFieldsValue({
                    third_party_pan_file_expected_availability_date: date,
                  })
                }
                picker="month"
                format="MMM YYYY"
                placeholder="Select date"
              />
            </Form.Item>
          }
          extraField={
            <Form.Item
              name="is_third_party_pan_file_download_disabled"
              valuePropName="checked"
            >
              <Checkbox>Disable User Download</Checkbox>
            </Form.Item>
          }
        />
        <br />
        <FileGroup
          name="Manufacturer PAN File"
          isDownloadDisabled={manufacturerPanFile === undefined}
          supportingDocuments={
            manufacturerPanFileSupportingFiles as unknown as ThirdPartyPanFileProp
          }
          fileId={manufacturerPanFile?.uuid as string}
          description={manufacturerPanFile?.notes}
          sizeInMB={manufacturerPanFile?.file_size_kb}
          uploadDate={
            manufacturerPanFile === undefined
              ? ('' as unknown as Date)
              : manufacturerPanFile?.updated_at || new Date()
          }
          onAddOrEdit={() => {
            setFileModalProps({
              fileType: fileTypes.MANUFACTURER_PAN_FILE.type,
              acceptedFileType: fileTypes.MANUFACTURER_PAN_FILE.extension,
              title: 'Add Manufacturer Pan File',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              ...manufacturerPanFile,
            });
          }}
          onAddSupportingDocument={() => {
            setFileModalProps({
              fileType: fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
              title: 'Add Manufacturer Pan File Supporting Document',
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type,
              ],
            });
          }}
          onRemove={() => onRemove(manufacturerPanFile as ModuleFile)}
          onDownload={() =>
            handleFileDownload(manufacturerPanFile?.uuid as string)
          }
          onDownloadAll={() =>
            handleFileDownload(manufacturerPanFilesZip?.uuid as string)
          }
          onEdit={() => {
            setFileModalProps({
              fileType: fileTypes.MANUFACTURER_PAN_FILE.type,
              acceptedFileType: fileTypes.MANUFACTURER_PAN_FILE.extension,
              searchFileTypes: [
                fileTypes.ANZA_PAN_FILE.type,
                fileTypes.MANUFACTURER_PAN_FILE.type,
                fileTypes.THIRD_PARTY_PAN_FILE.type,
              ],
              title: 'Edit Manufacturer Pan File',
              ...manufacturerPanFile,
            });
          }}
          removable
          editable
          canAdd
          newlyAddedId={manufacturerPanFile?.newlyAddedId}
          searchFileUuid={manufacturerPanFile?.searchFileUuid}
          canAddExpectedDate
          expectedDateFormItem={
            <Form.Item
              label="Expected Availability"
              name="manufacturer_pan_file_expected_availability_date"
            >
              <DatePicker
                onChange={(date) =>
                  form.setFieldsValue({
                    manufacturer_pan_file_expected_availability_date: date,
                  })
                }
                picker="month"
                format="MMM YYYY"
                placeholder="Select date"
              />
            </Form.Item>
          }
          extraField={
            <Form.Item
              name="is_manufacturer_pan_file_download_disabled"
              valuePropName="checked"
            >
              <Checkbox>Disable User Download</Checkbox>
            </Form.Item>
          }
        />
      </div>

      {fileModalProps !== null && (
        <FileModal
          onClose={handleOnCloseFileModal}
          onOk={onModalSave}
          okText="Save"
          visible
          {...fileModalProps}
          confirmLoading={isProcessingPanFile}
        />
      )}
    </FormGroup>
  );
};

export default memo(FilesFormGroup);
