import React from 'react';

import { ButtonAnt as Button } from '~/UI';
import StateCard from '~/components/StateCard';
import { generateTestId, TestIdProps } from '~/utils/dataTestProps';

interface StorageNoModulesAvailableStateProps {
  isLoading: boolean;
  handleOnOpenDrawer: () => void;
  dataTestIdConfig: TestIdProps;
}

const StorageNoModulesAvailableState: React.FC<
  StorageNoModulesAvailableStateProps
> = ({ isLoading, handleOnOpenDrawer, dataTestIdConfig }) => (
  <StateCard
    type="empty"
    title="There are no modules available that match your project details."
    hideBorder
  >
    <Button
      type="primary"
      disabled={isLoading}
      onClick={handleOnOpenDrawer}
      style={{ marginRight: '1rem' }}
      data-testid={generateTestId({
        ...dataTestIdConfig,
        identifier: 'edit-project',
      })}
    >
      Edit Project
    </Button>
  </StateCard>
);

export default StorageNoModulesAvailableState;
