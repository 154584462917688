import React from 'react';
import { CompanyType } from '~/utils';

import {
  useGetAllCompaniesQuery,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} from '~/store/api';
import { rtkService } from '~/types/rtkApi';
import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminCompaniesForm from '../components/Admin/Companies/AdminCompaniesForm';
import AdminCompaniesList from '../components/Admin/Companies/AdminCompaniesList';

export const allCompanyTypes = [
  { value: CompanyType.VENDOR, text: 'Vendor' },
  { value: CompanyType.CUSTOMER, text: 'Customer' },
  { value: CompanyType.CORP, text: 'Corporation' },
  { value: CompanyType.RACK_VENDOR, text: 'Rack Vendor' },
  { value: CompanyType.INVERTER_COMPANY, text: 'Inverter Vendor' },
];

const AdminCompanies: React.FC = () => {
  const service = {
    get: useGetCompanyQuery,
    getAll: useGetAllCompaniesQuery,
    delete: useDeleteCompanyMutation,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="Companies"
      listComponent={AdminCompaniesList}
      formComponent={AdminCompaniesForm}
      path={ADMIN_ROUTES.COMPANIES.path}
      service={service}
    />
  );
};

export default AdminCompanies;
