import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../utils';

interface SpinnerProps {
  size?: string;
  thickness?: string;

  trackColor?: string;
  indicatorColor?: string;
}

const Spinner = styled.div<SpinnerProps>`
  border-radius: 50%;
  width: ${(props) => props.size || '3rem'};
  height: ${(props) => props.size || '3rem'};

  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: ${(props) => props.thickness || '0.4rem'} solid
    ${(props) => props.trackColor || theme.colors.gray};
  border-right: ${(props) => props.thickness || '0.4rem'} solid
    ${(props) => props.trackColor || theme.colors.gray};
  border-bottom: ${(props) => props.thickness || '0.4rem'} solid
    ${(props) => props.trackColor || theme.colors.gray};
  border-left: ${(props) => props.thickness || '0.4rem'} solid
    ${(props) => props.indicatorColor || theme.colors.primary};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const MaskContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
`;

type SpinnerMaskProps = {
  className?: string;
};

export const SpinnerMask: React.FC<SpinnerMaskProps> = ({ className }) => (
  <MaskContainer className={className}>
    <Spinner />
  </MaskContainer>
);

export default Spinner;
