import * as R from 'ramda';
import {
  CELL_TECHNOLOGY_OPTIONS,
  type CellTechology,
  DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS,
  MODULE_TAGS,
} from '~/constants/modules';
import {
  ModuleFilters,
  wattageRangeValues,
} from '~/components/Projects/solar/RankingFilter';
import { SolarRankingData } from '~/store/project';
import { ModuleTag } from '~/types/modules';

enum EventSources {
  SIZZLE_SCREEN = 'sizzle_screen',
  ADVANCED_VIEW = 'advanced_view',
}

type FilterType = 'double' | 'boolean' | 'string';

export enum OPERATORS {
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LOWER_THAN_OR_EQUAL_TO = 'lower_than_or_equal_to',
  EQUAL = 'equal',
}

export type Filter = {
  uuid?: string;
  module_filter_set_uuid?: string;
  event_source: string;
  target_model: string;
  type: FilterType;
  target_field: string;
  target_double_value?: number;
  target_string_value?: string;
  target_bool_value?: boolean;
  operator?: OPERATORS;
};

export type FilterSet = {
  uuid: string;
  name?: string;
  project_uuid: string;
  module_filters: Filter[];
  selected_columns: Record<string, string[]>;
};

export type ClientFilters = {
  initialFiltersValues: Record<string, any[]>;
  tableFilters: {
    filters: Record<string, any[]>;
  };
};

const TARGET_MODEL_MAP_TO_EVENT_SOURCE: Record<string, string[]> = {
  [EventSources.SIZZLE_SCREEN]: ['initialFiltersValues'],
  [EventSources.ADVANCED_VIEW]: ['tableFilters', 'filters'],
};

const TYPE_MAP_TO_VALUE: Record<string, string[]> = {
  double: ['target_double_value'],
  string: ['target_string_value'],
  boolean: ['target_bool_value'],
};

const EVENT_SOURCE_MAP_TO_TARGET_MODEL: Record<string, string> = {
  [EventSources.SIZZLE_SCREEN]: 'modules_pricing',
  [EventSources.ADVANCED_VIEW]: 'key_values',
};

const getTagKey = (value: ModuleTag) => {
  if (MODULE_TAGS.TIER_1.value === value) {
    return 'tier1Modules';
  }
  if (CELL_TECHNOLOGY_OPTIONS.map((o) => o.value).includes(value)) {
    return 'cellTechnology';
  }

  if (
    DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS.map((o) => o.value).includes(value)
  ) {
    return 'domesticContentOrAssembly';
  }

  return 'invalid_tag_key';
};

const getStoredFilterPath = (filter: Filter) => {
  const initialPath = TARGET_MODEL_MAP_TO_EVENT_SOURCE[filter.event_source];
  let key;

  if (
    filter.event_source === EventSources.SIZZLE_SCREEN &&
    filter.target_field === 'tags'
  ) {
    key = getTagKey(filter.target_string_value as ModuleTag);
  } else {
    key = filter.target_field;
  }

  return [...initialPath, key];
};

/**
 * Given a list of filters for modules, returns a list of filters without filters to hide modules
 * @param module_filters list of filters for modules
 * @returns
 */
export const rejectHiddenModuleFilters = (module_filters: Filter[]) => {
  return R.reject<Filter, Filter[]>(
    (f) =>
      f.event_source === EventSources.SIZZLE_SCREEN &&
      f.target_model === 'modules_pricing' &&
      f.target_field === 'uuid',
    module_filters
  );
};

export const transformFilterSetsToClientFilters = (
  module_filters_set: FilterSet
): ClientFilters => {
  const filters = rejectHiddenModuleFilters(module_filters_set.module_filters);
  return R.reduce(
    (acc, filter: Filter) => {
      const storedFilterPath = getStoredFilterPath(filter);
      const filterValuePath = TYPE_MAP_TO_VALUE[filter.type];

      const currentFilterValue = R.pathOr([], storedFilterPath, acc);
      const newFilterValue = R.path(filterValuePath, filter);
      const filterValue = [...currentFilterValue, newFilterValue];
      return R.assocPath(storedFilterPath, filterValue, acc);
    },
    {
      initialFiltersValues: {},
    } as ClientFilters,
    filters
  );
};

const transformFilter = (
  target_field: string,
  values: any,
  event_source: string,
  target_model: string
) => {
  const vals = R.is(Object, values) ? R.values(values) : values;
  if (!Array.isArray(vals)) return [];
  return vals.map((value: string) => {
    const filter: Filter = {
      event_source,
      target_model,
      type: 'string',
      target_field,
      target_string_value: value,
      operator: OPERATORS.EQUAL,
    };
    return filter;
  });
};

const transformClientFilter = (
  client_filter_property: string,
  clientFilters: Record<string, any[]> | { filters: Record<string, any[]> }
) => {
  const filters =
    client_filter_property === 'tableFilters'
      ? clientFilters?.filters
      : clientFilters;
  const event_source = Object.keys(TARGET_MODEL_MAP_TO_EVENT_SOURCE).find(
    (key) =>
      TARGET_MODEL_MAP_TO_EVENT_SOURCE[key].includes(client_filter_property)
  );
  const target_model = EVENT_SOURCE_MAP_TO_TARGET_MODEL[event_source as string];

  return Object.entries(filters || []).flatMap(([target_field, values]) =>
    transformFilter(target_field, values, event_source as string, target_model)
  );
};

export const transformClientFiltersToFilterSets = (
  clientFilters: ClientFilters
) => {
  return Object.entries(clientFilters).flatMap(
    ([client_filter_property, clientFilters]) =>
      transformClientFilter(client_filter_property, clientFilters)
  );
};

export const isModuleHiddenByUuid = (
  module: SolarRankingData,
  filters: Filter[]
) => {
  return Boolean(
    filters.find(
      (f) =>
        f.event_source === EventSources.SIZZLE_SCREEN &&
        f.target_field === 'uuid' &&
        f.target_string_value === module.moduleId
    )
  );
};

/**
 * Given a list of modules and a list of filter for those modules,
 * returns the same list of modules but adding the property isModuleHidden as "true" if the mdules is hidden by uuid in the list of filters
 * @param modules list of modules
 * @param filters list of filters
 * @returns
 */
export const applyIsModuleHiddenToModules = (
  modules: SolarRankingData[],
  filters: Filter[]
): SolarRankingData[] => {
  return modules.map((m) => {
    const foundFilter = filters.find(
      (f) =>
        f.event_source === EventSources.SIZZLE_SCREEN &&
        f.target_field === 'uuid' &&
        f.target_string_value === m.moduleId
    );
    return {
      ...m,
      isModuleHidden: Boolean(foundFilter),
    };
  });
};

/**
 * Given a list of modules, returns a list of modules that are not hidden
 * @param modules list of modules
 * @returns
 */
export const filterHiddenModules = (modules: SolarRankingData[]) =>
  modules.filter((m) => !m.isModuleHidden);

/**
 * Given a list of filters for modules, returns a list of filters used for hiding modules
 * @param module_filters list of filters for modules
 * @returns
 */
export const getHiddenModuleFilters = (module_filters: Filter[]) => {
  const filtered = module_filters.filter(
    (f) =>
      f.event_source === EventSources.SIZZLE_SCREEN &&
      f.target_model === 'modules_pricing' &&
      f.target_field === 'uuid'
  );

  // We need to only pick these data fromthe filters otherwise when we send uuid data we will get errors in the api
  return filtered.map(
    R.pick([
      'type',
      'target_string_value',
      'target_model',
      'target_field',
      'operator',
      'event_source',
    ])
  );
};

/**
 * Given a module uuid and a filter set uuid,
 * returns a filter for hiding the module
 * @param uuid uuid of the module
 * @param filterSetUuid uuid of the filter set
 * @returns
 */
export const generateHiddenModuleFilter = (moduleUuid: string): Filter => ({
  event_source: EventSources.SIZZLE_SCREEN,
  target_field: 'uuid',
  target_model: 'modules_pricing',
  type: 'string',
  target_string_value: moduleUuid,
  operator: OPERATORS.EQUAL,
});

export const filterDataByAnalytics = (
  module: any,
  filters: Record<string, any[]>,
  options?: {
    pathToAnalyticsData?: string[];
  }
) => {
  // iterate over the filters and check if the module fits
  return Object.keys(filters || {}).every((key) => {
    const path = options?.pathToAnalyticsData
      ? [...options?.pathToAnalyticsData, key]
      : [key];
    const valueFromModule: string | number | undefined = R.path(path, module);
    const currentValues = filters[key];
    if (!currentValues) return true; // this means that the filter is not active
    if (R.isEmpty(currentValues)) return true;
    if (!valueFromModule && currentValues.includes('')) return true; // this means that user selected '-' value
    if (valueFromModule && currentValues) {
      return currentValues.includes(valueFromModule.toString());
    }
    return false;
  });
};

const filterByTags = (moduleTags: ModuleTag[], array?: string[]) => {
  if (R.is(Array, array) && !R.isEmpty(array)) {
    const foundTags = R.reduce(
      (acc, filterTag) => acc || moduleTags.includes(filterTag as ModuleTag),
      false as boolean,
      array
    );
    if (!foundTags) return false;
  }
  return true;
};

const filterByCellTechnology = (
  cell_technology?: CellTechology,
  array?: Array<string | null | undefined>
) => {
  if (R.is(Array, array) && !R.isEmpty(array)) {
    return array?.includes(cell_technology);
  }
  return true;
};

export const filterModule = (
  { manufacturer, wattClassKw, cell_technology, tags }: SolarRankingData,
  filters: ModuleFilters
) => {
  if (
    R.is(Array, filters.manufacturer) &&
    !R.isEmpty(filters.manufacturer) &&
    !filters.manufacturer?.includes(manufacturer!)
  ) {
    return false;
  }

  if (R.is(Array, filters.wattClassKw) && !R.isEmpty(filters.wattClassKw)) {
    const hasSome = filters.wattClassKw.some((wc) => {
      return (
        wattClassKw >= wattageRangeValues[wc].min &&
        wattClassKw < wattageRangeValues[wc].max
      );
    });
    if (!hasSome) return false;
  }

  const moduleTags = tags?.map((t) => t.tag) || [];

  if (!filterByCellTechnology(cell_technology, filters.cellTechnology)) {
    return false;
  }
  if (!filterByTags(moduleTags, filters.domesticContentOrAssembly)) {
    return false;
  }
  if (!filterByTags(moduleTags, filters.tier1Modules)) {
    return false;
  }

  return true;
};
