import {
  IoChevronDownSharp,
  IoChevronUpSharp,
  IoChevronForwardSharp,
  IoChevronBackSharp,
} from 'react-icons/io5';

export const IconChevronLeft = IoChevronBackSharp;
export const IconChevronRight = IoChevronForwardSharp;
export const IconChevronDown = IoChevronDownSharp;
export const IconChevronUp = IoChevronUpSharp;
