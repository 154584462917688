import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { DCBlock, DCBlocks } from '~/store/project';

export const adminDCBlocksApi = createApi({
  reducerPath: 'adminDCBlocksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AllDCBlocks', 'AdminGetDCBlock', 'DCBlock'],
  endpoints: (builder) => ({
    getDcBlockByUuid: builder.query<DCBlock, { uuid: string }>({
      query: ({ uuid }) => ({
        url: routes.dcblock.getDcBlockByUuid(uuid),
        method: 'GET',
      }),
      providesTags: (dcblock) => [{ type: 'DCBlock', uuid: dcblock?.uuid }],
    }),
    getAllDCBlocks: builder.query<DCBlocks, void>({
      query: () => routes.admin.dcBlocks(),
      keepUnusedDataFor: 3,
      providesTags: () => [{ type: 'AllDCBlocks', id: 'ALL_DC_BLOCKS' }],
    }),
    updateDcBlockByUuid: builder.mutation<DCBlock, DCBlock>({
      query: (updatedDcBlock) => ({
        url: routes.admin.dcBlockQuote(),
        body: updatedDcBlock,
        method: 'POST',
      }),
      invalidatesTags: (dcblock) => [{ type: 'DCBlock', uuid: dcblock?.uuid }],
    }),
  }),
});

export const {
  useGetAllDCBlocksQuery,
  useGetDcBlockByUuidQuery,
  useUpdateDcBlockByUuidMutation,
} = adminDCBlocksApi;
