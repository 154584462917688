/**
 * Scrolls to a target element after it is loaded.
 */
export const scrollToElementOnLoad = (elementId: string) => {
  const observer = new MutationObserver((_, observer) => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      targetElement.scrollIntoView();
      observer.disconnect();
    }
  });
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

/**
 * Scrolls to the element whose id is provided in the hash section of the url
 * but only after said element is loaded.
 */
export const scrollToHashElementOnLoad = () => {
  const elementId = window.location.hash.substring(1);
  scrollToElementOnLoad(elementId);
};
