import React, { useEffect, useState } from 'react';
import { ButtonAnt as Button, Typography } from '~/UI';
import { ButtonProps } from '~/UI/ButtonAnt';
import { StyledAlert } from '~/UI/Alert';
import { colors } from '~/utils';
import { FOOTER_HEIGHT } from '~/UI/Footer/styles';
import { ContentBox, FormFooterBox } from './styles';

type FormFooterProps = {
  saveButtonProps?: Omit<ButtonProps, 'htmlType'>;
  deleteButtonProps?: Omit<ButtonProps, 'danger'>;
  hasDeleteButton?: boolean;
  isDirty?: boolean;
  formError?: string;
};

const FormFooter = ({
  isDirty,
  saveButtonProps,
  deleteButtonProps,
  hasDeleteButton,
  formError,
}: FormFooterProps) => {
  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const documentSize = window.document.body.scrollHeight;
      const documentTop = window.document.body.getBoundingClientRect().top;
      const bottomPosition = window.innerHeight - documentTop;

      const isFormFooterOverLayoutFooter = bottomPosition >= documentSize;

      if (isFormFooterOverLayoutFooter) {
        setBottom(FOOTER_HEIGHT);
      } else {
        setBottom(0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    const resizeObserver = new ResizeObserver(() => {
      handleScroll();
    });
    resizeObserver.observe(window.document.body);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.unobserve(window.document.body);
    };
  }, []);

  return (
    <FormFooterBox bottom={bottom}>
      {formError && (
        <StyledAlert
          showIcon
          type="error"
          message={formError}
          style={{ width: '70%', height: '60%', margin: '12px 40px' }}
        />
      )}
      <ContentBox style={{ width: formError ? '30%' : '100%' }}>
        {isDirty && (
          <Typography.ModalText color={colors.gray800}>
            You have unsaved changes
          </Typography.ModalText>
        )}
        {hasDeleteButton && (
          <Button {...deleteButtonProps} danger>
            Delete
          </Button>
        )}
        <Button color={colors.primary} htmlType="submit" {...saveButtonProps}>
          Save
        </Button>
      </ContentBox>
    </FormFooterBox>
  );
};

export default FormFooter;
