import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { theme } from '~/utils';
import { ButtonAnt, InputAnt, Checkbox } from '~/UI';
import { IconSearch } from '~/UI/Icons';

const FilterContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const GhostButton = styled(ButtonAnt)`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  border: none;
  width: 100%;
  height: 36px;
`;

const GhostLabel = styled.span`
  font-size: 0.85rem;
  color: ${theme.colors.charcoal};
  font-weight: 500;
  text-align: left;
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border: 1.2px solid ${theme.colors.stone};
    border-radius: 2px;
  }
`;

export interface Filter {
  text: string;
  value: string;
  isStrict?: boolean | undefined;
}

interface TableDropdownOptions {
  handleSearch: (selectedKeys: string[], dataIndex: string) => void;
  handleReset: (clearFilters: boolean) => void;
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
}

interface FilterDropdownOptions {
  setSelectedKeys: (value: string[]) => void;
  selectedKeys: string[];
  selectedFilters: Array<Filter>;
  confirm: (params: { closeDropdown: boolean }) => void;
  clearFilters: boolean;
}

const SearchFilterDropdown = (
  {
    dataIndex,
    dataPath,
    filters,
    filterOnMemory = true,
    searchInputFormatter,
  }: {
    dataIndex: string;
    dataPath?: Array<string>;
    filters?: Array<Filter>;
    filterOnMemory?: boolean;
    searchInputFormatter?: (value: string) => string;
  },
  { handleSearch = () => {} }: Partial<TableDropdownOptions>,
  renderText?: (value: string, { ...args }) => React.FC
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
  }: FilterDropdownOptions) => (
    <FilterContainer>
      <InputAnt
        value={selectedKeys[0]}
        id={`${dataIndex}-search`}
        onChange={(e) => {
          const realValue = e.target.value.split(',').map((v) => {
            return R.isNil(searchInputFormatter) ? v : searchInputFormatter(v);
          });
          if (filterOnMemory) {
            setSelectedKeys(e.target.value ? realValue : []);
          }
          confirm({ closeDropdown: false });
          handleSearch(realValue, dataIndex);
        }}
        style={{ display: 'block', marginBottom: 4 }}
      />
      <div
        style={{
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        {filters &&
          filters.map((f) => (
            <GhostButton
              key={f.text}
              type="ghost"
              onClick={() => {
                if (selectedKeys.indexOf(f.value) === -1) {
                  setSelectedKeys([...selectedKeys, f.value]);
                } else {
                  const newSelectedFilters = selectedKeys.filter(
                    (selected) => selected !== f.value
                  );
                  setSelectedKeys(newSelectedFilters);
                }
                confirm({ closeDropdown: false });
                handleSearch([f.value], dataIndex);
              }}
            >
              <StyledCheckbox checked={selectedKeys.includes(f.value)} />
              <GhostLabel>{` ${f.text}`}</GhostLabel>
            </GhostButton>
          ))}
      </div>
      <ButtonAnt
        onClick={() => {
          setSelectedKeys([]);
          confirm({ closeDropdown: false });
          handleSearch([''], dataIndex);
        }}
        size="small"
        style={{ width: 90 }}
      >
        Clear Filter
      </ButtonAnt>
    </FilterContainer>
  ),
  filterIcon: () => <IconSearch />,
  onFilter: (value: string | number | boolean, record: any) => {
    // Filtering supports the basic dataIndex property which is a standard attribute on antd table columns but also
    // allows us to specify more complicated paths with Ramda. (e.g. ['project', 'name'])
    const pathToValue =
      R.isNil(dataPath) || R.isEmpty(dataPath) ? [dataIndex] : dataPath;

    if (filters && filters.length && typeof renderText === 'function') {
      const matchingFilter = filters.find((f: Filter) => f.value === value);
      if (matchingFilter?.isStrict) {
        return (
          R.pathOr('', pathToValue, record).toLowerCase() ===
          (value as string).toLowerCase()
        );
      }
      return R.pathOr('', pathToValue, record)
        .toLowerCase()
        .includes((value as string).toLowerCase());
    }

    return R.pipe(
      R.pathOr('', pathToValue),
      (prop) =>
        R.ifElse(R.isNil, R.always(prop), (fn) => fn(prop, record))(renderText),
      R.toString,
      R.toLower,
      R.includes((value as string).toLowerCase())
    )(record);
  },
  onFilterDropdownVisibleChange: (value: boolean) => {
    if (!value) {
      return;
    }
    setTimeout(
      () => document.getElementById(`${dataIndex}-search`)?.focus(),
      100
    );
  },
  render: (text: string, { ...args }) => {
    return renderText ? renderText(text, args) : text;
  },
});

export default SearchFilterDropdown;
