import React, { useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router';

import {
  Form,
  InputAnt as Input,
  Overlay,
  Spinner,
  FormGroup,
  FormItemLabel,
} from '~/UI';
import InputNumber from '~/UI/InputNumber';
import { blockEnterKeyEvents } from '~/utils/events';
import { requiredRule, formErrorScroll } from '~/utils';
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
} from '~/store/api';
import { DecimalSettingsFloor } from '~/utils/formatters';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { rtkService, LocationResponse } from '~/types/rtkApi';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';
import { LocationType } from '~/types/locations';

export interface Formatter {
  <ValueType>(
    value: ValueType | undefined,
    info: { userTyping: boolean; input: string }
  ): string;
}

const AdminLocationModifiersForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
  }
> = ({ service, history }) => {
  const { id }: { id: string } = useParams() || {};
  const [form] = Form.useForm();
  const [updateLocation] = useUpdateLocationMutation();
  const [createLocation] = useCreateLocationMutation();
  const { data = null, isLoading = false } = id
    ? (service.get({ id }) as LocationResponse)
    : {};

  const checkInvalid = async () => {
    try {
      await form.validateFields([
        'city',
        'state',
        'modifier',
        'tax',
        'retail_price',
        'energy_price',
        'port',
        'shipping',
        'fixed_tilt_a',
        'fixed_tilt_b',
        'fixed_tilt_c',
        'fixed_tilt_d',
        'fixed_tilt_e',
        'single_axis_tracker_a',
        'single_axis_tracker_b',
        'single_axis_tracker_c',
        'single_axis_tracker_d',
        'single_axis_tracker_e',
      ]);
    } catch (error) {
      formErrorScroll();
    }
  };

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        city: '',
        state: '',
        modifier: 0,
        tax: 0,
        retail_price: 0,
        energy_price: 0,
        port: '',
        shipping: '',
        fixed_tilt_a: 0,
        fixed_tilt_b: 0,
        fixed_tilt_c: 0,
        fixed_tilt_d: 0,
        fixed_tilt_e: 0,
        single_axis_tracker_a: 0,
        single_axis_tracker_b: 0,
        single_axis_tracker_c: 0,
        single_axis_tracker_d: 0,
        single_axis_tracker_e: 0,
      });
    } else {
      form.setFieldsValue(data);
      checkInvalid();
    }
  }, [isLoading]);

  const goBack = () =>
    history.push(ADMIN_ROUTES.LOCATION_MODIFIERS.path.default);

  const onFinish = async (values: LocationType) => {
    const body = {
      ...values,
      is_deleted: Boolean(values.is_deleted),
    };

    if (id) await updateLocation({ location: { id: +id, ...body } });
    else await createLocation({ location: body });

    goBack();
  };

  const { isDirty, onValuesChange } = useFormDirty();

  if (isLoading) {
    return null;
  }

  return (
    <AdminFormLayout>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        onValuesChange={onValuesChange}
      >
        {isLoading && (
          <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}
        <FormGroup key="Data">
          <Form.Item
            name="city"
            label={<FormItemLabel>City</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <Input required name="city" placeholder="Insert City" />
          </Form.Item>
          <Form.Item
            name="state"
            label={<FormItemLabel>State</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <Input required name="state" placeholder="Insert State" />
          </Form.Item>
          <Form.Item
            name="modifier"
            label={<FormItemLabel>Modifier</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="modifier"
              placeholder="Insert Modifier"
              precision={3}
            />
          </Form.Item>
          <Form.Item
            name="tax"
            label={<FormItemLabel>Tax</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="tax"
              placeholder="Insert Tax"
              precision={3}
            />
          </Form.Item>
          <Form.Item
            name="retail_price"
            label={<FormItemLabel>Retail Price</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="retail_price"
              placeholder="Insert Retail Price"
              precision={3}
            />
          </Form.Item>
          <Form.Item
            name="energy_price"
            label={<FormItemLabel>Energy Price</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="energy_price"
              placeholder="Insert Energy Price"
              precision={3}
            />
          </Form.Item>
          <Form.Item
            name="port"
            label={<FormItemLabel>Port</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <Input required name="port" placeholder="Insert Port" />
          </Form.Item>
          <Form.Item
            name="shipping"
            label={<FormItemLabel>Shipping</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <Input required name="shipping" placeholder="Insert Shipping" />
          </Form.Item>
        </FormGroup>
        <FormGroup key="Fixed Tilts" title="Fixed Tilts">
          <Form.Item
            name="fixed_tilt_a"
            label={<FormItemLabel>Fixed Tilt A</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="fixed_tilt_a"
              placeholder="Insert Fixed Tilt A"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="fixed_tilt_b"
            label={<FormItemLabel>Fixed Tilt B</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="fixed_tilt_b"
              placeholder="Insert Fixed Tilt B"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="fixed_tilt_c"
            label={<FormItemLabel>Fixed Tilt C</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="fixed_tilt_c"
              placeholder="Insert Fixed Tilt C"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="fixed_tilt_d"
            label={<FormItemLabel>Fixed Tilt D</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="fixed_tilt_d"
              placeholder="Insert Fixed Tilt D"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="fixed_tilt_e"
            label={<FormItemLabel>Fixed Tilt E</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="fixed_tilt_e"
              placeholder="Insert Fixed Tilt E"
              precision={7}
            />
          </Form.Item>
        </FormGroup>
        <FormGroup key="Single Axis Trackers" title="Single Axis Trackers">
          <Form.Item
            name="single_axis_tracker_a"
            label={<FormItemLabel>Single Axis Tracker A</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="single_axis_tracker_a"
              placeholder="Insert Single Axis Tracker A"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="single_axis_tracker_b"
            label={<FormItemLabel>Single Axis Tracker B</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="single_axis_tracker_b"
              placeholder="Insert Single Axis Tracker B"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="single_axis_tracker_c"
            label={<FormItemLabel>Single Axis Tracker C</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="single_axis_tracker_c"
              placeholder="Insert Single Axis Tracker C"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="single_axis_tracker_d"
            label={<FormItemLabel>Single Axis Tracker D</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="single_axis_tracker_d"
              placeholder="Insert Single Axis Tracker D"
              precision={7}
            />
          </Form.Item>
          <Form.Item
            name="single_axis_tracker_e"
            label={<FormItemLabel>Single Axis Tracker E</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <InputNumber
              required
              parser={DecimalSettingsFloor.parser}
              formatter={DecimalSettingsFloor.formatter as Formatter}
              name="single_axis_tracker_e"
              placeholder="Insert Single Axis Tracker E"
              precision={7}
            />
          </Form.Item>
        </FormGroup>
        <FormFooter
          isDirty={isDirty}
          saveButtonProps={{
            onClick: checkInvalid,
          }}
        />
      </Form>
    </AdminFormLayout>
  );
};

export default AdminLocationModifiersForm;
