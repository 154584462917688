import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ProjectState, ProjectPayload } from './types';

export const initialState: ProjectState = {
  uuid: '',
  name: '',
  sf_opportunity_id: '',
  isLoading: false,
  isFetched: false,
  owner_id: null,
  filters: {},
};

const storeProject: CaseReducer<ProjectState, PayloadAction<ProjectPayload>> = (
  state,
  action
) => ({
  ...state,
  name: action.payload.name,
  uuid: action.payload.uuid,
  owner_id: action.payload.owner_id,
  owner: action.payload.owner,
  sf_opportunity_id: action.payload.sf_opportunity_id,
  isLoading: action.payload.isLoading,
  isFetched: action.payload.isFetched,
});

const storeFilters: CaseReducer<ProjectState, PayloadAction<ProjectPayload>> = (
  state,
  action
) => ({
  ...state,
  filters: action.payload.filters,
});

const reducers = {
  storeProject,
  storeFilters,
};

export default reducers;
