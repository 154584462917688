import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import {
  EditProjectSpecificPricingPayload,
  ProjectSpecificPricingResponse,
  AddProjectSpecificPricingPayload,
  Pricing_Source,
} from '~/types/projectSpecificPricing';
import { projectsApi } from '../projects/projectsApi';

export const adminProjectSpecificPricingApi = createApi({
  reducerPath: 'adminProjectSpecificPricingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AdminGetProjectSpecificPricing'],
  endpoints: (builder) => ({
    getProjectSpecificPricing: builder.query<
      ProjectSpecificPricingResponse[],
      { id: string }
    >({
      query: ({ id }) => ({
        url: routes.admin.getProjectSpecificPricing(id),
        method: 'GET',
      }),
      providesTags: (_, __, { id }) => [
        { type: 'AdminGetProjectSpecificPricing', id },
      ],
    }),
    deleteProjectSpecificPricing: builder.mutation<
      null,
      { projectUuid: string; uuid: string }
    >({
      query: ({ projectUuid, uuid }) => ({
        url: routes.admin.deleteProjectSpecificPricing(projectUuid, uuid),
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { projectUuid }) => [
        { type: 'AdminGetProjectSpecificPricing', id: projectUuid },
      ],
    }),
    addProjectSpecificPricing: builder.mutation<
      ProjectSpecificPricingResponse,
      {
        projectUuid: string;
        source?: Pricing_Source;
      } & AddProjectSpecificPricingPayload
    >({
      query: ({ projectUuid, ...addProjectSpecificPricingPayload }) => ({
        url: routes.admin.addProjectSpecificPricing(projectUuid),
        method: 'POST',
        body: addProjectSpecificPricingPayload,
      }),
      invalidatesTags: (_, __, { projectUuid }) => [
        { type: 'AdminGetProjectSpecificPricing', id: projectUuid },
      ],
      onQueryStarted: async ({ projectUuid }, { dispatch, queryFulfilled }) => {
        // invalidating tags from the projectsApi will make refetch that data automatically
        await queryFulfilled;
        dispatch(
          projectsApi.util.invalidateTags([
            // @ts-ignore
            { type: 'Project', uuid: projectUuid },
          ])
        );
      },
    }),
    editProjectSpecificPricing: builder.mutation<
      ProjectSpecificPricingResponse,
      {
        projectUuid: string;
        uuid: string;
      } & EditProjectSpecificPricingPayload
    >({
      query: ({ projectUuid, uuid, ...editProjectSpecificPricingPayload }) => ({
        url: routes.admin.editProjectSpecificPricing(projectUuid, uuid),
        method: 'PUT',
        body: editProjectSpecificPricingPayload,
      }),
      invalidatesTags: (_, __, { projectUuid }) => [
        { type: 'AdminGetProjectSpecificPricing', id: projectUuid },
      ],
      onQueryStarted: async ({ projectUuid }, { dispatch, queryFulfilled }) => {
        // invalidating tags from the projectsApi will make refetch that data automatically
        await queryFulfilled;
        dispatch(
          projectsApi.util.invalidateTags([
            // @ts-ignore
            { type: 'Project', uuid: projectUuid },
          ])
        );
      },
    }),
  }),
});

export const {
  useGetProjectSpecificPricingQuery,
  useDeleteProjectSpecificPricingMutation,
  useAddProjectSpecificPricingMutation,
  useEditProjectSpecificPricingMutation,
} = adminProjectSpecificPricingApi;
