import React from 'react';

import { FormInstance } from 'antd';
import { Form, InputNumber } from '~/UI';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import { TAX_CREDIT_METHODOLOGY_OPTIONS } from '~/utils';
import { TestIdProps } from '~/utils/dataTestProps';
import { GenericLabel } from '../../FormGroup';
import {
  StyledFormHeader,
  StyledFormRadioGroup,
  StyledSpace,
} from '../SolarProjectEditForm';
import { StyledRadioButton } from '../../shared/styles';

type TaxCreditsProps = {
  testIdData: { component: string };
  disabledInputs: boolean;
  form: FormInstance;
};

export const TaxCredits = (props: TaxCreditsProps) => {
  const { testIdData, disabledInputs, form } = props;
  const isITCSelected = Form.useWatch('is_itc', form) === 'ITC';
  const taxCredits = form.getFieldValue('tax_credits');

  return (
    <>
      <StyledFormHeader>Tax Credits</StyledFormHeader>
      <Form.Item
        name="is_itc"
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'tax-credit-type',
              } as TestIdProps
            }
            title="Tax Credit Methodology"
            fontSize="14px"
          />
        }
        labelCol={{ span: 24 }}
      >
        <StyledFormRadioGroup
          buttonStyle="outline"
          size="large"
          disabled={disabledInputs}
        >
          {TAX_CREDIT_METHODOLOGY_OPTIONS.map((option) => (
            <StyledRadioButton value={option} key={option}>
              {option}
            </StyledRadioButton>
          ))}
        </StyledFormRadioGroup>
      </Form.Item>
      <Form.List name="tax_credits">
        {(fields) =>
          fields.map((field) => {
            const currentTaxCredit = taxCredits[field.name];
            return (
              <StyledSpace size="xs" key={field.key}>
                {currentTaxCredit.credit_type === 'ITC' && isITCSelected && (
                  <Form.Item
                    name={[field.name, 'base_credit']}
                    label={
                      <GenericLabel
                        title="ITC Base Percentage"
                        fontSize="14px"
                      />
                    }
                    labelCol={{ span: 24 }}
                    rules={SOLAR_INPUT_CONFIG.base_credit.rules}
                  >
                    <InputNumber
                      disabled={disabledInputs}
                      {...SOLAR_INPUT_CONFIG.base_credit.inputNumberProps}
                    />
                  </Form.Item>
                )}
                {!isITCSelected && currentTaxCredit.credit_type !== 'ITC' && (
                  <Form.Item
                    name={[field.name, 'base_credit']}
                    label={
                      <GenericLabel title="PTC Base Credit" fontSize="14px" />
                    }
                    labelCol={{ span: 24 }}
                    rules={SOLAR_INPUT_CONFIG.ptc_base_credit.rules}
                    validateTrigger="onChange"
                  >
                    <InputNumber
                      disabled={disabledInputs}
                      {...SOLAR_INPUT_CONFIG.ptc_base_credit.inputNumberProps}
                    />
                  </Form.Item>
                )}
                {!isITCSelected && currentTaxCredit.credit_type !== 'ITC' && (
                  <Form.Item
                    name={[field.name, 'escalation_rate']}
                    label={
                      <GenericLabel title="Escalation Rate" fontSize="14px" />
                    }
                    labelCol={{ span: 24 }}
                    rules={SOLAR_INPUT_CONFIG.escalation_rate.rules}
                    required={false}
                  >
                    <InputNumber
                      disabled={disabledInputs}
                      {...SOLAR_INPUT_CONFIG.escalation_rate.inputNumberProps}
                    />
                  </Form.Item>
                )}
              </StyledSpace>
            );
          })
        }
      </Form.List>
    </>
  );
};
