import { AxiosResponse } from 'axios';

import { ModelType, ProjectType, catchError } from '~/utils';
import { GetLocationByGooglePlaceIdResponse } from '~/types/addressSearchTypes';
import { routes } from '~/api/routes';
import { api, apiV2, GenericResponseDataObject } from '~/api/axios';

export interface ProjectResponseDataObjectList
  extends GenericResponseDataObject {
  data?: Array<ProjectType>;
}

export interface ProjectResponseDataObject extends GenericResponseDataObject {
  data?: ProjectType;
}

export interface ProjectRequestDataObject {
  name?: string;
  sf_opportunity_id?: string;
}

export interface ProjectPatchRequestDataObject {
  name?: string;
}

export interface ProjectModelResponseDataObject
  extends ProjectResponseDataObject {
  data?: ModelType & {
    download_url: string;
  };
}

export const getProjects = async (): Promise<
  AxiosResponse<ProjectResponseDataObjectList>
> => {
  try {
    return await api.get<ProjectResponseDataObjectList>(
      routes.project.getProjects()
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const getProject = async (
  uuid: string
): Promise<AxiosResponse<ProjectResponseDataObject>> => {
  try {
    return await api.get<ProjectResponseDataObject>(
      routes.project.getProjectById(uuid)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const getProjectModels = async (
  uuid: string
): Promise<AxiosResponse<ProjectModelResponseDataObject>> => {
  try {
    return await api.get<ProjectModelResponseDataObject>(
      routes.project.getProjectModelsByProjectId(uuid)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const createProject = async (
  data: ProjectRequestDataObject
): Promise<AxiosResponse<ProjectResponseDataObject>> => {
  try {
    return await api.post<ProjectResponseDataObject>(
      routes.project.createProject(),
      data
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const updateProject = async (
  uuid: string,
  data: ProjectPatchRequestDataObject
): Promise<AxiosResponse<ProjectResponseDataObject>> => {
  try {
    return await api.patch<ProjectResponseDataObject>(
      routes.project.updateProject(uuid),
      data
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const deleteProject = async (uuid: string): Promise<AxiosResponse> => {
  try {
    return await api.delete(routes.project.deleteProject(uuid));
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const searchLocation = async (query: string): Promise<AxiosResponse> => {
  try {
    return await apiV2.post<ProjectResponseDataObject>(
      routes.search.searchProjectLocation(),
      {
        query,
      }
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const getLocationByGooglePlaceId = async (
  placeId: string
): Promise<AxiosResponse<GetLocationByGooglePlaceIdResponse>> => {
  try {
    return await apiV2.get<GetLocationByGooglePlaceIdResponse>(
      routes.search.getLocationByGooglePlaceId(placeId)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};
