import React from 'react';
import styled from '@emotion/styled';

import { ContentHeading, ContentSubheading } from '~/UI/Typography';
import { theme } from '~/utils';
import { emptyRanking, loadingRanking } from '~/assets';

export type StateCardProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  type: 'empty' | 'loading' | 'inactive';
  hideBorder?: boolean;
};

const StateCardContainer = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 630px;
  gap: 1rem;
  grid-column-start: 1;
  grid-column-end: 2;
  background-color: ${theme.colors.white};
  border: ${({ hideBorder }) =>
    hideBorder ? 'none' : `1px solid ${theme.colors.gray200}`};
  border-radius: 12px;
`;

const StyledWrapperText = styled.div`
  text-align: center;
  width: 100%;
  max-width: 400px;
`;

const StyledContentHeading = styled(ContentHeading)`
  margin-bottom: 0.5rem;
`;

export const StateCard = ({
  title,
  children,
  type,
  subtitle,
  hideBorder,
}: StateCardProps) => {
  let src;
  let alt = '';
  if (type === 'empty' || type === 'inactive') {
    src = emptyRanking;
    alt = 'empty data';
  }
  if (type === 'loading') {
    src = loadingRanking;
    alt = 'loading data';
  }

  return (
    <StateCardContainer hideBorder={hideBorder}>
      <img src={src} alt={alt} />
      <StyledWrapperText>
        <StyledContentHeading>{title}</StyledContentHeading>
        <ContentSubheading>{subtitle}</ContentSubheading>
      </StyledWrapperText>
      {children}
    </StateCardContainer>
  );
};

export default StateCard;
