import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import {
  prepareHeaders,
  API_URL,
  API_VERSION_V2,
  API_VERSION_V3,
} from '~/store/api/settings';
import {
  CreateSolarProjectArgs,
  CreateSolarProjectResultType,
  CreateStorageProjectArgs,
  CreateStorageProjectResultType,
  GetProjectResultTypeTransformed,
  GetSolarProjectResultType,
  SolarProjectTransformedResultType,
  UpdateProjectArgs,
  UpdateProjectStatusArgs,
  UpdateProjectStatusResponse,
  UpdatedSolarProjectTransformResultType,
  UpdatedStorageProjectTransformResultType,
  UpdatedProjectType,
  DeleteProjectArgs,
} from '~/store/project';
import { parseEngineDataToTableData } from '~/utils/engineOutput';
import type {
  BaseProject,
  GetAdminProjectListResultType,
  GetProjectListResultType,
  StorageProjectResultType,
} from '~/store/project/types';
import { FilterValues } from '~/UI/Filters_WIP/FilterForm';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Projects', 'Project'],
  endpoints: (builder) => ({
    createSolarProject: builder.mutation<
      CreateSolarProjectResultType,
      CreateSolarProjectArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V2}/${routes.userProject.createSolarProject()}`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Projects'],
    }),
    createStorageProject: builder.mutation<
      CreateStorageProjectResultType,
      CreateStorageProjectArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V2}/${routes.userProject.createStorageProject()}`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Projects'],
    }),
    getProjectList: builder.query<GetProjectListResultType, string>({
      query: (params) =>
        `${API_VERSION_V2}/${routes.userProject.getProjects(params)}`,
    }),
    getAdminProjectList: builder.query<GetAdminProjectListResultType, string>({
      query: (params) => `${API_VERSION_V2}/${routes.admin.projects(params)}`,
    }),
    deleteProject: builder.mutation<null, DeleteProjectArgs>({
      query: ({ id: uuid }) => {
        return {
          url: `${API_VERSION_V2}/${routes.project.deleteProject(uuid)}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'Project' }],
    }),
    updateProjectStatus: builder.mutation<
      UpdateProjectStatusResponse,
      UpdateProjectStatusArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V2}/${routes.project.updateProjectStatus(
          args.uuid,
          args.status
        )}`,
        method: 'PUT',
        body: args,
      }),
      transformResponse: (response: UpdateProjectStatusResponse) => {
        return {
          uuid: response.uuid,
          status: response.status,
          projectUuid: response.projectUuid,
        };
      },
      invalidatesTags: (result) => [
        { type: 'Project', uuid: result?.projectUuid },
      ],
    }),
    updateSolarProject: builder.mutation<
      UpdatedSolarProjectTransformResultType,
      UpdateProjectArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V2}/${routes.userProject.updateSolarProject(
          args.uuid
        )}`,
        method: 'PUT',
        body: args,
      }),
      transformResponse: (response: UpdatedProjectType) => {
        return {
          name: response.name,
          project_address: response.project_details.project_address,
          type: response.project_details.project_type,
          state: response.project_details.state,
          latitude: response.project_details.latitude,
          longitude: response.project_details.longitude,
          google_place_id: response.project_details.google_place_id,
          arrival_date: response.project_details.arrival_date,
          size_in_kw: response.project_details.size_in_kw,
          racking_type: response.project_details.racking_type,
          anticipated_purchase_date:
            response.project_details.anticipated_purchase_date,
          location_id: response.project_details.location_id,
          current_purchase_volume_kw:
            response.project_details.current_purchase_volume_kw,
          previous_purchase_volume_kw: response.previous_purchase_volume_kw,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: 'Project', uuid }],
    }),
    updateStorageProject: builder.mutation<
      UpdatedStorageProjectTransformResultType,
      UpdateProjectArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V2}/${routes.userProject.updateStorageProject(
          args.uuid
        )}`,
        method: 'PUT',
        body: args,
      }),
      // @ts-ignore
      transformResponse: (response: UpdatedProjectType) => {
        return {
          name: response.name,
          project_address: response.project_details.project_address,
          type: response.project_details.project_type,
          state: response.project_details.state,
          latitude: response.project_details.latitude,
          longitude: response.project_details.longitude,
          google_place_id: response.project_details.google_place_id,
          arrival_date: response.project_details.storage_arrival_date || '',
          anticipated_purchase_date:
            response.project_details.anticipated_purchase_date,
          location_id: response.project_details.location_id,
          target_ac_power_mw: response.project_details.target_ac_power_mw,
          power_factor: response.project_details.power_factor,
          duration_hours: response.project_details.duration_hours,
          project_topology: response.project_details.project_topology,
          metering_location: response.project_details.metering_location,
          capacity_maintenance_term:
            response.project_details.capacity_maintenance_term,
          annual_cycle_count: response.project_details.annual_cycle_count,
          capacity_maintenance_strategy:
            response.project_details.capacity_maintenance_strategy,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: 'Project', uuid }],
    }),
    getSolarProject: builder.query<
      SolarProjectTransformedResultType,
      { id: string }
    >({
      query: ({ id }) =>
        `${API_VERSION_V2}/${routes.userProject.getSolarProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
      transformResponse: (response: GetSolarProjectResultType) => {
        const {
          data,
          modules,
          project,
          engine,
          lastUpdated,
          permissions,
          available_analytics_data,
        } = response;
        if (data && modules) {
          return {
            project,
            rankingData: parseEngineDataToTableData(
              data,
              modules,
              project.project_details.arrival_date
            ),
            engine,
            lastUpdated,
            permissions,
            modules,
            available_analytics_data,
          };
        }
        return {
          project,
          permissions,
          modules: modules || {},
          available_analytics_data,
          ...(engine ? { engine } : {}),
        };
      },
    }),
    getStorageProject: builder.query<StorageProjectResultType, { id: string }>({
      query: ({ id }) =>
        `${API_VERSION_V2}/${routes.userProject.getStorageProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
    }),
    getProject: builder.query<GetProjectResultTypeTransformed, { id: string }>({
      query: ({ id }) =>
        `${API_VERSION_V2}/${routes.userProject.getProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
    }),
    getProjectsBySalesforceOpportunityId: builder.query<BaseProject[], string>({
      query: (sf_opportunity_id) =>
        `${API_VERSION_V2}/${routes.project.getProjectsBySalesforceOpportunityId(
          sf_opportunity_id
        )}`,
    }),
    getProjectV3: builder.query<
      GetProjectResultTypeTransformed,
      { id: string }
    >({
      query: ({ id }) =>
        `${API_VERSION_V3}/${routes.userProject.getProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
    }),
    getSolarProjectV3: builder.query<
      SolarProjectTransformedResultType,
      { id: string }
    >({
      query: ({ id }) =>
        `${API_VERSION_V3}/${routes.userProject.getSolarProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
      transformResponse: (response: GetSolarProjectResultType) => {
        const {
          data,
          modules,
          project,
          engine,
          lastUpdated,
          permissions,
          available_analytics_data,
        } = response;
        if (data && modules) {
          return {
            project,
            rankingData: parseEngineDataToTableData(
              data,
              modules,
              project.project_details.arrival_date
            ),
            engine,
            lastUpdated,
            permissions,
            modules,
            available_analytics_data,
          };
        }
        return {
          project,
          permissions,
          modules: modules || {},
          available_analytics_data,
          ...(engine ? { engine } : {}),
        };
      },
    }),
    updateSolarProjectV3: builder.mutation<
      UpdatedSolarProjectTransformResultType,
      UpdateProjectArgs
    >({
      query: (args) => ({
        url: `${API_VERSION_V3}/${routes.userProject.updateSolarProject(
          args.uuid
        )}`,
        method: 'PUT',
        body: args,
      }),
      transformResponse: (response: UpdatedProjectType) => {
        return {
          name: response.name,
          project_address: response.project_details.project_address,
          type: response.project_details.project_type,
          state: response.project_details.state,
          latitude: response.project_details.latitude,
          longitude: response.project_details.longitude,
          google_place_id: response.project_details.google_place_id,
          arrival_date: response.project_details.arrival_date,
          size_in_kw: response.project_details.size_in_kw,
          racking_type: response.project_details.racking_type,
          anticipated_purchase_date:
            response.project_details.anticipated_purchase_date,
          location_id: response.project_details.location_id,
          current_purchase_volume_kw:
            response.project_details.current_purchase_volume_kw,
          previous_purchase_volume_kw: response.previous_purchase_volume_kw,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: 'Project', uuid }],
    }),
    getStorageProjectV3: builder.query<
      StorageProjectResultType,
      { id: string }
    >({
      query: ({ id }) =>
        `${API_VERSION_V3}${routes.userProject.getStorageProjectByUUID(id)}`,
      providesTags: (result, error, { id }) => [{ type: 'Project', uuid: id }],
    }),
    saveStorageFilters: builder.mutation<
      { success: boolean },
      { projectUuid: string; filters: FilterValues }
    >({
      query: ({ filters, projectUuid }) => {
        return {
          url: `${API_VERSION_V2}/${routes.userProject.saveStorageFilters(
            projectUuid
          )}`,
          method: 'POST',
          body: {
            filter: filters,
          },
        };
      },
    }),
  }),
});

export const {
  useGetProjectListQuery,
  useGetAdminProjectListQuery,
  useGetProjectQuery,
  useGetStorageProjectQuery,
  useLazyGetStorageProjectQuery,
  useCreateSolarProjectMutation,
  useCreateStorageProjectMutation,
  useGetSolarProjectQuery,
  useUpdateSolarProjectMutation,
  useUpdateStorageProjectMutation,
  useUpdateProjectStatusMutation,
  useDeleteProjectMutation,
  useLazyGetProjectsBySalesforceOpportunityIdQuery,
  useGetProjectV3Query,
  useGetSolarProjectV3Query,
  useUpdateSolarProjectV3Mutation,
  useGetStorageProjectV3Query,
  useSaveStorageFiltersMutation,
} = projectsApi;
