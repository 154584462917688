import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { theme } from '~/utils';
import MarketingLayout from '~/components/MarketingLayout';
import { IconChevronRight } from '~/UI/Icons';
import { ChangeMetaDescriptor } from '~/utils/changeMetaDescriptor';

const TitleContainer = styled.div`
  text-align: center;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 92px;

  line-height: 110%;
  letter-spacing: -0.07em;

  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  .primary {
    color: ${theme.colors.charcoal};
  }
  .secondary {
    color: ${theme.colors.primary};
  }
`;

const SubtextContainer = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;

  line-height: 27px;
  letter-spacing: -0.03em;

  text-align: center;

  color: ${theme.colors.charcoal};

  margin-bottom: 2.5rem;
`;

const NavButton = styled(NavLink)`
  padding: 1rem 3rem;

  border-radius: 5px;
  background-color: ${theme.colors.primaryDark};

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  text-align: center;
  letter-spacing: -0.02em;
  color: white;
`;

const NavOutlinedButton = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background: #ffffff;
  /* Ink/Sterling */

  border: 1px solid #eeeff0;
  box-sizing: border-box;
  border-radius: 80px;

  color: ${theme.colors.charcoal};

  .nav-text {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    line-height: 22px;
    letter-spacing: -0.02em;

    padding-right: 0.75rem;
  }
`;

const MarketingVendors: React.FC<void> = () => {
  ChangeMetaDescriptor('For Marketing Vendors');
  return (
    <MarketingLayout
      actions={
        <NavOutlinedButton to="/customers">
          <div className="nav-text">For Customers</div>
          <IconChevronRight />
        </NavOutlinedButton>
      }
    >
      <TitleContainer>
        <div className="primary">See your modules</div>
        <div className="secondary">in a new light.</div>
      </TitleContainer>
      <SubtextContainer>
        <div>
          See how your modules perform for any project type with a complete
          online Anza analysis.
        </div>
        <div> Go beyond $/watt to project value for your customers.</div>
      </SubtextContainer>
      <NavButton to="/modules">Get Started</NavButton>
    </MarketingLayout>
  );
};

export default MarketingVendors;
