import styled from '@emotion/styled';

import { theme } from '~/utils';
import ExternalLink from '~/components/ExternalLink';

export const FOOTER_HEIGHT = 90;

export const FooterBox = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  background-color: ${theme.colors.gray800};
`;

export const FooterContent = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${FOOTER_HEIGHT}px;
  padding: 28px 40px 28px 40px;
  max-width: ${theme.dimensions.width.max}px;
`;

export const InfoBox = styled.div`
  display: flex;
`;

const FooterText = styled.div`
  color: ${theme.colors.white};
  font-size: 10px;
  align-self: center;
`;

export const CopyrightBox = styled(FooterText)`
  padding-left: 80px;
  font-weight: 400;
`;

export const StyledLink = styled(ExternalLink)`
  color: ${theme.colors.white};
  font-size: 10px;
  align-self: center;
`;

export const UsefulLinksBox = styled(FooterText)`
  padding-left: 48px;
  display: flex;
  gap: 24px;
  font-weight: 600;
`;

export const SocialNetworksBox = styled.div`
  display: flex;
  gap: 20px;
`;
