import dayjs, { Dayjs } from 'dayjs';

import { MODULE_MAX_QUARTER_DATA_SHOWN } from '~/constants/modules';

type q = 1 | 2 | 3 | 4;

export interface Quarter {
  quarter: q;
  year: number;
  label?: string;
}

export const formatQuarterYearLabel = (quarter: q | number, year: number) => {
  return `Q${quarter} ${year}`;
};

export const convertDateToQuarter = (date: Date): Quarter => {
  const year = date.getFullYear();
  const quarter = Math.floor((date.getMonth() + 3) / 3) as q;
  return { year, quarter };
};

export const convertLabelIntoQuarterAndYear = (label: string): Quarter => {
  const [quarter, year] = label.split(' ');
  const quarterNumber = +quarter.split('')[1];
  return { year: +year, quarter: quarterNumber as q };
};

export const getCurrentYearAndQuarter = () => {
  const today = new Date();
  return convertDateToQuarter(today);
};

export const isQuarterEqualOrGraterThanCurrent = (
  quarter: q,
  year: number
): boolean => {
  const { year: currentYear, quarter: currentQuarter } =
    getCurrentYearAndQuarter();
  return !(
    year < currentYear ||
    (year === currentYear && quarter < currentQuarter)
  );
};

export const getQuarterInNQuarters = (
  { year, quarter }: Quarter,
  quarterCount: number
): Quarter => {
  const quarterAdd = quarterCount - 1;
  const y = year + Math.floor((quarter + quarterAdd) / 4);
  const q = (((quarter + quarterAdd) % 4) + 1) as q;
  return { quarter: q, year: y };
};

const getQuarterRange = (
  quarter: number,
  year: number,
  quarterCount: number
) => {
  const quarters = [
    { quarter, year, label: formatQuarterYearLabel(quarter, year) },
  ];
  for (let i = 0; i < quarterCount - 1; i += 1) {
    const y = year + Math.floor((quarter + i) / 4);
    const q = ((quarter + i) % 4) + 1;
    quarters.push({ quarter: q, year: y, label: formatQuarterYearLabel(q, y) });
  }
  return quarters;
};

const getPreviusQuarterRange = (
  quarter: number,
  year: number,
  quarterCount: number
) => {
  const quarters = [
    { quarter, year, label: formatQuarterYearLabel(quarter, year) },
  ];
  let lastQ = quarter;
  let lastY = year;
  for (let i = 0; i < quarterCount - 1; i += 1) {
    lastQ -= 1;
    if (lastQ === 0) {
      lastQ = 4;
      lastY -= 1;
    }
    quarters.push({
      quarter: lastQ,
      year: lastY,
      label: formatQuarterYearLabel(lastQ, lastY),
    });
  }
  return quarters;
};

/**
 * Returns a range of tuples of year from the current day on
 *
 * @param {number} quarterCount - the number of quarters to return
 * @returns quarterRange - a range containing tuples of (year, quarter)
 */
export const getQuarterRangeFromToday = (quarterCount: number) => {
  const { year, quarter } = getCurrentYearAndQuarter();
  return getQuarterRange(quarter, year, quarterCount);
};

export const getPreviusQuarterRangeFromToday = (quarterCount: number) => {
  const { year, quarter } = getCurrentYearAndQuarter();
  return getPreviusQuarterRange(quarter, year, quarterCount);
};

const QUARTER_DATES = {
  1: {
    start: {
      day: 1,
      month: 1,
    },
    end: {
      day: 31,
      month: 3,
    },
  },
  2: {
    start: {
      day: 1,
      month: 4,
    },
    end: {
      day: 30,
      month: 6,
    },
  },
  3: {
    start: {
      day: 1,
      month: 7,
    },
    end: {
      day: 30,
      month: 9,
    },
  },
  4: {
    start: {
      day: 1,
      month: 10,
    },
    end: {
      day: 31,
      month: 12,
    },
  },
};

export const getQuarterDate = ({ year, quarter }: Quarter, start = true) => {
  const key = start ? 'start' : 'end';
  const { day, month } = QUARTER_DATES[quarter][key];
  return new Date(year, month - 1, day);
};

export const getMaxDateInNQuarters = (quarterCount: number) => {
  const current = getCurrentYearAndQuarter();
  const maxQuarter = getQuarterInNQuarters(current, quarterCount);
  return getQuarterDate(maxQuarter, false);
};

export const disabledDateByQuarter = (
  current: Dayjs,
  quarterLimit = MODULE_MAX_QUARTER_DATA_SHOWN - 1
) => {
  const maxDate = getMaxDateInNQuarters(quarterLimit).getTime();
  return (
    current && (current.valueOf() < Date.now() || current.valueOf() > maxDate)
  );
};

export const getAnticipatedPurchaseDate = (
  current: Dayjs,
  maxDate: Dayjs | undefined
) => {
  if (!maxDate) {
    return disabledDateByQuarter(current);
  }
  const minDate = current.startOf('month');
  return (
    current &&
    (current.valueOf() < Date.now() || minDate.valueOf() > maxDate.valueOf())
  );
};

// This function will disable dates by default in a 2 years and 6 months window. Next 6 months from present will be disabled, and the valid range will go from the first valid date until 2 years.
export const disabledDateFromUntil = (current: Dayjs, from = 7, until = 24) => {
  const minDate = dayjs().add(from, 'M');
  const maxDate = minDate.add(until, 'M');

  return (
    current &&
    (current.valueOf() < minDate.valueOf() ||
      current.valueOf() > maxDate.valueOf())
  );
};

export const validateMinMaxRangeDate = (
  current: Dayjs,
  minDate: Dayjs,
  maxDate: Dayjs
) => {
  return (
    current &&
    (current.valueOf() < minDate.valueOf() ||
      current.valueOf() > maxDate.valueOf())
  );
};

export const STORAGE_ROLLING_WINDOW_IN_MONTHS = 6;
