import React from 'react';
import { Table as TableAnt } from 'antd';
import styled from '@emotion/styled';
import { TableProps as TableAntProps } from 'antd/lib/table';
import { ColumnProps as ClumnPropsAntd } from 'antd/lib/table/Column';

import {
  FilterValue as FilterValueAntd,
  SorterResult as SorterResultAntd,
  TableCurrentDataSource as TableCurrentDataSourceAntd,
} from 'antd/lib/table/interface';
import { Spinner } from '~/UI';

const TableSpinner = styled(Spinner)`
  height: 100px;
  width: 100px;
`;

export const EmptyTableContainer = styled.div`
  height: 510px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export type TableProps = TableAntProps<any>;

export type ColumnProps<T> = ClumnPropsAntd<T>;

export type FilterValue = FilterValueAntd;
export type SorterResult<RecordType> = SorterResultAntd<RecordType>;
export type TableCurrentDataSource<RecordType> =
  TableCurrentDataSourceAntd<RecordType>;
export const TableLoadingSpinner = () => (
  <EmptyTableContainer>
    <TableSpinner />
  </EmptyTableContainer>
);

type Props<T> = TableAntProps<T> & {
  showEmptyStateIfNoRecords?: boolean;
};

const StyledTable = styled(TableAnt)<any>`
  ${(props) =>
    props.showEmptyStateIfNoRecords
      ? ''
      : `
  & .ant-empty {
    display: none;
  }

  & .ant-table-empty .ant-table-tbody .ant-table-cell{
    padding: 0;
  }

  `}
`;

// eslint-disable-next-line
const Table = <T extends object = any>(props: Props<T>) => {
  return <StyledTable {...props} />;
};

export default Table;
