import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);

export const calendarTime = (date: Date): string => {
  return dayjs(date).calendar(null, {
    sameDay: '[today at] h:mm A',
    nextDay: '[tomorrow at] h:mm A',
    nextWeek: 'MMM D [at] h:mm A',
    lastDay: '[yesterday at] h:mm A',
    lastWeek: 'MMM D [at] h:mm A',
    sameElse: 'MMM D, YYYY',
    sameMinute: 'just now',
  });
};
