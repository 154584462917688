import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import FilterMenu, {
  FilterMenuFilterConfig,
  FilterMenuOption,
  OnFilterMenuValueChange,
} from '~/UI/Filters/FilterMenu';

import { FilterValues } from '~/UI/Filters/FilterForm';
import { DropdownType } from './GroupFilterDropdown';
import { IconPosition } from './FilterTag';

export type SelectableFilterConfig = FilterMenuFilterConfig & {
  group: string;
  datatype?: 'boolean' | 'integer' | 'double' | 'string' | 'float' | 'date';
  display_order?: number | string | null;
};

const generateSelectableFilterConfigs = (
  filters: SelectableFilterConfig[]
): SelectableFilterConfig[] => {
  const selectableFilterConfigs: SelectableFilterConfig[] = [];
  filters.forEach((f) => {
    const foundFilterGroup = selectableFilterConfigs.find(
      (sfc) => sfc.name === f.group
    );

    const newOption: FilterMenuOption = {
      label: f.title,
      title: f.title,
      value: f.name,
    };
    if (!foundFilterGroup) {
      selectableFilterConfigs.push({
        group: '',
        dropdownGroupTitle: f.group,
        name: f.group,
        title: f.group,
        options: [newOption],
      });
      return;
    }
    foundFilterGroup.options.push(newOption);
  });

  return selectableFilterConfigs;
};

/**
 * Given the values, returns a list with all the selected values
 * @param values
 * @returns
 */
const getValuesList = (values?: FilterValues) => {
  if (!values) return [];
  const initialForcedVisibleFilters: string[] = [];
  R.mapObjIndexed((values, filterKey) => {
    if (values && R.is(Array, values) && !R.isEmpty(values)) {
      initialForcedVisibleFilters.push(filterKey);
    }
  }, values || {});
  return initialForcedVisibleFilters;
};

const generateVisibleFiltersByValuesArray = (
  values: Record<string, string[]>,
  filters: SelectableFilterConfig[]
) => {
  const visibleFilters = getValuesList(values);
  const initial: Record<string, string[]> = R.reduce(
    (acc, filterConfig) => {
      return {
        ...acc,
        [filterConfig.group]: [],
      };
    },
    {},
    filters
  );
  visibleFilters.forEach((value) => {
    const group = filters.find((f) => f.name === value)?.group || '';
    initial[group]?.push(value);
  });
  return initial;
};

export type SelectableFilterMenuProps = {
  filters: SelectableFilterConfig[];
  /** used for controling state outise this component */
  values?: FilterValues;
  onChange?: (filterValues: FilterValues) => void;
  /** If true, when auser clicks on a filter in the dropdown to select the visible filters,
   * it will close the dropdown and open the filter dropdown automatically */
  openSelectedDropdownOnSelection?: boolean;
  disabled?: boolean;
  // Select Visible Filter Dropdown props
  selectFilterDropdownType?: DropdownType;
  selectFilterDropdownLabel?: React.ReactNode;
  selectFilterDropdownPosition?: 'start' | 'end';
  selectFilterDropdownIcon?: React.ReactNode;
  selectFilterDropdownIconPosition?: IconPosition;
  selectFilterDropdownCloseOnClearAll?: boolean;
};

const SelectableFilterMenu = ({
  filters,
  values,
  onChange,
  openSelectedDropdownOnSelection,
  selectFilterDropdownType,
  selectFilterDropdownLabel = 'Add filters',
  selectFilterDropdownPosition = 'start',
  selectFilterDropdownIcon,
  selectFilterDropdownIconPosition,
  disabled,
  selectFilterDropdownCloseOnClearAll,
}: SelectableFilterMenuProps) => {
  const initialVisibleFilterValues = generateVisibleFiltersByValuesArray(
    values || {},
    filters
  );
  // We use this because we need to controll the current filters state
  const [currentFilters, setCurrentFilters] = useState<FilterValues>(
    values || {}
  );

  // We use this because we need to controll the visible filter dropdowns state
  const [visibleFilterValues, setVisibleFilterValues] = useState<FilterValues>(
    initialVisibleFilterValues
  );

  const [forcedVisibleFilters, setForcedVisibleFilters] = useState<string[]>(
    []
  );

  useEffect(() => {
    // Only used when passing the current controlled value outside this component
    if (!R.equals(values, currentFilters)) {
      setCurrentFilters(values || {});
      setVisibleFilterValues(
        generateVisibleFiltersByValuesArray(values || {}, filters)
      );
      setForcedVisibleFilters(getValuesList(values));
    }
  }, [values]);

  const selectableFilters = generateSelectableFilterConfigs(filters);

  const [openDropdown, setOpenDropdown] = useState<string>();

  /** Removes a dropdown button from the menu  */
  const removeForcedVisibleFilter = (filterName: string) => {
    // Hiding dropdown button of that filter
    setForcedVisibleFilters((prev) => prev.filter((f) => f !== filterName));
    // Unselecting visible filter in "Add filters +" form
    const group = filters.find((f) => f.name === filterName)?.group || '';
    setVisibleFilterValues((prev) => {
      const newVisibleFilters = (prev[group] as string[]).filter(
        (vals) => vals !== filterName
      );
      return {
        ...prev,
        [group]: newVisibleFilters,
      };
    });
  };

  const onChangeVisibleFilters = (values: FilterValues) => {
    let filters: string[] = [];
    R.mapObjIndexed((activeFilters: string[]) => {
      filters = [...filters, ...(activeFilters || [])];
    }, values as Record<string, string[]>);

    setForcedVisibleFilters(filters);
  };

  const onFiltersChange = (filterValues: FilterValues) => {
    setCurrentFilters(filterValues);
    if (onChange) onChange(filterValues);
  };

  const onVisibleFilterValueChange: OnFilterMenuValueChange = ({
    action,
    value,
    allValues,
  }) => {
    // Seting forced visible dropdown filters
    onChangeVisibleFilters(allValues);

    // When a filter in the "Add Filters +" dropdown is UNSELECTED we remove all related filters from the currentFilters state
    if (action === 'remove') {
      setCurrentFilters((prev) => {
        const newFilterValues: FilterValues = {
          ...prev,
          [value]: [],
        };
        onFiltersChange(newFilterValues);
        return newFilterValues;
      });
    }

    // automatically opens the selected dropdown
    if (openSelectedDropdownOnSelection && action === 'add')
      setOpenDropdown(value);
  };

  const onOpenDropdownChange = (dropdownFilterName: string, open: boolean) => {
    /**
     * If a dropdown is closed and no values are selected for that filter we remove the dropdown button from the menu
     */
    const currentFilterValues = currentFilters[dropdownFilterName];
    const noValues =
      currentFilterValues &&
      R.is(Array, currentFilterValues) &&
      R.isEmpty(currentFilterValues);
    if (!open && (R.isNil(currentFilterValues) || noValues)) {
      removeForcedVisibleFilter(dropdownFilterName);
    }
  };

  const onClearAllFilters = () => {
    // hiding all dropdown buttons
    setForcedVisibleFilters([]);
    // unselecting all filters from the Selectable dropdown
    setVisibleFilterValues({});
    // removing all filter criteria
    setCurrentFilters({});
    if (onChange) onChange({});
  };

  /* Menu in charge of showing current visible dropdown buttons for filters */
  const SelectDropdown = (
    <FilterMenu
      mainDropdownLabel={selectFilterDropdownLabel}
      hideDropdownCount
      hideMainDropdownClearAllButton
      mainDropdownHighlight={false}
      mainDropdownAutoMaxWidth
      hideGroupDropdowns
      values={visibleFilterValues}
      filters={selectableFilters}
      noContainer
      onValuesChange={setVisibleFilterValues}
      onValueChange={onVisibleFilterValueChange}
      closeDropdownOnSelection={openSelectedDropdownOnSelection}
      mainDropdownCloseOnClearAll={selectFilterDropdownCloseOnClearAll}
      mainDropdownType={selectFilterDropdownType}
      mainDropdownIcon={selectFilterDropdownIcon}
      mainDropdownIconPosition={selectFilterDropdownIconPosition}
      disableMainDropdown={disabled}
      onClearAllFilters={onClearAllFilters}
    />
  );

  return (
    <>
      {selectFilterDropdownPosition === 'start' && SelectDropdown}
      {/* Menu in charge of handling filters */}
      <FilterMenu
        values={currentFilters}
        filters={filters}
        hideMainDropdown
        hideEmptyGroupDropdowns
        forcedVisibleGroups={forcedVisibleFilters}
        onValuesChange={onFiltersChange}
        noContainer
        hideDropdownCount
        openDropdown={openDropdown}
        onClearFilter={removeForcedVisibleFilter}
        onOpenDropdownChange={onOpenDropdownChange}
        disableMainDropdown={disabled}
      />
      {selectFilterDropdownPosition === 'end' && SelectDropdown}
    </>
  );
};

export default SelectableFilterMenu;
