import React from 'react';
import { theme } from '~/utils';

export type BarProps = {
  barWidth: number;
  direction?: 'left' | 'right';
  translateX?: number;
};

const Bar = ({ barWidth, direction = 'right', translateX }: BarProps) => {
  const transform =
    direction === 'right'
      ? `translate(${translateX},0)`
      : `rotate(180) translate(${-barWidth - (translateX || 0)},-5)`;
  return (
    <svg fill={theme.colors.gray300} xmlns="http://www.w3.org/2000/svg">
      <path
        transform={transform}
        d={`M0,0
            h${barWidth - 3}
            q3,0 3,2
            v1
            q0,2 -3,2
            h-${barWidth - 3}
            z`}
      />
    </svg>
  );
};

export default Bar;
