import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { getConfiguration } from '~/utils/configurations';

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const domain = getConfiguration('REACT_APP_AUTH0_DOMAIN');
  const clientId = getConfiguration('REACT_APP_AUTH0_CLIENT_ID');

  const history = useHistory();
  const { pathname, hash } = history.location;

  if (!!hash && typeof hash === 'string' && hash.length > 0) {
    const localHash = localStorage.getItem(pathname);
    if (!localHash) {
      localStorage.setItem(pathname, hash);
    }
  }

  const onRedirectCallback = (appState: AppState | undefined) => {
    const returnTo = appState?.returnTo;
    const search = appState?.returnToSearch ?? '';

    if (!returnTo) {
      history.push(window.location.pathname);
      return;
    }

    const localHash = localStorage.getItem(returnTo);
    if (localHash) {
      const route = `${returnTo}${search}${localHash}`;
      history.push(route);
      localStorage.removeItem(returnTo);
      return;
    }

    const route = `${returnTo}${search}`;
    history.push(route);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={getConfiguration('REACT_APP_AUTH0_AUDIENCE')}
      scopes={getConfiguration('REACT_APP_AUTH0_SCOPES')}
      scope="offline_access"
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
