import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { ButtonAnt as Button, Form, notification } from '~/UI';
import { IconAdd } from '~/UI/Icons';
import { ActionButton } from '~/UI/ActionBar';
import { gaCustomerEvent, GA } from '~/utils';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { ROUTER_PATHS } from '~/router/Paths';
import {
  toggleSearchModal,
  storeSolarProject,
  storeStorageProject,
} from '~/store/searchProject';
import CreateProjectModal from '~/components/Projects/CreateProjectModal';
import CreateProjectFormContent from '~/components/Projects/CreateProjectFormContent';
import {
  useCreateSolarProjectMutation,
  useCreateStorageProjectMutation,
} from '~/store/api';

const CreateProject: React.FC<
  {
    loaded?: boolean;
    primaryButton?: boolean;
    testIdData?: TestIdProps;
    style?: React.CSSProperties;
  } & React.HTMLProps<HTMLButtonElement>
> = ({ testIdData, loaded, primaryButton = false, style }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isFormSubmissionDisabled, setIsFormSubmissionDisabled] =
    useState(false);
  const [isFormFieldLoading, setIsFormFieldLoading] = useState(false);

  const [createSolarProject, { isLoading: isCreatingProject }] =
    useCreateSolarProjectMutation();
  const [createStorageProject, { isLoading: isCreatingStorageProject }] =
    useCreateStorageProjectMutation();

  useEffect(() => {
    setIsFormSubmissionDisabled(isFormFieldLoading);
  }, [isFormFieldLoading]);

  useEffect(() => {
    setIsFormLoading(isCreatingProject || isCreatingStorageProject);
  }, [isCreatingProject, isCreatingStorageProject]);
  const openCreateProjectModal = () => {
    dispatch(
      toggleSearchModal({
        value: true,
      })
    );
  };

  const [projectForm] = Form.useForm();

  const closeModal = () => {
    projectForm.resetFields();
    dispatch(toggleSearchModal({ value: false }));
  };

  const submitCreateProjectModal = async () => {
    projectForm.submit();
    projectForm
      .validateFields()
      .then(async (values) => {
        const parsedValues = {
          ...values,
          arrival_date: values?.arrival_date
            ? dayjs(values.arrival_date).toISOString()
            : undefined,
          storage_arrival_date: values?.storage_arrival_date
            ? dayjs(values.storage_arrival_date).toISOString()
            : undefined,
          anticipated_purchase_date: values?.anticipated_purchase_date
            ? dayjs(values.anticipated_purchase_date).toISOString()
            : undefined,
        };
        if (values.project_type === 'solar') {
          createSolarProject(parsedValues)
            .unwrap()
            .then((payload) => {
              dispatch(storeSolarProject(parsedValues));
              history.push(
                ROUTER_PATHS.projects.solar.PROJECT_DETAILS(payload.uuid)
              );
            })
            .catch((error) => {
              console.error('Error', error);
              notification.error({
                message:
                  error.data?.message ||
                  'There was an error creating the project',
              });
            })
            .finally(() => {
              gaCustomerEvent({
                action: GA.actions.INITIALIZE_PROJECT,
                label: values.name,
              });
              closeModal();
            });
        } else {
          createStorageProject(parsedValues)
            .unwrap()
            .then((payload) => {
              dispatch(storeStorageProject(parsedValues));
              history.push(
                ROUTER_PATHS.projects.storage.PROJECT_DETAILS(payload.uuid)
              );
            })
            .catch((error) => {
              console.error(error);
              notification.error({
                message:
                  error.data?.message ||
                  'There was an error creating the project',
              });
            })
            .finally(() => {
              gaCustomerEvent({
                action: GA.actions.INITIALIZE_PROJECT,
                label: values.name,
              });
              closeModal();
            });
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  return (
    <>
      <ActionButton
        style={style}
        disabled={!loaded}
        type={primaryButton ? 'primary' : 'default'}
        onClick={openCreateProjectModal}
        data-testid={generateTestId({
          ...testIdData,
          component: TEST_DATA_COMPONENTS.BUTTON,
          identifier: 'new-project',
        })}
      >
        <IconAdd size="24px" />
        New Project
      </ActionButton>

      <CreateProjectModal
        title="Create New Project"
        description="Enter the project details below to get started"
        actions={[
          <Button
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.BUTTON}`,
              identifier: 'submit',
            })}
            loading={isFormLoading}
            disabled={isFormSubmissionDisabled}
            type="primary"
            onClick={submitCreateProjectModal}
            key="ok"
            style={{
              position: 'relative',
              display: 'inline-block',
              width: '170px',
            }}
          >
            Create Project
          </Button>,
        ]}
        onOk={submitCreateProjectModal}
        onClose={closeModal}
      >
        <CreateProjectFormContent
          isEditing={false}
          form={projectForm}
          setIsFormFieldLoading={setIsFormFieldLoading}
        />
      </CreateProjectModal>
    </>
  );
};

export default CreateProject;
