import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { BASE_URL_V2, prepareHeaders } from '../settings';

export const dashboardsApi = createApi({
  reducerPath: 'dashboardsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  tagTypes: ['AdminDashboards'],
  endpoints: (builder) => ({
    getDashboard: builder.query<
      { url: string; sessionLengthInSeconds: number },
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `${routes.dashboards.getDashboardById(id)}`,
        };
      },
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardsApi;
