import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { PropertyAccessor } from '@nivo/core';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { theme } from '~/utils';
import { Typography } from '~/UI';
import NoDataAvailable from '~/components/NoDataAvailable';
import { NoTooltip } from './Tooltips';

const MOCK_DATA = [
  {
    id: 'Apple',
    label: 'Apple',
    value: 2147.75,
    count: 13,
  },
];

const defaultStyles: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const absoluteChartOverlayContainer: React.CSSProperties = {
  position: 'absolute',
  top: '10%',
  left: '0',
  width: '50%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  paddingLeft: '30px',
};

const LegendRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
`;

const LegendItem = styled.div`
  flex-grow: 1;
  padding-bottom: 10px;
  display: flex;
`;

const LegendRight = styled.div`
  text-align: right;
  flex-grow: 1;
  padding-bottom: 10px;
`;

const getDatumColorByIndex = (colors: string[], index: number) => {
  if (R.isNil(colors) || R.isEmpty(colors)) {
    return null;
  }

  const indexToGrab = index < colors.length ? index : index % colors.length;

  return colors[indexToGrab];
};

interface PropsValue {
  id: string;
  label: string;
  value: number;
  count: number;
}

interface Props {
  style: React.CSSProperties;
  enableArcLabels?: boolean;
  enableArcLinkLabels?: boolean;
  title?: string;
  dataLabel?: string;
  metricLabel?: string;
  colors: string[];
  data: {
    id: string;
  }[];
  value?: PropsValue | string;
}

const NoDataPieChart: React.FC<Props> = (props) => {
  const stylesToUse = {
    ...defaultStyles,
    ...props.style,
  };

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <div style={absoluteChartOverlayContainer}>
        <NoDataAvailable styles={{ maxWidth: '88px' }} />
      </div>
      <div style={absoluteChartOverlayContainer}>
        <ResponsivePie
          data={MOCK_DATA}
          isInteractive={false}
          value={
            (props.value as PropertyAccessor<PropsValue, number>) || 'value'
          }
          valueFormat={() => ''}
          colors={[theme.colors.grey]}
          borderColor={{
            from: 'color',
            modifiers: [
              ['darker', 0.6],
              ['opacity', 0.5],
            ],
          }}
          margin={{
            top: 50,
            right: 10,
            bottom: 50,
            left: 10,
          }}
          innerRadius={0.675}
          padAngle={1.25}
          activeOuterRadiusOffset={8}
          borderWidth={0}
          enableArcLabels={props.enableArcLabels || false}
          enableArcLinkLabels={props.enableArcLinkLabels || false}
          arcLabelsSkipAngle={10}
          theme={{
            labels: { text: { fontSize: '0.6em', fontWeight: 'bold' } },
          }}
          tooltip={NoTooltip}
        />
      </div>
      <div style={stylesToUse}>
        {props.title && (
          <Typography.VendorLabel
            style={{
              marginBottom: '15px',
            }}
          >
            {props.title}
          </Typography.VendorLabel>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 50px',
              marginTop: '20px',
            }}
          >
            <LegendRow
              style={{
                borderBottom: theme.borders.lightGray,
                marginBottom: '20px',
              }}
            >
              <LegendItem>{props.dataLabel}</LegendItem>
              <LegendRight>{props.metricLabel}</LegendRight>
            </LegendRow>
            {props.data &&
              props.data.map((datum: { id: string }, index: number) => (
                <LegendRow key={datum.id}>
                  <LegendItem>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          height: '15px',
                          width: '15px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          background:
                            getDatumColorByIndex(props.colors, index) ??
                            undefined,
                        }}
                      />
                    </div>
                    <div>{datum.id}</div>
                  </LegendItem>
                  {!!props.value && (
                    <LegendRight style={{ fontWeight: 700 }}>
                      {R.pathOr('', [`${props.value}`], datum)}
                    </LegendRight>
                  )}
                </LegendRow>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataPieChart;
