import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '.';
import { theme } from '../utils';
import Button from './Button';

const getBackgroundColor = ({ error, disabled }: Partial<FileInputProps>) => {
  if (!error) {
    return theme.colors.lightError;
  }
  if (disabled) {
    return theme.colors.sterling;
  }
  return 'transparent';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  width: 100%;
`;

const FileSelector = styled.div<Partial<FileInputProps>>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
  outline: none;
  border-radius: 0.375rem;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${(props) => getBackgroundColor(props)};
  font-size: ${theme.fonts.sizes.placeholder};
  border: 2px solid
    ${(props) => (props.error ? 'transparent' : theme.colors.sterling)};
  padding: 0 1rem;
  pointer-events: none;

  :focus {
    border: 2px solid
      ${(props) => (props.error ? 'transparent' : theme.colors.stone)};
  }

  ::placeholder {
    color: ${theme.fonts.colors.charcoal};
  }
`;

const OptionalLabel = styled.span`
  font-size: 12px;
  margin-left: 12px;
  color: #565656;
  font-weight: 400;
`;

interface FileInputProps {
  label?: string;
  onChange?: (file: File) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  error?: string;
  optional?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  label,
  style,
  onChange,
  optional,
  disabled,
  error,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = React.useState<File>();

  const handleClick = () => {
    hiddenFileInput.current!.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event!.target!.files![0];
    setSelectedFile(fileUploaded);

    if (fileUploaded) {
      onChange!(fileUploaded);
    }
  };

  return (
    <Container style={style}>
      <Typography.Label>
        {label} {optional && <OptionalLabel>Optional</OptionalLabel>}
      </Typography.Label>

      <InnerContainer>
        <FileSelector
          style={{ display: 'flex', flexGrow: 4 }}
          error={error}
          disabled={disabled}
        >
          {(selectedFile && selectedFile.name) || 'No file chosen'}
        </FileSelector>
        <Button
          text="Choose File"
          onClick={handleClick}
          style={{ marginLeft: '20px', width: '40%' }}
          htmlType="button"
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </InnerContainer>
    </Container>
  );
};

FileInput.defaultProps = {
  label: '',
  style: {},
  error: '',
  disabled: false,
  optional: false,
  onChange: () => {},
};

export default FileInput;
