import React from 'react';
import dayjs from 'dayjs';
import {
  Form,
  FormInstance,
  FormItemLabel,
  Switch,
  InputAnt as Input,
} from '~/UI';
import { DCBlock } from '~/store/project';
import { notRequiredRules, requiredRule } from '~/utils';

type DcBlockFormItemsProps = {
  dcblock: DCBlock;
  form: FormInstance;
};

const DcBlockFormItems = ({ form, dcblock }: DcBlockFormItemsProps) => {
  const isDcBlockActive = Form.useWatch('active', form) || false;

  return (
    <>
      <Form.Item
        name="active"
        label={<FormItemLabel>Is Active</FormItemLabel>}
        labelCol={{ span: 24 }}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <div style={{ marginBottom: '20px' }}>
        Last Updated: {dayjs(dcblock.updated_at).format('MM/D/YY')}
      </div>
      <Form.Item
        name="name"
        label={<FormItemLabel>Name</FormItemLabel>}
        labelCol={{ span: 24 }}
        rules={[
          ...(isDcBlockActive ? [requiredRule] : notRequiredRules),
          { whitespace: true, message: 'Name cannot be blank' },
        ]}
        required={isDcBlockActive}
      >
        <Input required={isDcBlockActive} placeholder="Insert Module Name" />
      </Form.Item>
    </>
  );
};

export default DcBlockFormItems;
