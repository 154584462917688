import * as R from 'ramda';

export const ProjectStatuses = {
  CREATED: 'Created',
  PROPOSAL: 'Proposal',
  AWARDED: 'Awarded',
  LOST: 'Lost',
  INACTIVE: 'Inactive',
};

export const PROJECT_STATUSES = R.mapObjIndexed(
  (value) => value.toLocaleLowerCase(),
  ProjectStatuses
);

export const ClosedProjectstatuses = R.pick(
  ['AWARDED', 'LOST', 'INACTIVE'],
  ProjectStatuses
);

export const anzaProjectHistoryStatuses: Array<{
  text: string;
  value: string;
}> = R.values(ProjectStatuses).map((val) => ({
  text: val,
  value: val.toLowerCase(),
}));
