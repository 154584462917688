import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getConfiguration } from '~/utils/configurations';
import { StorageProject, StorageAdvancedInputs } from '~/store/project';
import { AddressSearch, InputAnt as Input, Form, FormInstance } from '~/UI';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';
import { TEST_DATA_COMPONENTS, generateTestId } from '~/utils/dataTestProps';
import { PROJECT_STATUSES, ProjectTypes } from '~/utils';
import AdvancedInputsBanner from '~/components/AdvancedInputsBanner';
import useAuth from '~/hooks/useAuth';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import {
  StyledCollapse,
  StyledCollapsePanel,
  StyledForm,
} from '../shared/styles';
import { GenericLabel, ProjectLocationLabel } from '../FormGroup';
import StorageFormGroup from './StorageFormGroup';
import StorageAdvacedInputsFormGroup from './StorageAdvancedInputsFormGroup';

interface Props {
  form: FormInstance;
  data: StorageProject;
  disabledInputs?: boolean;
  defaultAdvancedInputs: StorageAdvancedInputs;
  disabledAdvancedInputs?: boolean;
}
const StorageProjectDrawerForm = ({
  form,
  data,
  disabledInputs,
  defaultAdvancedInputs,
  disabledAdvancedInputs,
}: Props) => {
  const [locationError, setLocationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.validateFields();
  }, [data]);

  const handleSelectLocation = (location: AddressSearchResponseType) => {
    setLocationError('');
    if (location?.error?.message) {
      setLocationError(location?.error?.message);
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        google_place_id: null,
        state: null,
      });
      form.validateFields(['project_address']);
      return;
    }
    form.setFieldsValue({
      project_address: location.address,
      latitude: location.lat,
      longitude: location.lng,
      google_place_id: location.placeId || '',
      state: location.state,
    });
    form.validateFields(['project_address']);
  };

  const { user } = useAuth();
  const isEngagedStorage = user.company?.engaged_storage;
  const showEngagingBanner = !isEngagedStorage;

  return (
    <StyledForm
      name="storage-project-details"
      form={form}
      initialValues={{
        name: data.name || '',
        project_address: data.project_details.project_address || '',
        storage_arrival_date: dayjs(
          data.project_details.storage_arrival_date || ''
        ),
        target_ac_power_mw: data.project_details.target_ac_power_mw,
        power_factor: data.project_details.power_factor,
        duration_hours: data.project_details.duration_hours,
        metering_location: data.project_details.metering_location,
        project_topology: data.project_details.project_topology,
        capacity_maintenance_term:
          data.project_details.capacity_maintenance_term,
        capacity_maintenance_strategy:
          data.project_details.capacity_maintenance_strategy,
        annual_cycle_count: data.project_details.annual_cycle_count,
        anticipated_purchase_date: dayjs(
          data.project_details.anticipated_purchase_date || ''
        ),
        latitude: data.project_details.latitude,
        longitude: data.project_details.longitude,
        location_id: data.project_details.location_id,
        state: data.project_details.state,
      }}
      layout="vertical"
    >
      <Form.Item
        name="name"
        label={
          <GenericLabel
            testIdData={{
              component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
              identifier: 'project-name',
            }}
            title="Project Name"
            fontSize="14px"
          />
        }
        labelCol={{ span: 24 }}
        rules={PROJECT_INPUT_CONFIG.name.rules}
      >
        <Input
          {...PROJECT_INPUT_CONFIG.name.inputProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'project-name',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="project_address"
        label={
          <ProjectLocationLabel
            testIdData={{
              component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER,
            }}
            fontSize="14px"
          />
        }
        rules={PROJECT_INPUT_CONFIG.projectAddress.applyRules(
          isLoading,
          form,
          locationError
        )}
      >
        <AddressSearch
          disabled
          onSelectLocation={handleSelectLocation}
          isLocationLoading={setIsLoading}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.AUTOCOMPLETE}`,
            identifier: 'address-search',
          })}
          onValueChange={(value) => {
            if (value) {
              form.setFieldsValue({
                latitude: null,
                longitude: null,
                google_place_id: null,
              });
            }
          }}
        />
      </Form.Item>
      <StorageFormGroup form={form} isEditing />
      {[PROJECT_STATUSES.CREATED, PROJECT_STATUSES.PROPOSAL].includes(
        data.project_details.status
      ) && (
        <StyledCollapse
          defaultActiveKey={['1']}
          bordered={false}
          expandIconPosition="right"
          ghost
        >
          <StyledCollapsePanel header="Advanced Inputs" key="1">
            <AdvancedInputsBanner
              learnMoreUrl={getConfiguration('REACT_APP_CALENDLY_STORAGE_URL')}
              disable={!showEngagingBanner}
              projectType={ProjectTypes.STORAGE}
            />
            <StorageAdvacedInputsFormGroup
              defaultAdvancedInputs={defaultAdvancedInputs}
              disabledInputs={disabledAdvancedInputs}
              form={form}
            />
          </StyledCollapsePanel>
        </StyledCollapse>
      )}
      <Form.Item
        name="state"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="latitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="longitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="google_place_id"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
    </StyledForm>
  );
};

export default StorageProjectDrawerForm;
