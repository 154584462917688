import React from 'react';

import { Space } from '~/UI';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';
import {
  CELL_TECHNOLOGY_OPTIONS,
  DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS,
  MODULE_TAGS,
} from '~/constants/modules';
import ModuleTag from '~/components/ModuleTag';
import { FilterMenuOption } from '~/UI/Filters/FilterMenu';

type WattageRange = {
  [key: string]: {
    label: string;
    max: number;
    min: number;
  };
};

export const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.TEXT,
};

export const wattageRangeValues: WattageRange = {
  _600W_OR_HIGHER: { label: '600 W or higher', max: 100000, min: 600 },
  _500W_TO_600W: { label: '500 W - 599 W', max: 600, min: 500 },
  _499W_OR_LESS: { label: '499 W or less', max: 500, min: 0 },
};

export const wattageRangeOptions: FilterMenuOption[] = Object.keys(
  wattageRangeValues
).map((wattageKey, index) => ({
  label: wattageRangeValues[wattageKey].label,
  title: wattageRangeValues[wattageKey].label,
  value: wattageKey,
  dataTestId: generateTestId({
    ...dataTestIdConfig,
    component: TEST_DATA_COMPONENTS.CHECK_BOX,
    identifier: 'wattage-option',
    indices: index.toString(),
  }),
}));

export const tier1Options: FilterMenuOption[] = [
  {
    value: MODULE_TAGS.TIER_1.value,
    label: (
      <Space size="xs">
        Only show
        <ModuleTag
          size="md"
          icon={MODULE_TAGS.TIER_1.icon}
          label={`${MODULE_TAGS.TIER_1.label} Modules`}
          description={MODULE_TAGS.TIER_1.description}
        />
      </Space>
    ),
    title: 'Tier-1',
  },
];

export enum ModuleFiltersKeys {
  manufacturer = 'manufacturer',
  wattClassKw = 'wattClassKw',
  tier1Modules = 'tier1Modules',
  cellTechnology = 'cellTechnology',
  domesticContentOrAssembly = 'domesticContentOrAssembly',
}

export const domesticContentOrAssemblyOptions: FilterMenuOption[] =
  DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS.map((tag) => ({
    value: tag.value,
    title: tag.label,
    label: (
      <ModuleTag
        size="md"
        icon={tag.icon}
        label={tag.label}
        description={tag.description}
      />
    ),
  }));

export const cellTechOptions: FilterMenuOption[] = CELL_TECHNOLOGY_OPTIONS.map(
  (tag) => ({
    value: tag.value,
    title: tag.label,
    label: (
      <ModuleTag
        size="md"
        icon={tag.icon}
        label={tag.label}
        description={tag.description}
      />
    ),
  })
);

export const getManufacturerOptions = (
  manufacturers: string[]
): FilterMenuOption[] => {
  return manufacturers
    .map((manuf, i) => ({
      label: manuf,
      title: manuf,
      value: manuf,
      dataTestId: generateTestId({
        ...dataTestIdConfig,
        component: TEST_DATA_COMPONENTS.CHECK_BOX,
        identifier: 'manufacturer',
        indices: i.toString(),
      }),
    }))
    .sort((a, b) =>
      a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })
    );
};

export type ModuleFilters = {
  [ModuleFiltersKeys.manufacturer]?: string[];
  [ModuleFiltersKeys.wattClassKw]?: [string, string];
  [ModuleFiltersKeys.tier1Modules]?: string[];
  [ModuleFiltersKeys.cellTechnology]?: string[];
  [ModuleFiltersKeys.domesticContentOrAssembly]?: string[];
};
