import { getConfiguration } from '~/utils/configurations';
import { maybeGetAuth0RefreshToken } from '~/api/axios';
import { getActiveFlags } from '~/App';

const API_VERSION = '/v1';
export const API_VERSION_V2 = '/v2';
export const API_VERSION_V3 = '/v2';
export const API_URL = getConfiguration('REACT_APP_API_URL');

const prepareHeaders = async (headers: Headers) => {
  const activeFlags = getActiveFlags();
  const refreshedToken = await maybeGetAuth0RefreshToken();

  if (Object.keys(activeFlags).length > 0) {
    headers.set('X-LD-Flags', JSON.stringify(activeFlags));
  }

  if (refreshedToken) {
    headers.set('Authorization', `Bearer ${refreshedToken}`);
  }
  return headers;
};

// use this on the queries we want to send flags
export const prepareHeadersWithFlags = async (headers: Headers) => {
  const activeFlags = getActiveFlags();
  const refreshedToken = await maybeGetAuth0RefreshToken();

  if (Object.keys(activeFlags).length > 0) {
    headers.set('X-LD-Flags', JSON.stringify(activeFlags));
  }

  if (refreshedToken) {
    headers.set('Authorization', `Bearer ${refreshedToken}`);
  }

  return headers;
};

const baseUrl = `${API_URL}${API_VERSION}`;
const BASE_URL_V2 = `${API_URL}${API_VERSION_V2}`;
const BASE_URL_V3 = `${API_URL}${API_VERSION_V2}`;

export { prepareHeaders, baseUrl, BASE_URL_V2, BASE_URL_V3 };
