export const ROUTER_PATHS = {
  HOME: () => '/',
  LOGIN: () => '/login',
  ADMIN: () => '/admin',
  ADMIN_DASHBOARD: () => '/admin/dashboard',
  AUTH: () => '/auth',
  MARKETING_CUSTOMERS: () => '/customers',
  MARKETING_VENDORS: () => '/vendors',
  SETTINGS: () => '/settings',
  ERROR: () => '/error',
  PAGE_NOT_FOUND: () => '/404',
  SCHEDULING: () => `/schedule`,
  projects: {
    PROJECTS: () => '/projects',
    solar: {
      PROJECT_DETAILS: (uuid = ':uuid') => `/projects/solar/${uuid}`,
      PROJECT_DETAILS_DETAILED: (uuid = ':uuid') =>
        `/projects/solar/${uuid}/detailed`,
      PROJECT_DETAILS_EDIT: (uuid = ':uuid') => `/projects/solar/${uuid}/edit`,
    },
    storage: {
      PROJECT_DETAILS: (uuid = ':uuid') => `/projects/storage/${uuid}`,
      PROJECT_DETAILS_DETAILED: (uuid = ':uuid') =>
        `/projects/storage/${uuid}/detailed`,
    },
    // TODO: The following 2 Anza routes are meant only for allowing users to access projects when using old anza routes. Remove later.
    ANZA_MODULES: (id = ':id') => `/anza/modules/${id}`,
    ANZA_STORAGE: (id = ':id') => `/anza/storage/${id}`,
  },
  vendors: {
    OUR_MODULES: () => '/vendor/modules',
    PROJECT_PRICING: (vendorId = ':vendorId', projectId = ':projectId') =>
      `/vendor/${vendorId}/projects/${projectId}`,
    PIPELINE: () => '/vendor/overview',
    MODULES: () => '/vendor/modules',
    MARKET_INTELLIGENCE: () => '/vendor/market-intelligence',
  },
  modules: {
    MODULE_DETAILS: (uuid = ':uuid') => `/module/${uuid}`,
  },
  MAINTENANCE: () => '/maintenance',
  dashboards: {
    PRICING_HISTORY: () => '/pricing-history',
  },
};
