import React from 'react';
import styled from '@emotion/styled';

import { IconClose } from '~/UI/Icons';
import { ModalAnt as Modal, Typography } from '~/UI';
import { theme } from '~/utils';
import {
  TestIdProps,
  generateTestId,
  TEST_DATA_COMPONENTS,
} from '~/utils/dataTestProps';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 16px 24px;
    }
    .ant-modal-footer {
      padding-top: 0;
    }
  }
`;

interface ModalTitleProps {
  title?: React.ReactNode;
  description?: React.ReactNode;
  testIdData?: TestIdProps;
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  title,
  description,
  testIdData,
}) => (
  <div>
    <Typography.GenericText
      color={theme.colors.charcoal}
      size="24px"
      weight={700}
      style={{ marginBottom: '12px', maxWidth: '93%' }}
      data-testid={generateTestId({
        component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.TEXT}`,
        identifier: 'title',
      })}
    >
      {title}
    </Typography.GenericText>
    <Typography.GenericText
      color={theme.colors.charcoal}
      size="16px"
      weight={400}
      data-testid={generateTestId({
        component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.TEXT}`,
        identifier: 'description',
      })}
    >
      {description}
    </Typography.GenericText>
  </div>
);

interface ProjectModalProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  onClose?: () => void;
  onOk?: () => void;
  okText?: string;
  visible: boolean;
  actions?: React.ReactNode[];
  testIdData?: TestIdProps;
}

const ProjectModal: React.FC<ProjectModalProps> = ({
  children,
  description,
  okText,
  onClose,
  onOk,
  title,
  visible,
  actions = [],
  testIdData,
}) => {
  return (
    <StyledModal
      data-testid={generateTestId({
        component: TEST_DATA_COMPONENTS.MODAL,
      })}
      title={
        <ModalTitle
          title={title}
          description={description}
          testIdData={testIdData}
        />
      }
      open={visible}
      onCancel={onClose}
      onOk={onOk}
      okText={okText}
      width="928px"
      footer={actions}
      closeIcon={
        <IconClose
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.BUTTON}`,
            identifier: 'close-modal',
          })}
          fontSize={24}
        />
      }
    >
      {children}
    </StyledModal>
  );
};

export default ProjectModal;
