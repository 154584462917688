import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { AutoCompleteProps } from 'antd';
import { Autocomplete, Space } from '~/UI';
import { useLazyGetProjectsBySalesforceOpportunityIdQuery } from '~/store/api/projects/projectsApi';
import { BaseProject } from '~/store/project';
import { Label } from '~/UI/Typography';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { nameSorter } from '~/utils';

const StyledLink = styled(Link)`
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

const ProjectLabel = styled.span`
  font-size: 12px;
`;

type Props = AutoCompleteProps & {
  initialValue?: string;
};

const AutocompleteProjectBySalesforceOpportunity = ({
  initialValue,
  ...props
}: Props) => {
  const [trigger] = useLazyGetProjectsBySalesforceOpportunityIdQuery();
  const [currentOpportunity, setCurrentOpportunity] = useState<string>();
  const [initialValueLoaded, setInitialValueLoaded] = useState<boolean>(false);
  const [options, setOptions] = useState<
    { projects: BaseProject[]; sf_opportunity_id: string }[]
  >([]);

  const getOpportunitiesOptions = async (sf_opportunity_id: string) => {
    const { data } = await trigger(sf_opportunity_id);
    const projects = data || [];
    const opportunities = R.groupBy(
      (p) => p.sf_opportunity_id?.toString() || '',
      projects
    );
    const options: {
      projects: BaseProject[];
      sf_opportunity_id: string;
    }[] = [];
    R.mapObjIndexed((projects, sf_opportunity_id) => {
      options.push({
        projects,
        sf_opportunity_id,
      });
    }, opportunities);
    return options;
  };

  const loadOpportunities = async (sf_opportunity_id: string) => {
    setCurrentOpportunity(sf_opportunity_id);
    if (!sf_opportunity_id) {
      return setOptions([]);
    }
    const options = await getOpportunitiesOptions(sf_opportunity_id);
    return setOptions(options);
  };

  useEffect(() => {
    const setValue = async () => {
      if (initialValue && !initialValueLoaded) {
        loadOpportunities(initialValue);
        setInitialValueLoaded(true);
      }
    };
    setValue();
  }, [initialValue]);

  const handleSearch = useCallback(
    debounce(async (sf_opportunity_id: string) => {
      loadOpportunities(sf_opportunity_id);
    }, 300),
    []
  );

  const getOpportunityLabel = (opportunity: string) => {
    if (!currentOpportunity) {
      return <span>{opportunity}</span>;
    }
    const splitted = opportunity.split(currentOpportunity);
    const opportunityArray: React.ReactNode[] = [];
    if (R.isEmpty(splitted)) {
      opportunityArray.push(<strong>{currentOpportunity}</strong>);
    } else {
      splitted.forEach((string, i) => {
        if (i !== 0 && i - 1 !== splitted.length) {
          // last item
          opportunityArray.push(<strong>{currentOpportunity}</strong>);
        }
        opportunityArray.push(string);
      });
    }
    return <span>{opportunityArray}</span>;
  };

  const matchedProjects = options
    .filter(({ sf_opportunity_id }) => sf_opportunity_id === currentOpportunity)
    .map((opt) => opt.projects)
    .flat()
    .sort(nameSorter);

  return (
    <>
      <Autocomplete
        options={options.map(({ sf_opportunity_id, projects }) => {
          return {
            label: (
              <div>
                <Space size="small" direction="vertical">
                  <Label>{getOpportunityLabel(sf_opportunity_id)}</Label>
                  {projects.sort(nameSorter).map(({ name, uuid }) => (
                    <ProjectLabel key={uuid}>{name}</ProjectLabel>
                  ))}
                </Space>
              </div>
            ),
            value: sf_opportunity_id,
          };
        })}
        onSearch={handleSearch}
        onSelect={loadOpportunities}
        {...props}
      />
      <Space size="small" direction="vertical">
        {matchedProjects.map(({ name, uuid }) => {
          return (
            <StyledLink
              key={uuid}
              onClick={(e) => e.stopPropagation()}
              to={ADMIN_ROUTES.ANZA_PROJECTS.path.edit(uuid)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </StyledLink>
          );
        })}
      </Space>
    </>
  );
};

export default AutocompleteProjectBySalesforceOpportunity;
