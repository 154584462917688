import React from 'react';

import styled from '@emotion/styled';
import { maintenanceIcon } from '~/assets';
import { BoxTitle, H1 } from '~/UI/Typography';
import { theme } from '~/utils';
import { Space } from '~/UI';

const StyledSpace = styled(Space)`
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
`;

const MaintenancePage = () => {
  return (
    <StyledSpace size="2xl" direction="vertical">
      <img width={120} height={120} src={maintenanceIcon} alt="MAINTENANCE" />
      <Space direction="vertical" size="xl" align="center">
        <H1 style={{ color: theme.colors.primary, whiteSpace: 'pre-wrap' }}>
          {'Scheduled\nMaintenance'}
        </H1>
        <BoxTitle>
          We’re busy making Anza even better. Please check back later.
        </BoxTitle>
      </Space>
    </StyledSpace>
  );
};

export default MaintenancePage;
