import React from 'react';

import { ChangeMetaTitle } from '~/utils/changeMetaTitle';

interface PageProps {
  title?: string;
}

const Page: React.FC<PageProps> = ({ children, title }) => {
  React.useEffect(() => {
    if (title) {
      ChangeMetaTitle(title);
    }
  }, [title]);

  return <>{children}</>;
};

export default Page;
