import * as R from 'ramda';
import {
  PipelineData,
  PipelineDataStruct,
  PipelineDataValues,
  PipelineDataSet,
  ChartDataStructureFromAPIModel,
  PipelineDataObject,
} from '~/services/api/anza';
import { RACKING_TYPE } from '~/utils/rackingTypes';
import { getQuarterRangeFromToday } from '~/utils/quarter-utils';
import { XAxisGrouping } from '~/UI/Metrics/ResponsiveBarChart';
import { MODULE_MAX_QUARTER_DATA_SHOWN } from '~/constants/modules';

export enum DATA_SET {
  DELIVERY_DATE = 'byDeliveryDate',
  REGIONS = 'byRegion',
  RACKING_TYPES = 'byRackingType',
  RANK = 'byRank',
  PROJECT_SIZE = 'byProjectSize',
}

const rankOrdering = ['1', '2-5', '6-10', '>10'];
// we don't use the enum for SAT because the display value is different than the actual data.
const rackingTypeOrdering = [RACKING_TYPE.FIXED_TILT, 'SAT'];
const projectSizeOrdering = [
  '<10 MW',
  '10-25 MW',
  '25-50 MW',
  '50-100 MW',
  '>100 MW',
];

const sortBySpecifiedOrder = (
  itemsToSort: Array<PipelineData>,
  specifiedOrder: Array<string>
) => {
  return R.sort((a: PipelineData, b: PipelineData) => {
    return R.indexOf(a.id, specifiedOrder) - R.indexOf(b.id, specifiedOrder);
  }, itemsToSort);
};

const sortByYearThenQuarter = (itemsToSort: Array<PipelineData>) => {
  return R.sort((a: PipelineData, b: PipelineData) => {
    // Split the quarter, year strings and reverse the resulting arrays so we can sort by year, then quarter.
    const yearThenQuarterA = R.reverse(R.split('|', a.id as string));
    const yearThenQuarterB = R.reverse(R.split('|', b.id as string));

    return `${yearThenQuarterA[0]} ${yearThenQuarterA[1]}` >
      `${yearThenQuarterB[0]} ${yearThenQuarterB[1]}`
      ? 1
      : -1;
  }, itemsToSort);
};

const sortDatasetByKey = (
  datasetToSort: Array<PipelineData>,
  datasetName: string
) => {
  switch (datasetName) {
    case DATA_SET.DELIVERY_DATE: {
      const sortedDeliveryDateData = sortByYearThenQuarter(datasetToSort);
      sortedDeliveryDateData.splice(MODULE_MAX_QUARTER_DATA_SHOWN);
      return sortedDeliveryDateData;
    }
    case DATA_SET.REGIONS:
      return datasetToSort;
    case DATA_SET.RACKING_TYPES:
      return sortBySpecifiedOrder(datasetToSort, rackingTypeOrdering);
    case DATA_SET.RANK:
      return sortBySpecifiedOrder(datasetToSort, rankOrdering);
    case DATA_SET.PROJECT_SIZE:
      return sortBySpecifiedOrder(datasetToSort, projectSizeOrdering);
    default:
      return datasetToSort;
  }
};

const formatRackingTypeKey = (keyToFormat: string) => {
  return keyToFormat === RACKING_TYPE.SINGLE_AXIS ? 'SAT' : keyToFormat;
};

const formatDeliveryDateKey = (keyToFormat: string) => {
  const quarterThenYear = R.split(' ', keyToFormat);

  return `${quarterThenYear[0]}|${R.slice(
    2,
    quarterThenYear[1].length,
    quarterThenYear[1]
  )}`;
};

const formatKeyForDataset = (keyToFormat: string, datasetName: string) => {
  switch (datasetName) {
    case DATA_SET.DELIVERY_DATE:
      return formatDeliveryDateKey(keyToFormat);
    case DATA_SET.RACKING_TYPES:
      return formatRackingTypeKey(keyToFormat);
    default:
      return keyToFormat;
  }
};

const createEmptyData = (): PipelineDataValues => {
  return { totalMw: 0, count: 0 };
};

const addEmptyDatasets = (
  expectedDataKeys: string[],
  dataset: PipelineDataSet
) => {
  const fulldataset = { ...dataset };

  Object.values(expectedDataKeys).forEach((datakey) => {
    if (R.isNil(dataset[datakey])) {
      fulldataset[datakey] = createEmptyData();
    }
  });

  return fulldataset;
};

const populateEmptyData = (datasetName: string, dataset: PipelineDataSet) => {
  switch (datasetName) {
    case DATA_SET.DELIVERY_DATE:
      return addEmptyDatasets(
        getQuarterRangeFromToday(MODULE_MAX_QUARTER_DATA_SHOWN).map(
          (quarter) => quarter.label
        ),
        dataset
      );
    case DATA_SET.REGIONS:
      return dataset;
    case DATA_SET.RACKING_TYPES:
      return addEmptyDatasets(rackingTypeOrdering, dataset);
    case DATA_SET.RANK:
      return addEmptyDatasets(rankOrdering, dataset);
    case DATA_SET.PROJECT_SIZE:
      return addEmptyDatasets(projectSizeOrdering, dataset);
    default:
      return dataset;
  }
};

// The API only returns groups which contain data. For example, if Q2 2024 has 0 projects, it will not be included in the API response.
// This function ensures the user will always see the same values on the y-axes of our charts
const ensureAllDataSetKeys = (
  datasetName: string,
  dataset: string | number | PipelineDataSet
) => {
  const isDataSetString = typeof dataset === 'string';
  const isDataSetNumber = typeof dataset === 'number';
  const isDataSetEmpty = R.isEmpty(Object.entries(dataset));
  if (isDataSetString || isDataSetNumber || isDataSetEmpty) {
    return dataset;
  }

  return populateEmptyData(datasetName, dataset);
};

const buildChartDataStructureFromAPIModel = (
  datasetName: string,
  dataSet: string | number | PipelineDataSet
) => {
  const chartDataStructure: ChartDataStructureFromAPIModel[] = [];

  Object.entries(dataSet).forEach(([key, val]) => {
    chartDataStructure.push({
      id: formatKeyForDataset(key, datasetName),
      label: key,
      value: val.totalMw,
      count: val.count,
    });
  });

  return chartDataStructure;
};

export const getSortOrderValue = (sortValue: string | null) => {
  switch (sortValue) {
    case 'ascend':
    case 'descend':
      return sortValue;
    default:
      return null;
  }
};

export const processMetricData = (keys: Array<string>, data: PipelineData) => {
  const response: PipelineDataStruct = {};
  keys.forEach((datasetName) => {
    response[datasetName] = [];

    // This is here in case the API doesn't return data for one of our aggregations such as byRank, byProjectSize, etc
    let dataSet = data[datasetName];
    if (!dataSet) {
      return;
    }

    dataSet = ensureAllDataSetKeys(datasetName, dataSet);

    response[datasetName] = buildChartDataStructureFromAPIModel(
      datasetName,
      dataSet
    );

    response[datasetName] = sortDatasetByKey(
      response[datasetName] as unknown as PipelineData[],
      datasetName
    ) as unknown as PipelineDataObject[];
  });

  return response;
};

export const getXAxisGroupingsFromDeliveryDateData = (
  data: ChartDataStructureFromAPIModel[]
) => {
  const groupings: { [key: string]: string[] } = {};

  Object.values(data).forEach((datum) => {
    // Split and reverse it so we have the year first then the quarter.
    const yearThenQuarter = R.reverse(R.split(' ', datum.label as string));
    const year = yearThenQuarter[0];
    const quarter = yearThenQuarter[1];

    if (R.isNil(groupings[year])) {
      groupings[year] = [quarter];
    } else {
      groupings[year] = [...groupings[year], quarter];
    }
  });

  const axisGroupings: XAxisGrouping[] = [];

  Object.entries(groupings).forEach(([key, val]) => {
    axisGroupings.push({
      group: key,
      children: val,
    });
  });

  const axisGroupingSorter = R.sortBy(R.prop('group'));

  return axisGroupingSorter(axisGroupings);
};

export const getEmptyDataSet = (datasetName: string) => {
  return buildChartDataStructureFromAPIModel(
    datasetName,
    populateEmptyData(datasetName, {})
  );
};

export const convertMwToGw = (
  pipelineSizeMw: number | string | PipelineDataSet
) => {
  if (R.isNil(pipelineSizeMw) || R.isEmpty(pipelineSizeMw)) {
    return null;
  }

  const sizeMw = Number(pipelineSizeMw);
  return sizeMw / 1000;
};

export const convertKwToMw = (mw: number) => mw / 1000;
