import React from 'react';

const MapMarker = ({
  radius,
  color,
  className,
}: {
  radius: number;
  color: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      viewBox="-20 -20 40 40"
      xmlns="http://www.w3.org/2000/svg"
      height={radius}
      width={radius}
    >
      <circle
        cx="0"
        cy="0"
        r={10}
        strokeWidth={20}
        strokeOpacity={0.3}
        stroke={color}
        fill={color}
      />
    </svg>
  );
};

export default MapMarker;
