import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import { ButtonAnt as Button } from '~/UI';
import { IconClose, IconEdit, IconView } from '~/UI/Icons';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';

const dataTestIdConfig: TestIdProps = {
  component: TEST_DATA_COMPONENTS.BUTTON,
};

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0.25rem;
  }
`;

enum CustomActionType {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}
export type CustomAction<RecordType> = {
  visible?: boolean;
  onClick?: (data: RecordType) => void;
  onHref?: (record: RecordType) => string;
};

export type CustomActions<RecordType> = {
  [CustomActionType.VIEW]?: CustomAction<RecordType> | undefined | null;
  [CustomActionType.EDIT]?: CustomAction<RecordType> | undefined | null;
  [CustomActionType.DELETE]?:
    | Omit<CustomAction<RecordType>, 'onHref'>
    | undefined
    | null;
};

const isActionVisible = <T,>(action: CustomAction<T> | null | undefined) =>
  action && (R.isNil(action.visible) || action.visible);

const AdminTableActionColumn = <T extends unknown>({
  customActions,
  onDelete,
  testIdPage = '',
  testIdCategory = '',
  defaultRowKey = 'id',
}: {
  customActions?: CustomActions<T>;
  onDelete: (id: number | string) => void;
  testIdPage?: string;
  testIdCategory?: string;
  defaultRowKey?: string;
}) => {
  dataTestIdConfig.category = testIdCategory;
  dataTestIdConfig.page = testIdPage;

  const renderActions = (id: number | string, record: T, index: number) => {
    dataTestIdConfig.indices = index.toString();

    const viewAction = customActions?.view;
    const editAction = customActions?.edit;
    const deleteAction = customActions?.delete;

    const viewButton = (
      <Button
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'view',
        })}
        size="small"
        onClick={() => viewAction?.onClick?.(record)}
      >
        <IconView />
      </Button>
    );
    const viewHref = viewAction?.onHref && viewAction?.onHref(record);
    const viewComponent = viewHref ? (
      <Link to={viewHref} onClick={(e) => e.stopPropagation()}>
        {viewButton}
      </Link>
    ) : (
      viewButton
    );

    const editButton = (
      <Button
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'edit',
        })}
        size="small"
        type="primary"
        onClick={() => editAction?.onClick?.(record)}
      >
        <IconEdit />
      </Button>
    );
    const editHref = editAction?.onHref && editAction?.onHref(record);
    const editComponent = editHref ? (
      <Link to={editHref} onClick={(e) => e.stopPropagation()}>
        {editButton}
      </Link>
    ) : (
      editButton
    );

    const deleteButton = (
      <Button
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'delete',
        })}
        size="small"
        danger
        onClick={(e) => {
          e.stopPropagation();
          deleteAction?.onClick?.(record);
          return onDelete(id);
        }}
      >
        <IconClose />
      </Button>
    );

    return (
      <ActionButtonsContainer>
        {isActionVisible(viewAction) && viewComponent}
        {isActionVisible(editAction) && editComponent}
        {isActionVisible(deleteAction) && deleteButton}
      </ActionButtonsContainer>
    );
  };

  const actionColumnWidth = R.reduce(
    (acc, action) => (isActionVisible(action) ? acc + 50 : acc),
    20,
    R.values(customActions || {})
  );

  return {
    title: '',
    dataIndex: defaultRowKey,
    key: defaultRowKey,
    render: renderActions,
    fixed: 'right',
    width: actionColumnWidth,
  };
};

export default AdminTableActionColumn;
