import React from 'react';
import styled from '@emotion/styled';
import Popover from '~/UI/Popover';

import { Typography } from '~/UI';
import { theme } from '~/utils';
import { infoFilled } from '~/assets';
import {
  TestIdProps,
  generateTestId,
  TEST_DATA_COMPONENTS,
} from '~/utils/dataTestProps';

const PopoverContent = styled.div`
  width: 396px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 1rem;

  & input,
  & span {
    font-size: 14px;
  }
`;

type GenericLabelProps = {
  title: string;
  popoverTitle?: string;
  showPopoverTitle?: boolean;
  popoverContent?: string;
  fontSize?: string;
  testIdData?: TestIdProps;
};

export const GenericLabel = ({
  title,
  popoverTitle,
  showPopoverTitle = true,
  popoverContent,
  fontSize = '12px',
  testIdData,
}: GenericLabelProps) => {
  const popoverTitleText = popoverTitle || title;
  return (
    <Typography.GenericText
      color={theme.colors.charcoal}
      size={fontSize}
      weight={700}
      data-testid={generateTestId({
        component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.LABEL}`,
        identifier: testIdData?.identifier,
      })}
    >
      {!popoverContent ? (
        title
      ) : (
        <Popover
          title={showPopoverTitle ? popoverTitleText : undefined}
          content={<PopoverContent>{popoverContent}</PopoverContent>}
          placement="bottomLeft"
          trigger="hover"
          zIndex={9999}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              style={{
                cursor: 'default',
                whiteSpace: 'normal',
              }}
            >
              {title}
              <sup
                style={{
                  left: 1,
                  fontSize: 9,
                  color: theme.colors.primary,
                }}
              >
                <img src={infoFilled} alt={title} />
              </sup>
            </div>
          </div>
        </Popover>
      )}
    </Typography.GenericText>
  );
};

export const ProjectLocationLabel = ({
  title = 'Project Location',
  showPopoverTitle = false,
  popoverContent = 'Enter a site location (Address or City, State). The Anza app currently only supports projects in the contiguous United States.',
  fontSize,
  testIdData,
}: {
  title?: string;
  showPopoverTitle?: boolean;
  popoverContent?: string;
  fontSize?: string;
  testIdData?: TestIdProps;
}) => (
  <GenericLabel
    title={title}
    showPopoverTitle={showPopoverTitle}
    popoverContent={popoverContent}
    fontSize={fontSize}
    testIdData={
      { ...testIdData, identifier: 'project-location' } as TestIdProps
    }
  />
);

const FormGroup: React.FC = ({ children }) => {
  return <Grid>{children}</Grid>;
};

export default FormGroup;
