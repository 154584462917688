import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { Spinner, Typography } from '~/UI';
import {
  convertLabelIntoQuarterAndYear,
  isQuarterEqualOrGraterThanCurrent,
} from '~/utils/quarter-utils';
import BigValueLabel from '~/UI/Metrics/BigValueLabel';
import ResponsiveBarChart, {
  quarterYearTickFormatter,
} from '~/UI/Metrics/ResponsiveBarChart';
import {
  getXAxisGroupingsFromDeliveryDateData,
  convertMwToGw,
} from '~/utils/vendorDataProcessor';
import { Box } from '~/components/Modules';
import { decimalFormatter, decimalFormatterTens, theme } from '~/utils';
import { ChartDataStructureFromAPIModel } from '~/services/api/anza';
import { DashboardInitialState } from './DashboardView';

export interface PipelineProps {
  loading: boolean;
  allTimePipelineSize: string;
  activePipelineSize: string;
  activeNumberOfProjects: string;
  pipelineMetricsState: DashboardInitialState;
  emptyDeliveryDateDataSet: ChartDataStructureFromAPIModel[];
}

const PipelineContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 8px;
`;

const numberMetricContainerStyles: React.CSSProperties = {
  width: '100%',
  gridColumn: '1/3',
};

const numberMetricsStyles: React.CSSProperties = {
  padding: '42px 30px',
  borderRight: theme.borders.lightGray,
};

const spinnerStyles: React.CSSProperties = { margin: '50px auto' };

const Pipeline: React.FC<PipelineProps> = ({
  loading,
  allTimePipelineSize,
  activePipelineSize,
  activeNumberOfProjects,
  pipelineMetricsState,
  emptyDeliveryDateDataSet,
}) => {
  const gwChartFormatter = (pipelineSizeMw: number | null) => {
    return R.isNil(pipelineSizeMw) || R.isEmpty(pipelineSizeMw)
      ? 0
      : decimalFormatterTens(convertMwToGw(pipelineSizeMw) as number);
  };

  const chartLabelFormatter = (columnData: {
    data: { value: number | null };
  }) => {
    return gwChartFormatter(columnData.data.value);
  };

  const quartersDataToDisplay = pipelineMetricsState.byDeliveryDate.filter(
    ({ label }: { label: string }) => {
      const { year, quarter } = convertLabelIntoQuarterAndYear(label);
      return isQuarterEqualOrGraterThanCurrent(quarter, year);
    }
  );

  return (
    <PipelineContainer style={numberMetricContainerStyles}>
      {loading ? (
        <div
          style={{
            minHeight: '266px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner style={spinnerStyles} />
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <div
            style={{
              flex: 3,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                borderBottom: theme.borders.lightGray,
                borderRight: theme.borders.lightGray,
                padding: '32px 30px',
              }}
            >
              <Typography.VendorSectionHeader>
                Anza Pipeline
              </Typography.VendorSectionHeader>
            </div>
            <div style={{ display: 'flex', height: '100%' }}>
              <BigValueLabel
                title="All-Time Pipeline in GW"
                value={decimalFormatter(Number(allTimePipelineSize), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                containerStyle={numberMetricsStyles}
                valueStyle={{ fontSize: 48 }}
              />
              <BigValueLabel
                title="Active Pipeline in GW"
                value={decimalFormatter(Number(activePipelineSize), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                containerStyle={numberMetricsStyles}
                valueStyle={{ fontSize: 48 }}
              />
              <BigValueLabel
                title="Active Projects"
                value={activeNumberOfProjects}
                containerStyle={numberMetricsStyles}
                valueStyle={{ fontSize: 48 }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              padding: '32px 20px 0px',
              height: '230px',
              maxWidth: '500px',
            }}
          >
            <ResponsiveBarChart
              title="Delivery Forecast - GW per Quarter"
              data={quartersDataToDisplay}
              keys={['value']}
              style={{ width: '100%' }}
              tooltipConfig={{
                leftValuePath: ['count'],
                rightValuePath: ['value'],
                leftLabel: 'Projects',
                rightLabel: 'GW',
              }}
              valueFormat={gwChartFormatter}
              label={chartLabelFormatter}
              axisBottomTickFormat={quarterYearTickFormatter}
              emptyDataSet={emptyDeliveryDateDataSet}
              customXAxis={getXAxisGroupingsFromDeliveryDateData(
                R.isNil(quartersDataToDisplay) ||
                  R.isEmpty(quartersDataToDisplay)
                  ? emptyDeliveryDateDataSet
                  : quartersDataToDisplay
              )}
            />
          </div>
        </div>
      )}
    </PipelineContainer>
  );
};

export default Pipeline;
