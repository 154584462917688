import React from 'react';

import { Row } from '~/UI';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';

import AdminTable from '../AdminTable/AdminTable';

const AdminPcsList: React.FC<AdminListPageProps> = ({ service, pageName }) => {
  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'PCS Name',
      dataIndex: 'pcs_name',
      key: 'pcs_name',
    },
  ];
  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
      </Row>
      <AdminTable service={service} columns={columns as any} />
    </div>
  );
};

export default AdminPcsList;
