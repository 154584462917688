import styled from '@emotion/styled';

const Content = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  text-align: center;
  padding: 4rem 15px;
  justify-content: space-around;
  align-items: flex-start;
`;

export default Content;
