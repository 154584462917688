import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getConfiguration } from './utils/configurations';

Sentry.init({
  dsn: getConfiguration('REACT_APP_SENTRY_DSN'),
  environment: getConfiguration('REACT_APP_DEPLOYMENT_ENV'),
  release: getConfiguration('REACT_APP_BUILD_VERSION'),
  integrations: [],
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: getConfiguration('REACT_APP_LAUNCH_DARKLY_KEY') ?? '',
    options: {
      streaming: false,
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
