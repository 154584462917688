import React from 'react';

import styled from '@emotion/styled';
import { TagAnt } from '~/UI';
import { IconChevronDown } from '~/UI/Icons';
import { colors } from '~/utils';

const StyledTagAnt = styled(TagAnt)`
  height: 40px;
  gap: 4px;
  border: solid 1px ${colors.primaryLight300};
  padding: 10px 8px 10px 12px;
`;

export type IconPosition = 'left' | 'right';

export interface FilterTagProps {
  label: string | number | boolean | JSX.Element;
  color?: string;
  /** Set the icon for the tag. If null then no Icon is shown */
  icon?: React.ReactNode;
  onClick?: () => void;
  children?: React.ReactNode;
  iconPosition?: IconPosition;
}

const FilterTag = ({
  label,
  color = 'primary',
  icon,
  onClick,
  children,
  iconPosition = 'right',
}: FilterTagProps) => {
  const IconComponent =
    icon === undefined ? <IconChevronDown size={20} /> : icon;

  return (
    <StyledTagAnt
      onClick={() => {
        if (onClick) onClick();
      }}
      color={color}
    >
      {iconPosition === 'left' && IconComponent}
      {label}
      {iconPosition === 'right' && IconComponent}
      {children}
    </StyledTagAnt>
  );
};

export default FilterTag;
