import styled from '@emotion/styled';
import { theme } from '~/utils';

const Padder = styled.div`
  @media (max-width: ${theme.dimensions.width.min + 80}px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
`;

export default Padder;
