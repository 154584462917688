import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';

import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import {
  DatePickerConfig,
  InputConfig,
  InputNumberConfig,
  SelectConfig,
} from '~/types/inputs';
import {
  PercentSettings,
  getAnticipatedPurchaseDate,
  requiredRule,
} from '~/utils';
import {
  inRangeValidator,
  inRangeOrEmptyValidator,
} from '~/utils/numberValidators';
import { Formatter } from '~/components/Admin/LocationModifiers/AdminLocationModifiersForm';

const name: InputConfig = {
  rules: [{ required: true, message: 'Name is required' }],
  inputProps: {
    placeholder: 'Enter project name',
    maxLength: 255,
  },
};

const projectAddress = {
  applyRules: (
    isLoading: boolean,
    form: FormInstance,
    locationError?: string
  ) => {
    return [
      {
        required: true,
        validator: () => {
          if (isLoading) {
            return Promise.resolve();
          }

          if (locationError) {
            return Promise.reject(new Error(locationError));
          }

          const { latitude, longitude, project_address } =
            form.getFieldsValue(true);

          if (!!latitude && !!longitude && !!project_address) {
            return Promise.resolve();
          }

          const errorMessage = project_address
            ? 'Please select an address from the list'
            : 'Invalid Address';

          return Promise.reject(new Error(errorMessage));
        },
      },
    ];
  },
};

const status: SelectConfig = {
  rules: [requiredRule],
  selectProps: {
    placeholder: 'Select a status',
  },
};

const projectType: {
  rules: Rule[];
} = {
  rules: [requiredRule],
};

const arrivalDate: DatePickerConfig = {
  rules: [{ required: true, message: 'Delivery date is required' }],
  datePickerProps: {
    picker: 'month',
    placeholder: 'Select date',
    format: 'MMM YYYY',
  },
};

const startYearET: DatePickerConfig = {
  rules: [{ required: true, message: 'Start Year is required' }],
  datePickerProps: {
    picker: 'year',
    placeholder: 'Select year',
    format: 'YYYY',
  },
};

const anticipatedPurchaseDate = {
  applyRules: (getCurrentArrivalDate: () => Dayjs) => {
    return [
      {
        required: true,
        validator: (_: unknown, value: Dayjs) => {
          if (!value) {
            return Promise.reject(
              new Error('Anticipated Purchase Date is required')
            );
          }
          const arrivalDate = getCurrentArrivalDate();
          if (!arrivalDate) {
            return Promise.resolve();
          }
          if (
            value
              .startOf('month')
              .diff(arrivalDate.endOf('month'), 'month', true) > 0
          ) {
            return Promise.reject(
              new Error(
                'Anticipated Purchase Date cannot be past Delivery Date'
              )
            );
          }

          return Promise.resolve();
        },
      },
    ];
  },
  applyDatePickerProps: (
    currentArrivalDate: () => Dayjs
  ): PickerProps<Dayjs> => {
    return {
      disabledDate: (current) =>
        getAnticipatedPurchaseDate(current, currentArrivalDate()),
      picker: 'month',
      placeholder: 'Select date',
      format: 'MMM YYYY',
    };
  },
};

const PROJECT_INPUT_CONFIG = {
  name,
  projectAddress,
  status,
  projectType,
  arrivalDate,
  anticipatedPurchaseDate,
  startYearET,
};

export default PROJECT_INPUT_CONFIG;
