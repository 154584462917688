import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { FileTypes } from '~/types/file';
import { ModuleResponse } from '~/types/rtkApi';
import { ModuleType } from '~/types/modules';

export const modulesApi = createApi({
  reducerPath: 'modulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['Module', 'ModuleFile'],
  endpoints: (builder) => ({
    // 👇 Query: Get Singler Module
    getModule: builder.query<ModuleType, { uuid: string }>({
      query: (args) => ({
        url: routes.anza.module.getModule(args.uuid),
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => [{ type: 'Module', id: 'module' }],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: ModuleResponse) => response.data,
    }),
    getModuleFile: builder.query<
      { data: { presignedUrl: string } },
      { moduleUuid: string; fileUuid: string; fileType?: FileTypes }
    >({
      query: ({ moduleUuid, fileUuid, fileType }) => ({
        url: routes.anza.module.downloadFile(moduleUuid, fileUuid, fileType),
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => [{ type: 'ModuleFile', id: 'moduleFile' }],
    }),
  }),
});

export const { useGetModuleQuery, useLazyGetModuleFileQuery } = modulesApi;
