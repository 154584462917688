import { DDP_EAST_USD_PER_W_MIN } from '~/constants/projects';
import { InputNumberConfig } from '~/types/inputs';
import { inRangeValidator } from '~/utils/numberValidators';

const ddp_east_USD_per_W: InputNumberConfig = {
  rules: [
    inRangeValidator(
      DDP_EAST_USD_PER_W_MIN.min,
      DDP_EAST_USD_PER_W_MIN.max,
      true,
      `Introduce a value between ${DDP_EAST_USD_PER_W_MIN.min} and ${DDP_EAST_USD_PER_W_MIN.max}`,
      false
    ),
  ],
  inputNumberProps: {
    name: 'ddp_east_USD_per_W',
    precision: 3,
    suffix: '$/W',
  },
};

const PSP_INPUT_CONFIG = { ddp_east_USD_per_W };

export default PSP_INPUT_CONFIG;
