import styled from '@emotion/styled';
import Button from './ButtonAnt';
import { theme } from '../utils';

export default styled(Button)`
  border-radius: 50%;
  border: 1px solid ${theme.colors.sterling};
  background-color: ${theme.colors.sterling};
  color: ${theme.colors.charcoal};
  width: 28px;
  height: 28px;
  padding: 0;
`;
