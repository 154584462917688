import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { theme } from '~/utils';

const StyledLink = styled(Link)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: ${theme.colors.primary} !important;
  display: flex;
  position: relative;
  vertical-align: middle;
  align-items: center;
  font-size: inherit;
  display: inline-block;

  &:hover {
    color: ${theme.colors.charcoal};
    text-decoration: underline;
  }
`;

export interface ModuleLinkProps {
  path: string;
  moduleName: string;
}

const ModuleLink: React.FC<ModuleLinkProps> = ({
  path,
  moduleName,
  ...rest
}) => {
  return (
    <StyledLink to={path} {...rest}>
      {moduleName}
    </StyledLink>
  );
};

export default ModuleLink;
