import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { BASE_URL_V2, prepareHeaders } from '../settings';

export const adminProjectsApi = createApi({
  reducerPath: 'adminProjectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  tagTypes: ['AdminGetProjects'],
  endpoints: (builder) => ({
    unapprovedStorageProject: builder.mutation({
      query: ({ id }) => ({
        url: routes.admin.unapprovedStorageProject(id),
        method: 'PUT',
      }),
    }),
    approvedStorageProject: builder.mutation({
      query: ({ id }) => ({
        url: routes.admin.approvedStorageProject(id),
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useUnapprovedStorageProjectMutation,
  useApprovedStorageProjectMutation,
} = adminProjectsApi;
