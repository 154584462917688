import React from 'react';
import type { ReactNode } from 'react';
import { CSVLink } from 'react-csv';
import { isEmpty, mapObjIndexed, values, isNil, filter } from 'ramda';
import { SolarRankingData } from '~/store/project';
import {
  calculateCostSavings,
  calculateEpcPrice,
  calculateNvpDelta,
  calculateRevenueDelta,
  calculateRevenueDeltaPerWatt,
  calculateYieldDelta,
} from '~/utils/solarRankingTableCalculus';
import { EngineResultSizingArchitectureRow } from '~/components/Projects/storage/StorageModuleTable';
import {
  calcualteAugmentationCapex,
  calcualteBolDcBlock,
  calcualteCapex,
  calcualtePreventativeMaintenance,
  calcualteProductAndCapacityWarranty,
  calculateAcUsableEnergy,
  calculateAddedDcBlocks,
  calculatePCSModulesCount,
  calculateDcBlockCount,
  calculateLifecycleCost,
  calculateLifecycleCostDelta,
  calculateNameplateEnergy,
  calculateOptimalAugmentationYears,
  calculatePrice,
  calculatePriceDelta,
  calculateRte,
  calculateSystemPower,
  calculateTotalPrice,
  calculatePCSModulesCountV3,
  calculateAddedPCS,
  calculateAugmentationStrategy,
} from '~/utils/storageRankingTableCalculus';
import {
  columnSorter,
  currencyFormatter,
  generateTitlePopover,
  integerFormatter,
} from '~/utils';
import { ColumnProps } from '~/UI/Table';
import Skeleton from '~/UI/Skeleton';
import { buildSystemSummaryCSV } from '~/utils/buildSystemSummaryCSV';
import { UseGetStorageColumnsWithFilterOptions } from '~/hooks/useGetStorageColumns';
import { Button } from '~/UI';

export type TabKey =
  | 'NPV_DELTA'
  | 'REVENUE'
  | 'PERFORMANCE'
  | 'COST_SAVINGS'
  | 'BOS_COST_SAVINGS'
  | 'MOD_STRING_QUANTITIES'
  | 'RACKING_QUANTITIES'
  | 'MODULE_MECH'
  | 'MODULE_ELEC'
  | 'PROJECT_DESIGN';

type Column = {
  title: string;
  dataIndex: keyof SolarRankingData;
  key: string;
  prefix?: string;
  unit?: string;
  decorate?: (value: number) => number;
};

const skeleton = (
  <Skeleton
    title={false}
    paragraph={{
      rows: 1,
      width: '150px',
    }}
  />
);

const showSkeletonWhenNil = (value: string) => value || skeleton;

export type Tab = {
  label: string;
  sortKey: string;
  columns: Column[];
};

export const TABS_CONFIG: {
  [tab in TabKey]: Tab;
} = {
  NPV_DELTA: {
    label: 'NPV Delta',
    sortKey: 'npv_delta_USD',
    columns: [
      {
        title: 'Watt Class',
        dataIndex: 'epc_price_USD_per_W',
        key: 'epc_price_USD_per_W',
        unit: 'W',
      },
      {
        title: 'Availability',
        dataIndex: 'availability',
        key: 'availability',
        unit: 'MW',
      },
      {
        title: 'Price',
        dataIndex: 'epcPriceUsd',
        key: 'epcPriceUsd',
        unit: '$/W',
      },
      {
        title: 'NPV Delta',
        dataIndex: 'nvpDelta',
        key: 'nvpDelta',
        prefix: '-$',
        decorate: Math.abs,
      },
      {
        title: 'Revenue Delta',
        dataIndex: 'revenueDelta',
        key: 'revenueDelta',
        prefix: '-$',
        decorate: Math.abs,
      },
      {
        title: 'Cost Savings',
        dataIndex: 'costSavings',
        key: 'costSavings',
        prefix: '-$',
        decorate: Math.abs,
      },
    ],
  },
  REVENUE: {
    label: 'Revenue',
    sortKey: 'project_value_USD',
    columns: [
      {
        title: 'Yield',
        dataIndex: 'post_unavailability_yield_kWh_per_kWp',
        key: 'post_unavailability_yield_kWh_per_kWp',
        unit: 'kWh/kWp',
      },
      {
        // Delta
        title: 'Yield Delta',
        dataIndex: 'yieldDelta',
        key: 'yieldDelta',
        unit: '%',
        decorate: (value) => value * 100,
      },
      {
        title: 'Degradation',
        dataIndex: 'module_degredation_rate_percent',
        key: 'module_degredation_rate_percent',
        unit: '%',
        decorate: (value) => value * 100,
      },
      {
        // Delta
        title: 'Revenue Delta $/W',
        dataIndex: 'revenueDeltaUsdPerW',
        key: 'revenueDeltaUsdPerW',
        unit: '$/W',
      },
      {
        // Delta
        title: 'Revenue Delta $',
        dataIndex: 'revenueDeltaUsd',
        key: 'revenueDeltaUsd',
        prefix: '$',
        decorate: Math.abs,
      },
    ],
  },
  PERFORMANCE: {
    label: 'Performance',
    sortKey: 'epcPriceUsdPerW',
    columns: [
      {
        title: 'Degradation',
        dataIndex: 'module_degredation_rate_percent',
        key: 'module_degredation_rate_percent',
      },
      {
        title: 'Bifaciality',
        dataIndex: 'bifaciality',
        key: 'bifaciality',
      },
      {
        title: 'Temp. Loss',
        dataIndex: 'temp_loss',
        key: 'temp_loss',
      },
      {
        title: 'Irradiance Loss',
        dataIndex: 'irr_loss',
        key: 'irr_loss',
      },
      {
        title: 'LID Loss',
        dataIndex: 'lid_loss',
        key: 'lid_loss',
      },
      {
        title: 'IAM Loss',
        dataIndex: 'iam_loss',
        key: 'iam_loss',
      },
      {
        // Delta
        title: 'Yield Delta',
        dataIndex: 'yieldDelta',
        key: 'yieldDelta',
      },
    ],
  },
  COST_SAVINGS: {
    label: 'Cost Savings',
    sortKey: 'costSavings',
    columns: [],
  },
  BOS_COST_SAVINGS: {
    label: 'BOS Cost Savings',
    sortKey: 'bos_price_USD',
    columns: [],
  },
  MOD_STRING_QUANTITIES: {
    label: 'Module String Quantities',
    sortKey: 'module_count',
    columns: [],
  },
  RACKING_QUANTITIES: {
    label: 'Racking Quantities',
    sortKey: 'racking_count',
    columns: [],
  },
  MODULE_MECH: {
    label: 'Mechanical',
    sortKey: 'module_mass_kg',
    columns: [],
  },
  MODULE_ELEC: {
    label: 'Electrical',
    sortKey: 'module_vmp',
    columns: [],
  },
  PROJECT_DESIGN: {
    label: 'Project Design',
    sortKey: 'project_design',
    columns: [],
  },
};

// this seems like a useful function to leave in the codebase
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const random = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const SOLAR_CALCULATED_FIELDS = [
  {
    field: 'epcPriceUsd',
    calculate: calculateEpcPrice,
  },
  {
    field: 'nvpDelta',
    calculate: calculateNvpDelta,
  },
  {
    field: 'revenueDelta',
    calculate: calculateRevenueDelta,
  },
  {
    field: 'costSavings',
    calculate: calculateCostSavings,
  },
  {
    field: 'yieldDelta',
    calculate: calculateYieldDelta,
  },
  {
    field: 'revenueDeltaUsdPerW',
    calculate: calculateRevenueDeltaPerWatt,
  },
  {
    field: 'revenueDeltaUsd',
    calculate: calculateRevenueDeltaPerWatt,
  },
] as const;

/**
 * Use this array to configure new calculated values for the Storage Ranking Data
 * Storage calculated values are calculated when Storage Engine Data is requested in PDP (storage)
 */
export const STORAGE_CALCULATED_FIELDS = [
  {
    field: 'capex',
    calculate: calcualteCapex,
  },
  {
    field: 'preventativeMaintenance',
    calculate: calcualtePreventativeMaintenance,
  },
  {
    field: 'productAndCapacityWarranty',
    calculate: calcualteProductAndCapacityWarranty,
  },
  {
    field: 'augmentationCapex',
    calculate: calcualteAugmentationCapex,
  },
  {
    field: 'lifecycleCost',
    calculate: calculateLifecycleCost,
  },
  {
    field: 'lifecycleCostDelta',
    calculate: calculateLifecycleCostDelta,
  },
  {
    field: 'dcBlockCount',
    calculate: calculateDcBlockCount,
  },
  {
    field: 'nameplateEnergy',
    calculate: calculateNameplateEnergy,
  },
  {
    field: 'acUsableEnergy',
    calculate: calculateAcUsableEnergy,
  },
  {
    field: 'systemPower',
    calculate: calculateSystemPower,
  },
  {
    field: 'bolDcBlock',
    calculate: calcualteBolDcBlock,
  },
  {
    field: 'optimalAugmentationYears',
    calculate: calculateOptimalAugmentationYears,
  },
  {
    field: 'addedDcBlocks',
    calculate: calculateAddedDcBlocks,
  },
  {
    field: 'PCSModulesCount',
    calculate: calculatePCSModulesCount,
  },
  {
    field: 'PCSModulesCountV3',
    calculate: calculatePCSModulesCountV3,
  },
  {
    field: 'totalPrice',
    calculate: calculateTotalPrice,
  },
  {
    field: 'lifecycleCostDelta',
    calculate: calculateLifecycleCostDelta,
  },
  {
    field: 'priceDelta',
    calculate: calculatePriceDelta,
  },
  {
    field: 'rte',
    calculate: calculateRte,
  },
  {
    field: 'price',
    calculate: calculatePrice,
  },
  {
    field: 'addedPCS',
    calculate: calculateAddedPCS,
  },
  {
    field: 'augmentationStrategy',
    calculate: calculateAugmentationStrategy,
  },
] as const;

export const TABS: { value: TabKey; label: string }[] = values(
  mapObjIndexed(({ label }, key) => ({ label, value: key }), TABS_CONFIG)
);

export const commonColumnsFilterKeys = {
  dcBlock: 'dc_block',
  pcsModule: 'pcs_module',
} as const;
export type CommonColumnsKeys =
  (typeof commonColumnsFilterKeys)[keyof typeof commonColumnsFilterKeys];

export const getCommonColumnsModuleName = (
  moduleName: string,
  manufacturer: string,
  options?: UseGetStorageColumnsWithFilterOptions
) => {
  const fullName =
    moduleName && !options?.redacted ? `${manufacturer} ${moduleName}` : '';
  return showSkeletonWhenNil(fullName);
};

const getDCBlockName = (
  moduleName: string,
  module: EngineResultSizingArchitectureRow,
  options?: UseGetStorageColumnsWithFilterOptions
) =>
  getCommonColumnsModuleName(moduleName, module.dc_block.Manufacturer, options);

const getPCSName = (
  moduleName: string,
  module: EngineResultSizingArchitectureRow,
  options?: UseGetStorageColumnsWithFilterOptions
) =>
  getCommonColumnsModuleName(
    moduleName,
    module.pcs_module.Manufacturer,
    options
  );

const dcBlockSorter = (
  a: EngineResultSizingArchitectureRow,
  b: EngineResultSizingArchitectureRow
) => {
  const aValue = getDCBlockName(a.dc_block.ProductName, a);
  const bValue = getDCBlockName(b.dc_block.ProductName, b);

  if (aValue > bValue) return 1;
  if (aValue < bValue) return -1;
  return 0;
};

export const getTableTitleWithTooltip = (
  title: string,
  content: ReactNode,
  justifyContent = 'flex-end'
) =>
  generateTitlePopover(
    title,
    content,
    { justifyContent },
    undefined,
    'pre-wrap'
  );

export const GET_STORAGE_COMMON_COLUMNS = (
  options?: UseGetStorageColumnsWithFilterOptions
): ColumnProps<EngineResultSizingArchitectureRow>[] => {
  return [
    {
      key: 'index',
      dataIndex: 'index',
      align: 'left',
      width: '80px',
      render: (text: number) => (
        <div style={{ paddingLeft: '4px' }}>{text + 1}</div>
      ),
    },
    {
      title: 'AC / DC Block',
      width: 270,
      key: commonColumnsFilterKeys.dcBlock,
      dataIndex: ['dc_block', 'ProductName'],
      align: 'left',
      sorter: dcBlockSorter,
      render: (moduleName, module) =>
        getDCBlockName(moduleName, module, options),
    },
    {
      title: getTableTitleWithTooltip(
        'PCS',
        'Power Conversion System + Medium Voltage Transformer. A blank value indicates that the PCS is integrated into the AC / DC Block.',
        'flex-left'
      ),
      width: 270,
      key: commonColumnsFilterKeys.pcsModule,
      dataIndex: ['pcs_module', 'ProductName'],
      sorter: columnSorter(['pcs_module', 'ProductName']),
      render: (moduleName, module) => getPCSName(moduleName, module, options),
    },
  ];
};

/*
  `value` param is unknown because it is used in 'render' Column Props,
   which `value` param has an `any` type.
*/
const getIntegerCommaSeparatedValue = (
  value: unknown,
  prefix = '',
  suffix = ''
) => (value ? `${prefix}${integerFormatter(Number(value))}${suffix}` : '-');
const getRoundedCurrency = (value: unknown) =>
  getIntegerCommaSeparatedValue(value, '$');
const getRoundedKilowattHour = (value: unknown) =>
  getIntegerCommaSeparatedValue(value, '', ' kWh');

const getCommaSeparatedValue = (value: unknown[]) =>
  isEmpty(value) ? '-' : value.join(', ');

type STORAGE_TABLE_KEYS =
  | 'STORAGE_RANKING_TABLE'
  | 'LIFECYCLE_COST'
  | 'BOL_ARCHITECTURE'
  | 'CAPACITY_MAINTENANCE';

export const storageEngineVersion1 = 'storage_v1';

export const storageEngineVersion2 = 'storage_v2';

export const storageEngineVersion3 = 'storage_v3';

export const storageEngineVersion4 = 'storage_v4';

export const storageTables = (
  options?: UseGetStorageColumnsWithFilterOptions,
  storageEngineVersion?: string
): Record<
  STORAGE_TABLE_KEYS,
  ColumnProps<EngineResultSizingArchitectureRow>[]
> => {
  const CAPACITY_MAINTENANCE: (ColumnProps<EngineResultSizingArchitectureRow> | null)[] =
    [
      {
        title: getTableTitleWithTooltip(
          'BOL AC / DC\nBlock Count',
          'Number of AC / DC Blocks deployed at the beginning of life.'
        ),
        align: 'right',
        key: 'bolDcBlock',
        dataIndex: ['bolDcBlock'],
        sorter: columnSorter(['bolDcBlock']),
      },
      {
        title: 'Optimal Augmentation Years',
        align: 'right',
        key: 'optimalAugmentationYears',
        dataIndex: ['optimalAugmentationYears'],
        render: getCommaSeparatedValue,
      },
      storageEngineVersion === storageEngineVersion3 ||
      storageEngineVersion === storageEngineVersion4
        ? {
            title: getTableTitleWithTooltip(
              'Added\nPCS',
              'PCS installed at respective augmentation year(s).'
            ),
            align: 'right',
            key: 'addedPCS',
            dataIndex: 'addedPCS',
            sorter: columnSorter(['addedPCS']),
            render: getCommaSeparatedValue,
          }
        : null,
      {
        title: getTableTitleWithTooltip(
          'Added AC / DC\nBlocks',
          'AC / DC blocks installed at respective augmentation year(s).'
        ),
        align: 'right',
        key: 'addedDcBlocks',
        dataIndex: ['addedDcBlocks'],
        render: getCommaSeparatedValue,
      },
      {
        title: getTableTitleWithTooltip(
          'Augmentation\nCapEx',
          'Net present value of augmentation costs including forecasted EPC mobilization and BESS equipment costs.'
        ),
        align: 'right',
        key: 'augmentationCapex',
        dataIndex: 'augmentationCapex',
        sorter: columnSorter(['augmentationCapex']),
        render: (value) => getRoundedCurrency(value).replace('-', 'Overbuild'),
      },
      {
        title: 'System Summary',
        align: 'right',
        render: (_, record) => (
          <>
            {options?.enableDownload ? (
              <CSVLink
                filename={`${record.dc_block.ProductName} - ${record.pcs_module.ProductName}.csv`}
                style={{
                  textDecoration: 'underline',
                  fontWeight: '600',
                }}
                data={buildSystemSummaryCSV(record, storageEngineVersion)}
              >
                Download
              </CSVLink>
            ) : (
              <Button disabled styleType="link">
                Download
              </Button>
            )}
          </>
        ),
      },
    ];

  const capacityMaintenance = filter(
    (v) => !isNil(v),
    CAPACITY_MAINTENANCE
  ) as ColumnProps<EngineResultSizingArchitectureRow>[];

  return {
    STORAGE_RANKING_TABLE: [
      {
        title: getTableTitleWithTooltip('Price', '$/kWh DC nameplate.'),
        align: 'right',
        key: 'price',
        dataIndex: ['price'],
        sorter: columnSorter(['price']),
        render: (value: string) =>
          !value
            ? '-'
            : `$${currencyFormatter(Number(value), 2, 2, false)}/kWh`,
      },
      {
        title: getTableTitleWithTooltip(
          'Nameplate Energy',
          'Nameplate DC energy deployed at the beginning of life.'
        ),
        align: 'right',
        key: 'nameplateEnergy',
        dataIndex: ['nameplateEnergy'],
        sorter: columnSorter(['nameplateEnergy']),
        render: getRoundedKilowattHour,
      },
      {
        title: getTableTitleWithTooltip(
          'CapEx',
          'Your system CapEx including AC / DC Block, PCS, MVT, EMS, shipping, and tariff. Sales tax excluded. Final pricing to be confirmed at purchase.'
        ),
        align: 'right',
        key: 'totalPrice',
        dataIndex: ['totalPrice'],
        sorter: columnSorter(['totalPrice']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'CapEx Delta',
          "Difference between a selected architecture's CapEx and the lowest CapEx in the ranking table."
        ),
        align: 'right',
        key: 'priceDelta',
        dataIndex: 'priceDelta',
        sorter: columnSorter(['priceDelta']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'Lifecycle Cost\nDelta',
          "Difference between a selected architecture's Lifecycle Cost and the lowest Lifecycle Cost in your ranking table, in present day dollars."
        ),
        align: 'right',
        key: 'lifecycleCostDelta',
        dataIndex: 'lifecycleCostDelta',
        sorter: columnSorter(['lifecycleCostDelta']),
        render: getRoundedCurrency,
      },
    ],
    LIFECYCLE_COST: [
      {
        title: 'CapEx',
        align: 'right',
        key: 'capex',
        dataIndex: ['capex'],
        sorter: columnSorter(['capex']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'Preventative\nMaintenance',
          "The net present value of OEM's preventative maintenance plan."
        ),
        align: 'right',
        key: 'preventativeMaintenance',
        dataIndex: ['preventativeMaintenance'],
        sorter: columnSorter(['preventativeMaintenance']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'Product & Capacity Warranty',
          'Net present value of the extended warranty and capacity guarantee.'
        ),
        align: 'right',
        width: 200,
        key: 'productAndCapacityWarranty',
        dataIndex: ['productAndCapacityWarranty'],
        sorter: columnSorter(['productAndCapacityWarranty']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'Augmentation\nCapEx',
          'Net present value of augmentation costs including forecasted EPC mobilization and BESS equipment costs.'
        ),
        align: 'right',
        key: 'augmentationCapex',
        dataIndex: 'augmentationCapex',
        sorter: columnSorter(['augmentationCapex']),
        render: (value) => getRoundedCurrency(value).replace('-', 'Overbuild'),
      },
      {
        title: getTableTitleWithTooltip(
          'Lifecycle Cost',
          'Net present value of the sum of CapEx and OpEx including preventative maintenance, warranties, and augmentation.'
        ),
        align: 'right',
        key: 'lifecycleCost',
        dataIndex: ['lifecycleCost'],
        sorter: columnSorter(['lifecycleCost']),
        render: getRoundedCurrency,
      },
      {
        title: getTableTitleWithTooltip(
          'Lifecycle Cost Delta',
          "Difference between an architecture's total price against the lowest price architecture in your ranking table, in present day dollars."
        ),
        align: 'right',
        key: 'lifecycleCostDelta',
        dataIndex: 'lifecycleCostDelta',
        sorter: columnSorter(['lifecycleCostDelta']),
        render: getRoundedCurrency,
      },
    ],
    BOL_ARCHITECTURE: [
      {
        title: getTableTitleWithTooltip(
          'AC / DC Block\nCount',
          'Number of AC / DC Blocks deployed at the beginning of life.'
        ),
        align: 'right',
        key: 'dcBlockCount',
        dataIndex: ['dcBlockCount'],
        sorter: columnSorter(['dcBlockCount']),
      },
      {
        title: getTableTitleWithTooltip(
          'PCS Count',
          'Number of Power Conversion Systems deployed at the beginning of life. Where the PCS is integrated into the AC / DC Block the count reflects stand alone or Medium Voltage Transformers.'
        ),
        align: 'right',
        key: 'pcs_module_count',
        // dataIndex: ['ac_data', 'pcs_module_count'],
        dataIndex:
          storageEngineVersion === storageEngineVersion3 ||
          storageEngineVersion === storageEngineVersion4
            ? ['PCSModulesCountV3']
            : ['PCSModulesCount'],
        sorter:
          storageEngineVersion === storageEngineVersion3 ||
          storageEngineVersion === storageEngineVersion4
            ? columnSorter(['PCSModulesCountV3'])
            : columnSorter(['PCSModulesCount']),
      },
      {
        title: getTableTitleWithTooltip(
          'Nameplate Energy',
          'Nameplate DC energy deployed at the beginning of life.'
        ),
        align: 'right',
        key: 'nameplateEnergy',
        dataIndex: ['nameplateEnergy'],
        sorter: columnSorter(['nameplateEnergy']),
        render: getRoundedKilowattHour,
      },
      {
        title: getTableTitleWithTooltip(
          'AC Usable Energy',
          'Beginning of life AC useable energy at the selected point of metering.'
        ),
        align: 'right',
        key: 'acUsableEnergy',
        dataIndex: ['acUsableEnergy'],
        sorter: columnSorter(['acUsableEnergy']),
        render: getRoundedKilowattHour,
      },
      {
        title: getTableTitleWithTooltip(
          'System Power',
          'Determined at point of metering and considering maximum design temperature.'
        ),
        align: 'right',
        key: 'systemPower',
        dataIndex: ['systemPower'],
        sorter: columnSorter(['systemPower']),
        render: (value) => getIntegerCommaSeparatedValue(value, '', ' kVA'),
      },
      {
        title: getTableTitleWithTooltip(
          'RTE',
          'Estimated Round Trip Efficiency at point of metering.'
        ),
        align: 'right',
        key: 'rte',
        dataIndex: ['rte'],
        sorter: columnSorter(['rte']),
        render: (value) =>
          `${currencyFormatter(Number((value || 0) * 100), 2, 2, false)} %`,
      },
    ],
    CAPACITY_MAINTENANCE: capacityMaintenance,
  };
};

export const STORAGE_TABLES = mapObjIndexed(
  (tableColumns) => [...GET_STORAGE_COMMON_COLUMNS(), ...tableColumns],
  storageTables()
);
