import styled from '@emotion/styled';

import { ButtonAnt as Button, Divider } from '~/UI';
import { colors, theme } from '~/utils';
import { H4, H4Strong, ModalText } from '~/UI/Typography';
import { EffectivePriceChip } from '../ranking/styles';

// SolarDetails -----------------------------------------

export const StyledModalText = styled(ModalText)`
  color: ${colors.gray800};
`;

export const StyledDivider = styled(Divider)`
  height: 40px;
  margin: 0;
`;

export const AnalysisSwitchBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AnalysisViewSwitchText = styled(H4Strong)`
  color: ${colors.gray800};
`;

export const RankingMenu = styled.div`
  background-color: ${theme.colors.greyPageBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px 0;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 1px;
    z-index: -1;
    transform: scale(0.99);
    box-shadow: 0px 0px 10px 1px;
  }
`;

export const Quarter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.gray800};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  border-radius: 6px;
  align-self: flex-start;
  height: 40px;
`;

export const ArrowButton = styled(Button)`
  ${(props) =>
    props.disabled ? `color: ${theme.colors.gray300} !important;` : ''}
`;

export const QuarterDate = styled(H4)`
  padding-left: 12px;
  font-weight: 700 !important;
`;

export const InfoButton = styled.button`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  border-radius: 6px;
  padding: 11px;
  cursor: pointer;
  align-self: center;
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  &:disabled {
    cursor: not-allowed;
    background-color: ${theme.colors.gray200};
    border: 1px solid ${theme.colors.gray200};
    color: ${theme.colors.gray600};
  }
`;

// SolarInfo -----------------------------------------

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InfoDrawertitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: normal;
`;

export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.primary};
`;

export const InfoContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid ${theme.colors.gray200};
`;

export const PriceChip = styled(EffectivePriceChip)`
  background-color: ${theme.colors.white}!important;
  color: ${theme.colors.gray800};
  border: 1px solid ${theme.colors.gray200};
  min-width: 90px;
`;

export const EPCRevenueValueChip = styled(EffectivePriceChip)`
  background-color: ${theme.colors.gray200}!important;
  color: ${theme.colors.gray800};
  line-height: 10px;
  min-width: 140px;
`;

export const ChipsContainer = styled.div`
  display: flex;
  gap: 6px;
  padding: 8px 0;
  align-items: center;
`;

export const EffectivePriceChipInfo = styled(EffectivePriceChip)`
  min-width: 80px;
`;

export const TextBlock = styled.p`
  font-size: ${theme.fonts.sizes.label};
  font-weight: 400;
  line-height: 18px;
  color: ${theme.colors.gray800};
`;
