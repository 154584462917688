import React from 'react';

import AdminModuleRouter from '~/components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import {
  useGetAdminProjectListQuery,
  useDeleteProjectMutation,
} from '~/store/api';
import { rtkService } from '~/types/rtkApi';

import AdminProjectsForm from '../components/Admin/Projects/AdminProjectsForm';
import AdminProjectsList from '../components/Admin/Projects/AdminProjectsList';

const AdminProjects: React.FC = () => {
  const service = {
    paginatedQuery: useGetAdminProjectListQuery,
    delete: useDeleteProjectMutation,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="Projects"
      listComponent={AdminProjectsList}
      formComponent={AdminProjectsForm}
      path={ADMIN_ROUTES.ANZA_PROJECTS.path}
      service={service}
    />
  );
};

export default AdminProjects;
