import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import { Row, Tag, Tooltip } from '~/UI';
import { IconWarningCircle } from '~/UI/Icons';
import {
  anzaProjectHistoryStatuses,
  theme,
  IntegerSettings,
  getArrivalDateBasedOnProjectDetailsType,
  getQuarterRangeFromToday,
  getPreviusQuarterRangeFromToday,
} from '~/utils';
import {
  getProjectTypeClientFacingValueOrDefault,
  PROJECT_TYPES,
  PROJECT_STATUS_STYLES,
  PROJECT_STATUSES,
} from '~/constants/projects';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import CreateProject from '~/components/Projects/CreateProject';
import { H2 } from '~/UI/Typography';
import { ROUTER_PATHS } from '~/router/Paths';
import { AdminListPageProps } from '~/types/admin';
import type { AdminAnzaProjectItem } from '~/types/projects';
import { Filter } from '~/components/Tables/SearchFilterDropdown';
import AdminTableServerPagination, {
  Column,
} from '../AdminTable/AdminTableServerPagination';

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const hasCreatedState = (data: AdminAnzaProjectItem) =>
  data?.status === PROJECT_STATUSES.created.key;

const getDeliveryDateFilters = () => {
  const filters: Array<Filter> = [];

  getQuarterRangeFromToday(10).forEach(
    (quarter: { quarter: number; year: number }) => {
      const filterText = `Q${quarter.quarter} ${quarter.year}`;
      filters.push({ text: filterText, value: filterText });
    }
  );
  return filters;
};

const getPassedQuarters = () => {
  const filters: Array<Filter> = [];

  getPreviusQuarterRangeFromToday(10).forEach(
    (quarter: { quarter: number; year: number }) => {
      const filterText = `Q${quarter.quarter} ${quarter.year}`;
      filters.push({ text: filterText, value: filterText });
    }
  );
  return filters;
};

const getViewProjectClick = (record: AdminAnzaProjectItem) => {
  if (record.project_type === 'solar') {
    return ROUTER_PATHS.projects.solar.PROJECT_DETAILS(record.uuid);
  }

  return ROUTER_PATHS.projects.storage.PROJECT_DETAILS(record.uuid);
};

const AdminProjectsList: React.FC<AdminListPageProps> = ({
  service,
  history,
  pageName,
}) => {
  const handleViewProjectClick = (record: AdminAnzaProjectItem) => {
    if (record.project_type === 'solar') {
      return history.push(
        ROUTER_PATHS.projects.solar.PROJECT_DETAILS(record.uuid)
      );
    }

    return history.push(
      ROUTER_PATHS.projects.storage.PROJECT_DETAILS(record.uuid)
    );
  };

  const columns: Column<AdminAnzaProjectItem>[] = [
    {
      title: '',
      dataIndex: 'warning',
      key: 'warning',
      isFilterable: false,
      isSortable: true,
      fixed: 'left',
      align: 'left',
      width: '46px',
      sorter: true,
      render: (_, record) => {
        const hasWarning = hasCreatedState(record);
        return hasWarning ? (
          <Tooltip title="Pending approval">
            <IconWarningCircle size={18} color={theme.colors.secondary} />
          </Tooltip>
        ) : null;
      },
    },
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      isFilterable: true,
      isSortable: true,
      fixed: 'left',
      width: '300px',
      sorter: true,
    },
    {
      title: 'System Size (kW DC/AC)',
      dataIndex: 'project_size_in_kw',
      key: 'project_size_in_kw',
      isSortable: true,
      width: '150px',
      render: (value: string) => IntegerSettings.formatter(+value),
      sorter: true,
    },
    {
      title: 'Project Type',
      dataIndex: 'project_type',
      key: 'project_type',
      sorter: true,
      width: '150px',
      isSortable: true,
      filters: [
        {
          text: PROJECT_TYPES.solar.clientFacingValue,
          value: PROJECT_TYPES.solar.key,
        },
        {
          text: PROJECT_TYPES.storage.clientFacingValue,
          value: PROJECT_TYPES.storage.key,
        },
        {
          text: PROJECT_TYPES.solarStorage.clientFacingValue,
          value: PROJECT_TYPES.solarStorage.key,
        },
      ],
      render: (projectType: string) => (
        <Tag
          text={getProjectTypeClientFacingValueOrDefault(
            projectType,
            projectType
          )}
        />
      ),
    },
    {
      title: `Status`,
      dataIndex: 'status',
      key: 'status',
      filters: anzaProjectHistoryStatuses,
      isSortable: true,
      width: '120px',
      sorter: true,
      render: (text: string) => {
        const style = PROJECT_STATUS_STYLES[text];
        return <Tag style={style} text={text} />;
      },
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      isFilterable: true,
      isSortable: true,
      width: '300px',
      sorter: true,
    },
    {
      title: 'Purchase Date',
      dataIndex: 'anticipated_purchase_date',
      key: 'anticipated_purchase_date',
      isSortable: true,
      width: '200px',
      render: (date: string) =>
        date ? dayjs(date, 'YYYY/MM/DD').format('MM/YYYY') : '',
      sorter: true,
      filters: getDeliveryDateFilters(),
    },
    {
      title: 'Delivery Date',
      dataIndex: 'arrival_date',
      key: 'arrival_date',
      isSortable: true,
      width: '150px',
      render: (_, record) =>
        dayjs(
          getArrivalDateBasedOnProjectDetailsType(record),
          'YYYY/MM/DD'
        ).format('MM/YYYY'),
      sorter: true,
      filters: getDeliveryDateFilters(),
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      isFilterable: true,
      isSortable: true,
      width: '200px',
      sorter: true,
    },
    {
      title: 'Created On',
      dataIndex: 'created_at',
      key: 'created_at',
      isSortable: true,
      isFilterable: false,
      width: '150px',
      render: (date: string) => dayjs(date, 'YYYY/MM/DD').format('MM/DD/YYYY'),
      sorter: true,
      filters: getPassedQuarters(),
    },
  ];

  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
        <ButtonRow>
          <CreateProject loaded />
        </ButtonRow>
      </Row>
      <AdminTableServerPagination
        onRow={(record) => ({
          onClick: () => handleViewProjectClick(record),
        })}
        history={history}
        service={service}
        columns={columns}
        defaultRowKey="uuid"
        customActions={{
          view: {
            onHref: getViewProjectClick,
          },
          edit: {
            onHref: (project) =>
              ADMIN_ROUTES.ANZA_PROJECTS.path.edit(project.uuid),
          },
          delete: {
            visible: true,
          },
        }}
      />
    </div>
  );
};

export default AdminProjectsList;
