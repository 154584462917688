import { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { TableCurrentDataSource } from 'antd/lib/table/interface';
import type { FilterMenuFilterConfig } from '~/UI/Filters/FilterMenu';
import type { FilterValues } from '~/UI/Filters/FilterForm';
import { ColumnProps, FilterValue, SorterResult } from '~/UI/Table';

export const ActiveColumnsFilter = {
  always: 'always',
  selected: 'selected',
  unselected: 'unselected',
} as const;

export type TypeActiveColumnsFilter =
  (typeof ActiveColumnsFilter)[keyof typeof ActiveColumnsFilter];

export type ColumnWithMenuFilter<T = any> = ColumnProps<T> & {
  active?: TypeActiveColumnsFilter;
  dropdownGroupTitle?: string;
  key: string;
};

export type UseTableColumnsReturnType<T> = {
  initialValues: FilterValues;
  filters: FilterMenuFilterConfig[];
  updateColumns: (filterValues: FilterValues) => void;
  clearAllOptionalColumns: () => void;
  clearColumnGroup: (filter: string) => void;
  resetFilters: () => void;
  setTableFilters: (filters: Record<string, FilterValue | null>) => void;
  setTableSorter: (sorter: SorterResult<any> | undefined) => void;
  tableProps: {
    columns: ColumnWithMenuFilter<T>[];
    onChange: (
      filters: Record<string, FilterValue | null>,
      { action }: TableCurrentDataSource<any>
    ) => void;
  };
};

type Accumulator = {
  initialValues: FilterValues;
  filters: FilterMenuFilterConfig[];
};

const isAlwaysActive = (item: ColumnWithMenuFilter) =>
  item.active === ActiveColumnsFilter.always;

const applyFiltersSortersToColumns = (
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<any> | undefined,
  columns: ColumnWithMenuFilter<any>[]
) => {
  return columns?.map((c) => {
    const filter = filters[c.key as string];
    const sort = sorter && sorter.columnKey === c.key ? sorter.order : null;
    return {
      ...c,
      filteredValue: filter || null,
      sortOrder: sort,
    };
  });
};

const useTableColumns = <T = any>(
  allColumns: ColumnWithMenuFilter<T>[]
): UseTableColumnsReturnType<T> => {
  const [columns, setColumns] = useState<ColumnWithMenuFilter<T>[]>([]);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sorter, setSorter] = useState<SorterResult<any> | undefined>({});

  useEffect(() => {
    const newCols = applyFiltersSortersToColumns(tableFilters, sorter, columns);
    setColumns(newCols);
  }, [sorter, tableFilters]);

  const resetFilters = () => {
    setTableFilters({});
  };

  const { initialValues, filters } = useMemo(
    () =>
      allColumns.reduce<Accumulator>(
        (acc, item) => {
          if (isAlwaysActive(item)) return acc;

          const itemGroup = item.dropdownGroupTitle ?? '';
          const itemTitle = item.title ? `${item.title}` : '';
          const itemOption = {
            title: itemTitle,
            label: itemTitle,
            value: item.key,
          };

          const filterGroupIndex = acc.filters.findIndex((filterConfig) => {
            return filterConfig.title === itemGroup;
          });

          const createNewFilter = filterGroupIndex === -1;

          if (createNewFilter) {
            acc.filters.push({
              name: itemGroup,
              title: itemGroup,
              dropdownGroupTitle: itemGroup,
              options: [itemOption],
            });
          } else {
            const currentFilter = acc.filters[filterGroupIndex];
            acc.filters[filterGroupIndex] = {
              ...currentFilter,
              options: [...currentFilter.options, itemOption],
            };
          }

          const active = item.active || ActiveColumnsFilter.selected;

          if (active === ActiveColumnsFilter.selected) {
            if (acc.initialValues[itemGroup]) {
              // @ts-ignore
              acc.initialValues[itemGroup]?.push(item.key);
            } else {
              acc.initialValues[itemGroup] = [item.key];
            }
          }
          return acc;
        },
        { initialValues: {}, filters: [] }
      ),
    [allColumns]
  );

  const updateColumns = useCallback(
    (filterValues: FilterValues) => {
      const vals = Object.values(filterValues || {});
      const allValues = R.flatten(vals);
      const updatedColumns = allColumns.filter(
        (column) => allValues.includes(column.key) || isAlwaysActive(column)
      );
      const newFilters = R.mapObjIndexed((filter, filterKey) => {
        const foundColumn = Boolean(
          updatedColumns.find((c) => c.key === filterKey)
        );
        return foundColumn ? filter : null;
      }, tableFilters);

      if (sorter) {
        const foundSorter = Boolean(
          updatedColumns.find((c) => c.key === sorter.columnKey)
        );
        const newSorter = foundSorter ? sorter : undefined;
        setSorter(newSorter);
      }

      const newCols = applyFiltersSortersToColumns(
        tableFilters,
        sorter,
        updatedColumns
      );

      setTableFilters(newFilters);
      setColumns(newCols);
    },
    [allColumns]
  );

  const clearAllOptionalColumns = useCallback(() => {
    const clearState = allColumns.filter(isAlwaysActive);
    setColumns(clearState);
  }, [allColumns]);

  const clearColumnGroup = useCallback((filter: string) => {
    setColumns((prev) => {
      return prev.filter((item) => item.title !== filter);
    });
  }, []);

  useEffect(() => {
    updateColumns(initialValues);
  }, []);

  return {
    initialValues,
    filters,
    updateColumns,
    clearAllOptionalColumns,
    clearColumnGroup,
    resetFilters,
    setTableFilters,
    setTableSorter: setSorter,
    tableProps: {
      columns,
      onChange: (
        filters: Record<string, FilterValue | null>,
        { action }: TableCurrentDataSource<any>
      ) => {
        if (action === 'sort') {
          setSorter(sorter as SorterResult<any>);
        }
        if (action === 'filter') {
          setTableFilters(filters);
        }
      },
    },
  };
};

export default useTableColumns;
