import React from 'react';
import styled from '@emotion/styled';
import { Tag as TagAnt } from 'antd';
import { TagProps as TagAntProps } from 'antd/lib/tag';

import { theme } from '../utils';

export type TagProps = TagAntProps;

const StyledTag = styled(TagAnt)<TagProps>`
  border: none;
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.02em;
  height: 28px;
  background-color: ${theme.tags.gray.background};
  color: ${theme.tags.gray.color};
  cursor: pointer;
  svg + span:not(:empty) {
    margin-left: 4px;
  }
  &.ant-tag-primary {
    background-color: ${theme.colors.primary}24;
    color: ${theme.colors.primary}!important;
  }
  &.ant-tag-white {
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray800}!important;
    border-color: ${theme.colors.gray400};
  }
  &.ant-tag-green {
    background-color: ${theme.tags.green.background};
    color: ${theme.tags.green.color};
  }
  &.ant-tag-yellow {
    background-color: ${theme.tags.yellow.background};
    color: ${theme.tags.yellow.color};
  }
  &.ant-tag-gray {
    background-color: ${theme.tags.gray.background};
    color: ${theme.tags.gray.color};
  }
  &.ant-tag-error {
    background-color: ${theme.tags.error.background};
    color: ${theme.tags.error.color};
  }
  &.ant-tag-warning {
    background-color: ${theme.tags.warning.background};
    color: ${theme.tags.warning.color};
  }
  &.ant-tag-blue {
    background-color: ${theme.tags.blue.background};
    color: ${theme.tags.blue.color};
  }
  &.ant-tag-transparent {
    background-color: transparent;
    color: ${theme.colors.gray800};
    border-color: ${theme.colors.gray400};
  }
`;

const Tag: React.FC<TagProps> = ({ color = 'gray', ...props }) => (
  <StyledTag
    {...props}
    className={`${
      props.className ? `${props.className} ` : ''
    }ant-tag-${color}`}
  />
);

export default Tag;
