import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import Button from '../ButtonAnt';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';
import { GenericLabel } from '~/components/Projects/FormGroup';
import { IconBack } from '../Icons';
import { Form } from '..';
import Filter, { Option } from './Filter';
import { theme } from '~/utils';

export type FilterMenuOption = Option & {
  /** Used to show the value when a single filter in that group is selected on the grouped filter dropdown button */
  title: string;
};

export type FilterConfig = {
  name: string;
  title: string;
  datatype?: 'boolean' | 'integer' | 'double' | 'string' | 'float' | 'date';
  filterHeaderDataTestIdConfig?: TestIdProps;
  group: string;
  options: FilterMenuOption[];
  hasHeaderClearButton?: boolean;
  /** Used to show the Group Dropdown Filter title */
  dropdownGroupTitle: string;
};

export type FilterValue = string[];

export type FilterValues = Record<string, FilterValue>;

const FilterHeaderContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  padding: 0 14px;
  border-bottom: solid 1px ${theme.colors.gray300};
`;

const BackButton = styled(IconBack)`
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.colors.gray300};
  }
`;

export const FilterHeader = ({
  filter,
  onClearClick,
  section,
  dataTestIdConfig,
  showBackButton,
  onBackButtonClick,
  ...rest
}: {
  filter: FilterConfig;
  showPopoverTitle?: boolean;
  popoverContent?: string;
  section: string;
  onClearClick?: () => void;
  dataTestIdConfig?: TestIdProps;
  showBackButton?: boolean;
  onBackButtonClick?: () => void;
}) => {
  const dataTestConfig = dataTestIdConfig || {};
  const { title } = filter;
  return (
    <FilterHeaderContainer>
      {showBackButton && <BackButton size={20} onClick={onBackButtonClick} />}
      <GenericLabel
        testIdData={{
          ...dataTestConfig,
          component: TEST_DATA_COMPONENTS.TEXT,
          identifier: `${section}-title`,
        }}
        fontSize="16px"
        title={title}
        {...rest}
      />
      <Button
        style={{ marginLeft: 'auto' }}
        data-testid={generateTestId({
          ...dataTestConfig,
          component: TEST_DATA_COMPONENTS.BUTTON,
          identifier: `${section}-clear`,
        })}
        type="link"
        onClick={() => onClearClick?.()}
      >
        Clear
      </Button>
    </FilterHeaderContainer>
  );
};

const FilterFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border: solid 1px ${theme.colors.gray300};
`;

const StyledForm = styled(Form)`
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledFilter = styled(Filter)`
  padding: 0 12px;
  max-height: 30dvh;
  overflow-y: auto;
`;

type FilterFormProps = {
  filter: FilterConfig;
  values?: FilterValues;
  showBackButton?: boolean;
  onBackButtonClick: () => void;
  onApplyClick: (values: FilterValues) => void;
};

const FilterForm = ({
  filter,
  values,
  onBackButtonClick,
  onApplyClick,
  showBackButton,
}: FilterFormProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (!R.equals(values, form.getFieldsValue())) {
      form.resetFields();
    }
  }, [values]);
  const { name } = filter;

  const onClearClick = () => {
    form.setFieldValue(name, []);
  };

  return (
    <StyledForm form={form} initialValues={values} onFinish={onApplyClick}>
      <FilterHeader
        showBackButton={showBackButton}
        onBackButtonClick={onBackButtonClick}
        filter={filter}
        onClearClick={() => {
          onClearClick();
        }}
        section={`module-${name}`}
      />
      <StyledFilter filter={filter} key={filter.name} />
      <FilterFooterContainer>
        <Button
          style={{
            width: '100%',
          }}
          type="primary"
          htmlType="submit"
        >
          Apply
        </Button>
      </FilterFooterContainer>
    </StyledForm>
  );
};

export default FilterForm;
