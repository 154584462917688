import { createSlice } from '@reduxjs/toolkit';
import reducers, { initialState } from './reducer';

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers,
});

export default slice;
