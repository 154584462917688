import styled from '@emotion/styled';
import { theme } from '~/utils';
import { ButtonAnt as Button } from '~/UI';

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .table {
    flex: 1 1 auto;
  }
  .nav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  button {
    margin-left: 1.5rem;
    margin-right: 0;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const ActionButton = styled(Button)`
  margin: 0 0.5rem;
`;

export const Title = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: ${theme.fonts.colors.charcoal};
`;
