// Base
import antdTheme from './antdTheme.json';

export const colors = {
  primary: antdTheme['@primary-color'],
  primary50: antdTheme['@primary-color-50'],
  primary100: antdTheme['@primary-color-100'],
  primary200: antdTheme['@primary-color-200'],
  primary300: antdTheme['@primary-color-300'],
  primaryDark: antdTheme['@primary-dark-color'],
  primaryMidDark: antdTheme['@primary-mid-dark-color'],
  primaryDark500: antdTheme['@primary-dark-color-500'],
  primaryDarkDecimal: antdTheme['@primary-dark-color-dec'],
  primaryLight: antdTheme['@primary-light-color'],
  primaryLight300: antdTheme['@primary-light-color-300'],
  secondary: antdTheme['@secondary-color'],
  dark: antdTheme['@dark-color'],
  background: antdTheme['@background-color'],
  gray: antdTheme['@gray-color'],
  charcoal: antdTheme['@charcoal-color'],
  graphite: antdTheme['@graphite-color'],
  greyBreadcrumbActive: antdTheme['@grey-breadcrumb-active-color'],
  greyBreadcrumbInactive: antdTheme['@grey-breadcrumb-inactive-color'],
  sterling: antdTheme['@sterling-color'],
  grey: antdTheme['@grey-color'],
  greyPageBackground: '#F4F5F5',
  disabledLightGray: '#f5f5f5',
  disabledDarkGray: '#C7CBCE',
  anotherGray: '#AAAEB2',
  stone: antdTheme['@stone-color'],
  lightGray: antdTheme['@light-gray-color'],
  lightBg: antdTheme['@light-bg-color'],
  lightError: antdTheme['@lightError-color'],
  error: antdTheme['@error-color'],
  blue: antdTheme['@blue-color'],
  alert: antdTheme['@alert-color'],
  warning: antdTheme['@warning-color'],
  tintGreen: antdTheme['@tint-green-color'],
  tintOrange: antdTheme['@tint-orange-color'],
  tintYellow: antdTheme['@tint-yellow-color'],
  tintBlue: antdTheme['@tint-blue-color'],
  purple: antdTheme['@purple-color'],
  darkText: antdTheme['@dark-text-color'],
  white: antdTheme['@white-color'],
  anzaDarkBlue: '#007bff',
  black: antdTheme['@black-color'],
  success: antdTheme['@success-color'],
  pinkRed: antdTheme['@pink-red-color'],
  gray100: antdTheme['@gray-100-color'],
  gray200: antdTheme['@gray-200-color'],
  gray300: antdTheme['@gray-300-color'],
  gray350: '#00000014',
  gray400: antdTheme['@gray-400-color'],
  gray500: antdTheme['@gray-500-color'],
  gray600: antdTheme['@gray-600-color'],
  gray700: antdTheme['@gray-700-color'],
  gray800: antdTheme['@gray-800-color'],
  infoMain: antdTheme['@info-main'],
  info1: antdTheme['@info-1-color'],
  info4: antdTheme['@info-4-color'],
  info1light: antdTheme['@info-1-light-color'],
  info4light: antdTheme['@info-4-light-color'],
  yellow: antdTheme['@yellow-color'],
};

const headerHeight = 48;
const secondaryHeaderHeight = 48;
export const elements = {
  header: {
    height: `${headerHeight}px`,
    heightNumber: headerHeight,
  },
  secondaryHeader: {
    height: `${secondaryHeaderHeight}px`,
    heightNumber: secondaryHeaderHeight,
  },
  drawer: {
    height: `calc(100vh - ${headerHeight})`,
  },
};

export const fonts = {
  family: antdTheme['@fonts-family'],
  sizes: {
    heading: antdTheme['@fonts-sizes-heading'],
    subheading: antdTheme['@fonts-sizes-subheading'],
    sidetitle: antdTheme['@fonts-sizes-sidetitle'],
    placeholder: antdTheme['@fonts-sizes-placeholder'],
    label: antdTheme['@fonts-sizes-label'],
    button: antdTheme['@fonts-sizes-button'],
    hint: antdTheme['@fonts-sizes-hint'],
  },
  colors: {
    primary: antdTheme['@primary-color'],
    secondary: antdTheme['@secondary-color'],
    charcoal: antdTheme['@charcoal-color'],
    graphite: antdTheme['@graphite-color'],
    sterling: antdTheme['@sterling-color'],
    stone: antdTheme['@stone-color'],
    error: antdTheme['@error-color'],
    black: antdTheme['@black-color'],
  },
};

// Components

export const tags = {
  primary: {
    background: antdTheme['@primary-color-50'],
    color: antdTheme['@primary-color'],
  },
  gray: {
    background: antdTheme['@sterling-color'],
    color: antdTheme['@graphite-color'],
  },
  yellow: {
    background: antdTheme['@tint-yellow-color'],
    color: antdTheme['@secondary-color'],
  },
  green: {
    background: antdTheme['@tint-green-color'],
    color: antdTheme['@primary-color'],
  },
  error: {
    background: antdTheme['@lightError-color'],
    color: antdTheme['@error-color'],
  },
  warning: {
    background: antdTheme['@tint-orange-color'],
    color: antdTheme['@warning-color'],
  },
  blue: {
    background: antdTheme['@primary-light-color'],
    color: antdTheme['@primary-color'],
  },
};

// Breakpoints

export const breakpoints = {
  tablet: antdTheme['@breakpoints-tablet'],
  laptop: antdTheme['@breakpoints-laptop'],
};

export const dimensions = {
  width: {
    min: 1400,
    max: 1647,
  },
};

export const chartColors = {
  // We start at yellow and as the index increases we transition to green. These colors come from the ANZA style guide.
  yellowToGreenGradient: [
    '#ffca44',
    '#f9e841',
    '#e1f33f',
    '#baed3c',
    '#94e839',
    '#6fe236',
    '#4bdd34',
    '#31d73a',
    '#2fd156',
    '#2ccc71',
  ],
  primaryGradient: [
    colors.primary100,
    colors.primary200,
    colors.primary300,
    colors.primary,
  ],
};

export const borders = {
  lightGray: `1px solid ${colors.lightGray}`,
};
