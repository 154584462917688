import React from 'react';
import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import { StyledDrawerFooter, StyledDrawerFooterButton } from './styles';

interface Props {
  isLoading: boolean;
  onCloseDrawer: () => void;
  onSaveDrawer: () => void;
}
const ProjectDrawerFooter = ({
  onCloseDrawer,
  onSaveDrawer,
  isLoading,
}: Props) => {
  return (
    <StyledDrawerFooter>
      <StyledDrawerFooterButton
        data-testid={generateTestId({
          component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.BUTTON}`,
          identifier: 'cancel',
        })}
        onClick={onCloseDrawer}
        type="default"
        size="large"
      >
        Cancel
      </StyledDrawerFooterButton>
      <StyledDrawerFooterButton
        data-testid={generateTestId({
          component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.BUTTON}`,
          identifier: 'save',
        })}
        loading={isLoading}
        onClick={onSaveDrawer}
        type="primary"
        size="large"
        htmlType="submit"
      >
        Save
      </StyledDrawerFooterButton>
    </StyledDrawerFooter>
  );
};

export default ProjectDrawerFooter;
