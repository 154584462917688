import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import {
  CreateCompanyArguments,
  CreateCompanyResultType,
  UpdateCompanyArguments,
  UpdateCompanyResultType,
  ProductOfferingsQueryResult,
} from './types';

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['Companies', 'Company', 'ProductOfferings'],
  endpoints: (builder) => ({
    createCompany: builder.mutation<
      CreateCompanyResultType,
      CreateCompanyArguments
    >({
      query: (args) => ({
        url: routes.admin.adminCreateCompany(),
        method: 'POST',
        body: args,
      }),
    }),
    updateCompany: builder.mutation<
      UpdateCompanyResultType,
      UpdateCompanyArguments
    >({
      query: (args) => ({
        url: routes.admin.adminUpdateCompany(args.id),
        method: 'PUT',
        body: args,
      }),
      invalidatesTags: ['Company'],
    }),
    getProductOfferings: builder.query<ProductOfferingsQueryResult, void>({
      query: () => routes.productOfferings.getProductOfferings(),
      providesTags: ['ProductOfferings'],
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useGetProductOfferingsQuery,
} = companiesApi;
