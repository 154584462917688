import React, { useState } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';

import { BoxTitle } from '~/UI/Typography';
import { ButtonAnt as Button, notification, Table } from '~/UI';
import ProjectSpecificPricingModal from '~/components/Admin/Projects/ProjectSpecificPricing/ProjectSpecificPricingModal';
import { ColumnProps } from '~/UI/Table';
import { ProjectSpecificPricingResponse } from '~/types/projectSpecificPricing';
import { theme } from '~/utils';
import { IconEdit, IconTrash } from '~/UI/Icons';
import { ModuleType } from '~/types/modules';
import { useDeleteProjectSpecificPricingMutation } from '~/store/api/admin/projectSpecificPricingApi';
import useToggle from '~/hooks/useToggle';
import Modal from '~/UI/ModalAnt';
import { SolarProject } from '~/store/project';
import {
  getModuleById,
  getModulePortAdderByPort,
  getModuleQuarter,
  getModuleShippingAdderByShippingCost,
} from '~/utils/modules';

interface ProjectSpecificPricingType {
  data: ProjectSpecificPricingResponse[];
  modules: ModuleType[];
  projectUuid: string;
  project?: SolarProject;
}

const ProjectSpecificPricingWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
const ProjectSpecificPricing: React.FC<ProjectSpecificPricingType> = ({
  data,
  modules,
  projectUuid,
  project,
}) => {
  const [selectedModule, setSelectedModule] =
    useState<ProjectSpecificPricingResponse>();

  const [deleteProjectSpecificPricing, { isLoading: isDeleteLoading }] =
    useDeleteProjectSpecificPricingMutation();

  const projectSpecificPricingAlreadyExists = (
    module_uuid: string,
    quarter: string
  ): boolean =>
    data.some(
      (projectSpecificPricing) =>
        projectSpecificPricing.module_uuid === module_uuid &&
        projectSpecificPricing.quarter === quarter
    );

  const {
    isOpen: isOpenAddProjectSpecificPricing,
    open: openAddProjectSpecificPricing,
    close: closeAddProjectSpecificPricing,
  } = useToggle();

  const {
    isOpen: isOpenDeleteProjectSpecificPricing,
    open: openDeleteProjectSpecificPricing,
    close: closeDeleteProjectSpecificPricing,
  } = useToggle();

  const closeDeleteModal = () => {
    setSelectedModule(undefined);
    closeDeleteProjectSpecificPricing();
  };

  const handleDelete = async () => {
    deleteProjectSpecificPricing({
      projectUuid: selectedModule?.project_uuid as string,
      uuid: selectedModule?.uuid as string,
    })
      .unwrap()
      .then(() => closeDeleteModal())
      .catch(() => {
        notification.error({
          message: 'Something went wrong.',
          description:
            'It looks like there was an error while deleting the project specific pricing. Please refresh your browser and try again.',
        });
      });
  };

  const OverwrittenValue = ({ val }: { val: string | number }) => {
    return (
      <span>
        <b>
          <u>{val}</u>
        </b>
      </span>
    );
  };

  const projectPort = project?.project_details.locationModifier.port;
  const projectShippingCost =
    project?.project_details.locationModifier.shipping;

  const getPspValue = (
    value: string | number | null | undefined,
    defaultVal: string | number | null | undefined
  ) => {
    if (R.isNil(value) && R.isNil(defaultVal)) return '-';
    if (R.isNil(value)) return defaultVal;
    return <OverwrittenValue val={value} />;
  };

  const projectSpecificPricingColumns: ColumnProps<ProjectSpecificPricingResponse>[] =
    [
      {
        title: 'Module',
        dataIndex: 'module_uuid',
        key: 'module',
        render: (_, record) => {
          const module = modules.find(
            (item) => item.uuid === record.module_uuid
          );
          const moduleName = module?.name ?? '';
          const vendorName = module?.company?.name ?? '';
          const name = `${vendorName} ${moduleName}`;
          return <>{name}</>;
        },
      },
      {
        title: 'Source',
        key: 'source',
        dataIndex: 'source',
      },
      {
        title: 'Quarter',
        dataIndex: 'quarter',
        key: 'quarter',
      },
      {
        title: 'Price',
        key: 'price',
        render: (_, record) =>
          getPspValue(
            record.ddp_east_USD_per_W,
            getModuleQuarter(record.module_uuid, record.quarter, modules)
              ?.ddp_east_coast_port
          ),
      },
      {
        title: 'Port Adder',
        key: 'port_adder',
        render: (_, record) => {
          const module = getModuleById(record.module_uuid, modules);
          const defaultPort =
            projectPort &&
            module &&
            getModulePortAdderByPort(projectPort, module);
          return getPspValue(record.port_adder_USD_per_W, defaultPort);
        },
      },
      {
        title: 'Shipping Adder',
        key: 'shipping_adder',
        render: (_, record) => {
          const module = getModuleById(record.module_uuid, modules);
          const defaultShipping =
            projectShippingCost &&
            module &&
            getModuleShippingAdderByShippingCost(projectShippingCost, module);
          return getPspValue(record.shipping_adder_per_W, defaultShipping);
        },
      },
      {
        title: 'Availability',
        key: 'availability',
        render: (_, record) =>
          getPspValue(
            record.availability_MW,
            getModuleQuarter(record.module_uuid, record.quarter, modules)
              ?.availability_mw
          ),
      },
      {
        key: 'buttons',
        render: (_, record) => {
          const handleInitDelete = () => {
            setSelectedModule(record);
            openDeleteProjectSpecificPricing();
          };

          const handleEdit = () => {
            setSelectedModule(record);
            openAddProjectSpecificPricing();
          };

          return (
            <>
              <StyledButtonsWrapper>
                <Button type="ghost" title="Edit" onClick={handleEdit}>
                  <IconEdit color={theme.colors.primary} size="24px" />
                </Button>
                <Button type="ghost" title="Delete" onClick={handleInitDelete}>
                  <IconTrash color={theme.colors.alert} size="24px" />
                </Button>
              </StyledButtonsWrapper>
            </>
          );
        },
      },
    ];

  return (
    <ProjectSpecificPricingWrapper>
      <BoxTitle>Project Specific Pricing</BoxTitle>
      <Table
        dataSource={data}
        columns={projectSpecificPricingColumns}
        pagination={false}
      />
      <div>
        <Button
          type="primary"
          size="middle"
          onClick={openAddProjectSpecificPricing}
        >
          + Add custom price
        </Button>
      </div>
      <ProjectSpecificPricingModal
        open={isOpenAddProjectSpecificPricing}
        onCancel={() => {
          setSelectedModule(undefined);
          closeAddProjectSpecificPricing();
        }}
        projectSpecificPricingAlreadyExists={
          projectSpecificPricingAlreadyExists
        }
        modules={modules}
        projectUuid={projectUuid}
        selectedModule={selectedModule}
        projectPort={projectPort}
        projectShippingCost={projectShippingCost}
      />
      <Modal
        title="Are you sure you want to delete this item?"
        open={isOpenDeleteProjectSpecificPricing}
        onCancel={closeDeleteModal}
        onOk={handleDelete}
        okButtonProps={{ loading: isDeleteLoading }}
        confirmLoading={isDeleteLoading}
        bodyStyle={{ paddingTop: 0 }}
        centered
      >
        Once you do it, all the information related to this item might be lost.
      </Modal>
    </ProjectSpecificPricingWrapper>
  );
};

export default ProjectSpecificPricing;
