import * as R from 'ramda';
import Big from 'big.js';
import { isNumeric } from '~/utils/numberValidators';

type ValueType = string | number;

export const isNullEmptyUndefined = (value?: unknown) =>
  value === null || value === undefined || value === '';

export const formatNumberStringWithoutCommas = R.pipe(R.split(','), R.join(''));

const parseNumber = R.pipe(
  R.split(' '),
  R.find(R.pipe(Number, R.is(Number))),
  Number,
  R.when(Number.isNaN, R.always(null))
);

const parseSignedNumber = R.pipe(
  R.split(' '),
  R.find(R.pipe(Number, R.is(Number))),
  R.when((value) => value !== '-', Number),
  R.when(Number.isNaN, R.always(null))
);

export const currencyFormatter = (
  value: number,
  decimalPlaces = 0,
  minDecimalPlaces = 2,
  style = true
) => {
  if (Number.isNaN(value) || !value) {
    return null;
  }
  const currency = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: Math.min(minDecimalPlaces, decimalPlaces),
    maximumFractionDigits: decimalPlaces,
    style: style ? 'currency' : undefined,
    currency: 'USD',
  });
  return currency.format(Number(value));
};

export const decimalFormatter = (
  value: number,
  options: Intl.NumberFormatOptions = {
    style: 'decimal',
  }
) =>
  R.unless(
    R.either(R.isNil, R.isEmpty),
    new Intl.NumberFormat('en-US', options).format
  )(value);

export const applyPrecision = (
  num: string | number,
  precision: number,
  trimTrailingZeros = false
) => {
  const removedCommasNumber = formatNumberStringWithoutCommas(num.toString());
  if (Number.isNaN(Number(removedCommasNumber))) {
    return num;
  }
  const valueWithPossibleTrailingZeroes =
    Big(removedCommasNumber).toFixed(precision);
  return trimTrailingZeros
    ? Number(valueWithPossibleTrailingZeroes).toString()
    : valueWithPossibleTrailingZeroes;
};

export const decimalFormatterTens = (value: number) =>
  decimalFormatter(value, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

const decimalFormatterCenti = (value: number) =>
  decimalFormatter(value, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

export const decimalFormatterThous = (value: number) =>
  decimalFormatter(value, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

export const integerFormatter = (value: number) =>
  decimalFormatter(value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const integerNoRoundFormatter = (value: number) =>
  decimalFormatter(value, {
    maximumFractionDigits: 20,
  });

const parseUnit = R.pipe(formatNumberStringWithoutCommas, parseNumber);

export const isValueZeroOrNegativeZero = (value: string | number) => {
  return Number(value).toString() === '0' || Number(value).toString() === '-0';
};
const getValueOrNumberInPercent = (value: string | unknown) => {
  if (isNullEmptyUndefined(value)) {
    return '';
  }

  if (
    typeof value !== 'number' ||
    Number.isNaN(value) ||
    isValueZeroOrNegativeZero(value!)
  ) {
    return value;
  }
  return Big(value).div(100).toNumber();
};

export const percentParser = R.pipe(
  formatNumberStringWithoutCommas,
  parseSignedNumber,
  getValueOrNumberInPercent
);

export const DecimalSettings = {
  formatter: (value: number) => decimalFormatter(value),
  parser: (value: string | undefined) => {
    if (isNullEmptyUndefined(value)) {
      return '';
    }

    if (isValueZeroOrNegativeZero(value!)) {
      return value!.toString();
    }

    return parseUnit(value || '') as number;
  },
};

export const DecimalSettingsFloor = {
  formatter: (value: number) =>
    decimalFormatter(value, { maximumFractionDigits: 20 }),
  parser: DecimalSettings.parser,
};

export const DecimalSettingsCenti = {
  formatter: (value: number) => decimalFormatterCenti(value),
  parser: DecimalSettings.parser,
};

export const IntegerSettings = {
  formatter: (value: number) => integerFormatter(value),
  parser: DecimalSettings.parser,
};

export const IntegerNoRoundSettings = {
  formatter: (value: number) => {
    return integerNoRoundFormatter(value);
  },
  parser: DecimalSettings.parser,
};

interface FormatterInfo {
  userTyping: boolean;
  input: string;
}

export const PercentSettings = {
  formatter: (value: number) => {
    return decimalFormatter(value * 100);
  },
  formatterWithFractionDigits:
    (fractionDigits: number, formatOptions?: Intl.NumberFormatOptions) =>
    (value: ValueType | undefined, info?: FormatterInfo): string => {
      // as long as the user is typing, do not do any formatting
      const { userTyping, input } = info!;
      const ZERO_STRING = '0.00';
      if (userTyping && input !== '') {
        const valueInPercent = isNumeric(value!) ? Big(value!).mul(100) : '';

        if (isNumeric(input)) {
          return input;
        }

        if (
          valueInPercent.toString() !== input &&
          value !== ZERO_STRING &&
          isNumeric(value!) &&
          !isValueZeroOrNegativeZero(value!)
        ) {
          return valueInPercent.toString();
        }

        return input;
      }

      // there's a bug here where if the user clears an existing value then input is '' but value is returned as '0'
      if (
        isNullEmptyUndefined(value) ||
        (input === '' && value === ZERO_STRING)
      ) {
        // when the initial value is 0, we want to ensure that we still return a '0'.
        // But if the user is typing and cleared the field then we return an empty string
        if (userTyping || isNullEmptyUndefined(value)) {
          return '';
        }

        return ZERO_STRING;
      }

      const valueInPercent = Big(value!).mul(100);

      // Another antd bug here: When a clean, empty field is first loaded, the first keystroke returns input as an empty string, but there is a value present. In this case we want to return the formatted value
      if (
        userTyping &&
        input === '' &&
        !isNullEmptyUndefined(valueInPercent.toString())
      ) {
        return valueInPercent.toString();
      }

      // And finally, default scenario, return a formatted value, with decimals, onBlur
      return decimalFormatter(valueInPercent.toNumber(), {
        style: 'decimal',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits:
          formatOptions?.maximumFractionDigits || fractionDigits,
      }).toString();
    },
  parser: (value: string | undefined) => {
    if (isNullEmptyUndefined(value)) {
      return '';
    }
    return percentParser(value || '') as number;
  },
};

export const PercentSettingsFloor = {
  formatterWithFractionDigits: (fractionDigits: number) =>
    PercentSettings.formatterWithFractionDigits(fractionDigits, {
      maximumFractionDigits: 20,
    }),
  parser: PercentSettings.parser,
};

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toTitleCase: (str: string) => string = R.pipe(
  R.split(' '),
  R.map(capitalize),
  R.join(' ')
);

const isThreeDecimalPlaces = (value: null | number | string) => {
  const numberValue = Number(value);
  if (value !== null && value !== '' && numberValue === 0) {
    return true;
  }
  const [, decimals] = numberValue.toString().split('.');

  return !decimals || decimals.length <= 3;
};

// used for formatting module pricing
export const formatPrice = (price?: number | string | null) => {
  if (price === null || typeof price === 'undefined' || Number.isNaN(price)) {
    return null;
  }

  const roundFunction = price > 0 ? Math.ceil : Math.floor;
  if (isThreeDecimalPlaces(price)) {
    const truncatedPrice = roundFunction(+price * 1000) / 1000;
    return truncatedPrice.toFixed(3);
  }
  const truncatedPrice = roundFunction(+price * 10000) / 10000;
  if (isThreeDecimalPlaces(truncatedPrice)) {
    return truncatedPrice.toFixed(3);
  }
  return truncatedPrice.toFixed(4);
};

export function formatAsPercentage(
  num: number,
  options: Intl.NumberFormatOptions = {}
) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(num);
}
