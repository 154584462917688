import React from 'react';
import { Global, css } from '@emotion/react';

import { theme } from '~/utils';

export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          outline: none;
        }

        body {
          font-family: Inter, sans-serif;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${theme.colors.greyPageBackground};
          overscroll-behavior: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          margin: 0;
        }

        .ant-select-item {
          padding: 12px 16px;
        }

        .ant-select-dropdown,
        .ant-dropdown-menu {
          padding-top: 0;
          padding-bottom: 0;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          display: flex;
          align-items: center;
          height: 48px;
        }

        button.ant-picker-header-next-btn,
        button.ant-picker-header-super-next-btn,
        button.ant-picker-header-prev-btn,
        button.ant-picker-header-super-prev-btn {
          color: #6c757d;
        }

        .calendly-inline-widget iframe {
          overflow: hidden;
        }
      `}
    />
  );
};
