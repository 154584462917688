import axios from 'axios';
import * as R from 'ramda';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import FileDownload from 'js-file-download';
import { getConfiguration } from '~/utils/configurations';

import { AnzaFile } from '~/types/file';
import { catchError, errorHandler } from '~/utils';

import { routes } from './routes';

const TIMEOUT = 300000;
const API_VERSION = '/v1';
const API_VERSION_2 = '/v2';

type ModelAxiosParams = {
  url: string | undefined;
  version?: string;
};

export interface GenericResponseDataObject {
  success: boolean;
  error?: string;
  data?: any;
}

export const maybeGetAuth0RefreshToken = async () => {
  const auth0 = await createAuth0Client({
    domain: getConfiguration('REACT_APP_AUTH0_DOMAIN'),
    clientId: getConfiguration('REACT_APP_AUTH0_CLIENT_ID'),
    useRefreshTokensFallback: true,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  });
  try {
    const { access_token } = await auth0.getTokenSilently({
      detailedResponse: true,
      authorizationParams: {
        scopes: getConfiguration('REACT_APP_AUTH0_SCOPES'),
        redirect_uri: window.location.origin,
        audience: getConfiguration('REACT_APP_AUTH0_AUDIENCE'),
      },
    });

    return access_token;
  } catch (error) {
    catchError({
      error,
      method: 'maybeGetAuth0RefreshToken',
      location: 'src/axios.ts',
    });
    await auth0.logout();
  }

  return null;
};

const getAxiosInstance = (params: ModelAxiosParams) => {
  const { url, version = '' } = params;
  const api = axios.create({
    baseURL: `${url}${version}`,
    timeout: TIMEOUT,
  });

  api.interceptors.request.use(async (reqConfig) => {
    const updatedReqConfig = { ...reqConfig };

    const reqPathsToExclude: (string | undefined)[] = ['/auth', 'status.json'];

    if (!reqPathsToExclude.includes(reqConfig.url)) {
      const refreshedToken = await maybeGetAuth0RefreshToken();
      if (refreshedToken) {
        updatedReqConfig.headers.authorization = `Bearer ${refreshedToken}`;
      }
    }
    return updatedReqConfig;
  });

  return api;
};

export const api = getAxiosInstance({
  url: getConfiguration('REACT_APP_API_URL'),
  version: API_VERSION,
});

export const apiV2 = getAxiosInstance({
  url: getConfiguration('REACT_APP_API_URL'),
  version: API_VERSION_2,
});

export interface CrudOperations {
  getAll: () => Promise<Record<string, unknown>>;
  get: (id: number | string) => Promise<Record<string, unknown>>;
  create: (body: Record<string, unknown>) => Promise<Record<string, unknown>>;
  update: (
    id: number | string,
    body: Record<string, unknown>
  ) => Promise<Record<string, unknown>>;
  delete: (id: number | string) => Promise<Record<string, unknown>>;
  import: (body: Record<string, unknown>) => Promise<Record<string, unknown>>;
}

export const AxiosCrud = (path: string) => {
  const httpClient = getAxiosInstance({
    url: `${getConfiguration('REACT_APP_API_URL')}`,
    version: API_VERSION,
  });
  return {
    getAll: () => httpClient.get(`${path}`).then(R.path(['data', 'data'])),
    get: (id: number | string) =>
      httpClient.get(`${path}/${id}`).then(R.path(['data', 'data'])),
    create: (body: any) =>
      httpClient.post(`${path}`, body).then(R.path(['data', 'data'])),
    update: (id: number | string, body: any) =>
      httpClient.put(`${path}/${id}`, body).then(R.path(['data', 'data'])),
    delete: (id: number | string) =>
      httpClient.delete(`${path}/${id}`).then(R.path(['data', 'data'])),
    import: (fileUploaded: any) => {
      const formData = new FormData();
      formData.append('file', fileUploaded);

      return httpClient.post(`${path}/import`, formData);
    },
  };
};

export const AxiosCrudV2 = (path: string) => {
  const httpClient = getAxiosInstance({
    url: `${getConfiguration('REACT_APP_API_URL')}`,
    version: API_VERSION_2,
  });
  return {
    getAll: () => httpClient.get(`${path}`).then(R.path(['data', 'data'])),
    get: (id: number | string) =>
      httpClient.get(`${path}/${id}`).then(R.path(['data', 'data'])),
    create: (body: any) =>
      httpClient.post(`${path}`, body).then(R.path(['data', 'data'])),
    update: (id: number | string, body: any) =>
      httpClient.put(`${path}/${id}`, body).then(R.path(['data', 'data'])),
    delete: (id: number | string) =>
      httpClient.delete(`${path}/${id}`).then(R.path(['data', 'data'])),
    import: (fileUploaded: any) => {
      const formData = new FormData();
      formData.append('file', fileUploaded);

      return httpClient.post(`${path}/import`, formData);
    },
  };
};

export const getAppStatus = async (): Promise<any> => {
  try {
    const httpClient = getAxiosInstance({
      url: '/',
    });
    return await httpClient.get('status.json');
  } catch (error) {
    errorHandler(`Status file not found - status.json`);
  }

  return {};
};

export const authApi = getAxiosInstance({
  url: getConfiguration('REACT_APP_AUTH_API_URL'),
});

export const getAnzaProjectLayoutFile = (
  file: AnzaFile,
  projectUuid: string
) => {
  const httpClient = getAxiosInstance({
    url: `${getConfiguration('REACT_APP_API_URL')}`,
    version: API_VERSION,
  });
  return httpClient({
    url: `${routes.userProject.downloadFile(projectUuid)}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => FileDownload(response.data, file.file_name))
    .catch((error) => errorHandler(error));
};
