import React from 'react';
import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ButtonAnt as Button, Checkbox, Form, Divider } from '~/UI';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';
import { colors, fonts } from '~/utils';
import { GenericLabel } from '~/components/Projects/FormGroup';
import {
  GTM_EVENTS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';
import { groupFilterDropdownPadding } from './constants';
import type { FilterMenuFilterConfig } from './FilterMenu';

const ModuleHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 350px;
  margin-bottom: 24px;
  button {
    padding: 0;
  }
`;

export const StyledCheckBoxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-checkbox-wrapper {
    margin-left: 0;
    font-weight: 400;
    font-size: ${fonts.sizes.button};
    line-height: 22px;
    color: ${colors.gray800};
    max-width: 100%;
    white-space: normal;
    text-align: left;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled + span {
    color: ${colors.gray400};
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.gray600};
    min-width: 18px;
    min-height: 18px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.primary};
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: transparent;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0 -${groupFilterDropdownPadding} 24px -${groupFilterDropdownPadding};
  width: auto;
`;

export const FilterHeader = ({
  name,
  title,
  fontSize = '16px',
  onClearFilter,
  section,
  dataTestIdConfig,
  hasHeaderClearButton,
  ...rest
}: {
  name: string;
  title: string;
  showPopoverTitle?: boolean;
  popoverContent?: string;
  fontSize?: string;
  section: string;
  onClearFilter?: (filterName: string) => void;
  dataTestIdConfig?: TestIdProps;
  hasHeaderClearButton: FilterMenuFilterConfig['hasHeaderClearButton'];
}) => {
  const dataTestConfig = dataTestIdConfig || {};
  return (
    <ModuleHeaderContainer>
      <GenericLabel
        testIdData={{
          ...dataTestConfig,
          component: TEST_DATA_COMPONENTS.TEXT,
          identifier: `${section}-title`,
        }}
        fontSize={fontSize}
        title={title}
        {...rest}
      />
      {hasHeaderClearButton && (
        <Button
          data-testid={generateTestId({
            ...dataTestConfig,
            component: TEST_DATA_COMPONENTS.BUTTON,
            identifier: `${section}-clear`,
          })}
          type="link"
          onClick={() => {
            if (onClearFilter) onClearFilter(name);
          }}
        >
          Clear
        </Button>
      )}
    </ModuleHeaderContainer>
  );
};

export type Option = {
  label: React.ReactNode;
  value: string;
  dataTestId?: string;
};

export type FilterProps = {
  name: string;
  title: string;
  options: Option[];
  onClearFilter?: (name: string) => void;
  headerDataTestIdConfig?: TestIdProps;
  hasReachedLimit?: boolean;
  selectedFilters?: string[];
  hasHeaderClearButton?: boolean;
  includeGtmProperties?: boolean;
};

const Filter = ({
  name,
  title,
  onClearFilter,
  options,
  headerDataTestIdConfig,
  hasReachedLimit,
  selectedFilters,
  hasHeaderClearButton,
  includeGtmProperties = false,
}: FilterProps) => (
  <Form.Item
    name={name}
    label={
      <FilterHeader
        name={name}
        title={title}
        onClearFilter={() => {
          if (onClearFilter) onClearFilter(name);
        }}
        section={`module-${name}`}
        dataTestIdConfig={headerDataTestIdConfig}
        hasHeaderClearButton={hasHeaderClearButton}
      />
    }
    labelCol={{ span: 24 }}
  >
    <StyledCheckBoxGroup>
      {options.map(({ label, value, dataTestId }) => {
        const infoFilterAnalytics = includeGtmProperties
          ? {
              'data-gtm-type': GTM_SOURCE_TYPES.FILTER,
              'data-gtm-id': value,
            }
          : {};
        return (
          <div key={value} {...infoFilterAnalytics}>
            <StyledCheckbox
              value={value}
              data-testid={dataTestId}
              disabled={hasReachedLimit && !selectedFilters?.includes(value)}
              onChange={
                includeGtmProperties
                  ? (e: CheckboxChangeEvent) => {
                      const event = e.target.checked
                        ? GTM_EVENTS.CHECK_BOX_CHECKED
                        : GTM_EVENTS.CHECK_BOX_UNCHECKED;
                      maybeSendPlatformEventToGTM(event, value);
                    }
                  : undefined
              }
            >
              {label}
            </StyledCheckbox>
          </div>
        );
      })}
    </StyledCheckBoxGroup>
  </Form.Item>
);

export default Filter;
