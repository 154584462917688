import { ascend, sortWith, take, values } from 'ramda';
import {
  storageEngineVersion1,
  storageEngineVersion2,
  storageEngineVersion3,
  storageEngineVersion4,
} from '~/constants/rankingTable';
import { EngineResultSizingArchitectureRow } from '~/components/Projects/storage/StorageModuleTable';
import { AugmentationStrategiesText } from './storageRankingTableCalculus';

const NUMBER_OF_YEARS_TO_PRESENT = 21; // 20 years of data plus the header

const createHeaderArrays = (columns: string[], values: any[]) => {
  const headerArrayColumns = ['', ...columns];
  const headerArrayValues = ['', ...values];
  const headerSpaceArray = new Array(columns.length + 1).fill('');
  return { headerArrayColumns, headerArrayValues, headerSpaceArray };
};

export const buildSystemSummaryCSV = (
  data: EngineResultSizingArchitectureRow,
  storageEngineVersion?: string
) => {
  const {
    target_ac_power_MW,
    duration_hours,
    power_factor,
    annual_cycle_count,
    metering_location,
    project_topology,
    capacity_maintenance_term_years,
    capacity_maintenance_strategy,
    delivery_date,
    resting_state_of_charge_percent,
    depth_of_discharge_percent,
    pcs_warranty_years,
    pcs_maintenance_years,
    dc_block_warranty_years,
    dc_block_maintenance_years,
  } = data.engineInputData;

  const { headerArrayColumns, headerArrayValues, headerSpaceArray } =
    createHeaderArrays(
      [
        'Target AC Power',
        'Duration',
        'Power Factor',
        'Annual Cycle Count',
        'Metering Location',
        'Project Topology',
        'Capacity Maintenance Term',
        'Capacity Maintenance Strategy',
        'Delivery Date',
        'Average Resting State of Charge',
        'Depth of Discharge',
        'PCS Extended Warranty Term',
        'PCS Preventative Maintenance Term',
        'AC/DC Block Extended Warranty Term',
        'AC/DC Block Preventative Maintenance Term',
      ],
      [
        target_ac_power_MW,
        duration_hours,
        power_factor,
        annual_cycle_count,
        metering_location,
        project_topology,
        capacity_maintenance_term_years,
        capacity_maintenance_strategy,
        delivery_date,
        resting_state_of_charge_percent,
        depth_of_discharge_percent,
        pcs_warranty_years,
        pcs_maintenance_years,
        dc_block_warranty_years,
        dc_block_maintenance_years,
      ]
    );

  const csvArray = [
    headerArrayColumns,
    headerArrayValues,
    headerSpaceArray,
    headerSpaceArray,
    headerSpaceArray,
  ];

  const getDcBlockRoundTripEfficiencyProp = (
    data: EngineResultSizingArchitectureRow,
    storageEngineVersion = 'storage_v1'
  ) => {
    switch (storageEngineVersion) {
      case storageEngineVersion1:
        return data.dc_block.DCRoundTripEfficiency_RTE_;
      case storageEngineVersion2:
        return data.dc_block.round_trip_efficiency_percent;
      case storageEngineVersion3:
        if (data.dc_block.product_archetype === 'AC Block - All-in-one') {
          return data.dc_block.ac_block_round_trip_efficiency_percent;
        }
        return data.dc_block.dc_block_round_trip_efficiency_percent;
      case storageEngineVersion4:
        if (data.dc_block.product_archetype === 'AC Block - All-in-one') {
          return data.dc_block.ac_block_round_trip_efficiency_percent;
        }
        return data.dc_block.dc_block_round_trip_efficiency_percent;
      default:
        return data.dc_block.dc_block_round_trip_efficiency_percent;
    }
  };

  // build second section of headers, only add values to the first column
  const bodySpaceArray = ['', '', ''];
  const schedules = values(data.augmentation_data);
  const takeTopFive = take(5);
  takeTopFive(
    sortWith(
      [ascend((schedule) => schedule.weighted_life_cycle_cost_USD)],
      schedules
    )
  ).forEach((schedule, index) => {
    // Just the first array includes more columns than the rest. This is because the way we want to display the data
    // Also the we adding the specific items we need to display 20 years of data
    const scheduleArrayItem = [
      [
        'Schedule',
        index + 1,
        '',
        'Year',
        'PCS Count',
        'AC/DC Block Count',
        'MWh AC Useable',
        'AC/DC Block Preventative Maintenance',
        'AC/DC Block Extended Warranty',
        'PCS Preventative Maintenance',
        'PCS Extended Warranty',
        'Augmentation CapEx',
        'Lifecycle Cost',
      ],
      ['AC/DC Block Model', data.dc_block.ProductName, ''],
      ['AC/DC Block Manufacturer', data.dc_block.Manufacturer, ''],
      ['PCS Model', data.pcs_module.ProductName, ''],
      ['PCS Manufacturer', data.pcs_module.Manufacturer, ''],
      ['AC/DC Block Count, BOL', schedule.beginning_of_life_dc_block_count, ''],
      [
        'PCS Count, BOL',
        storageEngineVersion === storageEngineVersion3 ||
        storageEngineVersion === storageEngineVersion4
          ? schedule.pcs_deployment_schedule[0]
          : schedule.pcs_module_count,
        '',
      ],
      [
        'Nameplate Energy, kWh',
        schedule.beginning_of_life_nameplate_energy_kWh,
        '',
      ],
      ['CapEx', schedule.beginning_of_life_capex_USD, ''],
      ['Lifecycle Cost', schedule.weighted_life_cycle_cost_USD, ''],
      [
        'System Round Trip Efficiency, BOL',
        schedule.system_round_trip_efficiency_percent,
        '',
      ],
      [
        'AC/DC Block Round Trip Efficiency',
        getDcBlockRoundTripEfficiencyProp(data, storageEngineVersion),
        '',
      ],
      [
        'PCS Efficiency',
        storageEngineVersion === storageEngineVersion1
          ? data.pcs_module.Efficiency
          : data.pcs_module.efficiency_percent,
        '',
      ],
      data.ac_data.loss_factor_display?.['DC Cabling Loss'] !== undefined
        ? [
            'DC Cabling Loss',
            data.ac_data.loss_factor_display['DC Cabling Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['DC Collection Loss'] !== undefined
        ? [
            'DC Collection Loss',
            data.ac_data.loss_factor_display['DC Collection Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['DC Converter Loss'] !== undefined
        ? [
            'DC Converter Loss',
            data.ac_data.loss_factor_display['DC Converter Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['HV Transformer Loss'] !== undefined
        ? [
            'HV Transformer Loss',
            data.ac_data.loss_factor_display['HV Transformer Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['LV Cabling Loss'] !== undefined
        ? [
            'LV Cabling Loss',
            data.ac_data.loss_factor_display['LV Cabling Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['MV Cabling Loss'] !== undefined
        ? [
            'MV Cabling Loss',
            data.ac_data.loss_factor_display['MV Cabling Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['MV Collection Loss'] !== undefined
        ? [
            'MV Collection Loss',
            data.ac_data.loss_factor_display['MV Collection Loss'],
            '',
          ]
        : [],
      data.ac_data.loss_factor_display?.['MV Transformer Loss'] !== undefined
        ? [
            'MV Transformer Loss',
            data.ac_data.loss_factor_display['MV Transformer Loss'],
            '',
          ]
        : [],
      data.dc_block.product_archetype !== undefined
        ? ['Product Archetype', data.dc_block.product_archetype, '']
        : [],
      schedule.augmentation_strategy !== undefined
        ? [
            'Augmentation Strategy',
            AugmentationStrategiesText[schedule.augmentation_strategy],
            '',
          ]
        : [],
    ];

    const filteredArrayItem = scheduleArrayItem.filter(
      (item) => item.length > 0
    );

    const linesToAdd = NUMBER_OF_YEARS_TO_PRESENT - filteredArrayItem.length;
    for (let i = 0; i <= linesToAdd; i += 1) {
      filteredArrayItem.push(bodySpaceArray);
    }
    // We iterate trough the maximum number of years to present, and we add the values to the array, stating from the position 1
    let year = 0;
    const mappedArrayItems = filteredArrayItem.map((item, index) => {
      if (index === 0) return item;
      const dcBlockPreventativeMaintenanceValue =
        schedule.dc_block_warranty_and_pm_lumped_together && index === 1
          ? 'Warranty Includes Preventative Maintenance'
          : '';
      const pcsBlockLifeCycleCostValue =
        schedule.pcs_warranty_and_pm_lumped_together && index === 1
          ? 'Warranty and Preventative Maintenance Lumped Together'
          : '';

      const newValues = [
        year,
        storageEngineVersion === storageEngineVersion3 ||
        storageEngineVersion === storageEngineVersion4
          ? schedule.pcs_cumulative_deployment_schedule_list[year]
          : schedule.pcs_module_count,
        `${schedule.cumulative_deployment_schedule_list?.[year] || 0}`,
        `${schedule.deployed_energy_list?.[year] || 0}`,
        schedule.dc_block_warranty_and_pm_lumped_together
          ? dcBlockPreventativeMaintenanceValue
          : `$${schedule.unweighted_dc_block_pm_life_cycle_cost_list_USD?.[year]}`,
        `$${schedule.unweighted_dc_block_warranty_life_cycle_cost_list_USD?.[year]}`,
        // We need to have this logic because the PCS warranty and PM can be lumped together
        // we don't know which list will have the value. This was Connors proposal

        // PCS Preventative Maintenance
        schedule.pcs_warranty_and_pm_lumped_together
          ? pcsBlockLifeCycleCostValue
          : `${
              schedule.unweighted_pcs_pm_life_cycle_cost_list_USD?.[year] ||
              schedule.unweighted_pcs_warranty_life_cycle_cost_list_USD?.[year]
            }`,
        // PCS Extended Warranty
        schedule.pcs_warranty_and_pm_lumped_together
          ? `$${
              schedule.unweighted_pcs_pm_life_cycle_cost_list_USD?.[year] ||
              schedule.unweighted_pcs_warranty_life_cycle_cost_list_USD?.[year]
            }`
          : `$${schedule.unweighted_pcs_warranty_life_cycle_cost_list_USD?.[year]}`,

        `$${schedule.unweighted_augmentation_cost_list_USD?.[year] || 0}`,
        `$${schedule.unweighted_life_cycle_cost_list_USD?.[year] || 0}`,
      ];
      year += 1;
      return year <= NUMBER_OF_YEARS_TO_PRESENT
        ? [...item, ...newValues]
        : item;
    });

    // Spaces required to separate the schedules
    mappedArrayItems.push(headerSpaceArray, headerSpaceArray, headerSpaceArray);
    csvArray.push(...mappedArrayItems);
  });
  return csvArray;
};
