import React from 'react';
import { Global, css } from '@emotion/react';

export const AdminGlobalStyle = () => {
  return (
    <Global
      styles={css`
        .ant-col-24.ant-form-item-label {
          padding-bottom: 0px;
        }
        .ant-form-item {
          margin-bottom: 5px;
        }
        .ant-form-item-control-input {
          min-height: 20px !important;
        }
        .ant-form-item-control-input-content .ant-input-number,
        .ant-input-number-wrapper .ant-input-number {
          height: 32px !important;
          padding: 4px 0;
        }
        .ant-input-number-input {
          height: inherit;
        }
        .ant-form-item-control-input-content .ant-picker {
          height: 32px !important;
          .ant-picker-input input {
            font-size: 14px;
          }
        }
        .ant-picker-input {
          height: inherit;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 32px !important;
        }
        .ant-select-selection-search .ant-select-selection-search-input {
          height: 28px !important;
        }
        .ant-input {
          height: 32px;
        }
      `}
    />
  );
};
