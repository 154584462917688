import React, { useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { Spin } from 'antd';
import debounce from 'lodash.debounce';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

import {
  getLocationByGooglePlaceId,
  searchLocation,
} from '~/services/api/projects';
import { catchError, theme } from '~/utils';
import { Spinner } from '~/UI/index';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';

import Autocomplete from './Autocomplete';

const { Option: AutocompleteOption } = Autocomplete;

interface AutocompleteInputProps {
  hasSelectedValue?: (value: boolean) => void;
  onSearchAddress?: (value: string) => void;
  onSelectLocation?: (value: AddressSearchResponseType) => void;
  isLocationLoading?: (value: boolean) => void;
  onValueChange?: (value: string) => void;
  bordered?: boolean;
  disabled?: boolean;
}

interface Address {
  place_id: string;
  description: string;
}

const globalStyle = css`
  .ant-select-item.ant-select-item-option.address-autocomplete-loading {
    .ant-select-item-option-content {
      display: flex;
      justify-content: center;
    }
  }
  .ant-select-item.ant-select-item-option-disabled.address-autocomplete-no-results {
    .ant-select-item-option-content {
      color: ${theme.colors.graphite};
    }
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  .ant-select-selector {
    .ant-select-selection-search {
      position: relative !important;
      width: inherit !important;
      left: 0 !important;
    }
    .ant-select-selection-placeholder {
      position: absolute;
      width: 100%;
      color: ${theme.colors.stone};
    }
  }
`;

export const parseAddress = (address: Record<string, string>) => {
  let formattedAddress = '';

  const filtered = Object.values(address).filter((val) => val.length > 1);

  filtered.forEach((value, index) => {
    if (filtered.length <= 1 || index === filtered.length - 1) {
      formattedAddress += value;
    } else {
      formattedAddress += `${value}, `;
    }
  });

  return formattedAddress;
};

const AddressSearch: React.FC<AutocompleteInputProps> = ({
  onSearchAddress,
  hasSelectedValue,
  onSelectLocation,
  isLocationLoading,
  onValueChange,
  bordered = true,
  disabled = false,
  ...rest
}) => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [addresses, setAddresses] = React.useState<Address[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasSelectedValueFromList, setHasSelectedValueFromList] =
    React.useState(false);

  const handleSearchValueChange = useCallback(
    debounce((searchString: string) => setSearchValue(searchString), 300),
    []
  );

  const handleSearchByAddress = async (searchAddress: string) => {
    setIsLoading(true);
    try {
      if (searchAddress === '') {
        setAddresses([]);
      } else {
        const res = await searchLocation(searchAddress);
        const { predictions } = res.data;
        setAddresses(predictions);

        if (onSearchAddress) {
          onSearchAddress(predictions);
        }
      }
    } catch (error) {
      catchError({
        error: new Error(`Server error: ${error}`),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchAddress = async (searchTerm: string) => {
    await handleSearchByAddress(searchTerm); // TODO: turn this into a api hook
  };

  useEffect(() => {
    setHasSelectedValueFromList(false);

    if (onValueChange) {
      onValueChange(searchValue);
    }

    if (hasSelectedValue) {
      hasSelectedValue(hasSelectedValueFromList);
    }

    handleSearchAddress(searchValue);
  }, [searchValue]);

  const handleSelectLocation = async (
    _: any,
    option: BaseOptionType | DefaultOptionType
  ) => {
    setIsLoading(true);
    const { key: placeId } = option;
    const place = await getLocationByGooglePlaceId(placeId);
    if (onSelectLocation) {
      const { data: placeData } = place;
      const locationData: AddressSearchResponseType = {
        lat: placeData.location.lat,
        lng: placeData.location.lng,
        address: option.value?.toString(),
        state: placeData.address.state,
        placeId: placeData.placeId,
      };
      setHasSelectedValueFromList(true);
      onSelectLocation(locationData);
    }

    setIsLoading(false);
  };

  const getAutoCompleteOptions = (loading: boolean, addressList: Address[]) => {
    if (loading) {
      return (
        <AutocompleteOption
          key={-1}
          value={-1}
          className="address-autocomplete-loading"
        >
          <Spinner />
        </AutocompleteOption>
      );
    }

    if (!addressList?.length && !!searchValue) {
      return (
        <AutocompleteOption
          key={-1}
          value={-1}
          disabled
          className="address-autocomplete-no-results"
        >
          Address not found, try again
        </AutocompleteOption>
      );
    }

    return addresses.map((address: Address) => (
      <AutocompleteOption key={address.place_id} value={address.description}>
        {address.description}
      </AutocompleteOption>
    ));
  };

  const dataFetchOccurring = isLoading;

  useEffect(() => {
    if (isLocationLoading) {
      isLocationLoading(dataFetchOccurring);
    }
  }, [isLocationLoading, dataFetchOccurring]);

  return (
    <>
      <Global styles={globalStyle} />
      <Spin spinning={isLoading} indicator={<></>}>
        <StyledAutocomplete
          disabled={disabled}
          bordered={bordered}
          style={{ width: '100%' }}
          onSearch={handleSearchValueChange}
          placeholder="Search location..."
          onSelect={handleSelectLocation}
          dropdownMatchSelectWidth={false}
          {...rest}
        >
          {getAutoCompleteOptions(dataFetchOccurring, addresses)}
        </StyledAutocomplete>
      </Spin>
    </>
  );
};

export default AddressSearch;
