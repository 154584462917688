import React from 'react';
import styled from '@emotion/styled';

import { colors } from '~/utils';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';

const BannerContentContainer = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.white};
  padding: 52px 0;

  .tagLine {
    font-size: 36px;
    font-weight: 800;
    line-height: 100%;
    margin-bottom: 12px;
  }
`;

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.TEXT,
};

const SeeMoreBuyBetter: React.FC = () => {
  return (
    <BannerContentContainer
      data-testid={generateTestId({
        ...dataTestIdConfig,
        identifier: 'see-more-banner',
      })}
    >
      <div className="tagLine">See More. Buy Better.</div>
      Choose from our large inventory of the best modules for your needs
    </BannerContentContainer>
  );
};

export default SeeMoreBuyBetter;
