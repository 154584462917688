import styled from '@emotion/styled';
import { Radio as RadioAnt } from 'antd';
import {
  RadioProps as RadioAntProps,
  RadioGroupProps as RadioGroupAntProps,
} from 'antd/lib/radio';

import { theme } from '~/utils';

export type RadioProps = RadioAntProps;
export type RadioGroupProps = RadioGroupAntProps;

const StyledRadio = styled(RadioAnt)<RadioAntProps>`
  .ant-radio-inner {
    width: 22px;
    height: 22px;
    border: 1px solid ${theme.colors.stone};
  }
  .ant-radio-inner:after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`;

export const RadioGroup = RadioAnt.Group;
export const RadioButton = RadioAnt.Button;

export default StyledRadio;
