import React from 'react';
import styled from '@emotion/styled';
import { getConfiguration } from '~/utils/configurations';

import type {
  BaseProject,
  ProjectDetails as ProjectDetailsType,
  ProjectType,
} from '~/store/project/types';
import { colors, isUserEngagedInProject, theme } from '~/utils';
import { Alert, ButtonAnt as Button, Space } from '~/UI';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';
import { useFlags } from '~/utils/launchdarkly';
import ExternalLinkWithAnalytics from '~/components/ExternalLinkWithAnalytics';
import { GTM_SOURCE_IDS } from '~/utils/gtm';
import useAuth from '~/hooks/useAuth';
import ExternalLink from '~/components/ExternalLink';

import { IconAlarm, IconTipsAndUpdates } from './Icons';

const CALENDLY_URL = 'https://calendly.com/anzarenewables';

type ExtractedStatuses = Extract<
  ProjectDetailsType['status'],
  'created' | 'proposal'
>;

interface Props {
  project: BaseProject;
  status: ProjectDetailsType['status'];
  dataTestIdConfig: TestIdProps;
}

type StatusesMap = Record<
  ProjectType,
  Record<ExtractedStatuses, React.ReactNode>
>;

const AlertContainer = styled.div`
  width: 100%;
`;

const ProjectDetailsAlert = ({ project, status, dataTestIdConfig }: Props) => {
  const { solarPpuBanner } = useFlags();
  const { user } = useAuth();
  const isUserEngaged = isUserEngagedInProject(user, project);
  const showPricingPolicyBanner = solarPpuBanner && !isUserEngaged;

  const projectType = project.project_details.project_type;

  const storageBannerContent = (
    <Space size="xs">
      <span>
        View lifecycle cost data for 20+ AC and DC ESS vendors to find the
        optimal product and deal.
      </span>
      <ExternalLink href="https://calendly.com/talk_with_anza">
        <u> Meet with our sales team today for access!</u>
      </ExternalLink>
    </Space>
  );

  const mapMessageFromStatus: StatusesMap = {
    solar: {
      created:
        'See exciting new filters to narrow your module selection by risk and technical elements from our industry-leading product database by clicking Advanced View. Only available to engaged clients.',
      proposal:
        'See exciting new filters to narrow your module selection by risk and technical elements from our industry-leading product database by clicking Advanced View. Only available to engaged clients.',
    },
    storage: {
      created: storageBannerContent,
      proposal: storageBannerContent,
    },
  };

  const getFontColorByStatus = () =>
    status === 'created' ? colors.info4 : colors.info4light;

  const getBgColorByStatus = () =>
    status === 'created' ? colors.info1 : colors.info1light;

  return (
    <AlertContainer
      style={{
        background: getBgColorByStatus(),
      }}
    >
      <Alert
        message={
          <span
            data-testid={generateTestId({
              ...dataTestIdConfig,
              component: `${TEST_DATA_COMPONENTS.ALERT}-${TEST_DATA_COMPONENTS.TEXT}`,
            })}
            style={{
              color: getFontColorByStatus(),
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              maxWidth: theme.dimensions.width.max,
            }}
          >
            {mapMessageFromStatus[projectType][status as ExtractedStatuses]}
            {status === 'proposal' &&
              showPricingPolicyBanner &&
              projectType === 'solar' && (
                <ExternalLinkWithAnalytics
                  style={{
                    paddingLeft: '5px',
                    textDecoration: 'underline',
                  }}
                  gtmId={GTM_SOURCE_IDS.CONTACT.TALK_TO_YOUR_MANAGER}
                  href={CALENDLY_URL}
                >
                  Talk to Anza sales today!
                </ExternalLinkWithAnalytics>
              )}
            {status === 'proposal' &&
              !isUserEngaged &&
              !showPricingPolicyBanner && (
                <a
                  data-testid={generateTestId({
                    ...dataTestIdConfig,
                    component: `${TEST_DATA_COMPONENTS.ALERT}-${TEST_DATA_COMPONENTS.BUTTON}`,
                    identifier: 'learn-more',
                  })}
                  href={getConfiguration('REACT_APP_CALENDLY_SOLAR_URL')}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    size="middle"
                    color="#10697D"
                    ghost
                    style={{
                      color: '#10697D',
                      marginLeft: '0.5rem',
                    }}
                  >
                    Schedule a Meeting
                  </Button>
                </a>
              )}
          </span>
        }
        icon={
          status === 'created' ? (
            <IconTipsAndUpdates color={getFontColorByStatus()} size={20} />
          ) : (
            <IconAlarm color={getFontColorByStatus()} size={20} />
          )
        }
        showIcon
        style={{
          color: getFontColorByStatus(),
          background: getBgColorByStatus(),
          borderColor: getBgColorByStatus(),
          height: '54px',
          maxWidth: theme.dimensions.width.max,
          margin: 'auto',
          padding: '0 40px',
        }}
        data-testid={generateTestId({
          ...dataTestIdConfig,
          component: TEST_DATA_COMPONENTS.ALERT,
        })}
      />
    </AlertContainer>
  );
};

export default ProjectDetailsAlert;
