import React from 'react';
import styled from '@emotion/styled';
import { Select as SelectAnt } from 'antd';
import { SelectProps } from 'antd/lib/select';
import Form, { FormItemProps } from '~/UI/Form';

import { theme } from '../utils';
import { IconChevronDown, IconChevronUp } from './Icons';

export { type SelectProps };

const StyledSelect = styled(SelectAnt)<any>`
  color: ${theme.colors.charcoal};

  .ant-select-selector {
    border: ${(props) => props.bordered === false && 'none'}!important;
  }

  .ant-select-selector {
    cursor: pointer !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: ${theme.colors.graphite};
  }
  .ant-select-arrow {
    height: 18px;
    width: 18px;
    font-size: 18px;
    top: 46.5%;
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 44px;
    cursor: pointer;
  }

  .ant-select-item-option-disabled {
    color: ${theme.colors.gray400};
  }
`;

const Select: React.FC<SelectProps> = (props) => {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const toggleDropdownVisible = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const suffixIcon = dropdownVisible ? <IconChevronUp /> : <IconChevronDown />;

  return (
    <StyledSelect
      suffixIcon={suffixIcon}
      open={dropdownVisible}
      onDropdownVisibleChange={toggleDropdownVisible}
      getPopupContainer={
        props.getPopupContainer ??
        ((trigger: { parentNode: Node }) => trigger.parentNode)
      }
      {...props}
    />
  );
};

type SelectFormItemProps = {
  children?: React.ReactNode;
  selectProps: SelectProps & {
    'data-testid'?: string;
  };
} & FormItemProps;

export const SelectFormItem = (props: SelectFormItemProps) => {
  const { children, selectProps, ...formItemProps } = props;
  return (
    <Form.Item validateTrigger="onChange" {...formItemProps}>
      <Select {...selectProps}>{children}</Select>
    </Form.Item>
  );
};

export const SelectOption = SelectAnt.Option;

export default Select;
