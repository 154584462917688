import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { UiPayload, UiType } from './types';

export const initialState: UiPayload = {
  pageBanner: null,
};

const setPageBanner: CaseReducer<UiType, PayloadAction<UiPayload>> = (
  state,
  action
) => {
  return {
    ...state,
    pageBanner: action.payload.pageBanner,
  };
};

const removePageBanner: CaseReducer<UiType> = (state) => {
  return {
    ...state,
    pageBanner: null,
  };
};

const reducers = {
  setPageBanner,
  removePageBanner,
};

export default reducers;
