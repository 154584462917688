import { ProjectStatus } from '~/store/project';

export const ModelStatuses = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
};

export const CompanyType = {
  CORP: 'CORP',
  CUSTOMER: 'CUSTOMER',
  VENDOR: 'VENDOR',
  RACK_VENDOR: 'RACK_VENDOR',
  INVERTER_COMPANY: 'INVERTER_COMPANY',
};

export const ServerResponses = {
  UNAUTHENTICATED: 401,
  VALIDATION: 400,
  NOT_FOUND: 404,
};

export const ModuleOwnershipStatuses = {
  VENDOR: 'vendor',
  INVENTORY: 'inventory',
  PURCHASE_ORDER: 'purchaseOrder',
};

export enum REGION {
  MIDWEST = 'Midwest',
  WEST = 'West',
  SOUTH = 'South',
  NORTHEAST = 'Northeast',
}

export const MAILTO = 'support-anza@anzarenewables.com';

export const NODE_ENVS = {
  LOCAL: 'local',
  DEV: 'dev',
  DEV2: 'dev2',
  STAGE: 'stage',
  TEST: 'test',
  DEMO: 'demo',
  DEMO2: 'demo2',
  PROD: 'prod',
};

export const meteringLocationOptions = [
  'AC Output of Inverter',
  'High Side of MV Transformer(s)',
  'HV Side of Main Power Transformer',
];

export const CAPACITY_MAINTENANCE_STRATEGY_OPTIONS = [
  'Optimal',
  'Overbuild Only',
];

export const projectTopologyOptions = [
  'Standalone',
  'AC-Coupled',
  'DC-Coupled (coming soon)',
];

export const cellTypes = {
  half: 'half',
  full: 'full',
};

export const fileTypes = {
  ANZA_PAN_FILE: {
    type: 'anzaPanFile',
    extension: ['pan'],
  },
  MANUFACTURER_PAN_FILE: {
    type: 'manufacturerPanFile',
    extension: ['pan'],
  },
  THIRD_PARTY_PAN_FILE: {
    type: 'thirdPartyPanFile',
    extension: ['pan'],
  },
  MODULE_DATASHEET: {
    type: 'moduleDatasheet',
    extension: ['pdf'],
  },
  ANZA_PAN_FILE_SUPPORTING_FILE: {
    type: 'anzaPanFileSupportingFile',
  },
  MANUFACTURER_PAN_FILE_SUPPORTING_FILE: {
    type: 'manufacturerPanFileSupportingFile',
  },
  THIRD_PARTY_PAN_FILE_SUPPORTING_FILE: {
    type: 'thirdPartyPanFileSupportingFile',
  },
  DATASHEET_SUPPORTING_FILE: {
    type: 'datasheetSupportingFile',
  },
  ANZA_PAN_SUPPORTING_FILES_ZIP: { type: 'anzaPanSupportingFilesZip' },
  THIRD_PARTY_PAN_SUPPORTING_FILES_ZIP: {
    type: 'thirdPartyPanSupportingFilesZip',
  },
  MANUFACTURER_PAN_SUPPORTING_FILES_ZIP: {
    type: 'manufacturerPanSupportingFilesZip',
  },
};

export const moduleFileCategories = {
  LID_REPORT: 'LID report',
  IAM_REPORT: 'IAM report',
  PAN_REPORT: 'PAN report',
  PAN_FILE: 'PAN file',
  OTHER: 'Other',
};

export const GSHEET_URL_PREFIX = 'https://docs.google.com/spreadsheets/u/1/d/';
export const projectIsClosed = (status: ProjectStatus) => {
  return status === 'lost' || status === 'inactive';
};

export const maxSafeDate = new Date(8640000000000000);
export const minSafeDate = new Date(-8640000000000000);

export const LOCAL_STORAGE_KEYS = {
  BLOCKED_ANNOUNCEMENTS: 'BLOCKED_ANNOUNCEMENTS',
};

// Use this enum instead of the rackingTypes array below.
export enum TAX_CREDIT_METHODOLOGY {
  ITC = 'ITC',
  PTC = 'PTC',
}

export const TAX_CREDIT_METHODOLOGY_OPTIONS = [
  TAX_CREDIT_METHODOLOGY.ITC,
  TAX_CREDIT_METHODOLOGY.PTC,
];
