export const formErrorScroll = (className?: string) => {
  // settimeout to let Antd validation set class into the DOM and find it in a differente js run time
  setTimeout(() => {
    const firstErrorField = document.querySelector('.ant-form-item-has-error');
    if (firstErrorField) {
      const margin = 110;
      const topPosition =
        firstErrorField.getBoundingClientRect().top + window.scrollY - margin;

      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    } else {
      const firstErrorFieldByClass = document.querySelector(`.${className}`);
      if (firstErrorFieldByClass) {
        const margin = 110;
        const topPosition =
          firstErrorFieldByClass.getBoundingClientRect().top +
          window.scrollY -
          margin;

        window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
    }
  }, 0);
};
