import { getConfiguration } from '~/utils/configurations';

type HsEvent = 'setPath' | 'trackPageView' | 'identify';
type HsProperties =
  | [HsEvent, string?]
  | [HsEvent, Partial<Record<'id' | 'email', string>>];

export function createHsWindow() {
  window._hsq = window._hsq || [];
  window._hsq.push(['setPath', '/']);
}

export function loadHsScript() {
  const script = document.createElement('script');
  script.src = `https://js.hs-scripts.com/${
    getConfiguration('REACT_APP_HUBSPOT_ID') || '8806820'
  }.js`;
  script.async = true;
  script.defer = true;
  script.id = 'hs-script-loader';
  script.type = 'text/javascript';

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}

function trackHsEvent(properties: HsProperties) {
  if (window && window._hsq) {
    window._hsq.push(properties);
  }
}

export function setHsPath(path: string) {
  trackHsEvent(['setPath', path]);
}

export function trackHsPageView() {
  trackHsEvent(['trackPageView']);
}

export function identifyHsVisitor(uuid: string) {
  trackHsEvent(['identify', { id: uuid }]);
}

export function identifyHsVisitorByEmail(email: string) {
  trackHsEvent(['identify', { email }]);
}
