import React from 'react';
import { theme } from '~/utils';

export interface TooltipProps {
  rightValue: number | string;
  rightLabel?: string;
  leftValue: number | string;
  leftLabel?: string;
  valueFormatterToUse: (value: number) => number | string;
}

export const NoTooltip = () => null;

export const TwoColumnTooltip: React.FC<TooltipProps> = ({
  rightValue,
  rightLabel,
  leftValue,
  leftLabel,
  valueFormatterToUse,
}) => (
  <div
    style={{
      height: 60,
      width: 150,
      background: '#FFFFFF',
      border: '1px solid #F2F2F2',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.28)',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '16px',
    }}
  >
    <div
      style={{
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '10px',
      }}
    >
      <div style={{ fontWeight: 700 }}>{leftValue || '0'}</div>
      <div style={{ fontSize: '14px' }}>{leftLabel}</div>
    </div>
    <div
      style={{
        width: 1,
        margin: '5px',
        borderRight: theme.borders.lightGray,
      }}
    />
    <div
      style={{
        display: 'flex',
        width: '50%',
        padding: '5px',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <div style={{ fontWeight: 700 }}>
        {valueFormatterToUse(rightValue as number) || '0'}
      </div>
      {rightLabel && <div style={{ fontSize: '14px' }}>{rightLabel}</div>}
    </div>
  </div>
);
