import React from 'react';

import { useGetModulesQuery, useGetModuleQueryV1 } from '~/store/api';
import { rtkService } from '~/types/rtkApi';
import { ModuleType } from '~/types/modules';

import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminModulesForm from '../components/Admin/Modules/AdminModulesForm';
import AdminModulesList from '../components/Admin/Modules/AdminModulesList';

const AdminModules: React.FC = () => {
  const service = {
    get: useGetModuleQueryV1,
    getAll: useGetModulesQuery,
  } as unknown as rtkService<ModuleType>;

  return (
    <AdminModuleRouter<ModuleType>
      name="Modules"
      listComponent={AdminModulesList}
      formComponent={AdminModulesForm}
      path={ADMIN_ROUTES.MODULES.path}
      service={service}
    />
  );
};

export default AdminModules;
