import React from 'react';
import {
  useGetAllDomainsQuery,
  useGetDomainQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
} from '~/store/api';
import { rtkService } from '~/types/rtkApi';
import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminDomainsForm from '../components/Admin/Domains/AdminDomainsForm';
import AdminDomainsList from '../components/Admin/Domains/AdminDomainsList';

const AdminDomains: React.FC = () => {
  const service = {
    getAll: useGetAllDomainsQuery,
    get: useGetDomainQuery,
    create: useCreateDomainMutation,
    update: useUpdateDomainMutation,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="Domains"
      listComponent={AdminDomainsList}
      formComponent={AdminDomainsForm}
      path={ADMIN_ROUTES.DOMAINS.path}
      service={service}
    />
  );
};

export default AdminDomains;
