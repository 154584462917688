import React from 'react';
import { getConfiguration } from '~/utils/configurations';

import { ButtonAnt as Button } from '~/UI';
import StateCard from '~/components/StateCard';

const StorageEngagedEmptyState = () => (
  <StateCard
    type="empty"
    title="Engaged customers have full access to our data."
    hideBorder
  >
    <a
      href={getConfiguration('REACT_APP_CALENDLY_SOLAR_STORAGE_URL')}
      target="_blank"
      rel="noreferrer"
    >
      <Button type="primary">Learn more</Button>
    </a>
  </StateCard>
);

export default StorageEngagedEmptyState;
