import React from 'react';
import { useHistory } from 'react-router';
import dayjs, { Dayjs } from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { CAPACITY_MAINTENANCE_STRATEGY_OPTIONS } from '~/utils/constants';
import {
  ButtonAnt as Button,
  Form,
  InputNumber,
  Option,
  Tooltip,
  Popconfirm,
  InputMultine,
  FormItemLabel,
  FormInstance,
} from '~/UI';
import { IconView } from '~/UI/Icons';
import { ROUTER_PATHS } from '~/router/Paths';
import { inRangeValidator } from '~/utils/numberValidators';
import {
  disabledDateFromUntil,
  meteringLocationOptions,
  projectTopologyOptions,
  requiredRuleNoMessage,
  theme,
  STORAGE_ROLLING_WINDOW_IN_MONTHS,
} from '~/utils';
import { DatePickerStyled } from '~/UI/DatePicker';
import { BoxTitle } from '~/UI/Typography';
import { SelectFormItem } from '~/UI/Select';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import STORAGE_INPUT_CONFIG from '~/constants/inputs/project/storageInputs';

const StyledDatePicker: React.FC<PickerProps<Dayjs>> = (props) => {
  return (
    <DatePickerStyled
      getPopupContainer={(trigger: HTMLElement) =>
        trigger.parentNode as HTMLElement
      }
      {...props}
    />
  );
};

interface ProjectDataFormGroupProps {
  form: FormInstance;
  requiredRule: {
    required: boolean;
    message: string;
  };
  projectUuid: string;
  isStorageApproved: boolean;
  onHandleStorageApproval: () => void;
}

const ProjectDataFormGroup: React.FC<ProjectDataFormGroupProps> = ({
  requiredRule,
  projectUuid,
  isStorageApproved,
  onHandleStorageApproval,
  form,
}) => {
  const history = useHistory();

  const goToStorageProjectPage = () => {
    history.push(ROUTER_PATHS.projects.storage.PROJECT_DETAILS(projectUuid));
  };

  const getTooltipTitle = () => {
    if (isStorageApproved) {
      return 'Unapproving this project will prevent the user to see DC Blocks in their ranking table.';
    }

    return 'Approving this project will allow the user to see DC Blocks in their ranking table.';
  };

  return (
    <div>
      <BoxTitle style={{ display: 'flex', alignItems: 'center' }}>
        Storage Details{' '}
        <Button
          style={{ marginLeft: 12 }}
          size="small"
          onClick={goToStorageProjectPage}
        >
          <IconView />
        </Button>
      </BoxTitle>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto',
          gap: '1rem',
        }}
      >
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Popconfirm
            title={`Are you sure you want to ${
              isStorageApproved ? 'unapprove' : 'approve'
            } this project?`}
            placement="bottom"
            onConfirm={onHandleStorageApproval}
          >
            <Tooltip title={getTooltipTitle}>
              <Button
                color={
                  isStorageApproved
                    ? theme.colors.secondary
                    : theme.colors.primary
                }
              >
                {isStorageApproved ? 'Unapprove' : 'Approve'}
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
        <Form.Item
          name="storage_arrival_date"
          label={<FormItemLabel>Delivery Date</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
        >
          <StyledDatePicker
            data-testid="arrival_date"
            disabledDate={disabledDateFromUntil}
            inputReadOnly
            defaultPickerValue={dayjs().add(
              STORAGE_ROLLING_WINDOW_IN_MONTHS,
              'months'
            )}
            {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
          />
        </Form.Item>
        <Form.Item
          name="anticipated_purchase_date"
          label={<FormItemLabel>Anticipated Purchase Date</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
            () =>
              form.getFieldsValue(['storage_arrival_date'])
                .storage_arrival_date || undefined
          )}
        >
          <StyledDatePicker
            data-testid="anticipated_purchase_date"
            inputReadOnly
            defaultPickerValue={dayjs().add(
              STORAGE_ROLLING_WINDOW_IN_MONTHS,
              'months'
            )}
            {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
              () =>
                form.getFieldsValue(['storage_arrival_date'])
                  .storage_arrival_date || undefined
            )}
          />
        </Form.Item>
        <SelectFormItem
          name="metering_location"
          label={<FormItemLabel>Metering Location</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
          selectProps={STORAGE_INPUT_CONFIG.meteringLocation.selectProps}
        >
          {meteringLocationOptions?.map((value) => (
            <Option value={value} key={value}>
              {value}
            </Option>
          ))}
        </SelectFormItem>
        <Form.Item
          name="target_ac_power_mw"
          label={<FormItemLabel>Target AC Power</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={STORAGE_INPUT_CONFIG.targetAcPowerMw.rules}
        >
          <InputNumber
            name="target_ac_power_mw"
            {...STORAGE_INPUT_CONFIG.targetAcPowerMw.inputNumberProps}
          />
        </Form.Item>
        <Form.Item
          name="duration_hours"
          label={<FormItemLabel>Duration</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={STORAGE_INPUT_CONFIG.durationHours.rules}
        >
          <InputNumber
            name="duration_hours"
            {...STORAGE_INPUT_CONFIG.durationHours.inputNumberProps}
            precision={1}
          />
        </Form.Item>
        <Form.Item
          name="power_factor"
          label={<FormItemLabel>Power Factor</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={STORAGE_INPUT_CONFIG.powerFactor.rules}
        >
          <InputNumber {...STORAGE_INPUT_CONFIG.powerFactor.inputNumberProps} />
        </Form.Item>
        <Form.Item
          name="annual_cycle_count"
          label={<FormItemLabel>Annual Cycle Count</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={STORAGE_INPUT_CONFIG.annualCycleCount.rules}
        >
          <InputNumber
            {...STORAGE_INPUT_CONFIG.annualCycleCount.inputNumberProps}
          />
        </Form.Item>
        <SelectFormItem
          name="project_topology"
          label={<FormItemLabel>Project Topology</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
          selectProps={STORAGE_INPUT_CONFIG.projectTopology.selectProps}
        >
          {projectTopologyOptions?.map((value) => (
            <Option
              key={value}
              value={value}
              disabled={value === 'DC-Coupled (coming soon)'}
              style={{
                color:
                  value === 'DC-Coupled (coming soon)' ? 'gray' : 'initial',
              }}
            >
              {value}
            </Option>
          ))}
        </SelectFormItem>
        <Form.Item
          name="capacity_maintenance_term"
          label={<FormItemLabel>Capacity Maintenance Term</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRuleNoMessage, inRangeValidator(0, 20, true)]}
        >
          <InputNumber
            {...STORAGE_INPUT_CONFIG.capacityMaintenanceTerm.inputNumberProps}
          />
        </Form.Item>
        <SelectFormItem
          name="capacity_maintenance_strategy"
          labelCol={{ span: 24 }}
          label={<FormItemLabel>Capacity Maintenance Strategy</FormItemLabel>}
          rules={[
            {
              required: true,
              message: 'Capacity Maintenance Strategy Type is required',
            },
          ]}
          selectProps={
            STORAGE_INPUT_CONFIG.capacityMaintenanceStrategy.selectProps
          }
        >
          {CAPACITY_MAINTENANCE_STRATEGY_OPTIONS.map((el) => (
            <Option key={el} value={el}>
              {el}
            </Option>
          ))}
        </SelectFormItem>
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Form.Item
            name="storage_notes"
            label={<FormItemLabel>Ranking Notes</FormItemLabel>}
            labelCol={{ span: 24 }}
          >
            <InputMultine
              name="storage_notes"
              placeholder="Add ranking notes"
              rows={4}
              style={{ fontSize: '14px' }}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default ProjectDataFormGroup;
