import React from 'react';
import styled from '@emotion/styled';
import { ButtonAnt as Button } from '~/UI';
import {
  ProjectStatus,
  SolarProjectTransformedResultType,
} from '~/store/project';
import {
  GTM_EVENTS,
  GTM_SOURCE_IDS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';
import { IconInfo, IconSettings } from '~/UI/Icons';
import useSideDrawer from '~/hooks/useSideDrawer';
import { useFlags } from '~/utils/launchdarkly';

import SolarInfo from './SolarInfo';
import AnalyticsInfo from './AnalyticsInfo';
import { getFilteredSchemas } from './utils';
import { ProjectStatuses } from '~/utils';

type Props = {
  projectStatus: ProjectStatus;
  disabled: boolean;
  handleOnOpenDrawerCb?: () => void;
  onEditProject: () => void;
  handleSaveFilterCb: () => void;
  isEditingProject?: boolean;
  onCancelClick?: () => void;
  isAnalysisViewOpen: boolean;
} & Pick<SolarProjectTransformedResultType, 'available_analytics_data'>;

const StyledButton = styled(Button)`
  padding: 0;
  color: #343f40;
`;

const StyledDrawersContainer = styled.div`
  display: none;
`;

const SolarActions = ({
  disabled,
  handleOnOpenDrawerCb,
  onEditProject,
  handleSaveFilterCb,
  isEditingProject,
  onCancelClick,
  isAnalysisViewOpen,
  available_analytics_data,
  projectStatus,
}: Props) => {
  const flags = useFlags();
  const { setIsDrawerOpen, SideDrawer } = useSideDrawer();
  const {
    setIsDrawerOpen: setIsAnalyticsDrawerOpen,
    SideDrawer: AnalyticsSideDrawer,
  } = useSideDrawer();

  const isLost = projectStatus === ProjectStatuses.LOST.toLocaleLowerCase();
  const isInactive =
    projectStatus === ProjectStatuses.INACTIVE.toLocaleLowerCase();
  const isAwarded =
    projectStatus === ProjectStatuses.AWARDED.toLocaleLowerCase();

  const disabledButton = isLost || isInactive || isAwarded;
  const filteredSchemas = getFilteredSchemas(available_analytics_data);
  const infoDrawerAnalytics = isAnalysisViewOpen
    ? {
        'data-gtm-type': GTM_SOURCE_TYPES.DRAWER,
        'data-gtm-id': GTM_SOURCE_IDS.SOLAR_PROJECT.ADVANCED_INFO_DRAWER,
      }
    : {};

  const onToggleAnalyticsDrawer = () => {
    maybeSendPlatformEventToGTM(
      GTM_EVENTS.CLICK,
      GTM_SOURCE_IDS.SOLAR_PROJECT.ADVANCED_INFO_DRAWER
    );
  };

  const openDrawer = () => {
    if (isAnalysisViewOpen) {
      setIsAnalyticsDrawerOpen(true);
      onToggleAnalyticsDrawer();
    }
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    if (isAnalysisViewOpen) {
      setIsAnalyticsDrawerOpen(false);
      onToggleAnalyticsDrawer();
    }
    setIsDrawerOpen(false);
  };

  const editProjectAction = () => {
    if (flags.taxCredit && onEditProject) return onEditProject();
    if (handleOnOpenDrawerCb) return handleOnOpenDrawerCb();
    return null;
  };

  return (
    <>
      {!isEditingProject && (
        <>
          <StyledButton
            type="link"
            onClick={editProjectAction}
            style={{ fontSize: '1.8em' }}
            disabled={disabled || disabledButton}
          >
            <IconSettings />
          </StyledButton>
          <StyledButton
            type="link"
            onClick={openDrawer}
            style={{ fontSize: '1.8em' }}
            disabled={disabled}
          >
            <IconInfo />
          </StyledButton>
        </>
      )}
      {isEditingProject && (
        <Button onClick={() => onCancelClick && onCancelClick()}>Cancel</Button>
      )}
      <Button
        type="primary"
        size="middle"
        onClick={handleSaveFilterCb}
        disabled={disabledButton}
      >
        Save Search
      </Button>
      <StyledDrawersContainer>
        <SideDrawer
          title="Ranking Table Terminology"
          onCloseDrawer={closeDrawer}
        >
          <SolarInfo />
        </SideDrawer>
        <AnalyticsSideDrawer
          title="Important Info"
          onCloseDrawer={closeDrawer}
          includeGtmProperties
          {...infoDrawerAnalytics}
          extra
        >
          <AnalyticsInfo schemas={filteredSchemas} />
        </AnalyticsSideDrawer>
      </StyledDrawersContainer>
    </>
  );
};

export default SolarActions;
