import React, { memo, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { useGetDashboardQuery } from '~/store/api';
import { getConfiguration } from '~/utils/configurations';
import { maybeGetAuth0RefreshToken } from '~/api/axios';
import { SpinnerMask } from '~/UI/Spinner';

const IFrameBox = styled.div`
  iframe {
    width: 100%;
  }
`;

type LookerEmbedProps = {
  id: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
};

const LookerEmbed: React.FC<LookerEmbedProps> = ({ id, style, onLoad }) => {
  const {
    data,
    refetch,
    isLoading: isDataLoading,
  } = useGetDashboardQuery({ id });
  const { url, sessionLengthInSeconds } = data ?? {};
  const lookerHost = getConfiguration('REACT_APP_LOOKER_HOST');
  const [userToken, setUserToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const iframeDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (async () => {
      const token = await maybeGetAuth0RefreshToken();
      setUserToken(token);
    })();
  }, []);

  useEffect(() => {
    if (lookerHost && userToken && !isDataLoading) {
      LookerEmbedSDK.init(lookerHost);
      LookerEmbedSDK.createDashboardWithUrl(url ?? '')
        .appendTo(`#${id}`)
        .withDynamicIFrameHeight()
        .on('dashboard:loaded', () => {
          setIsLoading(false);
          onLoad?.();
        })
        .build()
        .connect()
        .catch(console.error);
    }
  }, [userToken, lookerHost, isDataLoading]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isDataLoading) {
      const oneHourInMilliseconds = 3600000;
      const sessionLengthInMilliseconds = sessionLengthInSeconds
        ? sessionLengthInSeconds * 1000
        : oneHourInMilliseconds;
      const ninetyPercentOfInterval = sessionLengthInMilliseconds * 0.9;
      timer = setInterval(() => {
        setIsLoading(true);
        refetch();
      }, ninetyPercentOfInterval);
    }
    return () => clearInterval(timer);
  }, [isDataLoading]);

  useEffect(() => {
    const iframe = iframeDivRef.current?.firstElementChild;
    iframe?.setAttribute('src', url ?? '');
  }, [url]);

  return (
    <div style={style}>
      {isLoading && <SpinnerMask />}
      <IFrameBox ref={iframeDivRef} id={id} />
    </div>
  );
};

export default memo(LookerEmbed);
