export const TEST_DATA_CATEGORIES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  VENDOR: 'vendor',
};

export const TEST_DATA_PAGES = {
  ADMIN: {
    COMPANIES: {
      LIST: 'companies-list',
      EDIT: 'companies-edit',
      CREATE: 'companies-create',
    },
    DOMAINS: {
      LIST: 'domains-list',
      EDIT: 'domains-edit',
      CREATE: 'domains-create',
    },
    LOCATION_MODIFIERS: {
      LIST: 'location-modifiers-list',
      EDIT: 'location-modifiers-edit',
      CREATE: 'location-modifiers-create',
    },
    MODULES: {
      LIST: 'modules-list',
      EDIT: 'modules-edit',
      CREATE: 'modules-create',
    },
    PROJECTS: {
      LIST: 'projects-list',
      EDIT: 'projects-edit',
      CREATE: 'projects-create',
    },
    USERS: {
      LIST: 'users-list',
      EDIT: 'users-edit',
      CREATE: 'users-create',
    },
    DASHBOARD: 'dashboard',
    P360: 'p360',
  },
  CUSTOMER: {
    PROJECTS: 'projects',
    SOLAR_PROJECT_DETAILS: 'solar-project-details',
    SOLAR_PROJECTS_CARDS: 'solar-projects-cards',
    SOLAR_PROJECTS_CLASSIC: 'solar-projects-classic',
    STORAGE_PROJECT_DETAILS: 'storage-project-details',
    STORAGE_PROJECTS_CARDS: 'storage-projects-cards',
    STORAGE_PROJECTS_CLASSIC: 'storage-projects-classic',
  },
  SETTIGNS: {
    ACCOUNT: 'account',
  },
  VENDOR: {
    DASHBOARD: 'dashboard',
    PROJECT_PRICING_ANALYSIS: 'project-pricing-analysis',
    MODULE_PRICING_AVAILABILITY: 'module-pricing-availability',
  },
};

export const TEST_DATA_COMPONENTS = {
  ALERT: 'alert',
  APPLICATION_HEADER: 'application-header',
  AUTOCOMPLETE: 'autocomplete',
  AVATAR: 'avatar',
  BUTTON: 'button',
  CHECK_BOX: 'check-box',
  CHECK_BOX_GROUP: 'check-box-group',
  CONTAINER: 'container',
  DATE_PICKER: 'date-picker',
  EDIT_PROJECT_DRAWER: 'edit-project-drawer',
  FILTER_MENU: 'filter_menu',
  FORM: 'form',
  INPUT: 'input',
  LABEL: 'label',
  MODAL: 'modal',
  NEW_PROJECT_FORM: 'new-project-form',
  NOTIFICATION: 'notification',
  PAGINATION: 'pagination',
  RADIO_BUTTON: 'radio-button',
  RADIO_GROUP: 'radio-group',
  SECTION: 'section',
  SELECT: 'select',
  SELECT_OPTION: 'select-option',
  SWITCH: 'switch',
  TABLE: 'table',
  TABLE_HEADER: 'table-header',
  TABLE_ROW: 'table-row',
  TEXT: 'text',
};

export interface TestIdProps {
  category?: string;
  page?: string;
  component: string;
  identifier?: string;
  indices?: string;
}

export const generateTestId = ({
  category,
  page,
  component,
  identifier,
  indices,
}: TestIdProps) =>
  `${category ? `${category}-` : ''}${page ? `${page}-` : ''}${component}${
    identifier ? `-${identifier}` : ''
  }${indices ? `-${indices}` : ''}`;
