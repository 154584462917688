import * as R from 'ramda';
import { capitalCase } from 'change-case';
import React from 'react';

import { ButtonAnt as Button, Row, Tooltip } from '~/UI';
import { columnSorter, theme } from '~/utils';
import {
  IconAdd,
  IconCancel,
  IconCheckmark,
  IconWarningTriangle,
} from '~/UI/Icons';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';

import AdminTable, { Column } from '../AdminTable/AdminTable';

const renderStatusIcon = (value: string) => {
  const status = value.toUpperCase();
  if (status === 'APPROVED') {
    return <IconCheckmark size="24" color={theme.colors.primaryDark} />;
  }
  if (status === 'REJECTED') {
    return <IconCancel size="24" color={theme.colors.error} />;
  }
  return <IconWarningTriangle size="24" color={theme.colors.warning} />;
};

const renderStatus = (status: string) => (
  <Tooltip title={capitalCase(status || '')}>
    {renderStatusIcon(status)}
  </Tooltip>
);

const AdminDomainsList: React.FC<AdminListPageProps> = ({
  service,
  history,
  pageName,
}) => {
  const columns: Column[] = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      isSortable: true,
      isFilterable: true,
      sorter: columnSorter(['domain']),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      isSortable: true,
      isFilterable: true,
      render: (value: any) => R.propOr('', 'name', value),
      sorter: columnSorter(['company']),
    },
    {
      title: 'Status',
      dataIndex: 'domain_status',
      key: 'domain_status',
      width: '150px',
      isSortable: true,
      defaultSortOrder: 'ascend',
      render: renderStatus,
      sorter: columnSorter(['domain_status']),
    },
  ];

  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
        <Button onClick={() => history.push(ADMIN_ROUTES.DOMAINS.path.new)}>
          <IconAdd style={{ marginRight: '0.25rem' }} /> New Domain
        </Button>
      </Row>
      <AdminTable
        service={service}
        columns={columns}
        customActions={{
          edit: {
            onHref: (domain) =>
              ADMIN_ROUTES.DOMAINS.path.edit(domain.id?.toString() || ''),
          },
        }}
      />
    </div>
  );
};

export default AdminDomainsList;
