import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { ModuleConnectorTypesResponse } from '~/services/api/modules';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { ModuleType } from '~/types/modules';

export const adminModulesApi = createApi({
  reducerPath: 'adminModulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AllConnectorTypes', 'AdminGetModules', 'AdminGetModule'],
  endpoints: (builder) => ({
    getModules: builder.query<ModuleType[], void>({
      query: () => routes.admin.modules(),
      keepUnusedDataFor: 3,
      providesTags: () => [
        { type: 'AdminGetModules', id: 'ADMIN_GET_MODULES' },
      ],
    }),
    getModule: builder.query<ModuleType, { id: string }>({
      query: ({ id }) => routes.admin.modulesSettings(id),
      providesTags: () => [{ type: 'AdminGetModule', id: 'ADMIN_GET_MODULE' }],
    }),
    getAllConnectorTypes: builder.query<ModuleConnectorTypesResponse, void>({
      query: () => routes.admin.modulesConnectorTypes(),
      providesTags: () => [
        { type: 'AllConnectorTypes', id: 'ALL_CONNECTOR_TYPES' },
      ],
      transformResponse: (response: {
        connectorTypes: ModuleConnectorTypesResponse;
      }) => response.connectorTypes,
    }),
    deleteModule: builder.mutation<ModuleType, { moduleId: string }>({
      query: ({ moduleId }) => ({
        url: routes.admin.modulesSettings(moduleId),
        method: 'DELETE',
      }),
      transformResponse: (response: { data: ModuleType }) => response.data,
    }),
    updateModule: builder.mutation<
      ModuleType,
      { route: string; module?: ModuleType }
    >({
      query: ({ module = {}, route = '' }) => {
        return {
          url: `${routes.admin.modules()}/${route}`,
          method: 'PUT',
          body: module,
        };
      },
    }),
  }),
});

export const {
  useGetModuleQuery,
  useGetAllConnectorTypesQuery,
  useGetModulesQuery,
  useUpdateModuleMutation,
  useDeleteModuleMutation,
} = adminModulesApi;
