import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import {
  UpsertProjectFilterArgs,
  UpsertProjectFilterResponse,
} from '~/store/project';
import { BASE_URL_V3, prepareHeaders } from '../settings';

export const filtersApi = createApi({
  reducerPath: 'filtersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V3,
    prepareHeaders,
  }),
  tagTypes: ['Filter'],
  endpoints: (builder) => ({
    upsertFilter: builder.mutation<
      UpsertProjectFilterResponse,
      UpsertProjectFilterArgs
    >({
      query: (args) => ({
        url: routes.project.upsertFilter(args.projectId),
        method: 'POST',
        body: args,
      }),
    }),
  }),
});

export const { useUpsertFilterMutation } = filtersApi;
