import React from 'react';
import styled from '@emotion/styled';
import { Drawer as DrawerAnt, DrawerProps as DrawerAntProps } from 'antd';

import { PopupTitle } from './Typography';
import { colors } from '../utils/theme';
import { IconClose } from './Icons';

export type DrawerProps = DrawerAntProps;

const StyledDrawer = styled(DrawerAnt)`
  &.ant-drawer {
    
    &.no-padded-header {
      .ant-drawer-header {
        padding: 0 !important;
      }
    }

    display: flex;
    justify-content: center;
    
    .ant-drawer-mask {
      margin-top: 0;
      background: rgba(255, 255, 255, 0.7);
    }
    .ant-drawer-content-wrapper {
      border-radius: 0;
      height: 100vh;
      color: ${colors.charcoal};

      .ant-drawer-content {
        border-radius: 0;
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            padding-top: 20px;
            border-bottom: 0;

            .ant-drawer-header-title {
              width: 100%;

              .ant-drawer-close {
                position: absolute;
                right: 0;
                top: 0;
                margin: 0;

                padding: 20px;

                svg {
                  font-size: 26px;
                }
              }

              .ant-drawer-title {
                font-weight: 600;
                width: 100%;

                h2 {
                  width: 100%;
                  font-weight: 800;
                  font-size: 24px;
                  line-height: normal;
                }
              }
            }
          }

          .ant-drawer-body {
            padding-top: 10px;
            overflow: auto !important;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }
}
`;

const CloseIcon = styled(IconClose)`
  font-size: 24px;
  color: ${colors.charcoal};
`;

const Drawer: React.FC<DrawerProps> = ({ title, ...props }) => {
  return (
    <StyledDrawer
      {...props}
      title={<PopupTitle>{title}</PopupTitle>}
      closeIcon={<CloseIcon />}
      headerStyle={{
        borderBottom: 0,
        fontWeight: 'bold',
        fontSize: '100px',
      }}
      bodyStyle={{
        overflow: 'hidden',
        fontSize: '14px',
        lineHeight: '100%',
        letterSpacing: '-0.03em',
        color: colors.charcoal,
      }}
      drawerStyle={{
        boxShadow: '0px 20px 32px rgba(0, 0, 0, 0.24)',
      }}
    />
  );
};
export default Drawer;
