import React, { forwardRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import styled from '@emotion/styled';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';

import { theme } from '~/utils';

dayjs.extend(weekday);
dayjs.extend(localeData);

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  height: 46px;
  .ant-picker-input input {
    height: 48px;
    font-size: 14px;
    &::placeholder {
      color: ${theme.colors.stone};
    }
  }

  .ant-picker-suffix {
    color: ${theme.colors.graphite};
  }
`;

export const StyledDatePicker = forwardRef<HTMLDivElement, PickerProps<Dayjs>>(
  (props, ref) => (
    <div ref={ref}>
      <DatePickerStyled
        getPopupContainer={(trigger: HTMLElement) =>
          trigger.parentNode as HTMLElement
        }
        {...props}
      />
    </div>
  )
);

export default DatePicker;
