import React from 'react';
import styled from '@emotion/styled';
import { isNil } from 'ramda';
import { InputNumber as InputNumberAnt } from 'antd';
import { InputNumberProps as InputNumberAntProps } from 'antd/lib/input-number';
import { valueType } from 'antd/lib/statistic/utils';

import {
  applyPrecision,
  decimalFormatter,
  formatNumberStringWithoutCommas,
  isValueZeroOrNegativeZero,
  theme,
} from '~/utils';

export interface InputNumberProps extends InputNumberAntProps {
  defaultValue?: string | number;
  prefix?: string;
  suffix?: string;
  hint?: string;
  displayPrecision?: number;
  /**
   * floor (default): when precision is applied, rounds values to floor.
   *
   * nearest: when precision is applied, rounds values to Closest
   *  */
  precisionRound?: 'floor' | 'nearest';
}

const StyledInputGroup = styled.div`
  display: flex;

  span {
    display: flex;
    align-items: flex-end;
    align-self: center;
    flex-direction: column;
    padding: 10px 12px;
    height: 44px;
  }
`;
const StyledInputNumber = styled(InputNumberAnt)<InputNumberProps>`
  &.ant-input-number-group-wrapper {
    width: 100%;
  }
  .ant-input-number-input-wrap {
    display: flex;
    height: 100%;
  }
  &.ant-input-number {
    flex: 1 1 auto;
    border-radius: 6px;
    padding: 6px 0;
    height: 46px;
  }
  &.ant-input-number:focus,
  &.ant-input-number:hover,
  &.ant-input-number-focused {
    box-shadow: none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  ::after {
    content: '${(props) => props.suffix}';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${theme.colors.charcoal};
  }

  ::before {
    content: '${(props) => props.prefix}';
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: ${theme.colors.stone};
  }
`;

const HintElement = styled.div`
  position: absolute;
  right: 16px;
  bottom: 28px;
  font-size: 10px;
  color: ${theme.fonts.colors.graphite};
`;

const isNullEmptyUndefined = (value: string | number | undefined) =>
  value === null || value === undefined || value === '';

const MAX_INPUT_NUMBER_DIGITS = 15;

const InputNumber: React.FC<InputNumberProps> = React.forwardRef(
  (
    {
      defaultValue,
      hint,
      precision,
      precisionRound = 'floor',
      displayPrecision,
      parser,
      formatter = (
        value: valueType | undefined,
        info: { input: string; userTyping: boolean }
      ) => {
        if (info.userTyping && !isNullEmptyUndefined(value)) {
          if (
            !info?.input ||
            info.input === '.' ||
            info.input === '-.' ||
            info.input === '-'
          ) {
            return value;
          }
          return info.input;
        }

        if (
          typeof value === 'undefined' ||
          value === '' ||
          value === null ||
          value === '.' ||
          value === '-' ||
          value === '-0'
        ) {
          return value;
        }

        const precisionFixedValue = precision
          ? applyPrecision(value, precision)
          : value.toString();

        if (typeof displayPrecision !== 'undefined') {
          const stringValue = (+value).toString();
          if (
            stringValue.length !== (precisionFixedValue as string).length ||
            (stringValue.split('.').length === 2 &&
              stringValue.split('.')[1].length === precision)
          ) {
            return Number(value).toFixed(displayPrecision);
          }
        }
        return decimalFormatter(precisionFixedValue as number);
      },
      max = Number.MAX_SAFE_INTEGER,
      ...props
    },
    ref
  ) => {
    const precisionRoundNearest = precisionRound === 'nearest';
    const applyFormat = (
      value: valueType | undefined,
      info: {
        userTyping: boolean;
        input: string;
      }
    ) => {
      if (precisionRoundNearest) {
        return formatter(value, info);
      }
      const infoInput = info.input.replace(',', '');
      const isOnBlur = !info.userTyping && info.input;
      const isValidInfoInput = !Number.isNaN(Number(infoInput));
      if (isOnBlur && isValidInfoInput && precision) {
        const valueWithPrecision = applyPrecision(infoInput, precision);
        const parsedValue = parser
          ? parser(valueWithPrecision.toString())
          : valueWithPrecision;
        return formatter(parsedValue, info);
      }
      return formatter(value, info);
    };

    const applyParser = (value: string | undefined): valueType => {
      let appliedPrecisionValue: string = value?.toString() || '';
      if (
        !isNullEmptyUndefined(value) &&
        !isNullEmptyUndefined(precision) &&
        !precisionRoundNearest
      ) {
        appliedPrecisionValue = applyPrecision(
          value!,
          precision!,
          true
        ).toString();
      }

      if (parser) {
        return parser(appliedPrecisionValue);
      }

      if (isValueZeroOrNegativeZero(value!)) {
        return value!.toString();
      }

      return formatNumberStringWithoutCommas(appliedPrecisionValue);
    };

    return (
      <StyledInputGroup ref={ref as React.LegacyRef<HTMLDivElement>}>
        <StyledInputNumber
          formatter={applyFormat as () => string}
          max={max}
          maxLength={MAX_INPUT_NUMBER_DIGITS}
          precision={
            !isNil(precision) && precisionRoundNearest ? precision : undefined
          }
          parser={applyParser}
          {...props}
          value={props.value ?? defaultValue}
        />
        {hint && <HintElement>{hint || 'Read-only value'}</HintElement>}
      </StyledInputGroup>
    );
  }
);

export default InputNumber;
