import { UserType } from '~/types/users';

export interface AccountManagerInfo {
  name?: string;
  description?: string;
  email?: string | null;
  phoneNumber?: string | null;
}

export const getAccountManagerInfo = (
  user: UserType
): Partial<AccountManagerInfo> => {
  if (user.company?.account_manager_user_id) {
    const accountManager = user.company.accountManagerUser;

    return {
      name: `${accountManager?.first_name} ${accountManager?.last_name}`,
      description: 'Your account manager',
      email: accountManager?.email,
      phoneNumber: accountManager?.phone_number,
    };
  }

  return {
    name: 'Contact Anza',
    email: 'support-anza@anzarenewables.com',
  };
};
