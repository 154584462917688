import React, { useCallback, useEffect, useRef } from 'react';
import { max, min, omit, reduce, reverse } from 'ramda';
import { ResponsiveBar } from '@nivo/bar';

import { SolarRankingData } from '~/store/project';
import { Typography } from '~/UI';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';

import {
  ChartTitle,
  EffectivePriceChip,
  RankingChartContainer,
  StyledSpinner,
} from './styles';
import BarComponent from './BarComponent';
import BarTooltip from './BarTooltip';

const { H6Strong } = Typography;

export const CHIP_WIDTH = 51;

export const CHART_HORIZONTAL_MARGIN = 20;

export const CONTAINER_WIDTH = 550;

export const MIN_BAR_WIDTH = 6;

export const MIN_RANGE_WIDTH = CHIP_WIDTH + MIN_BAR_WIDTH;

const TOOLTIP_WIDTH = 150;

export const getCalculatedGraphWidth = (containerWidth: number) => {
  return (containerWidth || 600) - CHART_HORIZONTAL_MARGIN * 2;
};

export type RankingChartProps = {
  onMouseOverBar?: (moduleId: string) => void;
  onMouseLeaveBar?: () => void;
  onModuleClick?: (moduleId: string) => void;
  onClickOutsideModule?: (moduleId: string) => void;
  onClickOutside?: () => void;
  rankingData: SolarRankingData[];
  highlightedModule?: string;
  className?: string;
  loading?: boolean;
  width?: number;
  selectedModule?: string;
};

export type RankingDataWithDifference = SolarRankingData & {
  difference: number;
  initial: number;
  initialField: string;
  endField: string;
};

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.TEXT,
};

const RankingChart = (props: RankingChartProps) => {
  const {
    onMouseOverBar,
    onMouseLeaveBar,
    onModuleClick,
    onClickOutsideModule,
    rankingData,
    highlightedModule,
    className,
    loading,
    width = CONTAINER_WIDTH,
    selectedModule,
  } = props;
  const prevHighlightedModule = useRef<string>();

  useEffect(() => {
    prevHighlightedModule.current = highlightedModule;
  }, [highlightedModule]);

  const [xMinValue, setXMinValue] = React.useState(0);
  const [xMaxValue, setXMaxValue] = React.useState(1);
  const [containerWidth, setContainerWidth] = React.useState(0);
  useEffect(() => {
    const values = rankingData
      .map(
        ({
          module_customer_price_USD_per_W,
          module_effective_price_USD_per_W,
        }) => {
          return [
            module_customer_price_USD_per_W,
            module_effective_price_USD_per_W,
          ];
        }
      )
      .flat();

    const xMinValueAux = reduce(min, Infinity, values) as number;
    const xMaxValueAux = reduce(max, -Infinity, values) as number;

    const calculatedGraphWidth = getCalculatedGraphWidth(containerWidth);

    const chipWidthPercentage = (100 / calculatedGraphWidth) * CHIP_WIDTH;
    const chartValueRange = xMaxValueAux - xMinValueAux;
    const neededAditionalRange = (chartValueRange / 100) * chipWidthPercentage;
    setXMinValue(xMinValueAux - neededAditionalRange);
    setXMaxValue(xMaxValueAux + neededAditionalRange);
  }, [containerWidth, rankingData]);

  const containerRef = useCallback((containerNode) => {
    if (containerNode) {
      setContainerWidth(containerNode.offsetWidth);
    }
  }, []);

  const ranking: RankingDataWithDifference[] = reverse(
    rankingData.map((data) => ({
      ...data,
      difference:
        data.module_effective_price_USD_per_W >
        data.module_customer_price_USD_per_W
          ? data.module_effective_price_USD_per_W -
            data.module_customer_price_USD_per_W
          : data.module_customer_price_USD_per_W -
            data.module_effective_price_USD_per_W,
      initial:
        data.module_effective_price_USD_per_W >
        data.module_customer_price_USD_per_W
          ? data.module_customer_price_USD_per_W
          : data.module_effective_price_USD_per_W,
      initialField:
        data.module_effective_price_USD_per_W >
        data.module_customer_price_USD_per_W
          ? 'actual_price'
          : 'efective_price',
      endField:
        data.module_effective_price_USD_per_W >
        data.module_customer_price_USD_per_W
          ? 'actual_price'
          : 'efective_price',
    }))
  );

  return (
    <RankingChartContainer
      width={width}
      ref={containerRef}
      itemsAmount={ranking.length}
      className={className}
    >
      <ChartTitle
        data-testid={generateTestId({
          ...dataTestIdConfig,
          identifier: 'chart-title',
        })}
      >
        <H6Strong>Comparison of Actual Price vs.</H6Strong>
        <EffectivePriceChip>Effective Price</EffectivePriceChip>
      </ChartTitle>
      {loading ? (
        <StyledSpinner />
      ) : (
        <div
          style={{ width: '100%', height: '100%' }}
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.SECTION,
            identifier: 'chart',
          })}
        >
          <ResponsiveBar
            tooltip={(data) => {
              const module = data.data;
              const title = module.manufacturer
                ? `${module.manufacturer} ${module.wattClassKw} W`
                : '';
              return (
                <BarTooltip
                  title={title}
                  subtitle={`${module.moduleName}`}
                  width={TOOLTIP_WIDTH}
                />
              );
            }}
            margin={{
              top: 20,
              bottom: 25,
              left: CHART_HORIZONTAL_MARGIN,
              right: CHART_HORIZONTAL_MARGIN,
            }}
            labelSkipWidth={16}
            labelSkipHeight={16}
            colors={({ id }) => {
              return id === 'initial' ? 'transparent' : 'blue';
            }}
            // We omit those properties because BarDatum type needs an object without poperties of type "object"
            data={ranking.map(
              omit([
                'tags',
                'logoData',
                'quarterAvailability',
                'analytics_data',
                'isModuleHidden',
                'cell_technology',
              ])
            )}
            indexBy="moduleId"
            minValue={xMinValue}
            maxValue={xMaxValue}
            enableGridX
            enableGridY={false}
            valueFormat={(value: number) => `${Math.abs(value)}`}
            labelTextColor="inherit:darker(1.2)"
            axisBottom={{
              legendPosition: 'middle' as const,
              legendOffset: 50,
              tickSize: 0,
              tickPadding: 12,
            }}
            axisLeft={null}
            keys={['initial', 'difference']}
            groupMode="stacked"
            layout="horizontal"
            barComponent={(barData) => {
              const { bar } = barData;
              return (
                <BarComponent
                  hiddenTooltip={!highlightedModule}
                  selected={selectedModule === bar.data.indexValue}
                  barData={barData}
                  highlighted={highlightedModule === bar.data.indexValue}
                  onClick={onModuleClick}
                  module={
                    ranking.find((m) => m.moduleId === bar.data.indexValue)!
                  }
                  onMouseLeaveBar={onMouseLeaveBar}
                  onMouseOverBar={onMouseOverBar}
                  onClickOutside={(moduleId) => {
                    return (
                      onClickOutsideModule && onClickOutsideModule(moduleId)
                    );
                  }}
                />
              );
            }}
          />
        </div>
      )}
    </RankingChartContainer>
  );
};

export default RankingChart;
