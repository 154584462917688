import React from 'react';
import * as R from 'ramda';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveBar } from '@nivo/bar';
import { decimalFormatterTens, theme } from '~/utils';
import { Typography } from '~/UI';
import NoDataAvailable from '~/components/NoDataAvailable';
import GroupedXAxis from './GroupedXAxis';
import { BarItem } from './BarItem';
import { NoTooltip } from './Tooltips';
import { XAxisGrouping } from './ResponsiveBarChart';

const defaultBottomAxisTickFormatter = (value: string) => value;
const defaultValueFormatter = (value: number) =>
  R.isNil(value) || R.isEmpty(value) ? 0 : decimalFormatterTens(value);

const MOCK_DATA = [
  {
    id: 'Q3|22',
    label: 'Q3 2022',
    value: 125,
    count: 125,
  },
  {
    id: 'Q4|22',
    label: 'Q4 2022',
    value: 160,
    count: 160,
  },
  {
    id: 'Q1|23',
    label: 'Q1 2023',
    value: 30,
    count: 30,
  },
  {
    id: 'Q2|23',
    label: 'Q2 2023',
    value: 30,
    count: 30,
  },
  {
    id: 'Q3|23',
    label: 'Q3 2023',
    value: 30,
    count: 30,
  },
  {
    id: 'Q4|23',
    label: 'Q4 2023',
    value: 30,
    count: 30,
  },
  {
    id: 'Q1|24',
    label: 'Q1 2024',
    value: 160,
    count: 160,
  },
  {
    id: 'Q2|24',
    label: 'Q2 2024',
    value: 125,
    count: 125,
  },
  {
    id: 'Q3|24',
    label: 'Q3 2024',
    value: 160,
    count: 160,
  },
  {
    id: 'Q4|24',
    label: 'Q4 2024',
    value: 125,
    count: 125,
  },
];

interface NoDataPieChartProps {
  style: React.CSSProperties;
  title?: string;
  colors?: string[];
  valueFormat?: (value: number) => string;
  axisBottomTickFormat?: (value: string) => string;
  customXAxis: XAxisGrouping[];
  animate?: boolean;
  padding?: number;
  keys?: string[];
  barComponent?: typeof BarItem;
  data: {
    id: string;
    label: string;
  }[];
}

const NoDataBarChart: React.FC<NoDataPieChartProps> = (props) => {
  const defaultStyles: React.CSSProperties = {
    height: props.customXAxis ? '80%' : '100%',
    position: 'relative',
  };

  const stylesToUse = {
    ...defaultStyles,
    ...props.style,
  };

  const valueFormatterToUse = props.valueFormat || defaultValueFormatter;
  const bottomAxisTickFormatterToUse =
    props.axisBottomTickFormat || defaultBottomAxisTickFormatter;

  const NoDataTick = (tick: { x: number; y: number }) => {
    return (
      <g transform={`translate(${tick.x},${tick.y + 22})`}>
        <rect
          x={-16}
          y={-5}
          rx={3}
          ry={3}
          width={32}
          height={20}
          fill="url(#noDataGradient)"
        />
      </g>
    );
  };

  // If we provided a custom x axis, we need to tell nivo not to render the labels.
  // We do this instead of just passing null to the nivo axisBottom property so it still draws the x axis grid line.
  const bottomAxisToUse = props.data
    ? {
        tickRotation: 0,
        tickSize: 0,
        tickPadding: props.customXAxis ? 10 : 22,
        format: bottomAxisTickFormatterToUse,
      }
    : {
        tickRotation: 0,
        tickSize: 0,
        tickPadding: props.customXAxis ? 10 : 22,
        format: bottomAxisTickFormatterToUse,
        renderTick: NoDataTick,
      };

  let dataToUse = MOCK_DATA;
  // We need to supply an empty data set if we want to display the bottom axis labels instead of grey blobs.
  // We use the empty data set to get the correct axis labels but merge it with our mock data in order to ensure
  // that the no data chart displays nicely.
  if (props.data) {
    dataToUse = props.data.map((el, index) => {
      const mockDataTemplate =
        index >= MOCK_DATA.length
          ? MOCK_DATA[MOCK_DATA.length - 1]
          : MOCK_DATA[index];

      return {
        ...mockDataTemplate,
        id: el.id,
        label: el.label,
      };
    });
  }

  const numberOfColumns = dataToUse.length;

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
      <div
        style={{
          position: 'absolute',
          top: '10%',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <NoDataAvailable styles={{ maxWidth: '88px' }} />
      </div>
      <div style={stylesToUse}>
        {props.title && (
          <Typography.VendorLabel
            style={{
              marginBottom: '15px',
            }}
          >
            {props.title}
          </Typography.VendorLabel>
        )}
        <ResponsiveBar
          data={dataToUse}
          animate={props.animate || true}
          barComponent={props.barComponent || BarItem}
          valueFormat={valueFormatterToUse as (value: number) => string}
          label={() => ''}
          keys={props.keys}
          defs={[
            linearGradientDef('noDataGradient', [
              { offset: 0, color: '#F5F5F5' },
              { offset: 100, color: '#EEEEEE' },
            ]),
          ]}
          fill={[{ match: '*', id: 'noDataGradient' }]}
          colors={props.colors || { scheme: 'blue_green' }}
          borderColor={{
            from: 'color',
            modifiers: [
              ['darker', 0.6],
              ['opacity', 0.5],
            ],
          }}
          borderWidth={0}
          colorBy="indexValue"
          margin={{
            top: 0,
            right: 0,
            bottom: props.customXAxis ? 30 : 90,
            left: 0,
          }}
          padding={props.padding}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          axisBottom={bottomAxisToUse!}
          axisLeft={null}
          enableGridY={false}
          theme={{
            labels: {
              text: {
                fontWeight: 'bold',
                fontSize: '14px',
                color: theme.colors.charcoal,
              },
            },
            axis: {
              ticks: {
                text: {
                  fontSize: '13px',
                  fontWeight: 700,
                  wordSpacing: '-2px',
                  fill: props.customXAxis
                    ? theme.colors.stone
                    : theme.colors.charcoal,
                },
              },
              domain: {
                line: { stroke: 'rgb(51, 51, 51, 0.2)', strokeWidth: '1px' },
              },
            },
          }}
          tooltip={NoTooltip}
        />
        <GroupedXAxis
          axis={props.customXAxis}
          numberOfColumns={numberOfColumns}
        />
      </div>
    </div>
  );
};

export default NoDataBarChart;
