import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { UserType, UserResponseType } from '~/types/users';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { activeStatus } from '~/types/admin';

export const adminUsersApi = createApi({
  reducerPath: 'adminUsersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AdminAllUsers', 'AdminGetUser', 'AdminGetRoles'],
  endpoints: (builder) => ({
    getAllUsers: builder.query<UserType[], void>({
      query: () => routes.admin.users(),
      keepUnusedDataFor: 3,
      providesTags: () => [{ type: 'AdminAllUsers', id: 'ADMIN_ALL_USERS' }],
      transformResponse: (response: UserResponseType[]) =>
        response.map((user) => {
          const { domain, ...rest } = user;
          return {
            ...rest,
            domain,
            company: domain?.company,
          };
        }),
    }),
    getUser: builder.query<UserType, { id: number }>({
      query: ({ id }) => routes.admin.user(id),
      providesTags: () => [{ type: 'AdminGetRoles', id: 'ADMIN_GET_USER' }],
    }),
    updateUser: builder.mutation<
      UserType,
      {
        status?: activeStatus;
        user: UserType;
      }
    >({
      query: ({ user }) => {
        return {
          url: routes.admin.user(user.id!),
          method: 'PUT',
          body: user,
        };
      },
    }),
    deactivateUser: builder.mutation<UserType, { id: number }>({
      query: ({ id }) => ({
        url: routes.admin.userActivate(id.toString()),
        method: 'PUT',
      }),
    }),
    activateUser: builder.mutation<UserType, { id: number }>({
      query: ({ id }) => ({
        url: routes.admin.userDeactivate(id.toString()),
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeactivateUserMutation,
  useActivateUserMutation,
} = adminUsersApi;
