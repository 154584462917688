import { EngineOutputData } from '~/store/project';

const isMaxNvpDeltaModule = (
  module: EngineOutputData,
  maxNvpDeltaModule: EngineOutputData
) => module.moduleId === maxNvpDeltaModule.moduleId;

export type ModuleDataCalculus = (
  module: EngineOutputData,
  modulesList: EngineOutputData
) => any;

export const calculateEpcPrice: ModuleDataCalculus = (module) =>
  module.module_cost_USD_per_W / (1 - module.module_margin_percent);

export const calculateNvpDelta: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : module.net_present_value_USD - maxNvpDeltaModule.net_present_value_USD;

export const calculateRevenueDelta: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : module.project_value_USD - maxNvpDeltaModule.project_value_USD;

export const calculateCostSavings: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : maxNvpDeltaModule.epc_price_USD - module.epc_price_USD;

export const calculateYieldDelta: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : (module.post_unavailability_yield_kWh_per_kWp -
        maxNvpDeltaModule.post_unavailability_yield_kWh_per_kWp) /
      maxNvpDeltaModule.post_unavailability_yield_kWh_per_kWp;

export const calculateRevenueDeltaPerWatt: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : (module.project_value_USD / (module.dc_size_kW * 1000) -
        maxNvpDeltaModule.project_value_USD /
          (maxNvpDeltaModule.dc_size_kW * 1000)) /
      (maxNvpDeltaModule.project_value_USD /
        (maxNvpDeltaModule.dc_size_kW * 1000));

export const calculateRevenueDeltaUsd: ModuleDataCalculus = (
  module,
  maxNvpDeltaModule
) =>
  isMaxNvpDeltaModule(module, maxNvpDeltaModule)
    ? 0
    : (module.project_value_USD - maxNvpDeltaModule.project_value_USD) /
      maxNvpDeltaModule.project_value_USD;
