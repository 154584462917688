import React from 'react';
import { message } from 'antd';

import Tooltip from './Tooltip';
import { IconCopy } from './Icons';
import ButtonWithAnalytics from './ButtonWithAnalytics';

export type CopyToClipboardButtonProps = {
  copy?: string;
  tooltipTitle?: string;
  gtmId: string;
};

const CopyToClipboardButton = ({
  tooltipTitle,
  copy,
  gtmId,
}: CopyToClipboardButtonProps) => {
  return (
    <Tooltip title={tooltipTitle}>
      <ButtonWithAnalytics
        gtmId={gtmId}
        type="link"
        style={{ width: '32px', height: '32px', padding: 0 }}
        icon={<IconCopy />}
        onClick={() => {
          navigator.clipboard.writeText(copy || '').then(() => {
            message.success('Module UUID copied to clipboard');
          });
        }}
      />
    </Tooltip>
  );
};

export default CopyToClipboardButton;
