import React, { FunctionComponent } from 'react';
import * as R from 'ramda';
import { linearGradientDef } from '@nivo/core';
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { colors, decimalFormatterTens, theme } from '~/utils';
import { Typography } from '~/UI';
import { ChartDataStructureFromAPIModel } from '~/services/api/anza';
import { BarItem } from './BarItem';
import NoDataBarChart from './NoDataBarChart';
import GroupedXAxis from './GroupedXAxis';
import { TwoColumnTooltip } from './Tooltips';

const defaultValueFormatter = (value: number) =>
  R.isNil(value) || R.isEmpty(value) ? 0 : decimalFormatterTens(value);
const defaultBottomAxisTickFormatter = (value: number) => value;

export interface XAxisGrouping {
  group: string;
  children: string[];
}

export interface ResponsiveBarChartProps {
  style: React.CSSProperties;
  title?: string;
  colors?: string[];
  valueFormat?: (value: number | null) => string | number | bigint;
  axisBottomTickFormat?: (value: string) => string;
  customXAxis?: XAxisGrouping[];
  animate?: boolean;
  padding?: number;
  keys?: string[];
  label?: (columnData: {
    data: {
      value: number | null;
    };
  }) => string | number | bigint;
  emptyDataSet?: ChartDataStructureFromAPIModel[];
  barComponent?: typeof BarItem;
  tooltipConfig?: {
    leftValuePath: string[];
    rightValuePath: string[];
    leftLabel: string;
    rightLabel: string;
  };
  data: Partial<ChartDataStructureFromAPIModel>[];
}

export const quarterYearTickFormatter = (value: string) => {
  return R.isNil(value) || R.isEmpty(value)
    ? ''
    : R.split('|', value as string)[0];
};

const ResponsiveBarChart = (props: ResponsiveBarChartProps) => {
  const defaultStyles: React.CSSProperties = {
    height: props.customXAxis ? '80%' : '100%',
  };

  const stylesToUse = {
    ...defaultStyles,
    ...props.style,
  };

  const labelFormatterToUse = props.label || 'formattedValue';
  const valueFormatterToUse = props.valueFormat || defaultValueFormatter;
  const bottomAxisTickFormatterToUse =
    props.axisBottomTickFormat || defaultBottomAxisTickFormatter;

  const Tooltip = ({ data }: { data: string }) => {
    // We use a standardized data structure for our bar charts in anza and are currently only displaying the count on
    // the left and the value on the right but we have the option of overriding these if needed.
    const leftValuePath = props.tooltipConfig?.leftValuePath || ['count'];
    const rightValuePath = props.tooltipConfig?.rightValuePath || ['value'];

    return (
      <TwoColumnTooltip
        leftValue={R.pathOr('', leftValuePath, data)}
        leftLabel={props.tooltipConfig?.leftLabel}
        rightValue={R.pathOr('', rightValuePath, data)}
        rightLabel={props.tooltipConfig?.rightLabel}
        valueFormatterToUse={valueFormatterToUse as (value: number) => string}
      />
    );
  };

  // If we provided a custom x axis, we need to tell nivo not to render the labels.
  // We do this instead of just passing null to the nivo axisBottom property so it still draws the x axis grid line.
  const bottomAxisToUse = props.customXAxis
    ? {
        tickRotation: 0,
        tickSize: 0,
        tickPadding: 10,
        format: bottomAxisTickFormatterToUse,
      }
    : {
        tickRotation: 0,
        tickSize: 0,
        tickPadding: 22,
        format: bottomAxisTickFormatterToUse,
      };

  const numberOfColumns = props?.data?.length;

  return (
    <>
      {R.isNil(props.data) || R.isEmpty(props.data) ? (
        <NoDataBarChart
          style={props.style}
          title={props.title}
          axisBottomTickFormat={props.axisBottomTickFormat}
          data={props.emptyDataSet!}
          customXAxis={props.customXAxis!}
        />
      ) : (
        <div style={stylesToUse}>
          {props.title && (
            <Typography.VendorLabel
              style={{
                marginBottom: '15px',
              }}
            >
              {props.title}
            </Typography.VendorLabel>
          )}
          <ResponsiveBar
            data={props.data}
            animate={props.animate || true}
            barComponent={props.barComponent || BarItem}
            valueFormat={valueFormatterToUse as (value: number) => string}
            label={labelFormatterToUse as string}
            keys={props.keys}
            defs={[
              linearGradientDef('gradient', [
                { offset: 0, color: colors.primary300 },
                { offset: 100, color: colors.primary },
              ]),
            ]}
            fill={[{ match: '*', id: 'gradient' }]}
            colors={props.colors || { scheme: 'blue_green' }}
            borderColor={{
              from: 'color',
              modifiers: [
                ['darker', 0.6],
                ['opacity', 0.5],
              ],
            }}
            borderWidth={0}
            colorBy="indexValue"
            margin={{
              top: 0,
              right: 0,
              bottom: props.customXAxis ? 30 : 90,
              left: 0,
            }}
            padding={props.padding}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            axisBottom={bottomAxisToUse!}
            axisLeft={null}
            enableGridY={false}
            theme={{
              labels: {
                text: {
                  fontWeight: 'bold',
                  fontSize: '14px',
                  color: theme.colors.charcoal,
                },
              },
              axis: {
                ticks: {
                  text: {
                    fontSize: '13px',
                    fontWeight: 400,
                    wordSpacing: '-2px',
                    fill: props.customXAxis
                      ? theme.colors.stone
                      : theme.colors.charcoal,
                  },
                },
                domain: {
                  line: { stroke: 'rgb(51, 51, 51, 0.2)', strokeWidth: '1px' },
                },
              },
            }}
            tooltip={
              Tooltip as unknown as FunctionComponent<
                BarTooltipProps<Partial<ChartDataStructureFromAPIModel>>
              >
            }
            isInteractive={!!props.tooltipConfig}
          />
          <GroupedXAxis
            axis={props.customXAxis}
            numberOfColumns={numberOfColumns}
          />
        </div>
      )}
    </>
  );
};

export default ResponsiveBarChart;
