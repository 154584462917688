import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Drawer as UIDrawer } from '~/UI';
import { DrawerProps } from '~/UI/Drawer';

const Drawer = styled(UIDrawer)`
  .ant-drawer-body {
    padding-right: 20px;
    padding-left: 20px;
  }
  .ant-drawer-header {
    padding-left: 20px;
  }
`;

export type SideDrawerProps = {
  onCloseDrawer?: () => void;
  title?: string;
  description?: string;
  children: React.ReactNode;
  'data-gtm-event'?: string;
  'data-gtm-id'?: string;
  includeGtmProperties?: boolean;
} & DrawerProps;

const useSideDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const SideDrawer = useCallback(
    ({
      onCloseDrawer,
      title,
      description,
      children,
      includeGtmProperties = false,
      ...rest
    }: SideDrawerProps) => {
      const gtmEvent = rest['data-gtm-event'];
      const gtmId = rest['data-gtm-id'] as string | null;

      const infoDrawerAnalytics = includeGtmProperties
        ? {
            'data-gtm-type': gtmEvent,
            'data-gtm-id': gtmId,
          }
        : {};

      return (
        <div {...infoDrawerAnalytics}>
          <Drawer
            width={417}
            open={isDrawerOpen}
            onClose={() => onCloseDrawer && onCloseDrawer()}
            title={title}
            {...rest}
          >
            {description ? <p>{description}</p> : null}
            {children}
          </Drawer>
        </div>
      );
    },
    [isDrawerOpen]
  );

  return { SideDrawer, setIsDrawerOpen, isDrawerOpen };
};

export default useSideDrawer;
