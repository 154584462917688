import React from 'react';
import Skeleton from '~/UI/Skeleton';

import { Tooltip, TooltipArrow, TooltipSubtitle, TooltipTitle } from './styles';

export type BarTooltipProps = {
  width: number;
  title?: string;
  subtitle?: string;
};

const BarTooltip = ({ width, title, subtitle }: BarTooltipProps) => {
  return (
    <Tooltip onClick={(e) => e.preventDefault()} width={width}>
      {title ? (
        <TooltipTitle>{title}</TooltipTitle>
      ) : (
        <Skeleton title={{ width: 126 }} paragraph={false} />
      )}

      {subtitle ? (
        <TooltipSubtitle>{subtitle}</TooltipSubtitle>
      ) : (
        <Skeleton title={{ width: 134 }} paragraph={false} />
      )}
      <TooltipArrow />
    </Tooltip>
  );
};

export default BarTooltip;
