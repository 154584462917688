import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  padding: 0 0 1rem 0;
`;
