import { useHistory } from 'react-router';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

export const queryKeys = {
  filterByKey: 'filterByKey',
  filterByValue: 'filterByValue',
  sortByKey: 'sortByKey',
  sortByValue: 'sortByValue',
  limit: 'limit',
  skip: 'skip',
};

const getFilterMapFromQuery = (
  filterKeys: string[],
  filterValues: string[]
): { [key: string]: string[] } =>
  filterKeys.reduce((acc, key, idx) => {
    return {
      ...acc,
      [key]: filterValues[idx].split(','),
    };
  }, {});

const getInitialPaginationFromQuery = (
  skip: string | null,
  limit: string | null
) => {
  if (skip === null || limit === null) {
    return {
      defaultCurrent: 1,
      defaultPageSize: 20,
      showSizeChanger: false,
    };
  }
  const defaultCurrent = +skip / +limit + 1;
  const defaultPageSize = +limit;
  return {
    defaultCurrent,
    defaultPageSize,
    showSizeChanger: false,
  };
};

export const usePersistTableValues = () => {
  const routeHistory = useHistory();
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<any>
  ) => {
    const queryParams = new URLSearchParams(routeHistory.location.search);

    if (pagination && pagination.pageSize && pagination.current) {
      const skip = (pagination.current! - 1) * pagination.pageSize!;
      queryParams.set(queryKeys.limit, pagination.pageSize.toString());
      queryParams.set(queryKeys.skip, skip.toString());
    } else {
      queryParams.delete(queryKeys.limit);
      queryParams.delete(queryKeys.skip);
    }

    // @TODO - improve once table allows multi sort
    if (sorter) {
      queryParams.delete(queryKeys.sortByKey);
      queryParams.delete(queryKeys.sortByValue);
      if (sorter.order) {
        queryParams.set(queryKeys.sortByKey, sorter.field as string);
        queryParams.set(queryKeys.sortByValue, sorter.order as string);
      }
    }

    if (filters) {
      queryParams.delete(queryKeys.filterByKey);
      queryParams.delete(queryKeys.filterByValue);

      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          const filterValues = filters[key].join(',');
          queryParams.append(queryKeys.filterByKey, key);
          queryParams.append(queryKeys.filterByValue, filterValues);
        }
      });
    }

    routeHistory.replace({
      pathname: routeHistory.location.pathname,
      search: `?${queryParams.toString()}`,
    });
  };

  const queryParams = new URLSearchParams(routeHistory.location.search);
  const sortKey = queryParams.get(queryKeys.sortByKey);
  const sortValue = queryParams.get(queryKeys.sortByValue);
  const filterKeys = queryParams.getAll(queryKeys.filterByKey);
  const filterValues = queryParams.getAll(queryKeys.filterByValue);
  const filterMap = getFilterMapFromQuery(filterKeys, filterValues);
  const skip = queryParams.get('skip');
  const limit = queryParams.get('limit');
  const pagination = getInitialPaginationFromQuery(skip, limit);

  const resetPagination = () => {
    routeHistory.replace({
      pathname: routeHistory.location.pathname,
    });
  };

  return {
    handleTableChange,
    resetPagination,
    sortKey,
    sortValue,
    filterMap,
    pagination,
  };
};
