import React from 'react';
import styled from '@emotion/styled';
import { Modal as ModalAnt } from 'antd';
import { ModalProps as ModalAntProps } from 'antd/lib/modal';

export type ModalProps = ModalAntProps;

const StyledModal = styled(ModalAnt)`
  .ant-modal-content {
    box-shadow: none;
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }
  .ant-modal-close-x {
    width: 47px;
    height: 47px;
    line-height: 47px;
  }
`;

const Modal: React.FC<ModalProps> = (props) => <StyledModal {...props} />;

export default Modal;
