import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import FilterMenu from '~/UI/Filters_WIP/FilterMenu';
import { FilterConfig, FilterValues } from '~/UI/Filters_WIP/FilterForm';

import SelectFilterDropdown from './SelectFilterDropdown';

export type SelectFilterMenuProps = {
  filters: FilterConfig[];
  /** used for controling state outise this component */
  values?: FilterValues;
  onChange?: (filterValues: FilterValues) => void;
};

const SelectFilterMenu = ({
  filters,
  values,
  onChange,
}: SelectFilterMenuProps) => {
  // We use this because we need to controll the current filters state
  const [currentFilters, setCurrentFilters] = useState<FilterValues>(
    values || {}
  );

  /**
   * If the current values changes from outside this component (for example when
   * reseting filters) we need to update the current state
   */
  useEffect(() => {
    if (!R.isNil(values) && !R.equals(values, currentFilters)) {
      setCurrentFilters(values || {});
    }
  }, [values]);

  const onApplyClick = (values: FilterValues) => {
    setCurrentFilters((prev) => {
      const newValues = { ...prev, ...values };
      onChange?.(newValues);
      return newValues;
    });
  };

  const onClearFilter = (filterName: string) => {
    setCurrentFilters((prev) => {
      const newValues = {
        ...prev,
        [filterName]: [],
      };
      onChange?.(newValues);
      return newValues;
    });
  };

  return (
    <>
      {/*  Dropdown Menu in charge of showing current visible dropdown buttons for filters */}
      <SelectFilterDropdown
        filters={filters}
        values={currentFilters}
        onApplyClick={onApplyClick}
      />
      {/* Dropdown Menus in charge of handling filters */}
      <FilterMenu
        values={currentFilters}
        filters={filters}
        onApplyClick={onApplyClick}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export default SelectFilterMenu;
