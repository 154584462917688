import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import {
  GooglePlaceByLatLong,
  GooglePlaceByLatLongResponse,
} from '~/types/geoLocation';

export const googlePlacesApi = createApi({
  reducerPath: 'googlePlacesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['GooglePlaces', 'Place'],
  endpoints: (builder) => ({
    // 👇 Query: Get All Modules for vendor
    getGooglePlaceByLatLong: builder.query<
      GooglePlaceByLatLong,
      { lat: number; lng: number } | null
    >({
      query: (args) => ({
        url: routes.search.searchProjectLocationByLatLng(),
        method: 'POST',
        body: { coordinates: { ...args } },
      }),
      keepUnusedDataFor: 1,
      providesTags: (result) => {
        return result?.results?.length
          ? [
              ...result.results.map(({ place_id }: { place_id: string }) => ({
                type: 'Place' as const,
                id: place_id,
              })),
              { type: 'Place', id: 'PLACE' },
            ]
          : [{ type: 'Place', id: 'PLACE' }];
      },
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: GooglePlaceByLatLongResponse) =>
        response.data,
    }),
  }),
});

export const {
  useGetGooglePlaceByLatLongQuery,
  useLazyGetGooglePlaceByLatLongQuery,
} = googlePlacesApi;
