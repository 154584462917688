import React from 'react';

import styled from '@emotion/styled';
import { Form, InputNumber } from '~/UI';
import { FormRule } from '~/UI/Form';

interface EditableInputProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  precision?: number;
  displayPrecision?: number;
  step?: number;
  addonBefore?: string;
  addonAfter?: string;
  itemWidth?: string;
  children?: React.ReactNode;
  max?: number;
  min?: number;
  suffix?: string;
  rules?: FormRule[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    padding: 0px;
    height: 30px;
  }
`;

const EditableInput: React.FC<EditableInputProps> = ({
  editing,
  dataIndex,
  children,
  precision,
  step,
  addonBefore,
  addonAfter,
  itemWidth,
  max,
  min,
  displayPrecision,
  suffix,
  rules = [],
  onBlur,
}) => (
  <>
    {editing ? (
      <Form.Item
        rules={rules}
        name={dataIndex}
        style={{ margin: 0, padding: 0, minWidth: itemWidth }}
      >
        <StyledInputNumber
          onBlur={onBlur}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          step={step}
          precision={precision}
          min={min}
          max={max}
          displayPrecision={displayPrecision}
          suffix={suffix}
        />
      </Form.Item>
    ) : (
      <span>{`${addonBefore} ${children}`}</span>
    )}
  </>
);

export default EditableInput;
