import styled from '@emotion/styled';
import { theme } from '~/utils';

import { Table } from '~/UI';

const QuartersTable = styled(Table)`
  width: 99%;
  border-radius: 14px;
  overflow: auto hidden;

  tr:nth-child(odd) {
    .ant-table-cell-fix-left {
      background: ${theme.colors.lightBg};
    }
    .ant-table-cell-fix-right {
      background: ${theme.colors.lightBg};
    }
    background: ${theme.colors.lightBg};
  }

  tr:last-child > td {
    border-bottom: none;
  }

  tr:not(:first-child) th {
    border-top: 1px solid ${theme.colors.sterling};
  }

  .ant-table-cell {
    border-bottom: 1px solid ${theme.colors.sterling};
    border-left: 1px solid ${theme.colors.sterling};
    padding: 0px;
    margin: 0px;
  }

  .ant-table-tbody > tr:not(:first-child) > td {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
  }

  .ant-table-container {
    border-radius: 14px;
  }

  .ant-table-content {
    border: 1px solid ${theme.colors.sterling};
    border-radius: 14px;
    overflow: auto hidden;
    th {
      background: white !important;
      font-weight: 600;
      &::before {
        display: none;
      }
    }
  }

  .ant-table-row {
    cursor: pointer;

    .ant-input-number-input {
      text-align: right;
      padding-left: 2px;
      padding-right: 2px;
      border-bottom: none;
    }

    .ant-input-number-group-addon {
      width: auto;
      padding-right: 4px;
      padding-left: 4px;
      text-align: center;
    }

    .overflowText {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .action {
      display: flex;
      text-align: right;
      min-height: 66px !important;
      justify-content: center;
      align-items: center;

      .ant-btn-link[disabled] {
        color: ${theme.colors.stone};
      }
    }
  }
`;

export default QuartersTable;
