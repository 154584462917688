import { useHistory } from 'react-router';

export const Errors = {
  INVALID_SALESFORCE_PARAMETERS: 'invalid_salesforce_parameters',
  INVALID_PAGE: 'invalid_page',
  UNKNOWN: 'unknown',
  EMAIL_NOT_VERIFIED: 'email_not_verified',
  NOT_ALLOWED: 'not_allowed',
  NETWORK_ERROR: 'Network Error',
};

export type ErrorTypes = typeof Errors;

type ErrorValues = ErrorTypes[keyof ErrorTypes];

export const errorHandler = (type: ErrorValues): Error => {
  throw new Error(type);
};

export type ErrorLog = {
  error: Error | unknown;
  method?: string;
  location?: string;
};

export const catchError = (args: ErrorLog) => {
  console.error(
    `location: ${args.location}; method: ${args.method}; error: ${
      args.error as Error
    }`
  );
};

export const handle404Error = (error: Error) => {
  if (error && JSON.stringify(error).includes('404')) {
    useHistory().push('/404');
  }
};
