import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { ModuleFile } from '~/types/file';

export const createAdminSearchFilesApi = (
  customPrepareHeaders?: FetchBaseQueryArgs['prepareHeaders']
) =>
  createApi({
    reducerPath: 'adminSearchFilesApi',
    baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL_V2,
      prepareHeaders: customPrepareHeaders,
    }),
    keepUnusedDataFor: 0,
    tagTypes: ['SearchFiles'],
    endpoints: (builder) => ({
      getSearchFiles: builder.query<ModuleFile[], string>({
        query: (params?: string) => routes.admin.searchFiles(params),
        keepUnusedDataFor: 0,
        providesTags: () => [{ type: 'SearchFiles', id: 'SEARCH_FILES' }],
      }),
    }),
  });

export const adminSearchFilesApi = createAdminSearchFilesApi(prepareHeaders);

export const { useGetSearchFilesQuery } = adminSearchFilesApi;

export type UseGetSearchFilesQuery = typeof useGetSearchFilesQuery;
