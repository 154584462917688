import React from 'react';
import styled from '@emotion/styled';
import { InlineWidget } from 'react-calendly';
import { getConfiguration } from '~/utils/configurations';

import { Typography } from '~/UI';
import { theme } from '~/utils';
import {
  Box,
  BoxHeader,
  BoxHeaderTitle,
  BoxContent,
  Container,
  Content,
} from '~/components/Modules';
import useAuth from '~/hooks/useAuth';
import AnzaContainer from '~/components/Anza/AnzaLayout/AnzaContainer';

const ScheduleContent = styled(Content)`
  flex-direction: column;
  align-items: center;
`;

const ScheduleSubtext = styled(Typography.Subtext)`
  width: 620px;
  margin-bottom: 1.5rem;
`;

const ScheduleBox = styled(Box)`
  max-width: 1000px;
  margin-bottom: 2rem;
`;

const ScheduleBoxContent = styled(BoxContent)``;

const Schedule: React.FC = () => {
  const { user } = useAuth();

  return (
    <AnzaContainer>
      <Container>
        <ScheduleContent>
          <ScheduleSubtext>
            Speak with a Borrego representative about your project.
          </ScheduleSubtext>
          <ScheduleBox>
            <BoxHeader>
              <BoxHeaderTitle>
                <Typography.BoxTitle>Schedule A Call</Typography.BoxTitle>
              </BoxHeaderTitle>
            </BoxHeader>
            <ScheduleBoxContent>
              <InlineWidget
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideGdprBanner: true,
                  hideLandingPageDetails: false,
                  primaryColor: theme.colors.primary,
                  textColor: '343A40',
                }}
                prefill={{
                  email: user?.email as string,
                  firstName: user?.first_name as string,
                  guests: [],
                  lastName: user?.last_name as string,
                  name: `${user?.first_name} ${user?.last_name}`,
                }}
                styles={{
                  height: '700px',
                  width: '100%',
                  minWidth: '1000px',
                  maxWidth: '1000px',
                  margin: '-60px 0 0 -24px',
                  boxShadow: 'none',
                }}
                url={getConfiguration('REACT_APP_CALENDLY_SOLAR_URL')}
                utm={{
                  utmCampaign: '',
                  utmContent: '',
                  utmMedium: '',
                  utmSource: '',
                  utmTerm: '',
                }}
              />
            </ScheduleBoxContent>
          </ScheduleBox>
        </ScheduleContent>
      </Container>
    </AnzaContainer>
  );
};

export default Schedule;
