import React, { useEffect } from 'react';
import * as R from 'ramda';
import { RouteComponentProps, useHistory, useParams } from 'react-router';
import styled from '@emotion/styled';

import { CopyToClipboardButton, Form, Space, Spinner } from '~/UI';
import { blockEnterKeyEvents } from '~/utils/events';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { rtkService } from '~/types/rtkApi';
import FormFooter from '~/components/FormFooter';
import { formErrorScroll } from '~/utils';
import { ROUTER_PATHS } from '~/router/Paths';
import useFormDirty from '~/hooks/useFormDirty';
import StorageModulesAddersFormGroup from '~/components/ModulesAddersFormGroup/StorageModulesAddersFormGroup';
import {
  useGetDcBlockByUuidQuery,
  useUpdateDcBlockByUuidMutation,
} from '~/store/api/admin/dcBlocksApi';
import { H2 } from '~/UI/Typography';
import { GTM_SOURCE_IDS } from '~/utils/gtm';
import { DCBlock, DCBlockQuoteConstraint } from '~/store/project';

import DcBlockFormItems from './DcBlockFormItems';
import TierTable from './TierTable';

export const DC_BLOCK_QUOTE_DATA_KEY = 'dc_block_quote_data';

const SpinnerContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
`;

const sortConstraintsByTier = (coonstraints: DCBlockQuoteConstraint[]) =>
  R.sortWith([R.ascend(R.prop('numeric_value'))], coonstraints);

const AdminDcBlocksForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
    onFinishCallback: (value?: string) => void;
    createMode?: boolean;
  }
> = ({ createMode }) => {
  const [form] = Form.useForm<DCBlock>();
  const history = useHistory();
  const { id: uuid }: { id?: string | null } = createMode
    ? { id: null }
    : useParams() || {};
  const isNew = !uuid;

  const [updateDcBlockByUuid, { isLoading: isUpdating }] =
    useUpdateDcBlockByUuidMutation();

  if (isNew) {
    history.replace(ROUTER_PATHS.PAGE_NOT_FOUND());
    return <></>;
  }

  const {
    data: dcblock,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useGetDcBlockByUuidQuery({ uuid });

  useEffect(() => {
    if (dcblock) {
      const sortedConstraints = sortConstraintsByTier(
        dcblock.dc_block_quote_constraints
      );
      form.setFieldsValue({
        ...dcblock,
        dc_block_quote_constraints: sortedConstraints,
      });
    }
  }, [dcblock]);

  const {
    isDirty,
    onValuesChange: onValuesChangeIsDirty,
    setIsDirty,
  } = useFormDirty();

  const isDcBlockActive = Form.useWatch('active', form) || false;

  const onSave = async () => {
    try {
      await form.validateFields();
      setIsDirty(false);
    } catch (error) {
      console.log('error', error);
      formErrorScroll();
    }
  };

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (isError || !dcblock) {
    history.replace(ROUTER_PATHS.PAGE_NOT_FOUND());
    return <></>;
  }

  // isSuccess

  const onSubmit = async (newDcBlockData: DCBlock) => {
    try {
      await updateDcBlockByUuid(newDcBlockData);
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const isUpdatingDcBlock = isUpdating || isFetching;

  return (
    <AdminFormLayout>
      <H2>{dcblock.name}</H2>
      <Space size="xs" direction="horizontal">
        <span>{dcblock.uuid}</span>
        <CopyToClipboardButton
          gtmId={GTM_SOURCE_IDS.ADMIN.DC_BLOCKS.COPY_TO_CLIPBOARD_DC_BLOCK_UUID}
          copy={dcblock.uuid}
          tooltipTitle="Copy UUID"
        />
      </Space>
      <Form
        form={form}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        onValuesChange={onValuesChangeIsDirty}
        onFinish={onSubmit}
        disabled={isUpdatingDcBlock}
      >
        <Space size="large" direction="vertical">
          <DcBlockFormItems form={form} dcblock={dcblock} />
          <TierTable
            form={form}
            constraints={sortConstraintsByTier(
              dcblock.dc_block_quote_constraints
            )}
            formItemsPrefixName="dc_block_quote_constraints"
          />
          <StorageModulesAddersFormGroup
            formItemsPrefixName={DC_BLOCK_QUOTE_DATA_KEY}
            isModuleActive={isDcBlockActive}
          />

          {/* Adding hidden form items to send data on sumbit */}
          <Form.Item name="uuid" hidden />
        </Space>
        <FormFooter
          isDirty={isDirty}
          saveButtonProps={{
            onClick: onSave,
            loading: isUpdatingDcBlock,
          }}
        />
      </Form>
    </AdminFormLayout>
  );
};

AdminDcBlocksForm.defaultProps = {
  createMode: false,
};

export default AdminDcBlocksForm;
