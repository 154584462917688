import { Skeleton as SkeletonAnt } from 'antd';
import styled from '@emotion/styled';
import { theme } from '~/utils';

const color = `background-color: ${theme.colors.gray300} !important;`;

const Skeleton = styled(SkeletonAnt)`
  display: flex;
  align-items: center;
  h3 {
    ${color}
    height: 13px !important;
  }
  span {
    ${color}
  }
  p {
    ${color}
    height: 10px !important;
  }
`;

export const SkeletonAvatar = styled(SkeletonAnt.Avatar)`
  span {
    ${color}
  }
`;

export const SkeletonAntd = SkeletonAnt;

export default Skeleton;
