import React from 'react';

import { ButtonAnt as Button, Row } from '~/UI';
import { columnSorter } from '~/utils';
import { IconAdd } from '~/UI/Icons';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';

import AdminTable, { Column } from '../AdminTable/AdminTable';

const AdminLocationModifiersList: React.FC<AdminListPageProps> = ({
  service,
  history,
  pageName,
}) => {
  const columns: Column[] = [
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      isSortable: true,
      isFilterable: true,
      fixed: 'left',
      width: '150px',
      sorter: columnSorter(['city']),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      isSortable: true,
      isFilterable: true,
      fixed: 'left',
      width: '100px',
      defaultSortOrder: 'ascend',
      sorter: columnSorter(['state']),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      isSortable: true,
      isFilterable: true,
      width: '100px',
      sorter: columnSorter(['tax']),
    },
    {
      title: 'Modifier',
      dataIndex: 'modifier',
      key: 'modifier',
      isSortable: true,
      isFilterable: true,
      width: '130px',
      sorter: columnSorter(['modifier']),
    },
    {
      title: 'Energy Price',
      dataIndex: 'energy_price',
      key: 'energy_price',
      isSortable: true,
      isFilterable: true,
      width: '150px',
      sorter: columnSorter(['energy_price']),
    },
    {
      title: 'Retail Price',
      dataIndex: 'retail_price',
      key: 'retail_price',
      isSortable: true,
      isFilterable: true,
      width: '150px',
      sorter: columnSorter(['retail_price']),
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
      isSortable: true,
      isFilterable: true,
      width: '100px',
      sorter: columnSorter(['port']),
    },
    {
      title: 'Shipping',
      dataIndex: 'shipping',
      key: 'shipping',
      isSortable: true,
      isFilterable: true,
      width: '130px',
      sorter: columnSorter(['shipping']),
    },
    {
      title: 'Fixed Tilt A',
      dataIndex: 'fixed_tilt_a',
      key: 'fixed_tilt_a',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['fixed_tilt_a']),
    },
    {
      title: 'Fixed Tilt B',
      dataIndex: 'fixed_tilt_b',
      key: 'fixed_tilt_b',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['fixed_tilt_b']),
    },
    {
      title: 'Fixed Tilt C',
      dataIndex: 'fixed_tilt_c',
      key: 'fixed_tilt_c',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['fixed_tilt_c']),
    },
    {
      title: 'Fixed Tilt D',
      dataIndex: 'fixed_tilt_d',
      key: 'fixed_tilt_d',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['fixed_tilt_d']),
    },
    {
      title: 'Fixed Tilt E',
      dataIndex: 'fixed_tilt_e',
      key: 'fixed_tilt_e',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['fixed_tilt_e']),
    },
    {
      title: 'Single Axis Tracker A',
      dataIndex: 'single_axis_tracker_a',
      key: 'single_axis_tracker_a',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['single_axis_tracker_a']),
    },
    {
      title: 'Single Axis Tracker B',
      dataIndex: 'single_axis_tracker_b',
      key: 'single_axis_tracker_b',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['single_axis_tracker_b']),
    },
    {
      title: 'Single Axis Tracker C',
      dataIndex: 'single_axis_tracker_c',
      key: 'single_axis_tracker_c',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['single_axis_tracker_c']),
    },
    {
      title: 'Single Axis Tracker D',
      dataIndex: 'single_axis_tracker_d',
      key: 'single_axis_tracker_d',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['single_axis_tracker_d']),
    },
    {
      title: 'Single Axis Tracker E',
      dataIndex: 'single_axis_tracker_e',
      key: 'single_axis_tracker_e',
      isSortable: true,
      width: '150px',
      sorter: columnSorter(['single_axis_tracker_e']),
    },
  ];

  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
        <Button
          onClick={() => history.push(ADMIN_ROUTES.LOCATION_MODIFIERS.path.new)}
        >
          <IconAdd style={{ marginRight: '0.25rem' }} /> New Location Modifier
        </Button>
      </Row>
      <AdminTable
        service={service}
        columns={columns}
        customActions={{
          edit: {
            onHref: (locationModifier) =>
              ADMIN_ROUTES.LOCATION_MODIFIERS.path.edit(
                locationModifier.id?.toString() || ''
              ),
          },
          delete: {
            visible: true,
          },
        }}
      />
    </div>
  );
};

export default AdminLocationModifiersList;
