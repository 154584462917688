import React from 'react';
import confirm from 'antd/lib/modal/confirm';
import { useParams } from 'react-router';

import {
  SolarAdvancedInputs,
  SolarProject,
  UpdateProjectArgs,
} from '~/store/project';
import {
  isUserAdmin,
  omitProjectDetailsValues,
  pickProjectDetailsValues,
} from '~/utils';
import { useUpdateSolarProjectMutation } from '~/store/api';
import useAuth from '~/hooks/useAuth';
import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import { Drawer, Form } from '~/UI';
import { UnsavedChangesText } from '~/UI/Typography';
import ProjectSolarDrawerForm from './SolarProjectDrawerForm';
import ProjectDrawerFooter from '../shared/ProjectDrawerFooter';
import { transformAdvancedInputs } from '../shared/helpers';

interface Props {
  projectData: SolarProject;
  advancedInputs: SolarAdvancedInputs;
  onCloseDrawer: (isUpdate?: boolean) => void;
  isOpen: boolean;
  enableAdvanceInputs: boolean;
}

const SolarProjectDrawer = ({
  onCloseDrawer,
  isOpen,
  projectData,
  advancedInputs,
  enableAdvanceInputs,
}: Props) => {
  const params = useParams<{ uuid: string }>();
  const [projectDetailsForm] = Form.useForm();
  const [updateSolarProject, { isLoading }] = useUpdateSolarProjectMutation();
  const { user } = useAuth();

  const handleOnSaveDrawer = () => {
    projectDetailsForm.submit();
    projectDetailsForm.validateFields().then((values) => {
      const { is_larger_purchase, ...payload } = transformAdvancedInputs(
        values,
        advancedInputs
      );
      updateSolarProject({
        uuid: params.uuid,
        project_details: {
          ...pickProjectDetailsValues(payload),
          project_type: projectData.project_details.project_type,
          project_address: projectData.project_details.project_address,
          status: projectData.project_details.status,
        },
        ...omitProjectDetailsValues(payload),
      } as UpdateProjectArgs)
        .unwrap()
        .then(() => {
          onCloseDrawer(true);
        });
    });
  };

  const handleOnCloseDrawer = () => {
    const fieldsToCheck = Object.keys(
      projectDetailsForm.getFieldsValue() || {}
    );
    const formIsDirty = projectDetailsForm.isFieldsTouched(fieldsToCheck);
    if (formIsDirty) {
      confirm({
        width: '650px',
        centered: true,
        type: 'warning',
        okText: 'Discard',
        cancelButtonProps: {
          size: 'large',
          style: {
            width: '190px',
          },
        },
        okButtonProps: {
          size: 'large',
          style: {
            width: '190px',
          },
        },
        title: (
          <UnsavedChangesText>
            You have unsaved changes in your project details.
          </UnsavedChangesText>
        ),
        onOk: () => {
          projectDetailsForm.resetFields();
          onCloseDrawer();
        },
      });
    } else {
      projectDetailsForm.resetFields();
      onCloseDrawer();
    }
  };

  const disabledInputs =
    !isUserAdmin(user) &&
    user.company?.id !== projectData.owner.domain.company.id;

  return (
    <Drawer
      destroyOnClose
      width={540}
      open={isOpen}
      onClose={handleOnCloseDrawer}
      title={
        <h2
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.TEXT}`,
            identifier: 'title',
          })}
        >
          Project Details
        </h2>
      }
      footer={
        <ProjectDrawerFooter
          isLoading={isLoading}
          onCloseDrawer={handleOnCloseDrawer}
          onSaveDrawer={handleOnSaveDrawer}
        />
      }
    >
      <p
        data-testid={generateTestId({
          component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.TEXT}`,
          identifier: 'info',
        })}
      >
        Modify the assumptions about your project to improve your ranking
        results.
      </p>
      <ProjectSolarDrawerForm
        disabledInputs={disabledInputs}
        form={projectDetailsForm}
        data={projectData}
        defaultAdvancedInputs={advancedInputs}
        disabledAdvancedInputs={!enableAdvanceInputs}
      />
    </Drawer>
  );
};

export default SolarProjectDrawer;
