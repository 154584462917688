import { PORT, SHIPPING_COST } from '~/store/project';
import { ModuleType } from '~/types/modules';

export const getModulePortAdderByPort = (port: PORT, module: ModuleType) => {
  if (port === PORT.MIDWEST) {
    return module.midwest_coast_port_adder;
  }
  if (port === PORT.WEST) {
    return module.west_coast_port_adder;
  }
  // EAST
  return 0;
};

export const getModuleShippingAdderByShippingCost = (
  shippingCost: SHIPPING_COST,
  module: ModuleType
) => {
  if (shippingCost === SHIPPING_COST.STANDARD) {
    return module.site_shipping_standard_adder;
  }
  if (shippingCost === SHIPPING_COST.HIGH) {
    return module.site_shipping_high_adder;
  }
  return 0;
};

export const getModuleById = (moduleUuid: string, modules: ModuleType[]) => {
  return modules.find((module) => module.uuid === moduleUuid);
};

export const getModuleQuarter = (
  moduleUuid: string,
  quarter: string,
  modules: ModuleType[]
) => {
  const selectedModule = getModuleById(moduleUuid, modules);
  return selectedModule?.quarters.find(
    (q) => quarter === `Q${q.quarter} ${q.year}`
  );
};
