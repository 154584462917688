import ReactGA from 'react-ga4';

export const GA = {
  actions: {
    INITIALIZE_PROJECT: 'INITIALIZE PROJECT',
    CREATE_PROJECT: 'CREATE PROJECT',
    SCHEDULE_CALL: 'SCHEDULE CALL',
    DOWNLOAD_EXCEL: 'DOWNLOAD RANKING TABLE EXCEL',
    CHANGE_MODULE_PRICES: 'CHANGE MODULE PRICES',
  },
};

export interface gaInitializeParams {
  trackingId: string;
  userId?: string | undefined | null;
  user_type?: string | undefined | null;
}

export const gaInitialize = (params: gaInitializeParams) => {
  const { userId, user_type, trackingId } = params;
  ReactGA.initialize([
    {
      trackingId,
      gaOptions: { userId },
    },
  ]);
  ReactGA.set({ user_properties: { user_type } });
};

export const gaPageView = ({ page }: { page: string }) => {
  ReactGA.send({
    hitType: 'pageview',
    page,
  });
};

export interface gaEventParams {
  category: string;
  action: string;
  label?: string | undefined;
  value?: number | undefined;
}

export const gaEvent = ({ category, action, label, value }: gaEventParams) => {
  ReactGA.event({
    category,
    action,
    label, // optional
    value, // optional, must be a number
  });
};

export interface gaCompanyTypeEventParams {
  action: string;
  label?: string | null | undefined;
  value?: number | null | undefined;
}

export const gaVendorEvent = ({
  action,
  label,
  value,
}: gaCompanyTypeEventParams) =>
  gaEvent({
    category: 'vendor',
    action,
    label: label || undefined,
    value: value || undefined,
  });

export const gaCustomerEvent = ({
  action,
  label,
  value,
}: gaCompanyTypeEventParams) =>
  gaEvent({
    category: 'customer',
    action,
    label: label || undefined,
    value: value || undefined,
  });
