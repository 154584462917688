import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Dropdown, { DropdownProps } from '~/UI/DropdownAnt';

const FilterAnchorWrapper = styled.div<{ maxHeight?: number }>`
  position: relative;
  ${({ maxHeight }) =>
    maxHeight
      ? `
    max-height: ${maxHeight - 50}px;
    overflow-y: auto;
  `
      : ''}
`;

export const DropdownContainer = styled.div<{
  topPosition: number;
}>`
  ${({ topPosition }) => `max-height: calc(100vh - ${topPosition}px - 160px); `}
`;

/** This Dropdown is basically a workarround to be able to use the Dropdown inside the Affix component of ant design */
const DropdownAffix = (props: DropdownProps & { gtmId?: string }) => {
  const [dropdownTopPosition, setDropdownTopPosition] = useState(0);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const updateDropdownBottomPositionAndMaxHeight = () => {
    const top = anchorRef?.current?.getBoundingClientRect().top || 0;
    setDropdownTopPosition(top);
    setDropdownMaxHeight(window.innerHeight - top);
  };

  useEffect(() => {
    if (open) {
      updateDropdownBottomPositionAndMaxHeight();
    }
  }, [open, anchorRef]);

  return (
    <Dropdown
      {...props}
      onOpenChange={() => {
        setOpen(open);
        props.onOpenChange?.(open);
      }}
      getPopupContainer={() =>
        anchorRef?.current || document.getElementById(props.gtmId || 'root')!
      }
      dropdownRender={(originNode) => {
        return (
          <DropdownContainer topPosition={dropdownTopPosition}>
            {props.dropdownRender?.(originNode)}
          </DropdownContainer>
        );
      }}
    >
      <FilterAnchorWrapper ref={anchorRef} maxHeight={dropdownMaxHeight}>
        {props.children}
      </FilterAnchorWrapper>
    </Dropdown>
  );
};

export default DropdownAffix;
