import styled from '@emotion/styled';
import { Form } from '~/UI';

const FormItemLabel = styled(Form.Item)`
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  padding: 0.6rem 0;
`;

export default FormItemLabel;
