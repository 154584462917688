import React, { memo, useState } from 'react';
import { Button } from 'antd';
import { Typography } from '~/UI';
import Page from '~/router/Page';
import LookerEmbed from './LookerEmbed';

const LOOKS_IDS = {
  MODULE_PRICING_BY_CATEGORY: 'module_pricing_by_category',
  MODULE_PERCENTILE_PRICING: 'module_percentile_pricing',
  MODULE_PRICING_ABOVE_BELOW_SIMILAR: 'module_pricing_above_below_similar',
};

const MarketIntelligence: React.FC = () => {
  const [renderModulePercentile, setRenderModulePercentile] = useState(false);
  const [
    renderModulePricingAboveBelowSimilar,
    setRenderModulePricingAboveBelowSimilar,
  ] = useState(false);

  return (
    <Page title="Dashboard">
      <div style={{ paddingLeft: '16px', marginBottom: '16px' }}>
        <Typography.VendorSectionHeader
          style={{
            marginBottom: '12px',
          }}
        >
          Market Intelligence
        </Typography.VendorSectionHeader>
        <Button
          style={{
            width: '121px',
            height: '32px',
            padding: '6px 12px 6px 12px',
            borderRadius: '37px',
            opacity: '0px',
            display: 'flex',
            backgroundColor: '#E2E8F0',
            color: '#000000',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'unset',
          }}
        >
          Pricing Trends
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'stretch',
          gap: '12px',
        }}
      >
        <LookerEmbed
          style={{
            flexGrow: '1',
            borderRadius: '8px',
            overflow: 'hidden',
            border: '1px solid #E2E8F0',
          }}
          id={LOOKS_IDS.MODULE_PRICING_BY_CATEGORY}
          onLoad={() => setRenderModulePercentile(true)}
        />
        {renderModulePercentile && (
          <LookerEmbed
            style={{
              flexGrow: '1',
              borderRadius: '8px',
              overflow: 'hidden',
              border: '1px solid #E2E8F0',
            }}
            id={LOOKS_IDS.MODULE_PERCENTILE_PRICING}
            onLoad={() => setRenderModulePricingAboveBelowSimilar(true)}
          />
        )}
      </div>
      {renderModulePricingAboveBelowSimilar && (
        <LookerEmbed
          style={{
            marginTop: '12px',
            borderRadius: '20px',
            overflow: 'hidden',
            border: '1px solid #E2E8F0',
          }}
          id={LOOKS_IDS.MODULE_PRICING_ABOVE_BELOW_SIMILAR}
        />
      )}
    </Page>
  );
};

export default memo(MarketIntelligence);
