import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { getSecondsInHours } from '~/utils/timeConversions';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { ModuleType } from '~/types/modules';
import { ModuleResponse } from '~/types/rtkApi';

export const adminModulePricingApi = createApi({
  reducerPath: 'adminModulePricingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: getSecondsInHours(12),
  tagTypes: ['AdminCreateModulePricing', 'AdminUpdateModulePricing'],
  endpoints: (builder) => ({
    createModulePricing: builder.mutation<ModuleResponse, Partial<ModuleType>>({
      query: (body) => ({
        url: routes.admin.modules(),
        method: 'POST',
        body,
      }),
      transformResponse: (response: ModuleResponse) => response,
      invalidatesTags: ['AdminCreateModulePricing'],
    }),
    updateModulePricing: builder.mutation<ModuleResponse, ModuleType>({
      query: ({ id, ...body }) => ({
        url: routes.admin.modulesSettings(id.toString()),
        method: 'PUT',
        body,
      }),
      transformResponse: (response: ModuleResponse) => response,
      invalidatesTags: ['AdminUpdateModulePricing'],
    }),
  }),
});

export const {
  useCreateModulePricingMutation,
  useUpdateModulePricingMutation,
} = adminModulePricingApi;
