import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { slice as projectSlice } from './project';
import { slice as userSlice } from './user';
import {
  adminModulesApi,
  adminSearchFilesApi,
  adminUsersApi,
  modulesApi,
  projectsApi,
  vendorApi,
  supplierDashboardApi,
  anzaEngineApi,
  adminModulePricingApi,
  projectsApiv1,
  googlePlacesApi,
  engineApi,
  companiesApi,
  adminDomainsApi,
  adminLocationModifiersApi,
  adminCompaniesApi,
  adminProjectSpecificPricingApi,
  auditLogsApi,
  adminProjectsApi,
  adminDCBlocksApi,
  filtersApi,
  dashboardsApi,
} from './api';
import { slice as searchProjectSlice } from './searchProject';
import { slice as uiSlice } from './ui';

const rootReducer = combineReducers({
  searchProject: searchProjectSlice.reducer,
  project: projectSlice.reducer,
  user: userSlice.reducer,
  ui: uiSlice.reducer,
  [adminModulesApi.reducerPath]: adminModulesApi.reducer,
  [adminModulePricingApi.reducerPath]: adminModulePricingApi.reducer,
  [adminSearchFilesApi.reducerPath]: adminSearchFilesApi.reducer,
  [adminUsersApi.reducerPath]: adminUsersApi.reducer,
  [modulesApi.reducerPath]: modulesApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [vendorApi.reducerPath]: vendorApi.reducer,
  [supplierDashboardApi.reducerPath]: supplierDashboardApi.reducer,
  [anzaEngineApi.reducerPath]: anzaEngineApi.reducer,
  [projectsApiv1.reducerPath]: projectsApiv1.reducer,
  [googlePlacesApi.reducerPath]: googlePlacesApi.reducer,
  [engineApi.reducerPath]: engineApi.reducer,
  [companiesApi.reducerPath]: companiesApi.reducer,
  [adminLocationModifiersApi.reducerPath]: adminLocationModifiersApi.reducer,
  [adminDomainsApi.reducerPath]: adminDomainsApi.reducer,
  [adminCompaniesApi.reducerPath]: adminCompaniesApi.reducer,
  [adminDCBlocksApi.reducerPath]: adminDCBlocksApi.reducer,
  [adminProjectSpecificPricingApi.reducerPath]:
    adminProjectSpecificPricingApi.reducer,
  [auditLogsApi.reducerPath]: auditLogsApi.reducer,
  [adminProjectsApi.reducerPath]: adminProjectsApi.reducer,
  [filtersApi.reducerPath]: filtersApi.reducer,
  [dashboardsApi.reducerPath]: dashboardsApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    adminModulesApi.reducerPath,
    adminModulePricingApi.reducerPath,
    adminSearchFilesApi.reducerPath,
    adminUsersApi.reducerPath,
    modulesApi.reducerPath,
    projectsApi.reducerPath,
    vendorApi.reducerPath,
    supplierDashboardApi.reducerPath,
    anzaEngineApi.reducerPath,
    projectsApiv1.reducerPath,
    googlePlacesApi.reducerPath,
    engineApi.reducerPath,
    companiesApi.reducerPath,
    adminDomainsApi.reducerPath,
    adminLocationModifiersApi.reducerPath,
    adminDCBlocksApi.reducerPath,
    adminCompaniesApi.reducerPath,
    adminProjectSpecificPricingApi.reducerPath,
    auditLogsApi.reducerPath,
    adminProjectsApi.reducerPath,
    filtersApi.reducerPath,
    dashboardsApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      vendorApi.middleware,
      supplierDashboardApi.middleware,
      projectsApi.middleware,
      adminModulesApi.middleware,
      adminModulePricingApi.middleware,
      adminSearchFilesApi.middleware,
      modulesApi.middleware,
      adminUsersApi.middleware,
      anzaEngineApi.middleware,
      projectsApiv1.middleware,
      googlePlacesApi.middleware,
      engineApi.middleware,
      companiesApi.middleware,
      adminDomainsApi.middleware,
      adminLocationModifiersApi.middleware,
      adminDCBlocksApi.middleware,
      adminCompaniesApi.middleware,
      adminProjectSpecificPricingApi.middleware,
      auditLogsApi.middleware,
      adminProjectsApi.middleware,
      filtersApi.middleware,
      dashboardsApi.middleware,
    ]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
