import { Button, Divider, Space } from 'antd';
import styled from '@emotion/styled';

export const StyledSpace = styled(Space)`
  width: 100%;
  padding: 0 12px;
  height: 50px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 5px;
  }
`;
