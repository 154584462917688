import React from 'react';

import { Form, Space } from '~/UI';
import { inRangeValidator } from '~/utils/numberValidators';
import { FormLabel } from '~/UI/Typography';
import { maybeApplyPrefixName } from '~/utils/form';

import { PricingInputNumber } from './styles';

type StorageModulesAddersFormGroupProps = {
  /** Prefix key name to add in every form item */
  formItemsPrefixName?: string;
  isModuleActive: boolean;
};

const StorageModulesAddersFormGroup = ({
  isModuleActive,
  formItemsPrefixName,
}: StorageModulesAddersFormGroupProps) => (
  <Space size="small" direction="vertical" style={{ width: '100%' }}>
    <Form.Item
      name={maybeApplyPrefixName(formItemsPrefixName, 'tariff_percent')}
      label={<FormLabel>Tariff</FormLabel>}
      labelCol={{ span: 24 }}
      rules={[
        {
          required: isModuleActive,
          validator: inRangeValidator(
            0,
            100,
            true,
            'Please enter a number between 0 and 100, ex. 11.25',
            isModuleActive
          ).validator,
        },
      ]}
    >
      <PricingInputNumber
        data-testid="tariff_percent"
        maxLength={30}
        precision={2}
        displayPrecision={2}
        suffix="%"
      />
    </Form.Item>
    <Form.Item
      name={maybeApplyPrefixName(
        formItemsPrefixName,
        'east_coast_shipping_USD_per_kWh'
      )}
      label={<FormLabel>East Coast Shipping</FormLabel>}
      labelCol={{ span: 24 }}
      rules={[
        {
          required: isModuleActive,
          validator: inRangeValidator(
            -0.1,
            0.1,
            true,
            'Please enter a number between -0.1 and 0.1, ex. 0.015',
            isModuleActive
          ).validator,
        },
      ]}
    >
      <PricingInputNumber
        data-testid="east_coast_shipping_USD_per_kWh"
        maxLength={30}
        precision={4}
        displayPrecision={4}
        suffix="$/kWh DC"
      />
    </Form.Item>
    <Form.Item
      name={maybeApplyPrefixName(
        formItemsPrefixName,
        'midwest_coast_shipping_USD_per_kWh'
      )}
      label={<FormLabel>Midwest Shipping</FormLabel>}
      labelCol={{ span: 24 }}
      rules={[
        {
          required: isModuleActive,
          validator: inRangeValidator(
            -0.1,
            0.1,
            true,
            'Please enter a number between -0.1 and 0.1, ex. 0.015',
            isModuleActive
          ).validator,
        },
      ]}
    >
      <PricingInputNumber
        data-testid="midwest_coast_shipping_USD_per_kWh"
        maxLength={30}
        precision={4}
        displayPrecision={4}
        suffix="$/kWh DC"
      />
    </Form.Item>
    <Form.Item
      name={maybeApplyPrefixName(
        formItemsPrefixName,
        'west_coast_shipping_USD_per_kWh'
      )}
      label={<FormLabel>West Coast Shipping</FormLabel>}
      labelCol={{ span: 24 }}
      rules={[
        {
          required: isModuleActive,
          validator: inRangeValidator(
            -0.1,
            0.1,
            true,
            'Please enter a number between -0.1 and 0.1, ex. 0.015',
            isModuleActive
          ).validator,
        },
      ]}
    >
      <PricingInputNumber
        maxLength={30}
        precision={4}
        displayPrecision={4}
        suffix="$/kWh DC"
      />
    </Form.Item>

    {/* Adding hidden form items to send data on submit */}
    <Form.Item
      name={maybeApplyPrefixName(
        formItemsPrefixName,
        'default_shipping_cost_USD_per_kWh'
      )}
      hidden
    />
  </Space>
);

export default StorageModulesAddersFormGroup;
