import React from 'react';
import styled from '@emotion/styled';

import { theme } from '~/utils';
import { useAppSelector } from '~/store/hooks';
import { UiType } from '~/store/ui';
import PageBanner from '~/components/PageBanner';
import Footer from './Footer';

const LayoutBox = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${(props) =>
    props.backgroundColor || theme.colors.greyPageBackground};
  height: 100%;
  overflow: visible;
  flex-grow: 1;
`;

export interface LayoutProps {
  header?: React.ReactNode;
  backgroundColor?: string;
  wrapperStyles?: React.CSSProperties;
  className?: string;
  gtmId?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  header,
  backgroundColor,
  wrapperStyles,
  className,
  gtmId,
}) => {
  const { pageBanner } = useAppSelector<UiType>((state) => state.ui);
  return (
    <LayoutBox className={className}>
      {header}
      <PageBanner type={pageBanner} />
      <Wrapper
        style={wrapperStyles}
        backgroundColor={backgroundColor}
        id="main-content"
        data-gtm-type="root"
        data-gtm-id={gtmId}
      >
        {children}
      </Wrapper>
      <Footer />
    </LayoutBox>
  );
};

Layout.defaultProps = {
  header: undefined,
};

export default Layout;
