import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchProjectModalMode,
  SearchProjectPayload,
  SearchProjectState,
} from './types';

export const initialState: SearchProjectState = {
  type: 'solar',
  name: '',
  arrival_date: '',
  anticipated_purchase_date: '',
  duration: null,
  target_ac_power_mw: null,
  project_address: '',
  latitude: null,
  longitude: null,
  google_place_id: '',
  racking_type: null,
  size_in_kw: null,
  storage_arrival_date: '',
  size: null,
  annual_cycle_count: null,
  maintenance_term: null,
  metering_location: null,
  power_factor: null,
  project_topology: null,
  state: '',
  isModalVisible: false,
  mode: undefined,
  is_test: false,
};

const storeProjectType: CaseReducer<
  SearchProjectState,
  PayloadAction<{ type: 'solar' | 'storage' | null }>
> = (state, action) => ({
  ...state,
  type: action.payload.type,
});

const storeSolarProject: CaseReducer<
  SearchProjectState,
  PayloadAction<SearchProjectPayload>
> = (state, action) => ({
  ...state,
  type: 'solar',
  name: action.payload.name,
  arrival_date: action.payload.arrival_date,
  anticipated_purchase_date: action.payload.anticipated_purchase_date,
  project_address: action.payload.project_address,
  racking_type: action.payload.racking_type,
  size_in_kw: action.payload.size_in_kw,
  google_place_id: action.payload.google_place_id,
  latitude: action.payload.latitude,
  longitude: action.payload.longitude,
  state: action.payload.state,
  is_test: action.payload.is_test,
});

const storeStorageProject: CaseReducer<
  SearchProjectState,
  PayloadAction<SearchProjectPayload>
> = (state, action) => ({
  ...state,
  type: 'storage',
  name: action.payload.name,
  storage_arrival_date: action.payload.storage_arrival_date,
  duration: action.payload.duration,
  target_ac_power_mw: action.payload.target_ac_power_mw,
  project_address: action.payload.project_address,
  size: action.payload.size,
  annual_cycle_count: action.payload.annual_cycle_count,
  maintenance_term: action.payload.maintenance_term,
  metering_location: action.payload.metering_location,
  power_factor: action.payload.power_factor,
  project_topology: action.payload.project_topology,
  google_place_id: action.payload.google_place_id,
  latitude: action.payload.latitude,
  longitude: action.payload.longitude,
  state: action.payload.state,
  anticipated_purchase_date: action.payload.anticipated_purchase_date,
  is_test: action.payload.is_test,
});

const toggleSearchModal: CaseReducer<
  SearchProjectState,
  PayloadAction<{ value: boolean }>
> = (state, action) => ({
  ...state,
  isModalVisible: action.payload.value,
});

const updateModalMode: CaseReducer<
  SearchProjectState,
  PayloadAction<SearchProjectModalMode>
> = (state, action) => ({
  ...state,
  mode: action.payload,
});

const resetSearchModal = () => ({ ...initialState });

const reducers = {
  storeProjectType,
  storeSolarProject,
  storeStorageProject,
  toggleSearchModal,
  resetSearchModal,
  updateModalMode,
};

export default reducers;
