import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { catchError, pdfToImages } from '~/utils';
import { Skeleton } from '~/UI';

interface PDFViewerProps {
  fileUrl: string;
  height?: string | number;
  width?: string | number;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, width, height }) => {
  const [base64img, setBase64img] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const processPDF = async () => {
    try {
      const { data: blob } = await axios.get(fileUrl, { responseType: 'blob' });
      const image = new File([blob], 'thumbnail.pdf');
      const images = await pdfToImages(image);
      setBase64img(images[0]);
    } catch (error) {
      catchError({ error });
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fileUrl) {
      processPDF();
    }
  }, [fileUrl]);

  if (loading || hasError) {
    return <Skeleton.Image active style={{ width, height }} />;
  }

  return <img src={base64img} alt="thumbnail" width={width} height={height} />;
};

export default PDFViewer;
