import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../utils';
import { getColor } from '../utils/getColor';
import Dropdown, { DropdownProps } from './Dropdown';
import Spinner from './Spinner';

export interface ButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  text?: React.ReactNode | string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  spinner?: JSX.Element;
  style?: React.CSSProperties;
  icon?: JSX.Element;
  htmlType?: 'button' | 'submit' | 'reset';
  color?: keyof typeof theme.colors;
  styleType?: 'default' | 'link' | 'inverted';
  options?: string[];
  closeWhenClickOutside?: boolean;
  dropdownStyle?: React.CSSProperties;
}

type ColorPairLogic =
  | 'disabled'
  | 'disabledDefault'
  | 'disabledInverted'
  | 'enabled'
  | 'enabledDefault'
  | 'enabledInverted';

const getBorderColors = (color: ButtonProps['color']) => ({
  disabledInverted: theme.colors.gray,
  disabled: 'transparent',
  enabledInverted: getColor(color!),
  enabled: 'transparent',
});

const getComponentColors = (color: ButtonProps['color']) => ({
  disabledInverted: 'transparent',
  disabled: theme.colors.gray,
  enabledInverted: 'transparent',
  enabled: getColor(color!),
});

const getDisabledColors = (color: ButtonProps['color']) => ({
  disabled: 'lightgray',
  disabledDefault: 'lightgray',
  enabledDefault: 'white',
  enabled: getColor(color!),
});

const getSpinnerColors = (color: ButtonProps['color']) => ({
  disabled: 'lightgray',
  disabledDefault: 'lightgray',
  enabledDefault: 'rgba(255,255,255,0.4)',
  enabled: getColor(color!),
});

const getBorderColor = ({ disabled, styleType, color }: ButtonProps) => {
  const colorType: ColorPairLogic = `${disabled ? 'disabled' : 'enabled'}${
    styleType === 'inverted' ? 'Inverted' : ''
  }`;

  return getBorderColors(color)[colorType];
};

const getComponentColor = ({ disabled, styleType, color }: ButtonProps) => {
  const colorType: ColorPairLogic = `${disabled ? 'disabled' : 'enabled'}${
    styleType === 'inverted' ? 'Inverted' : ''
  }`;

  return getComponentColors(color)[colorType];
};

const getTextColor = ({ disabled, styleType, color }: ButtonProps) => {
  const colorType: ColorPairLogic = `${disabled ? 'disabled' : 'enabled'}${
    styleType === 'default' ? 'Default' : ''
  }`;

  return getDisabledColors(color)[colorType];
};

const getSpinnerColor = ({ disabled, styleType, color }: ButtonProps) => {
  const colorType: ColorPairLogic = `${disabled ? 'disabled' : 'enabled'}${
    styleType === 'default' ? 'Default' : ''
  }`;

  return getSpinnerColors(color)[colorType];
};

const ButtonElement = styled.button<ButtonProps>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  border-radius: 0.375rem;
  height: 48px;
  font-weight: 600;
  font-size: ${theme.fonts.sizes.button};
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props)};
  background-color: ${(props) => getComponentColor(props)};
  color: ${(props) => getTextColor(props)};
  cursor: ${(props) =>
    props.disabled || props.isLoading ? 'not-allowed' : 'pointer'};

  letter-spacing: 0.48px;
`;

const LinkButton = styled(ButtonElement)`
  color: ${(props) => (props.disabled ? 'lightgray' : getColor(props.color!))};
  background-color: transparent;
  text-decoration: underline;
  border: none;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: normal;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconElement = styled.span`
  margin-right: 0.5rem;
  font-size: 18px;

  svg {
    vertical-align: middle;
  }
`;

const DropdownContainer = styled.div`
  width: fit-content;
  position: relative;
`;

const ButtonContainer = styled.div`
  margin: 0;
  padding: 0;
  width: fit-content;
`;

const OverrideSpinner: React.FC<Partial<ButtonProps>> = ({
  disabled,
  styleType,
  color,
}) => {
  return (
    <Spinner
      style={{ marginRight: '0.6rem' }}
      size="1rem"
      thickness="0.2rem"
      trackColor={getSpinnerColor({ disabled, styleType, color })}
      indicatorColor="transparent"
    />
  );
};

const renderContent = (
  children: React.ReactNode,
  {
    isLoading,
    spinner,
    color,
    disabled,
    styleType,
    icon,
    text,
  }: Partial<ButtonProps> & Partial<DropdownProps>
) => {
  return isLoading ? (
    spinner || (
      <>
        <OverrideSpinner
          color={color}
          disabled={disabled}
          styleType={styleType}
        />{' '}
        Loading
      </>
    )
  ) : (
    <ButtonContainer>
      <ButtonContent>
        {icon && <IconElement>{icon}</IconElement>}
        {children || text}
      </ButtonContent>
    </ButtonContainer>
  );
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  options,
  spinner,
  style,
  icon,
  htmlType,
  text = '',
  disabled = false,
  isLoading = false,
  styleType = 'default',
  color = 'primary',
  closeWhenClickOutside,
  dropdownStyle,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);

  const handleDropdownOpen = (isOpen: boolean) => {
    setDropdownOpen(isOpen);
  };

  const handleClick = () => {
    if (!disabled && !isLoading) {
      if (options && options!.length) {
        setDropdownOpen(true);
      } else if (onClick != null) {
        setDropdownOpen(false);
        onClick();
      }
    }
  };

  return (
    <>
      {styleType === 'default' || styleType === 'inverted' ? (
        <>
          <ButtonElement
            onClick={handleClick}
            disabled={disabled}
            isLoading={isLoading}
            styleType={styleType}
            style={style}
            color={color}
            role="button"
            icon={icon}
            type={htmlType}
          >
            {renderContent(children, {
              isLoading,
              spinner,
              color,
              disabled,
              styleType,
              icon,
              text,
            })}
          </ButtonElement>

          {dropdownOpen && options && options!.length && (
            <DropdownContainer>
              <Dropdown
                options={options!}
                dropdownOpen={dropdownOpen}
                handleDropdownOpen={handleDropdownOpen}
                closeWhenClickOutside={closeWhenClickOutside}
                style={dropdownStyle}
              />
            </DropdownContainer>
          )}
        </>
      ) : (
        <>
          <LinkButton
            onClick={handleClick}
            disabled={disabled}
            isLoading={isLoading}
            styleType={styleType}
            style={style}
            color={color}
            icon={icon}
            type={htmlType}
          >
            {renderContent(children, {
              isLoading,
              spinner,
              color,
              disabled,
              styleType,
              icon,
              text,
            })}
          </LinkButton>
          {dropdownOpen && options && options!.length && (
            <DropdownContainer>
              <Dropdown
                options={options!}
                dropdownOpen={dropdownOpen}
                handleDropdownOpen={handleDropdownOpen}
                closeWhenClickOutside={closeWhenClickOutside}
                style={dropdownStyle}
              />
            </DropdownContainer>
          )}
        </>
      )}
    </>
  );
};

export default Button;
