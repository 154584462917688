import React, { memo } from 'react';
import { inRangeValidator } from '~/utils/numberValidators';
import { FormLabel } from '~/UI/Typography';
import { Form } from '~/UI';
import {
  Description,
  FormBox,
  FormRow,
  PricingInputNumber,
  Title,
} from './styles';
import { isNullEmptyUndefined } from '~/utils';

const valueParser = (value: string | undefined): string | number =>
  isNullEmptyUndefined(value) ? 0 : (value ?? 0);

const ModulesAddersFormGroup = () => {
  return (
    <FormBox>
      <div>
        <Title>Land Shipping</Title>
        <Description>
          {
            'High shipping is applied for all sites >500 miles from the nearest port.'
          }
        </Description>
      </div>
      <FormRow style={{ marginBottom: '40px' }}>
        <Form.Item
          name="site_shipping_standard_adder"
          label={<FormLabel>Standard</FormLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                0,
                0.1,
                true,
                'Enter a value between 0 and 0.1',
                false
              ).validator,
            },
          ]}
          validateTrigger="onBlur"
        >
          <PricingInputNumber
            name="site_shipping_standard_adder"
            data-testid="site_shipping_standard_adder"
            maxLength={30}
            precision={4}
            displayPrecision={4}
            suffix="$/W"
            style={{ width: 'fit-content' }}
            parser={valueParser}
          />
        </Form.Item>
        <Form.Item
          name="site_shipping_high_adder"
          label={<FormLabel>High</FormLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                0,
                0.1,
                true,
                'Enter a value between 0 and 0.1',
                false
              ).validator,
            },
          ]}
          validateTrigger="onBlur"
        >
          <PricingInputNumber
            data-testid="site_shipping_high_adder"
            name="site_shipping_high_adder"
            maxLength={30}
            precision={4}
            displayPrecision={4}
            suffix="$/W"
            style={{ width: 'fit-content' }}
            parser={valueParser}
          />
        </Form.Item>
      </FormRow>
      <div>
        <Title>Port Adders</Title>
        <Description>
          Quarterly pricing should be DDP East Coast port price. Set adders to
          adjust for other ports.
        </Description>
      </div>
      <FormRow>
        <Form.Item
          name="west_coast_port_adder"
          label={<FormLabel>West Coast</FormLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                -0.1,
                0.1,
                true,
                'Enter a value between -0.1 and 0.1',
                false
              ).validator,
            },
          ]}
          validateTrigger="onBlur"
        >
          <PricingInputNumber
            name="west_coast_port_adder"
            data-testid="west_coast_port_adder"
            maxLength={30}
            precision={4}
            displayPrecision={4}
            suffix="$/W"
            style={{ width: 'fit-content' }}
            parser={valueParser}
          />
        </Form.Item>
        <Form.Item
          name="midwest_coast_port_adder"
          label={<FormLabel>Midwest</FormLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                -0.1,
                0.1,
                true,
                'Enter a value between -0.1 and 0.1',
                false
              ).validator,
            },
          ]}
          validateTrigger="onBlur"
        >
          <PricingInputNumber
            name="midwest_coast_port_adder"
            data-testid="midwest_coast_port_adder"
            maxLength={30}
            precision={4}
            displayPrecision={4}
            suffix="$/W"
            style={{ width: 'fit-content' }}
            parser={valueParser}
          />
        </Form.Item>
      </FormRow>
    </FormBox>
  );
};

export default memo(ModulesAddersFormGroup);
