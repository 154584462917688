import * as R from 'ramda';
import * as H from 'history';
import queryString from 'query-string';
import type { ParsedQs } from 'qs';
import type { FilterValues } from '~/UI/Filters/FilterForm';

type SortOrder = 'ASC' | 'DESC';

export interface TablePaginationSortingConfig {
  offset?: number;
  limit?: number;
  sortField?: string;
  sortOrder?: SortOrder;
  filter?: string;
}

export interface TableConfig {
  pagination: {
    current: number;
    pageSize: number;
    showSizeChanger?: boolean;
  };
  sorter?: {
    sortField: string;
    sortOrder: 'ascend' | 'descend';
  };
  total?: number;
  filters?: {
    [key: string]: string[];
  };
}

enum SortDirection {
  ascend = 'ASC',
  descend = 'DESC',
}

enum SortDirectionKey {
  ASC = 'ascend',
  DESC = 'descend',
}

const removeEmptyParams = <T>(
  params: Record<string, T> = {}
): Record<string, T> => {
  return Object.fromEntries(
    Object.entries(params).filter(([, value]) => value)
  );
};

export type InitialState = {
  filters: Record<string, string[]>;
  pagination: {
    current: number;
    pageSize: number;
    total: number;
    showSizeChanger?: boolean;
  };
  sorter: {
    sortField: string;
    sortOrder: string;
  };
};
export const paramsReducer = <T extends Partial<InitialState>>(
  state: InitialState,
  action: {
    type: string;
    data: T;
  }
): InitialState => {
  switch (action.type) {
    case 'count':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: action.data.pagination?.total as number,
        },
      };
    case 'update':
      return {
        ...state,
        filters: { ...state.filters, ...action.data?.filters },
        pagination: { ...state.pagination, ...action.data?.pagination },
        sorter: { ...state.sorter, ...action.data?.sorter },
      };
    case 'reset':
      return { ...action.data } as InitialState;
    default:
      return state;
  }
};

export const toApiParams = ({
  pagination,
  sorter,
  filters,
}: InitialState): string => {
  const { current, pageSize: limit } = pagination;
  const offset = (current - 1) * limit;
  const { sortField, sortOrder } = sorter || {};
  let filterObject = {};

  if (filters) {
    filterObject = Object.keys(filters).reduce(
      (acc: { [key: string]: string }, key) => {
        acc[key] = filters[key]?.filter((value) => value).join(',');
        return acc;
      },
      {}
    );
    filterObject = removeEmptyParams(filterObject);
  }

  const apiParams = removeEmptyParams({
    limit,
    offset,
    sortField,
    sortOrder:
      sortOrder && SortDirection[sortOrder as keyof typeof SortDirection],
    filter: R.isEmpty(filterObject) ? undefined : JSON.stringify(filterObject),
  });
  const searchParams = new URLSearchParams(apiParams as Record<string, string>);

  return searchParams.toString();
};

interface Sorter {
  sortField: string;
  sortOrder: SortOrder;
}

const setSorter = ({ sortField, sortOrder }: Sorter, params: InitialState) => {
  const { sorter, ...otherParams } = params;

  return {
    ...otherParams,
    sorter: {
      sortField,
      sortOrder: SortDirectionKey[sortOrder],
    },
  };
};

interface Pagination {
  limit: string;
  offset: string | undefined;
}

const setPagination = ({ limit, offset }: Pagination, params: InitialState) => {
  const offsetInt = parseInt(offset ?? '0', 10);
  const limitInt = parseInt(limit, 10);
  let page = 1;

  if (offsetInt >= limitInt) {
    page = Math.floor(offsetInt / limitInt) + 1;
  }

  return {
    ...params,
    pagination: {
      ...params.pagination,
      current: page,
    },
  };
};

interface Filters {
  filter: string;
}

const setFilters = ({ filter }: Filters, params: InitialState) => {
  const filters = R.map((f: string) => f.split(','))(
    JSON.parse(filter)
  ) as unknown as Record<string, string[]>;

  return {
    ...params,
    filters,
  };
};

export const queryStringToParams = (
  history: H.History,
  defaultParams: InitialState
) => {
  const historyParams = queryString.parse(history.location.search);

  let returnParams = { ...defaultParams };

  // sorting and ordering
  if (!!historyParams.sortField && !!historyParams.sortOrder) {
    returnParams = setSorter(historyParams as unknown as Sorter, returnParams);
  }

  // pagination
  if (historyParams.limit) {
    returnParams = setPagination(
      historyParams as unknown as Pagination,
      returnParams
    );
  }

  // filters
  if (historyParams.filter) {
    returnParams = setFilters(
      historyParams as unknown as Filters,
      returnParams
    );
  }

  return returnParams;
};

export type ParsedQsPossibleValues =
  | string
  | string[]
  | ParsedQs
  | ParsedQs[]
  | undefined;

export const getSolarPDPFilterValuesFromQs = (
  obj: ParsedQsPossibleValues,
  fallback?: FilterValues
) => {
  if (!obj || Array.isArray(obj)) return fallback ?? {};
  const ret = Object.entries(obj).reduce<FilterValues>((acc, [key, value]) => {
    if (!value || typeof value === 'string') return acc;
    const stringValues = Array.isArray(value)
      ? value.map((item) => `${item}`)
      : Object.values(value).map((item) => `${item}`);
    return { ...acc, [key]: stringValues };
  }, {});
  return ret;
};
