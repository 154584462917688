import React, { useEffect } from 'react';
import FormItem from 'antd/es/form/FormItem';
import {
  ButtonAnt as Button,
  Form,
  FormItemLabel,
  InputNumber,
  Space,
} from '~/UI';
import { DCBlockQuoteConstraint } from '~/store/project';
import { inRangeValidator } from '~/utils/numberValidators';

export type ConstraintFormProps = {
  type: 'edit' | 'create';
  constraint?: DCBlockQuoteConstraint;
  onFinish?: (values: DCBlockQuoteConstraint) => void;
  onCancel?: () => void;
};

const ConstraintForm = ({
  type,
  constraint,
  onFinish,
  onCancel,
}: ConstraintFormProps) => {
  const [form] = Form.useForm<DCBlockQuoteConstraint>();

  useEffect(() => {
    // When we change the constraint data or the form type ("edit" or "creation")
    form.resetFields();
    form.setFieldsValue(constraint || {});
  }, [constraint, type]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        if (onFinish) onFinish(values);
      }}
    >
      <Space size="middle" direction="vertical">
        <Form.Item
          label={<FormItemLabel>Min (MWh DC)</FormItemLabel>}
          name="numeric_value"
          rules={[inRangeValidator(0, 9, true, '', false)]}
          labelCol={{ span: 24 }}
        >
          <InputNumber
            precision={4}
            displayPrecision={3}
            step={0.0001}
            max={9}
            suffix="MWh"
          />
        </Form.Item>
        <FormItem
          label={<FormItemLabel>Price FOB ($/kWh DC)</FormItemLabel>}
          name="fob_cost_USD_per_kWh"
          rules={[inRangeValidator(0, 9, true, '', false)]}
          labelCol={{ span: 24 }}
        >
          <InputNumber
            precision={4}
            displayPrecision={3}
            step={0.0001}
            max={9}
            suffix="$/kWh"
          />
        </FormItem>
        <Form.Item
          label={
            <FormItemLabel>Battery Unit Commissioning ($/kWh DC)</FormItemLabel>
          }
          name="commissioning_cost_USD_per_kWh"
          rules={[inRangeValidator(0, 9, true, '', false)]}
          labelCol={{ span: 24 }}
        >
          <InputNumber
            precision={4}
            displayPrecision={3}
            step={0.0001}
            max={9}
          />
        </Form.Item>

        {/* Adding hidden form items to send data when submitting */}
        <Form.Item style={{ height: 0 }} name="uuid" hidden />
        <Form.Item style={{ height: 0 }} name="dc_block_uuid" hidden />
        <Form.Item style={{ height: 0 }} name="dc_block_quote_uuid" hidden />
        <Form.Item style={{ height: 0 }} name="type" hidden />

        <Space size="middle" direction="horizontal">
          <Button onClick={() => onCancel && onCancel()}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default ConstraintForm;
