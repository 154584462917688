import React from 'react';
import styled from '@emotion/styled';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

import { IconAdd } from '~/UI/Icons';
import { Alert, Upload } from '~/UI';
import { theme } from '~/utils';
import type { UploadFile } from './Upload';

const UploadLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: 8px;
    color: ${theme.colors.primary};
  }
`;

type ImageFileInputProps = {
  allowedLogoSizeInMBs: number;
  fileToDisplay: UploadFile;
  fileError: string;
  handleImageChange: (info: UploadChangeParam<UploadFile>) => void;
  updateFile: (fileToUpload: UploadFile) => void;
  updateFileError: (error: string) => void;
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ImageFileInput = ({
  allowedLogoSizeInMBs,
  fileToDisplay,
  fileError,
  handleImageChange,
  updateFile,
  updateFileError,
}: ImageFileInputProps) => {
  const beforeUpload = (fileIndividual: RcFile) => {
    const isJpgOrPng =
      fileIndividual.type === 'image/jpeg' ||
      fileIndividual.type === 'image/png';
    const insideSizeLimit =
      fileIndividual.size / 1024 / 1024 < allowedLogoSizeInMBs;

    if (!isJpgOrPng) {
      updateFileError('You can only upload JPG/PNG file!');
    }

    if (!insideSizeLimit) {
      updateFileError(`Image must smaller than ${allowedLogoSizeInMBs}MB!`);
    }

    if (insideSizeLimit && isJpgOrPng) {
      updateFileError('');
      getBase64(fileIndividual as RcFile, (url) => {
        updateFile({
          ...fileToDisplay,
          thumbUrl: url,
          status: 'uploading',
          percent: 50,
        });
      });
    }
    return false;
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileToDisplay.thumbUrl ? [fileToDisplay] : []}
        onChange={handleImageChange}
        beforeUpload={beforeUpload}
        showUploadList={{ showPreviewIcon: false }}
      >
        {fileToDisplay.thumbUrl ? null : (
          <UploadLogoContainer>
            <IconAdd size="24px" color={theme.colors.primary} />
            <span>Upload</span>
          </UploadLogoContainer>
        )}
      </Upload>
      {fileError ? (
        <Alert
          message=""
          description={fileError}
          type="error"
          closable
          onClose={() => updateFileError('')}
          style={{ maxWidth: 'max-content' }}
        />
      ) : null}
    </>
  );
};

export default ImageFileInput;
