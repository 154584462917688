import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { AnalyticsSchema } from '~/store/project';
import { SmallPrimaryTitle } from '~/UI/Typography';
import ExternalLinkWithAnalytics from '~/components/ExternalLinkWithAnalytics';
import { GTM_SOURCE_IDS } from '~/utils/gtm';
import { InfoContainer, TextBlock, TitleContainer } from './styles';

const StyeldExternalLinkWithAnalytics = styled(ExternalLinkWithAnalytics)`
  font-weight: 600;
  text-decoration: underline;
`;

type AnayticsInfoProps = {
  schemas: Record<string, AnalyticsSchema>;
};

const AnayticsInfo = ({ schemas }: AnayticsInfoProps) => {
  const schemasArray = R.values(schemas);
  const sortedSchemas = R.sortWith(
    [R.ascend((s) => Number(s.display_order))],
    schemasArray
  );

  return (
    <>
      <TitleContainer>
        <StyeldExternalLinkWithAnalytics
          href="https://go.anzarenewables.com/knowledge-base-advanced-view"
          gtmId={GTM_SOURCE_IDS.SOLAR_PROJECT.ADVANCED_INFO_DRAWER_BLOG}
        >
          Learn more about how to use the Advanced View
        </StyeldExternalLinkWithAnalytics>
      </TitleContainer>
      {sortedSchemas.map((schema) => {
        return (
          <InfoContainer key={schema.ui_name}>
            <SmallPrimaryTitle>{schema.ui_name}</SmallPrimaryTitle>
            <TextBlock>{schema.tooltip}</TextBlock>
          </InfoContainer>
        );
      })}
    </>
  );
};

export default AnayticsInfo;
