import styled from '@emotion/styled';
import { Form, Alert, Collapse, ButtonAnt as Button } from '~/UI';

import { theme } from '~/utils';
import { RadioButton, RadioGroup } from '~/UI/Radio';

export const StyledForm = styled(Form)`
  margin-top: 2rem;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
`;

export const StyledRadioButton = styled(RadioButton)`
  text-align: center;
  width: 49%;
  border-radius: 6px !important;
  border-left: 1px solid #eeeff0;

  &:before {
    content: none !important;
  }
`;

export const StyledDrawerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledDrawerFooterButton = styled(Button)`
  width: 100%;
  height: 4rem;
`;

export const StyledCollapse = styled(Collapse)`
  margin-top: 2rem;
  .ant-collapse-item {
    .ant-collapse-header {
      border-radius: 8px !important;
      background-color: rgba(16, 105, 125, 0.08);
      color: #10697d;
      padding: 14px 16px;
      border-top: none;

      .ant-collapse-header-text {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
`;

export const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    margin-top: 1.25rem;
    padding: 0;
  }
`;

export const StyledAlert = styled(Alert)`
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  background-color: ${theme.colors.tintBlue};
`;
