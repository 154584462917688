import React from 'react';
import { useWatch } from 'antd/es/form/Form';

import { FormInstance } from 'antd';
import { StorageAdvancedInputs } from '~/store/project';
import { InputNumber, Form } from '~/UI';
import STORAGE_INPUT_CONFIG from '~/constants/inputs/project/storageInputs';

import { GenericLabel } from '../FormGroup';

interface Props {
  defaultAdvancedInputs: StorageAdvancedInputs;
  disabledInputs?: boolean;
  form: FormInstance;
}
const StorageAdvancedInputsFormGroup = ({
  defaultAdvancedInputs,
  disabledInputs,
  form,
}: Props) => {
  const pcs_warranty_years = useWatch('pcs_warranty_years', form);
  const pcs_maintenance_years = useWatch('pcs_maintenance_years', form);
  const dc_block_warranty_years = useWatch('dc_block_warranty_years', form);
  const dc_block_maintenance_years = useWatch(
    'dc_block_maintenance_years',
    form
  );

  const showWarrantyDisclaimer = pcs_warranty_years !== dc_block_warranty_years;
  const showMaintenanceDisclaimer =
    pcs_maintenance_years !== dc_block_maintenance_years;

  return (
    <>
      <Form.Item
        name="resting_state_of_charge_percent"
        initialValue={defaultAdvancedInputs?.resting_state_of_charge_percent}
        label={
          <GenericLabel
            title="Average Resting State of Charge"
            fontSize="14px"
            popoverContent="AC / DC Block’s average resting state of charge"
          />
        }
        labelCol={{ span: 24 }}
        rules={
          STORAGE_INPUT_CONFIG.advancedInputs.resting_state_of_charge_percent
            .rules
        }
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs
            .resting_state_of_charge_percent.inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="depth_of_discharge_percent"
        initialValue={defaultAdvancedInputs?.depth_of_discharge_percent}
        label={
          <GenericLabel
            title="Depth of Discharge"
            fontSize="14px"
            popoverContent="System’s intended depth of discharge relative to the usable state of charge"
          />
        }
        labelCol={{ span: 24 }}
        rules={
          STORAGE_INPUT_CONFIG.advancedInputs.depth_of_discharge_percent.rules
        }
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.depth_of_discharge_percent
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="interconnection_voltage"
        initialValue={defaultAdvancedInputs?.interconnection_voltage}
        label={
          <GenericLabel
            title="Interconnection Voltage"
            fontSize="14px"
            popoverContent="Not included in calculations at this time."
          />
        }
        labelCol={{ span: 24 }}
        rules={
          STORAGE_INPUT_CONFIG.advancedInputs.interconnection_voltage.rules
        }
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.interconnection_voltage
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="pcs_warranty_years"
        initialValue={defaultAdvancedInputs?.pcs_warranty_years}
        label={
          <GenericLabel
            title="PCS Extended Warranty Term"
            fontSize="14px"
            popoverContent="Term is defined starting from COD."
          />
        }
        labelCol={{ span: 24 }}
        rules={STORAGE_INPUT_CONFIG.advancedInputs.pcs_warranty_years.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.pcs_warranty_years
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="pcs_maintenance_years"
        initialValue={defaultAdvancedInputs?.pcs_maintenance_years}
        label={
          <GenericLabel
            title="PCS Preventative Maintenance Term"
            fontSize="14px"
            popoverContent="Term is defined starting from COD."
          />
        }
        labelCol={{ span: 24 }}
        rules={STORAGE_INPUT_CONFIG.advancedInputs.pcs_maintenance_years.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.pcs_maintenance_years
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="dc_block_warranty_years"
        initialValue={defaultAdvancedInputs?.dc_block_warranty_years}
        label={
          <GenericLabel
            title="AC / DC Block Extended Warranty Term"
            fontSize="14px"
            popoverContent="Term is defined starting from COD."
          />
        }
        labelCol={{ span: 24 }}
        rules={
          STORAGE_INPUT_CONFIG.advancedInputs.dc_block_warranty_years.rules
        }
        extra={
          showWarrantyDisclaimer &&
          'For AC Blocks, the PCS Extended Warranty terms will default to the terms entered for the AC / DC Block.'
        }
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.dc_block_warranty_years
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="dc_block_maintenance_years"
        initialValue={defaultAdvancedInputs?.dc_block_maintenance_years}
        label={
          <GenericLabel
            title="AC / DC Block Preventative Maintenance Term"
            fontSize="14px"
            popoverContent="Term is defined starting from COD."
          />
        }
        labelCol={{ span: 24 }}
        rules={
          STORAGE_INPUT_CONFIG.advancedInputs.dc_block_maintenance_years.rules
        }
        extra={
          showMaintenanceDisclaimer &&
          'For AC Blocks, the PCS Preventative Maintenance terms will default to the terms entered for the AC / DC Block.'
        }
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.advancedInputs.dc_block_maintenance_years
            .inputNumberProps}
          disabled={disabledInputs}
        />
      </Form.Item>
    </>
  );
};

export default StorageAdvancedInputsFormGroup;
