import type { Dispatch, SetStateAction } from 'react';

import type { FormInstance } from '~/UI';

export type CheckInvalid = (
  fieldNameList?: Parameters<FormInstance['validateFields']>[0]
) => Promise<void>;

/**
  Due to how the Antd Form API works, calling the return of this function
  without any parameters can cause the page to slow down, since
  form.validateFields() will try to validate every required field in the form.
*/
const getCheckInvalidFormFields =
  (
    form: FormInstance,
    setIsInvalid: Dispatch<SetStateAction<boolean>>
  ): CheckInvalid =>
  async (fieldNameList?: Parameters<typeof form.validateFields>[0]) => {
    return form
      .validateFields(fieldNameList)
      .then(() => {
        setIsInvalid(false);
      })
      .catch(({ errorFields: { length = 0 } }) => {
        setIsInvalid(!!length);
      });
  };

export default getCheckInvalidFormFields;
