import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { theme } from '~/utils';

import { Avatar, ButtonAnt as Button } from '~/UI';

import Padder from '../Padder';
import { IconCall } from '../Icons';

export const HEADER_MARGIN_BOTTOM = '1rem';

export const HeaderContainer = styled.div`
  background-color: ${theme.colors.primary};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 120;
  margin-bottom: ${HEADER_MARGIN_BOTTOM};
`;

export const NavContainer = styled(Padder)`
  display: flex;
  gap: 32px;
  height: ${theme.elements.header.height};
  width: 100%;
  max-width: ${theme.dimensions.width.max}px;
  align-items: center;
  padding: 7px 40px;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 24px;
    padding-right: 18px;
  }
`;

export const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-color: transparent !important;
`;

export const PrimaryItem = styled(Button)<{ active?: boolean }>`
  gap: 7px;
  height: 34px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 9px 12px;
  background-color: transparent;
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 14px;
  border-radius: 36px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.24) !important;
    color: ${theme.colors.white};
  }
  &:focus {
    color: ${theme.colors.white};
    border-color: transparent;
    background-color: transparent;
  }
  ${({ active }) => (active ? 'background-color: rgba(0, 0, 0, 0.24);' : '')}
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin-left: auto;
`;

export const ContactUsButton = styled(PrimaryItem)`
  text-wrap: nowrap;
  gap: 7px;
  border: 1px solid ${theme.colors.white} !important;
`;

export const ContactUsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

export const ContactUsIcon = styled(IconCall)`
  color: ${theme.colors.yellow};
`;

export const Mailto = styled.a`
  color: ${theme.colors.white};
  border-radius: 50px;
  padding: 4px 12px;
  &:hover {
    color: ${theme.colors.white};
    background-color: rgba(0, 0, 0, 0.24);
  }
`;

export const UserAvatar = styled(Avatar)<{ color: string; fontSize?: number }>`
  background-color: ${({ color }) => color};
  font-weight: 600;
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px !important;` : '')}
`;

export const SecondaryTabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  height: 100%;
  max-width: ${theme.dimensions.width.max}px;
  margin: auto;
  padding: 0 40px;
  @media (max-width: ${theme.dimensions.width.min + 80}px) {
    padding: 0 10px !important;
  }
`;

export const SecondaryNav = styled.div<{ visible?: boolean }>`
  height: 0;
  transition: height 0.1s ease-out;
  pointer-events: none;
  ${({ visible }) =>
    visible
      ? `pointer-events: all; height: ${theme.elements.secondaryHeader.height};`
      : ''}
  color: ${theme.colors.charcoal};
  background-color: ${theme.colors.white};
`;

export const SecondaryTabLink = styled(NavLink)<{ active: boolean }>`
  height: 100%;
  ${({ active }) =>
    active
      ? `
  border-bottom: 2px solid ${theme.colors.primary} !important;
  & > div {
    color: ${theme.colors.primary} !important;
  }`
      : ''}
  &.active {
    border-bottom: 2px solid ${theme.colors.primary} !important;
    & > div {
      color: ${theme.colors.primary} !important;
    }
  }
`;

export const SecondaryTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
  cursor: pointer;
  color: ${theme.colors.gray800};
  font-weight: 500;
  font-size: 14px;
  border-bottom: 2px solid transparent;
`;
