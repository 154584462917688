// @ts-nocheck
import React from 'react';
import * as R from 'ramda';

import { Tag } from '~/UI';

export const getArrayAsTags =
  (field: string) =>
  (arr: string[], raw?: boolean): JSX.Element =>
    R.pipe(
      (value) => R.pluck(field, value as string[]),
      (value: string[]) =>
        R.ifElse(
          R.equals(true),
          () => R.join(', ', value),
          () =>
            R.pipe(
              R.map((name: string) => <Tag text={name} key={Math.random()} />),
              (tags) => <>{tags}</>
            )(value)
        )(!!raw)
    )(arr) as JSX.Element;
