import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ActiveProjectsData,
  ActiveProjectsResponse,
  CompanyModulesData,
  QuartersData,
  VendorPipelineResponse,
} from '~/services/api/anza';
import { getSecondsInHours } from '~/utils/timeConversions';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { ModuleType } from '~/types/modules';

export const vendorApi = createApi({
  reducerPath: 'vendorApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: getSecondsInHours(12),
  tagTypes: [
    'Pipeline',
    'VendorModulesMetrics',
    'VendorActiveProjects',
    'AllVendorsModuleMetrics',
    'AllVendorsActiveProjects',
    'Module',
    'AllModules',
  ],
  endpoints: (builder) => ({
    // 👇 Query: Get Vendor's Pipeline Data
    getVendorPipeline: builder.query<VendorPipelineResponse, void>({
      query: () => routes.anza.metrics.pipeline(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [{ type: 'Pipeline', id: 'PIPELINE' }],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
    // 👇 Query: Get Vendor's Module Metrics Data
    getModuleMetrics: builder.query<
      VendorPipelineResponse,
      { vendorId: string; moduleId: string }
    >({
      query: ({ vendorId, moduleId }) =>
        routes.anza.metrics.vendorModules(vendorId, moduleId),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'VendorModulesMetrics', id: 'VENDOR_MODULE_METRICS' },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
    // 👇 Query: Get all vendors Module Metrics Data
    getAllModuleMetrics: builder.query<VendorPipelineResponse, undefined>({
      query: () => routes.anza.metrics.allVendorsModules(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'AllVendorsModuleMetrics', id: 'ALL_VENDORS_MODULE_METRICS' },
      ],
      transformResponse: (response: { data: VendorPipelineResponse }) =>
        response.data,
    }),
    // 👇 Query: Get Vendor's Module Metrics Data
    getActiveProjects: builder.query<
      ActiveProjectsData[],
      { vendorId: string }
    >({
      query: ({ vendorId }) => routes.anza.metrics.vendorProjects(vendorId),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'VendorActiveProjects', id: 'VENDOR_ACTIVE_PROJECTS' },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: ActiveProjectsResponse) => response.data,
    }),
    // 👇 Query: Get Vendor's Module Metrics Data
    getAllActiveProjects: builder.query<ActiveProjectsData[], undefined>({
      query: () => routes.anza.metrics.allVendorsProjects(),
      keepUnusedDataFor: getSecondsInHours(2),
      providesTags: () => [
        { type: 'AllVendorsActiveProjects', id: 'ALL_VENDORS_ACTIVE_PROJECTS' },
      ],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: ActiveProjectsResponse) => response.data,
    }),
    updateModulePricing: builder.mutation<
      ModuleType,
      {
        uuid: string;
        quarters: QuartersData[];
        adders: Partial<CompanyModulesData>;
      }
    >({
      query: (args) => {
        const { uuid, quarters, adders } = args;
        return {
          url: routes.modules.updateModulePricing(uuid),
          method: 'PATCH',
          body: { quarters, adders },
        };
      },
      transformResponse: (response: { data: ModuleType }) => response.data,
    }),
    confirmModulePricing: builder.mutation<ModuleType, { uuid: string }>({
      query: (args) => {
        const { uuid } = args;
        return {
          url: routes.modules.confirmModulePricing(uuid),
          method: 'POST',
        };
      },
      transformResponse: (response: { data: ModuleType }) => response.data,
    }),
    // 👇 Query: Get All Modules for current vendor
    getModules: builder.query<CompanyModulesData[], undefined>({
      query: () => routes.anza.vendor.modules(),
      keepUnusedDataFor: getSecondsInHours(24),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Module' as const,
                id,
              })),
              { type: 'Module', id: 'MODULE' },
            ]
          : [{ type: 'Module', id: 'MODULE' }],
    }),
    // 👇 Query: Get All Modules for all vendors
    getAllModules: builder.query<CompanyModulesData[], { vendorId: string }>({
      query: ({ vendorId }) => `${routes.anza.vendor.vendorModules(vendorId)}`,
      keepUnusedDataFor: getSecondsInHours(24),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'AllModules' as const,
                id,
              })),
              { type: 'AllModules', id: 'ALL_MODULE' },
            ]
          : [{ type: 'AllModules', id: 'ALL_MODULE' }],
    }),
  }),
});

export const {
  useGetVendorPipelineQuery,
  useGetModuleMetricsQuery,
  useGetActiveProjectsQuery,
  useGetAllModuleMetricsQuery,
  useGetAllActiveProjectsQuery,
  useUpdateModulePricingMutation,
  useConfirmModulePricingMutation,
  useGetModulesQuery,
  useLazyGetModulesQuery,
  useGetAllModulesQuery,
} = vendorApi;
