import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Switch as SwitchAnt } from 'antd';
import {
  SwitchProps as SwitchAntProps,
  SwitchClickEventHandler,
} from 'antd/lib/switch';

import { GTM_EVENTS, maybeSendPlatformEventToGTM } from '~/utils/gtm';
import { theme } from '../utils';

export type SwitchProps = SwitchAntProps & {
  includeGtmProperties?: boolean;
};

const StyledSwitch = styled(SwitchAnt)<SwitchAntProps>`
  &:not(.ant-switch-checked) {
    background-color: ${theme.colors.stone};
    background-image: none;
    width: 44px;
    height: 22px;
  }
  &.ant-switch-checked {
    width: 44px;
    height: 22px;
  }

  .ant-switch-handle {
    width: 18px;
    height: 18px;
    right: 2px;
  }

  .ant-switch-handle::before {
    min-width: 18px;
    min-height: 18px;
    border-radius: 100%;
    top: -2px;
    bottom: 2px;
  }

  &.ant-switch-small {
    .ant-switch-handle {
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
    }
  }

  &:not(.ant-switch-checked) {
    .ant-switch-handle::before {
      left: -2px;
      right: 2px;
    }
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
  }
`;

const Switch: React.FC<SwitchProps> = ({ ...props }) => {
  const onClick: SwitchClickEventHandler = useCallback((checked, event) => {
    if (props.includeGtmProperties) {
      const gtmId = props['data-gtm-id' as keyof SwitchProps] as string | null;
      maybeSendPlatformEventToGTM(GTM_EVENTS.CLICK, gtmId);
    }

    if (props.onClick) {
      props.onClick(checked, event);
    }
  }, []);
  return (
    <StyledSwitch
      {...props}
      onClick={(checked, event) => onClick(checked, event)}
    />
  );
};

export default Switch;
