import React from 'react';

import { Row } from '~/UI';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';

import AdminTable from '../AdminTable/AdminTable';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';

const AdminDcBlocksList: React.FC<AdminListPageProps> = ({
  service,
  pageName,
}) => {
  const columns = [
    {
      title: 'AC / DC Block Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];
  return (
    <div>
      <Row justify="space-between">
        <H2>{pageName}</H2>
      </Row>
      <AdminTable
        service={service}
        columns={columns}
        customActions={{
          edit: {
            onHref: (dcBlock) =>
              ADMIN_ROUTES.DC_BLOCKS.path.edit(dcBlock.uuid?.toString() || ''),
          },
          delete: {
            visible: false,
          },
        }}
      />
    </div>
  );
};

export default AdminDcBlocksList;
