import React from 'react';
import { Space as SpaceAnt, SpaceProps as SpacePropsAnt } from 'antd';
import styled from '@emotion/styled';
import { includes } from 'ramda';

type AntSize = 'small' | 'middle' | 'large';

// To add new size just include it here
const ADDITINAL_GAP = {
  xs: 4,
  xl: 42,
  '2xl': 58,
} as const;

export type SpaceProps = Omit<SpacePropsAnt, 'size'> & {
  size: AntSize | keyof typeof ADDITINAL_GAP;
};

const StyledSpace = styled(SpaceAnt)<{ gap?: number | null }>`
  ${({ gap }) => (gap ? `gap: ${gap}px !important;` : '')}
`;

const ANTD_SIZES: AntSize[] = ['small', 'middle', 'large'];

export const SpaceAnza = (props: SpaceProps) => {
  const isUsingAditionalSize = !includes(props.size, ANTD_SIZES);
  let size: AntSize = !isUsingAditionalSize ? (props.size as AntSize) : 'large';
  const selectedGap = props.size.toString() as keyof typeof ADDITINAL_GAP;
  const gap = isUsingAditionalSize ? ADDITINAL_GAP[selectedGap] : null;
  if (!ANTD_SIZES.includes(size)) {
    size = 'large';
  }
  return <StyledSpace gap={gap} {...props} size={size} />;
};

export default SpaceAnza;
