import * as R from 'ramda';

/**
 * For a Form Item name, if prefix name is provided returns a concatenation of the prefix and the name
 * otherwise returns the name only
 * @param name name of the Form Item
 * @param prefixName prefix added
 * @returns Form Item name with prefix name included if it was provided
 */
export const maybeApplyPrefixName = (
  prefixName: string | Array<string | number> | null | undefined,
  name: string | Array<string | number>
) => {
  if (!prefixName) return name;

  const nameArray: Array<string | number> = R.is(String, name) ? [name] : name;
  const prefixNameArray: Array<string | number> = R.is(String, prefixName)
    ? [prefixName]
    : prefixName;

  return [...prefixNameArray, ...nameArray];
};
