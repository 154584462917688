import React from 'react';

import IntroWrapper from '~/components/IntroWrapper';
import { Switch } from '~/UI';

import { introSolarProposal } from '~/constants/intro';
import { GTM_SOURCE_IDS, GTM_SOURCE_TYPES } from '~/utils/gtm';
import { SolarProjectTransformedResultType } from '~/store/project';
import { LoadingCardState } from '~/components/LoadingCard';

import { AnalysisSwitchBox, AnalysisViewSwitchText } from './styles';

type AdvancedViewSwitchboxProps = Pick<
  SolarProjectTransformedResultType,
  'engine'
> & {
  loadingCardState: LoadingCardState;
  toggle: () => void;
  isAnalysisViewOpen: boolean;
};

export const AdvancedViewSwitchbox = ({
  engine,
  loadingCardState,
  isAnalysisViewOpen,
  toggle,
}: AdvancedViewSwitchboxProps) => {
  const isLoading =
    engine === 'active' || !engine || loadingCardState === 'loading';

  return (
    <IntroWrapper {...introSolarProposal.advancedView}>
      <AnalysisSwitchBox>
        <AnalysisViewSwitchText>Advanced View</AnalysisViewSwitchText>
        <Switch
          disabled={isLoading}
          checked={isAnalysisViewOpen}
          onChange={toggle}
          data-gtm-type={GTM_SOURCE_TYPES.SWITCH}
          data-gtm-id={GTM_SOURCE_IDS.SOLAR_PROJECT.ANALYSIS_VIEW_SWITCH}
          includeGtmProperties
        />
      </AnalysisSwitchBox>
    </IntroWrapper>
  );
};
