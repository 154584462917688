import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import { Tabs } from 'antd';
import { AxiosResponse } from 'axios';
import Modal, { ModalProps } from '~/UI/ModalAnt';
import { useGetInputsByProjectIdQuery } from '~/store/api/admin/anzaEngine';
import { ButtonAnt as Button, Form, Spinner } from '~/UI';
import { IconPlay } from '~/UI/Icons';
import { api } from '~/api/axios';
import { catchError } from '~/utils';
import { blockEnterKeyEvents } from '~/utils/events';
import { EngineGetInputsResponse } from '~/types/engine';

const StyledModal = styled(Modal)``;
const StyledEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding-top: 15px;

  .editor-container {
    flex: 1;
    overflow: auto;

    > div {
      height: 100%;
    }
  }
`;

export interface AddDetailsModalProps extends ModalProps {
  projectId: string;
  onCancel: () => void;
}

const EngineInputsModal: React.FC<AddDetailsModalProps> = ({
  projectId,
  onCancel,
}) => {
  const [inputsJson, setInputsJson] = useState<EngineGetInputsResponse>();
  const [requestId, setRequestId] = useState(null);
  const [engineStatus, setEngineStatus] = useState<any>(null);
  const [requestedCount, setRequestedCount] = React.useState<number>(0);

  const [form] = Form.useForm();
  const {
    data: engineInputsData,
    isFetching: engineInputsDataIsFetching,
    isLoading: engineInputsDataIsLoading,
    fulfilledTimeStamp: engineInputsTimeStamp,
  } = useGetInputsByProjectIdQuery({
    projectId,
  });

  useEffect(() => {
    setInputsJson(engineInputsData);
  }, [engineInputsTimeStamp]);

  const handleJsonChanged = (json: EngineGetInputsResponse) => {
    setInputsJson(json);
  };

  const runEngine = async (json: any): Promise<AxiosResponse> => {
    try {
      const engineRunResponse = await api.post(
        `/anza/engine/run/${projectId}`,
        { inputData: { ...json } }
      );
      setRequestId(engineRunResponse.data.data.requestId);
      return engineRunResponse;
    } catch (error) {
      catchError({ error });
      throw error;
    }
  };

  const onFinish = async () => {
    await runEngine(inputsJson);
  };

  const getEngineStatus = async (engineRequestId: string) => {
    const engineRunResponse = await api.get(
      `/anza/engine/request/${engineRequestId}`
    );
    setEngineStatus(engineRunResponse.data.data.engineRequestStatus);
    setRequestedCount(requestedCount + 1);
  };

  const renderEngineResponse = (engineRequestId: string | null) => {
    if (!engineRequestId) {
      return <div>Run Anza Engine first, then come back here.</div>;
    }

    const parseJsonData = (node: any) => {
      const { inputs, outputs } = node;
      return {
        ...node,
        inputs,
        outputs,
      };
    };

    const getParsedEngineStatusJson = (engineStatusJson: any) => {
      const { inputs, steps, ...restOfEngineStatus } = engineStatusJson;
      const { cost, finance, iteration, performance } = steps;

      return {
        inputs,
        steps: {
          iteration: parseJsonData(iteration),
          performance: parseJsonData(performance),
          cost: parseJsonData(cost),
          finance: parseJsonData(finance),
        },
        ...restOfEngineStatus,
      };
    };

    return (
      <div key={requestedCount}>
        <div style={{ marginBottom: 25 }}>
          <Button
            onClick={async () => {
              await getEngineStatus(engineRequestId);
            }}
          >
            <IconPlay style={{ marginRight: '0.25rem' }} /> Get Engine Status
          </Button>
        </div>
        <div>
          {engineStatus ? (
            <Editor value={getParsedEngineStatusJson(engineStatus)} />
          ) : (
            <div>Click &quot;Get Engine Status&quot;</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <StyledModal
      title="Anza Engine JSON"
      maskClosable
      open
      width="80%"
      footer={null}
      onCancel={onCancel}
    >
      {engineInputsDataIsFetching || engineInputsDataIsLoading ? (
        <Spinner />
      ) : (
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Run Engine" key="1">
            <StyledEditorContainer>
              <Form
                form={form}
                onFinish={onFinish}
                style={{ display: 'flex', marginBottom: 25 }}
                onKeyDown={blockEnterKeyEvents}
              >
                <Button type="primary" htmlType="submit" disabled>
                  <IconPlay style={{ marginRight: '0.25rem' }} /> Run Anza
                  Engine
                </Button>
              </Form>
              <div className="editor-container">
                <Editor value={engineInputsData} onChange={handleJsonChanged} />
              </div>
            </StyledEditorContainer>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Engine Output" key="2">
            <StyledEditorContainer>
              <div className="editor-container">
                <div>{renderEngineResponse(requestId)}</div>
              </div>
            </StyledEditorContainer>
          </Tabs.TabPane>
        </Tabs>
      )}
    </StyledModal>
  );
};

export default EngineInputsModal;
