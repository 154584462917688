import React, { memo, useEffect, useState } from 'react';

import type { ColumnsType } from 'antd/lib/table';
import { Table, Space, ButtonAnt as Button, FormInstance, Form } from '~/UI';
import { IconEdit, IconTrash } from '~/UI/Icons';
import { theme } from '~/utils';
import { maybeApplyPrefixName } from '~/utils/form';
import { DCBlockQuoteConstraint } from '~/store/project';

import ConstraintFormModal, {
  ConstraintFormModalProps,
} from './ConstraintFormModal';

const applyTierToConstraints = (constraints: DCBlockQuoteConstraint[]) =>
  constraints.map((c, index) => ({
    ...c,
    tier: index + 1,
  }));

interface ModulePricingFormGroupProps {
  form: FormInstance;
  constraints: DCBlockQuoteConstraint[];
  /** Prefix key name to add in every form item */
  formItemsPrefixName: string;
}

const CLOSED_CONSTRAINT_FORM_MODAL: ConstraintFormModalProps = {
  open: false,
  type: 'create',
};

const TierFormItems: React.FC<ModulePricingFormGroupProps> = ({
  form,
  constraints,
  formItemsPrefixName,
}) => {
  const [currentConstraints, setCurrentConstraints] = useState<
    DCBlockQuoteConstraint[]
  >([]);
  const [constraintFormModal, setConstraintFormModal] =
    useState<ConstraintFormModalProps>(CLOSED_CONSTRAINT_FORM_MODAL);

  useEffect(() => {
    if (currentConstraints) setCurrentConstraints(constraints);
  }, []);

  /**
   * Prepares a hidden FormItem to send the value corresponding to the selected key when submitting the form.
   * @param index index of current constraint item in dc_block_quote_constraints array (form)
   * @param key key of the value we want to send to the form on submit
   * @returns
   */
  const renderFormItemCell = (index: number, key: string) => {
    return (
      <Form.Item
        name={maybeApplyPrefixName(formItemsPrefixName, [index, key])}
        hidden
      />
    );
  };

  /**
   * Prepares a non editable FormItem to send the value corresponding to the selected key when submitting the form.
   * @param index index of current constraint item in dc_block_quote_constraints array (form)
   * @param key key of the value we want to send to the form on submit
   * @returns
   */
  const renderCell = (value: any, index: number, key: string) => {
    return (
      <>
        {value}
        {renderFormItemCell(index, key)}
      </>
    );
  };

  const columns: ColumnsType<DCBlockQuoteConstraint> = [
    {
      title: 'Tier',
      dataIndex: 'tier',
      render: (value, __, index) => {
        return (
          <>
            {value}
            {/* Adding hidden Form Items to send data on submit */}
            {renderFormItemCell(index, 'uuid')}
            {renderFormItemCell(index, 'type')}
          </>
        );
      },
    },
    {
      title: 'Min (MWh DC)',
      dataIndex: 'numeric_value',
      render: (value, __, index) => renderCell(value, index, 'numeric_value'),
    },
    {
      title: 'Price FOB ($/kWh DC)',
      dataIndex: 'fob_cost_USD_per_kWh',
      render: (value, __, index) =>
        renderCell(value, index, 'fob_cost_USD_per_kWh'),
    },
    {
      title: 'Battery Unit Commissioning ($/kWh DC)',
      dataIndex: 'commissioning_cost_USD_per_kWh',
      render: (value, __, index) =>
        renderCell(value, index, 'commissioning_cost_USD_per_kWh'),
    },
    {
      key: 'buttons',
      render: (_, record) => {
        const handleDelete = () => {};

        const handleEdit = () => {
          setConstraintFormModal({
            open: true,
            type: 'edit',
            constraint: record,
          });
        };

        return (
          <Space size="small" direction="horizontal">
            <Button type="ghost" title="Edit" onClick={handleEdit}>
              <IconEdit color={theme.colors.primary} size="24px" />
            </Button>
            <Button type="ghost" title="Delete" onClick={handleDelete}>
              <IconTrash color={theme.colors.alert} size="24px" />
            </Button>
          </Space>
        );
      },
    },
  ];

  const closeConstraintsFormModal = () =>
    setConstraintFormModal((prev) => ({
      ...prev,
      open: false,
    }));

  return (
    <>
      <Table
        columns={columns}
        dataSource={applyTierToConstraints(currentConstraints)}
        pagination={false}
      />
      <ConstraintFormModal
        {...constraintFormModal}
        onFinish={(value) => {
          if (!value.uuid) {
            // Creating a new constraint
            setCurrentConstraints((prev) => {
              const newConstraints = [...prev, value];
              form.setFieldValue(formItemsPrefixName, newConstraints);
              return newConstraints;
            });
          } else {
            // Editing a constraint
            setCurrentConstraints((prev) => {
              const newConstraints = prev.map((c) => {
                if (c.uuid === value.uuid) return value;
                return c;
              });
              form.setFieldValue(formItemsPrefixName, newConstraints);
              return newConstraints;
            });
          }

          return closeConstraintsFormModal();
        }}
        onCancel={closeConstraintsFormModal}
      />
    </>
  );
};

export default memo(TierFormItems);
