import React, { memo } from 'react';
import LookerEmbed from '~/components/LookerEmbed';

const PRICING_HISTORY = 'buyer_pricing_history';

const PricingHistory = () => {
  return <LookerEmbed id={PRICING_HISTORY} />;
};

export default memo(PricingHistory);
