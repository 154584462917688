import React, { useState } from 'react';
import styled from '@emotion/styled';

import { ChangeMetaDescriptor } from '~/utils/changeMetaDescriptor';
import { Layout, RestoreScrollOnMount } from '~/UI';
import useAnzaRedirectHandler from '~/hooks/useAnzaRedirectHandler';
import { theme } from '~/utils';
import Padder from '~/UI/Padder';
import HeaderContainer from '~/components/HeaderContainer';
import BackHandler, { BackButtonProps } from '~/components/BackHandler';
import { HEADER_MARGIN_BOTTOM } from '~/UI/Header/styles';

const Container = styled(Padder)<{ minWidth?: string }>`
  overflow: visible;
  background-color: ${theme.colors.greyPageBackground};
  min-width: ${({ minWidth }) => minWidth || `${theme.dimensions.width.min}px`};
  max-width: ${theme.dimensions.width.max}px;
  padding: 0 40px;
  margin: 0 auto;
  min-height: calc(100vh - 290px);
`;

export type AnzaLayoutProps = {
  navbarBackHandlerData: BackButtonProps;
  contentBackHandlerData: BackButtonProps;
  hideNavBarMainItems?: boolean;
  hideNavBarSecondaryItems?: boolean;
  hideNavBarRightMenuItems?: boolean;
  className?: string;
  minWidth?: string;
  gtmId?: string;
};

const Anza: React.FC<AnzaLayoutProps> = ({
  children,
  contentBackHandlerData,
  navbarBackHandlerData,
  hideNavBarMainItems,
  hideNavBarSecondaryItems,
  hideNavBarRightMenuItems,
  className,
  minWidth,
  gtmId,
}) => {
  useAnzaRedirectHandler();
  ChangeMetaDescriptor('Anza');
  const [banner, setBanner] = useState<React.ReactNode>();

  let Children = children;

  if (
    React.isValidElement<{
      setBanner: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    }>(Children)
  ) {
    Children = React.cloneElement(Children, {
      setBanner,
    });
  }

  const hideMarginBotton = !!banner;

  return (
    <Layout
      className={className}
      header={
        <HeaderContainer
          style={
            hideMarginBotton
              ? {
                  margin: 0,
                }
              : {}
          }
          backHandlerData={navbarBackHandlerData}
          hideMainItems={hideNavBarMainItems}
          hideSecondaryItems={hideNavBarSecondaryItems}
          hideRightMenuItems={hideNavBarRightMenuItems}
        />
      }
      gtmId={gtmId}
    >
      <RestoreScrollOnMount />
      {banner ? (
        <div
          style={{
            width: '100%',
            marginBottom: HEADER_MARGIN_BOTTOM,
          }}
        >
          {banner}
        </div>
      ) : null}
      <Container minWidth={minWidth}>
        <BackHandler {...contentBackHandlerData} />
        {Children}
      </Container>
    </Layout>
  );
};

export default Anza;
