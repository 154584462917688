import styled from '@emotion/styled';
import { theme } from '../utils';
import { colors } from '../utils/theme';

export const FormGroupHeading = styled.h1`
  color: ${theme.fonts.colors.primary};
  font-size: ${theme.fonts.sizes.sidetitle};
  font-weight: 400;
`;

export const Label = styled.span`
  font-size: ${theme.fonts.sizes.label};
  color: ${theme.fonts.colors.charcoal};
  font-weight: 600;
  text-transform: capitalize;
  padding: 0.6rem 1rem 0.6rem 0;
`;

export const ModalText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.fonts.colors.graphite};
`;

export const InputHelperText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray800};
  margin-left: 8px;
`;

export const BoxTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
`;

export const BoxH3 = styled.h3`
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

export const Subtext = styled.span`
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.03em;
`;

export const PopupTitle = styled.span`
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: ${colors.darkText};
  font-weight: bold;
`;

export const AnzaHeaderTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

export const H1 = styled.h1`
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: -4.32px;
`;

export const H2 = styled.h2`
  color: ${colors.charcoal};
  font-size: 36px;
  font-weight: 800;
  line-height: 46px;
  letter-spacing: -0.36px;
`;

export const H3 = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: ${colors.charcoal};
`;

export const H4 = styled.h4`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.charcoal};
  letter-spacing: -0.03em;
`;

export const H4Strong = styled.h4`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.charcoal};
  font-weight: 600;
`;

export const H5 = styled.h5`
  font-size: 14px;
  line-height: 24px;
  color: ${colors.graphite};
`;

export const H6Strong = styled.h6`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.charcoal};
  font-weight: 600;
`;

export const VendorSectionHeader = styled.h2`
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.015em;
  color: ${colors.charcoal};
`;

export const VendorSectionSubtitle = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: ${colors.charcoal};
`;

export const VendorLabel = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: ${colors.charcoal};
`;

export const PageHeader = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: ${colors.charcoal};
  margin-bottom: 8px;
  overflow-wrap: break-word;
`;

export const PageDescription = styled.div`
  font-size: 16px;
  overflow-wrap: break-word;
  margin: 0;
  padding: 0;
`;

export const ContentHeading = styled.h4`
  font-weight: 700;
  font-size: ${theme.fonts.sizes.sidetitle};
  line-height: 30px;
  color: ${colors.gray800};
`;

export const ContentSubheading = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray800};
`;

export const ContentParagraph = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.gray800};
  line-height: 18px;
`;

export const SmallPrimaryTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${theme.colors.primary};
`;

export const GenericText = styled.p<{
  size: string;
  color: string;
  weight: number;
  lineHeight?: string;
}>`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.lineHeight};
`;

export const UnsavedChangesText = styled.h3`
  font-size: 24px;
  font-weight: 600;
`;

export const FormLabel = styled.div`
  font-weight: 600;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

export const SmallLabel = styled.p`
  font-size: 12px;
  line-height: initial;
  font-weight: 500;
  color: ${colors.gray600};
`;
