import React, { type PropsWithChildren } from 'react';
import styled from '@emotion/styled';

const StyledDisplayDiv = styled.div<{ hide?: boolean; invisible?: boolean }>`
  ${({ hide }) =>
    hide
      ? `
      display: none;
      `
      : ''}

  ${({ invisible }) =>
    invisible
      ? `
        opacity: 0;
        visibility: hidden;
        width: 0px;
        height: 0px;
        `
      : ''}
`;

const DisplayWrapper = ({
  children,
  hide,
  invisible,
}: PropsWithChildren<{ hide?: boolean; invisible?: boolean }>) => (
  <StyledDisplayDiv hide={hide} invisible={invisible}>
    {children}
  </StyledDisplayDiv>
);

export default DisplayWrapper;
