import { equals, mapObjIndexed, omit, pick } from 'ramda';
import { SolarAdvancedInputs, StorageAdvancedInputs } from '~/store/project';

type TransformAdvancedInputs<T, V> = Omit<T, keyof V> & {
  advancedInputs: Partial<V> | null;
};

/**
 * Given the values from a form that contains the AdvancedInputFormGroup component, and the default values for the advanced inputs,
 * returns groups all the advanced input values in a property called "advanced_inputs".
 * If at least one advanced input value is different from the default values then "advanced_inputs" is set to null, otherwise
 * returns the advanced input values.
 *
 * This is for the purpose of not sending the advanced inputs to the backend if they are the same as the default values.
 *
 * @param values values from form that contains AdvancedInputFormGroup component
 * @param defaultAdvancedInputs default values for advanced inputs
 * @returns transformed form values
 */
export const transformAdvancedInputs = <
  T extends Record<string, any>,
  V extends SolarAdvancedInputs | StorageAdvancedInputs
>(
  values: T,
  defaultAdvancedInputs: V
): TransformAdvancedInputs<T, V> => {
  const advancedInputKeys = Object.keys(defaultAdvancedInputs);
  const advancedInputValues = pick(advancedInputKeys, values);
  let defaultValuesChanged = false;
  mapObjIndexed((value, key) => {
    if (!equals(value, defaultAdvancedInputs[key as keyof V])) {
      defaultValuesChanged = true;
    }
  }, advancedInputValues);
  return {
    ...omit(advancedInputKeys, values),
    advancedInputs: defaultValuesChanged ? advancedInputValues : null,
  } as unknown as TransformAdvancedInputs<T, V>;
};
