import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { IconClose } from '~/UI/Icons';
import { Badge, ButtonAnt, Typography } from '~/UI';
import ArrowDropdown from '~/UI/Icons/ArrowDropdown';
import { colors, theme } from '~/utils';
import useToggle from '~/hooks/useToggle';
import { FilterValues } from './FilterForm';
import GroupFilterDropdown from './GroupFilterDropdown';
import FilterTag from './FilterTag';
import FilterAnchorWrapper from './FilterAnchorWrapper';
import type { FilterMenuFilterConfig } from './FilterMenu';

const StyledH4 = styled(Typography.H4)`
  font-weight: 600;
  color: ${colors.gray800};
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    box-shadow: none;
    font-weight: 600;
  }
`;

const QuickClearAllButton = styled(ButtonAnt)`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray200};
  }
  transition: ease-out 0.2s;
  display: flex;
  border: none;
`;

export type SubFilterMenuProps = {
  // ref: RefObject<HTMLDivElement>;
  filterConfig: FilterMenuFilterConfig;
  filterValues: FilterValues;
  clearGroupFilter: (filter: string) => void;
  onFilterValuesChange: (
    changedValed: FilterValues,
    values: FilterValues
  ) => void;
  showSingleSelectedFilter?: boolean;
  hideDropdownCount?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  onDropdownButttonClick?: () => void;
  disabled?: boolean;
};

const SubFilterMenu = ({
  filterConfig,
  filterValues,
  clearGroupFilter,
  onFilterValuesChange,
  showSingleSelectedFilter,
  hideDropdownCount,
  onOpenChange,
  open,
  onDropdownButttonClick,
  disabled,
}: SubFilterMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { name } = filterConfig;
  const currentSelectedFilters =
    (filterValues[filterConfig.name] as string[]) || [];
  const count = currentSelectedFilters.length;
  const onlyOneSelectedFilter = count === 1;
  const singleSelectedFilter = onlyOneSelectedFilter
    ? currentSelectedFilters[0]
    : null;
  const singleSelectedFilterTitle =
    filterConfig.options.find((opt) => opt.value === singleSelectedFilter)
      ?.title || '';

  const onlyOneOption = filterConfig.options.length === 1;

  const { isOpen: isIconUp, toggle: toggleIcon } = useToggle();

  const showDisableFilterTag = onlyOneOption && singleSelectedFilterTitle;
  if (showDisableFilterTag) {
    return (
      <FilterTag
        label={singleSelectedFilterTitle}
        icon={<IconClose size={20} />}
        onClick={() => {
          clearGroupFilter(name);
          toggleIcon();
        }}
      />
    );
  }

  return (
    <GroupFilterDropdown
      key={name}
      filters={[filterConfig]}
      anchorRef={ref}
      onClearFilter={clearGroupFilter}
      onClearAllFilters={() => clearGroupFilter(name)}
      onValuesChange={onFilterValuesChange}
      values={filterValues}
      onOpenChange={(open) => {
        toggleIcon();
        onOpenChange?.(open);
      }}
      open={open}
      disableDropdown={disabled}
    >
      {/* @ts-ignore */}
      <FilterAnchorWrapper ref={ref}>
        {singleSelectedFilter && showSingleSelectedFilter ? (
          <FilterTag
            label={singleSelectedFilterTitle}
            icon={<ArrowDropdown rotate={isIconUp} fill={colors.primary} />}
            onClick={onDropdownButttonClick}
          />
        ) : (
          <FilterTag
            color={count ? 'primary' : 'transparent'}
            icon={null}
            label={
              <>
                {!!count && !hideDropdownCount && (
                  <StyledBadge color={theme.colors.primary} count={count} />
                )}
                <StyledH4>{filterConfig.dropdownGroupTitle}</StyledH4>
              </>
            }
            onClick={onDropdownButttonClick}
          >
            {count ? (
              <QuickClearAllButton
                square
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  clearGroupFilter(name);
                }}
              >
                <IconClose size={20} />
              </QuickClearAllButton>
            ) : null}
          </FilterTag>
        )}
      </FilterAnchorWrapper>
    </GroupFilterDropdown>
  );
};
export default SubFilterMenu;
