import React from 'react';

import { rtkService } from '~/types/rtkApi';

import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminPcsForm from '../components/Admin/Pcs/AdminPcsForm';
import AdminPcsList from '../components/Admin/Pcs/AdminPcsList';

const AdminPcs: React.FC = () => {
  const service = {
    get: () => {},
    getAll: () => ({ data: [], refetch: () => [] }),
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="PCS"
      listComponent={AdminPcsList}
      formComponent={AdminPcsForm}
      path={ADMIN_ROUTES.PCS.path}
      service={service}
    />
  );
};

export default AdminPcs;
