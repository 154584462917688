// Use this enum instead of the rackingTypes array below.
export enum RACKING_TYPE {
  SINGLE_AXIS = 'Single Axis Tracker',
  FIXED_TILT = 'Fixed Tilt',
  ROOFTOP_MLPE = 'Rooftop - MLPE',
}

export const rackingTypes = [
  RACKING_TYPE.FIXED_TILT,
  RACKING_TYPE.SINGLE_AXIS,
  RACKING_TYPE.ROOFTOP_MLPE,
];
