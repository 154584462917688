import React from 'react';
import { useSelector } from 'react-redux';

import { SearchProjectState } from '~/store/searchProject';
import { RootState } from '~/store';
import { TEST_DATA_COMPONENTS, TestIdProps } from '~/utils/dataTestProps';

import FormModal from './ProjectModal';

const testIdData: TestIdProps = {
  component: TEST_DATA_COMPONENTS.NEW_PROJECT_FORM,
};

const CreateProjectModal: React.FC<{
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  actions?: React.ReactNode[];
  onOk?: () => void;
  onClose?: () => void;
}> = ({ children, actions, title, description, onOk, onClose }) => {
  const searchProjectState = useSelector<RootState, SearchProjectState>(
    (state) => state.searchProject
  );

  return (
    <FormModal
      title={title}
      description={description}
      onOk={onOk}
      onClose={onClose}
      visible={!!searchProjectState.isModalVisible}
      actions={actions}
      testIdData={testIdData}
    >
      {children}
    </FormModal>
  );
};

export default CreateProjectModal;
