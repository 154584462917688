import React from 'react';
import { capitalCase } from 'change-case';
import Modal from '~/UI/ModalAnt';
import ConstraintForm, { ConstraintFormProps } from './ConstraintForm';

export type ConstraintFormModalProps = ConstraintFormProps & {
  open: boolean;
};

const ConstraintFormModal = ({
  open,
  ...constraintFormProps
}: ConstraintFormModalProps) => {
  const { type, onCancel } = constraintFormProps;
  return (
    <Modal
      open={open}
      title={`${capitalCase(type)} DC Block Quote Constraint`}
      footer={null}
      forceRender
      onCancel={() => onCancel && onCancel()}
    >
      <ConstraintForm {...constraintFormProps} />
    </Modal>
  );
};

export default ConstraintFormModal;
