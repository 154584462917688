import { SolarPermissions, StoragePermissions } from '~/store/project';

const useBaseProjectPermissions = (
  permissions: SolarPermissions | StoragePermissions
) => {
  return {
    enableAdvanceInputs: permissions.advancedInputs.write,
  };
};

export default useBaseProjectPermissions;
