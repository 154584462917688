import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import styled from '@emotion/styled';
import { getConfiguration } from '~/utils/configurations';

import { Table, ButtonAnt as Button } from '~/UI';
import DisplayWrapper from '~/components/DisplayWrapper';
import { UseTableColumnsReturnType } from '~/hooks/useTableColumns';
import {
  SolarProjectTransformedResultType,
  SolarRankingData,
} from '~/store/project';
import { GTM_SOURCE_TYPES } from '~/utils/gtm';
import StateCard from '~/components/StateCard';
import { useFlags } from '~/utils/launchdarkly';
import { theme } from '~/utils';

type Props = Pick<
  SolarProjectTransformedResultType,
  'available_analytics_data'
> & {
  dataGTMid: string;
  showP360Content: boolean;
  showAnalyticsView: boolean;
  resetFilters: () => void;
  tableProps: UseTableColumnsReturnType<SolarRankingData>['tableProps'];
  rankingData: SolarRankingData[];
};

const StyledTable = styled(Table)`
  .hiddenModule {
    background-color: ${theme.colors.gray300};
  }
`;

const SolarAdvancedView = ({
  dataGTMid,
  showP360Content,
  showAnalyticsView,
  resetFilters,
  tableProps,
  rankingData,
}: Props) => {
  const flags = useFlags();
  const [isAnalyticsTableEmpty, setIsAnalyticsTableEmpty] =
    useState<boolean>(false);

  useEffect(() => {
    setIsAnalyticsTableEmpty(isEmpty(rankingData));
  }, [rankingData]);

  const showEngagingCard = showAnalyticsView && !showP360Content;

  return (
    <DisplayWrapper
      invisible={!showAnalyticsView}
      data-gmt-type={GTM_SOURCE_TYPES.MODE}
      data-gtm-id={dataGTMid}
    >
      {/* @ts-ignore */}
      <StyledTable
        showEmptyStateIfNoRecords={false}
        dataSource={showP360Content ? rankingData : []}
        pagination={{
          pageSize: rankingData.length,
          disabled: true,
          hideOnSinglePage: true,
        }}
        {...tableProps}
        rowClassName={(record) =>
          (record as SolarRankingData).isModuleHidden ? 'hiddenModule' : ''
        }
      />
      {showP360Content && isAnalyticsTableEmpty && (
        <StateCard
          title="There are no modules available that match your search."
          type="empty"
        >
          <Button onClick={resetFilters}>
            {flags.persistentFilters ? 'Reset All Filters' : 'Reset Filters'}
          </Button>
        </StateCard>
      )}
      {showEngagingCard && (
        <StateCard
          title="Engaged customers have full access to our data."
          type="empty"
        >
          <a
            href={getConfiguration('REACT_APP_CALENDLY_SOLAR_URL')}
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary">Learn more</Button>
          </a>
        </StateCard>
      )}
    </DisplayWrapper>
  );
};

export default React.memo(SolarAdvancedView);
