import React from 'react';
import styled from '@emotion/styled';
import {
  Dropdown as DropdownAnt,
  DropdownProps as DropdownPropsAnt,
} from 'antd';
import ButtonWithAnalytics, {
  ButtonWithAnalyticsProps,
} from './ButtonWithAnalytics';

type AntDropdownPlacement = DropdownPropsAnt['placement'];
const EXTENDED_PLACEMENTS = ['left', 'right'] as const;
type ExtendedDropdownPlacement = (typeof EXTENDED_PLACEMENTS)[number];
type DropdownPlacement = AntDropdownPlacement | ExtendedDropdownPlacement;

export type DropdownProps = Omit<DropdownPropsAnt, 'placement'> & {
  placement?: DropdownPlacement;
};

const StyledDropdown = styled(DropdownAnt)<DropdownPropsAnt>`
  display: flex;
`;

const MENU_ITEMS_HEIGHT = 16;
const DEFAULT_EXTENDED_OFFSET = 16;

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { placement, getPopupContainer, ...rest } = props;
  const isUsingExtendedPlacement =
    placement === 'left' || placement === 'right';

  const itemsAmount = props.menu?.items?.length;
  const yOffset = itemsAmount
    ? itemsAmount * MENU_ITEMS_HEIGHT * -1 - DEFAULT_EXTENDED_OFFSET
    : -60;

  if (!isUsingExtendedPlacement) {
    return (
      <StyledDropdown
        placement={placement}
        getPopupContainer={getPopupContainer}
        {...rest}
      />
    );
  }

  // This code is only important is you are using "left" or "right" placement
  let placementPassedToAntComponent: AntDropdownPlacement;
  let offset: [number, number] = [0, 0];

  if (placement === 'left') {
    placementPassedToAntComponent = 'bottomRight';
    offset = [-30, yOffset];
  }
  if (placement === 'right') {
    placementPassedToAntComponent = 'bottomLeft';
    offset = [30, yOffset];
  }

  return (
    <StyledDropdown
      {...rest}
      align={{
        offset,
      }}
      placement={placementPassedToAntComponent}
    />
  );
};

export default Dropdown;

export const DropdownButtonMenuItem = (props: ButtonWithAnalyticsProps) => {
  return <ButtonWithAnalytics type="text" size="small" {...props} />;
};
