import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../utils';
import { Label } from './Typography';
import { IconWarningCircle } from './Icons';

const getBackgroundColor = ({ error, disabled }: Partial<InputProps>) => {
  if (!error) {
    return theme.colors.lightError;
  }
  if (disabled) {
    return theme.colors.sterling;
  }
  return 'transparent';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;

const InputElement = styled.input<Partial<InputProps>>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'normal')};
  outline: none;
  border-radius: 0.375rem;
  height: 48px;
  width: 100%;
  background: ${(props) => getBackgroundColor(props)};
  font-size: ${theme.fonts.sizes.placeholder};
  border: 2px solid
    ${(props) => (props.error ? 'transparent' : theme.colors.sterling)};
  padding: 0 1rem;

  :focus {
    border: 2px solid
      ${(props) => (props.error ? 'transparent' : theme.colors.stone)};
  }

  ::placeholder {
    color: ${theme.fonts.colors.graphite};
  }

  // Removing arrows if input is number
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const HintElement = styled.span`
  position: absolute;
  right: 16px;
  bottom: 28px;
  font-size: 10px;
  color: ${theme.fonts.colors.stone};
`;

const MessagesContainer = styled.div<{ error?: string }>`
  display: flex;
  padding: 0.6rem 1rem;
  padding-bottom: 0;
  font-size: ${theme.fonts.sizes.hint};
  width: 100%;
  justify-content: flex-start;
`;

const ErrorMessage = styled.p`
  color: ${theme.fonts.colors.error};
  margin-top: 0;
  margin-bottom: 0;
`;

const NormalMessage = styled.p`
  color: ${theme.fonts.colors.graphite};
  margin-top: 0;
  margin-bottom: 0;
`;

const IconElement = styled.span`
  margin-right: 0.5rem;

  svg {
    font-size: 16px;
    vertical-align: bottom;
  }
`;

export interface InputProps {
  label?: string;
  placeholder?: string;
  onChange?: (...event: any[]) => void;
  type?: 'text' | 'number';
  style?: React.CSSProperties;
  value?: string;
  multiplier?: number;
  min?: number;
  max?: number;
  name?: string;
  disabled?: boolean;
  hint?: string;
  error?: string;
  message?: string;
}

const Input: React.FC<InputProps> = ({
  onChange,
  label,
  placeholder,
  style,
  name,
  hint,
  min,
  max,
  error,
  message,
  value = '',
  multiplier = 1,
  type = 'text',
  disabled = false,
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>('');

  React.useEffect(() => {
    if (type === 'number') {
      if (typeof value !== undefined) {
        const numberValue = parseFloat(value!);
        if (Number.isNaN(numberValue)) {
          setCurrentValue('');
        } else {
          setCurrentValue(
            `${Math.round(numberValue * multiplier * 10000) / 10000}`
          );
        }
      }
    } else if (type === 'text' && typeof value !== undefined) {
      if (typeof value !== undefined) {
        setCurrentValue(value);
      }
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      setCurrentValue(event.target.value);
      if (type === 'number' && event.target.value !== '') {
        onChange!(parseFloat(event.target.value) / multiplier);
      } else if (type === 'text') {
        onChange!(event.target.value);
      }
    }
  };

  return (
    <Container style={style}>
      <Label>{label}</Label>
      <InputElement
        error={error}
        disabled={disabled}
        type={type}
        min={min}
        max={max}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
        placeholder={placeholder}
        value={currentValue}
        name={name}
      />
      <MessagesContainer error={error}>
        {error && (
          <ErrorMessage>
            <IconElement>
              <IconWarningCircle />{' '}
            </IconElement>
            {error}
          </ErrorMessage>
        )}
        {message && !error && <NormalMessage>{message}</NormalMessage>}
      </MessagesContainer>
      {hint && <HintElement>{hint || 'Read-only value'}</HintElement>}
    </Container>
  );
};

export default Input;
