import React from 'react';
import { PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { PropertyAccessor } from '@nivo/core';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { theme, decimalFormatterTens } from '~/utils';
import { Typography } from '~/UI';
import NoDataPieChart from '~/UI/Metrics/NoDataPieChart';
import { ChartDataStructureFromAPIModel } from '~/services/api/anza';
import { TwoColumnTooltip } from './Tooltips';

const defaultStyles: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const LegendRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
`;

const LegendItem = styled.div`
  flex-grow: 1;
  padding-bottom: 10px;
  display: flex;
`;

const LegendRight = styled.div`
  text-align: right;
  flex-grow: 1;
  padding-bottom: 10px;
`;

export interface ResponsivePieChartProps {
  style: React.CSSProperties;
  enableArcLabels?: boolean;
  enableArcLinkLabels?: boolean;
  title?: string;
  colors?: string[];
  emptyDataSet: ChartDataStructureFromAPIModel[];
  valueFormat?: (value: number) => string;
  tooltip?: string;
  data: {
    id: string;
  }[];
  tooltipConfig: {
    leftValuePath: string[];
    rightValuePath: string[];
    leftLabel: string;
    rightLabel: string;
  };
  value?: {
    id: string;
  };
  legendConfig: {
    leftValuePath: string[];
    rightValuePath: string[];
    leftLabel: string;
    rightLabel: string;
  };
}

const valueFormatter = (value: number) => decimalFormatterTens(value);

const getDatumColorByIndex = (colors: string[] | undefined, index: number) => {
  if (R.isNil(colors) || R.isEmpty(colors)) {
    return undefined;
  }

  const indexToGrab = index < colors.length ? index : index % colors.length;

  return colors[indexToGrab];
};

const ResponsivePieChart = (props: ResponsivePieChartProps) => {
  const stylesToUse = {
    ...defaultStyles,
    ...props.style,
  };

  const valueFormatterToUse = props.valueFormat || valueFormatter;
  const Tooltip = ({ datum }: { datum: { data: string } }) => {
    // We use a standardized data structure for our bar charts in anza and are currently only displaying the count on
    // the left and the value on the right but we have the option of overriding these if needed.
    const leftValuePath = props.tooltipConfig?.leftValuePath || ['count'];
    const rightValuePath = props.tooltipConfig?.rightValuePath || ['value'];

    return (
      <TwoColumnTooltip
        leftValue={R.pathOr('', leftValuePath, datum.data)}
        leftLabel={props.tooltipConfig?.leftLabel}
        rightValue={R.pathOr('', rightValuePath, datum.data)}
        rightLabel={props.tooltipConfig?.rightLabel}
        valueFormatterToUse={valueFormatterToUse as (value: number) => string}
      />
    );
  };

  return (
    <>
      {R.isNil(props.data) || R.isEmpty(props.data) ? (
        <NoDataPieChart
          title={props.title}
          style={props.style}
          data={props.emptyDataSet}
          dataLabel="Rank"
          metricLabel="Count"
          value="count"
          colors={props.colors || ['blue_green']}
        />
      ) : (
        <div style={stylesToUse}>
          {props.title && (
            <Typography.VendorLabel
              style={{
                marginBottom: '15px',
              }}
            >
              {props.title}
            </Typography.VendorLabel>
          )}
          <div style={{ display: 'flex', height: '90%' }}>
            <div style={{ width: '50%' }}>
              <ResponsivePie
                data={props.data}
                value={
                  props.value as unknown as PropertyAccessor<
                    { id: string },
                    number
                  >
                }
                valueFormat={valueFormatterToUse as (value: number) => string}
                colors={props.colors || { scheme: 'blue_green' }}
                borderColor={{
                  from: 'color',
                  modifiers: [
                    ['darker', 0.6],
                    ['opacity', 0.5],
                  ],
                }}
                margin={{
                  top: 0,
                  right: 10,
                  bottom: 5,
                  left: 10,
                }}
                innerRadius={0.675}
                padAngle={1.25}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                enableArcLabels={props.enableArcLabels || false}
                enableArcLinkLabels={props.enableArcLinkLabels || false}
                arcLabelsSkipAngle={10}
                theme={{
                  labels: { text: { fontSize: '0.6em', fontWeight: 'bold' } },
                }}
                tooltip={
                  (props.tooltip || Tooltip) as unknown as React.FC<
                    PieTooltipProps<{ id: string }>
                  >
                }
              />
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 30px 0 50px',
              }}
            >
              <LegendRow
                style={{
                  borderBottom: theme.borders.lightGray,
                  marginBottom: '20px',
                }}
              >
                <LegendItem>{props.legendConfig?.leftLabel}</LegendItem>
                <LegendRight>{props.legendConfig?.rightLabel}</LegendRight>
              </LegendRow>
              {props.data.map((datum: { id: string }, index: number) => (
                <LegendRow key={datum.id}>
                  <LegendItem>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          height: '15px',
                          width: '15px',
                          borderRadius: '50%',
                          marginRight: '10px',
                          background: getDatumColorByIndex(props.colors, index),
                        }}
                      />
                    </div>
                    <div>
                      {R.pathOr(
                        '',
                        [
                          props.legendConfig
                            ?.leftValuePath as unknown as string,
                        ],
                        datum
                      )}
                    </div>
                  </LegendItem>
                  <LegendRight style={{ fontWeight: 700 }}>
                    {R.pathOr(
                      '',
                      [props.legendConfig?.rightValuePath as unknown as string],
                      datum
                    )}
                  </LegendRight>
                </LegendRow>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResponsivePieChart;
