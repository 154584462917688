import React, { useCallback } from 'react';
import { ButtonAnt as Button } from '~/UI';
import {
  GTM_EVENTS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';
import { ButtonProps } from './ButtonAnt';

export type ButtonWithAnalyticsProps = ButtonProps & {
  gtmId: string;
};

const ButtonWithAnalytics = ({
  gtmId,
  onClick,
  ...props
}: ButtonWithAnalyticsProps) => {
  const onClickWithAnalytics: React.MouseEventHandler<HTMLElement> =
    useCallback(
      (event) => {
        maybeSendPlatformEventToGTM(GTM_EVENTS.CLICK, gtmId);
        onClick?.(event);
      },
      [gtmId, onClick]
    );
  return (
    <div data-gtm-id={gtmId} data-gtm-type={GTM_SOURCE_TYPES.BUTTON}>
      <Button {...props} onClick={onClickWithAnalytics} />
    </div>
  );
};

export default ButtonWithAnalytics;
