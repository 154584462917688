import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ButtonAnt as Button, Tag } from '~/UI';
import { IconPlay, IconFileText } from '~/UI/Icons';
import { theme } from '~/utils';
import EngineInputsModal from '~/components/Admin/Projects/AdminProjectsForm/EngineInputsModal';
import {
  useLazyGetInputsByProjectIdQuery,
  useLazyGetStorageInputsByProjectIdQuery,
} from '~/store/api';
import { ProjectType } from '~/store/project';
import { useFlags } from '~/utils/launchdarkly';

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const AdminProjectsFormHeader: React.FC<{
  projectType: ProjectType;
  projectDetailsUUID: string;
  projectUuid: string;
}> = ({ projectType, projectDetailsUUID, projectUuid }) => {
  const flags = useFlags();
  const [isEngineModalOpen, setIsEngineModalOpen] = useState(false);
  const [getProjectInputs] = useLazyGetInputsByProjectIdQuery();
  const [getStorageProjectInputs] = useLazyGetStorageInputsByProjectIdQuery();

  const handleToggleEngineModalVisible = () =>
    setIsEngineModalOpen(!isEngineModalOpen);

  const handleDownloadEnginInputs = () => {
    getProjectInputs({ projectId: projectDetailsUUID })
      .unwrap()
      .then((response) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(response, null, 2)
        )}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = 'engine_inputs.json';
        link.click();
      });
  };

  const handleDownloadStorageEngineInputs = () => {
    getStorageProjectInputs({
      projectId: projectUuid,
      engineVersion: flags.storageEngineVersion,
    })
      .unwrap()
      .then((response) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
          JSON.stringify(response, null, 2)
        )}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = 'engine_inputs.json';
        link.click();
      });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Tag text={projectType} />
        {projectType === 'solar' && (
          <StyledButtonsWrapper>
            <Button
              style={{ marginRight: '0.5rem' }}
              color={theme.colors.primary}
              onClick={handleDownloadEnginInputs}
            >
              <IconFileText style={{ marginRight: '0.25rem' }} />
              Generate Solar Inputs (JSON)
            </Button>
            <Button
              color={theme.colors.primary}
              onClick={handleToggleEngineModalVisible}
            >
              <IconPlay style={{ marginRight: '0.25rem' }} />
              Anza Engine
            </Button>
          </StyledButtonsWrapper>
        )}
        {projectType === 'storage' && (
          <StyledButtonsWrapper>
            <Button
              style={{ marginRight: '0.5rem' }}
              color={theme.colors.primary}
              onClick={handleDownloadStorageEngineInputs}
            >
              <IconFileText style={{ marginRight: '0.25rem' }} />
              Generate Storage Inputs (JSON)
            </Button>
            <Button
              color={theme.colors.primary}
              onClick={handleToggleEngineModalVisible}
            >
              <IconPlay style={{ marginRight: '0.25rem' }} />
              Anza Engine
            </Button>
          </StyledButtonsWrapper>
        )}
      </div>
      {isEngineModalOpen ? (
        <EngineInputsModal
          onCancel={handleToggleEngineModalVisible}
          projectId={projectDetailsUUID}
        />
      ) : null}
    </div>
  );
};

export default AdminProjectsFormHeader;
