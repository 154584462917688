import React from 'react';

export const blockEvent = (
  event: React.MouseEvent<HTMLElement, MouseEvent> | undefined
) => {
  if (!event) return;
  event.stopPropagation();
  event.preventDefault();
};

export const blockEnterKeyEvents = (keyEvent: React.KeyboardEvent) => {
  if (keyEvent.key === 'Enter') {
    keyEvent.preventDefault();
  }
};
