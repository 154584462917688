import React, { useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { Space } from '..';
import FilterForm, { FilterConfig, FilterValues } from './FilterForm';
import FilterLabel from './FilterLabel';
import { IconAdd } from '../Icons';
import { theme } from '~/utils';
import { GenericLabel } from '~/components/Projects/FormGroup';
import DropdownAffix from '../DropdownAffix';
import DropdownContent from './DropdownContent';

const SelectFilterButtonContiner = styled.div<{ disabled: boolean }>`
  border-radius: 8px;
  padding: 4px;
  display: flex;
  min-width: 300px;
  gap: 8px;
  ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}
  ${({ disabled }) => (disabled ? `color: ${theme.colors.gray500};` : '')};
  align-items: center;
  transition: ease-out 0.15s;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'transparent' : theme.colors.gray200};
  }
`;

/** Button to select the filter to show */
const SelectFilterButton = ({
  filter,
  onClick,
  disabled,
}: {
  filter: FilterConfig;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <SelectFilterButtonContiner
      disabled={disabled}
      onClick={() => !disabled && onClick()}
    >
      <IconAdd size={20} />
      <span>{filter.title}</span>
    </SelectFilterButtonContiner>
  );
};

/**
 * Given a filter configuration, and a record of filter values, returns true if the there is any values
 * selected for the filter configuration
 * @param filter filter configuration
 * @param values selected filter values/options
 * @returns
 */
const filterHasAtLeastOneValueSelected = (
  filter: FilterConfig,
  values: FilterValues
) => {
  const filterValues = values[filter.name];
  return (
    !R.isNil(filterValues) &&
    R.is(Array, filterValues) &&
    !R.isEmpty(filterValues)
  );
};

/** List of available filters (includes the group name as title) */
const FilterGroup = ({
  group,
  filters,
  onClickFilter,
  values,
}: {
  group: string;
  filters: FilterConfig[];
  onClickFilter: (filter: FilterConfig) => void;
  values: FilterValues;
}) => {
  return (
    <Space size="middle" direction="vertical">
      <GenericLabel title={group} fontSize="16px" />
      <Space size="small" direction="vertical">
        {filters.map((f) => (
          <SelectFilterButton
            key={f.name}
            disabled={filterHasAtLeastOneValueSelected(f, values)}
            filter={f}
            onClick={() => onClickFilter(f)}
          />
        ))}
      </Space>
    </Space>
  );
};

type SelectFilterDropdownProps = {
  filters: FilterConfig[];
  /** The current values state is handled outside of this component */
  values: FilterValues;
  onApplyClick: (values: FilterValues) => void;
};

const SelectFilterDropdown = ({
  filters,
  values,
  onApplyClick,
}: SelectFilterDropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const groups = R.uniq(filters.map((f) => f.group));
  const filtersByGroup = R.groupBy(R.prop('group'), filters);

  /**
   * In the SelectFilterDropdown component once the user selects a filter to show, the user will see the next step
   * which is the form to select one of the diferent options/values corresponding to the selected filter.
   * When the currentFilterSelected is undefined it means the user still needs to select a filter.
   */
  const [currentFilterSelected, setCurrentFilterSelected] =
    useState<FilterConfig>();

  const closeDropdown = () => {
    setOpen(false);
    /**
     * When the dropdown closes, we return to the initial step, so the user can select a filter again.
     * We need to wait a few miliseconds because the dropdown has a transition on close and it looks odd if we don't.
     */
    setTimeout(() => setCurrentFilterSelected(undefined), 300);
  };

  const onOpenDropdownChange = (open: boolean) => {
    if (!open) closeDropdown();
  };

  const googlesheetInputBooleanTrueStrings = ['yes', 'true', 'Yes'];

  const applyAutomaticFilterValue = (currentFilterSelected: FilterConfig) => {
    const { name } = currentFilterSelected;
    onApplyClick({
      [name]: googlesheetInputBooleanTrueStrings,
    });
    setCurrentFilterSelected(undefined);
    closeDropdown();
  };

  const isFilterDataTypeBoolean = (filter: FilterConfig) => {
    const { datatype } = filter;
    return datatype === 'boolean';
  };

  return (
    <DropdownAffix
      open={open}
      trigger={['click']}
      onOpenChange={onOpenDropdownChange}
      dropdownRender={() => {
        if (
          currentFilterSelected &&
          isFilterDataTypeBoolean(currentFilterSelected)
        ) {
          applyAutomaticFilterValue(currentFilterSelected);
        }
        return (
          <DropdownContent>
            {currentFilterSelected &&
            !isFilterDataTypeBoolean(currentFilterSelected) ? (
              <FilterForm
                showBackButton
                filter={currentFilterSelected}
                onApplyClick={(values) => {
                  onApplyClick(values);
                  closeDropdown();
                }}
                onBackButtonClick={() => setCurrentFilterSelected(undefined)}
                values={values}
              />
            ) : (
              <Space
                style={{
                  padding: 14,
                }}
                size="middle"
                direction="horizontal"
                align="start"
              >
                {groups.map((group) => (
                  <FilterGroup
                    key={group}
                    group={group}
                    filters={filtersByGroup[group]}
                    values={values}
                    onClickFilter={setCurrentFilterSelected}
                  />
                ))}
              </Space>
            )}
          </DropdownContent>
        );
      }}
    >
      <FilterLabel
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        data-testid=""
      >
        Add Filters
        <IconAdd size={20} />
      </FilterLabel>
    </DropdownAffix>
  );
};

export default SelectFilterDropdown;
