import React, { Ref, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { IconClose } from '~/UI/Icons';
import {
  TEST_DATA_COMPONENTS,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';

import { Form, ButtonAnt as Button, DropdownAnt, Card, ModalAnt } from '~/UI';
import { colors, fonts } from '~/utils';
import { GTM_EVENTS, maybeSendPlatformEventToGTM } from '~/utils/gtm';
import type { DropdownProps } from '~/UI/DropdownAnt';
import FilterForm, { FilterConfig, FilterValues } from './FilterForm';
import { groupFilterDropdownPadding } from './constants';
import type { FilterMenuFilterConfig } from './FilterMenu';

export const StyledButton = styled(Button)`
  border-color: ${colors.gray200};
`;

export const StyledDropdown = styled(DropdownAnt)<
  DropdownProps & { horizontallyCenteredDropdown?: boolean }
>`
  .ant-card {
    border-radius: 12px;
  }
`;

export const DropdownContent = styled(Card)<{
  autoMaxWidth?: boolean;
  maxHeight?: number;
}>`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  .ant-card-body {
    padding: 0;
  }
  ${({ maxHeight }) =>
    maxHeight
      ? `
    max-height: ${maxHeight - 50}px;
    overflow-y: auto;
  `
      : ''};
  ${({ autoMaxWidth }) => !autoMaxWidth && 'max-width: 417px'};
`;

export const FiltersContainer = styled.div<{
  topPosition: number;
  type?: DropdownType;
}>`
  padding: ${groupFilterDropdownPadding};
  ${({ type, topPosition }) =>
    type === 'dropdown'
      ? `
    overflow-y: scroll;
    max-height: calc(100vh - ${topPosition}px - 160px)
  `
      : ''}
`;

export const FiltersFooter = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 -1px 11px rgba(159, 159, 159, 0.25);
`;

export const FiltersHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  color: ${colors.gray800};
  svg {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`;

const HeaderContainer = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-wrap;
  font-size: ${fonts.sizes.label};
  color: ${colors.gray600};
  font-weight: 400;
`;

const CounterText = styled.div`
  color: ${colors.primary};
  font-weight: 700;
`;

export type DropdownType = 'dropdown' | 'modal';

export type ActionsLocation = 'header' | 'footer';

export type GroupFilterDropdownProps = {
  filters: (FilterConfig &
    Pick<FilterMenuFilterConfig, 'hasHeaderClearButton'>)[];
  values?: FilterValues;
  onValuesChange?: (changedValues: FilterValues, values: FilterValues) => void;
  anchorRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  onClearAllFilters?: () => void;
  onResetAllFilters?: () => void;
  onClearFilter?: (filter: string) => void;
  dataTestIdConfig?: TestIdProps;
  disableDropdown?: boolean;
  limit?: number;
  showResetButton?: boolean;
  showClearAllButton?: boolean;
  onOpenChange?: (open: boolean) => void;
  gtmId?: string;
  gtmType?: string;
  includeGtmProperties?: boolean;
  dropdownAutoMaxWidth?: boolean;
  dropdownPlacement?: DropdownProps['placement'];
  /** Selects the location of actions button (Reset, Clear All, ...). */
  actionsLocation?: ActionsLocation;
  /** Use this value to handle the state outside the GroupFilterDropdown component */
  open?: boolean;
  type?: DropdownType;
};

const GroupFilterDropdown = ({
  filters,
  onValuesChange,
  values,
  anchorRef,
  children,
  onClearAllFilters,
  onResetAllFilters,
  onClearFilter,
  dataTestIdConfig,
  disableDropdown,
  limit,
  showResetButton = false,
  showClearAllButton = true,
  onOpenChange,
  gtmId,
  gtmType,
  includeGtmProperties = false,
  actionsLocation = 'footer',
  dropdownAutoMaxWidth,
  dropdownPlacement = 'bottom',
  open,
  type = 'dropdown',
}: GroupFilterDropdownProps) => {
  const [form] = Form.useForm();
  const dropdownContentRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (!R.equals(values, form.getFieldsValue())) {
      form.resetFields();
    }
  }, [values]);

  const [dropdownTopPosition, setDropdownTopPosition] = useState(0);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    if (!R.isNil(open) && open !== dropdownVisible) {
      setDropdownVisible(open);
    }
  }, [open, dropdownContentRef]);

  const updateDropdownBottomPositionAndMaxHeight = () => {
    const top = anchorRef?.current?.getBoundingClientRect().top || 0;
    setDropdownTopPosition(top);
    setDropdownMaxHeight(window.innerHeight - top);
  };

  useEffect(() => {
    if (dropdownVisible) {
      updateDropdownBottomPositionAndMaxHeight();
    }
  }, [dropdownVisible, anchorRef]);

  const selectedFilters = Object.values(values ?? {}).reduce<string[]>(
    (acc, item) => {
      // @ts-ignore
      const keys = (item || [])?.map((value) => value);
      acc?.push(...keys);
      return acc;
    },
    []
  );

  const hasReachedLimit = selectedFilters.length === limit;

  const FooterComponent = actionsLocation === 'footer' && (
    <FiltersFooter>
      {showClearAllButton && (
        <StyledButton onClick={onClearAllFilters}>Clear All</StyledButton>
      )}
      {showResetButton && (
        <StyledButton onClick={() => onResetAllFilters && onResetAllFilters()}>
          Reset
        </StyledButton>
      )}
    </FiltersFooter>
  );

  const FiltersComponent = (
    <>
      <FiltersHeader
        data-testid={
          dataTestIdConfig &&
          generateTestId({
            ...dataTestIdConfig,
            identifier: 'filters-title',
          })
        }
      >
        <HeaderContainer>
          {limit && (
            <>
              <CounterText>{selectedFilters.length}</CounterText>
              <>{` of ${limit}`}</>
            </>
          )}
          {showClearAllButton && actionsLocation === 'header' && (
            <StyledButton size="small" type="link" onClick={onClearAllFilters}>
              Clear All
            </StyledButton>
          )}
          {showResetButton && actionsLocation === 'header' && (
            <StyledButton
              size="small"
              type="link"
              onClick={() => onResetAllFilters && onResetAllFilters()}
            >
              Reset
            </StyledButton>
          )}
        </HeaderContainer>
        <IconClose
          data-testid={
            dataTestIdConfig &&
            generateTestId({
              ...dataTestIdConfig,
              component: TEST_DATA_COMPONENTS.BUTTON,
              identifier: 'filters-close-icon',
            })
          }
          fontSize="26px"
          onClick={() => {
            setDropdownVisible(false);
            onOpenChange?.(false);
          }}
        />
      </FiltersHeader>
      <FilterForm
        onClearFilter={(filterName) => {
          if (onClearFilter) onClearFilter(filterName);
        }}
        filters={filters}
        onValuesChange={(
          changedValues: FilterValues,
          allValues: FilterValues
        ) => onValuesChange && onValuesChange(changedValues, allValues)}
        values={values}
        hasReachedLimit={hasReachedLimit}
        selectedFilters={selectedFilters}
        gtmPayloadLabel={gtmId}
        includeGtmProperties={includeGtmProperties}
      />
    </>
  );

  return (
    <div id={gtmId} data-gtm-id={gtmId} data-gtm-type={gtmType}>
      {type === 'modal' ? (
        <>
          {/* eslint-disable-next-line */}
          <div
            onClick={() => {
              onOpenChange?.(true);
              setDropdownVisible(true);
            }}
          >
            {children}
          </div>
          <ModalAnt
            open={dropdownVisible}
            onCancel={() => {
              setDropdownVisible(false);
              onOpenChange?.(false);
            }}
            footer={null}
            closeIcon={React.Fragment} // hiding close icon
            width="fit-content"
          >
            <div>{FiltersComponent}</div>
            {FooterComponent}
          </ModalAnt>
        </>
      ) : (
        <StyledDropdown
          trigger={['click']}
          open={dropdownVisible}
          onOpenChange={(open: boolean) => {
            updateDropdownBottomPositionAndMaxHeight();
            // The Dropdown component from Antd needs the "open" prop to be undefined
            // in order to close the component when clicking outside of it.
            setDropdownVisible(undefined);
            onOpenChange?.(open);

            maybeSendPlatformEventToGTM(
              open ? GTM_EVENTS.POPUP_SHOW : GTM_EVENTS.POPUP_HIDE,
              gtmId ?? null
            );
          }}
          getPopupContainer={() =>
            anchorRef?.current || document.getElementById(gtmId || 'root')!
          }
          disabled={disableDropdown}
          placement={dropdownPlacement}
          dropdownRender={() => {
            return (
              <DropdownContent
                ref={dropdownContentRef as Ref<HTMLDivElement>}
                maxHeight={dropdownMaxHeight}
                autoMaxWidth={dropdownAutoMaxWidth}
              >
                <FiltersContainer topPosition={dropdownTopPosition}>
                  {FiltersComponent}
                </FiltersContainer>
                {FooterComponent}
              </DropdownContent>
            );
          }}
        >
          {children}
        </StyledDropdown>
      )}
    </div>
  );
};

export default GroupFilterDropdown;
