import styled from '@emotion/styled';
import { theme } from '~/utils';

const DropdownContent = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  transition: max-width ease-out 2s;
  width: max-content;
`;

export default DropdownContent;
