import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../utils';

export interface TagProps {
  text: string;
  color?: 'green' | 'yellow' | 'gray' | 'error' | 'primary';
  style?: React.CSSProperties;
}

const Container = styled.span<{ background: string }>`
  width: fit-content;
  height: auto;
  border-radius: 4px;
  padding: 4px 10px;
  background: ${(props) => props.background};
  white-space: nowrap;
  display: inline-block;
  margin: 0.16rem 0.16rem;
`;

const Text = styled.p<{ color: string }>`
  color: ${(props) => props.color};
`;

const defaultStyles: React.CSSProperties = {
  fontSize: '12px',
  textTransform: 'capitalize',
};

const Tag: React.FC<TagProps> = ({ text, color = 'gray', ...rest }) => {
  const [currentBackground, setCurrentBackground] = React.useState(
    theme.tags.gray.background
  );
  const [currentColor, setCurrentColor] = React.useState(theme.tags.gray.color);

  const stylesToUse = { ...defaultStyles, ...rest.style };

  const setTagTheme = (tagTheme: { background: string; color: string }) => {
    setCurrentBackground(tagTheme.background);
    setCurrentColor(tagTheme.color);
  };

  const updateStyle = (c: TagProps['color']) => {
    switch (c) {
      case 'primary':
        setTagTheme(theme.tags.primary);
        break;
      case 'green':
        setTagTheme(theme.tags.green);
        break;
      case 'yellow':
        setTagTheme(theme.tags.yellow);
        break;
      case 'error':
        setTagTheme(theme.tags.error);
        break;
      default:
        setTagTheme(theme.tags.gray);
    }
  };

  React.useEffect(() => {
    return updateStyle(color);
  }, [color]);

  return (
    <Container background={currentBackground} {...rest}>
      <Text color={currentColor} style={stylesToUse}>
        {text}
      </Text>
    </Container>
  );
};

export default Tag;
