import React from 'react';
import styled from '@emotion/styled';
import { Divider } from 'antd';

import { theme } from '../utils';

const CustomHeaderContainer = styled.div`
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
`;

const Description = styled.div`
  width: 80%;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: ${theme.colors.charcoal};
  margin-bottom: 0.25rem;
  padding: 36px 30px 0px 36px;
`;

const Title = styled.h3`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
`;

const CustomDrawerHeader: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <CustomHeaderContainer>
      <Description>
        <Title>{title}</Title>
        {description}
      </Description>
      <Divider style={{ margin: '24px 0 0 0' }} />
    </CustomHeaderContainer>
  );
};

export default CustomDrawerHeader;
