import React from 'react';
import styled from '@emotion/styled';
import { Modal as ModalAnt, ModalProps as ModalAntProps } from 'antd';

import { IconClose } from '~/UI/Icons/Close';
import { theme } from '~/utils';

export type PopconfirmProps = ModalAntProps;

const CloseIcon = styled(IconClose)`
  font-size: 24px;
  color: ${theme.colors.charcoal};
  margin-top: 24px;
  margin-right: 11px;
`;

const Modal: React.FC<PopconfirmProps> = (props) => {
  return <ModalAnt closeIcon={<CloseIcon />} {...props} />;
};

export default Modal;
