import React from 'react';
import dayjs from 'dayjs';

import { Form, InputNumber, Option, FormInstance } from '~/UI';
import { StyledDatePicker } from '~/UI/DatePicker';
import {
  disabledDateFromUntil,
  meteringLocationOptions,
  projectTopologyOptions,
  STORAGE_ROLLING_WINDOW_IN_MONTHS,
  CAPACITY_MAINTENANCE_STRATEGY_OPTIONS,
  requiredRuleNoMessage,
} from '~/utils';
import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import { discontinuousRangeValidator } from '~/utils/numberValidators';
import { SelectFormItem } from '~/UI/Select';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import STORAGE_INPUT_CONFIG from '~/constants/inputs/project/storageInputs';
import FormGroup, { GenericLabel } from './FormGroup';

interface StorageFormGroupProps {
  form: FormInstance;
}

const StorageFormGroup: React.FC<StorageFormGroupProps> = ({ form }) => {
  return (
    <>
      <FormGroup>
        <div style={{ gridColumn: '1/3' }}>
          <Form.Item
            name="storage_arrival_date"
            label={
              <GenericLabel
                title="Delivery Date"
                popoverContent="The date you expect products to arrive onsite."
              />
            }
            rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
            required={false}
          >
            <StyledDatePicker
              {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
                identifier: 'delivery-date',
              })}
              disabledDate={disabledDateFromUntil}
              inputReadOnly
              defaultPickerValue={dayjs().add(
                STORAGE_ROLLING_WINDOW_IN_MONTHS,
                'months'
              )}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="target_ac_power_mw"
            label={<GenericLabel title="Target AC Power" />}
            rules={STORAGE_INPUT_CONFIG.targetAcPowerMw.rules}
            required={false}
          >
            <InputNumber
              {...STORAGE_INPUT_CONFIG.targetAcPowerMw.inputNumberProps}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'target-ac-power',
              })}
            />
          </Form.Item>

          <Form.Item
            name="power_factor"
            label={<GenericLabel title="Power Factor" />}
            rules={[
              requiredRuleNoMessage,
              discontinuousRangeValidator({
                range1: [0.5, 1],
                range2: [-1, -0.5],
                inclusive: true,
              }),
            ]}
            required={false}
          >
            <InputNumber
              {...STORAGE_INPUT_CONFIG.powerFactor.inputNumberProps}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'power-factor',
              })}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="duration_hours"
            label={<GenericLabel title="Duration" />}
            rules={STORAGE_INPUT_CONFIG.durationHours.rules}
            required={false}
          >
            <InputNumber
              {...STORAGE_INPUT_CONFIG.durationHours.inputNumberProps}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'duration',
              })}
            />
          </Form.Item>
          <SelectFormItem
            name="metering_location"
            label={<GenericLabel title="Metering Location" />}
            rules={STORAGE_INPUT_CONFIG.meteringLocation.rules}
            selectProps={{
              ...STORAGE_INPUT_CONFIG.meteringLocation.selectProps,
              'data-testid': generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT}`,
                identifier: 'metering-location',
              }),
            }}
          >
            {meteringLocationOptions.map((el, index) => (
              <Option
                data-testid={generateTestId({
                  component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT_OPTION}`,
                  identifier: 'metering-location',
                  indices: index.toString(),
                })}
                key={el}
                value={el}
              >
                {el}
              </Option>
            ))}
          </SelectFormItem>
        </div>
        <div>
          <SelectFormItem
            name="project_topology"
            label={<GenericLabel title="Project Topology" />}
            rules={STORAGE_INPUT_CONFIG.projectTopology.rules}
            selectProps={{
              ...STORAGE_INPUT_CONFIG.projectTopology.selectProps,
              'data-testid': generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT}`,
                identifier: 'topology',
              }),
            }}
          >
            {projectTopologyOptions.map((el, index) => (
              <Option
                data-testid={generateTestId({
                  component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT_OPTION}`,
                  identifier: 'topology',
                  indices: index.toString(),
                })}
                key={el}
                value={el}
                disabled={el === 'DC-Coupled (coming soon)'}
                style={{
                  color: el === 'DC-Coupled (coming soon)' ? 'gray' : 'initial',
                }}
              >
                {el}
              </Option>
            ))}
          </SelectFormItem>
          <Form.Item
            name="capacity_maintenance_term"
            label={<GenericLabel title="Capacity Maintenance Term" />}
            rules={STORAGE_INPUT_CONFIG.capacityMaintenanceTerm.rules}
            required={false}
          >
            <InputNumber
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'capacity-maintenance-term',
              })}
              {...STORAGE_INPUT_CONFIG.capacityMaintenanceTerm.inputNumberProps}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="annual_cycle_count"
            label={<GenericLabel title="Annual Cycle Count" />}
            rules={STORAGE_INPUT_CONFIG.annualCycleCount.rules}
            required={false}
          >
            <InputNumber
              {...STORAGE_INPUT_CONFIG.annualCycleCount.inputNumberProps}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
                identifier: 'annual-cycle-count',
              })}
            />
          </Form.Item>
          <SelectFormItem
            name="capacity_maintenance_strategy"
            label={
              <GenericLabel
                title="Capacity Maintenance Strategy"
                popoverContent="Optimal allows the engine to seek the best Capacity Maintenance Strategy via augmentation and overbuild to minimize lifetime project costs. Overbuild Only produces architectures where the only capacity maintenance strategy is an initial equipment over sizing and prohibits augmentation."
              />
            }
            required={false}
            rules={STORAGE_INPUT_CONFIG.capacityMaintenanceStrategy.rules}
            selectProps={{
              ...STORAGE_INPUT_CONFIG.capacityMaintenanceStrategy.selectProps,
              'data-testid': generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT}`,
                identifier: 'capacity-maintenance-strategy',
              }),
            }}
          >
            {CAPACITY_MAINTENANCE_STRATEGY_OPTIONS.map((el, index) => (
              <Option
                data-testid={generateTestId({
                  component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.SELECT_OPTION}`,
                  identifier: 'capacity-maintenance-strategy',
                  indices: index.toString(),
                })}
                key={el}
                value={el}
              >
                {el}
              </Option>
            ))}
          </SelectFormItem>
        </div>
      </FormGroup>

      <Form.Item
        name="anticipated_purchase_date"
        label={
          <GenericLabel
            title="Anticipated Purchase Date"
            popoverContent="The date you expect to execute a contract with Anza."
          />
        }
        rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
          () =>
            form.getFieldsValue(['storage_arrival_date']).storage_arrival_date
        )}
        required={false}
      >
        <StyledDatePicker
          {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
            () =>
              form.getFieldsValue(['storage_arrival_date']).storage_arrival_date
          )}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'anticipated-date',
          })}
          inputReadOnly
        />
      </Form.Item>
    </>
  );
};

export default StorageFormGroup;
