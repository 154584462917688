import { useLocation } from 'react-router-dom';
import * as R from 'ramda';
import { strToU8, strFromU8, compressSync, decompressSync } from 'fflate';

type Location = ReturnType<typeof useLocation>;

const cleanRedirect = (q: string) => {
  const [qMarks, values] = R.splitAt(2, q);
  return qMarks === '??' ? `?${values}` : q;
};

export const getURLParams = <T extends unknown>(
  location: Location
): T | null => {
  const search = location.search.substring(1);

  if (search) {
    return JSON.parse(
      `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => {
        return key === '' ? value : decodeURIComponent(value);
      }
    );
  }

  return null;
};

export const getRedirectPathFromUrl = (baseUrl: string) => {
  const initialPath = window.location.pathname;
  const initialQuery = window.location.search;

  if (initialPath.includes('/auth') || initialPath === '/') {
    return baseUrl;
  }

  const redirectPath = initialPath ? `?redirect=${initialPath}` : '';
  const redirectQuery = cleanRedirect(initialQuery ? `?${initialQuery}` : '');
  const encodedInitialUri = initialPath
    ? encodeURI(`${redirectPath}${redirectQuery}`)
    : '';

  return `${baseUrl}${encodedInitialUri}`;
};

export const getCompressedB64String = (value: string | undefined) => {
  if (!value) {
    return '';
  }
  const buf = strToU8(value);
  const ffCompressed = compressSync(buf, { level: 6, mem: 8 }) as unknown;

  return btoa(String.fromCharCode.apply(null, ffCompressed as number[]));
};

export const getStringFromCompressedB64String = (value: string | undefined) => {
  if (!value) {
    return '';
  }
  try {
    const ffCompressedFromB64 = new Uint8Array(
      atob(value)
        .split('')
        .map((c) => {
          return c.charCodeAt(0);
        })
    );

    const decompressed = decompressSync(ffCompressedFromB64);
    return strFromU8(decompressed);
  } catch (error) {
    console.error('Could not parse B64 string to string: ', value);
    return '';
  }
};
