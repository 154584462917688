import React from 'react';

import { OverlayLoader } from '../UI/Overlay';
import useAuth, { KEYS } from '../hooks/useAuth';

interface LoginProps {
  location: {
    state: {
      from: {
        pathname: string;
        search: string;
      };
    };
  };
}

const Login: React.FC<LoginProps> = () => {
  const { loginWithRedirect } = useAuth();
  React.useEffect(() => {
    const intendedRoute = localStorage.getItem(KEYS.INTENDED_ROUTE);
    if (intendedRoute) {
      localStorage.removeItem(KEYS.INTENDED_ROUTE);
      const intededSearch = sessionStorage.getItem(KEYS.INTENDED_SEARCH);
      if (intededSearch) {
        sessionStorage.removeItem(KEYS.INTENDED_SEARCH);
        loginWithRedirect({
          appState: { returnTo: intendedRoute, returnToSearch: intededSearch },
        });
      } else {
        loginWithRedirect({
          appState: { returnTo: intendedRoute },
        });
      }
    } else {
      loginWithRedirect();
    }
  }, []);

  return <OverlayLoader />;
};

export default Login;
