import React, {
  createElement,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTheme } from '@nivo/core';
import { animated, to } from '@react-spring/web';
import { useTooltip } from '@nivo/tooltip';

export const BarItem = ({
  bar: { data, ...bar },
  style: {
    borderColor,
    color,
    height,
    labelColor,
    labelOpacity,
    labelX,
    transform,
  },
  borderWidth,
  label,
  shouldRenderLabel,
  isInteractive,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  isFocusable,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
}: any) => {
  const theme = useTheme();
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip();
  const [isHover, setIsHover] = useState(false);
  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );

  const handleClick = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onClick?.({ color: bar.color, ...data }, event);
    },
    [bar, data, onClick]
  );
  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) =>
      showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  );
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event);
      showTooltipFromEvent(renderTooltip(), event);
      setIsHover(true);
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  );
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event);
      hideTooltip();
      setIsHover(false);
    },
    [data, hideTooltip, onMouseLeave]
  );

  // extra handlers to allow keyboard navigation
  const handleFocus = useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }, [showTooltipAt, renderTooltip, bar]);
  const handleBlur = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  // We are supporting a fixed width for bar items in our bar charts to give us a consistent look.
  // However, in case we run into a bar chart with enough data to require skinnier bar items, our chart will recover.
  const fixedWidth = 10;
  const calculatedWidth =
    bar.width < fixedWidth ? Math.max(bar.width / 5, 0) : fixedWidth;
  const calculatedX =
    bar.width < fixedWidth
      ? Math.max((bar.width * 4) / 10, 0)
      : Math.max(bar.width / 2 - calculatedWidth / 2, 0);
  const calculatedCurve = calculatedWidth / 2;

  const calculatedHeight = Math.max(bar.height * 0.75, 0);
  const calculatedY = Math.max(bar.height, 0);

  const getLabelBackgroundWidth = (barchartLabel: number | string) => {
    if (barchartLabel === 0) {
      return 25;
    }

    const { length } = (barchartLabel || '').toString().replace(/[,.]/g, '');
    if (length === 0) {
      return 0;
    }
    if (length === 1) {
      return 25;
    }
    if (length > 2) {
      return 12.5 * length;
    }
    return 35;
  };

  const calculatedLabelBackgroundWidth = getLabelBackgroundWidth(label);
  const calulatedLabelBackgroundXOffset =
    calculatedLabelBackgroundWidth / 2 - calculatedWidth / 2;
  const labelBackgroundHeight = 25;

  return (
    <animated.g transform={transform}>
      {data.value > 0 && (
        <animated.path
          d={`
             m${calculatedX},${calculatedY}
             v-${calculatedHeight}
             c0,-${calculatedCurve} ${calculatedWidth},-${calculatedCurve} ${calculatedWidth},0
             v${calculatedHeight}
             z
          `}
          fill={data.fill ?? color}
          strokeWidth={borderWidth}
          stroke={borderColor}
          focusable={isFocusable}
          tabIndex={isFocusable ? 0 : undefined}
          aria-label={ariaLabel ? ariaLabel(data) : undefined}
          aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
          aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
          onMouseEnter={isInteractive ? handleMouseEnter : undefined}
          onMouseMove={isInteractive ? handleTooltip : undefined}
          onMouseLeave={isInteractive ? handleMouseLeave : undefined}
          onClick={isInteractive ? handleClick : undefined}
          onFocus={isInteractive && isFocusable ? handleFocus : undefined}
          onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        />
      )}

      {shouldRenderLabel && (
        <>
          <animated.rect
            x={calculatedX - calulatedLabelBackgroundXOffset}
            y={to(height, (value) =>
              Math.max(
                value * 0.25 - labelBackgroundHeight * 1.5,
                -labelBackgroundHeight * 1.5
              )
            )}
            width={calculatedLabelBackgroundWidth}
            height={labelBackgroundHeight}
            rx={8}
            ry={8}
            fill={isHover ? '#E9EBEC' : '#fff'}
            strokeWidth={borderWidth}
            stroke={borderColor}
            focusable={isFocusable}
            tabIndex={isFocusable ? 0 : undefined}
            aria-label={ariaLabel ? ariaLabel(data) : undefined}
            aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
            aria-describedby={
              ariaDescribedBy ? ariaDescribedBy(data) : undefined
            }
            onMouseEnter={isInteractive ? handleMouseEnter : undefined}
            onMouseMove={isInteractive ? handleTooltip : undefined}
            onMouseLeave={isInteractive ? handleMouseLeave : undefined}
            onClick={isInteractive ? handleClick : undefined}
            onFocus={isInteractive && isFocusable ? handleFocus : undefined}
            onBlur={isInteractive && isFocusable ? handleBlur : undefined}
            filter="drop-shadow(1px 2px 4px rgba(60, 68, 86, 0.15))"
          />
          <animated.text
            x={labelX}
            y={to(height, (value) => Math.max(value * 0.25 - 25, -25))}
            textAnchor="middle"
            dominantBaseline="central"
            fillOpacity={labelOpacity}
            style={{
              ...theme.labels.text,
              pointerEvents: 'none',
              fill: labelColor,
            }}
          >
            {label}
          </animated.text>
        </>
      )}
    </animated.g>
  );
};
