import React from 'react';
import styled from '@emotion/styled';

import { ButtonAnt as Button } from '~/UI';
import { IconHelp } from '~/UI/Icons';
import { MAILTO, theme } from '~/utils';

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  height: 48px;
  z-index: 999;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border: 0;

  &:hover {
    background: #ffffff;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    background: #ffffff;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const EmailTo = styled.a`
  margin-left: 0.75rem;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;

  color: ${theme.colors.graphite};

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  &:hover {
    color: ${theme.colors.graphite};
  }
`;

const HelpButton: React.FC = () => {
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const [opened, setOpened] = React.useState(false);

  const handleClickOutside = (e: MouseEvent) => {
    if (!ref.current.contains(e.target as Node)) {
      setOpened(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div
      ref={ref}
      onFocus={() => {
        setOpened(true);
      }}
    >
      <StyledButton>
        <IconHelp
          onClick={() => {
            setOpened(!opened);
          }}
        />
        {opened && (
          <EmailTo
            href={`mailto:${MAILTO}?subject=Anza - Help`}
            target="_blank"
          >
            {MAILTO}
          </EmailTo>
        )}
      </StyledButton>
    </div>
  );
};

export default HelpButton;
