import React from 'react';
import styled from '@emotion/styled';

import { theme } from '~/utils';
import { AdminRoutePath } from '~/router/AdminRoutes';
import Padder from '~/UI/Padder';
import { Layout } from '~/UI';
import HeaderContainer from '~/components/HeaderContainer';

const AdminContent = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled(Padder)`
  display: flex;
  position: relative;
  min-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  min-width: ${theme.dimensions.width.min}px;
  max-width: ${theme.dimensions.width.max}px;
  margin: 0 auto;
  padding: 0 40px 40px 40px;
`;

export const FullWidthContainer = styled(Padder)`
  display: flex;
  position: relative;
  min-width: ${theme.dimensions.width.min}px;
  max-width: ${theme.dimensions.width.max}px;
  padding: 0 40px;
  margin: 0 auto;
`;

export interface AdminLayoutProps {
  menuItems: {
    text: string;
    path: AdminRoutePath;
  }[];
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  return (
    <Layout
      // "marginTop: -1rem" -> Temporal style to match Brett requested changes for marketing video 08/04/2023.
      // After that date we might want to remove the navbar "margin-bottom: 1rem" and "marginTop: -1rem" from this component
      // Ticket to make that change: ANZAD-2685
      wrapperStyles={{ marginTop: '-1rem', paddingTop: '16px' }}
      header={<HeaderContainer />}
    >
      <Container>
        <AdminContent>{children}</AdminContent>
      </Container>
    </Layout>
  );
};

export default AdminLayout;
