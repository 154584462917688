import { mapObjIndexed } from 'ramda';
import { ModulePermissions, ModuleFilesPermissions } from '~/store/project';

export type UseModuleFilesPermissions = {
  [key in keyof ModuleFilesPermissions]: {
    enableDownload: boolean;
  };
};

const useModulePermissions = (permissions: ModulePermissions) => {
  const files: UseModuleFilesPermissions = mapObjIndexed((file) => {
    return { enableDownload: file.read };
  }, permissions.files);
  return {
    files,
    isModuleDetailsPageEnabled: permissions.moduleDetailsPage.solar.isVisible,
  };
};

export default useModulePermissions;
