import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, baseUrl } from '~/store/api/settings';
import { UserProjectResponse } from '~/types/rtkApi';

export const projectsApi = createApi({
  reducerPath: 'projectsApiv1',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['Projectsv1', 'Projectv1', 'AllProjects'],
  endpoints: (builder) => ({
    getProjectByDetailUuid: builder.query<
      { projectUuid: string },
      { id: string }
    >({
      query: ({ id }) => routes.userProject.getProjectById(id),
      providesTags: (result, error, { id }) => [{ type: 'Projectv1', id }],
      transformResponse: (response: UserProjectResponse) => response.data,
    }),
    deleteProject: builder.mutation<{ projectUuid: string }, { id: string }>({
      query: ({ id }) => {
        return {
          url: `${routes.admin.projects()}/${id}`,
          method: 'DELETE',
        };
      },
      transformResponse: (response: UserProjectResponse) => response.data,
    }),
  }),
});

export const { useLazyGetProjectByDetailUuidQuery, useDeleteProjectMutation } =
  projectsApi;
