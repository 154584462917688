import React from 'react';
import styled from '@emotion/styled';

import { questionMark } from '~/assets';
import { Button, Layout, Space } from '~/UI';
import NavBar from '~/components/HeaderContainer';
import { BoxTitle, H1 } from '~/UI/Typography';
import { theme } from '~/utils';

const StyledSpace = styled(Space)`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H1)`
  color: ${theme.colors.primary};
  white-space: pre-wrap;
  font-size: 136px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;
`;

const PageNotFound = () => {
  return (
    <Layout
      header={<NavBar hideMainItems hideSecondaryItems hideRightMenuItems />}
    >
      <StyledSpace size="2xl" direction="vertical">
        <img width={120} height={120} src={questionMark} alt="404" />
        <Space direction="vertical" size="xl" align="center">
          <Title>404</Title>
          <ContentBox>
            <BoxTitle>
              The page you&apos;re trying to access cannot be found.
            </BoxTitle>
            <a href="/">
              <Button style={{ minWidth: '180px' }}>Return to Home</Button>
            </a>
          </ContentBox>
        </Space>
      </StyledSpace>
    </Layout>
  );
};

export default PageNotFound;
