import React from 'react';
import styled from '@emotion/styled';

import { Table } from '~/UI';
import { TableProps } from '~/UI/Table';
import { theme } from '~/utils';
import { colors } from '~/utils/theme';

export interface ProjectDetailsTableProps extends TableProps {
  isUserCorp?: boolean;
  hideBorder?: boolean;
}

const StyledTable = styled(Table)<{ hideBorder?: boolean }>`
  border-radius: 14px;
  .ant-table-cell-with-append {
    align-items: center !important;
  }

  .ant-table.ant-table-small {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    height: 100%;
  }

  .ant-table-thead > tr > th {
    background: white !important;
    max-height: 50px;
    height: 50px;

    white-space: pre-line;
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    color: ${theme.colors.charcoal};
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 16px;
  }

  .ant-table {
    border-radius: 14px;
  }

  .ant-table-content {
    border-radius: 14px;
    ${({ hideBorder }) =>
      hideBorder ? 'border: none' : `border: 1px solid ${colors.sterling};`}
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0;
  }
  .ant-table-thead {
    border-bottom: 1px solid ${colors.sterling};
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
  }
  .ant-table td {
    border: none;
  }
  .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }
  .ant-skeleton-element {
    width: 100%;
    .ant-skeleton-input {
      background: ${colors.sterling};
      border-radius: 4px;
    }
  }

  .ant-table-cell-fix-left {
    padding: 8px;
  }

  .ant-table-row-level-0 {
    .ant-table-row-expand-icon {
      color: ${colors.primary};
      border-color: ${colors.primary};
    }
  }

  .ant-table-row-level-1 {
    td:first-child {
      div {
        margin-left: 25px !important;
      }
    }
    .ant-table-cell {
      div {
        margin-left: 0;
      }
    }
    .ant-table-row-expand-icon {
      display: none;
    }
  }

  .ant-table-row-level-0 > td:first-child {
    padding-left: 7px !important;
  }
`;

const ProjectDetailsTable: React.FC<ProjectDetailsTableProps> = ({
  dataSource,
  loading,
  columns,
  rowKey = '',
  hideBorder,
  ...rest
}) => {
  return (
    <StyledTable
      {...rest}
      rowKey={rowKey}
      hideBorder={hideBorder}
      dataSource={dataSource}
      columns={columns}
      scroll={{ x: '100%' }}
      pagination={false}
      loading={loading}
    />
  );
};

export default ProjectDetailsTable;
