import { StoragePermissions } from '~/store/project';
import useBaseProjectPermissions from './useBaseProjectPermissions';

const useStoragePermissions = (permissions: StoragePermissions) => {
  return {
    ...useBaseProjectPermissions(permissions),
    modules: {
      showDetailTabAsRedacted:
        !permissions.modules.properties.dc_block.ProductName.read &&
        !permissions.modules.properties.pcs_module.ProductName.read,
      showEngagedEmptyState:
        !permissions.modules.properties.bol_architecture.Table.read &&
        !permissions.modules.properties.life_cycle_cost.Table.read &&
        !permissions.modules.properties.capacity_maintenance.Table.read,
      enableCSVSummaryDownload:
        permissions.modules.properties.augmentation_csv.EnableDownload.read,
    },
  };
};

export default useStoragePermissions;
