import styled from '@emotion/styled';
import { theme } from '../utils';

const VerticalLine = styled.div`
  width: 1px;
  height: 45px;
  background-color: ${theme.colors.gray};
`;

export default VerticalLine;
