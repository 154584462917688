import React from 'react';
import styled from '@emotion/styled';
import { Button as ButtonAnt } from 'antd';
import { ButtonProps as ButtonAntProps } from 'antd/lib/button/button';
import { theme } from '../utils';

export type ButtonProps = ButtonAntProps & {
  square?: boolean;
};

const StyledButton = styled(ButtonAnt)<ButtonProps>`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-width: 1px;

  &.ant-btn-background-ghost.ant-btn-primary[disabled] {
    color: ${theme.colors.stone};
  }

  &.ant-btn-link[disabled] {
    color: ${theme.colors.stone};
    background: transparent;

    &::before {
      background: none;
    }
  }

  ${(props) =>
    props.color
      ? `
  &, &:hover, &:focus {
    background-color: ${props.color};
    border-color: ${props.color};
    color: white;
  }
  `
      : null}

  ${({ square, size }) => {
    if (!square) return '';
    let padding = 10;
    if (size === 'middle') padding = 4;
    if (size === 'small') padding = 0;
    return `padding: ${padding}px !important;`;
  }}
`;

const Button: React.FC<ButtonProps> = ({ size = 'large', ...props }) => (
  <StyledButton size={size} {...props} />
);

export default Button;
