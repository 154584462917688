import React from 'react';
import styled from '@emotion/styled';

import { ButtonAnt as Button, ModalConfirm } from '~/UI';
import { theme } from '~/utils';

export interface DeleteModalConfirmProps {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
}

const StyledButton = styled(Button)`
  position: relative;
  display: inline-block;
`;

const DeleteModalConfirm: React.FC<DeleteModalConfirmProps> = (props) => {
  return (
    <ModalConfirm
      {...props}
      title="Are you sure you want to delete this project?"
      maskStyle={{
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
      }}
      style={{
        top: 'calc((100vh - 195px) / 2)',
      }}
      width={629}
      footer={[
        <StyledButton key="no" onClick={props.onCancel}>
          No, keep it
        </StyledButton>,
        <StyledButton
          key="yes"
          type="primary"
          color={theme.colors.error}
          onClick={props.onOk}
        >
          Yes, delete
        </StyledButton>,
      ]}
    />
  );
};

export default DeleteModalConfirm;
