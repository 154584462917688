import styled from '@emotion/styled';
import { theme } from '~/utils';

export const FormFooterBox = styled.div<{ bottom?: number }>`
  z-index: 1;
  position: fixed;
  left: 0;
  bottom: ${({ bottom }) => `${bottom ?? 0}px`};
  min-width: 100vw;
  background-color: white;
  display: flex;
  justify-content: center;
`;

export const ContentBox = styled.div<{ bottom?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 19px;
  height: 88px;
  width: 100%;
  max-width: ${theme.dimensions.width.max}px;
  padding-left: 40px;
  padding-right: 40px;
`;
