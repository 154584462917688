import React from 'react';

import { Form, InputNumber, FormInstance } from '~/UI';
import { StyledDatePicker } from '~/UI/DatePicker';
import { disabledDateByQuarter } from '~/utils';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { RACKING_TYPE, rackingTypes } from '~/utils/rackingTypes';
import { ProjectDetails } from '~/store/project';
import SOLAR_INPUT_CONFIG from '~/constants/inputs/project/solarInputs';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';

import { GenericLabel } from '../FormGroup';
import { StyledRadioButton, StyledRadioGroup } from '../shared/styles';
import { useFlags } from '~/utils/launchdarkly';

interface SolarFormGroupProps {
  form: FormInstance;
  isEditing: boolean;
  projectStatus: ProjectDetails['status'];
  previousPurchaseVolumeKw: number;
  disabledInputs?: boolean;
  displayPricingBanner?: boolean;
  testIdData?: TestIdProps;
}

const SolarFormGroup: React.FC<SolarFormGroupProps> = ({
  form,
  isEditing,
  disabledInputs = false,
  testIdData,
}) => {
  const flags = useFlags();
  const rackingType = Form.useWatch('racking_type', form);
  return (
    <>
      <Form.Item
        name="size_in_kw"
        label={
          <GenericLabel
            title="System Size"
            fontSize={isEditing ? '14px' : ''}
            testIdData={
              { ...testIdData, identifier: 'project-size' } as TestIdProps
            }
          />
        }
        rules={SOLAR_INPUT_CONFIG.sizeInKw.generateRules(rackingType)}
        required
      >
        <InputNumber
          {...SOLAR_INPUT_CONFIG.sizeInKw.inputNumberProps}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'project-size',
          })}
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="arrival_date"
        label={
          <GenericLabel
            title="Delivery Date"
            popoverContent="The date you expect products to arrive onsite."
            fontSize={isEditing ? '14px' : ''}
            testIdData={
              { ...testIdData, identifier: 'delivery-date' } as TestIdProps
            }
          />
        }
        rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
      >
        <StyledDatePicker
          {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
          disabled={disabledInputs}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'delivery-date',
          })}
          disabledDate={disabledDateByQuarter}
          inputReadOnly
        />
      </Form.Item>
      <Form.Item
        name="anticipated_purchase_date"
        label={
          <GenericLabel
            title="Anticipated Purchase Date"
            popoverContent="The date you expect to execute a contract with Anza."
            fontSize={isEditing ? '14px' : ''}
            testIdData={
              {
                ...testIdData,
                identifier: 'anticipated-purchase-date',
              } as TestIdProps
            }
          />
        }
        rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
          () => form.getFieldsValue(['arrival_date']).arrival_date
        )}
      >
        <StyledDatePicker
          {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
            () => form.getFieldsValue(['arrival_date']).arrival_date
          )}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'anticipated-date',
          })}
          inputReadOnly
          disabled={disabledInputs}
        />
      </Form.Item>
      <Form.Item
        name="racking_type"
        label={
          <GenericLabel
            testIdData={
              {
                ...testIdData,
                identifier: 'racking-type',
              } as TestIdProps
            }
            title="Racking Type"
            fontSize="14px"
          />
        }
        labelCol={{ span: 24 }}
        rules={SOLAR_INPUT_CONFIG.rackingType.rules}
      >
        <StyledRadioGroup
          buttonStyle="solid"
          size="large"
          disabled={disabledInputs}
          data-testid={generateTestId({
            component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.RADIO_GROUP}`,
            identifier: 'project-type',
          })}
        >
          {rackingTypes
            .filter(
              (value) =>
                !(value === RACKING_TYPE.ROOFTOP_MLPE && !flags.enableRooftop)
            )
            .map((rackingType, index) => (
              <StyledRadioButton
                value={rackingType}
                data-testid={generateTestId({
                  component: `${testIdData?.component}-${TEST_DATA_COMPONENTS.RADIO_BUTTON}`,
                  identifier: 'racking-type',
                  indices: index.toString(),
                })}
                key={rackingType}
              >
                {rackingType}
              </StyledRadioButton>
            ))}
        </StyledRadioGroup>
      </Form.Item>
    </>
  );
};

export default SolarFormGroup;
