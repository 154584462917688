import React from 'react';
import * as R from 'ramda';
import { theme } from '~/utils';

const defaultStyles: React.CSSProperties = {
  textAlign: 'center',
  color: theme.colors.graphite,
  fontSize: '16px',
  fontWeight: 600,
  backgroundColor: theme.colors.grey,
  padding: '12px',
  borderRadius: '10px',
};

const getContent = (contentValue: string | undefined) => {
  return R.isNil(contentValue) ? 'No Data' : contentValue;
};

const NoDataAvailable: React.FC<{
  content?: string | undefined;
  styles?: React.CSSProperties | undefined;
}> = ({ content, styles }) => {
  return (
    <div style={{ ...defaultStyles, ...styles }}>{getContent(content)}</div>
  );
};

export default NoDataAvailable;
