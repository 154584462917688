import React from 'react';
import { values } from 'ramda';
import { usaFlagIcon } from '~/assets';

type ModuleTag =
  | 'hjt'
  | 'perc'
  | 'top_con'
  | 'produced_in_usa_100'
  | 'partially_produced_in_usa'
  | 'us_cells'
  | 'tier_1'
  | 'us_assembly'
  | 'cdte_thin_film';

const MODULE_TAGS_KEYS = {
  TIER_1: 'TIER_1',
  TOP_CON: 'TOP_CON',
  HJT: 'HJT',
  PERC: 'PERC',
  US_CELLS: 'US_CELLS',
  US_ASSEMBLY: 'US_ASSEMBLY',
  CDTE_THIN_FILM: 'CDTE_THIN_FILM',
} as const;

export type CellTechology = Exclude<
  ModuleTag,
  | 'produced_in_usa_100'
  | 'partially_produced_in_usa'
  | 'tier_1'
  | 'us_assembly'
  | 'us_cells'
> | null;

type ModuleTagKeys = (typeof MODULE_TAGS_KEYS)[keyof typeof MODULE_TAGS_KEYS];

type ModuleTagData = Record<
  ModuleTagKeys,
  {
    value: ModuleTag;
    label: string;
    description?: string;
    helpInfo?: string;
    icon?: JSX.Element;
  }
>;

export const MODULE_TAGS: ModuleTagData = {
  HJT: {
    value: 'hjt',
    label: 'HJT',
    helpInfo:
      'Heterojunction technology combines crystalline and amorphous silicon layers that when combined deliver high module efficiencies.',
  },
  PERC: {
    value: 'perc',
    label: 'PERC',
    helpInfo:
      'Passivated Emitter and Rear Contact (PERC) solar cells build upon a standard monocrystalline silicon cell by adding a backside dielectric passivation layer that reflects light back through the cell.',
  },
  TOP_CON: {
    value: 'top_con',
    label: 'TOPCon',
    helpInfo:
      'TOPCon has an N-Type Silicon substrate and a thin tunneling oxide is applied, followed by a layer of highly doped N or P polysilicon.',
  },
  US_CELLS: {
    value: 'us_cells',
    icon: <img src={usaFlagIcon} alt="USA flag" />,
    label: 'U.S. Cells',
    helpInfo:
      'This module contains Cells that can be considered a U.S. Component for the purpose of calculating a project’s Domestic Manufactured Products and Components Cost.',
  },
  TIER_1: {
    value: 'tier_1',
    label: 'Tier-1',
    helpInfo:
      'This module manufacturer has reported being Tier-1 in at least one of the two most recent quarters.',
  },
  US_ASSEMBLY: {
    value: 'us_assembly',
    icon: <img src={usaFlagIcon} alt="USA flag" />,
    label: 'U.S. Assembly',
    helpInfo:
      'This module was assembled in the US. The components, including cells, are not verified to be domestically produced for the purpose of calculating a project’s Domestic Manufactured Products and Components cost.',
  },
  CDTE_THIN_FILM: {
    value: 'cdte_thin_film',
    label: 'CdTe Thin-film',
  },
} as const;

export const ORDERED_MODULE_TAGS_KEYS = [
  MODULE_TAGS_KEYS.TIER_1,
  MODULE_TAGS_KEYS.TOP_CON,
  MODULE_TAGS_KEYS.HJT,
  MODULE_TAGS_KEYS.PERC,
  MODULE_TAGS_KEYS.US_CELLS,
  MODULE_TAGS_KEYS.US_ASSEMBLY,
] as const;

export const MODULE_TAGS_VALUES = values(MODULE_TAGS).map((t) => t.value);

export const ORDERED_MODULE_TAGS_VALUES = [
  MODULE_TAGS.TIER_1.value,
  MODULE_TAGS.TOP_CON.value,
  MODULE_TAGS.HJT.value,
  MODULE_TAGS.PERC.value,
  MODULE_TAGS.US_CELLS.value,
  MODULE_TAGS.US_ASSEMBLY.value,
] as const;

export const MODULE_MAX_QUARTER_DATA_SHOWN = 10;

export const FRONTSIDE_GLASS_MM_OPTIONS = [2, 2.5, 3.2, 4];
export const BACKSIDE_GLASS_MM_OPTIONS = [2, 2.5, 3.2, null];

export const BACKSIDE_MATERIAL_OPTIONS = {
  GLASS: 'Glass',
  TRANSPARENT_BACKSHEET: 'Transparent Backsheet',
  SOLID_BACKSHEET: 'Solid Backsheet',
};

export const MAX_MECH_TEST_LOAD_SNOW_MIN = 1000;
export const MAX_MECH_TEST_LOAD_SNOW_MAX = 20000;

export const MAX_MECH_TEST_LOAD_WIND_MIN = 1000;
export const MAX_MECH_TEST_LOAD_WIND_MAX = 10000;

export const CELL_TECHNOLOGY_OPTIONS = [
  MODULE_TAGS.PERC,
  MODULE_TAGS.TOP_CON,
  MODULE_TAGS.HJT,
  MODULE_TAGS.CDTE_THIN_FILM,
];
export const DOMESTIC_CONTENT_OR_ASSEMBLY_OPTIONS = [
  MODULE_TAGS.US_CELLS,
  MODULE_TAGS.US_ASSEMBLY,
];
