import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { CompanyType } from '~/types/users';

export const adminCompaniesApi = createApi({
  reducerPath: 'adminCompaniesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AllCompanies', 'AdminGetCompany'],
  endpoints: (builder) => ({
    getAllCompanies: builder.query<
      CompanyType[],
      { limitToActiveModules?: boolean } | void
    >({
      query: (params) => {
        const { limitToActiveModules } = params ?? {};
        const parsedParams = limitToActiveModules
          ? `limitToActiveModules=${limitToActiveModules}`
          : undefined;
        return routes.admin.companies(parsedParams);
      },
      keepUnusedDataFor: 3,
      providesTags: () => [{ type: 'AllCompanies', id: 'ALL_COMPANIES' }],
    }),
    getCompany: builder.query<CompanyType, { id: string }>({
      query: ({ id }) => routes.admin.companiesSettings(id),
      keepUnusedDataFor: 3,
      providesTags: () => [
        { type: 'AdminGetCompany', id: 'ADMIN_GET_COMPANY' },
      ],
    }),
    deleteCompany: builder.mutation<CompanyType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `${routes.admin.companies()}/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useLazyGetAllCompaniesQuery,
  useGetAllCompaniesQuery,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} = adminCompaniesApi;
