import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../utils';

const List = styled.div`
  top: 56px;
  right: 0;
  z-index: 1;
  display: flex;
  max-height: 192px;
  width: 100%;
  position: absolute;
  overflow-y: auto;
  border-radius: 0.375rem;
  flex-direction: column;
  box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 20%);
`;

const ListElement = styled.div<{
  selected?: boolean;
}>`
  width: 100%;
  border: none;
  display: flex;
  cursor: pointer;
  min-height: 48px;
  max-height: 48px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0.6rem 1rem;
  color: ${theme.fonts.colors.charcoal};
  background: ${(props) =>
    props.selected ? theme.colors.gray : theme.colors.background};
  font-size: ${theme.fonts.sizes.placeholder};

  &:hover {
    background: ${theme.colors.gray};
  }
`;

export interface DropdownProps {
  style?: React.CSSProperties;
  dropdownOpen: boolean;
  options?: string[];
  handleDropdownOpen?: (value: boolean) => void;
  onChange?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: string
  ) => void;
  closeWhenClickOutside?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  dropdownOpen = true,
  options,
  onChange,
  handleDropdownOpen,
  style,
  closeWhenClickOutside = true,
}) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  const [currentValue, setCurrentValue] = React.useState<string>('');

  const handleChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    option: string
  ) => {
    event.stopPropagation();
    handleDropdownOpen!(false);
    setCurrentValue(option);

    if (onChange != null) {
      onChange(event, option);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      listRef.current === event.composedPath()[1] ||
      listRef.current === event.composedPath()[2] ||
      listRef.current === event.composedPath()[3] ||
      listRef.current === event.composedPath()[5]
    ) {
      return;
    }
    if (handleDropdownOpen != null && closeWhenClickOutside) {
      handleDropdownOpen!(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={listRef}>
      {dropdownOpen && (
        <List style={style}>
          {options!.map((option) => (
            <ListElement
              key={Math.random()}
              onClick={(event) => handleChange(event, option)}
              selected={option === currentValue}
            >
              {option}
            </ListElement>
          ))}
        </List>
      )}
    </div>
  );
};

export default Dropdown;
