import React from 'react';

import { colors } from '~/utils';
import { IconTipsAndUpdates } from '~/UI/Icons';
import { PROJECT_TYPES } from '~/constants/projects';

import { Box, Content, StyledButton, Subtitle, TextBox, Title } from './styles';

export type AdvancedInputsBannerProps = {
  learnMoreUrl?: string;
  loading?: boolean;
  disable?: boolean;
  projectType: string;
};

const AdvancedInputsBanner = ({
  learnMoreUrl,
  loading,
  disable,
  projectType,
}: AdvancedInputsBannerProps) => {
  if (disable) return null;
  return (
    <Box>
      <Content>
        <IconTipsAndUpdates size="24" color={colors.blue} />
        <TextBox>
          <Title>
            {`Need to customize your
            ${PROJECT_TYPES[projectType].clientFacingValue} project?`}
          </Title>
          <Subtitle>Reach out to your account manager.</Subtitle>
        </TextBox>
      </Content>
      <a href={learnMoreUrl} target="_blank" rel="noreferrer">
        <StyledButton type="default" size="large" loading={loading}>
          Schedule a Meeting
        </StyledButton>
      </a>
    </Box>
  );
};

export default AdvancedInputsBanner;
