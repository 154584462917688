import React from 'react';

import { SmallPrimaryTitle } from '~/UI/Typography';

import {
  ChipsContainer,
  EPCRevenueValueChip,
  InfoContainer,
  PriceChip,
  EffectivePriceChipInfo,
  TextBlock,
  TitleContainer,
} from './styles';

const SolarInfo = () => {
  return (
    <>
      <TitleContainer>
        <TextBlock>
          The terms seen in the ranking table headings are defined below.
        </TextBlock>
      </TitleContainer>
      <InfoContainer>
        <SmallPrimaryTitle>Anza Effective $/W</SmallPrimaryTitle>
        <TextBlock>
          The Anza Effective $/W is the way you can easily compare modules based
          on value so that your module selection maximizes project profits. We
          calculate this value by starting with the module Purchase Price and
          then subtracting BOS Savings and Production Benefit. The lower the
          Effective $/W, the more value you create for your project.
        </TextBlock>
        <ChipsContainer>
          <PriceChip>Purchase Price</PriceChip> -{' '}
          <EPCRevenueValueChip>BOS + Production Benefit</EPCRevenueValueChip>=
          <EffectivePriceChipInfo>Effective $/W</EffectivePriceChipInfo>
        </ChipsContainer>
      </InfoContainer>
      <InfoContainer>
        <SmallPrimaryTitle>BOS Savings</SmallPrimaryTitle>
        <TextBlock>
          The Balance of System (BOS) Savings represents the delta in the labor
          and material costs related to module installation, racking,
          foundation, and DC wiring between a specific module and the Reference
          Module. This value takes into account local labor and material costs
          as well as the racking type. This scope is not exhaustive of all EPC
          costs and represents the scopes most impacted by module selection. The
          unit is $/Watt.
        </TextBlock>
      </InfoContainer>
      <InfoContainer>
        <SmallPrimaryTitle>Production Benefit</SmallPrimaryTitle>
        <TextBlock>
          The Production Benefit is the delta in the present value of the
          project revenue between a specific module and the Reference Module as
          a result of variations in performance and degradation. This value
          takes into account the Energy Value / PPA Rate, Discount Rate, Project
          Term, and ITC Value. The unit is $/Watt.
        </TextBlock>
      </InfoContainer>
      <InfoContainer>
        <SmallPrimaryTitle>Anza Reference Module</SmallPrimaryTitle>
        <TextBlock>
          The Anza Reference Module is an average, representative module for the
          current U.S. market. In this Ranking Table, the Reference Module is a
          550W Bifacial, P-Type Mono PERC, 1134mm x 2279mm, 182 cell module with
          median EPC costs and performance. The BOS Savings and Production
          Benefit listed in this Ranking Table are the deltas between the
          specific module and the Reference Module.
        </TextBlock>
      </InfoContainer>
      <InfoContainer>
        <SmallPrimaryTitle>Purchase Price</SmallPrimaryTitle>
        <TextBlock>
          Purchase Price is the sales price of the module, including estimated
          delivery to the project site and Anza fees, excluding sales tax.
        </TextBlock>
      </InfoContainer>
      <InfoContainer>
        <SmallPrimaryTitle>Availability</SmallPrimaryTitle>
        <TextBlock>
          The megawatts (MW) of a specific module that is available for your
          project during the selected delivery quarter.
        </TextBlock>
      </InfoContainer>
    </>
  );
};

export default SolarInfo;
