import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { SolarEngineResultType } from '~/store/project';
import { EngineGetInputsResponse } from '~/types/engine';
import { BASE_URL_V2, prepareHeaders } from '../settings';

export const anzaEngineApi = createApi({
  reducerPath: 'anzaEngineApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['EngineInputs', 'SolarEngineResults'],
  endpoints: (builder) => ({
    // 👇 Query: Get All Modules for vendor
    getInputsByProjectId: builder.query<
      EngineGetInputsResponse,
      { projectId: string }
    >({
      query: ({ projectId }) => routes.admin.anzaEngineInputs(projectId),
      keepUnusedDataFor: 1,
      providesTags: () => [{ type: 'EngineInputs', id: 'ENGINE_INPUTS' }],
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: EngineGetInputsResponse) => {
        return response;
      },
    }),
    getStorageInputsByProjectId: builder.query<
      any,
      { projectId: string; engineVersion: string }
    >({
      query: ({ projectId, engineVersion }) =>
        routes.admin.anzaStorageEngineInputs(projectId, engineVersion),
      keepUnusedDataFor: 1,
      // 👇 Transform the result to prevent nested data
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getSolarEngineResultsDownloadUrl: builder.query<
      { signedUrl: string },
      { requestId: string }
    >({
      query: ({ requestId }) =>
        routes.engine.getSolarEngineResultsDownloadUrl(requestId),
    }),
  }),
});

export const {
  useGetInputsByProjectIdQuery,
  useLazyGetInputsByProjectIdQuery,
  useLazyGetStorageInputsByProjectIdQuery,
  useLazyGetSolarEngineResultsDownloadUrlQuery,
} = anzaEngineApi;
