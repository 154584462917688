import React, { useEffect } from 'react';
import { equals } from 'ramda';
import styled from '@emotion/styled';

import { Form } from '~/UI';
import { TestIdProps } from '~/utils/dataTestProps';
import { GTM_EVENTS, maybeSendPlatformEventToGTM } from '~/utils/gtm';

import Filter, { Option } from './Filter';
import type { FilterMenuFilterConfig } from './FilterMenu';

export type FilterConfig = {
  name: string;
  title: string;
  options: Option[];
  filterHeaderDataTestIdConfig?: TestIdProps;
};

export type FilterValues = Record<string, string[]>;

export type FilterFormProps = {
  filters: (FilterConfig &
    Pick<FilterMenuFilterConfig, 'hasHeaderClearButton'>)[];
  values?: FilterValues;
  onValuesChange?: (changedValues: FilterValues, values: FilterValues) => void;
  onClearFilter?: (filter: string) => void;
  hasReachedLimit?: boolean;
  selectedFilters?: string[];
  gtmPayloadLabel?: string;
  includeGtmProperties?: boolean;
};

export const StyledForm = styled(Form)`
  display: flex;
  .ant-form-item {
    margin-bottom: 32px;
  }
`;

const isStringArray = (value: unknown): value is string[] => {
  return (
    Array.isArray(value) && value.every((item) => typeof item === 'string')
  );
};

const getSelectedFilters = (values: FilterValues) => {
  const selectedFiltersArray = Object.values(values)
    .filter(isStringArray)
    .flat();
  const selectedFilters: Record<string, string> = {};
  selectedFiltersArray.forEach((filter, i) => {
    selectedFilters[i] = filter;
  });
  return selectedFilters;
};

const FilterForm = ({
  filters,
  onValuesChange,
  values,
  onClearFilter,
  hasReachedLimit,
  selectedFilters,
  gtmPayloadLabel,
  includeGtmProperties = false,
}: FilterFormProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (!equals(values, form.getFieldsValue())) {
      form.resetFields();
    }
  }, [values]);

  return (
    <StyledForm
      form={form}
      initialValues={values}
      onValuesChange={(
        changedValues: FilterValues,
        allValues: FilterValues
      ) => {
        onValuesChange?.(changedValues, allValues);
        maybeSendPlatformEventToGTM(
          GTM_EVENTS.STAGE_CHANGED,
          gtmPayloadLabel ?? null,
          getSelectedFilters(allValues),
          gtmPayloadLabel
        );
      }}
    >
      {filters.map(
        ({
          name,
          options,
          title,
          filterHeaderDataTestIdConfig,
          hasHeaderClearButton,
        }) => (
          <Filter
            key={name}
            name={name}
            options={options}
            onClearFilter={(filterName) => {
              if (onClearFilter) onClearFilter(filterName);
            }}
            title={title}
            headerDataTestIdConfig={filterHeaderDataTestIdConfig}
            hasReachedLimit={hasReachedLimit}
            selectedFilters={selectedFilters}
            hasHeaderClearButton={hasHeaderClearButton}
            includeGtmProperties={includeGtmProperties}
          />
        )
      )}
    </StyledForm>
  );
};

export default FilterForm;
