import { AxiosResponse } from 'axios';
import { catchError } from '~/utils';
import { UserType } from '~/types/users';
import { apiV2 } from '~/api/axios';
import { routes } from '~/api/routes';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export interface ModelEditSettings {
  firstName: string;
  lastName: string;
  jobTitle: string;
  moduleDigestOptOut: boolean;
}

export const update = async (
  id: number,
  params: ModelEditSettings
): Promise<AxiosResponse<UserType>> => {
  try {
    return await apiV2.put(routes.auth.settings(id), params, config);
  } catch (error) {
    catchError({
      error,
      method: 'update',
      location: 'services/api/settings',
    });
    throw error;
  }
};
