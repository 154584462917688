import dayjs, { Dayjs } from 'dayjs';

import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
export const getClosestQuarterInOrderedArray = (
  inputDate: string,
  quarterYearArray: string[]
) => {
  const inputDateObj = dayjs(inputDate);
  const inputQuarterYear = `${inputDateObj.year()}-${inputDateObj.quarter()}`;

  if (!inputDate || !quarterYearArray.length) {
    return inputQuarterYear;
  }

  if (quarterYearArray.includes(inputQuarterYear)) {
    return inputQuarterYear;
  }
  const getIndex = (datesList: Dayjs[], date: Dayjs) => {
    const epochListDiff: number[] = datesList.map(
      (q) => q.unix() - date.unix()
    );

    if (epochListDiff[0] > 0) return 0;
    if (epochListDiff[datesList.length - 1] < 0) return epochListDiff.length;

    let foundIndex = 0;

    epochListDiff.some((e, i) => {
      if (i >= epochListDiff.length - 2) {
        foundIndex = epochListDiff.length;
      }

      if (e < 0 && epochListDiff[i + 1] >= 0) {
        foundIndex = i + 1;
        return true;
      }
      return false;
    });

    return foundIndex - 1;
  };

  const quarters = quarterYearArray.map((q) => {
    const [year, quarter] = q.split('-');
    const firstMonthOfQuarter = 1 + (parseInt(quarter, 10) - 1) * 3;

    return dayjs(`${year}-${firstMonthOfQuarter}-01`);
  });

  const indexOfPrevQuarter = getIndex(quarters, inputDateObj);

  if (indexOfPrevQuarter === 0) {
    return inputDate[0];
  }

  if (indexOfPrevQuarter >= inputDate.length - 1) {
    return inputDate[inputDate.length - 1];
  }

  const endOfPrevQuarter = quarters[indexOfPrevQuarter].endOf('quarter');
  const startOfNextQuarter =
    quarters[indexOfPrevQuarter + 1].startOf('quarter');

  const diffBetweenPrev = inputDateObj.diff(endOfPrevQuarter, 'millisecond');
  const diffBetweenNext = inputDateObj.diff(startOfNextQuarter, 'millisecond');

  if (diffBetweenPrev < diffBetweenNext) {
    return quarterYearArray[indexOfPrevQuarter];
  }

  return quarterYearArray[indexOfPrevQuarter + 1];
};
