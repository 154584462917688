const MILLISECONDS_IN_SECOND = 1000;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_HOUR = MINUTES_IN_HOUR * 60;
const HOURS_IN_DAYS = 24;

export const getSecondsInHours = (hours: number): number =>
  hours * SECONDS_IN_HOUR;

export const getMilliSecondsInDays = (days: number): number =>
  days * HOURS_IN_DAYS * SECONDS_IN_HOUR * MILLISECONDS_IN_SECOND;

export const getMilliSecondsInHours = (hours: number): number =>
  hours * SECONDS_IN_HOUR * MILLISECONDS_IN_SECOND;
