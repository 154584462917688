import React from 'react';

import { BorregoRouteProps } from '~/types/route-props';
import { StyledCard } from '~/UI/Card';
import { ActionRow, Title } from '~/UI/ActionBar';

import SettingsForm from '../components/SettingsForm';

const Settings: React.FC<BorregoRouteProps> = () => {
  return (
    <>
      <StyledCard>
        <ActionRow>
          <div className="table">
            <Title>Settings</Title>
          </div>
        </ActionRow>
      </StyledCard>
      <StyledCard>
        <SettingsForm />
      </StyledCard>
    </>
  );
};

export default Settings;
