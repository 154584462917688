import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { PaginationAndSortingParams } from '~/services/api/moduleUpdateLogs';
import { ModuleUpdateLog } from '~/utils';
import { BASE_URL_V2, prepareHeaders } from '../settings';

export const auditLogsApi = createApi({
  reducerPath: 'auditLogsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['AuditLogs'],
  endpoints: (builder) => ({
    getModuleUpdateLogs: builder.query<
      {
        count: number;
        items: ModuleUpdateLog[];
      },
      PaginationAndSortingParams
    >({
      providesTags: () => [{ type: 'AuditLogs' }],
      query: (params) => {
        return {
          url: routes.admin.moduleUpdateLogs(),
          params,
        };
      },
    }),
  }),
});

export const { useGetModuleUpdateLogsQuery, useLazyGetModuleUpdateLogsQuery } =
  auditLogsApi;
