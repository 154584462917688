import styled from '@emotion/styled';

import { Popover } from '~/UI';
import { colors, fonts } from '~/utils';

const ModulePopover = styled(Popover)`
  .ant-popover-placement-top {
    padding-bottom: 0;
  }
  .ant-popover-content > .ant-popover-inner {
    border-radius: 6px;
    max-width: 250px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
  }
  h3 {
    font-size: ${fonts.sizes.label};
    font-weight: 400;
    line-height: 18px;
    color: ${colors.gray800};
  }
  h3 > div > div {
    font-size: ${fonts.sizes.label};
    font-weight: 700;
    line-height: 16px;
    color: ${colors.infoMain};
  }
`;

export default ModulePopover;
