import styled from '@emotion/styled';
import { colors } from '~/utils';
import { H4, H4Strong } from '~/UI/Typography';
import { ButtonAnt } from '~/UI';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 12px 20px 12px 20px;
  background-color: ${colors.info1light};
  min-width: 337px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 190px;
`;

export const StyledButton = styled(ButtonAnt)`
  height: 36px;
`;

export const Title = styled(H4Strong)`
  color: ${colors.blue};
`;

export const Subtitle = styled(H4)`
  font-weight: 400;
  color: ${colors.blue};
`;
