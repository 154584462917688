import styled from '@emotion/styled';
import { theme } from '~/utils';

export default styled.span`
  color: ${theme.colors.graphite};
  margin-right: 12px;
  padding: 2px 12px 2px 0;
  bottom: 0;
  border-right: 1px solid ${theme.colors.grey};
  font-size: 16px;
`;
