import * as R from 'ramda';

export const downloadFile = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop() || '';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * Given a PAN file content, returns a string of the Remarks section
 * @param panFileText
 * @returns pan file notes
 */
export const getPanFileTextRemarks = (panFileText: string) => {
  const lines = panFileText.split('\n');
  const startIndex = lines.findIndex((line) => line.includes('Remarks,'));
  const remarkLine = lines.find((line) => line.includes('Remarks,'));
  if (!remarkLine) return '';
  const linesCount = remarkLine.split('Count=')[1];
  const array = R.repeat('', Number(linesCount));
  const notesArray = array.map((_, j) => lines[startIndex + j + 1]);
  const cleanedNotesArray = notesArray.map((line, index) => {
    const initalRemarkString = `Str_${index + 1}=`;
    return line.split(initalRemarkString)[1];
  });
  const notes = cleanedNotesArray.join(' ').replace(/(\r\n|\n|\r)/gm, '');
  return notes;
};
