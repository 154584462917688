import React from 'react';
import { useHistory } from 'react-router';
import * as R from 'ramda';

import useAuth from '~/hooks/useAuth';
import { PersonnelInfoBanner } from '~/UI';
import { SpinnerMask } from '~/UI/Spinner';
import { getAccountManagerInfo, ServerResponses } from '~/utils';
import AnzaContainer from '~/components/Anza/AnzaLayout/AnzaContainer';
import { ROUTER_PATHS } from '~/router/Paths';
import useModulePermissions from '~/hooks/Permissions/useModulePermissions';
import { ModulePermissions } from '~/store/project';

import ModuleHero from './ModuleHero';
import ModuleDetails, { ModuleDetailsType } from './ModuleDetails';
import { useModuleDatabase } from '../hooks/useModuleDatabase';

const ModuleDatabaseFeature = ({
  changeBreadcrumbPageTitle,
}: {
  changeBreadcrumbPageTitle: (moduleName: string) => void;
}) => {
  const history = useHistory();
  const {
    chartData,
    heroData,
    lastUpdated,
    moduleName,
    vendor,
    moduleDetails,
    isLoading,
    isFetching,
    fileGroups,
    isError,
    error,
    thumbnailImageUrl,
  } = useModuleDatabase();
  const { user } = useAuth();

  if (isLoading || isFetching) {
    return <SpinnerMask />;
  }

  if (
    isError &&
    (error as { status: number })?.status === ServerResponses.NOT_FOUND
  ) {
    history.replace(ROUTER_PATHS.PAGE_NOT_FOUND());
    return null;
  }

  if (!moduleDetails || R.isEmpty(moduleDetails)) {
    return null;
  }

  if (typeof changeBreadcrumbPageTitle === 'function') {
    changeBreadcrumbPageTitle(moduleName);
  }

  const { files } = useModulePermissions(
    moduleDetails?.permissions as ModulePermissions
  );

  return (
    <AnzaContainer>
      <ModuleHero
        data={heroData}
        lastUpdated={lastUpdated}
        moduleName={moduleName}
        vendor={vendor}
        chartData={chartData}
        thumbnailImageUrl={thumbnailImageUrl}
      />
      <ModuleDetails
        filesPermissions={files}
        moduleDetails={moduleDetails as ModuleDetailsType}
        fileGroups={fileGroups}
      />
      <PersonnelInfoBanner
        title="Any questions?"
        personnelInfo={getAccountManagerInfo(user)}
      />
    </AnzaContainer>
  );
};

export default ModuleDatabaseFeature;
