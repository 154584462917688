import React from 'react';
import styled from '@emotion/styled';
import { Popconfirm as PopconfirmAnt } from 'antd';
import { PopconfirmProps as PopconfirmAntProps } from 'antd/lib/popconfirm/index';

export type PopconfirmProps = PopconfirmAntProps;

const StyledPopconfirm = styled(PopconfirmAnt)<PopconfirmAntProps>``;

const Popconfirm: React.FC<PopconfirmProps> = (props) => (
  <StyledPopconfirm {...props} />
);

export default Popconfirm;
