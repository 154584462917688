import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { ButtonAnt as Button } from '~/UI';
import { IconArrowBack } from '~/UI/Icons';
import { theme } from '~/utils';

export type BackButtonProps = {
  to: string;
  text: string;
  visible?: boolean;
  params?: {
    fromLocation: string;
  };
  goBackHistory?: boolean;
};

const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 20px;
  display: flex;
`;

const StyledButton = styled(Button)`
  display: flex;
  gap: 7px;
  background-color: rgba(16, 105, 125, 0.08);
  color: ${theme.colors.primary};
  border: none;
  padding: 12px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 16px;
  height: 40px;
`;

const BackHandler = ({
  to,
  text,
  visible,
  params,
  goBackHistory,
}: BackButtonProps) => {
  const history = useHistory();

  const goBack = () => {
    history.go(-1);
  };

  if (!visible) return null;

  const firstPageRender = !window.history.state;

  return (
    <ButtonContainer>
      {goBackHistory && !firstPageRender ? (
        <StyledButton onClick={goBack}>
          <IconArrowBack size={20} />
          {text}
        </StyledButton>
      ) : (
        <Link
          to={{
            pathname: to,
            state: params ? { fromLocation: params.fromLocation } : null,
          }}
        >
          <StyledButton>
            <IconArrowBack size={20} />
            {text}
          </StyledButton>
        </Link>
      )}
    </ButtonContainer>
  );
};

export default BackHandler;
