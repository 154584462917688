import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { useGetModuleQuery } from '~/store/api';
import { fileTypes, ServerResponses } from '~/utils';
import { MODULE_MAX_QUARTER_DATA_SHOWN } from '~/constants/modules';
import { ModuleFile, ThirdPartyPanFile } from '~/types/file';
import { ModuleType } from '~/types/modules';
import { ModulePermissions } from '~/store/project';

dayjs.extend(quarterOfYear);

type ChartData = Array<{
  availability_mw: number;
  created_at: string;
  created_by_user_id: number;
  ddp_east_coast_port: string;
  id: number;
  module_pricing_id: number;
  quarter: number;
  updated_at: string;
  year: number;
}>;

interface ModuleTypePermissions extends ModuleType {
  permissions: ModulePermissions;
}

interface ModuleDatabase {
  moduleDatasheet: ModuleFile;
  anzaPanFile: ModuleFile;
  thirdPartyPanFile: ModuleFile;
  manufacturerPanFile: ModuleFile;
  anzaPanFileSupportingFiles: Array<ThirdPartyPanFile>;
  anzaPanFileSupportingFilesZip: ModuleFile;
  thirdPartyPanFileSupportingFiles: Array<ThirdPartyPanFile>;
  thirdPartyPanFileSupportingFilesZip: ModuleFile;
  manufacturerPanFileSupportingFiles: Array<ThirdPartyPanFile>;
  manufacturerPanFileSupportingFilesZip: ModuleFile;
}

const processChartData = (chartData: ChartData) => {
  return chartData.map((el) => ({
    id: `Q${el.quarter}|${el.year.toString().slice(2)}`,
    label: `Q${el.quarter} ${el.year}`,
    value: el.quarter,
    count: el.availability_mw || 0,
  }));
};

const setAndProcessChartData = (
  defaultData: ChartData,
  quartersData: ChartData
) => {
  const currentQuartersData = defaultData.map((defaultQuarterYear) => {
    const foundQuarterYearItem = quartersData.find(
      (qd) =>
        qd.quarter === defaultQuarterYear.quarter &&
        qd.year === defaultQuarterYear.year
    );

    return foundQuarterYearItem || defaultQuarterYear;
  });
  return processChartData(currentQuartersData);
};

export const useModuleDatabase = () => {
  const params = useParams<{ uuid: string }>();

  if (!params.uuid || params.uuid === 'null' || params.uuid === 'undefined') {
    return {
      vendor: '',
      moduleName: '',
      lastUpdated: '',
      heroData: [],
      moduleDetails: null,
      data: [],
      chartData: [],
      isFetching: false,
      isLoading: false,
      isSuccess: false,
      fileGroups: [],
      isError: true,
      error: {
        status: ServerResponses.NOT_FOUND,
      },
    };
  }

  const initialQuarterData = {
    availability_mw: 0,
    created_at: null,
    created_by_user_id: null,
    ddp_east_coast_port: null,
    id: null,
    module_pricing_id: null,
    quarter: 0,
    updated_at: null,
    year: 0,
  };

  const defaultChartData = new Array(MODULE_MAX_QUARTER_DATA_SHOWN)
    .fill(initialQuarterData)
    .map((q, index) => {
      const dateToFill = dayjs().add(index, 'quarter');
      return { ...q, quarter: dateToFill.quarter(), year: dateToFill.year() };
    });

  const [vendor, setVendor] = useState('');
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const [heroData, setHeroData] = useState<
    Array<{ label: string; value: string | number }>
  >([]);
  const [moduleDetails, setModuleDetails] = useState<
    Partial<ModuleTypePermissions>
  >({});
  const [chartData, setChartData] = useState<
    Array<{ id: string; label: string; value: number; count: number }>
  >(processChartData(defaultChartData));

  const { isFetching, isLoading, isSuccess, data, isError, error } =
    useGetModuleQuery({ uuid: params.uuid });

  const [fileGroups, setFileGroups] = useState<any>({
    moduleDatasheet: {},
    anzaPanFile: {},
    thirdPartyPanFile: {},
    manufacturerPanFile: {},
    anzaPanFileSupportingFiles: [],
    anzaPanFileSupportingFilesZip: {},
    thirdPartyPanFileSupportingFiles: [],
    thirdPartyPanFileSupportingFilesZip: {},
    manufacturerPanFileSupportingFiles: [],
    manufacturerPanFileSupportingFilesZip: {},
  });

  useEffect(() => {
    if (data) {
      const {
        updated_at,
        name,
        first_quarter_available_for_delivery,
        watts,
        efficiency,
        is_bifacial,
        company,
        quarters,
        files,
        dataSheetThumbnailUrl,
        ...rest
      } = data;

      files.forEach((file: ModuleFile) => {
        switch (file.file_type) {
          case fileTypes.MODULE_DATASHEET.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              moduleDatasheet: file,
            }));
            break;
          case fileTypes.ANZA_PAN_FILE.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              anzaPanFile: file,
            }));
            break;
          case fileTypes.THIRD_PARTY_PAN_FILE.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              thirdPartyPanFile: file,
            }));
            break;
          case fileTypes.MANUFACTURER_PAN_FILE.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              manufacturerPanFile: file,
            }));
            break;
          case fileTypes.ANZA_PAN_FILE_SUPPORTING_FILE.type:
            setFileGroups((prev: ModuleDatabase) => ({
              ...prev,
              anzaPanFileSupportingFiles: [
                ...prev.anzaPanFileSupportingFiles,
                { ...file, name: file.file_title, fileId: file.uuid },
              ],
            }));
            break;
          case fileTypes.THIRD_PARTY_PAN_FILE_SUPPORTING_FILE.type:
            setFileGroups((prev: ModuleDatabase) => ({
              ...prev,
              thirdPartyPanFileSupportingFiles: [
                ...prev.thirdPartyPanFileSupportingFiles,
                { ...file, name: file.file_title, fileId: file.uuid },
              ],
            }));
            break;
          case fileTypes.MANUFACTURER_PAN_FILE_SUPPORTING_FILE.type:
            setFileGroups((prev: ModuleDatabase) => ({
              ...prev,
              manufacturerPanFileSupportingFiles: [
                ...prev.manufacturerPanFileSupportingFiles,
                { ...file, name: file.file_title, fileId: file.uuid },
              ],
            }));
            break;
          case fileTypes.ANZA_PAN_SUPPORTING_FILES_ZIP.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              anzaPanFileSupportingFilesZip: file,
            }));
            break;
          case fileTypes.THIRD_PARTY_PAN_SUPPORTING_FILES_ZIP.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              thirdPartyPanFileSupportingFilesZip: file,
            }));
            break;
          case fileTypes.MANUFACTURER_PAN_SUPPORTING_FILES_ZIP.type:
            setFileGroups((prev: ModuleFile) => ({
              ...prev,
              manufacturerPanFileSupportingFilesZip: file,
            }));
            break;
          default:
            break;
        }
      });

      setVendor(company?.name);
      setModuleName(name);
      setLastUpdated(dayjs(updated_at).format('MM/DD/YYYY'));
      setModuleDetails(rest);
      setThumbnailImageUrl(dataSheetThumbnailUrl);
      setHeroData([
        {
          label: 'Watt Class',
          value: watts ? `${watts} W` : 'N/A',
        },
        {
          label: 'Module Efficiency',
          value: efficiency ? `${(+efficiency * 100).toFixed(2)}%` : 'N/A',
        },
        {
          label: 'Bifacial or Monofacial',
          value: is_bifacial ? 'Bifacial' : 'Monofacial',
        },
      ]);
      setChartData(
        setAndProcessChartData(
          defaultChartData,
          quarters as unknown as ChartData
        )
      );
    }
  }, [data]);

  return {
    vendor,
    moduleName,
    lastUpdated,
    heroData,
    moduleDetails,
    data,
    chartData,
    isError,
    error,
    isFetching,
    isLoading,
    isSuccess,
    fileGroups,
    thumbnailImageUrl,
  };
};
