import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Input as InputAnt } from 'antd';
import { InputProps as InputAntProps } from 'antd/lib/input';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { theme } from '../utils';

export interface InputProps extends InputAntProps {
  hint?: string;
}

const InputCSS = css`
  outline: none;
  font-size: 14px;
`;

const StyledInput = styled(InputAnt)<InputAntProps>`
  ${InputCSS}
`;

const StyledTextArea = styled(InputAnt.TextArea)<InputAntProps>`
  ${InputCSS}
`;

const StyledInputPassword = styled(InputAnt.Password)<InputAntProps>`
  ${InputCSS}
`;

const HintElement = styled.span`
  position: absolute;
  right: 16px;
  bottom: 28px;
  font-size: 10px;
  color: ${theme.fonts.colors.graphite};
`;

const Input: React.FC<InputProps> = ({ size = 'large', hint, ...props }) => {
  return (
    <>
      <StyledInput size={size} {...props} />
      {hint && <HintElement>{hint || 'Read-only value'}</HintElement>}
    </>
  );
};

interface InputMultineProps {
  size?: SizeType;
  hint?: string;
  name?: string;
  placeholder: string;
  rows: number;
  style: React.CSSProperties;
}

export const InputMultine = ({
  size = 'large',
  hint,
  ...props
}: InputMultineProps) => {
  return (
    <>
      <StyledTextArea size={size} {...props} />
      {hint && <HintElement>{hint || 'Read-only value'}</HintElement>}
    </>
  );
};

export const InputPassword: React.FC<InputProps> = ({
  size = 'large',
  ...props
}) => <StyledInputPassword size={size} {...props} />;

export default Input;
