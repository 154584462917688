import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import useAuth from '../hooks/useAuth';
import { OverlayLoader } from '../UI/Overlay';
import { ROUTER_PATHS } from './Paths';
import Page from './Page';
import { BackHandlerFunction } from './routes';
import { ROLES } from '~/utils';

interface PrivateRouteParams extends RouteProps {
  roles?: Array<(typeof ROLES)[keyof typeof ROLES]>;
  title?: string;
  layout?: React.FC;
  component: React.FC<any>;
  contentBackHandler?: BackHandlerFunction;
  navbarBackHandler?: BackHandlerFunction;
  hideNavBarMainItems?: boolean;
  hideNavBarSecondaryItems?: boolean;
  hideNavBarRightMenuItems?: boolean;
  gtmId?: string;
}

const PrivateRoute: React.FC<PrivateRouteParams> = ({
  component: Component,
  layout: Layout = React.Fragment,
  roles = [],
  title,
  contentBackHandler,
  navbarBackHandler,
  hideNavBarMainItems,
  hideNavBarSecondaryItems,
  hideNavBarRightMenuItems,
  gtmId,
  ...rest
}) => {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();

  const userHasRequiredRole = () =>
    user?.auth0_roles?.some((role: string) => roles.includes(role));

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isAuthenticated) {
          return null;
        }
        if (userHasRequiredRole()) {
          const layoutProps = {
            navbarBackHandlerData:
              navbarBackHandler && navbarBackHandler(user, location),
            contentBackHandlerData:
              contentBackHandler && contentBackHandler(user, location),
            hideNavBarMainItems,
            hideNavBarSecondaryItems,
            hideNavBarRightMenuItems,
            gtmId,
          };
          return (
            <Page title={title}>
              <Layout {...layoutProps}>
                <Component {...routeProps} />
              </Layout>
            </Page>
          );
        }
        return <Redirect to={ROUTER_PATHS.PAGE_NOT_FOUND()} />;
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  roles: [],
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <OverlayLoader />,
});
