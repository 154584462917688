import { Card } from 'antd';
import styled from '@emotion/styled';

export const StyledCard = styled(Card)`
  max-width: 1750px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 1rem;

  .ant-card-body {
    width: 100%;
  }
`;

export const StyledCardNoPadding = styled(StyledCard)`
  .ant-card-body {
    padding: 0;
    width: 100%;
  }
`;

export const StyledStackedCards = styled.div`
  max-width: 1750px;
  width: 100%;
  .ant-card {
    &:not(:last-child) {
      margin-bottom: 0;
      border-bottom-width: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`;

export default Card;
