import { useState } from 'react';
import * as R from 'ramda';

import { modulePricingGroupFormNamePrefix } from '~/components/SolarModules/ModulePricingFormGroup';

type ModulePricingGroupFormNamesPrefix =
  (typeof modulePricingGroupFormNamePrefix)[keyof typeof modulePricingGroupFormNamePrefix];

type RulesAccumulator = {
  priceKeys: string[];
  availabilityKeys: string[];
};

const getRelatedKey = (
  fieldType: ModulePricingGroupFormNamesPrefix,
  quarter: string
) => {
  switch (fieldType) {
    case modulePricingGroupFormNamePrefix.price:
      return `${modulePricingGroupFormNamePrefix.availability}${quarter}`;
    case modulePricingGroupFormNamePrefix.availability:
      return `${modulePricingGroupFormNamePrefix.price}${quarter}`;
    default:
      return modulePricingGroupFormNamePrefix.price;
  }
};

const getRelatedAccumulatorKey = (
  fieldType: ModulePricingGroupFormNamesPrefix
): keyof RulesAccumulator => {
  switch (fieldType) {
    case modulePricingGroupFormNamePrefix.price:
      return 'availabilityKeys';
    default:
      return 'priceKeys';
  }
};

const getAccumulatorKey = (
  fieldType: ModulePricingGroupFormNamesPrefix
): keyof RulesAccumulator => {
  switch (fieldType) {
    case modulePricingGroupFormNamePrefix.price:
      return 'priceKeys';
    default:
      return 'availabilityKeys';
  }
};

const getRequiredFormNames = (
  fieldType: ModulePricingGroupFormNamesPrefix,
  key: string,
  values: Record<string, unknown> | undefined | null,
  accumulator: RulesAccumulator
) => {
  if (typeof values !== 'object' || !values) return accumulator;
  if (!(key in values)) return accumulator;

  const currentQuarter = key.replace(fieldType, '');
  const relatedKey = getRelatedKey(fieldType, currentQuarter);
  if (!(relatedKey in values)) return accumulator;

  const relatedFieldValue = values[relatedKey];
  const fieldValue = values[key];
  if (relatedFieldValue === null && fieldValue !== null) {
    const accKey = getRelatedAccumulatorKey(fieldType);
    return {
      ...accumulator,
      [accKey]: [...accumulator[accKey], relatedKey],
    };
  }

  if (relatedFieldValue !== null && fieldValue === null) {
    const accFieldTypeKey = getAccumulatorKey(fieldType);
    return {
      ...accumulator,
      [accFieldTypeKey]: [...accumulator[accFieldTypeKey], key],
    };
  }
  return accumulator;
};

const requiredFormNamesCallback = (prev: string[], current: string[]) => {
  if (R.equals(current, prev)) {
    return prev;
  }
  return current;
};

const useModulePricingFormGroupRules = () => {
  const [priceRequiredFormNames, setPriceRequiredFormNames] = useState<
    string[]
  >([]);
  const [availabilityRequiredFormNames, setAvailabilityRequiredFormNames] =
    useState<string[]>([]);

  const onValuesChange = async (
    changedValues: unknown,
    values?: Record<string, unknown> | null
  ) => {
    if (typeof changedValues === 'object' && changedValues) {
      const { priceKeys, availabilityKeys } = Object.keys(
        values ?? {}
      ).reduce<RulesAccumulator>(
        (acc, key) => {
          if (key.includes(modulePricingGroupFormNamePrefix.price)) {
            return getRequiredFormNames(
              modulePricingGroupFormNamePrefix.price,
              key,
              values,
              acc
            );
          }
          if (key.includes(modulePricingGroupFormNamePrefix.availability)) {
            return getRequiredFormNames(
              modulePricingGroupFormNamePrefix.availability,
              key,
              values,
              acc
            );
          }
          return acc;
        },
        { priceKeys: [], availabilityKeys: [] }
      );
      setPriceRequiredFormNames((prev) =>
        requiredFormNamesCallback(prev, priceKeys)
      );
      setAvailabilityRequiredFormNames((prev) =>
        requiredFormNamesCallback(prev, availabilityKeys)
      );
    }
  };

  return {
    priceRequiredFormNames,
    availabilityRequiredFormNames,
    onValuesChange,
  };
};

export default useModulePricingFormGroupRules;
