import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import Modal from '~/UI/ModalAnt';
import { Form, FormItemLabel, InputNumber } from '~/UI';
import { getEscalationRate, requiredFieldRule, theme } from '~/utils';
import { SolarProject, TaxCredit } from '~/store/project';
import { inRangeValidator } from '~/utils/numberValidators';

type EnergyTermModalProps = {
  energyModalOpen: boolean;
  setEnergyModalOpen: (state: boolean) => void;
  updateEnergyTerms: (terms: TaxCredit[]) => void;
  terms: TaxCredit[];
  project: SolarProject;
  termToEdit?: TaxCredit;
  onCancel: () => void;
  currentProjectTermYears: number;
};
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledLabel = styled.div`
  font-size: 0.8rem;
  color: ${theme.colors.gray700};
  display: flex;
  justify-content: flex-end;
  margin-top: -24px;
  line-height: 0;
`;

export const EnergyTermModal = (props: EnergyTermModalProps) => {
  const {
    energyModalOpen,
    setEnergyModalOpen,
    updateEnergyTerms,
    terms,
    project,
    termToEdit,
    onCancel,
    currentProjectTermYears,
  } = props;

  const [form] = Form.useForm();
  const duration = Form.useWatch('duration', form);
  const startYear = Form.useWatch('start_year', form);

  useEffect(() => {
    if (R.is(Number, duration)) form.validateFields(['start_year']);
  }, [duration]);
  useEffect(() => {
    if (R.is(Number, startYear)) form.validateFields(['duration']);
  }, [startYear]);

  const editMode = !!termToEdit;

  const projectYear = new Date(
    project.project_details.arrival_date
  ).getFullYear();

  const update = (edit = false) => {
    form.validateFields().then((values) => {
      const newValue: TaxCredit = {
        ...values,
        credit_type: 'ENERGY_TERM',
        project_id: project.id,
        escalation_rate: values.escalation_rate / 100,
      };
      const currentState = edit
        ? terms.map((item) => (item === termToEdit ? newValue : item))
        : [...terms, newValue];
      updateEnergyTerms(currentState);
      setEnergyModalOpen(false);
    });
  };

  const getStartingYear = () => {
    if (startYear && startYear > 0) {
      return `Energy Term Start Year: ${Math.round(projectYear + startYear)}`;
    }
    return null;
  };
  const getEndingDate = () => {
    if (startYear && duration)
      return `Energy Term End Year: ${Math.round(
        projectYear + startYear + duration
      )}`;
    return null;
  };

  return (
    <Modal
      destroyOnClose
      title={
        <h3>
          <b>Add Energy Term</b>
        </h3>
      }
      open={energyModalOpen}
      afterClose={() => setEnergyModalOpen(false)}
      closable
      onCancel={onCancel}
      okText={editMode ? 'Save' : 'Add'}
      onOk={() => update(editMode)}
    >
      <StyledForm form={form} preserve={false}>
        <Form.Item
          name="base_credit"
          label={<FormItemLabel>Energy Value / PPA Rate</FormItemLabel>}
          labelCol={{ span: 24 }}
          required={false}
          initialValue={editMode && termToEdit.base_credit}
          rules={[
            requiredFieldRule,
            {
              validator: inRangeValidator(
                0,
                1,
                true,
                'Please enter a value between 0-1 $ / kwh',
                true
              ).validator,
            },
          ]}
        >
          <InputNumber
            name="base_credit"
            data-testid="base_credit"
            precision={4}
            displayPrecision={4}
            suffix="$ / kWh"
          />
        </Form.Item>
        <Form.Item
          name="escalation_rate"
          label={<FormItemLabel>Escalation Rate</FormItemLabel>}
          labelCol={{ span: 24 }}
          required={false}
          initialValue={editMode && getEscalationRate(termToEdit)}
          rules={[
            requiredFieldRule,
            {
              validator: inRangeValidator(
                0,
                30,
                true,
                'Please enter a value between 0-30%',
                true
              ).validator,
            },
          ]}
        >
          <InputNumber
            name="escalation_rate"
            data-testid="escalation_rate"
            precision={2}
            displayPrecision={2}
            suffix="%"
          />
        </Form.Item>
        <Form.Item
          name="start_year"
          label={<FormItemLabel>Start Year</FormItemLabel>}
          labelCol={{ span: 24 }}
          required={false}
          initialValue={
            termToEdit && termToEdit.start_year && termToEdit.start_year
          }
          rules={[
            requiredFieldRule,
            {
              type: 'integer',
              message: 'No decimals allowed',
            },
            {
              validator: (_, value: number) => {
                if (value + duration > currentProjectTermYears + 1) {
                  return Promise.reject(
                    new Error('Duration cannot exceed project term.')
                  );
                }
                return Promise.resolve();
              },
            },
            {
              validator: inRangeValidator(
                1,
                currentProjectTermYears,
                true,
                `Please enter a value between 1-${currentProjectTermYears}`,
                true,
                true
              ).validator,
            },
          ]}
          validateTrigger="onChange"
        >
          <InputNumber
            name="duration"
            data-testid="duration"
            precision={3}
            suffix="Year"
          />
        </Form.Item>
        <StyledLabel>{getStartingYear()}</StyledLabel>
        <Form.Item
          name="duration"
          label={<FormItemLabel>Duration</FormItemLabel>}
          labelCol={{ span: 24 }}
          required={false}
          initialValue={editMode && termToEdit.duration}
          rules={[
            requiredFieldRule,
            {
              type: 'integer',
              message: 'No decimals allowed',
            },
            {
              validator: (_, value: number) => {
                if (value + startYear > currentProjectTermYears + 1) {
                  return Promise.reject(
                    new Error('Duration cannot exceed project term.')
                  );
                }
                return Promise.resolve();
              },
            },
            {
              validator: inRangeValidator(
                1,
                currentProjectTermYears,
                true,
                `Please enter a value between 1-${currentProjectTermYears}`,
                true,
                true
              ).validator,
            },
          ]}
          validateTrigger="onChange"
        >
          <InputNumber
            name="duration"
            data-testid="duration"
            precision={3}
            suffix={duration === 1 ? 'Year' : 'Years'}
          />
        </Form.Item>
        <StyledLabel>{getEndingDate()}</StyledLabel>
      </StyledForm>
    </Modal>
  );
};
