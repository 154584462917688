export const introSolarProposal = {
  effectivePrice: {
    title: 'Effective $/W',
    content:
      'The Anza Effective $/W is the way you can easily compare modules based on value so that your module selection maximizes project profits. We calculate this value by starting with the module Purchase Price and then subtracting BOS Savings and Production Benefit. The lower the Effective $/W, the more value you create for your project.',
    step: 1,
    position: 'bottom-right-aligned',
  },
  purchasePrice: {
    title: 'Purchase Price',
    content:
      'Purchase Price is the sales price of the module, including estimated delivery to the project site and Anza fees, excluding sales tax.',
    step: 2,
  },
  availability: {
    title: 'Availability',
    content:
      'Availability is the megawatts (MW) of a specific module that is available for your project during the selected delivery quarter.',
    step: 3,
  },
  editProjectDetails: {
    title: 'Edit Project Details',
    content:
      'Clicking on the pencil icon allows you to edit project details such as the system size, racking type, and delivery date. Dial in the analysis by adjusting assumptions such as the PPA Rate in the Advanced Inputs section.',
    step: 5,
    position: 'bottom-right-aligned',
  },
  filters: {
    title: 'Filters',
    content:
      "Use filters to curate a list of modules that meet your project's requirements.",
    step: 6,
  },
  deliveryDateTool: {
    title: 'Delivery Date Tool',
    content:
      'Use the Delivery Date Tool to see module pricing for adjacent delivery quarters.',
    step: 7,
  },
  advancedView: {
    title: 'Advanced View',
    content:
      'Advanced View provides Engaged Customers full access to Anza’s industry-leading, comprehensive data set as well as the ability to easily sort and filter modules based on your specific preferences.',
    step: 8,
  },
} as const;
