import React, { ReactNode, useEffect, useReducer, useState } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';
import * as H from 'history';
import { Table } from 'antd';
import type { TablePaginationConfig } from 'antd/lib/table';
import {
  ColumnGroupType,
  FilterValue,
  SorterResult,
} from 'antd/lib/table/interface';

import { Overlay, Spinner, ModalConfirm as Modal } from '~/UI';
import SearchFilterDropdown, {
  type Filter,
} from '~/components/Tables/SearchFilterDropdown';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { type PaginatedQueryDataItem, rtkService } from '~/types/rtkApi';
import {
  type InitialState,
  paramsReducer,
  toApiParams,
  queryStringToParams,
} from '~/utils';
import type { TableProps } from '~/UI/Table';
import Button from '~/UI/ButtonAnt';
import { IconRemoveFilters } from '~/UI/Icons';
import AdminTableActionColumn, {
  CustomActions,
} from './AdminTableActionColumn';

export type Column<T = unknown> = Omit<ColumnGroupType<T>, 'children'> & {
  title: string;
  key: string;
  isFilterable?: boolean;
  isSortable?: boolean;
  defaultSortOrder?: string;
  filters?: Array<Filter>;
  dataIndex: string;
};

const StyledTable = styled(Table)`
  .ant-table-row {
    ${(props) => props.onRow && `cursor: pointer;`}
  }
`;

const StyledResetButton = styled(Button)`
  margin-bottom: 15px;
`;

const dataTestIdConfig: TestIdProps = {
  component: TEST_DATA_COMPONENTS.TABLE,
};

const initialSorter = {
  sortField: 'updated_at',
  sortOrder: 'descend',
};

const getInitialSorter = (columns: Column<PaginatedQueryDataItem>[]) => {
  let sorter = initialSorter;
  columns.forEach((col) => {
    if (col.defaultSortOrder) {
      sorter = { sortField: col.key, sortOrder: col.defaultSortOrder };
    }
  });
  return sorter;
};

const AdminTableServerPagination = ({
  refresh,
  columns,
  history,
  service,
  customActions,
  onRow,
  testIdPage = '',
  testIdCategory = '',
  defaultRowKey = 'id',
}: {
  refresh?: number;
  customActions?: CustomActions<PaginatedQueryDataItem>;
  columns: Column<PaginatedQueryDataItem>[];
  history: H.History;
  service: rtkService;
  onRow?: TableProps['onRow'];
  testIdPage?: string;
  testIdCategory?: string;
  defaultRowKey?: string;
}) => {
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    searchInput: null,
  });
  const [modalVisibleId, setModalVisibleId] = useState<number | string>(0);

  const defaultParams = {
    filters: {},
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    sorter: getInitialSorter(columns),
  };

  const parsedParamsFromUrl = queryStringToParams(history, defaultParams);
  const [params, dispatchParams] = useReducer(
    paramsReducer,
    parsedParamsFromUrl
  );

  const queryParams = toApiParams(params);

  const resetFiltersSortingAndPagination = () => {
    dispatchParams({
      data: defaultParams,
      type: 'reset',
    });
    history.push({
      pathname: history.location.pathname,
      search: `?${toApiParams(defaultParams).toString()}`,
    });
  };

  const {
    data = { items: [], count: 0 },
    isLoading,
    refetch: refetchData,
    isFetching,
  } = service?.paginatedQuery?.(queryParams) ?? {};

  useEffect(() => {
    if (data) {
      history.replace({
        pathname: history.location.pathname,
        search: `?${queryParams.toString()}`,
      });
      dispatchParams({
        data: {
          pagination: { total: data.count, showSizeChanger: false },
        } as Partial<InitialState>,
        type: 'count',
      });
    }
  }, [isFetching, data.count, params.sorter, history.location.search]);

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    newFilters: Record<string, FilterValue | null>,
    newSorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const paramsMap: Record<string, any> = {};

    if (!R.isEmpty(newFilters)) {
      paramsMap.filters = newFilters;
    }

    if (!R.isEmpty(newPagination)) {
      paramsMap.pagination = newPagination;
    }

    if (!R.isEmpty(newSorter)) {
      const sorterObject = Array.isArray(newSorter) ? newSorter[0] : newSorter;
      const newSortOrder = sorterObject.order;
      const newSortField = sorterObject.field;
      if (newSortOrder) {
        paramsMap.sorter = {
          sortField: newSortField,
          sortOrder: newSortOrder,
        };
      } else {
        paramsMap.sorter = getInitialSorter(columns);
      }
    }

    if (!R.isEmpty(paramsMap)) {
      dispatchParams({
        data: paramsMap as Partial<InitialState>,
        type: 'update',
      });
    }
  };

  const [deleteItem] = service.delete ? service.delete() : [() => {}];

  useEffect(() => {
    if (!modalVisibleId) {
      refetchData?.();
    }
  }, [refresh, modalVisibleId]);

  dataTestIdConfig.category = testIdCategory;
  dataTestIdConfig.page = testIdPage;

  const handleReset = () => {
    setState({ searchText: '', searchedColumn: '', searchInput: null });
  };

  const customColumns = columns.map((column) => {
    let customColumn: any = R.pick(
      [
        'title',
        'dataIndex',
        'key',
        'width',
        'render',
        'fixed',
        'className',
        'filters',
      ],
      column
    );
    const {
      isSortable,
      isFilterable,
      dataIndex,
      defaultSortOrder,
      filters,
      sorter,
    } = column;
    if (isSortable) {
      if (params?.sorter.sortField === column.key) {
        customColumn.defaultSortOrder = params?.sorter.sortOrder;
      } else if (defaultSortOrder) {
        customColumn.defaultSortOrder = defaultSortOrder;
      }

      customColumn.sorter = sorter || null;
    }
    if (isFilterable) {
      customColumn = {
        ...customColumn,
        ...SearchFilterDropdown(
          { dataIndex: `${dataIndex}`, filters },
          {
            state,
            setState,
            handleReset,
          },
          customColumn.render ? customColumn.render : null
        ),
      };
    } else {
      customColumn.onFilter = column.onFilter;
    }

    customColumn.filteredValue = params?.filters[dataIndex] || [];

    return customColumn;
  });

  const handleCancelDelete = () => setModalVisibleId(0);

  const handleOk = async () => {
    await deleteItem({ id: modalVisibleId });
    setModalVisibleId(0);
  };

  if (!customActions || !R.isEmpty(customActions)) {
    customColumns.push(
      AdminTableActionColumn({
        customActions,
        onDelete: (id) => setModalVisibleId(id),
        testIdCategory: dataTestIdConfig.category,
        testIdPage: dataTestIdConfig.page,
        defaultRowKey,
      })
    );
  }

  const renderPaginationItem = (
    page: number,
    type: string,
    originalElement: ReactNode
  ) => {
    const dataTestIdPaginationConfig = {
      ...dataTestIdConfig,
      component: TEST_DATA_COMPONENTS.PAGINATION,
      identifier: type,
    };
    dataTestIdPaginationConfig.indices = type === 'page' ? `${page}` : '';

    return (
      <div data-testid={generateTestId(dataTestIdPaginationConfig)}>
        {originalElement}
      </div>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      {isLoading && (
        <Overlay style={{ position: 'absolute', zIndex: 9999 }}>
          <Spinner />
        </Overlay>
      )}
      <StyledResetButton
        onClick={resetFiltersSortingAndPagination}
        size="small"
      >
        <IconRemoveFilters size={18} style={{ margin: '0 5px' }} />
        Reset all filtering
      </StyledResetButton>
      <StyledTable
        rowKey={defaultRowKey}
        data-testid={generateTestId(dataTestIdConfig)}
        columns={customColumns}
        dataSource={data.items}
        scroll={{ x: 1000, y: 768 }}
        onChange={handleTableChange}
        pagination={{ ...params.pagination, itemRender: renderPaginationItem }}
        onRow={onRow}
        loading={isLoading || isFetching}
      />
      <Modal
        title="Are you sure you want to delete this item?"
        open={Boolean(modalVisibleId)}
        onOk={handleOk}
        okButtonProps={{ type: 'primary', size: 'small' }}
        cancelButtonProps={{ size: 'small' }}
        onCancel={handleCancelDelete}
        confirmLoading={isLoading}
      >
        <p>
          Once you do it, all the information related to this item might be
          lost.
        </p>
      </Modal>
    </div>
  );
};

AdminTableServerPagination.defaultProps = {
  refresh: 0,
};

export default AdminTableServerPagination;
