export const isNumeric = (num: number | string): boolean =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !Number.isNaN(Number(num)) &&
  !/[^-.\d]/.test(num.toString()); // this guards against strings like "12m"

const handleIsRequiredNumberValidation = (
  isRequired: boolean,
  value: number
): boolean => !isRequired && (typeof value === undefined || value == null);

export const inRangeValidator = (
  min: number,
  max: number,
  inclusiveOfMinMax = true,
  errorMessage?: string,
  isRequired = true,
  isInteger = false
) => {
  return {
    validator: (_: unknown, value: number) => {
      if (handleIsRequiredNumberValidation(isRequired, value)) {
        return Promise.resolve();
      }
      if (isInteger && !Number.isInteger(value)) {
        return Promise.reject(
          new Error(errorMessage ?? `Please enter an integer`)
        );
      }

      const outOfRange = inclusiveOfMinMax
        ? value < min || value > max
        : value <= min || value >= max;
      if (!isNumeric(value) || outOfRange) {
        return Promise.reject(
          new Error(
            errorMessage ?? `Please enter a number between ${min} and ${max}`
          )
        );
      }
      return Promise.resolve();
    },
  };
};

interface IDiscontinuousRangeValidator {
  range1: [min: number, max: number];
  range2: [min: number, max: number];
  inclusive: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  isInteger?: boolean;
}
export const discontinuousRangeValidator = ({
  range1,
  range2,
  inclusive = true,
  errorMessage,
  isRequired = true,
  isInteger = false,
}: IDiscontinuousRangeValidator) => {
  return {
    validator: (_: unknown, value: number) => {
      if (handleIsRequiredNumberValidation(isRequired, value)) {
        return Promise.resolve();
      }
      if (isInteger && !Number.isInteger(value)) {
        return Promise.reject(
          new Error(errorMessage ?? `Please enter an integer`)
        );
      }

      const outOfRange =
        (inclusive
          ? value < range1[0] || value > range1[1]
          : value <= range1[0] || value >= range1[1]) &&
        (inclusive
          ? value < range2[0] || value > range2[1]
          : value <= range2[0] || value >= range2[1]);
      if (!isNumeric(value) || outOfRange) {
        return Promise.reject(
          new Error(
            errorMessage ??
              `Please enter a number between ${range1[0]} and ${range1[1]} or ${range2[0]} and ${range2[1]}`
          )
        );
      }
      return Promise.resolve();
    },
  };
};

export const inRangeOrEmptyValidator = (
  min: number,
  max: number,
  inclusiveOfMinMax = true,
  errorMessage?: string
) => {
  return inRangeValidator(min, max, inclusiveOfMinMax, errorMessage, false);
};

export const greaterThanValidator = (
  min: number,
  inclusiveOfMin = true,
  errorMessage?: string,
  isRequired = true
) => {
  return {
    validator: (_: unknown, value: number) => {
      if (handleIsRequiredNumberValidation(isRequired, value)) {
        return Promise.resolve();
      }

      return (isRequired && !isNumeric(value)) ||
        (inclusiveOfMin ? value < min : value <= min)
        ? Promise.reject(
            new Error(
              errorMessage ?? `Please enter a number greater than ${min}`
            )
          )
        : Promise.resolve();
    },
  };
};

export const lesserThanValidator = (
  max: number,
  inclusiveOfMax = true,
  errorMessage?: string,
  isRequired = true
) => {
  return {
    validator: (_: unknown, value: number) => {
      if (handleIsRequiredNumberValidation(isRequired, value)) {
        return Promise.resolve();
      }

      return (isRequired && !isNumeric(value)) ||
        (inclusiveOfMax ? value > max : value >= max)
        ? Promise.reject(
            new Error(
              errorMessage ?? `Please enter a number smaller than ${max}`
            )
          )
        : Promise.resolve();
    },
  };
};

export const integerValidator = (errorMessage?: string) => {
  return {
    validator: (_: unknown, value: number) =>
      !Number.isInteger(Number(value))
        ? Promise.reject(
            new Error(errorMessage ?? 'Please enter a number with no decimals')
          )
        : Promise.resolve(),
  };
};
