import * as R from 'ramda';
import { stringToBoolean } from '~/utils/string';
import type { AnalyticsSchema } from '~/store/project';

export const getFilteredSchemas = (data: Record<string, AnalyticsSchema>) =>
  R.filter(
    ({ include_in_analysis_view, is_advanced_view_data }) =>
      is_advanced_view_data ?? stringToBoolean(include_in_analysis_view),
    data || []
  );
