import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Popover as PopoverAnt, PopoverProps as PopoverAntProps } from 'antd';
import * as Typography from '~/UI/Typography';

export type PopoverProps = PopoverAntProps;

const StyledTitle = styled.div`
  h3 {
    font-weight: 600;
  }
  margin-bottom: 0.5rem;
`;

const PopoverContent = styled.div`
  position: relative;
`;

const StyledPopover = styled(PopoverAnt)`
  .ant-popover-inner-content {
    padding: 20px !important;
  }
`;

const Popover: React.FC<PopoverProps> = ({ content, ...props }) => {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    if (typeof props.open !== 'undefined') {
      setOpen(props.open);
    }
  }, [props.open]);

  const onOpenChange = (popoverVisible: boolean) => setOpen(popoverVisible);

  return (
    <StyledPopover
      overlayInnerStyle={{ border: 0 }}
      content={
        <PopoverContent>
          {props.title && (
            <StyledTitle>
              <Typography.BoxH3>{props.title}</Typography.BoxH3>
            </StyledTitle>
          )}
          <Typography.BoxH3>{content}</Typography.BoxH3>
        </PopoverContent>
      }
      {...props}
      open={open}
      title={null}
      onOpenChange={onOpenChange}
    />
  );
};

export default Popover;
