export const ANZA_ENGINE_STATES = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  error: 'error',
} as const;

export const ANZA_ENGINE_STEP_STATES = {
  COMPLETE: 'complete',
  ERROR: 'error',
  GENESIS: 'genesis',
  REQUESTED: 'requested',
} as const;

// Status from new tables solar_engine_runs and storage_engine_runs
export const ANZA_ENGINE_RUN_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  NONE: 'NONE' // no engine run found
} as  const