import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import {
  getProjectTopRankings,
  TopRankingsProject,
  TopRankingsModule,
} from '~/services/api/anza';
import {
  Table,
  Overlay,
  Spinner,
  PersonnelInfoBanner,
  TooltipWithEllipsis,
  ModuleLink,
} from '~/UI';
import useAuth from '~/hooks/useAuth';
import {
  theme,
  ProjectStatuses,
  decimalFormatter,
  getAccountManagerInfo,
  generateTitlePopover,
  formatQuarterYearLabel,
  catchError,
  handle404Error,
  formatPrice,
  toTitleCase,
} from '~/utils';
import { PageHeader, PageDescription } from '~/UI/Typography';
import { StyledStackedCards } from '~/UI/Card';
import CollapsibleHeroModule from '~/UI/CollapsibleHeroModule';
import { ROUTER_PATHS } from '~/router/Paths';
import { useFlags } from '~/utils/launchdarkly';

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-thead > tr:first-of-type > th.ant-table-cell {
    text-align: left;
    font-weight: 700;
    padding: 30px;
  }

  .ant-table-tbody > tr > td {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-thead > tr > th:first-of-type,
  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 30px;
  }

  .ant-table-thead > tr > th:last-of-type,
  .ant-table-tbody > tr > td:last-of-type {
    padding-right: 30px;
  }

  .ant-table-thead > tr:first-of-type > th.divider {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: #ffffff;
  }

  .on-row-hover {
    display: none;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #eeeff0;
  }

  .ant-table-cell-row-hover .on-row-hover {
    display: flex;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #ffffff;
  }

  .divider {
    border-left: 2px solid ${theme.colors.lightGray};
  }
`;

const NormalText = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: ${theme.colors.charcoal};
`;

const SmallerText = styled(NormalText)`
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${theme.colors.charcoal};
`;

const HeroContainer = styled.div`
  margin: 2rem 0;
`;

const renderPrice = (val: null | undefined | string | number) => {
  return val === null || val === undefined ? '-' : formatPrice(val);
};

const sortModules = (a: TopRankingsModule, b: TopRankingsModule) => {
  if (a.rank !== null && b.rank !== null) {
    return a.rank - b.rank;
  }
  if (a.rank === null && b.rank !== null) {
    return 1;
  }
  if (b.rank === null && a.rank !== null) {
    return -1;
  }
  return 0;
};

const rankDiscountColumnWidth = 75;

const VendorPricing = () => {
  const { user } = useAuth();

  const { vendorId, projectId } = useParams<{
    vendorId: string;
    projectId: string;
  }>();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<TopRankingsProject>();
  const [modules, setModules] = useState<TopRankingsModule[]>();
  const [lastRankingDate, setLastRankingDate] = useState<Date>();

  const { newProjectFlow } = useFlags();

  const handleGetData = async (vendorUuid: string, projectUuid: string) => {
    try {
      setLoading(true);
      const response =
        await getProjectTopRankings(projectUuid, vendorUuid, newProjectFlow);

      const { data } = response;
      setLastRankingDate(data.data.lastRankingAt);
      setProject(data.data.project);
      setModules(data.data.modules.sort(sortModules));
    } catch (error) {
      catchError({
        error,
        method: 'handleGetData',
        location: 'pages/anza/vendorProjectPricing',
      });
      handle404Error(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (vendorId && projectId) {
      handleGetData(vendorId, projectId);
    }
  }, [vendorId, projectId]);

  if (!project) {
    return <></>;
  }
  const {
    deliveryYear,
    deliveryQuarter,
    sizeKw,
    region,
    port,
    shipping,
    rackingType,
    status,
  } = project;

  const projectPropertiesToDisplay = [
    {
      label: 'System Size (kW DC)',
      value: decimalFormatter(sizeKw).toString(),
    },
    {
      label: 'Delivery Quarter',
      value: toTitleCase(`Q${deliveryQuarter} ${deliveryYear}`),
    },
    {
      label: 'Region / Port',
      value: toTitleCase(region === port ? region : `${region} / ${port ?? 'N/A'}`),
    },
    {
      label: 'Land Shipping Cost',
      value: toTitleCase(shipping ?? 'N/A'),
    },
    {
      label: 'Racking Type',
      value: toTitleCase(rackingType),
    },
    ...(
      newProjectFlow
        ? []
        : [{
          label: 'Status',
          value: toTitleCase(status ?? ''),
        }]
    )
  ];

  const columns = [
    {
      title: 'Your Modules',
      children: [
        {
          title: 'Module',
          dataIndex: 'name',
          key: 'name',
          width: 400,
          isSortable: true,
          render: (text: string, record: TopRankingsModule) => (
            <TooltipWithEllipsis
              id={text}
              text={text}
              component={
                <ModuleLink
                  path={ROUTER_PATHS.modules.MODULE_DETAILS(record.uuid)}
                  moduleName={text}
                />
              }
            />
          ),
        },
        {
          title: generateTitlePopover(
            'Price ($/W)',
            `Delivered Duty Paid (DDP) East Coast port price for ${formatQuarterYearLabel(
              deliveryQuarter,
              deliveryYear
            )}.`
          ),
          dataIndex: 'price',
          key: 'price',
          render: (val: string) => formatPrice(val) || '-',
          width: 200,
        },
        {
          title: generateTitlePopover(
            'Port Adjustment ($/W)',
            `Port adjustment for modules in the ${port} Coast Port.`
          ),
          dataIndex: 'portAdder',
          key: 'portAdder',
          render: (val: string) => formatPrice(val) || 'N/A',
          width: 250,
        },
        {
          title: generateTitlePopover(
            'Land Shipping Cost ($/W)',
            `Cost adjustment for ${shipping} land shipping.`
          ),
          dataIndex: 'shippingAdder',
          key: 'shippingAdder',
          render: (val: string) => formatPrice(val) || 'N/A',
          width: 250,
        },
        {
          title: generateTitlePopover(
            'Rank',
            "Module's current rank for this project.",
            { justifyContent: 'center' }
          ),
          dataIndex: 'rank',
          key: 'rank',
          align: 'center',
          width: 100,
          render: (val: string) => val || '-',
        },
      ],
    },
    {
      title: generateTitlePopover(
        'Discount Needed to Reach Rank ($/W)',
        "Lowering your price by the indicated amount will bring your module to the rank indicated on this project's Ranking Table.",
        { justifyContent: 'center' }
      ),
      className: 'divider',
      children: [
        {
          title: '1',
          dataIndex: ['discountNeededToGetRank', '1'],
          key: 'discountNeededToGetRank1',
          render: renderPrice,
          align: 'center',
          className: 'divider',
          width: rankDiscountColumnWidth,
        },
        {
          title: '2',
          dataIndex: ['discountNeededToGetRank', '2'],
          key: 'discountNeededToGetRank2',
          render: renderPrice,
          align: 'center',
          width: rankDiscountColumnWidth,
        },
        {
          title: '3',
          dataIndex: ['discountNeededToGetRank', '3'],
          key: 'discountNeededToGetRank3',
          render: renderPrice,
          align: 'center',
          width: rankDiscountColumnWidth,
        },
        {
          title: '4',
          dataIndex: ['discountNeededToGetRank', '4'],
          key: 'discountNeededToGetRank4',
          render: renderPrice,
          align: 'center',
          width: rankDiscountColumnWidth,
        },
        {
          title: '5',
          dataIndex: ['discountNeededToGetRank', '5'],
          key: 'discountNeededToGetRank5',
          render: renderPrice,
          align: 'center',
          width: rankDiscountColumnWidth,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        paddingBottom: '2rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
      }}
    >
      {loading && (
        <Overlay style={{ position: 'absolute', zIndex: 9999 }}>
          <Spinner />
        </Overlay>
      )}
      {!loading && (
        <>
          <StyledStackedCards>
            <HeroContainer>
              <PageHeader>Project Pricing Analysis</PageHeader>
              <PageDescription>
                Estimated price required to reach rank for this project.
              </PageDescription>
            </HeroContainer>
            <HeroContainer>
              <CollapsibleHeroModule
                items={projectPropertiesToDisplay}
                showToggle={false}
              />
            </HeroContainer>
          </StyledStackedCards>
          <div style={{ width: '100%' }}>
            {project.status !== ProjectStatuses.CREATED ? (
              <>
                <StyledTable
                  columns={columns}
                  rowKey="uuid"
                  dataSource={modules}
                  pagination={false}
                />
                <SmallerText
                  style={{
                    marginTop: '12px',
                    textAlign: 'right',
                    whiteSpace: 'pre-line',
                    lineHeight: '20px',
                    color: theme.colors.stone,
                  }}
                >
                  {lastRankingDate &&
                    `Data is based on last reranking on ${dayjs(
                      lastRankingDate,
                      'YYYY/MM/DD'
                    ).format(
                      'MMM D [at] LT'
                    )}.\n Price changes may take up to 24 hours to be reflected in this project’s rankings.\n`}
                  Modules might not be ranked due to availability or other
                  factors.
                </SmallerText>
              </>
            ) : (
              <NormalText style={{ textAlign: 'center' }}>
                This project has not yet been ranked. Check back later to see
                how your modules compare.
              </NormalText>
            )}
            <div style={{ marginTop: '12px' }}>
              <PersonnelInfoBanner
                title="Questions about your rankings?"
                personnelInfo={getAccountManagerInfo(user)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VendorPricing;
