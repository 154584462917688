import { ascend, sortWith, values, tail, isEmpty } from 'ramda';

import { EngineResultSizingArchitecture } from '~/store/project';

export type ModuleStorageCalculus<T> = (
  module: EngineResultSizingArchitecture,
  moduleList: EngineResultSizingArchitecture[]
) => T;

export const AugmentationStrategiesText: Record<string, string> = {
  ac_augmentation: 'AC Augmentation',
  dc_augmentation: 'DC Augmentation',
  overbuild: 'Overbuild',
};

export const findLowestLifecycleCostSchedule = (
  module: EngineResultSizingArchitecture
) => {
  const schedules = values(module.augmentation_data);
  const sortedSchedules = sortWith(
    [ascend((s) => s.weighted_life_cycle_cost_USD)],
    schedules
  );
  return sortedSchedules[0];
};

const findLowestLifecycleCostModule = (
  modules: EngineResultSizingArchitecture[]
) =>
  sortWith(
    [
      ascend(
        (module) =>
          findLowestLifecycleCostSchedule(module).weighted_life_cycle_cost_USD
      ),
    ],
    modules
  )[0];

const findLowestTotalPriceModule = (
  modules: EngineResultSizingArchitecture[]
) =>
  sortWith(
    [
      ascend(
        (module) =>
          findLowestLifecycleCostSchedule(module).beginning_of_life_capex_USD
      ),
    ],
    modules
  )[0];

export const calcualteCapex: ModuleStorageCalculus<number> = (module) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).beginning_of_life_capex_USD;
};

export const calcualtePreventativeMaintenance: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module)
        .preventative_maintenance_net_present_value_USD;
};

export const calcualteProductAndCapacityWarranty: ModuleStorageCalculus<
  number
> = (module) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).warranty_net_present_value_USD;
};

export const calcualteAugmentationCapex: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).augmentation_capex_NPV_USD;
};

export const calculateLifecycleCost: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).weighted_life_cycle_cost_USD;
};

export const calculateDcBlockCount: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).beginning_of_life_dc_block_count;
};

export const calculateNameplateEnergy: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module)
        .beginning_of_life_nameplate_energy_kWh;
};

export const calculateAcUsableEnergy: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module)
        .beginning_of_life_useable_energy_kWh;
};

export const calculateSystemPower: ModuleStorageCalculus<number> = (module) => {
  if (!module.augmentation_data) return 0;
  const value = findLowestLifecycleCostSchedule(module).system_power_kVA;
  if (Array.isArray(value) && value.length > 0) return value[0];
  if (Array.isArray(value) && value.length === 0) return 0;
  if (typeof value === 'number') return value;
  return 0;
};

export const calcualteBolDcBlock: ModuleStorageCalculus<number> = (module) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).beginning_of_life_dc_block_count;
};

export const calculateOptimalAugmentationYears: ModuleStorageCalculus<
  number[]
> = (module) => {
  return !module.augmentation_data
    ? []
    : findLowestLifecycleCostSchedule(module).augmentation_years;
};

export const calculateAddedDcBlocks: ModuleStorageCalculus<number[]> = (
  module
) => {
  return !module.augmentation_data
    ? []
    : findLowestLifecycleCostSchedule(module).added_dc_blocks;
};

export const calculatePCSModulesCount: ModuleStorageCalculus<number> = (
  module
) => {
  return !module.augmentation_data
    ? 0
    : findLowestLifecycleCostSchedule(module).pcs_module_count;
};

export const calculatePCSModulesCountV3: ModuleStorageCalculus<number> = (
  module
) => {
  if (!module.augmentation_data) return 0;
  const schedule = findLowestLifecycleCostSchedule(module);
  if (
    !schedule?.pcs_deployment_schedule ||
    isEmpty(schedule.pcs_deployment_schedule)
  ) {
    return 0;
  }
  return schedule.pcs_deployment_schedule[0];
};

// Deltas

export const calculatePriceDelta: ModuleStorageCalculus<number> = (
  module,
  modules
) => {
  const lowestTotalPriceModule = findLowestTotalPriceModule(modules);
  return Math.abs(
    findLowestLifecycleCostSchedule(lowestTotalPriceModule)
      .beginning_of_life_capex_USD -
      findLowestLifecycleCostSchedule(module).beginning_of_life_capex_USD
  );
};

export const calculateLifecycleCostDelta: ModuleStorageCalculus<number> = (
  module,
  modules
) => {
  const lowestLifecycleCostModule = findLowestLifecycleCostModule(modules);
  const lowestLifecycleCostSchedule = findLowestLifecycleCostSchedule(
    lowestLifecycleCostModule
  );
  const lowestCurrentSchedule = findLowestLifecycleCostSchedule(module);
  return Math.abs(
    lowestLifecycleCostSchedule.weighted_life_cycle_cost_USD -
      lowestCurrentSchedule.weighted_life_cycle_cost_USD
  );
};

export const calculateTotalPrice: ModuleStorageCalculus<number> = (module) => {
  const lowestLifecycleCostSchedule = findLowestLifecycleCostSchedule(module);
  return lowestLifecycleCostSchedule.beginning_of_life_capex_USD;
};

export const calculateRte: ModuleStorageCalculus<number> = (module) => {
  return findLowestLifecycleCostSchedule(module)
    .system_round_trip_efficiency_percent;
};

export const calculatePrice: ModuleStorageCalculus<number> = (module) => {
  return findLowestLifecycleCostSchedule(module)
    .beginning_of_life_capex_USD_per_kWh;
};

export const calculateAddedPCS: ModuleStorageCalculus<number[]> = (module) => {
  if (!module.augmentation_data) return [];
  const schedule = findLowestLifecycleCostSchedule(module);
  if (!schedule.pcs_deployment_schedule) return [];
  const valuesGreaterThanCero = schedule.pcs_deployment_schedule.filter(
    (v) => v > 0
  );
  return tail(valuesGreaterThanCero);
};

export const calculateAugmentationStrategy: ModuleStorageCalculus<string> = (
  module
) => {
  const augmentationStrategy = !module.augmentation_data
    ? ''
    : findLowestLifecycleCostSchedule(module).augmentation_strategy;

  return AugmentationStrategiesText[augmentationStrategy]
    ? AugmentationStrategiesText[augmentationStrategy]
    : '';
};
