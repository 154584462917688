import React from 'react';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { InlineWidget } from 'react-calendly';
import { getConfiguration } from '~/utils/configurations';

import useAuth from '~/hooks/useAuth';
import { GA, gaCustomerEvent, theme } from '~/utils';

const { TabPane } = Tabs;

export enum ScheduleCallDialogSubject {
  MODULES,
  STORAGE,
  BOTH,
}

type ScheduleCallDialogProps = {
  subject?: ScheduleCallDialogSubject;
  onEventScheduled?: () => void;
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab:hover {
    color: initial;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${theme.colors.primary} !important;
    font-weight: 500;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${theme.colors.primary};
    pointer-events: none;
  }
`;

const ScheduleCallDialog: React.FC<ScheduleCallDialogProps> = ({
  subject = ScheduleCallDialogSubject.MODULES,
  onEventScheduled,
}) => {
  const { user } = useAuth();

  const inlineCalendlyProps = {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideGdprBanner: true,
      hideLandingPageDetails: false,
      primaryColor: theme.colors.primary,
      textColor: '343A40',
    },
    prefill: {
      email: user?.email as string,
      firstName: user?.first_name as string,
      guests: [],
      lastName: user?.last_name as string,
      name: `${user?.first_name} ${user?.last_name}`,
    },
    styles: {
      height: '670px',
      width: '100%',
      minWidth: '1000px',
      maxWidth: '1000px',
      marginLeft: -50,
      marginTop: 0,
      boxShadow: 'none',
    },
    utm: {
      utmCampaign: '',
      utmContent: '',
      utmMedium: '',
      utmSource: '',
      utmTerm: '',
    },
  };

  const isCalendlyEvent = (e: MessageEvent) => {
    return (
      e.origin === 'https://calendly.com' &&
      e.data.event &&
      e.data.event.indexOf('calendly.') === 0
    );
  };

  React.useEffect(() => {
    window.addEventListener('message', (e: MessageEvent) => {
      if (
        onEventScheduled &&
        isCalendlyEvent(e) &&
        e.data.event.toString().toLowerCase() === 'calendly.event_scheduled'
      ) {
        onEventScheduled();
      }
    });

    gaCustomerEvent({
      action: GA.actions.SCHEDULE_CALL,
      label: user?.company?.name,
    });
  }, []);

  return (
    <StyledTabs
      defaultActiveKey={subject.toString()}
      color={theme.colors.secondary}
      size="large"
      animated
      tabPosition="top"
    >
      <TabPane tab="Modules" key={ScheduleCallDialogSubject.MODULES}>
        <InlineWidget
          {...inlineCalendlyProps}
          url={getConfiguration('REACT_APP_CALENDLY_SOLAR_URL')}
        />
      </TabPane>
      <TabPane tab="Storage" key={ScheduleCallDialogSubject.STORAGE}>
        <InlineWidget
          {...inlineCalendlyProps}
          url={getConfiguration('REACT_APP_CALENDLY_STORAGE_URL')}
        />
      </TabPane>
      <TabPane tab="Both" key={ScheduleCallDialogSubject.BOTH}>
        <InlineWidget
          {...inlineCalendlyProps}
          url={getConfiguration('REACT_APP_CALENDLY_SOLAR_STORAGE_URL')}
        />
      </TabPane>
    </StyledTabs>
  );
};

export default ScheduleCallDialog;
