import * as R from 'ramda';
import Big from 'big.js';
import dayjs from 'dayjs';
import {
  ActiveColumnsFilter,
  ColumnWithMenuFilter,
  TypeActiveColumnsFilter,
} from '~/hooks/useTableColumns';
import {
  AnalyticsKeyType,
  AnalyticsSchema,
  AnalyticsSchemaUnits,
} from '~/store/project';
import {
  FilterMenuFilterConfig,
  FilterMenuOption,
} from '~/UI/Filters/FilterMenu';
import { SelectableFilterConfig } from '~/UI/Filters/SelectableFilterMenu';
import { columnNumberSorterWithFalsyValues } from './comparer';
import { MODULE_TAGS } from '~/constants/modules';

export type SchemaColumnProps<T> = ColumnWithMenuFilter<T>;

type SortFunction = <T>(a: T, b: T, path: string[]) => number;

const isEmptyOrNil = <T>(value: T) => R.isNil(value) || R.isEmpty(value);

const getSchemaCellValue = <T>(value: T, { units }: AnalyticsSchema) => {
  if (isEmptyOrNil(value)) return '-';
  // @ts-ignore This ignore is used because we are receiving "units === 'null'" from the api, so we need to check that
  if (units === AnalyticsSchemaUnits.NA || R.isNil(units) || units === 'null')
    return value;
  return `${value}${units}`;
};

export const stringSort: SortFunction = (a, b, path) => {
  const aValue = R.pathOr('', path, a);
  const bValue = R.pathOr('', path, b);
  return aValue.localeCompare(bValue);
};

export const numberSort: SortFunction = (a, b, path) => {
  const aValue = R.pathOr(0, path, a);
  const bValue = R.pathOr(0, path, b);
  return aValue - bValue;
};

// integer sort works the same as the boolean sort
export const booleanSort: SortFunction = numberSort;

export const KEYS_TYPE_SORT_MAP: Record<AnalyticsKeyType, SortFunction> = {
  [AnalyticsKeyType.INTEGER]: numberSort,
  [AnalyticsKeyType.DOUBLE]: numberSort,
  [AnalyticsKeyType.FLOAT]: numberSort,
  [AnalyticsKeyType.STRING]: stringSort,
  [AnalyticsKeyType.BOOLEAN]: booleanSort,
  [AnalyticsKeyType.DATE]: stringSort,
};

const BOOLEAN_YES = 'Yes';
const BOOLEAN_NO = 'No';

type AnalyticsSchemaWithKey = AnalyticsSchema & { key: string };

/**
 * Given a value and it's Analytics Schema, transforms the value based on the schema
 * @param value
 * @param schema
 * @returns
 */
const parseValueBySchema = (value: any, schema: AnalyticsSchema) => {
  if (R.isNil(value)) return value;

  if (
    schema.units === AnalyticsSchemaUnits.PERCENTAGE &&
    schema.type === AnalyticsKeyType.FLOAT
  ) {
    return new Big(value).mul(100);
  }

  if (schema.type === AnalyticsKeyType.BOOLEAN || typeof value === 'boolean') {
    if (typeof value !== 'boolean') return value;
    return value ? BOOLEAN_YES : BOOLEAN_NO;
  }

  if (schema.type === AnalyticsKeyType.DATE) {
    return dayjs(value, 'YYYY/MM/DD').format('MM/DD/YYYY');
  }

  return value;
};

/**
 * Given a value and it's Analytics Schema, parses the value based on the schema units
 * @param value
 * @param schema
 * @returns
 */
export const formatValueBySchema = (
  value: number | string | null,
  schema: AnalyticsSchema
) => {
  if (R.isNil(value)) return value;

  if (
    [
      AnalyticsKeyType.DOUBLE,
      AnalyticsKeyType.FLOAT,
      AnalyticsKeyType.INTEGER,
    ].includes(schema.type)
    && typeof value === 'number'
  ) {
    return new Intl.NumberFormat('default', {
      minimumFractionDigits: schema.decimal_places,
      maximumFractionDigits: schema.decimal_places,
    }).format(value);
  }

  return value;
};

const transformValueBySchema = (value: any, schema: AnalyticsSchema) => {
  const parsedValue = parseValueBySchema(value, schema);
  const formattedValue = formatValueBySchema(parsedValue, schema);
  return getSchemaCellValue(formattedValue, schema);
};

type FilterOption = {
  text: string;
  title: string;
  label: string;
  value: string;
};

const generateFilterOptionsBySchemaAndValues = (
  schema: AnalyticsSchema,
  values: (string | number)[]
): FilterOption[] => {
  return values.map((value) => {
    const text = isEmptyOrNil(value)
      ? '-'
      : String(transformValueBySchema(value, schema));
    return {
      text,
      title: text,
      label: text,
      value: R.isNil(value) ? '' : String(value),
    };
  });
};

const sortFilterOptions = (filterOptions: FilterOption[]): FilterOption[] => {
  const sortedFiltersByText = R.sortWith(
    [R.ascend((f) => f.text)],
    filterOptions
  );
  const dashFilter = sortedFiltersByText.find(({ text }) => text === '-');
  const sortedFilters = sortedFiltersByText.filter(({ text }) => text !== '-');
  if (dashFilter) sortedFilters.push(dashFilter);
  return sortedFilters;
};

const applyDynamicFilterToColumn = <T>(
  column: ColumnWithMenuFilter<T>,
  records: T[],
  schema: AnalyticsSchema
): ColumnWithMenuFilter<T> => {
  const valuePath =
    R.is(String, column.dataIndex) || R.is(Number, column.dataIndex)
      ? [column.dataIndex]
      : column.dataIndex;
  const values = records.map((r) => R.path(valuePath as R.Path, r));
  const uniqValues = R.uniq(values) as (string | number)[];

  const filters = generateFilterOptionsBySchemaAndValues(schema, uniqValues);

  const sortedFilters = sortFilterOptions(filters);
  return {
    filters: sortedFilters,
    onFilter: (value, record) => {
      const pathVal = R.path(valuePath as R.Path, record);
      const pathValue = R.isNil(pathVal) ? '' : pathVal;
      return pathValue === value;
    },
    ...column,
  };
};

const generateSchemaColumn: <T>(
  schema: AnalyticsSchemaWithKey,
  records: T[],
  options: Options
) => ColumnWithMenuFilter<T> = (
  schema,
  records,
  { pathToSchemaValues = [], activeColumns = [], generateFilters }
) => {
  const column: ColumnWithMenuFilter = {
    key: schema.key,
    dataIndex: [...pathToSchemaValues, schema.key],
    title: schema.ui_name,
    dropdownGroupTitle: schema.ui_group,
    render: (value) => transformValueBySchema(value, schema),
    active:
      activeColumns.find((c) => c.key === schema.key)?.active ||
      ActiveColumnsFilter.unselected,
  };
  if (schema.key === 'cell_technology') {
    column.render = (value) =>
      R.values(MODULE_TAGS).find((tag) => tag.value === value)?.label || value;
  }
  return generateFilters
    ? applyDynamicFilterToColumn(column, records, schema)
    : column;
};

type Options = {
  /** Path to the schema object that contains all the key-value pairs */
  pathToSchemaValues?: string[];
  /** If you are using useTableColumns, this is usefull to set which columns you want to e activated by default */
  activeColumns?: {
    key: string;
    active: TypeActiveColumnsFilter;
  }[];
  sortedColumnsByKey?: string[];
  generateFilters?: boolean;
};

/**
 * Given a Record of schemas and "dataSource" (array of records). Returns an array of columns, one column for each schema.
 *
 * "dataSource" is used to generate dynamic filters automatically for each column
 *
 * @param schemas
 * @param dataSource
 * @param options
 * @returns
 */
export const generateSchemaColumns = <T>(
  schemas: Record<string, AnalyticsSchema>,
  dataSource: T[],
  options: Options
): ColumnWithMenuFilter<T>[] => {
  const schemasWithKeys = R.mapObjIndexed((schema, key) => {
    return {
      ...schema,
      key,
    };
  }, schemas);

  const schemasArray = R.values(schemasWithKeys);
  const sortedSchemasByDisplayOrder = R.sortWith(
    [columnNumberSorterWithFalsyValues(['display_order'])],
    schemasArray
  );
  const columns = sortedSchemasByDisplayOrder.map((schema) =>
    generateSchemaColumn<T>(schema, dataSource, options)
  );

  // Sorting columns by key
  const { sortedColumnsByKey } = options;
  if (sortedColumnsByKey) {
    const sortedCols = sortedColumnsByKey.map((sortedKey) => {
      return columns.find(({ key }) => sortedKey === key);
    });
    const nonSortedCols = columns.filter(
      ({ key }) => !sortedColumnsByKey.includes(key)
    );
    const rejectNilItems = R.reject(R.isNil);

    return [...rejectNilItems(sortedCols), ...rejectNilItems(nonSortedCols)];
  }

  return columns;
};

export const generateSchemaFilterGroups = (
  schemas: Record<string, AnalyticsSchema>
): FilterMenuFilterConfig[] => {
  const filters: FilterMenuFilterConfig[] = [];

  R.mapObjIndexed((schema, schemaKey) => {
    const foundFilterGroup = filters.find((f) => f.name === schema.ui_group);
    const newOption: FilterMenuOption = {
      label: schema.ui_name,
      title: schema.ui_name,
      value: schemaKey,
    };
    if (foundFilterGroup) {
      foundFilterGroup.options = [...foundFilterGroup.options, newOption];
      return;
    }

    filters.push({
      name: schema.ui_group,
      dropdownGroupTitle: schema.ui_group,
      options: [newOption],
      title: schema.ui_group,
      filterHeaderDataTestIdConfig: {
        component: '',
      },
    });
  }, schemas);
  return filters;
};

export const generateSchemaFilters = <T>(
  schemas: Record<string, AnalyticsSchema>,
  records: T[],
  options?: {
    /** Path in which all schema values are located for each record */
    pathToSchemaValue?: string[];
  }
): SelectableFilterConfig[] => {
  const filters: SelectableFilterConfig[] = [];
  R.mapObjIndexed((schema, schemaKey) => {
    const path = options?.pathToSchemaValue
      ? [...options.pathToSchemaValue, schemaKey]
      : [schemaKey];
    const schemaUniqValues = R.uniq(
      records.map((data) => R.pathOr('', path, data))
    );

    const filterOptions = generateFilterOptionsBySchemaAndValues(
      schema,
      schemaUniqValues
    );

    const sortedFilterOptions = sortFilterOptions(filterOptions);

    filters.push({
      group: schema.ui_group,
      name: schemaKey,
      title: schema.ui_name,
      dropdownGroupTitle: schema.ui_name,
      options: sortedFilterOptions,
      datatype: schema.type,
      display_order: schema.display_order,
    });
  }, schemas);
  return R.sortWith(
    [R.ascend((s) => Number(s.display_order))],
    filters
  )
};
