import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Card, Divider, Spinner } from '~/UI';
import { PageDescription, PopupTitle } from '~/UI/Typography';
import { anzaLogo2, checkCircle } from '~/assets';
import { theme } from '~/utils';

const Container = styled(Card)`
  background-color: ${theme.colors.gray200};
  width: 100%;
  height: 630px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  padding: 40px;
  .ant-card-body {
    padding: 0;
  }
`;

const StyledPageDescription = styled(PageDescription)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 420px;
  .ant-card-body {
    padding: 0;
  }
`;

const StyledTextBox = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledContainer = styled.div<{ idle: boolean }>`
  padding-left: ${({ idle }) => (idle ? '30px' : 'auto')};
`;

const StyledTextContainer = styled.div<{ idle: boolean }>`
  color: ${({ idle }) => (idle ? theme.colors.gray400 : 'auto')};
  transition: 1s;
  display: flex;
  text-align: left;
  gap: 10px;
`;

const StyledText = styled.div`
  max-width: 390px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  margin-top: 20px;
`;

export type LoadingCardState = 'pending' | 'loading' | 'finished';

export type LoadingCardProps = {
  textLines: [string, ...string[]];
  waitMiliseconds?: number;
  onChangeState?: (state: LoadingCardState) => any;
};

const LoadingCard = ({
  textLines,
  waitMiliseconds = 2000,
  onChangeState,
}: LoadingCardProps) => {
  const [loadingCardState, setLoadingCardState] =
    useState<LoadingCardState>('pending');
  const [currentText, setCurrentText] = useState<number>(0);

  useEffect(() => {
    setLoadingCardState('loading');
    if (onChangeState) onChangeState('loading');
  }, []);

  useEffect(() => {
    if (loadingCardState === 'loading') {
      const timer = setInterval(() => {
        setCurrentText((prev) => {
          if (prev + 1 < textLines.length) return prev + 1;
          clearInterval(timer);
          return prev;
        });
      }, waitMiliseconds);
      setTimeout(() => {
        setLoadingCardState('finished');
        if (onChangeState) onChangeState('finished');
      }, waitMiliseconds * textLines.length);
    }
  }, [loadingCardState]);

  return (
    <Container>
      <StyledCard>
        <CardHeader>
          <PopupTitle
            style={{
              color: theme.colors.primary,
            }}
          >
            Hang tight...
          </PopupTitle>
          <img width="45px" height="45px" src={anzaLogo2} alt="Anza logo" />
        </CardHeader>
        <StyledDivider />
        <StyledPageDescription>
          {textLines.map((value, index) => (
            <StyledTextBox key={value}>
              <StyledContainer idle={currentText < index}>
                <StyledTextContainer idle={currentText < index}>
                  {currentText > index && (
                    <img
                      width="20px"
                      height="20px"
                      src={checkCircle}
                      alt={`Step ${value} complete`}
                    />
                  )}
                  {currentText === index && (
                    <Spinner
                      size="20px"
                      thickness="3px"
                      indicatorColor={theme.colors.gray800}
                      trackColor={theme.colors.white}
                    />
                  )}
                  <StyledText>{value}</StyledText>
                </StyledTextContainer>
              </StyledContainer>
            </StyledTextBox>
          ))}
        </StyledPageDescription>
      </StyledCard>
    </Container>
  );
};

export default LoadingCard;
