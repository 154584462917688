import React from 'react';
import { useHistory } from 'react-router';
import { AxiosResponse, AxiosError } from 'axios';
import { logger } from '~/utils/logger';
import { ServerResponses, sleep } from '~/utils';
import useAuth, { KEYS } from './useAuth';

interface ApiWrapperEnvelope {
  service: <T>() => Promise<AxiosResponse<T | any>>;
  requiresAuth?: boolean;
}

const sleepTimeInMs = 1000;

export const useApiWrapper = () => {
  const { getAccessTokenSilently, signOutUser, user } = useAuth();
  const { location } = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const retryQueue = async (
    retryFn: <T>() => Promise<AxiosResponse<T>>,
    retryCount = 0
  ) => {
    let data: AxiosResponse<unknown> | null = null;

    logger(['initial retryCount', retryCount]);

    if (retryCount < 3) {
      await sleep(sleepTimeInMs);
      logger(['calling retryFn']);
      try {
        const res = await retryFn();
        logger(['retryFn success with res:', res]);
        data = res;
      } catch (error) {
        logger(['failed', error]);
        if (retryCount >= 2) {
          logger([
            'max retires reached, signing user out with retry count:',
            retryCount,
          ]);
          return signOutUser(location);
        }
        logger(['retrying next intent', retryCount + 1]);
        await retryQueue(retryFn, retryCount + 1);
      }
    }
    return data || undefined;
  };

  const tokenRefreshProcedure = async (
    error: AxiosError,
    retryFn: <T>() => Promise<AxiosResponse<T>>
  ) => {
    logger([
      'starting refresh procedure with error:',
      JSON.stringify(error, null, 2),
    ]);
    logger(['error.response object:', JSON.stringify(error.response, null, 2)]);
    if (error.response?.status === ServerResponses.UNAUTHENTICATED) {
      const token = await getAccessTokenSilently();

      if (token) {
        const currentUser = {
          ...user,
        };
        localStorage.setItem(KEYS.USER, JSON.stringify(currentUser));

        logger(['starting retry queue', KEYS.USER, currentUser]);
        const data = await retryQueue(retryFn);
        return data;
      }
      logger(['signing out user']);
      signOutUser();
    }

    throw error;
  };

  const executeApi = async <T extends unknown>({
    service,
    requiresAuth = true,
  }: ApiWrapperEnvelope): Promise<AxiosResponse<T>> => {
    logger(['executeApi with trace:', service]);
    setIsLoading(true);
    try {
      const data = await service();
      return data as AxiosResponse<T>;
    } catch (error: unknown) {
      logger(['error executing api wrapper']);
      if (requiresAuth) {
        logger(['requiresAuth', requiresAuth]);
        return (await tokenRefreshProcedure(
          error as AxiosError<T>,
          service
        )) as AxiosResponse<T>;
      }
      setHasError(!!error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    executeApi,
    isLoading,
    hasError,
  };
};
