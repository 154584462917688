import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import debounce from 'lodash.debounce';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Table, TooltipWithEllipsis } from '~/UI';
import {
  ModuleUpdateLogType,
  theme,
  catchError,
  ModuleUpdateLog,
} from '~/utils';
import {
  PaginationAndSortingParams,
  SortOrder,
} from '~/services/api/moduleUpdateLogs';
import SearchFilterDropdown from '~/components/Tables/SearchFilterDropdown';
import { useLazyGetModuleUpdateLogsQuery } from '~/store/api/admin/auditLogsApi';
import { getPopupContainerForTable } from '~/utils/table';

dayjs.extend(utc);
dayjs.extend(timezone);
interface Params {
  pagination?: TablePaginationConfig;
  sorter?: SorterResult<any> | SorterResult<any>[];
  total?: number;
  sortField?: string;
  sortOrder?: 'ascend' | 'descend';
  filters?: {
    [key: string]: string[];
  };
}

const sortDirectionMap: { [key: string]: SortOrder } = {
  ascend: 'ASC',
  descend: 'DESC',
};

const convertToApiParams = ({
  pagination,
  sortField,
  sortOrder,
  filters,
}: Params): PaginationAndSortingParams | undefined => {
  if (!pagination) {
    return undefined;
  }
  const limit = pagination.pageSize;
  const offset = (pagination.current! - 1) * pagination.pageSize!;
  if (!filters) {
    return {
      limit,
      offset,
      sortField,
      sortOrder: sortOrder ? sortDirectionMap[sortOrder] : undefined,
    };
  }
  const filterObj = Object.keys(filters).reduce(
    (acc: { [key: string]: string | string[] }, curr) => {
      if (!filters[curr] || filters[curr].length === 0) {
        return acc;
      }
      if (curr === 'quarter') {
        acc[curr] = filters[curr];
        return acc;
      }
      const [val] = filters[curr];
      acc[curr] = val;
      return acc;
    },
    {}
  );

  return {
    limit,
    offset,
    sortField,
    sortOrder: sortOrder ? sortDirectionMap[sortOrder] : undefined,
    filter: JSON.stringify(filterObj),
  };
};

export interface Filter {
  text: string;
  value: string;
  isStrict?: boolean | undefined;
}

const StyledTable = styled(Table)`
  margin-top: -24px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: initial;
  }
  .ant-pagination-item-link:disabled {
    color: ${theme.colors.lightGray};
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: ${theme.colors.charcoal};
  }
  .ant-select-arrow {
    color: black;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
`;

export const AdminModuleUpdateLog = () => {
  const [trigger] = useLazyGetModuleUpdateLogsQuery();
  const [items, setItems] = useState<ModuleUpdateLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [filterState, setFilterState] = useState({
    searchText: '',
    searchedColumn: '',
    searchInput: null,
  });

  const handleSearch = (selectedKeys: string[], dataIndex: string) => {
    setFilterState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      searchInput: filterState.searchInput,
    });
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilterState({
      searchText: '',
      searchedColumn: '',
      searchInput: null,
    });
  };

  const fetchData = async (params: Params = {}) => {
    try {
      setIsLoading(true);
      const data = await trigger(convertToApiParams(params) || {}).unwrap();

      if (data) {
        setItems(data.items);
        setPagination({
          ...params.pagination,
          total: data.count,
        });
      }
    } catch (error) {
      catchError({
        error,
        method: 'fetchData',
        location: 'pages/Admin/Modules/AdminModuleUpdateLog',
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<ModuleUpdateLog> = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: true,
      width: 110,
      fixed: 'left',
      defaultSortOrder: 'descend',
      render: (date: string) =>
        dayjs
          .tz(new Date(date), 'America/Los_Angeles')
          .format('MM/DD/YYYY hh:mma'),
    },
    {
      title: 'User',
      dataIndex: ['user', 'email'],
      key: 'user.email',
      sorter: true,
      width: 200,
      ...SearchFilterDropdown(
        { dataIndex: 'user.email', dataPath: ['user', 'email'] },
        {
          state: filterState,
          setState: setFilterState,
          handleReset: handleReset as () => void,
          handleSearch,
        }
      ),
      render: (email: string) => email || '-',
    },
    {
      title: 'Company',
      dataIndex: ['module', 'company', 'name'],
      key: 'module.company.name',
      sorter: true,
      width: 120,
      ...SearchFilterDropdown(
        {
          dataIndex: 'module.company.name',
          dataPath: ['module', 'company', 'name'],
        },
        {
          state: filterState,
          setState: setFilterState,
          handleReset: handleReset as () => void,
          handleSearch,
        }
      ),
      render: (_, row) => row.module?.company?.name || '-',
    },
    {
      title: 'Module',
      dataIndex: ['module', 'name'],
      sorter: true,
      width: 200,
      key: 'module.name',
      ...SearchFilterDropdown(
        { dataIndex: 'module.name', dataPath: ['module', 'name'] },
        {
          state: filterState,
          setState: setFilterState,
          handleReset: handleReset as () => void,
          handleSearch,
        }
      ),
      render: (moduleName) => (
        <TooltipWithEllipsis id={moduleName} text={moduleName} />
      ),
    },
    {
      title: 'Quarter',
      dataIndex: ['metadata', 'quarter'],
      key: 'quarter',
      sorter: true,
      width: 100,
      render: (quarter: number, record) => {
        if (!quarter || !record.metadata.year) return '-';
        return `Q${quarter} ${record.metadata.year}`;
      },
    },
    {
      title: 'Price \n($/W)',
      dataIndex: ['metadata', 'price'],
      key: 'price',
      width: 100,
      align: 'center',
      sorter: true,
      render: (ddpEastCoastPort: number) => ddpEastCoastPort || '-',
    },
    {
      title: 'Availability \n(MW)',
      dataIndex: ['metadata', 'availabilityMw'],
      key: 'availabilityMw',
      width: 100,
      align: 'center',
      sorter: true,
      render: (availabilityMw: number) => availabilityMw || '-',
    },
    {
      title: 'Event Type',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      align: 'center',
      sorter: true,
    },
  ];

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<ModuleUpdateLogType>
  ) => {
    fetchData({
      sortField: R.is(Array, sorter.field)
        ? sorter.field.join('.')
        : (sorter.field as string),
      sortOrder: sorter.order as 'ascend' | 'descend',
      pagination: newPagination,
      filters: filters as { [key: string]: string[] },
    });
  };

  const debouncedTableChangeHandler = useMemo(
    () => debounce(handleTableChange, 300),
    []
  );

  useEffect(() => {
    fetchData({ pagination, sortField: 'timestamp', sortOrder: 'descend' });
    // Stop the invocation of the debounced function
    // after unmounting
    return () => {
      debouncedTableChangeHandler.cancel();
    };
  }, []);

  return (
    <StyledTable
      rowKey="id"
      className="alternate-rows-even"
      scroll={{ x: 1000, y: 768 }}
      onChange={debouncedTableChangeHandler as any}
      pagination={pagination}
      loading={isLoading}
      columns={columns as any}
      dataSource={items}
      getPopupContainer={getPopupContainerForTable}
    />
  );
};
