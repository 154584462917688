import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { RouteComponentProps, useParams } from 'react-router';

import {
  Form,
  InputAnt as Input,
  ButtonAnt as Button,
  Overlay,
  Spinner,
  ModalAnt as Modal,
  Option,
  FormItemLabel,
} from '~/UI';
import { IconCheckmark, IconCancel } from '~/UI/Icons';
import { blockEnterKeyEvents } from '~/utils/events';
import { requiredRule, theme } from '~/utils';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { selectFilterOption, selectFilterSort } from '~/utils/comparer';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { rtkService, DomainResponse } from '~/types/rtkApi';
import { CompanyType, DomainType } from '~/types/users';
import {
  useGetAllCompaniesQuery,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
} from '~/store/api';
import { SelectFormItem } from '~/UI/Select';
import { RadioButton, RadioGroup } from '~/UI/Radio';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';

import AdminCompaniesForm from '../Companies/AdminCompaniesForm';

const StyledRadioButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.graphite};
  svg {
    margin-right: 0.5rem;
  }
`;

const CustomRadioGroup = styled.div`
  .ant-radio-button-wrapper-checked {
    border: 2px solid;
    .ant-radio-button-checked {
      background: ${theme.colors.sterling};
    }
  }
`;

const companiesService = {
  get: useGetCompanyQuery,
  getAll: useGetAllCompaniesQuery,
  delete: useDeleteCompanyMutation,
} as unknown as rtkService;

const AdminDomainsForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
  }
> = ({ service, history, ...props }) => {
  const { id }: { id: string } = useParams() || {};
  const [form] = Form.useForm();
  const [companiesToLoad, setCompaniesToLoad] = useState<CompanyType[]>([]);
  const [updateDomain] = useUpdateDomainMutation();
  const [createDomain] = useCreateDomainMutation();
  const [newCompanyId, setNewCompanyId] = useState<number | undefined>();
  const { data = null, isLoading = false } = id
    ? (service.get({ id }) as DomainResponse)
    : {};
  const {
    data: companies = [],
    isLoading: isLoadingCompanies,
    refetch: refetchCompanies,
  } = useGetAllCompaniesQuery();

  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);

  const openAddCompanyModal = () => setAddCompanyModalOpen(true);
  const closeAddCompanyModal = (newCompany?: any) => {
    setAddCompanyModalOpen(false);
    refetchCompanies();
    if (!newCompany) {
      return;
    }
    setNewCompanyId(newCompany.id);
  };

  useEffect(() => {
    if (newCompanyId) {
      form.setFieldsValue({
        company_id: newCompanyId,
      });
    }
  }, [companiesToLoad.length]);

  const checkInvalid = () => {
    form.validateFields(['domain', 'company_id']);
  };

  useEffect(() => {
    const companiesStructured = R.indexBy(R.prop('id'), companies);
    setCompaniesToLoad(Object.values(companiesStructured));
    if (!id) {
      form.setFieldsValue({
        domain: form.getFieldValue('domain'),
        company_id: null,
        domain_status: 'PENDING',
      });
    } else if (data) {
      const { domain_status = 'PENDING' } = data;
      form.setFieldsValue({
        ...data,
        domain_status: domain_status.toUpperCase(),
        company_id: companiesStructured[+data.company_id]
          ? data.company_id
          : null,
      });
      checkInvalid();
    }
  }, [isLoading, isLoadingCompanies, companies.length]);

  const goBack = () => history.push(ADMIN_ROUTES.DOMAINS.path.default);

  const onFinish = async (values: DomainType) => {
    if (id) await updateDomain({ domain: { id, ...values } });
    else await createDomain({ domain: values });
    goBack();
  };

  const { isDirty, onValuesChange } = useFormDirty();

  return (
    <>
      <AdminFormLayout>
        <Form
          form={form}
          onFinish={onFinish}
          style={{ width: '100%', minHeight: '450px', padding: '0 1rem' }}
          onKeyDown={blockEnterKeyEvents}
          onValuesChange={onValuesChange}
        >
          {isLoading && (
            <Overlay
              style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}
            >
              <Spinner />
            </Overlay>
          )}
          <Form.Item
            name="domain"
            label={<FormItemLabel>Domain</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
          >
            <Input required name="domain" placeholder="Insert domain" />
          </Form.Item>

          <SelectFormItem
            name="company_id"
            label={<FormItemLabel>Company</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[requiredRule]}
            selectProps={{
              placeholder: 'Select a company',
              showSearch: true,
              filterOption: selectFilterOption,
              filterSort: selectFilterSort,
            }}
          >
            {(companiesToLoad as CompanyType[])
              .sort((optionA: { name: string }, optionB: { name: string }) =>
                optionA.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLocaleLowerCase())
              )
              .map(({ id: companyId, name }: { id: number; name: string }) => (
                <Option key={companyId} value={companyId}>
                  {name}
                </Option>
              ))}
          </SelectFormItem>
          <Button type="primary" size="small" onClick={openAddCompanyModal}>
            + Add Company
          </Button>
          <CustomRadioGroup>
            <Form.Item
              name="domain_status"
              label={<FormItemLabel>Status</FormItemLabel>}
              labelCol={{ span: 24 }}
            >
              <RadioGroup>
                <RadioButton value="APPROVED">
                  <StyledRadioButtonContent>
                    <IconCheckmark color={theme.colors.primaryDark} size="16" />
                    Approved
                  </StyledRadioButtonContent>
                </RadioButton>
                <RadioButton value="REJECTED">
                  <StyledRadioButtonContent>
                    <IconCancel color={theme.colors.error} size="16" /> Rejected
                  </StyledRadioButtonContent>
                </RadioButton>
              </RadioGroup>
            </Form.Item>
          </CustomRadioGroup>
          <FormFooter
            isDirty={isDirty}
            saveButtonProps={{
              onClick: checkInvalid,
            }}
          />
        </Form>
      </AdminFormLayout>
      <Modal
        key={addCompanyModalOpen ? 'open' : 'closed'}
        open={addCompanyModalOpen}
        onOk={closeAddCompanyModal}
        onCancel={closeAddCompanyModal}
        footer={false}
      >
        <AdminCompaniesForm
          isInModal
          onFinishCallback={closeAddCompanyModal}
          createMode
          service={companiesService}
          history={history}
          {...props}
        />
      </Modal>
    </>
  );
};

export default AdminDomainsForm;
