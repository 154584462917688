import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ErrorPage from 'routes/ErrorPage';
import { getConfiguration } from '~/utils/configurations';
import { Errors, ErrorTypes } from './errorHandler';
import { ROUTES } from '../router/routes';

interface State {
  hasError: boolean;
  error?: ErrorTypes[keyof ErrorTypes] | null;
}

interface Props extends RouteComponentProps {
  children: React.ReactChildren | React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: State['error']): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error): void {
    // You can also log the error to an error reporting service
    this.setState({ error: error.message.toString() });
    const { history } = this.props;
    history.push(ROUTES.ERROR.path());
  }

  render(): React.ReactNode {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      if (error === Errors.INVALID_SALESFORCE_PARAMETERS) {
        return (
          <ErrorPage message="We were unable to pull data through from Salesforce." />
        );
      }

      if (error === Errors.INVALID_PAGE) {
        return <ErrorPage message="Invalid Page" />;
      }

      if (error === Errors.UNKNOWN) {
        return (
          <ErrorPage message="Unknown Error. Please try again or contact an administrator of this site." />
        );
      }
      // You can render any custom fallback UI. Log actual error for development purposes.
      return getConfiguration('NODE_ENV') === 'development' ? (
        <ErrorPage message={error!.toString()} />
      ) : (
        <ErrorPage message="Something wrong happened. Please try again or contact an administrator of this site." />
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
