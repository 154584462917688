import styled from '@emotion/styled';
import { theme } from '~/utils';
import { InputNumber } from '~/UI';

const autocompleteRemoval = `
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: ${theme.colors.charcoal};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const FormBox = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.05em;
  color: ${theme.colors.charcoal};
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${theme.colors.charcoal};
  margin-bottom: 1.25rem;
`;

export const FormRow = styled.div`
  display: inline-flex;
  width: 100%;

  .ant-row {
    display: block;
  }
`;

export const PricingInputNumber = styled(InputNumber)`
  ${autocompleteRemoval}
  font-size: 14px;
  input {
    font-size: 14px;
    height: fit-content;
    color: ${theme.colors.charcoal};
    &::placeholder {
      color: ${theme.colors.stone};
    }
    border-radius: 0;
  }
  &.ant-input-number-disabled {
    background-color: initial;
  }
  &.ant-input-number,
  &.ant-input-number-focused {
    font-size: 14px;
    height: fit-content;
  }
`;
