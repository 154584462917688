import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as R from 'ramda';
import { useLocation } from 'react-router';
import { CompanyModulesData } from '~/services/api/anza';
import { Spinner } from '~/UI';
import { StyledCard } from '~/UI/Card';
import { EmptyTableContainer } from '~/UI/Table';
import AnzaContainer from '~/components/Anza/AnzaLayout/AnzaContainer';
import { catchError, theme } from '~/utils';
import ModulePricing from '~/components/SolarModules/ModulePricing';
import { ModuleType } from '~/types/modules';
import { useLazyGetModulesQuery } from '~/store/api/vendor/vendorApi';

const Container = styled(AnzaContainer)`
  overflow: hidden;
`;

const Title = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: ${theme.fonts.colors.charcoal};
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .table {
    flex: 1 1 auto;
  }
  .nav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  button {
    margin-left: 1.5rem;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const EmptyTitle = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${theme.colors.charcoal};
  margin-bottom: 1rem;
`;

const EmptyDescription = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.charcoal};
  margin-bottom: 3.5rem;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const AnzaOurModules: React.FC = () => {
  const [data, setData] = useState<CompanyModulesData[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [getCompanyModules] = useLazyGetModulesQuery();
  const { pathname } = useLocation();

  useEffect(() => {
    // Local storage is being used here to persist navigation state through
    // authentication. This data is being used to determine whether or not
    // to scroll to a particular module and which module should be the target.
    // For users that are logged off and clicked on an external link that points
    // to a particular module, it is expected that they'll navigate to the
    // correct target module (as defined in the link hash "#module-uuid") after
    // the auth redirect. This is where we're doing the clean up, i.e., on component
    // mount, so we're sure that navigation has been successful.
    localStorage.removeItem(pathname);
  }, []);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleGetModules = async () => {
    try {
      setLoaded(false);
      const response = await getCompanyModules(undefined).unwrap();
      setData(response);
    } catch (error) {
      catchError({
        error,
        method: 'handleGetModules',
        location: 'pages/anza/ourmodules',
      });
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    handleGetModules();
  }, []);

  const Empty = () => (
    <EmptyTableContainer>
      <EmptyTitle>No modules.</EmptyTitle>
      <EmptyDescription>
        It seems like you have no modules at the moment.
      </EmptyDescription>
    </EmptyTableContainer>
  );

  const sortedData = data
    .filter((module) => !module.is_anza_deactivated)
    .sort((a: CompanyModulesData, b: CompanyModulesData) => {
      return a.name.localeCompare(b.name);
    }) as unknown as ModuleType[];

  return (
    <Container>
      {loaded ? (
        <div style={{ display: 'contents' }}>
          <StyledCard>
            <ActionBar>
              <div className="table">
                <Title>Modules</Title>
              </div>
            </ActionBar>
          </StyledCard>
          {R.isNil(sortedData) || R.isEmpty(sortedData) ? (
            <Empty />
          ) : (
            sortedData?.map((el: ModuleType) => (
              <ModulePricing key={el.uuid} moduleData={el} />
            ))
          )}
        </div>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Container>
  );
};

export default AnzaOurModules;
