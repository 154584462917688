import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import Tooltip from '~/UI/Tooltip';
import { isEllipsisActive } from '~/utils/isEllipsisActive';
import { truncatedTextStyles, truncatedTextStylesObject } from '~/UI/CSSUtils';

interface TooltipWithEllipsisProps {
  id: string;
  text: string;
  textStyles?: React.CSSProperties;
  component?: React.ReactElement;
}

const TruncatedText = styled.p`
  ${truncatedTextStyles};

  width: 90%;
`;

const TooltipWithEllipsis: React.FC<TooltipWithEllipsisProps> = ({
  text,
  id,
  textStyles,
  component,
}) => {
  const [showEllipsis, setShowEllipsis] = useState(false);
  const ellipsisText = showEllipsis ? text : null;
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (element || component) {
      setShowEllipsis(isEllipsisActive(document.getElementById(id)));
    }
  }, [element, component]);

  useEffect(() => {
    setElement(document.getElementById(id));
  }, [document.getElementById(id)]);

  if (component) {
    const clonedComponent = React.cloneElement(component, {
      id,
      style: truncatedTextStylesObject,
    });

    return <Tooltip title={ellipsisText}>{clonedComponent}</Tooltip>;
  }

  return (
    <Tooltip title={ellipsisText}>
      <TruncatedText id={id} style={textStyles}>
        {text}
      </TruncatedText>
    </Tooltip>
  );
};

export default TooltipWithEllipsis;
