import axios, { AxiosResponse } from 'axios';

import { api, apiV2 } from '~/api/axios';
import { routes } from '~/api/routes';
import { catchError } from '~/utils';
import { FileTypes, ModuleFile, UploadResponse, UploadUrl } from '~/types/file';

export const getUploadUrl = async (
  fileName: string,
  fileType?: FileTypes
): Promise<AxiosResponse<UploadUrl>> => {
  try {
    return await apiV2.post(routes.admin.getUploadUrl(), {
      fileName,
      fileType,
    });
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const uploadFile = async (
  uploadUrl: string,
  file: Blob
): Promise<AxiosResponse<UploadResponse>> => {
  try {
    return await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': 'attachment',
      },
    });
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const getDownloadUrl = async (
  fileName: string
): Promise<AxiosResponse<{ success: boolean; data: string }>> => {
  try {
    return await api.post('files/get_download_url', { fileName });
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export interface SearchFilesResponse {
  data?: ModuleFile[];
}
