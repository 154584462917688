import React from 'react';
import {
  useGetAllLocationModifiersQuery,
  useDeleteLocationMutation,
  useGetLocationQuery,
} from '~/store/api';
import { rtkService } from '~/types/rtkApi';
import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminLocationModifiersForm from '../components/Admin/LocationModifiers/AdminLocationModifiersForm';
import AdminLocationModifiersList from '../components/Admin/LocationModifiers/AdminLocationModifiersList';

const AdminLocationModifiers: React.FC = () => {
  const service = {
    get: useGetLocationQuery,
    getAll: useGetAllLocationModifiersQuery,
    delete: useDeleteLocationMutation,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="Location Modifiers"
      listComponent={AdminLocationModifiersList}
      formComponent={AdminLocationModifiersForm}
      path={ADMIN_ROUTES.LOCATION_MODIFIERS.path}
      service={service}
    />
  );
};

export default AdminLocationModifiers;
