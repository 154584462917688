import React from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router';
import dayjs from 'dayjs';
import { Input as InputAnt, message } from 'antd';

import { Link } from 'react-router-dom';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {
  ButtonAnt as Button,
  Form,
  FormItemLabel,
  InputAnt as Input,
  Option,
  Switch,
  Tooltip,
} from '~/UI';
import { blockEnterKeyEvents } from '~/utils/events';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { rtkService } from '~/types/rtkApi';
import FormFooter from '~/components/FormFooter';
import { SelectFormItem } from '~/UI/Select';
import { formErrorScroll, notRequiredRules, requiredRule } from '~/utils';
import { selectFilterOption, selectFilterSort } from '~/utils/comparer';
import { IconCopy, IconView } from '~/UI/Icons';
import { ROUTER_PATHS } from '~/router/Paths';
import { useGetAllCompaniesQuery } from '~/store/api';
import useFormDirty from '~/hooks/useFormDirty';

const StyledAdminFormLayout = styled(AdminFormLayout)`
  min-height: 60vh;
`;

const AdminPcsForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
    onFinishCallback: (value?: boolean) => void;
    createMode?: boolean;
  }
> = ({ createMode }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id }: { id?: string | null } = createMode
    ? { id: null }
    : useParams() || {};
  const isNew = !id;
  if (isNew) {
    history.replace(ROUTER_PATHS.PAGE_NOT_FOUND());
  }

  const { data: companies } = useGetAllCompaniesQuery();
  const indexedCompanies = Object.values(
    R.indexBy(R.prop('id'), companies ?? [])
  );

  const {
    isDirty,
    onValuesChange: onValuesChangeIsDirty,
    setIsDirty,
  } = useFormDirty();

  const isAnzaActive = Form.useWatch('is_anza_activated', form) || false;

  const onSave = async () => {
    try {
      await form.validateFields();
      setIsDirty(false);
    } catch (error) {
      console.log('error', error);
      formErrorScroll();
    }
  };

  return (
    <StyledAdminFormLayout>
      <Form
        form={form}
        onFinish={() => {}}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        onValuesChange={onValuesChangeIsDirty}
      >
        <Form.Item
          name="is_anza_activated"
          label={<FormItemLabel>Is Active</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <div style={{ marginBottom: '20px' }}>
          Last Updated: {dayjs('').format('MM/D/YY')}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <SelectFormItem
            name="company_id"
            label={<FormItemLabel>Company</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredRule] : notRequiredRules}
            required={isAnzaActive}
            selectProps={{
              placeholder: 'Select a company',
              showSearch: true,
              filterOption: selectFilterOption,
              filterSort: selectFilterSort,
            }}
          >
            {indexedCompanies
              .sort((optionA: { name: string }, optionB: { name: string }) =>
                optionA.name
                  .toLowerCase()
                  .localeCompare(optionB.name.toLocaleLowerCase())
              )
              .map(({ id: companyId, name }) => (
                <Option key={companyId} value={companyId}>
                  {name}
                </Option>
              ))}
          </SelectFormItem>
          <Form.Item
            name="name"
            label={<FormItemLabel>Name</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              ...(isAnzaActive ? [requiredRule] : notRequiredRules),
              { whitespace: true, message: 'Name cannot be blank' },
            ]}
            required={isAnzaActive}
          >
            <Input
              required={isAnzaActive}
              name="name"
              placeholder="Insert Module Name"
            />
          </Form.Item>
          <Form.Item
            label={<FormItemLabel>Module UUID</FormItemLabel>}
            labelCol={{ span: 24 }}
          >
            <InputAnt.Group compact>
              <InputAnt
                style={{
                  width: 'calc(100% - 64px)',
                  borderRadius: 0,
                }}
                defaultValue=""
                disabled
              />
              <Tooltip title="Copy UUID">
                <Button
                  style={{ width: '32px', height: '32px', padding: 0 }}
                  icon={<IconCopy />}
                  onClick={() => {
                    navigator.clipboard.writeText('').then(() => {
                      message.success('Module UUID copied to clipboard');
                    });
                  }}
                />
              </Tooltip>
              <Tooltip title="Visit Module">
                <Link
                  to={ROUTER_PATHS.modules.MODULE_DETAILS()}
                  style={{ borderRadius: 0 }}
                >
                  <Button
                    icon={<IconView />}
                    style={{
                      borderRadius: 0,
                      width: '32px',
                      height: '32px',
                      padding: 0,
                    }}
                  />
                </Link>
              </Tooltip>
            </InputAnt.Group>
          </Form.Item>
        </div>
        <FormFooter
          isDirty={isDirty}
          saveButtonProps={{
            onClick: onSave,
          }}
        />
      </Form>
    </StyledAdminFormLayout>
  );
};

AdminPcsForm.defaultProps = {
  createMode: false,
};

export default AdminPcsForm;
