import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import useAuth from '~/hooks/useAuth';
import { OverlayLoader } from '~/UI/Overlay';
import { ROUTER_PATHS } from '~/router/Paths';
import { Errors, getURLParams } from '~/utils';
import { logoutUser as logoutUserAction } from '~/store/user';
import PendingVerification from '~/components/PendingVerification';

const Auth: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // eslint-disable-next-line prettier/prettier
  const params = getURLParams<{ code: string; error: string; action: string }>(
    location
  )!;
  const { isLoading, authorizeUser, signOutUser } = useAuth();
  const [hasErrors, setHasErrors] = React.useState<string | undefined>(
    undefined
  );

  const handleRedirectWithCode = async () => {
    try {
      await authorizeUser();
      history.push(ROUTER_PATHS.HOME());
    } catch (error: any) {
      if (
        error.message === Errors.EMAIL_NOT_VERIFIED ||
        error.message === Errors.NOT_ALLOWED ||
        error.message === Errors.NETWORK_ERROR
      ) {
        setHasErrors(error.message);
      } else {
        signOutUser();
      }
    }
  };

  const handleRedirectWithAction = (action: string) => {
    history.push(ROUTER_PATHS.LOGIN());
    if (action === 'logout') dispatch(logoutUserAction());
  };

  const handleRedirectWithError = () => {
    signOutUser();
  };

  React.useEffect(() => {
    (async () => {
      if (params && params.action) {
        return handleRedirectWithAction(params.action);
      }

      if (params && params.error) {
        return handleRedirectWithError();
      }

      return handleRedirectWithCode();
    })();
  }, []);

  return (
    <>
      {isLoading || (!hasErrors && <OverlayLoader />)}

      {hasErrors && <PendingVerification errors={hasErrors} />}
    </>
  );
};

export default Auth;
