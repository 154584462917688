import React, { useState, useEffect } from 'react';
import { isEmpty, pick, values } from 'ramda';
import styled from '@emotion/styled';
import dayjs, { Dayjs } from 'dayjs';
import { CrudOperations } from 'api/axios';
import { RouteComponentProps, useParams } from 'react-router';
import {
  Alert,
  Form,
  Switch,
  notification,
  FormItemLabel,
  Divider,
} from '~/UI';
import { SpinnerMask } from '~/UI/Spinner';
import {
  catchError,
  requiredRule,
  anzaProjectHistoryStatuses,
  anzaProjectTypes,
  ProjectStatuses,
  omitProjectDetailsValues,
  pickProjectDetailsValues,
  formErrorScroll,
  ProjectTypes,
} from '~/utils';
import { BoxTitle } from '~/UI/Typography';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { getModules } from '~/services/api/modules';
import {
  useGetProjectV3Query,
  useUpdateSolarProjectV3Mutation,
} from '~/store/api/projects/projectsApi';

import { blockEnterKeyEvents } from '~/utils/events';
import AdminProjectsFormHeader from '~/components/Admin/Projects/AdminProjectsForm/AdminProjectsFormHeader';
import AdvancedInputFormGroup from '~/components/Projects/solar/SolarAdvancedInputsFormGroup';
import {
  useApprovedStorageProjectMutation,
  useGetProjectQuery,
  useUnapprovedStorageProjectMutation,
  useUpdateSolarProjectMutation,
  useUpdateStorageProjectMutation,
} from '~/store/api';
import {
  ProjectDetails,
  ProjectType,
  SolarAdvancedInputs,
  SolarProject,
  StorageAdvancedInputs,
  TaxCredit,
} from '~/store/project';
import { transformAdvancedInputs } from '~/components/Projects/shared/helpers';
import StorageAdvancedInputsFormGroup from '~/components/Projects/storage/StorageAdvancedInputsFormGroup';
import { TEST_DATA_COMPONENTS, TestIdProps } from '~/utils/dataTestProps';
import { GenericLabel } from '~/components/Projects/FormGroup';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';
import { useApiWrapper } from '~/hooks/useApiWrapper';
import { useGetProjectSpecificPricingQuery } from '~/store/api/admin/projectSpecificPricingApi';
import type { ModuleType } from '~/types/modules';

import { ProjectSpecificPricing } from '~/components/Admin/Projects/ProjectSpecificPricing';
import { HistoricEngineTable } from '~/components/Admin/Projects/HistoricEngineTable';
import { useFlags } from '~/utils/launchdarkly';
import ProjectDataFormGroup from './AdminProjectsForm/ProjectDataFormGroup';
import SolarDetailsFormGroup from './AdminProjectsForm/SolarDetailsFormGroup';
import StorageDetailsFormGroup from './AdminProjectsForm/StorageDetailsFormGroup';
import { TaxCredits } from '~/components/Projects/solar/EditComponents/TaxCredits';
import { EnergyTerms } from '~/components/Projects/solar/EditComponents/EnergyTerms';

interface ProjectPayload {
  arrival_date: Dayjs;
  size_in_kw: number;
  name: string;
  sf_opportunity_id: string;
  project_address: string;
  racking_type: string;
  user_id: number;
}

const AddressAlert = styled.div`
  margin-bottom: 20px;
`;

const AdvancedInputFormGroupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem;
`;

const TaxCreditWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 40px;
`;

interface User {
  id: number;
  email: string;
}

const storageKeys: (keyof ProjectDetails)[] = [
  'storage_arrival_date',
  'storage_notes',
  'anticipated_purchase_date',
  'metering_location',
  'target_ac_power_mw',
  'duration_hours',
  'power_factor',
  'annual_cycle_count',
  'project_topology',
  'capacity_maintenance_term',
  'capacity_maintenance_strategy',
  'depth_of_discharge',
  'resting_state_of_charge',
  'includes_augmentation',
  'pcs_warranty_purchase',
  'pcs_maintenance_purchase',
  'dc_block_warranty_purchase',
  'dc_block_maintenance_purchase',
];
const solarKeys: (keyof ProjectDetails)[] = [
  'size_in_kw',
  'racking_type',
  'arrival_date',
  'anticipated_purchase_date',
];
const commonKeys: (keyof ProjectDetails)[] = [
  'project_address',
  'uuid',
  'status',
  'project_type',
  'latitude',
  'longitude',
  'state',
  'google_place_id',
  'user_id',
];

type ProjectFetcher = typeof useGetProjectQuery | typeof useGetProjectV3Query;

const AdminProjectsForm: React.FC<
  RouteComponentProps & {
    service: CrudOperations;
  }
> = ({ history }) => {
  const { id }: { id: string } = useParams() || {};
  const [form] = Form.useForm();
  const [user, setUser] = useState<User>();
  const [projectType, setProjectType] = useState('');
  const [projectUuid, setProjectUuid] = useState('');
  const [projectDetailsUuid, setProjectDetailsUuid] = useState('');
  const [isStorageApproved, setIsStorageApproved] = useState(false);
  const [modules, setModules] = useState<ModuleType[]>([]);
  const [hasLatLng, setHasLatLng] = useState(false);
  const flags = useFlags();
  const testIdData = { component: TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER };
  const useProjectFetcher: ProjectFetcher = flags.solarPricingPolicy
    ? useGetProjectV3Query
    : useGetProjectQuery;

  const { data, isFetching } = useProjectFetcher({ id });

  const [updateSolarProjectV2, { isLoading: isLoadingSaveProjectV2 }] =
    useUpdateSolarProjectMutation();
  const [updateSolarProjectV3, { isLoading: isLoadingSaveProjectV3 }] =
    useUpdateSolarProjectV3Mutation();
  const [updateStorageProject, { isLoading: isSavingStorageProject }] =
    useUpdateStorageProjectMutation();
  const [approveStorageProject] = useApprovedStorageProjectMutation();
  const [unapprovedStorageProject] = useUnapprovedStorageProjectMutation();

  const updateSolarProject = flags.solarPricingPolicy
    ? updateSolarProjectV3
    : updateSolarProjectV2;
  const isSavingProject = flags.solarPricingPolicy
    ? isLoadingSaveProjectV3
    : isLoadingSaveProjectV2;

  const valuesList = data?.project
    ? [
        ...commonKeys,
        ...(data.project.project_details.project_type === 'storage'
          ? storageKeys
          : solarKeys),
        'is_test',
        'availability_override',
        'isApproved',
        'name',
        'sf_opportunity_id',
      ]
    : [];

  const checkInvalid = () => {
    return form.validateFields(valuesList);
  };

  const taxCredits = data?.project?.tax_credits?.filter(
    (item) => item.credit_type !== 'ENERGY_TERM'
  );

  useEffect(() => {
    if (data) {
      const { project } = data;
      setUser(project.owner);
      form.setFieldsValue({
        ...pick(valuesList, project.project_details),
        name: project.name || '',
        sf_opportunity_id: project.sf_opportunity_id || '',
        arrival_date: project.project_details.arrival_date
          ? dayjs(project.project_details.arrival_date)
          : null,
        storage_arrival_date: project.project_details.storage_arrival_date
          ? dayjs(project.project_details.storage_arrival_date)
          : null,
        anticipated_purchase_date: project.project_details
          .anticipated_purchase_date
          ? dayjs(project.project_details.anticipated_purchase_date)
          : null,
        user_id: project.owner.id,
        tax_credits: taxCredits?.map((item) => ({
          base_credit: item.base_credit,
          credit_type: item.credit_type,
          escalation_rate: item.escalation_rate,
          project_id: item.project_id,
        })),
        is_itc: project.project_details.is_itc ? 'ITC' : 'PTC',
        energy_terms: project?.tax_credits
          ?.filter((item) => item.credit_type === 'ENERGY_TERM')
          .map((item) => ({
            base_credit: item.base_credit,
            credit_type: item.credit_type,
            duration: item.duration,
            escalation_rate: item.escalation_rate,
            project_id: item.project_id,
            start_year: item.start_year,
          })),
      });
      setProjectUuid(project.uuid);
      setProjectDetailsUuid(project.project_details.uuid);
      setIsStorageApproved(!!project.project_details.is_storage_approved);
      setHasLatLng(
        !!project.project_details.latitude &&
          !!project.project_details.longitude
      );
      checkInvalid();
    }
  }, [data]);

  const { executeApi } = useApiWrapper();

  const handleProjectTypeChange = (value: string) => {
    setProjectType(value);
  };

  useEffect(() => {
    setProjectType(form.getFieldValue('project_type'));
  }, [form.getFieldValue('project_type')]);

  const handleGetModules = async () => {
    try {
      const response = await executeApi<ModuleType[]>({
        service: () => getModules(),
      });
      setModules(response.data);
    } catch (error) {
      catchError({ error });
    }
  };
  useEffect(() => {
    if (projectType === 'solar') {
      handleGetModules();
    }
  }, [projectType, id]);

  const onFinish = async ({
    arrival_date,
    storage_arrival_date,
  }: ProjectPayload & { storage_arrival_date: dayjs.Dayjs }) => {
    try {
      const { notes, isApproved, energy_terms, ...currentFields } =
        transformAdvancedInputs(
          form.getFieldsValue(true),
          data!.project.advancedInputs
        );
      const promises = [];
      [
        'target_ac_power_mw',
        'duration_hours',
        'power_factor',
        'annual_cycle_count',
        'capacity_maintenance_term',
      ].forEach((field) => {
        if (data?.project.project_details.project_type === 'storage') {
          currentFields[field] = Number(currentFields[field]);
        }
      });

      const tax_credits = [
        ...(energy_terms?.map((energyTerm: TaxCredit) => ({
          ...energyTerm,
          updated_by: user?.id,
        })) || []),
        ...currentFields.tax_credits.map((taxCredit: TaxCredit) => ({
          ...taxCredit,
          updated_by: user?.id,
        })),
      ];

      promises.push(
        data?.project.project_details.project_type === 'storage'
          ? updateStorageProject({
              uuid: data!.project.uuid,
              name: currentFields.name,
              owner_id: currentFields.user_id,
              ...omitProjectDetailsValues(currentFields),
              project_details: {
                ...pickProjectDetailsValues(currentFields),
                arrival_date: arrival_date && arrival_date.format(),
                storage_arrival_date:
                  storage_arrival_date && storage_arrival_date.format(),
              },
            })
          : updateSolarProject({
              uuid: data!.project.uuid,
              name: currentFields.name,
              owner_id: currentFields.user_id,
              ...omitProjectDetailsValues(currentFields),
              project_details: {
                ...pickProjectDetailsValues(currentFields),
                is_itc: currentFields.is_itc === 'ITC',
                tax_credits,
                arrival_date: arrival_date && arrival_date.format(),
              },
            })
      );
      await Promise.all(promises);

      // refreshing the page clears out lots of weird states
      history.go(0);
      return;
    } catch (error: unknown) {
      form.setFieldsValue({ isApproved: false });
      throw error;
    }
  };

  const handleStorageApproval = async () => {
    try {
      if (isStorageApproved) {
        unapprovedStorageProject({
          id: data?.project.project_details.id,
        });
      } else {
        approveStorageProject({
          id: data?.project.project_details.id,
        });
        form.setFieldsValue({ status: ProjectStatuses.PROPOSAL });
      }
      setIsStorageApproved(!isStorageApproved);
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Something went wrong.',
        description:
          'It looks like there was an error while updating the approval of the module. Please refresh your browser and try again.',
      });
    }
  };

  const onSave = async () => {
    try {
      await checkInvalid();
    } catch (error) {
      formErrorScroll();
    }
  };

  const { isDirty, onValuesChange, setIsDirty } = useFormDirty();

  const isSolar = projectType === ProjectTypes.SOLAR;

  const { data: projectSpecificPricingData = [] } =
    useGetProjectSpecificPricingQuery({ id });

  return (
    <>
      {isFetching ? (
        <SpinnerMask />
      ) : (
        <AdminFormLayout>
          {!hasLatLng ? (
            <AddressAlert>
              <Alert
                type="error"
                message="It looks like this Project does not yet have an address with Lat/Lng. Please be sure to update the address"
                showIcon
              />
            </AddressAlert>
          ) : null}
          <Form
            form={form}
            onFinish={onFinish}
            onKeyDown={blockEnterKeyEvents}
            onValuesChange={onValuesChange}
          >
            <AdminProjectsFormHeader
              projectType={projectType as ProjectType}
              projectDetailsUUID={projectDetailsUuid}
              projectUuid={projectUuid}
            />
            <ProjectDataFormGroup
              projectType={projectType}
              form={form}
              user={user}
              statuses={anzaProjectHistoryStatuses}
              projectTypes={anzaProjectTypes}
              onProjectTypeChange={handleProjectTypeChange}
              onChange={checkInvalid}
            />

            {projectType === 'solar' && (
              <>
                <SolarDetailsFormGroup
                  form={form}
                  projectStatus={data!.project.project_details.status}
                  projectUuid={projectUuid}
                />
                <AdvancedInputFormGroupContainer>
                  <AdvancedInputFormGroup
                    form={form}
                    defaultAdvancedInputs={
                      data!.project.advancedInputs as SolarAdvancedInputs
                    }
                  />
                </AdvancedInputFormGroupContainer>
              </>
            )}

            {projectType === 'storage' && (
              <>
                <StorageDetailsFormGroup
                  form={form}
                  requiredRule={requiredRule}
                  projectUuid={projectUuid}
                  isStorageApproved={isStorageApproved}
                  onHandleStorageApproval={handleStorageApproval}
                />
                <BoxTitle style={{ marginTop: '1.5rem' }}>
                  Advanced Inputs
                </BoxTitle>
                <AdvancedInputFormGroupContainer>
                  <StorageAdvancedInputsFormGroup
                    form={form}
                    defaultAdvancedInputs={
                      data!.project.advancedInputs as StorageAdvancedInputs
                    }
                  />
                </AdvancedInputFormGroupContainer>
              </>
            )}
            <Form.Item
              name="is_test"
              label={<FormItemLabel>Is Test</FormItemLabel>}
              labelCol={{ span: 24 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            {projectType === ProjectTypes.SOLAR && (
              <Form.Item
                name="availability_override"
                label={
                  <GenericLabel
                    testIdData={
                      {
                        identifier: 'modules-limited-availability',
                      } as TestIdProps
                    }
                    title="Show Modules with Limited Availability"
                    fontSize="14px"
                    popoverContent="Include all modules with a non-zero availability in the ranking table"
                  />
                }
                labelCol={{ span: 24 }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            )}
            {isSolar && flags.taxCredit && (
              <TaxCreditWrapper>
                <TaxCredits
                  form={form}
                  testIdData={testIdData}
                  disabledInputs={false}
                />
                <Divider />
                {flags.energyTerms && (
                  <EnergyTerms
                    form={form}
                    project={data?.project as SolarProject}
                    disableEdition={false}
                    setIsDirty={setIsDirty}
                  />
                )}
              </TaxCreditWrapper>
            )}

            {isSolar && (
              <ProjectSpecificPricing
                data={projectSpecificPricingData}
                modules={modules}
                projectUuid={data?.project?.uuid ?? ''}
                project={data?.project as SolarProject}
              />
            )}

            <FormFooter
              isDirty={isDirty}
              saveButtonProps={{
                disabled: isSavingProject || isSavingStorageProject,
                loading: isSavingProject || isSavingStorageProject,
                onClick: onSave,
              }}
            />
          </Form>
          {projectType === 'solar' && (
            <HistoricEngineTable
              project={data?.project}
              data={data?.engineResultsList}
            />
          )}
        </AdminFormLayout>
      )}
    </>
  );
};

export default AdminProjectsForm;
