import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { TestIdProps } from '~/utils/dataTestProps';
import { Badge, ButtonAnt as Button, Space, TagAnt } from '~/UI';
import ArrowDropdown from '~/UI/Icons/ArrowDropdown';
import { theme } from '~/utils';
import useToggle from '~/hooks/useToggle';
import { FilterConfig, FilterValues } from './FilterForm';
import GroupFilterDropdown, {
  DropdownType,
  type ActionsLocation,
  type GroupFilterDropdownProps,
} from './GroupFilterDropdown';
import FilterTag, { IconPosition } from './FilterTag';
import { Option } from './Filter';
import SubFilterMenu from './SubFilterMenu';
import FilterAnchorWrapper from './FilterAnchorWrapper';

export const StyledButtonAnt = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.primary};
  &.ant-dropdown-open {
    border: 1px solid ${theme.colors.primary};
    background-color: ${theme.colors.primary}10;
  }
`;

const StyledFilterTag = styled(TagAnt)`
  background-color: transparent !important;
`;

export const ALL_FILTERS_KEY = 'all-filters';

export type FilterMenuOption = Option & {
  /** Used to show the value when a single filter in that group is selected on the grouped filter dropdown button */
  title: string;
};

export type FilterMenuFilterConfig = Omit<FilterConfig, 'options'> & {
  options: FilterMenuOption[];
  hasHeaderClearButton?: boolean;
  /** Used to show the Group Dropdown Filter title */
  dropdownGroupTitle: string;
};

export type OnFilterMenuValueChange = (event: {
  key: string;
  value: string;
  action: 'add' | 'remove';
  changedValue: FilterValues;
  allValues: FilterValues;
}) => void;

export type FilterMenuProps = {
  // ----- Main dropdown options
  mainDropdownLabel?: React.ReactNode;
  mainDropdownIcon?: React.ReactNode;
  mainDropdownIconPosition?: IconPosition;
  mainDropdownPlacement?: GroupFilterDropdownProps['dropdownPlacement'];
  hideMainDropdown?: boolean;
  disableMainDropdown?: boolean;
  mainDropdownAutoMaxWidth?: boolean;
  showMainDropdownResetButton?: boolean;
  hideMainDropdownClearAllButton?: boolean;
  mainDropdownButtonDataTestId?: string;
  mainDropdownType?: DropdownType;
  mainDropdownCloseOnClearAll?: boolean;
  /** If the filter has at least one value selected it highlights the filter with the primary color. DEFAULT = true */
  mainDropdownHighlight?: boolean;
  /** Selects the location of actions button (Reset, Clear All, ...). */
  mainDropdownActionsLocation?: ActionsLocation;

  // ----- Group dropdown options
  hideGroupDropdowns?: boolean;
  /** If true, it will hide dropdowns filter groups if there is no selected filter in that group */
  hideEmptyGroupDropdowns?: boolean;
  groupDropdownsShowSingleSelectedFilter?: boolean;

  // ----- All Dropdown options
  hideDropdownCount?: boolean;
  /** If true, when an option is selected in any dropdown, the dropdown is closed */
  closeDropdownOnSelection?: boolean;
  defaultValues?: FilterValues;
  filters: FilterMenuFilterConfig[];
  onClearAllFilters?: () => void;
  onResetFilters?: () => void;
  onClearFilter?: (filterName: string) => void;
  dataTestIdConfig?: TestIdProps;
  onValuesChange?: (values: FilterValues) => void;
  limit?: number;
  initialValues?: FilterValues;
  style?: React.CSSProperties;
  gtmId?: string;
  gtmType?: string;
  includeGtmProperties?: boolean;
  forcedVisibleGroups?: string[];
  noContainer?: boolean;
  onValueChange?: OnFilterMenuValueChange;
  /** Use this to controll the state from outside the FilterMenu component.
   * If you want to just set the initial values use initialValues instead. */
  values?: FilterValues;
  /** Use this to controll the state from outisde the FilterMenu component */
  openDropdown?: string;
  onOpenDropdownChange?: (dropdown: string, open: boolean) => void;
  buttonComponent?: ReactElement;
};

const FilterMenu = ({
  initialValues,
  defaultValues,
  filters,
  onClearAllFilters,
  onClearFilter,
  dataTestIdConfig,
  disableMainDropdown,
  mainDropdownButtonDataTestId,
  onValuesChange,
  mainDropdownLabel,
  hideGroupDropdowns,
  limit,
  showMainDropdownResetButton = false,
  hideMainDropdownClearAllButton = false,
  hideMainDropdown,
  onResetFilters,
  style,
  gtmId,
  gtmType,
  includeGtmProperties = false,
  mainDropdownIconPosition,
  mainDropdownActionsLocation,
  mainDropdownAutoMaxWidth,
  mainDropdownPlacement,
  mainDropdownCloseOnClearAll,
  hideEmptyGroupDropdowns = false,
  forcedVisibleGroups = [],
  mainDropdownIcon,
  hideDropdownCount,
  noContainer,
  groupDropdownsShowSingleSelectedFilter,
  onValueChange,
  values,
  openDropdown: controlledOpenDropdown,
  closeDropdownOnSelection,
  mainDropdownType,
  mainDropdownHighlight = true,
  onOpenDropdownChange,
  buttonComponent,
}: FilterMenuProps) => {
  const { isOpen: isIconUp, toggle: toggleIcon } = useToggle();
  const [filterValues, setFilterValues] = useState<FilterValues>(
    initialValues || {}
  );
  const allFiltersRef = useRef<HTMLDivElement>(null);
  const [openDropdown, setOpenDropdown] = useState<string | undefined>();

  useEffect(() => {
    if (
      !R.isNil(controlledOpenDropdown) &&
      controlledOpenDropdown !== openDropdown
    )
      setOpenDropdown(controlledOpenDropdown);
  }, [controlledOpenDropdown]);

  useEffect(() => {
    if (values && !R.equals(values, filterValues)) {
      setFilterValues(values);
    }
  }, [values]);

  const clearAllFilters = () => {
    if (mainDropdownCloseOnClearAll) setOpenDropdown(undefined);
    setFilterValues({});
    if (onClearAllFilters) onClearAllFilters();
    onValuesChange?.({});
  };

  const resetFilters = () => {
    setFilterValues(defaultValues || {});
    if (onResetFilters) onResetFilters();
    onValuesChange?.(defaultValues || {});
  };

  const clearGroupFilter = (filter: string) => {
    setFilterValues((prev) => {
      const newValues = { ...prev, [filter]: [] };
      if (onClearFilter) onClearFilter(filter);
      onValuesChange?.(newValues);
      return newValues;
    });
  };

  const onFilterValuesChange = (
    changedValue: FilterValues,
    values: FilterValues
  ) => {
    setFilterValues((prev) => {
      // This form handles only one field at a time so we know the first one is the correct key
      const key = R.keys(changedValue)[0];
      const valuesArray = R.values(changedValue).flat() as string[];
      const previousArray = (prev[key] || []) as string[];
      const action =
        valuesArray.length > previousArray.length ? 'add' : 'remove';
      // Finding the only different element
      const value = valuesArray
        .filter((x) => !previousArray.includes(x))
        .concat(previousArray.filter((x) => !valuesArray.includes(x)))[0];
      onValueChange?.({
        key: String(key),
        value,
        action,
        changedValue,
        allValues: values,
      });
      const newValues = { ...prev, ...values };
      onValuesChange?.(newValues);
      if (closeDropdownOnSelection && action === 'add')
        setOpenDropdown(undefined);

      return newValues;
    });
  };

  const valuesCount = R.values(filterValues)
    .filter((val) => !R.isNil(val))
    .flat().length;

  const content = (
    <>
      {!hideMainDropdown && (
        <GroupFilterDropdown
          filters={filters}
          onClearAllFilters={clearAllFilters}
          onClearFilter={clearGroupFilter}
          dataTestIdConfig={dataTestIdConfig}
          anchorRef={allFiltersRef}
          disableDropdown={disableMainDropdown}
          values={filterValues}
          onValuesChange={onFilterValuesChange}
          limit={limit}
          showResetButton={showMainDropdownResetButton}
          showClearAllButton={hideMainDropdownClearAllButton}
          onResetAllFilters={resetFilters}
          gtmId={gtmId}
          gtmType={gtmType}
          includeGtmProperties={includeGtmProperties}
          actionsLocation={mainDropdownActionsLocation}
          dropdownAutoMaxWidth={mainDropdownAutoMaxWidth}
          dropdownPlacement={mainDropdownPlacement}
          onOpenChange={toggleIcon}
          open={openDropdown === ALL_FILTERS_KEY}
          type={mainDropdownType}
        >
          <FilterAnchorWrapper ref={allFiltersRef}>
            {buttonComponent ? (
              <StyledFilterTag
                data-testid={mainDropdownButtonDataTestId}
                onClick={() => setOpenDropdown(ALL_FILTERS_KEY)}
              >
                {buttonComponent}
              </StyledFilterTag>
            ) : (
              <FilterTag
                data-testid={mainDropdownButtonDataTestId}
                color={
                  mainDropdownHighlight && valuesCount
                    ? 'primary'
                    : 'transparent'
                }
                icon={
                  mainDropdownIcon || (
                    <ArrowDropdown
                      rotate={isIconUp}
                      fill={valuesCount ? theme.colors.primary : undefined}
                    />
                  )
                }
                iconPosition={mainDropdownIconPosition}
                label={
                  <>
                    {!!valuesCount && !hideDropdownCount && (
                      <Badge color={theme.colors.primary} count={valuesCount} />
                    )}
                    {mainDropdownLabel}
                  </>
                }
                onClick={() => setOpenDropdown(ALL_FILTERS_KEY)}
              />
            )}
          </FilterAnchorWrapper>
        </GroupFilterDropdown>
      )}
      {!hideGroupDropdowns &&
        filters.map((filterConfig) => {
          const currentFillterGroupIsEmpty =
            R.isEmpty(filterValues[filterConfig.name]) ||
            R.isNil(filterValues[filterConfig.name]);
          const currentFilterIsForcedVisibility = Boolean(
            forcedVisibleGroups.find((g) => g === filterConfig.name)
          );
          const visible =
            !hideEmptyGroupDropdowns ||
            (hideEmptyGroupDropdowns &&
              (!currentFillterGroupIsEmpty || currentFilterIsForcedVisibility));
          return visible ? (
            <SubFilterMenu
              key={filterConfig.name}
              filterConfig={filterConfig}
              filterValues={filterValues}
              clearGroupFilter={clearGroupFilter}
              onFilterValuesChange={onFilterValuesChange}
              showSingleSelectedFilter={groupDropdownsShowSingleSelectedFilter}
              hideDropdownCount={hideDropdownCount}
              open={openDropdown === filterConfig.name}
              onDropdownButttonClick={() => setOpenDropdown(filterConfig.name)}
              onOpenChange={(open) =>
                onOpenDropdownChange?.(filterConfig.name, open)
              }
            />
          ) : null;
        })}
    </>
  );

  return noContainer ? (
    content
  ) : (
    <Space style={style} size="small" direction="horizontal">
      {content}
    </Space>
  );
};

export default FilterMenu;
