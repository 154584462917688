import React, { useEffect, useState } from 'react';
import { Popover, Tag } from 'antd';
import styled from '@emotion/styled';
import { Radio } from '~/UI';
import { RadioGroup } from '~/UI/Radio';
import { theme } from '~/utils';
import { RACKING_TYPE } from '~/utils/rackingTypes';

const StyledRadioContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`;
const StyledRadio = styled(Radio)`
  margin: 0.5em 0;
`;
const StyledTag = styled(Tag)`
  align-self: stretch;
  border-radius: 15px;
  background-color: ${theme.colors.grey};
  cursor: pointer;
  color: ${theme.fonts.colors.primary};
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid ${theme.colors.primary300};
  &:hover {
    background-color: ${theme.colors.primary}29;
  }
`;

export type FilterOption =
  | 'effective-price'
  | 'price'
  | 'epc-savings'
  | 'revenue-value'
  | 'epc_plus_revenue';

type SortingOption = {
  name: string;
  value: FilterOption;
};

const sortingOptions: SortingOption[] = [
  {
    value: 'effective-price',
    name: 'Effective $/W',
  },
  {
    value: 'price',
    name: 'Purchase Price',
  },
  {
    value: 'epc-savings',
    name: 'BOS Savings',
  },
  {
    value: 'revenue-value',
    name: 'Production Benefit',
  },
  {
    value: 'epc_plus_revenue',
    name: 'BOS Savings + Production Benefit',
  },
];

type SortingMenuProps = {
  onFilterChange: (filter: FilterOption) => void;
  rackingType?: string;
};

export const SortingMenu = ({
  onFilterChange,
  rackingType,
}: SortingMenuProps) => {
  const [selected, setSelected] = useState(sortingOptions[0]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    onFilterChange(selected.value);
  }, [selected]);

  const showBOSSavings = rackingType !== RACKING_TYPE.ROOFTOP_MLPE;
  const filteredSortingOptions = sortingOptions.filter(
    (option) =>
      showBOSSavings ||
      (option.value !== 'epc-savings' && option.value !== 'epc_plus_revenue')
  );

  return (
    <Popover
      content={
        <StyledRadioContainer
          onChange={(e) => {
            setSelected(
              sortingOptions.find((val) => val.value === e.target.value) ??
                sortingOptions[1]
            );
          }}
          value={selected.value}
        >
          {filteredSortingOptions.map((option) => (
            <StyledRadio
              value={option.value}
              key={`sorting-option-${option.value}`}
              onClick={() => setOpen(false)}
            >
              {option.name}
            </StyledRadio>
          ))}
        </StyledRadioContainer>
      }
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={(e) => {
        setOpen(e);
      }}
    >
      <StyledTag onClick={() => setOpen(true)}>
        Sort by: {selected.name}
      </StyledTag>
    </Popover>
  );
};
