import React from 'react';
import { ColumnsType } from 'antd/lib/table';

import { TableProps } from '~/UI/Table';
import ProjectDetailsTable from '~/components/Projects/shared/ProjectDetailsTable.shared';
import { EngineResultSizingArchitectureRow } from './StorageModuleTable';

interface TableComponentProps extends TableProps {
  data: EngineResultSizingArchitectureRow[]; // Add more types if this is reused
  columns: ColumnsType;
  isLoading: boolean;
  hideBorder?: boolean;
}

const TableComponent: React.FC<TableComponentProps> = ({
  data,
  columns,
  isLoading,
  rowKey = 'uuid',
  hideBorder,
  ...rest
}) => {
  return (
    <ProjectDetailsTable
      {...rest}
      rowKey={rowKey}
      dataSource={data}
      loading={isLoading}
      columns={columns}
      hideBorder={hideBorder}
    />
  );
};

export default TableComponent;
