import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import CollapsibleHeroModule from '~/UI/CollapsibleHeroModule';
import { H6Strong, Label } from '~/UI/Typography';
import { breakpoints, theme } from '~/utils';
import { Spinner, Affix, Avatar, ButtonAnt } from '~/UI';
import Skeleton from '~/UI/Skeleton';

import RankingChart, { CHIP_WIDTH } from './RankingChart';

// ModuleCard -----------------------------------------

const shadow = 'box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.16);';

export const MenuButton = styled(ButtonAnt)`
  height: 100%;
  transition: background-color 0.2s ease-out;
  border-radius: 12px;
  &:hover {
    background-color: ${theme.colors.gray200};
  }
`;

export const Container = styled.div<{
  clickable: boolean;
  isModuleHidden?: boolean;
}>`
  min-height: 94px;
  display: flex;
  background-color: ${theme.colors.white};
  border-radius: 12px;
  align-items: center;
  padding-left: 20px;
  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
  &:hover {
    ${shadow}
    position: relative;
  }
  transition: box-shadow 0.2s ease-out;
  ${({ isModuleHidden }) =>
    isModuleHidden ? `background-color: ${theme.colors.gray300};` : ''}
`;

export const ModuleCardContainer = styled.div`
  padding: 5px 0 20px 0;
  display: grid;
  grid:
    'Rank Avatar Info'
    'Hero Hero Hero';
  grid-template-columns: fit-content(50px) 50px;
  align-items: center;
  width: 100%;
  gap: 10px;
  @media (min-width: ${breakpoints.laptop}) {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 5px 0;
  }
`;

export const ModuleHero = styled(CollapsibleHeroModule)`
  width: 100%;
  grid-area: Hero;
  p {
    font-size: 11px;
    line-height: 14px;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
  }
  & > div:nth-child(3) {
    /* Separator */
    border-left: solid 1px ${theme.colors.gray300};
    padding-left: 10px;
  }
  & > div:nth-child(3) .ant-space-item {
    padding-left: 20px;
  }
`;

export const ModuleTagsList = styled.ul`
  display: flex;
  gap: 4px;
  margin: 0;
  margin-left: auto;
  @media (min-width: ${breakpoints.laptop}) {
    margin-left: 0;
  }
`;

export const ModuleTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 20px;
`;

export const ModuleName = styled(H6Strong)`
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledLabel = styled(Label)`
  grid-area: Rank;
  padding: 0;
  font-weight: 500;
  width: 20px;
  text-align: right;
`;

export const ModuleInformation = styled.div`
  grid-area: Info;
  min-width: 384px;
  display: flex;
  gap: 20px;
  align-items: center;
  @media (min-width: ${breakpoints.laptop}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
  }
`;

export const ModuleAvatar = styled(Avatar)`
  grid-area: Avatar;
  width: 48px;
  min-width: 48px;
  margin-right: 4px;
`;

export const ModuleAvatarPlaceholder = styled(ModuleAvatar)`
  background-color: rgba(16, 105, 125, 1);
`;

// Ranking -----------------------------------------

export const StyledChartAffix = styled(Affix)`
  grid-column-start: 2;
  grid-column-end: 3;
`;

export const RankingContainer = styled.div<{ margin?: number }>`
  ${({ margin }) => (margin ? `margin: ${margin}px 0;` : '')}
`;

export const ModuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 70vh;
  grid-column-start: 1;
  grid-column-end: 2;
  &:focus {
    background-color: ${theme.colors.gray200};
  }
`;

export const StyledRankingChart = styled(RankingChart)`
  background-color: white;
  border-radius: 1rem;
  width: 100%;
  border: 1px solid ${theme.colors.gray200};
`;

export const StateCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 630px;
  gap: 1rem;
  grid-column-start: 1;
  grid-column-end: 2;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray200};
  border-radius: 12px;
`;

export const AbsoluteChartContainer = styled.div<{ width?: number }>`
  position: absolute;
  right: 0;
  height: 100%;
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`;

// RankingChart -----------------------------------------

export const RankingChartContainer = styled.div<
  HTMLAttributes<HTMLDivElement> & { width: number; itemsAmount: number }
>`
  height: ${(props) => {
    const height = props.itemsAmount * 34 + 80;
    return height < 200 ? 200 : height;
  }}px;
  width: ${(props) => props.width}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0px 40px 0px;
`;

export const RankingChartModuleHighlight = styled.div<{
  selected?: boolean;
  highlighted?: boolean;
  width: number;
}>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width}px;
  height: 30px;
  border-radius: 6px;
  padding: 0 3px;
  gap: 8px;
  background-color: white;
  visibility: hidden;
  ${(props) =>
    props.selected ? `border: 1px solid ${theme.colors.primary};` : ''}
  ${(props) =>
    props.highlighted || props.selected
      ? 'box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16); visibility: visible;'
      : ''}
`;

export const Chip = styled.div`
  width: ${CHIP_WIDTH}px;
  height: fit-content;
  background-color: ${theme.colors.primary};
  color: white;
  padding: 6px;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 600;
  line-height: 8px;
`;

export const WhiteChip = styled(Chip)`
  background-color: ${theme.colors.white};
  color: ${theme.colors.gray800};
`;

export const EffectivePriceChip = styled(Chip)`
  width: fit-content;
`;

export const ChartTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const StyledSpinner = styled(Spinner)`
  margin-top: 3rem;
`;

export const TruncatedText = styled(H6Strong)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledSkeleton = styled(Skeleton)`
  width: fit-content;
`;

export const ManufacturerName = styled(H6Strong)`
  white-space: nowrap;
`;

// BarTooltip -----------------------------------------

export const Tooltip = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const TooltipTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  font-size: 11px;
`;

export const TooltipSubtitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  font-size: 11px;
`;

export const TooltipArrow = styled.i`
  position: absolute;
  top: 99%;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: white;
  }
`;
