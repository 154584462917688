import { AxiosResponse } from 'axios';
import { authApi, api, apiV2 } from '../../api/axios';
import { routes } from '../../api/routes';
import { catchError } from '../../utils';
import { UserType } from '../../types/users';

export interface ModelCredentials {
  email: string;
  password: string;
}

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const login = async (
  params: ModelCredentials
): Promise<AxiosResponse<UserType>> => {
  try {
    const { email, password } = params;

    const data = {
      email,
      password,
    };

    return await authApi.post(routes.auth.login(), data, config);
  } catch (error) {
    catchError({
      error,
      method: 'login',
      location: 'services/api/users',
    });
    throw error;
  }
};

interface RefreshTokenPayload {
  token: string;
}

export const refreshToken = async (): Promise<
  AxiosResponse<RefreshTokenPayload>
> => {
  try {
    return await authApi.post(routes.auth.refreshToken(), {}, config);
  } catch (error) {
    catchError({
      error,
      method: 'refreshToken',
      location: 'services/api/users',
    });
    throw error;
  }
};

export const resendVerificationEmail = async (
  token: string
): Promise<AxiosResponse> => {
  try {
    return await apiV2.post(
      routes.auth.resendVerificationEmail(),
      {},
      { ...config, headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    catchError({
      error,
      method: 'resendVerificationEmail',
      location: 'services/api/users',
    });
    throw error;
  }
};
