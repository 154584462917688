import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { DomainType } from '~/types/users';

export const adminDomainsApi = createApi({
  reducerPath: 'adminDomainsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AllDomains', 'AdminGetDomain'],
  endpoints: (builder) => ({
    getAllDomains: builder.query<DomainType[], void>({
      query: () => routes.admin.domains(),
      keepUnusedDataFor: 3,
      providesTags: () => [{ type: 'AllDomains', id: 'ALL_DOMAINS' }],
    }),
    getDomain: builder.query<DomainType, { id: string }>({
      query: ({ id }) => routes.admin.domainsSettings(id),
      keepUnusedDataFor: 3,
      providesTags: () => [{ type: 'AdminGetDomain', id: 'ADMIN_GET_DOMAIN' }],
    }),
    createDomain: builder.mutation<DomainType, { domain: DomainType }>({
      query: ({ domain }) => ({
        url: routes.admin.domains(),
        method: 'POST',
        body: domain,
      }),
    }),
    updateDomain: builder.mutation<DomainType, { domain: DomainType }>({
      query: ({ domain }) => {
        const { id } = domain;
        return {
          url: routes.admin.domainsSettings(id!),
          method: 'PUT',
          body: domain,
        };
      },
    }),
  }),
});

export const {
  useGetAllDomainsQuery,
  useGetDomainQuery,
  useCreateDomainMutation,
  useUpdateDomainMutation,
} = adminDomainsApi;
