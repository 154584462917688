import React from 'react';
import styled from '@emotion/styled';
import LogoAnza from 'assets/logo-anza.png';
import Background from 'assets/login-bg.svg';
import { poweredByBorrego } from 'assets';

const Container = styled.div`
  background-image: url(${Background});
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
`;

const Header = styled.header`
  display: flex;
  align-items: center;

  margin-top: 50px;
  padding: 0 6rem;
  width: 100%;
  min-height: 70px;
`;

const Content = styled.div`
  height: calc(100vh - 220px);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  min-height: 100px;

  background: #ffffff;
`;

const AnzaLogoImage = styled.img`
  width: 162px;
`;

const BorregoFooterImage = styled.img`
  height: 36px;
  margin-right: 2rem;
`;

const ActionContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface MarketingLayoutProps {
  actions?: React.ReactElement;
}

const MarketingLayout: React.FC<MarketingLayoutProps> = ({
  children,
  actions,
}) => {
  return (
    <Container>
      <Header>
        <AnzaLogoImage src={LogoAnza} alt="Logo Anza" />
        <ActionContainer>{actions}</ActionContainer>
      </Header>
      <Content>{children}</Content>
      <Footer>
        <BorregoFooterImage src={poweredByBorrego} />
      </Footer>
    </Container>
  );
};

MarketingLayout.defaultProps = {
  actions: <></>,
};

export default MarketingLayout;
