import React, { createElement, MouseEvent, useCallback, useMemo } from 'react';
import { BarItemProps } from '@nivo/bar';
import OutsideClickHandler from 'react-outside-click-handler';

import { useChartTooltip } from '~/hooks';

import {
  CHIP_WIDTH,
  MIN_BAR_WIDTH,
  MIN_RANGE_WIDTH,
  RankingDataWithDifference,
} from './RankingChart';
import { WhiteChip, Chip, RankingChartModuleHighlight } from './styles';
import RankingBar from './RankingBar';

const CARD_PADDING = 8;

type RankingBarData = BarItemProps<
  Omit<
    RankingDataWithDifference,
    | 'logoData'
    | 'tags'
    | 'quarterAvailability'
    | 'analytics_data'
    | 'isModuleHidden'
    | 'cell_technology'
  >
>;

export type RankingBarItemProps = RankingBarData;

export type BarComponentProps = {
  module: RankingDataWithDifference;
  barData: RankingBarData;
  onMouseOverBar?: (moduleId: string) => void;
  onMouseLeaveBar?: () => void;
  onClick?: (moduleId: string) => void;
  onClickOutside?: (moduleId: string) => void;
  highlighted: boolean;
  selected?: boolean;
  hiddenTooltip: boolean;
};

const BarComponent = ({
  barData,
  module,
  onMouseLeaveBar,
  onMouseOverBar,
  onClick,
  onClickOutside,
  highlighted,
  selected,
  hiddenTooltip,
}: BarComponentProps) => {
  const { bar, tooltip } = barData;
  const { data } = bar;
  const { showTooltipFromEvent, showTooltipAt, hideTooltip } =
    useChartTooltip();
  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );

  if (highlighted) {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY]);
  }
  if (hiddenTooltip) {
    hideTooltip();
  }

  const handleTooltip = useCallback(
    (event: MouseEvent<SVGRectElement>) =>
      showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip]
  );
  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      if (onMouseOverBar) onMouseOverBar(bar.data.indexValue.toString());
      showTooltipFromEvent(renderTooltip(), event);
    },
    [data, onMouseOverBar, showTooltipFromEvent, renderTooltip]
  );
  const handleMouseLeave = useCallback(() => {
    if (onMouseLeaveBar) onMouseLeaveBar();
    hideTooltip();
  }, [data, hideTooltip, onMouseLeaveBar]);

  if (bar.data.id === 'initial') return null;
  if (!module) return null;

  const effectivePriceButton = (
    <Chip>${module.module_effective_price_USD_per_W?.toFixed(3)}</Chip>
  );
  const actualPriceButton = (
    <WhiteChip>${module.module_customer_price_USD_per_W?.toFixed(3)}</WhiteChip>
  );

  const isEffectivePriceFirstButton = module.initialField === 'efective_price';

  const usingMinRangeWidth = bar.width < MIN_RANGE_WIDTH;

  const rangeWidth = usingMinRangeWidth ? MIN_RANGE_WIDTH : bar.width;
  let barWidth = 0;
  const firstButton = isEffectivePriceFirstButton
    ? effectivePriceButton
    : actualPriceButton;
  const secondButton = !isEffectivePriceFirstButton
    ? effectivePriceButton
    : actualPriceButton;

  let translateFirstButton = 0;
  let translateSecondButton = 0;

  let translateAllX = 0;

  if (!usingMinRangeWidth) {
    translateFirstButton = -CHIP_WIDTH / 2;
    translateSecondButton = rangeWidth - CHIP_WIDTH / 2;
    barWidth = rangeWidth - CHIP_WIDTH;
  }

  if (usingMinRangeWidth) {
    // Adjusting buttons relative to bar
    translateFirstButton = -CHIP_WIDTH / 2;
    translateSecondButton = CHIP_WIDTH / 2 + MIN_BAR_WIDTH;
    barWidth = MIN_BAR_WIDTH;
  }

  if (usingMinRangeWidth && !isEffectivePriceFirstButton) {
    // Adjusting buttons + bar relative to chart
    translateAllX = bar.width - MIN_BAR_WIDTH - CHIP_WIDTH;
  }

  const CARD_WIDTH = CHIP_WIDTH * 2 + barWidth + CARD_PADDING * 2 + 1;

  return (
    <g
      cursor="pointer"
      onMouseDown={() => onClick && onClick(module.moduleId)}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleTooltip}
      transform={`translate(${bar.x + translateAllX},${
        bar.y + bar.height / 2
      })`}
    >
      <foreignObject
        width={CARD_WIDTH + 2}
        height={35}
        transform={`translate(${-CHIP_WIDTH / 2 - CARD_PADDING},-13)`}
      >
        <OutsideClickHandler
          disabled={!selected}
          onOutsideClick={() =>
            onClickOutside && onClickOutside(module.moduleId)
          }
        >
          <RankingChartModuleHighlight
            width={CARD_WIDTH}
            highlighted={highlighted}
            selected={selected}
          />
        </OutsideClickHandler>
      </foreignObject>
      <foreignObject
        width={CHIP_WIDTH + 2}
        height={23}
        transform={`translate(${translateFirstButton},-8)`}
      >
        {firstButton}
      </foreignObject>
      <foreignObject
        width={CHIP_WIDTH + 2}
        height={23}
        transform={`translate(${translateSecondButton},-8)`}
      >
        {secondButton}
      </foreignObject>

      <RankingBar
        barWidth={barWidth}
        translateX={CHIP_WIDTH / 2}
        direction={module.initialField === 'efective_price' ? 'right' : 'left'}
      />
    </g>
  );
};

export default BarComponent;
