import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useFlags } from '~/utils/launchdarkly';
import { getUserFromLocalStorage } from '~/hooks/useAuth';
import { gaInitialize, GlobalStyle } from '~/utils';
import { getConfiguration } from '~/utils/configurations';

import { Router } from './router';
import { store, persistor } from './store';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import './App.less';

dayjs.extend(localizedFormat);

let activeFlags: LDFlagSet = {};
const App: React.FC = () => {
  React.useEffect(() => {
    const user = getUserFromLocalStorage();
    gaInitialize({
      userId: user?.uuid,
      user_type: user?.company?.company_type,
      trackingId: getConfiguration('REACT_APP_GA_MEASUREMENT_ID'),
    });
  }, []);
  activeFlags = useFlags();

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <GlobalStyle />
            <Router />
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
};
export const getActiveFlags = () => activeFlags;
export default App;
