import React, { useContext, useMemo, useState } from 'react';
import { values } from 'ramda';
import { Link } from 'react-router-dom';

import { SolarProject, SolarRankingData } from '~/store/project';
import Skeleton, { SkeletonAvatar } from '~/UI/Skeleton';
import { MODULE_TAGS, ORDERED_MODULE_TAGS_VALUES } from '~/constants/modules';
import ModuleTag from '~/components/ModuleTag';
import {
  generateTestId,
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';
import { introSolarProposal } from '~/constants/intro';
import ModulePopover from '~/UI/ModulePopover';
import {
  decimalFormatterThous,
  formatQuarterYearLabel,
  NODE_ENVS,
  theme,
} from '~/utils';
import { Divider, DropdownAnt, Space } from '~/UI';
import { IconDotsVertical, IconEye, IconEyeSlash } from '~/UI/Icons';
import { MenuProps } from '~/UI/Menu';
import { ROUTER_PATHS } from '~/router/Paths';
import useAuth from '~/hooks/useAuth';
import {
  ModuleHero,
  ModuleAvatar,
  ModuleInformation,
  ModuleName,
  ModuleTitle,
  ModuleCardContainer,
  StyledLabel,
  ModuleTagsList,
  ModuleAvatarPlaceholder,
  Container,
  MenuButton,
} from './styles';
import { AnalysisViewContext } from '../solar/SolarDetails';
import { getConfiguration } from '~/utils/configurations';
import { useFlags } from '~/utils/launchdarkly';
import Modal from '~/UI/ModalAnt';
import { SmallLabel } from '~/UI/Typography';
import ModulePSPForm from './ModulePSPForm';
import { FilterOption } from '../solar/SortingMenu';
import { RACKING_TYPE } from '~/utils/rackingTypes';

let availability: string | undefined | null;
let price: string | undefined | null;
let epcSavings: string | undefined | null;
let revenueValue: string | undefined | null;
let effectivePrice: string | undefined | null;

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.SOLAR_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.TEXT,
};

const decorateValueIfNotNil = (
  value: string | boolean,
  prefix?: string,
  unit?: string
) => {
  const positiveZeroOrNumericValue = value === '-0.000' ? '0.000' : value;
  if (Number.isNaN(Number(value))) return '-';
  return (positiveZeroOrNumericValue && positiveZeroOrNumericValue !== true) ||
    parseFloat(positiveZeroOrNumericValue.toString()) === 0
    ? `${prefix || ''}${positiveZeroOrNumericValue}${unit || ''}`
    : '-';
};

const decorateAvailabilityValue = (value: string | boolean) =>
  value && value !== true && parseFloat(value) !== 0 ? `${value} MW` : `0 MW`;

export type ModuleData = Partial<SolarRankingData> & { moduleId: string };

export type ModuleCardProps = {
  className?: string;
  moduleData?: ModuleData;
  index?: number;
  onClick?: (moduleId: string) => void;
  tabIndex?: number;
  project: SolarProject;
  onHideModule?: (uuid: string) => void;
  onUnhideModule?: (uuid: string) => void;
  shouldIncludeModuleLink: boolean;
  currentQuarter: string;
  selectedSortingFilter?: FilterOption;
};

type MenuItems = MenuProps['items'];

const ModuleCard = (props: ModuleCardProps) => {
  const {
    moduleData,
    className,
    index,
    onClick,
    tabIndex,
    onHideModule,
    onUnhideModule,
    shouldIncludeModuleLink,
    currentQuarter,
    project,
    selectedSortingFilter,
  } = props;

  const { user } = useAuth();
  const flags = useFlags();
  const isAnalysisViewOpen = useContext(AnalysisViewContext);
  const moduleTags = moduleData?.tags || [];
  const tags = moduleTags.map(({ tag }) => tag);
  const sortedTags = ORDERED_MODULE_TAGS_VALUES.filter((tag) =>
    tags.includes(tag)
  );
  const isDemoEnv =
    getConfiguration('REACT_APP_DEPLOYMENT_ENV') === NODE_ENVS.DEMO2;
  const logoLocationPath = moduleData?.logoData?.file_location_path;
  const isSolarEngaged = user.company?.engaged_solar;
  const showLogoAvatar = !!logoLocationPath && !isDemoEnv;
  const showFirstLetterAvatar =
    !logoLocationPath && moduleData?.manufacturer && !isDemoEnv;
  const showSkeletonAvatar =
    (!logoLocationPath && !moduleData?.manufacturer) || isDemoEnv;
  const [projectSpecificPricingModalOpen, setProjectSpecificPricingModalOpen] =
    useState<boolean>(false);

  const currentQuarterProjectSpecificPricing = useMemo(
    () =>
      project.project_specific_pricing.find((projectSpecificPricing) => {
        const arr = currentQuarter.split('-');
        const year = Number(arr[0]);
        const quarter = Number(arr[1]);
        const isSameQuarter =
          projectSpecificPricing.quarter ===
          formatQuarterYearLabel(quarter, year);
        const isSameModule =
          moduleData?.moduleId === projectSpecificPricing.module_uuid;
        return isSameQuarter && isSameModule;
      }),
    [project.project_specific_pricing, currentQuarter]
  );

  const currentQuarterModuleAvailability =
    moduleData?.module_availability_MW || 0;
  availability = decimalFormatterThous(
    currentQuarterModuleAvailability
  ).toString();
  price = moduleData?.module_customer_price_USD_per_W?.toFixed(3);
  epcSavings = moduleData?.relative_epc_cost_USD_per_W?.toFixed(3);
  revenueValue = moduleData?.relative_revenue_USD_per_W?.toFixed(3);
  effectivePrice = moduleData?.module_effective_price_USD_per_W?.toFixed(3);

  const getMenuItems = (
    props: ModuleCardProps,
    shouldIncludeModuleLink: boolean
  ): MenuItems => {
    if (!props.moduleData) return [];
    const items: MenuItems = [];
    const { moduleData } = props;

    if (shouldIncludeModuleLink)
      items.push({
        key: 'view-module-details',
        label: (
          <Link
            to={{
              pathname: ROUTER_PATHS.modules.MODULE_DETAILS(
                props.moduleData.moduleId
              ),
              state: {
                fromProject: props.project.uuid,
              },
            }}
          >
            View Module Detail Page
          </Link>
        ),
      });

    if (flags.projectSpecificPricingV2)
      items.push({
        key: 'enter-custom-price',
        label: 'Enter custom price',
        onClick: () => setProjectSpecificPricingModalOpen(true),
      });

    if (!moduleData.isModuleHidden) {
      items.push({
        key: 'hide-from-list',
        icon: <IconEyeSlash size={15} />,
        label: 'Hide from list',
        onClick: () => onHideModule && onHideModule(moduleData?.moduleId || ''),
      });
    }
    if (moduleData.isModuleHidden) {
      items.push({
        key: 'unhide-from-list',
        icon: <IconEye size={15} />,
        label: 'Unhide from list',
        onClick: () =>
          onUnhideModule && onUnhideModule(moduleData?.moduleId || ''),
      });
    }
    return items;
  };

  const ModuleAvatarComponent = (
    <ModuleAvatar
      data-testid={generateTestId({
        ...dataTestIdConfig,
        component: TEST_DATA_COMPONENTS.AVATAR,
        identifier: 'module-logo',
        indices: tabIndex?.toString(),
      })}
      size={48}
      src={logoLocationPath}
    />
  );

  const ModuleInformationComponent = moduleData?.manufacturer &&
    moduleData?.moduleName && (
      <>
        <ModuleTitle
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.TEXT,
            identifier: 'project-size',
            indices: tabIndex?.toString(),
          })}
        >
          {moduleData.manufacturer} {moduleData.wattClassKw} W
        </ModuleTitle>
        <ModuleName
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.TEXT,
            identifier: 'module-name',
            indices: tabIndex?.toString(),
          })}
        >
          {moduleData.moduleName}
        </ModuleName>
      </>
    );

  const tagsList = sortedTags.map((tag) => {
    const currentTag = values(MODULE_TAGS).find((t) => t.value === tag);
    if (!currentTag) return null;
    const tagComponent = (
      <ModuleTag size="sm" icon={currentTag?.icon} label={currentTag?.label} />
    );
    return currentTag?.helpInfo ? (
      <ModulePopover
        title={
          <ModuleTag
            size="md"
            icon={currentTag?.icon}
            label={currentTag?.label}
          />
        }
        content={currentTag?.helpInfo}
        getPopupContainer={(triggerNode) => triggerNode}
      >
        {tagComponent}
      </ModulePopover>
    ) : (
      tagComponent
    );
  });

  const moduleHeroItems = [
    {
      key: 'availability',
      label: 'Availability',
      value: decorateAvailabilityValue(availability ?? ''),
      introWrapperProps: {
        ...introSolarProposal.availability,
        hideIntro: index !== 1 || isAnalysisViewOpen,
      },
    },
    {
      key: 'price',
      color: currentQuarterProjectSpecificPricing && theme.colors.primary,
      backgroundColor:
        currentQuarterProjectSpecificPricing && theme.colors.primary50,
      label: currentQuarterProjectSpecificPricing
        ? 'Custom Purchase Price'
        : 'Purchase Price',
      value: decorateValueIfNotNil(price ?? '', '$ ', undefined),
      introWrapperProps: {
        ...introSolarProposal.purchasePrice,
        hideIntro: index !== 1 || isAnalysisViewOpen,
      },
      success: selectedSortingFilter === 'price',
    },
    ...(project.project_details.racking_type === RACKING_TYPE.ROOFTOP_MLPE
      ? []
      : [
          {
            key: 'epc-savings',
            label: 'BOS Savings',
            value: decorateValueIfNotNil(epcSavings ?? '', '$ ', undefined),
            success: selectedSortingFilter === 'epc-savings',
          },
        ]),
    {
      key: 'revenue-value',
      label: 'Production Benefit',
      value: decorateValueIfNotNil(revenueValue ?? '', '$ ', undefined),
      success: selectedSortingFilter === 'revenue-value',
    },
    {
      key: 'epc_plus_revenue',
      label: 'BOS + Production',
      value: decorateValueIfNotNil(
        (Number(epcSavings) + Number(revenueValue)).toFixed(3) ?? '',
        '$ ',
        undefined
      ),
      success: selectedSortingFilter === 'epc_plus_revenue',
    },
    {
      key: 'effective-price',
      label: 'Effective $/W',
      value: decorateValueIfNotNil(effectivePrice ?? '', '$ ', undefined),
      domesticContent: moduleData?.domestic_content_value_USD_per_W ?? 0,
      introWrapperProps: {
        ...introSolarProposal.effectivePrice,
        hideIntro: index !== 1 || isAnalysisViewOpen,
      },
      success: flags.projectSpecificPricingV2
        ? selectedSortingFilter === 'effective-price'
        : true,
    },
  ];

  return (
    <>
      <Container
        clickable={!!onClick}
        onClick={() => onClick && onClick(moduleData?.moduleId || '')}
        onMouseDown={(e) => e.preventDefault()}
        key={moduleData?.moduleId}
        data-testid={generateTestId({
          ...dataTestIdConfig,
          component: TEST_DATA_COMPONENTS.TABLE_ROW,
          identifier: 'modules',
          indices: tabIndex?.toString(),
        })}
        tabIndex={tabIndex}
        className={className}
        isModuleHidden={moduleData?.isModuleHidden}
      >
        <ModuleCardContainer>
          {!!moduleData?.rank && <StyledLabel>{moduleData?.rank}</StyledLabel>}
          {showLogoAvatar && ModuleAvatarComponent}
          {showFirstLetterAvatar && (
            <ModuleAvatarPlaceholder
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.AVATAR,
                identifier: 'module-logo',
                indices: tabIndex?.toString(),
              })}
              size={48}
            >
              {moduleData.manufacturer ? moduleData.manufacturer[0] : ''}
            </ModuleAvatarPlaceholder>
          )}
          {showSkeletonAvatar && <SkeletonAvatar size={48} />}
          <ModuleInformation>
            {moduleData?.manufacturer &&
            moduleData?.moduleName &&
            !isDemoEnv ? (
              ModuleInformationComponent
            ) : (
              <>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Skeleton
                    title={{
                      width: '86px',
                    }}
                    paragraph={false}
                  />
                  <ModuleTitle style={{ width: '100%' }}>
                    {`${moduleData?.wattClassKw} W`}
                  </ModuleTitle>
                </div>
                <Skeleton
                  title={{
                    width: '195px',
                  }}
                  paragraph={false}
                />
              </>
            )}
            <ModuleTagsList>{tagsList}</ModuleTagsList>
          </ModuleInformation>
          <ModuleHero
            defaultExpanded={false}
            items={
              selectedSortingFilter === 'epc_plus_revenue'
                ? moduleHeroItems
                : moduleHeroItems.filter(
                    (item) => item?.key !== 'epc_plus_revenue'
                  )
            }
            showToggle={false}
            testIdData={{
              ...dataTestIdConfig,
              indices: tabIndex?.toString(),
            }}
          />
        </ModuleCardContainer>
        {flags.persistentFilters && isSolarEngaged && (
          <DropdownAnt
            trigger={['click']}
            placement="left"
            menu={{
              items: getMenuItems(props, shouldIncludeModuleLink),
            }}
          >
            <MenuButton type="link">
              <IconDotsVertical
                style={{
                  height: '100%',
                }}
                size={25}
              />
            </MenuButton>
          </DropdownAnt>
        )}
      </Container>
      {flags.projectSpecificPricingV2 && (
        <Modal
          centered
          open={projectSpecificPricingModalOpen}
          onCancel={() => setProjectSpecificPricingModalOpen(false)}
          footer={null}
          destroyOnClose
        >
          <Space size="small">
            {ModuleAvatarComponent}
            <Space direction="vertical" size="xs">
              {ModuleInformationComponent}
              <Space size="xs">{tagsList}</Space>
            </Space>
          </Space>
          <Divider />
          <Space
            style={{
              marginBottom: 20,
            }}
            direction="vertical"
            size="xs"
          >
            <SmallLabel>Current Purchase Price</SmallLabel>
            <span>
              <b>{decorateValueIfNotNil(price ?? '', '$ ', undefined)}</b>
            </span>
          </Space>
          {moduleData && (
            <ModulePSPForm
              onSave={() => setProjectSpecificPricingModalOpen(false)}
              currentQuarter={currentQuarter}
              moduleData={moduleData}
              projectUuid={project.uuid}
              projectSpecificPricing={currentQuarterProjectSpecificPricing}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default ModuleCard;
