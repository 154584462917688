import React from 'react';
import styled from '@emotion/styled';
import { Button, Tooltip } from 'antd';
import { ButtonProps as ButtonAntProps } from 'antd/lib/button/button';
import { theme } from '../utils';

export interface ButtonProps extends ButtonAntProps {
  tooltipText?: string;
}

const StyledButton = styled(Button)`
  padding: 4px 6px 4px 10px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  border-radius: 44px;
  height: fit-content;
  color: ${theme.colors.charcoal};
  display: flex;
  border: 1px solid transparent;
  svg {
    visibility: hidden;
    color: ${theme.colors.primary};
    align-self: center;
  }
  span {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    color: ${theme.colors.primary};
  }
  a {
    color: inherit;
  }
  &:hover {
    border: 1px solid ${theme.colors.primary};
    background: ${theme.colors.sterling};
    svg {
      visibility: visible;
    }
  }
`;

const DownloadButton: React.FC<ButtonProps> = ({ tooltipText, ...props }) => (
  <Tooltip title={tooltipText}>
    <StyledButton {...props} />
  </Tooltip>
);

export default DownloadButton;
