import TagManager from 'react-gtm-module';
import { getConfiguration } from '~/utils/configurations';
import { UserType } from '~/types/users';

export const initializeGTM = (user: UserType) => {
  const gtmId = getConfiguration('REACT_APP_GTM_CONTAINER_ID');
  const environment = getConfiguration('REACT_APP_DEPLOYMENT_ENV');
  if (gtmId) {
    TagManager.initialize({
      gtmId,
      dataLayer: {
        user_uuid: user?.uuid,
        user_type: user?.company?.company_type,
        environment,
        engaged_solar: user?.company?.engaged_solar,
        engaged_storage: user?.company?.engaged_storage,
      },
    });
  }
};

export const getElementByGtmId = (gtmId: string): HTMLElement | null => {
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[data-gtm-id=${gtmId}]`
  );
  if (!elements) return null;
  if (elements.length > 1) return null;
  return elements[0];
};

export const buildHumanReadableGtmId = (
  element: HTMLElement | null,
  initialId = ''
): string | undefined => {
  if (!element) return undefined;

  const { gtmId, gtmType } = element.dataset;
  let humanId: string = initialId;
  if (gtmId) {
    humanId = gtmType
      ? `/${gtmType}:${gtmId}${initialId}`
      : `/${gtmId}${initialId}`;
  }

  const { parentElement } = element;
  if (parentElement) {
    return buildHumanReadableGtmId(parentElement, humanId);
  }

  if (!humanId.includes('root:')) {
    humanId = `/root:unknown${humanId}`;
  }
  return humanId;
};

export const GTM_SOURCE_TYPES = {
  ROOT: 'root',
  MODE: 'mode',
  SWITCH: 'switch',
  POPUP: 'popup',
  MODAL: 'modal',
  FILTER: 'filter',
  DRAWER: 'drawer',
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  CARD: 'card',
  LINK: 'link',
};

export const GTM_SOURCE_IDS = {
  ROOTS: {
    SOLAR_PROJECT: 'solar_project',
    STORAGE_PROJECT: 'storage_project',
  },
  ANNOUNCEMENTS: {
    MODAL: 'announcement_modal',
    REGISTER: 'register_button',
    DONT_SHOW_AGAIN: 'dont_show_again',
    CLOSE: 'close_modal',
    ANZA_STARTER: 'anza_starter',
    ANZA_PREMIER: 'anza_premier',
    TRY_IT_NOW: 'try_it_now_button',
    LEARN_MORE: 'learn_more_button',
    SCHEDULE_A_CALL: 'schedule_a_call',
  },
  SOLAR_PROJECT: {
    MODES: {
      SIZZLE: 'sizzle',
      ADVANCED_VIEW: 'advanced_view',
    },
    ANALYSIS_VIEW_SWITCH: 'toggle_advanced_view',
    ADVANCED_SELECT_COLUMNS: 'toggle_advanced_select_columns',
    ADVANCED_INFO_DRAWER: 'toggle_advanced_info_drawer',
    ADVANCED_INFO_DRAWER_BLOG: 'click_advanced_info_drawer_blog',
    TOGGLE_HIDDEN_MODULES: 'toggle_show_hidden_modules',
  },
  ADMIN: {
    COMPANIES: {
      SAVE_BUTTON: 'create_new_company_button',
    },
    DC_BLOCKS: {
      COPY_TO_CLIPBOARD_DC_BLOCK_UUID: 'copy_to_clipboard_dc_block_uuid',
    },
  },
  CONTACT: {
    TALK_TO_YOUR_MANAGER: 'talk_to_your_manager',
  },
  PRICING_HISTORY: {
    ACCESS_PRICING_HISTORY: 'access_pricing_history',
  },
};

export const GTM_EVENTS = {
  CLICK: 'platform.click',
  STAGE_CHANGED: 'platform.state_changed',
  POPUP_SHOW: 'platform.popup.show',
  POPUP_HIDE: 'platform.popup.hide',
  MODAL_SHOW: 'platform.modal.show',
  MODAL_HIDE: 'platform.modal.hide',
  CHECK_BOX_CHECKED: 'platform.checkbox.checked',
  CHECK_BOX_UNCHECKED: 'platform.checkbox.unchecked',
};

export const gtmSetCurrentProjectState = <Payload>(
  eventName: string,
  sourceId: string,
  payload: Payload
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      source: sourceId,
      project: {
        ...payload,
      },
    },
  });
};

export const gtmSendEvent = <Payload>(
  eventName: string,
  sourceId: string,
  payload?: Payload,
  payloadLabel?: string
) => {
  if (payload) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        'platform.id': sourceId,
        [payloadLabel ?? 'payload']: payload,
      },
    });
  } else {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        'platform.id': sourceId,
      },
    });
  }
};

export const maybeSendPlatformEventToGTM = <Payload>(
  event: string,
  gtmId: string | null,
  payload?: Payload,
  payloadLabel?: string
) => {
  if (gtmId) {
    const switchElement: HTMLElement | null = document.querySelector(
      `[data-gtm-id="${gtmId}"]`
    );

    const sourceId = buildHumanReadableGtmId(switchElement);
    if (sourceId) {
      gtmSendEvent(event, sourceId, payload, payloadLabel);
    }
  }
};
