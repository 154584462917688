import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { FormInstance } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { Rule } from 'antd/lib/form';
import { UploadFile } from 'antd/lib/upload/interface';

import {
  Alert,
  ButtonAnt as Button,
  Form,
  InputAnt as Input,
  Upload,
  FormItemProps,
} from '~/UI';
import { theme, getFileDisplayName } from '~/utils';
import {
  getFileExtension,
  validateFileSize,
  formatAllowedFileExtensions,
} from '~/utils/validateFileSize';

const ComposedUpload = styled(Upload)`
  .ant-upload {
    display: flex;
    flex-direction: row;
    span {
      width: 100%;
    }
    .ant-input {
      flex: 1 1 auto;
      margin-right: 12px;
      &:disabled {
        background-color: ${theme.colors.background};
        cursor: pointer;
        color: ${theme.colors.charcoal};
      }
      &::placeholder {
        font-size: 16px;
      }
    }
    button {
      height: 32px;
    }
    .input-placeholder {
      top: 6px;
      font-size: 14px;
    }
  }
`;

const InputPlaceholder = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${theme.colors.graphite};
  position: absolute;
  top: 16px;
  left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledFormItem = styled(Form.Item)`
  display: flex;
  flex-direction: column;
  .ant-form-item-row {
    flex-direction: column;
  }
  .ant-form-item-label {
    text-align: left;
    padding: 0.6rem 0;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;

    label::after {
      content: none;
    }
  }
`;

interface InputFileFormItemProps {
  form: FormInstance;
  rules?: Array<Rule>;
  acceptedFileType?: Array<string>;
  onError?: (error: string) => void;
  onFinishCallback?: { (value?: UploadFile): void };
  customFileValidation?: () => void;
  allowedFileSizeInMBs: number;
  error?: string | undefined;
  onSave?: () => void | undefined;
  resetError?: () => void | undefined;
  isUploadingInProgress?: boolean | undefined;
  noFileSelectedText?: string;
  validateTrigger?: string | string[] | false;
}

export const InputFileFormItem: React.FC<
  FormItemProps & InputFileFormItemProps
> = ({
  form,
  name,
  label,
  rules,
  acceptedFileType,
  onError,
  onFinishCallback,
  customFileValidation,
  allowedFileSizeInMBs,
  error,
  onSave,
  resetError,
  isUploadingInProgress,
  noFileSelectedText,
  validateTrigger,
}) => {
  const [hasError, setHasError] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fieldDisplayName = getFileDisplayName(form?.getFieldValue(name!));
    setDisplayName(fieldDisplayName as string);
  }, []);

  useEffect(() => {
    setHasError(error || '');
    setIsUploading(isUploadingInProgress || false);
  }, [error, isUploadingInProgress]);

  const customRequestOp = ({ onSuccess, data }: UploadRequestOption) => {
    if (!onSuccess) {
      return;
    }
    onSuccess(data, new XMLHttpRequest());
  };

  const handleFileUpload = async (info: UploadChangeParam<UploadFile>) => {
    if (!hasError && onFinishCallback) {
      if (info.file.name.length > 0) {
        setDisplayName(info.file.name);
        onFinishCallback(info.file);
        if (hasError) {
          setHasError('');
        }
        if (resetError) {
          resetError();
        }
      }
    }
  };

  const handleError = (errorStr: string) => {
    setHasError(errorStr);
    if (onError) {
      onError(errorStr);
    }
    setIsUploading(false);
  };

  const handleFileRemoval = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setDisplayName('');
    if (resetError) {
      resetError();
    }
    setIsUploading(false);

    if (onFinishCallback) {
      onFinishCallback(undefined);
    }
  };

  const handleFileValidation = useCallback(
    (file: RcFile) => {
      if (
        acceptedFileType?.length &&
        !acceptedFileType.includes(getFileExtension(file))
      ) {
        handleError(
          `Please upload a ${formatAllowedFileExtensions(
            acceptedFileType
          )} file`
        );
      }

      if (!validateFileSize(file, allowedFileSizeInMBs)) {
        handleError(
          `Please verify that the file size is less than ${allowedFileSizeInMBs}MB`
        );
      }
      return false; // Return false so file upload won't happen automatically.
    },
    [
      acceptedFileType,
      acceptedFileType?.length,
      customFileValidation,
      allowedFileSizeInMBs,
      validateFileSize,
    ]
  );

  const clearError = () => {
    setHasError('');
    if (resetError) {
      resetError();
    }
  };

  const errorAlert = useMemo(() => {
    return (
      <Alert
        message="Error"
        description={hasError || error}
        type="error"
        closable
        onClose={() => clearError()}
        style={{ marginTop: '24px' }}
      />
    );
  }, [hasError || error]);

  const isValidationError = useMemo(
    () => error && error.toLowerCase().indexOf('validation') > -1,
    [error]
  );

  useEffect(() => {
    if (isValidationError) {
      setDisplayName('');
      setIsUploading(false);
    }
  }, [isValidationError]);

  const actionButton = useMemo(
    () =>
      onSave && displayName && displayName.length > 0 ? (
        <Button
          type="primary"
          size="middle"
          color={theme.colors.primary}
          style={{ minWidth: '138px' }}
          onClick={(e: React.MouseEvent) => {
            if (onSave) {
              onSave();
            }
            if (!isUploading) {
              e.preventDefault();
              e.stopPropagation();
              setIsUploading(true);
            }
          }}
          loading={isUploading}
        >
          Save
        </Button>
      ) : (
        <Button
          type="primary"
          size="small"
          color={theme.colors.primary}
          style={{ minWidth: '138px' }}
          onClick={() => clearError()}
        >
          Choose a file
        </Button>
      ),
    [displayName, onSave, isUploading, setIsUploading]
  );

  return (
    <StyledFormItem
      label={label}
      name={name}
      rules={rules || []}
      validateTrigger={validateTrigger}
    >
      <Input type="hidden" />
      <ComposedUpload
        maxCount={1}
        name={name as string}
        multiple={false}
        showUploadList={false}
        customRequest={customRequestOp}
        onChange={!displayName ? handleFileUpload : undefined}
        beforeUpload={handleFileValidation}
      >
        <Input disabled value={displayName} />
        {displayName && displayName.length && (
          <Button
            type="primary"
            size="middle"
            color={theme.colors.gray}
            style={{
              marginRight: '0.5rem',
              color: theme.colors.charcoal,
              minWidth: '138px',
            }}
            onClick={handleFileRemoval}
          >
            Remove file
          </Button>
        )}
        {actionButton}
        {!displayName || displayName.length === 0 ? (
          <InputPlaceholder className="input-placeholder">
            {noFileSelectedText}
          </InputPlaceholder>
        ) : null}
      </ComposedUpload>

      {hasError || isValidationError ? errorAlert : null}
    </StyledFormItem>
  );
};

InputFileFormItem.defaultProps = {
  onFinishCallback: undefined,
  acceptedFileType: [],
  onError: undefined,
  customFileValidation: undefined,
  rules: [],
  noFileSelectedText: 'No file selected',
};

export default InputFileFormItem;
