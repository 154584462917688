import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';

import * as service from '~/services/api/settings';
import { requiredRule, CompanyType } from '~/utils';
import {
  Alert,
  InputAnt as Input,
  ButtonAnt as Button,
  Form,
  Switch,
  Overlay,
  Spinner,
  FormItemLabel,
} from '~/UI';
import useAuth from '~/hooks/useAuth';
import { useApiWrapper } from '~/hooks/useApiWrapper';
import { UserType } from '~/types/users';
import { ModelEditSettings } from '~/services/api/settings';

export const SettingsFormLayout = styled.div`
  width: 100%;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0.25rem;
  }
`;

interface UserResponse {
  success: boolean;
  user: UserType;
}

const SettingsForm: React.FC = () => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { executeApi } = useApiWrapper();

  const { user: authUser, updateUser } = useAuth();

  const checkInvalid = (fieldNames?: string[]) => {
    form.validateFields(
      fieldNames || [
        'firstName',
        'lastName',
        'jobTitle',
        'moduleDigestOptOut',
        'vendorModulePricingNotificationsOptOut',
      ]
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: authUser.first_name,
      lastName: authUser.last_name,
      jobTitle: authUser.job_title,
      email: authUser.email,
      company: authUser.company?.name,
      moduleDigestOptOut: !authUser.module_digest_opt_out,
    });
    checkInvalid();
  }, [authUser]);

  const onFinish = async (values: ModelEditSettings) => {
    const payload: any = R.pick(
      ['firstName', 'lastName', 'jobTitle', 'moduleDigestOptOut'],
      values
    );
    payload.moduleDigestOptOut = !payload.moduleDigestOptOut;
    setIsLoading(true);
    executeApi({
      service: () =>
        service
          .update(authUser.id!, payload)
          .then((result) => {
            const { user, success }: { success: boolean; user: UserType } =
              result.data as unknown as UserResponse;
            if (success) {
              updateUser(user);
            }
            return result;
          })
          .catch((err) => {
            setFormError('Error updating settings');
            throw err;
          })
          .finally(() => setIsLoading(false)),
    });
  };

  return (
    <SettingsFormLayout>
      <Form form={form} onFinish={onFinish} style={{ width: '100%' }}>
        {isLoading && (
          <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}
        <Form.Item
          name="firstName"
          label={<FormItemLabel>First Name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="firstName" placeholder="Insert First Name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={<FormItemLabel>Last Name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="lastName" placeholder="Insert Last Name" />
        </Form.Item>
        <Form.Item
          name="jobTitle"
          label={<FormItemLabel>Job Title</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input name="jobTitle" placeholder="Insert Job Title" />
        </Form.Item>
        <Form.Item
          name="email"
          label={<FormItemLabel>Email</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input disabled name="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="company"
          label={<FormItemLabel>Company</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input disabled name="company" placeholder="Insert Company" />
        </Form.Item>
        <Form.Item
          name="moduleDigestOptOut"
          label={<FormItemLabel>Receive Weekly Ranking Updates</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {authUser?.company?.company_type === CompanyType.VENDOR ? (
          <Form.Item
            name="vendorModulePricingNotificationsOptOut"
            label={
              <FormItemLabel>
                Receive Module Pricing Notifications
              </FormItemLabel>
            }
            labelCol={{ span: 24 }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        ) : null}

        {formError && (
          <Alert
            message="Error"
            description={formError}
            type="error"
            closable
            onClose={() => setFormError('')}
            style={{ margin: '24px 0' }}
          />
        )}
        <ActionRow>
          <Button htmlType="submit" onClick={() => checkInvalid()}>
            Save
          </Button>
        </ActionRow>
      </Form>
    </SettingsFormLayout>
  );
};

export default SettingsForm;
