import React from 'react';
import { Radio } from 'antd';
import styled from '@emotion/styled';
import { RadioGroupProps } from 'antd/lib/radio';

export type RadioButtonGroupProps = Omit<RadioGroupProps, 'optionType'>;

const RadioStyled = styled(Radio.Group)<RadioGroupProps>`
  display: flex;
  gap: 1rem;
  .ant-radio-button-wrapper-checked {
    font-weight: 600 !important;
  }
  .ant-radio-button-wrapper {
    font-weight: 500;
    color: black;
    padding: 0 10px !important;
    border-radius: 6px !important;
    border-left-width: 1px !important;
    &:before {
      content: none !important;
    }
  }
`;

const RadioButtonsGroup = (props: RadioButtonGroupProps) => {
  return <RadioStyled {...props} optionType="button" />;
};

export default RadioButtonsGroup;
