import React from 'react';
import {
  Form,
  FormProps,
  FormItemProps as FormItemPropsAnt,
  FormInstance as FormInstanceAnt,
  FormRule as FormRuleAnt,
} from 'antd';
import styled from '@emotion/styled';

export type FormItemProps = FormItemPropsAnt;
export type FormInstance = FormInstanceAnt;
export type FormRule = FormRuleAnt;

const FormAnt = (props: FormProps) => {
  return <Form validateTrigger="onBlur" scrollToFirstError {...props} />;
};

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-extra {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const FormItem = (props: FormItemPropsAnt) => {
  return <StyledFormItem {...props} />;
};

FormAnt.useForm = Form.useForm;
FormAnt.Item = FormItem;
FormAnt.useWatch = Form.useWatch;
FormAnt.useFormInstance = Form.useFormInstance;
FormAnt.List = Form.List;
FormAnt.ErrorList = Form.ErrorList;
FormAnt.Provider = Form.Provider;

export default FormAnt;
