import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { TypeOptions, rtkService } from '~/types/rtkApi';
import BackHandler from '~/components/BackHandler';
import { AdminListPageProps } from '~/types/admin';

import { AdminRoutePath } from '../../../router/AdminRoutes';

interface AdminSectionProps<T = TypeOptions> {
  path: AdminRoutePath;
  listComponent: React.FC<AdminListPageProps<T>>;
  formComponent: React.FC<any>;
  name: string;
  service: rtkService<T>;
}

const AdminSectionRouter = <T,>({
  listComponent: ListComponent,
  formComponent: FormComponent,
  path,
  name,
  service,
}: AdminSectionProps<T>) => {
  return (
    <Route>
      <Switch>
        <Route
          exact
          path={path.default}
          render={(props) => (
            <ListComponent {...props} pageName={name} service={service} />
          )}
        />
        <Route
          exact
          path={path.new}
          render={(props) => <FormComponent {...props} service={service} />}
        />
        <Route
          exact
          path={`${path.edit(':id')}`}
          render={(props) => (
            <>
              <BackHandler
                visible
                goBackHistory
                text={`All ${name}`}
                to={path.default}
              />
              <FormComponent {...props} service={service} />
            </>
          )}
        />
      </Switch>
    </Route>
  );
};

export default AdminSectionRouter;
