import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { ButtonAnt as Button, Tooltip, Popconfirm } from '~/UI';
import {
  AnzaFile,
  AnzaFileWithAttachment,
  IconAdd,
  IconDownload,
  IconEdit,
  IconTrash,
} from '~/UI/Icons';
import { isEllipsisActive, theme } from '~/utils';
import { truncatedTextStyles } from './CSSUtils';

export const getReadableFileSizeString = (fileSizeInKb = 0) => {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  let fileSizeInBytes = fileSizeInKb * 1024;
  do {
    fileSizeInBytes /= 1024;
    /* eslint-disable-next-line */
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export interface FileGroupProps {
  fileId: string;
  name: string | React.ReactNode;
  uploadDate?: Date;
  description?: string;
  sizeInMB?: number;
  supportingDocuments?: Array<Omit<FileGroupProps, 'supportingDocuments'>>;
  icon?: React.ReactNode;
  onDownload?: () => void;
  onDownloadAll?: () => void;
  isDownloadLoading?: boolean;
  isDownloadDisabled?: boolean;
  onAddOrEdit?: () => void;
  onAddSupportingDocument?: () => void;
  onRemove?: () => void;
  removable?: boolean;
  onEdit?: () => void;
  editable?: boolean;
  canAdd?: boolean;
  newlyAddedId?: number;
  searchFileUuid?: string;
  canAddExpectedDate?: boolean;
  expectedDateFormItem?: React.ReactNode;
  expectedDate?: string;
  extraField?: React.ReactNode;
  className?: string;
}

const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #f9f9f9;
  padding: 28px 22px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const Details = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Name = styled.p`
  font-size: 20px;
  color: ${theme.colors.charcoal};
  font-weight: 600;
`;

const Uploaded = styled.span`
  font-size: 16px;
  color: ${theme.colors.stone};
  font-weight: 600;

  .ant-form-item-label > label {
    color: ${theme.colors.stone};
  }
`;

const Description = styled.div`
  margin-top: 26px;
  font-size: 16px;
  overflow: auto;
`;

const SupportingDocumentation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

const SupportingDocumentationHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SupportingDocumentationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
`;

const Title = styled.p`
  font-size: 16px;
  color: ${theme.colors.charcoal};
  font-weight: 600;
`;

const SingleFileContainer = styled.div`
  width: 100%;
  max-width: 350px;
  height: fit-content;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 11px;
  border-radius: 6px;
`;

const SingleFileDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
`;

const SingleFileName = styled.p`
  ${truncatedTextStyles};
  font-size: 16px;
  color: ${theme.colors.charcoal};
  font-weight: 600;
  max-width: 140px;
`;

const SingleFileUploaded = styled.span`
  font-size: 14px;
  color: ${theme.colors.graphite};
  font-weight: 400;
`;

const UploadedData: React.FC<{
  uploadDate?: Date;
  fileId?: string;
  sizeInMB?: number;
  showText?: boolean;
}> = ({ uploadDate, fileId, sizeInMB, showText = true }) => (
  <>
    {/* eslint-disable-next-line no-nested-ternary */}
    {uploadDate
      ? `${showText ? 'Uploaded ' : ''}${dayjs(uploadDate).format(
          'MMM YYYY h:mm A'
        )}`
      : fileId
      ? 'NEWLY ADDED,'
      : null}{' '}
    {sizeInMB ? getReadableFileSizeString(sizeInMB) : ''}
  </>
);

const SingleFile: React.FC<
  Omit<
    FileGroupProps,
    | 'description'
    | 'onDownloadAll'
    | 'supportingDocuments'
    | 'onAddExpectedDate'
    | 'expectedDate'
  >
> = ({
  fileId,
  name,
  uploadDate,
  onDownload,
  sizeInMB,
  icon,
  isDownloadDisabled,
  isDownloadLoading,
  removable,
  onRemove,
  editable,
  onEdit,
}) => (
  <SingleFileContainer>
    <SingleFileDetails>
      <span>
        {icon || (
          <AnzaFileWithAttachment size={30} color={theme.colors.primary} />
        )}
      </span>
      <div>
        <Tooltip
          title={
            isEllipsisActive(
              document.getElementById(`single-file-name-${name}`)
            )
              ? name
              : null
          }
        >
          <SingleFileName id={`single-file-name-${name}`}>
            {name}
          </SingleFileName>
        </Tooltip>
        <SingleFileUploaded>
          <UploadedData
            fileId={fileId}
            sizeInMB={sizeInMB}
            uploadDate={uploadDate}
            showText={false}
          />
        </SingleFileUploaded>
      </div>
    </SingleFileDetails>

    <div style={{ display: 'flex' }}>
      {removable && (
        <Tooltip title="Remove">
          <Popconfirm
            placement="bottom"
            title="Are you sure to remove this file?"
            onConfirm={onRemove}
            okText="Yes"
            cancelText="No"
          >
            <Button
              loading={isDownloadLoading}
              shape="round"
              type="link"
              icon={<IconTrash size="24px" />}
              size="large"
              style={{ padding: 0, margin: 0, marginRight: '6px' }}
            />
          </Popconfirm>
        </Tooltip>
      )}
      {editable && (
        <Tooltip title="Edit">
          <Button
            loading={isDownloadLoading}
            onClick={onEdit}
            shape="round"
            type="link"
            icon={<IconEdit size="24px" />}
            size="large"
            style={{ padding: 0, margin: 0, marginRight: '6px' }}
          />
        </Tooltip>
      )}
      <Tooltip title="Download">
        <Button
          loading={isDownloadLoading}
          disabled={isDownloadDisabled}
          onClick={onDownload}
          shape="round"
          type="link"
          icon={<IconDownload size="24px" />}
          size="large"
          style={{ padding: 0, margin: 0 }}
        />
      </Tooltip>
    </div>
  </SingleFileContainer>
);

const FileGroup: React.FC<FileGroupProps> = ({
  fileId,
  name,
  description,
  onDownload,
  onDownloadAll,
  supportingDocuments,
  uploadDate,
  sizeInMB,
  icon,
  isDownloadLoading,
  isDownloadDisabled,
  onAddOrEdit,
  onAddSupportingDocument,
  onRemove,
  removable,
  onEdit,
  editable,
  canAdd = false,
  newlyAddedId,
  searchFileUuid,
  canAddExpectedDate = false,
  expectedDateFormItem,
  expectedDate,
  extraField,
  className,
}) => {
  return (
    <Container className={className}>
      <Heading>
        <Details>
          <span>
            {icon || <AnzaFile size={50} color={theme.colors.primary} />}
          </span>
          <div>
            <Name>{name}</Name>
            <Uploaded>
              <UploadedData
                fileId={fileId}
                sizeInMB={sizeInMB}
                uploadDate={uploadDate}
              />
              {extraField}
              {canAddExpectedDate ? expectedDateFormItem : null}
              {expectedDate ? (
                <p>Expected availability date: {expectedDate}</p>
              ) : null}
            </Uploaded>
          </div>
        </Details>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {removable && onRemove && !isDownloadDisabled && (
            <Tooltip title="Remove">
              <Popconfirm
                placement="bottom"
                title="Are you sure to remove this file?"
                onConfirm={onRemove}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  loading={isDownloadLoading}
                  shape="round"
                  type="link"
                  icon={<IconTrash size={30} />}
                  size="large"
                  style={{ padding: 0, margin: 0, marginRight: '6px' }}
                />
              </Popconfirm>
            </Tooltip>
          )}

          {editable && onEdit && !isDownloadDisabled && (
            <Tooltip title="Edit">
              <Button
                loading={isDownloadLoading}
                onClick={onEdit}
                shape="round"
                type="link"
                icon={<IconEdit size={30} />}
                size="large"
                style={{ padding: 0, margin: 0, marginRight: '6px' }}
              />
            </Tooltip>
          )}

          {canAdd && !fileId && !newlyAddedId && !searchFileUuid ? (
            <Button
              type="dashed"
              onClick={onAddOrEdit}
              loading={isDownloadLoading}
              disabled={!isDownloadDisabled}
              style={{ width: '110px' }}
            >
              <IconAdd style={{ marginRight: '0.4rem' }} size="24px" /> Add
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={onDownload}
              loading={isDownloadLoading}
              disabled={
                isDownloadDisabled || !!newlyAddedId || !!searchFileUuid
              }
            >
              Download
            </Button>
          )}
        </div>
      </Heading>

      {description ? <Description>{description}</Description> : null}

      {supportingDocuments?.length || onAddSupportingDocument ? (
        <SupportingDocumentation>
          <>
            <SupportingDocumentationHeader>
              <Title>Supporting Documentation</Title>
              {supportingDocuments?.length ? (
                <Button
                  type="link"
                  onClick={onDownloadAll}
                  loading={isDownloadLoading}
                  disabled={
                    isDownloadDisabled || !!newlyAddedId || !!searchFileUuid
                  }
                >
                  Download All
                </Button>
              ) : null}
            </SupportingDocumentationHeader>

            <SupportingDocumentationGrid>
              <>
                {onAddSupportingDocument && (
                  <div>
                    <Button
                      type="dashed"
                      onClick={onAddSupportingDocument}
                      style={{
                        width: '100%',
                        height: '100%',
                        minHeight: '64px',
                      }}
                    >
                      <IconAdd style={{ marginRight: '0.4rem' }} size="24px" />{' '}
                      Add
                    </Button>
                  </div>
                )}
                {supportingDocuments?.map((document) => (
                  <SingleFile
                    isDownloadDisabled={
                      isDownloadDisabled || !!newlyAddedId || !!searchFileUuid
                    }
                    {...document}
                    key={`${document.name}_${Math.random()}`}
                  />
                ))}
              </>
            </SupportingDocumentationGrid>
          </>
        </SupportingDocumentation>
      ) : null}
    </Container>
  );
};

export default FileGroup;
