import React, { useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { Badge, ButtonAnt as Button, ButtonAnt, Typography } from '~/UI';
import { theme } from '~/utils';

import { FilterConfig, FilterValues } from './FilterForm';
import FilterDropdown from './FilterDropdown';
import FilterLabel from './FilterLabel';
import { IconChevronDown, IconClose } from '../Icons';

const StyledH4 = styled(Typography.H4)`
  font-weight: 600;
  color: ${theme.colors.gray800};
`;

const QuickClearAllButton = styled(ButtonAnt)`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.gray200};
  }
  transition: ease-out 0.2s;
  display: flex;
  border: none;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    box-shadow: none;
    font-weight: 600;
  }
`;

export const StyledButtonAnt = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.primary};
  &.ant-dropdown-open {
    border: 1px solid ${theme.colors.primary};
    background-color: ${theme.colors.primary}10;
  }
`;

export const ALL_FILTERS_KEY = 'all-filters';

export type OnFilterMenuValueChange = (event: {
  key: string;
  value: string;
  action: 'add' | 'remove';
  changedValue: FilterValues;
  allValues: FilterValues;
}) => void;

export type FilterMenuProps = {
  filters: FilterConfig[];
  onClearFilter?: (filterName: string) => void;
  /** Use this to controll the state from outside the FilterMenu component.
   * If you want to just set the initial values use initialValues instead. */
  values?: FilterValues;
  onApplyClick: (values: FilterValues) => void;
};

const FilterMenu = ({
  filters,
  onClearFilter,
  values = {},
  onApplyClick,
}: FilterMenuProps) => {
  const clearGroupFilter = (filter: string) => onClearFilter?.(filter);
  const [openDropdown, setOpenDropdown] = useState<string>();
  return (
    <>
      {filters.map((filterConfig) => {
        const { name, dropdownGroupTitle, datatype } = filterConfig;
        const isMultipleFilterValuesAvailable = datatype !== 'boolean';
        const currentFillterGroupIsEmpty =
          R.isEmpty(values[name]) || R.isNil(values[name]);
        const visible = !currentFillterGroupIsEmpty;
        const currentSelectedFilters = (values[name] as string[]) || [];
        const count = currentSelectedFilters.length;
        return visible ? (
          <FilterDropdown
            key={name}
            filter={filterConfig}
            values={values}
            open={isMultipleFilterValuesAvailable && openDropdown === name}
            onApplyClick={(values) => {
              onApplyClick(values);
              setOpenDropdown(undefined);
            }}
            onOpenChange={(open) => {
              if (!open) {
                // if previousOpenDropdown is the current dropdown we are closing
                setOpenDropdown((prev) => (prev === name ? undefined : prev));
              }
            }}
          >
            <FilterLabel
              highlighted
              onClick={(e) => {
                e.stopPropagation();
                setOpenDropdown(name);
              }}
            >
              <QuickClearAllButton
                square
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  clearGroupFilter?.(name);
                }}
              >
                <IconClose size={20} />
              </QuickClearAllButton>
              <StyledH4>{dropdownGroupTitle}</StyledH4>
              {isMultipleFilterValuesAvailable && !!count && (
                <StyledBadge color={theme.colors.primary} count={count} />
              )}
              {isMultipleFilterValuesAvailable ? (
                <IconChevronDown size={18} />
              ) : null}
            </FilterLabel>
          </FilterDropdown>
        ) : null;
      })}
    </>
  );
};

export default FilterMenu;
