import { DefaultOptionType } from 'antd/lib/select';
import * as R from 'ramda';

export const selectFilterOption = (input: string, option?: DefaultOptionType) =>
  (R.propOr('', 'children', option) as string)
    .toLowerCase()
    .includes((input || '').toLowerCase());

export const selectFilterOptionStartBy = (
  input: string,
  option?: DefaultOptionType
) => {
  const child = Array.isArray(option?.children)
    ? option?.children?.[0]
    : option?.children;
  return child?.toLowerCase().indexOf((input || '').toLowerCase()) >= 0;
};

export const selectFilterSort = (
  optionA: DefaultOptionType,
  optionB: DefaultOptionType
) =>
  (R.propOr('', 'children', optionA) as string)
    .toLowerCase()
    .localeCompare((R.propOr('', 'children', optionB) as string).toLowerCase());

export const columnSorter =
  (path: string[]) =>
  <T>(a: T, b: T) => {
    const aValue = R.pathOr('', path, a);
    const bValue = R.pathOr('', path, b);

    const aValueParsed =
      typeof aValue === 'number' ? aValue : `${aValue}`.toLowerCase();
    const bValueParsed =
      typeof bValue === 'number' ? bValue : `${bValue}`.toLowerCase();

    if (aValueParsed > bValueParsed) return 1;
    if (aValueParsed < bValueParsed) return -1;
    return 0;
  };

export const columnNumberSorterWithFalsyValues =
  (path: string[]) =>
  <T>(a: T, b: T) => {
    const aValue: unknown = R.pathOr('', path, a);
    const bValue: unknown = R.pathOr('', path, b);

    const aValueParsed =
      typeof aValue === 'number' ? aValue : Number(aValue);
    const bValueParsed =
      typeof bValue === 'number' ? bValue : Number(bValue);

    if (!aValue && aValue !== 0) return 1
    if (!bValue && bValue !== 0) return -1
    if (aValueParsed > bValueParsed) return 1;
    if (aValueParsed < bValueParsed) return -1;
    return 0;
  };

export const rankColumnSorter =
  (path: string[]) =>
  <T>(a: T, b: T) => {
    const aValue = R.pathOr('', path, a);
    const bValue = R.pathOr('', path, b);

    if (aValue === null || aValue === '') return 1;
    if (bValue === null || bValue === '') return -1;

    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;
    return 0;
  };

export const columnJoinStringSorter =
  (fields: string[]) =>
  <T>(a: T, b: T) => {
    const picker = R.pipe(
      R.defaultTo({}),
      R.pick(fields),
      R.values,
      R.join(', ')
    );

    return picker(a) > picker(b) ? 1 : -1;
  };

export const columnArrayStringSorter =
  (path: string[], map: string[]) =>
  <T>(a: T, b: T) => {
    const picker = R.pipe(
      R.pathOr([], path),
      R.map(R.pathOr('', map)),
      R.join(', ')
    );

    return picker(a) > picker(b) ? 1 : -1;
  };

export const booleanColumnSorter =
  (path: string[]) =>
  <T>(a: T, b: T) => {
    return R.pathOr('', path, a).toString() > R.pathOr('', path, b).toString()
      ? 1
      : -1;
  };

export const nameSorter = (
  optionA: { name: string },
  optionB: { name: string }
) => optionA.name.toLowerCase().localeCompare(optionB.name.toLocaleLowerCase());
