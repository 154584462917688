import slice from './slice';

export const {
  storeProjectType,
  storeSolarProject,
  storeStorageProject,
  toggleSearchModal,
  updateModalMode,
  resetSearchModal,
} = slice.actions;
