import React from 'react';
import styled from '@emotion/styled';
import { Alert } from '~/UI';
import { IconTipsAndUpdates } from '~/UI/Icons';
import { theme } from '~/utils';
import {
  GTM_EVENTS,
  GTM_SOURCE_IDS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';

const StyledAlert = styled(Alert)`
  color: ${theme.colors.info4light};
  background: ${theme.colors.info1};
  border-color: ${theme.colors.info1};
  height: 54px;
  max-width: ${theme.dimensions.width.max};
  margin: auto;
  padding: 0 40px;
`;

const StyledMessage = styled.span`
  color: ${theme.colors.info4};
  font-weight: 600;
  display: flex;
  align-items: center;
  max-width: ${theme.dimensions.width.max};
  white-space: pre-wrap;
`;

const StyledDiv = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const PricingHistoryBanner = () => (
  <StyledDiv>
    <StyledAlert
      message={
        <StyledMessage>
          <a
            href="/pricing-history"
            data-gtm-id={GTM_SOURCE_IDS.PRICING_HISTORY.ACCESS_PRICING_HISTORY}
            data-gtm-type={GTM_SOURCE_TYPES.LINK}
            onClick={() => {
              maybeSendPlatformEventToGTM(
                GTM_EVENTS.CLICK,
                GTM_SOURCE_IDS.PRICING_HISTORY.ACCESS_PRICING_HISTORY
              );
            }}
          >
            Access Anza Module Pricing Trends
          </a>
        </StyledMessage>
      }
      icon={<IconTipsAndUpdates color={theme.colors.info4} size={20} />}
      showIcon
    />
  </StyledDiv>
);

export default PricingHistoryBanner;
