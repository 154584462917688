import AdminProjects from '../routes/AdminProjects';
import AdminCompanies from '../routes/AdminCompanies';
import AdminDomains from '../routes/AdminDomains';
import AdminLocationModifiers from '../routes/AdminLocationModifiers';
import AdminModules from '../routes/AdminModules';
import AdminUsers from '../routes/AdminUsers';
import AdminDcBlocks from '../routes/AdminDcBlocks';
import AdminPcs from '../routes/AdminPcs';

export interface AdminRoutePath {
  default: string;
  new: string;
  edit: (id: string) => string;
}

const adminRouteCreator = (path: string) => ({
  default: path,
  new: `${path}/new`,
  edit: (id: string) => `${path}/edit/${id}`,
});

export interface AdminRoute {
  text: string;
  path: AdminRoutePath;
  component: React.FC<any>;
  default: boolean;
  hideFromNavbar?: boolean;
}

export type AdminRoutesConfig = { [key: string]: AdminRoute };

export const ADMIN_ROUTES: AdminRoutesConfig = {
  ANZA_PROJECTS: {
    text: 'Projects',
    path: adminRouteCreator('/admin/projects'),
    component: AdminProjects,
    default: true,
  },
  COMPANIES: {
    text: 'Companies',
    path: adminRouteCreator('/admin/companies'),
    component: AdminCompanies,
    default: false,
  },
  DOMAINS: {
    text: 'Domains',
    path: adminRouteCreator('/admin/domains'),
    component: AdminDomains,
    default: false,
  },
  LOCATION_MODIFIERS: {
    text: 'Location Modifiers',
    path: adminRouteCreator('/admin/location-modifiers'),
    component: AdminLocationModifiers,
    default: false,
  },
  MODULES: {
    text: 'Modules',
    path: adminRouteCreator('/admin/modules'),
    component: AdminModules,
    default: false,
  },
  DC_BLOCKS: {
    text: 'AC / DC Blocks',
    path: adminRouteCreator('/admin/dc-blocks'),
    component: AdminDcBlocks,
    default: false,
  },
  PCS: {
    text: 'PCS',
    path: adminRouteCreator('/admin/pcs'),
    component: AdminPcs,
    default: false,
  },
  USERS: {
    text: 'Users',
    path: adminRouteCreator('/admin/users'),
    component: AdminUsers,
    default: false,
  },
};

export const ADMIN_SETTINGS = Object.values(ADMIN_ROUTES);
