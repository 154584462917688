import { useEffect, useState } from 'react';
import { SMALL_DESKTOP_HEIGHT, SMALL_DESKTOP_WIDTH } from '~/utils';

interface WindowSize {
  width: number;
  height: number;
}

const defaultWindowSize: WindowSize = {
  width: SMALL_DESKTOP_WIDTH,
  height: SMALL_DESKTOP_HEIGHT,
};

const useResizeListener = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>(defaultWindowSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useResizeListener;
