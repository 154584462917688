import { useHistory } from 'react-router';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ROUTER_PATHS } from '~/router/Paths';
import { ServerResponses } from '~/utils';

const useHandleProjectError = () => {
  const history = useHistory();

  const handleProjectError = (
    error?: FetchBaseQueryError | SerializedError
  ) => {
    const hasStatus = error && 'status' in error;
    const isStatusNotFound =
      hasStatus && error?.status === ServerResponses.NOT_FOUND;
    if (isStatusNotFound) {
      history.replace(ROUTER_PATHS.PAGE_NOT_FOUND());
    }
  };

  return { handleProjectError };
};

export default useHandleProjectError;
