import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import styled from '@emotion/styled';
import { useWatch } from 'antd/es/form/Form';
import { SolarProject, TaxCredit } from '~/store/project';
import { IconEdit, IconTrash } from '~/UI/Icons';
import { Form, FormInstance } from '~/UI';
import DeleteModalConfirm from '~/UI/DeleteModalConfirm';

import { StyledEnergyHeader, StyledFormHeader } from '../SolarProjectEditForm';
import { EnergyTermModal } from './EnergyTermModal';
import { getEscalationRate } from '~/utils';

interface Props {
  form: FormInstance;
  project: SolarProject;
  disableEdition?: boolean;
  setIsDirty?: (value: boolean) => void;
}

const StyledEnergyElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 1em 5px;
    margin-top: 10px;
    border-bottom: 0.5px solid #adb3b4;
    button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5em;
      color: #adb3b4;
      & :hover {
        color: #757474;
      }
    }
  }
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1em;
`;

export const EnergyTerms = ({
  form,
  project,
  disableEdition,
  setIsDirty,
}: Props) => {
  const [energyModalOpen, setEnergyModalOpen] = useState<boolean>(false);
  const [energyTermToDelete, setEnergyTermToDelete] = useState<TaxCredit>();
  const [energyTermToEdit, setEnergyTermToEdit] = useState<TaxCredit>();

  const [terms, setTerms] = useState<TaxCredit[]>(
    form.getFieldValue('energy_terms')
  );

  const updateEnergyTerms = (newTerms: TaxCredit[]) => {
    setTerms(newTerms);
    setIsDirty?.(true);
    setEnergyTermToEdit(undefined);
    form.setFieldValue('energy_terms', newTerms);
  };

  const deleteEnergyTerm = (termToDelete?: TaxCredit) => {
    if (termToDelete) {
      const newTermsArray = terms?.filter((term) => term !== termToDelete);
      updateEnergyTerms(newTermsArray);
      setIsDirty?.(true);
      setEnergyTermToDelete(undefined);
    }
  };

  const clearModal = () => {
    setEnergyModalOpen(false);
    setEnergyTermToEdit(undefined);
  };

  useEffect(() => {
    setEnergyModalOpen(!!energyTermToEdit);
  }, [energyTermToEdit]);

  const currentProjectTermYears = useWatch('project_term_years', form);
  return (
    <>
      <StyledEnergyHeader>
        <StyledFormHeader>Energy Terms</StyledFormHeader>
        {!disableEdition && (
          <Button
            type="default"
            onClick={() => {
              setEnergyModalOpen(true);
              form.resetFields(['energy_terms']);
            }}
          >
            + Add Energy Term
          </Button>
        )}
      </StyledEnergyHeader>
      <StyledEnergyElementsContainer>
        <Form.List name="energy_terms" initialValue={terms}>
          {() => (
            <div>
              {terms
                ?.filter((term) => term.credit_type === 'ENERGY_TERM')
                .map((term, _, arr) => (
                  <Form.Item
                    // @ts-ignore
                    key={term.uuid}
                  >
                    <div className="row">
                      <span>
                        {`$${term.base_credit?.toFixed(
                          4
                        )} / kWh @ ${getEscalationRate(term).toFixed(
                          2
                        )}% escalation from year ${
                          term.start_year
                        } through year ${
                          (term?.start_year || 0) - 1 + (term?.duration || 0)
                        }`}
                      </span>
                      {!disableEdition && (
                        <StyledButtonsContainer>
                          <button
                            type="button"
                            onClick={() => setEnergyTermToEdit(term)}
                          >
                            <IconEdit />
                          </button>
                          {arr.length > 1 && (
                            <button
                              type="button"
                              onClick={() => setEnergyTermToDelete(term)}
                            >
                              <IconTrash />
                            </button>
                          )}
                        </StyledButtonsContainer>
                      )}
                    </div>
                  </Form.Item>
                ))}
            </div>
          )}
        </Form.List>
      </StyledEnergyElementsContainer>
      <EnergyTermModal
        energyModalOpen={energyModalOpen}
        setEnergyModalOpen={setEnergyModalOpen}
        updateEnergyTerms={updateEnergyTerms}
        terms={terms}
        project={project}
        termToEdit={energyTermToEdit}
        onCancel={clearModal}
        currentProjectTermYears={currentProjectTermYears}
      />
      <DeleteModalConfirm
        title="Delete energy term?"
        visible={!!energyTermToDelete}
        onOk={() => deleteEnergyTerm(energyTermToDelete)}
        onCancel={() => {
          setEnergyTermToDelete(undefined);
        }}
      />
    </>
  );
};
