import React, { useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router';

import { Form, InputAnt as Input, Overlay, Spinner, FormItemLabel } from '~/UI';
import { UserType } from '~/types/users';
import { blockEnterKeyEvents } from '~/utils/events';
import { requiredRule, phoneNumberRule, formErrorScroll } from '~/utils';
import { AdminFormLayout } from '~/components/Admin/AdminLayout/AdminFormLayout';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { rtkService, UserResponse } from '~/types/rtkApi';
import { useUpdateUserMutation } from '~/store/api';
import FormFooter from '~/components/FormFooter';
import useFormDirty from '~/hooks/useFormDirty';

const AdminUsersForm: React.FC<
  RouteComponentProps & {
    service: rtkService;
  }
> = ({ service, history }) => {
  const { id }: { id: string } = useParams() || {};
  const [form] = Form.useForm();
  const [updateUser] = useUpdateUserMutation();

  const { data, isLoading = true } = service.get({ id }) as UserResponse;

  const checkInvalid = async () => {
    try {
      await form.validateFields(['first_name', 'last_name', 'email']);
    } catch (error) {
      formErrorScroll();
    }
  };

  useEffect(() => {
    if (data) {
      const { first_name, last_name, job_title, email, phone_number, domain } =
        data;
      form.setFieldsValue({
        first_name,
        last_name,
        job_title,
        email,
        phone_number: phone_number ?? '',
        domain,
      });
      checkInvalid();
    }
  }, [data]);

  const goBack = () => history.push(ADMIN_ROUTES.USERS.path.default);

  const onFinish = async (values: UserType) => {
    await updateUser({
      user: {
        ...values,
        id: +id,
        email: form.getFieldValue('email'),
      },
    });
    goBack();
  };

  const { isDirty, onValuesChange } = useFormDirty();

  if (isLoading) {
    return null;
  }

  return (
    <AdminFormLayout>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ width: '100%' }}
        onKeyDown={blockEnterKeyEvents}
        onValuesChange={onValuesChange}
      >
        {isLoading && (
          <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
            <Spinner />
          </Overlay>
        )}
        <Form.Item
          name="email"
          label={<FormItemLabel>Email</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="email" disabled />
        </Form.Item>
        <Form.Item
          name="first_name"
          label={<FormItemLabel>First Name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="first_name" placeholder="Insert First Name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label={<FormItemLabel>Last Name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[requiredRule]}
        >
          <Input required name="last_name" placeholder="Insert Last Name" />
        </Form.Item>
        <Form.Item
          name="job_title"
          label={<FormItemLabel>Job Title</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <Input name="job_title" placeholder="Insert Job Title" />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label={<FormItemLabel>Phone Number</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[phoneNumberRule]}
        >
          <Input name="phone_number" placeholder="Phone Number" />
        </Form.Item>
        <FormFooter
          isDirty={isDirty}
          saveButtonProps={{
            onClick: checkInvalid,
          }}
        />
      </Form>
    </AdminFormLayout>
  );
};

export default AdminUsersForm;
