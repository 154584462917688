import React from 'react';
import * as R from 'ramda';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFlags } from '~/utils/launchdarkly';

import { ChangeMetaDescriptor } from '~/utils/changeMetaDescriptor';
import { BorregoRouteProps } from '~/types/route-props';
import { AdminLayout } from '~/components/Admin/AdminLayout';
import { AdminGlobalStyle } from '~/utils';
import Page from '~/router/Page';
import { filterAdminRoutesByStorageModulesFeatureFlag } from '~/utils/routes';
import {
  ADMIN_ROUTES,
  AdminRoute,
  AdminRoutePath,
} from '../router/AdminRoutes';

interface MenuItemType {
  text: string;
  path: AdminRoutePath;
}

const ADMIN_SETTINGS = R.values(ADMIN_ROUTES);

const menuItems = R.map(
  R.pick(['text', 'path']),
  ADMIN_SETTINGS
) as MenuItemType[];

const defaultMenuItem = (R.find(R.propEq('default', true), ADMIN_SETTINGS) ||
  ADMIN_SETTINGS[0]) as AdminRoute;

const Admin: React.FC<BorregoRouteProps> = () => {
  ChangeMetaDescriptor('Admin');
  const adminTabTilePrefix = 'Admin | ';
  const flags = useFlags();

  const filteredAdminRoutes = filterAdminRoutesByStorageModulesFeatureFlag(
    flags.storageModule,
    ADMIN_ROUTES
  );
  return (
    <>
      <AdminGlobalStyle />
      <Route>
        <AdminLayout menuItems={menuItems}>
          <Switch>
            <Route exact path="/admin">
              <Redirect to={defaultMenuItem.path.default} />
            </Route>
            {filteredAdminRoutes.map(
              ({ path, component: Component, text }: AdminRoute) => (
                <Route
                  path={path.default}
                  key={text}
                  render={() => (
                    <Page title={`${adminTabTilePrefix}${text}`}>
                      <Component />
                    </Page>
                  )}
                />
              )
            )}
          </Switch>
        </AdminLayout>
      </Route>
    </>
  );
};

export default Admin;
