import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Layout } from '../UI';
import { theme } from '../utils';
import { circularBanner } from '../assets';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.gray100};
`;

const ErrorTitle = styled.h1`
  color: ${theme.fonts.colors.charcoal};
  font-size: 36px;
  font-weight: 300;
`;

const ErrorMessage = styled.h3`
  color: ${theme.fonts.colors.graphite};
  font-size: 14px;
  font-weight: 400;
`;

export interface ErrorProps extends RouteComponentProps {
  message: string;
  title?: string;
  action?: string;
  location: {
    state: {
      [key: string]: any;
    };
  };
}

const ErrorPage: React.FC<ErrorProps> = ({
  message,
  title,
  action,
  location,
}) => {
  const [hasState, setHasState] = React.useState<boolean>(false);
  const { state } = location;

  React.useEffect(() => {
    if (state !== undefined && Object.keys(state).length) {
      setHasState(true);
    }
  }, [state]);

  const renderStateErrors = () => (
    <>
      <div
        style={{
          textAlign: 'left',
          marginTop: '0.8rem',
          background: '#EEEFF0',
          padding: '0.8rem',
          borderRadius: '0.5rem',
          maxWidth: '600px',
          wordWrap: 'break-word',
        }}
      >
        <ErrorMessage style={{ marginBottom: '0.5rem' }}>
          {state && state.url && <>Url: {state.url}</>}
        </ErrorMessage>

        {(state.missingParams && state.missingParams.length && (
          <ErrorMessage>
            Missing parameters:{' '}
            {state.missingParams.map(
              (el: string, index: number) =>
                `${el}${index === state.missingParams.length - 1 ? '.' : ', '}`
            )}
          </ErrorMessage>
        )) ||
          null}
        {(state.missingValues && state.missingValues.length && (
          <ErrorMessage>
            Invalid values from:{' '}
            {state.missingValues.map(
              (el: string, index: number) =>
                `${el}${index === state.missingValues.length - 1 ? '.' : ', '}`
            )}
          </ErrorMessage>
        )) ||
          null}
      </div>
    </>
  );

  return (
    <Layout>
      <Container>
        <img
          src={circularBanner}
          alt=""
          width="289px"
          style={{ marginTop: '3rem', marginBottom: '2rem' }}
        />
        <ErrorTitle style={{ marginBottom: '0.5rem' }}>
          {title || "Hmm. Something's not right here."}
        </ErrorTitle>
        <ErrorMessage>
          {state && state.salesforceError
            ? 'We were unable to pull data through from Salesforce.'
            : message || 'An error has occurred.'}
        </ErrorMessage>
        {hasState && renderStateErrors()}
        <Button
          style={{ maxWidth: '344px', marginTop: '3rem' }}
          onClick={() => window.location.replace('/')}
        >
          {action || 'Back to home'}
        </Button>
      </Container>
    </Layout>
  );
};

ErrorPage.defaultProps = {
  title: '',
  action: 'Back to home',
};

export const ErrorPagePure = ErrorPage;

export default withRouter(ErrorPage);
