import React, { memo } from 'react';
import styled from '@emotion/styled';
import {
  Form,
  InputNumber,
  DatePicker,
  InputMultine,
  Radio,
  FormItemLabel,
  FormGroup,
} from '~/UI';
import { RadioGroup } from '~/UI/Radio';
import { inRangeValidator } from '~/utils/numberValidators';
import { PercentSettings } from '~/utils/formatters';
import { notRequiredRules, requiredFieldRule } from '~/utils';
import { useFlags } from '~/utils/launchdarkly';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 46px;
  .ant-picker-input input {
    height: 48px;
    font-size: 16px;
  }
`;

const PricingAndAvailabilityGroup: React.FC = () => {
  return (
    <FormGroup
      key="Pricing And Availability"
      title="Pricing &amp; Availability"
      style={{ maxWidth: '100%' }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
          gridTemplateRows: 'auto',
          gap: '1rem',
        }}
      >
        <Form.Item
          name="shipping_notes_exceptions"
          label={<FormItemLabel>Shipping Notes / Exceptions</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <InputMultine
            placeholder="Shipping Notes / Exceptions"
            rows={4}
            style={{ fontSize: '14px' }}
          />
        </Form.Item>
        <Form.Item
          name="total_mw_remaining_for_po"
          label={<FormItemLabel>Total MW remaining for PO</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={[
            {
              required: false,
              validator: inRangeValidator(
                0,
                99999,
                true,
                'Please enter an number between 0 and 99999, ex. 12',
                false,
                false
              ).validator,
            },
          ]}
          validateTrigger="onBlur"
        >
          <InputNumber
            name="total_mw_remaining_for_po"
            placeholder="Total MW remaining for PO"
            precision={3}
            addonAfter="MW"
          />
        </Form.Item>
      </div>
    </FormGroup>
  );
};

export default memo(PricingAndAvailabilityGroup);
