import React, { useEffect } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { Form, ButtonAnt as Button, DropdownAnt } from '~/UI';
import { colors } from '~/utils';
import { GTM_EVENTS, maybeSendPlatformEventToGTM } from '~/utils/gtm';
import type { DropdownProps } from '~/UI/DropdownAnt';

import FilterForm, { FilterConfig, FilterValues } from './FilterForm';
import DropdownAffix from '../DropdownAffix';
import DropdownContent from './DropdownContent';

export const StyledButton = styled(Button)`
  border-color: ${colors.gray200};
`;

export const StyledDropdown = styled(DropdownAnt)<
  DropdownProps & { horizontallyCenteredDropdown?: boolean }
>`
  .ant-card {
    border-radius: 12px;
  }
`;

export const FiltersFooter = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 -1px 11px rgba(159, 159, 159, 0.25);
`;

export const FiltersHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
  color: ${colors.gray800};
  svg {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`;

export type ActionsLocation = 'header' | 'footer';

export type FilterDropdownProps = {
  filter: FilterConfig;
  values?: FilterValues;
  children: React.ReactNode;
  gtmId?: string;
  gtmType?: string;
  dropdownPlacement?: DropdownProps['placement'];
  /** Selects the location of actions button (Reset, Clear All, ...). */
  /** Use this value to handle the state outside the GroupFilterDropdown component */
  onApplyClick: (values: FilterValues) => void;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
};

const FilterDropdown = ({
  filter,
  values,
  children,
  gtmId,
  gtmType,
  dropdownPlacement = 'bottom',
  onApplyClick,
  onOpenChange,
  open,
}: FilterDropdownProps) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (!R.equals(values, form.getFieldsValue())) {
      form.resetFields();
    }
  }, [values]);

  return (
    <div id={gtmId} data-gtm-id={gtmId} data-gtm-type={gtmType}>
      <DropdownAffix
        open={open}
        trigger={['click']}
        destroyPopupOnHide
        onOpenChange={(open: boolean) => {
          maybeSendPlatformEventToGTM(
            open ? GTM_EVENTS.POPUP_SHOW : GTM_EVENTS.POPUP_HIDE,
            gtmId ?? null
          );
          onOpenChange?.(open);
        }}
        placement={dropdownPlacement}
        dropdownRender={() => {
          return (
            <DropdownContent>
              <FilterForm
                onBackButtonClick={() => {}}
                onApplyClick={onApplyClick}
                filter={filter}
                values={values}
              />
            </DropdownContent>
          );
        }}
      >
        {children}
      </DropdownAffix>
    </div>
  );
};

export default FilterDropdown;
