import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 100px);
  flex-grow: 1;
  padding: 0;
  background-color: white;
`;

export default Container;
