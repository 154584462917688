import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { routes } from '~/api/routes';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import { LocationType } from '~/types/locations';

export const adminLocationModifiersApi = createApi({
  reducerPath: 'adminLocationModifiersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 0,
  tagTypes: ['AllLocationModifiers', 'AdminGetLocation'],
  endpoints: (builder) => ({
    getAllLocationModifiers: builder.query<LocationType[], void>({
      query: () => routes.admin.locationModifiers(),
      keepUnusedDataFor: 3,
      providesTags: () => [
        { type: 'AllLocationModifiers', id: 'ALL_LOCATION_MODIFIERS' },
      ],
    }),
    getLocation: builder.query<LocationType, { id: number }>({
      query: ({ id }) => `${routes.admin.locationModifiers()}/${id}`,
      providesTags: () => [
        { type: 'AdminGetLocation', id: 'ADMIN_GET_LOCATION' },
      ],
    }),
    createLocation: builder.mutation<LocationType, { location: LocationType }>({
      query: ({ location }) => ({
        url: routes.admin.locationModifiers(),
        method: 'POST',
        body: location,
      }),
    }),
    updateLocation: builder.mutation<LocationType, { location: LocationType }>({
      query: ({ location }) => {
        const { id } = location;
        return {
          url: routes.admin.locationModifiersSettings(id!),
          method: 'PUT',
          body: location,
        };
      },
    }),
    deleteLocation: builder.mutation<LocationType, { id: string }>({
      query: ({ id }) => {
        return {
          url: routes.admin.locationModifiersSettings(id),
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetAllLocationModifiersQuery,
  useDeleteLocationMutation,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useGetLocationQuery,
} = adminLocationModifiersApi;
