import React from 'react';
import { useGetAllUsersQuery, useGetUserQuery } from '~/store/api';
import { rtkService } from '~/types/rtkApi';
import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminUsersForm from '../components/Admin/Users/AdminUsersForm';
import AdminUsersList from '../components/Admin/Users/AdminUsersList';

const AdminUsers: React.FC = () => {
  const service = {
    getAll: useGetAllUsersQuery,
    get: useGetUserQuery,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="Users"
      listComponent={AdminUsersList}
      formComponent={AdminUsersForm}
      path={ADMIN_ROUTES.USERS.path}
      service={service}
    />
  );
};

export default AdminUsers;
