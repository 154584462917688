import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Form, Select, Option, FormItemLabel } from '~/UI';
import { UserType } from '~/types/users';
import { useGetAllUsersQuery } from '~/store/api';

export interface AdminProjectUserDropdownProps {
  label: string;
  name: string;
  initialValue?: string;
  selectedUser?: number;
  onAssignUser?: (id: number) => void;
  placeholder?: string;
  filterByRole?: string;
  setLoadingState?: (isLoading: boolean) => void;
}

const StyledSelect = styled(Select)`
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
`;

const AdminProjectUserDropdown: React.FC<AdminProjectUserDropdownProps> = ({
  name,
  label,
  selectedUser,
  initialValue,
  onAssignUser,
  placeholder = 'Assign a user',
  setLoadingState = () => {},
}) => {
  const [value, setValue] = useState<string | undefined>(initialValue);
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );
  const [users, setUsers] = useState<UserType[]>([]);

  const {
    data: usersData,
    isFetching: isUsersDataFetching,
    isLoading: isUsersDataLoading,
  } = useGetAllUsersQuery();

  useEffect(() => {
    if (!isUsersDataFetching) {
      setUsers(usersData ?? []);
    }
    setLoadingState(isUsersDataFetching || isUsersDataLoading);
  }, [isUsersDataFetching]);

  const onBlur = () => {
    const emailIsInUsers = users.filter((u) => u.email === value).length > 0;
    const currentlySelectedUser = users.find((u) => u.id === selectedUser);
    if (!value || (!emailIsInUsers && currentlySelectedUser)) {
      setValue(currentlySelectedUser?.email as string);
    }
  };

  const onSearch = (searchText: string) => {
    setOptions([
      ...users
        .filter(
          (u) =>
            searchText.length === 0 ||
            u.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            u.company?.name.toLowerCase().includes(searchText.toLowerCase()) ||
            `${u.first_name}${u.last_name}`
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
        .map((u) => ({
          label: `${u.first_name} ${u.last_name} - ${u.email}`,
          value: u.id as number,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ]);
  };

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    onSearch('');
  };

  useEffect(() => {
    if (selectedUser && users && users.length) {
      const currentlySelectedUser = users.find((u) => u.id === selectedUser);
      if (currentlySelectedUser) {
        setValue(currentlySelectedUser?.email as string);
      }
    }
  }, [selectedUser, users]);

  const onSelect = (data: string) => {
    const currentlySelectedUser = users.find((u) => u.id === +data);
    if (currentlySelectedUser) {
      setValue(currentlySelectedUser.email as string);
      if (onAssignUser) {
        onAssignUser(currentlySelectedUser.id as number);
      }
    }
  };

  if (!options.length && selectedUser && value) {
    options.push({
      label: value,
      value: selectedUser,
    });
  }

  return (
    <Form.Item
      label={<FormItemLabel>{label}</FormItemLabel>}
      name={name}
      labelCol={{ span: 24 }}
    >
      <StyledSelect
        showSearch
        value={value ?? initialValue}
        onClick={onClick}
        onBlur={onBlur}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onSelect}
        onSearch={onSearch}
        disabled={isUsersDataLoading || isUsersDataFetching}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </Form.Item>
  );
};

export default AdminProjectUserDropdown;
