import React from 'react';
import dayjs from 'dayjs';
import { Form, InputNumber, Option, FormInstance } from '~/UI';
import { StyledDatePicker } from '~/UI/DatePicker';
import { TEST_DATA_COMPONENTS, generateTestId } from '~/utils/dataTestProps';
import {
  disabledDateFromUntil,
  meteringLocationOptions,
  projectTopologyOptions,
  STORAGE_ROLLING_WINDOW_IN_MONTHS,
  CAPACITY_MAINTENANCE_STRATEGY_OPTIONS,
} from '~/utils';
import { SelectFormItem } from '~/UI/Select';
import STORAGE_INPUT_CONFIG from '~/constants/inputs/project/storageInputs';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import { GenericLabel } from '../FormGroup';

interface StorageFormGroupProps {
  form: FormInstance;
  isEditing: boolean;
}

const StorageFormGroup: React.FC<StorageFormGroupProps> = ({
  form,
  isEditing,
}) => {
  return (
    <>
      <Form.Item
        name="target_ac_power_mw"
        label={
          <GenericLabel
            title="Target AC Power"
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={STORAGE_INPUT_CONFIG.targetAcPowerMw.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.targetAcPowerMw.inputNumberProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'target-ac-power',
          })}
        />
      </Form.Item>
      <Form.Item
        name="duration_hours"
        label={
          <GenericLabel title="Duration" fontSize={isEditing ? '14px' : ''} />
        }
        rules={STORAGE_INPUT_CONFIG.durationHours.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.durationHours.inputNumberProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'duration',
          })}
        />
      </Form.Item>
      <Form.Item
        name="power_factor"
        label={
          <GenericLabel
            title="Power Factor"
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={STORAGE_INPUT_CONFIG.powerFactor.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.powerFactor.inputNumberProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'power-factor',
          })}
        />
      </Form.Item>
      <Form.Item
        name="annual_cycle_count"
        label={
          <GenericLabel
            title="Annual Cycle Count"
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={STORAGE_INPUT_CONFIG.annualCycleCount.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.annualCycleCount.inputNumberProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'annual-cycle-count',
          })}
        />
      </Form.Item>
      <Form.Item
        name="storage_arrival_date"
        label={
          <GenericLabel
            title="Delivery Date"
            popoverContent="The date you expect products to arrive onsite."
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={PROJECT_INPUT_CONFIG.arrivalDate.rules}
      >
        <StyledDatePicker
          {...PROJECT_INPUT_CONFIG.arrivalDate.datePickerProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'anticipated-date',
          })}
          disabledDate={disabledDateFromUntil}
          inputReadOnly
          defaultPickerValue={dayjs().add(
            STORAGE_ROLLING_WINDOW_IN_MONTHS,
            'months'
          )}
        />
      </Form.Item>
      <Form.Item
        name="anticipated_purchase_date"
        label={
          <GenericLabel
            title="Anticipated Purchase Date"
            popoverContent="The date you expect to execute a contract with Anza."
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyRules(
          () =>
            form.getFieldsValue(['storage_arrival_date'])
              .storage_arrival_date || undefined
        )}
      >
        <StyledDatePicker
          {...PROJECT_INPUT_CONFIG.anticipatedPurchaseDate.applyDatePickerProps(
            () =>
              form.getFieldsValue(['storage_arrival_date'])
                .storage_arrival_date || undefined
          )}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.DATE_PICKER}`,
            identifier: 'anticipated-date',
          })}
          inputReadOnly
        />
      </Form.Item>
      <Form.Item
        name="capacity_maintenance_term"
        label={
          <GenericLabel
            title="Capacity Maintenance Term"
            fontSize={isEditing ? '14px' : ''}
            popoverContent="Desired length of time for the system to maintain a specific target power and duration. This can be achieved via augmentation or substantial overbuilds."
          />
        }
        rules={STORAGE_INPUT_CONFIG.capacityMaintenanceTerm.rules}
      >
        <InputNumber
          {...STORAGE_INPUT_CONFIG.capacityMaintenanceTerm.inputNumberProps}
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.INPUT}`,
            identifier: 'capacity-maintenance-term',
          })}
        />
      </Form.Item>
      <SelectFormItem
        name="capacity_maintenance_strategy"
        label={
          <GenericLabel
            title="Capacity Maintenance Strategy"
            fontSize={isEditing ? '14px' : ''}
            popoverContent="Optimal allows the engine to seek the best Capacity Maintenance Strategy via augmentation and overbuild to minimize lifetime project costs. Overbuild Only produces architectures where the only capacity maintenance strategy is an initial equipment over sizing and prohibits augmentation."
          />
        }
        rules={STORAGE_INPUT_CONFIG.capacityMaintenanceStrategy.rules}
        selectProps={
          STORAGE_INPUT_CONFIG.capacityMaintenanceStrategy.selectProps
        }
      >
        {CAPACITY_MAINTENANCE_STRATEGY_OPTIONS.map((el, index) => (
          <Option
            key={el}
            value={el}
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.SELECT_OPTION}`,
              identifier: 'capacity-maintenance-strategy',
              indices: index.toString(),
            })}
          >
            {el}
          </Option>
        ))}
      </SelectFormItem>
      <SelectFormItem
        name="metering_location"
        label={
          <GenericLabel
            title="Metering Location"
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={STORAGE_INPUT_CONFIG.meteringLocation.rules}
        selectProps={STORAGE_INPUT_CONFIG.meteringLocation.selectProps}
      >
        {meteringLocationOptions.map((el) => (
          <Option key={el} value={el}>
            {el}
          </Option>
        ))}
      </SelectFormItem>
      <SelectFormItem
        name="project_topology"
        label={
          <GenericLabel
            title="Project Topology"
            fontSize={isEditing ? '14px' : ''}
          />
        }
        rules={STORAGE_INPUT_CONFIG.projectTopology.rules}
        selectProps={STORAGE_INPUT_CONFIG.projectTopology.selectProps}
      >
        {projectTopologyOptions.map((el) => (
          <Option
            key={el}
            value={el}
            disabled={el === 'DC-Coupled (coming soon)'}
            style={{
              color: el === 'DC-Coupled (coming soon)' ? 'gray' : 'initial',
            }}
          >
            {el}
          </Option>
        ))}
      </SelectFormItem>
    </>
  );
};

export default StorageFormGroup;
