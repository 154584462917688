import { SolarPermissions } from '~/store/project';
import useBaseProjectPermissions from './useBaseProjectPermissions';

const useSolarPermissions = (permissions: SolarPermissions) => {
  return {
    ...useBaseProjectPermissions(permissions),
    showP360Content: permissions.analyticsData.read,
  };
};

export default useSolarPermissions;
