import React from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { isEllipsisActive, stringToKebabCase, theme } from '~/utils';
import { DecimalSettingsCenti } from '~/utils/formatters';
import { Typography, Tooltip } from '~/UI';

const Suffix = styled.span`
  padding-left: 5px;
`;
export interface BigValueLabelProps {
  title: string;
  value: number | bigint | string | null | undefined;
  containerStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  showValueOnHover?: boolean | undefined;
  suffix?: string | undefined;
}

const defaultStyles: React.CSSProperties = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const formatValue = (
  displayValue: string | number | bigint | null | undefined
) => {
  if (typeof displayValue === 'string') {
    return displayValue;
  }

  return DecimalSettingsCenti.formatter(displayValue as number);
};

const isNilOrEmpty = (
  valueToCheck: number | bigint | string | null | undefined
) => {
  return R.isNil(valueToCheck) || R.isEmpty(valueToCheck);
};

const BigValueLabel: React.FC<BigValueLabelProps> = ({
  title,
  value,
  containerStyle,
  valueStyle,
  labelStyle,
  showValueOnHover,
  suffix,
}) => {
  const stylesToUse = {
    ...defaultStyles,
    ...containerStyle,
  };

  const defaultValueStyles: React.CSSProperties = {
    fontWeight: 700,
    fontSize: '20px',
    color: isNilOrEmpty(value) ? theme.colors.graphite : theme.colors.charcoal,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minHeight: '30px',
    textOverflow: 'ellipsis',
    maxWidth: '95%',
  };

  const content = isNilOrEmpty(value)
    ? 'No Data'
    : (formatValue(value) as string);

  return (
    <div style={stylesToUse}>
      <Typography.VendorLabel style={labelStyle}>
        {title}
      </Typography.VendorLabel>

      <Tooltip
        title={
          isEllipsisActive(
            document.getElementById(`label-item-${stringToKebabCase(title)}`)
          )
            ? content
            : null
        }
      >
        <div
          id={`label-item-${stringToKebabCase(title)}`}
          style={{
            ...defaultValueStyles,
            ...valueStyle,
          }}
          title={
            showValueOnHover && !isNilOrEmpty(value)
              ? (formatValue(value) as string)
              : ''
          }
        >
          {content}
          {suffix && <Suffix>{suffix}</Suffix>}
        </div>
      </Tooltip>
    </div>
  );
};

export default BigValueLabel;
