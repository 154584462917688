import React from 'react';
import styled from '@emotion/styled';
import { colors } from '~/utils';

const Box = styled.div<{ rotate?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  transform: ${({ rotate }) => (rotate ? 'rotate(-180deg)' : '')};
  transition: all 0.2s ease-out;
`;

type ArrowDropdownProps = {
  fill?: string;
  rotate?: boolean;
};

const ArrowDropdown = ({
  fill = colors.gray800,
  rotate,
}: ArrowDropdownProps) => (
  <Box rotate={rotate}>
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28252 4.21781C4.12631 4.37402 3.87304 4.37402 3.71683 4.21781L0.51585 1.01683C0.263864 0.76484 0.442331 0.333984 0.798693 0.333984H7.20066C7.55702 0.333984 7.73548 0.764841 7.4835 1.01683L4.28252 4.21781Z"
        fill={fill}
      />
    </svg>
  </Box>
);

export default ArrowDropdown;
