import React from 'react';
import styled from '@emotion/styled';

import { theme } from '~/utils';

import SeeMoreBuyBetter from '~/components/PageBanner/SeeMoreBuyBetter';

export interface BannerProps {
  type: string | null | undefined;
}

const StyledBanner = styled.div`
  background-color: ${theme.colors.primaryDark500};
  margin: 0 auto;
  margin-top: -1rem;
  width: 100%;
`;

export const StyledBannerContainer = styled.div`
  width: 100%;
  max-width: ${theme.dimensions.width.max}px;
  padding: 0 40px;
  margin: 0 auto;
`;

export const BANNER_TYPES = {
  SEE_MORE_BUY_BETTER: 'seeMoreBuyBetter',
};

const banners = {
  [`${BANNER_TYPES.SEE_MORE_BUY_BETTER}`]: <SeeMoreBuyBetter />,
};
const PageBanner: React.FC<BannerProps> = ({ type }) => {
  if (!type) {
    return null;
  }
  const selectedBanner = banners[type];
  if (!selectedBanner) {
    return null;
  }

  return (
    <StyledBanner>
      <StyledBannerContainer>{selectedBanner}</StyledBannerContainer>
    </StyledBanner>
  );
};

export default PageBanner;
