import React from 'react';
import styled from '@emotion/styled';
import { Tooltip as TooltipAnt } from 'antd';
import { TooltipProps as TooltipAntProps } from 'antd/lib/tooltip/index';

export type TooltipProps = TooltipAntProps;

const StyledTooltip = styled(TooltipAnt)<TooltipAntProps>``;

const Tooltip: React.FC<TooltipProps> = (props) => <StyledTooltip {...props} />;

export default Tooltip;
