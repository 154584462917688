import React from 'react';

import { REGION } from '~/utils/constants';
import ProjectsMap from '~/UI/Metrics/ProjectsMap';

type RegionTypes =
  | REGION.NORTHEAST
  | REGION.WEST
  | REGION.SOUTH
  | REGION.MIDWEST;

export type RegionDataItemType = {
  count: number;
  id: RegionTypes | string;
  label: string;
  value: number;
  lat?: number;
  lng?: number;
};

export type RegionalData = Array<RegionDataItemType>;

export interface GoogleMapsProps {
  regionalData: RegionalData;
}

const GeoMapUnitedStates: React.FC<GoogleMapsProps> = ({ regionalData }) => {
  return (
    <>
      <ProjectsMap regionalData={regionalData} />
    </>
  );
};

export default GeoMapUnitedStates;
