import { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { api, apiV2 } from '../../api/axios';
import { routes } from '../../api/routes';
import { UserType } from '../../types/users';
import { catchError } from '../../utils';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const isUnauthorized = ({ response }: AxiosError) =>
  response?.status === 401 && response?.statusText === 'Unauthorized';

export const auth = async (
  accessToken: string
): Promise<AxiosResponse<UserType>> => {
  axiosRetry(api, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => !isUnauthorized(error),
  });

  try {
    return await apiV2.post(
      routes.auth.auth(),
      {},
      { ...config, headers: { Authorization: `Bearer ${accessToken}` } }
    );
  } catch (error) {
    catchError({
      error,
      method: 'login',
      location: 'services/api/auth',
    });
    throw error;
  }
};
