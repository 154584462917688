import React from 'react';

import { rtkService } from '~/types/rtkApi';

import { useGetAllDCBlocksQuery } from '~/store/api';
import AdminModuleRouter from '../components/Admin/router/AdminModuleRouter';
import { ADMIN_ROUTES } from '../router/AdminRoutes';
import AdminDcBlocksForm from '../components/Admin/DcBlocks/AdminDcBlocksForm';
import AdminDcBlocksList from '../components/Admin/DcBlocks/AdminDcBlocksList';

const AdminDcBlocks: React.FC = () => {
  const service = {
    getAll: useGetAllDCBlocksQuery,
  } as unknown as rtkService;

  return (
    <AdminModuleRouter
      name="AC / DC Blocks"
      listComponent={AdminDcBlocksList}
      formComponent={AdminDcBlocksForm}
      path={ADMIN_ROUTES.DC_BLOCKS.path}
      service={service}
    />
  );
};

export default AdminDcBlocks;
