import React from 'react';
import { useForm } from 'antd/es/form/Form';
import styled from '@emotion/styled';
import {
  ButtonAnt,
  Divider,
  Form,
  FormItemLabel,
  InputNumber,
  Popover,
} from '~/UI';
import PSP_INPUT_CONFIG from '~/constants/inputs/project/projectSpecificPricing';
import { SmallLabel } from '~/UI/Typography';
import { IconInfo } from '~/UI/Icons';
import { theme } from '~/utils';
import {
  useAddProjectSpecificPricingMutation,
  useEditProjectSpecificPricingMutation,
} from '~/store/api/admin/projectSpecificPricingApi';
import { ModuleData } from './ModuleCard';
import { ProjectSpecificPricing } from '~/store/project';

export type ModuleProjectSpecificPricingFormValues = {
  ddp_east_USD_per_W: number;
};

type ModuleProjectSpecificPricingFormProps = {
  onSave: () => void;
  currentQuarter: string;
  moduleData: ModuleData;
  projectUuid: string;
  projectSpecificPricing?: ProjectSpecificPricing;
};

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-top: 20px;
`;

const ModulePSPForm = ({
  currentQuarter,
  onSave,
  moduleData,
  projectUuid,
  projectSpecificPricing,
}: ModuleProjectSpecificPricingFormProps) => {
  const [form] = useForm<ModuleProjectSpecificPricingFormValues>();
  const [addProjectSpecificPricing, { isLoading: isCreatingCustomPrice }] =
    useAddProjectSpecificPricingMutation();
  const [editProjectSpecificPricing] = useEditProjectSpecificPricingMutation();

  const currentSelectedQuarter = Number(currentQuarter.split('-')[1]);
  const currentSelectedYear = Number(currentQuarter.split('-')[0]);
  const quarterYearDisplay = `Q${currentSelectedQuarter} ${currentSelectedYear}`;

  const onSubmit = (values: ModuleProjectSpecificPricingFormValues) => {
    addProjectSpecificPricing({
      quarter: quarterYearDisplay,
      ddp_east_USD_per_W: values.ddp_east_USD_per_W,
      port_adder_USD_per_W: 0,
      shipping_adder_per_W: 0,
      projectUuid,
      module_uuid: moduleData.moduleId,
      module_customer_price_USD_per_W:
        moduleData.module_customer_price_USD_per_W,
    })
      .unwrap()
      .then(() => {
        onSave();
      });
  };

  const onReset = () => {
    if (projectSpecificPricing) {
      editProjectSpecificPricing({
        projectUuid,
        uuid: projectSpecificPricing.uuid,
        quarter: projectSpecificPricing.quarter,
        active: false,
        module_customer_price_USD_per_W:
          moduleData.module_customer_price_USD_per_W,
      });
    }
  };

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        name="ddp_east_USD_per_W"
        label={
          <FormItemLabel>
            Custom Purchase Price*
            <Popover
              zIndex={1500}
              title="Custom Purchase Price"
              content={
                <span style={{ maxWidth: 350 }}>
                  This price represents the full cost to deliver a module to
                  site, including any purchase price, shipping costs, tariffs,
                  and/or adders. Sales tax excluded.
                </span>
              }
            >
              <IconInfo color={theme.colors.gray500} size={15} />
            </Popover>
          </FormItemLabel>
        }
        labelCol={{ span: 24 }}
        rules={PSP_INPUT_CONFIG.ddp_east_USD_per_W.rules}
      >
        <InputNumber
          data-testid="ddp_east_USD_per_W"
          {...PSP_INPUT_CONFIG.ddp_east_USD_per_W.inputNumberProps}
        />
      </Form.Item>
      <Divider />
      <Footer>
        <SmallLabel
          style={{
            marginRight: 'auto',
          }}
        >{`* Effective for ${quarterYearDisplay}`}</SmallLabel>
        <ButtonAnt
          disabled={!projectSpecificPricing}
          size="small"
          onClick={onReset}
        >
          Reset
        </ButtonAnt>
        <ButtonAnt
          size="small"
          type="primary"
          htmlType="submit"
          loading={isCreatingCustomPrice}
          disabled={isCreatingCustomPrice}
        >
          Save
        </ButtonAnt>
      </Footer>
    </Form>
  );
};

export default ModulePSPForm;
