import React from 'react';
import styled from '@emotion/styled';
import Popover from '~/UI/Popover';
import { theme } from '~/utils';
import { infoFilled } from '~/assets';

const PopoverContent = styled.div`
  width: 396px;
`;

export const generateTitlePopover = (
  title: string,
  content: string | React.ReactNode,
  titleContainerStyle?: React.CSSProperties,
  wrapWhiteSpace?: boolean,
  whiteSpace?: React.CSSProperties['whiteSpace']
) => (
  <>
    <Popover
      title={title}
      content={<PopoverContent>{content}</PopoverContent>}
      placement="bottomLeft"
      trigger="hover"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          ...(titleContainerStyle || {}),
        }}
      >
        <div
          style={{
            cursor: 'default',
            whiteSpace: whiteSpace ?? (wrapWhiteSpace ? 'normal' : 'nowrap'),
          }}
        >
          {title}
          <sup
            style={{
              left: 1,
              fontSize: 9,
              color: theme.colors.primary,
            }}
          >
            <img src={infoFilled} alt={title} />
          </sup>
        </div>
      </div>
    </Popover>
  </>
);
