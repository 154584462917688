import React, {
  type AnchorHTMLAttributes,
  type DetailedHTMLProps,
} from 'react';

export type ExternalLinkProps = Exclude<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'target' | 'rel'
>;

const ExternalLink = (props: ExternalLinkProps) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export default ExternalLink;
