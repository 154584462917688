import React from 'react';
import confirm from 'antd/lib/modal/confirm';
import type { ModalFuncProps } from 'antd';
import { UnsavedChangesText } from '~/UI/Typography';

const confirmModal = ({ title, onOk }: ModalFuncProps) => {
  confirm({
    width: '650px',
    centered: true,
    type: 'warning',
    okText: 'Discard',
    cancelButtonProps: {
      size: 'large',
      style: {
        width: '190px',
      },
    },
    okButtonProps: {
      size: 'large',
      style: {
        width: '190px',
      },
    },
    title: <UnsavedChangesText>{title}</UnsavedChangesText>,
    onOk,
  });
};

export default confirmModal;
