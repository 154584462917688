import * as R from 'ramda';
import React, { useState } from 'react';

import {
  booleanColumnSorter,
  columnArrayStringSorter,
  columnSorter,
} from '~/utils';
import { Overlay, Spinner, Switch } from '~/UI';
import { getArrayAsTags } from '~/utils/cellRenderers';
import useAuth from '~/hooks/useAuth';
import { ADMIN_ROUTES } from '~/router/AdminRoutes';
import { H2 } from '~/UI/Typography';
import { AdminListPageProps } from '~/types/admin';
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
} from '~/store/api';

import AdminTable, { Column } from '../AdminTable/AdminTable';

const AdminUsersList: React.FC<AdminListPageProps> = ({
  service,
  pageName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { user } = useAuth();
  const [activateUser] = useActivateUserMutation();
  const [deactivateUser] = useDeactivateUserMutation();

  const onChange = async (id: number, is_deactivated: boolean) => {
    setIsLoading(true);
    if (is_deactivated) {
      await deactivateUser({ id });
    } else {
      await activateUser({ id });
    }
    setIsLoading(false);
    setRefresh(refresh + 1);
  };

  const columns: Column[] = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      isSortable: true,
      isFilterable: true,
      fixed: 'left',
      width: '350px',
      defaultSortOrder: 'ascend',
      sorter: columnSorter(['email']),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '150px',
      isSortable: true,
      isFilterable: true,
      sorter: columnSorter(['first_name']),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '150px',
      isSortable: true,
      isFilterable: true,
      sorter: columnSorter(['last_name']),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      width: '200px',
      isSortable: true,
      isFilterable: true,
      render: R.propOr('', 'domain'),
      sorter: columnSorter(['watts']),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '200px',
      isSortable: true,
      isFilterable: true,
      render: R.propOr('', 'name'),
      sorter: columnSorter(['company']),
    },
    {
      title: 'Is active',
      dataIndex: '',
      key: 'id',
      width: '100px',
      isSortable: false,
      isFilterable: false,
      sorter: booleanColumnSorter(['is_deactivated']),
      fixed: 'right',
      render: ({
        id,
        is_deactivated,
        email,
      }: {
        id: number;
        is_deactivated: boolean;
        email: string;
      }) => (
        <Switch
          disabled={user.email === email}
          checked={!is_deactivated}
          onChange={() => onChange(id, is_deactivated)}
        />
      ),
    },
  ];

  return (
    <div>
      {isLoading && (
        <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
          <Spinner />
        </Overlay>
      )}
      <H2>{pageName}</H2>
      <AdminTable
        refresh={refresh}
        service={service}
        columns={columns}
        customActions={{
          edit: {
            onHref: (user) =>
              ADMIN_ROUTES.USERS.path.edit(user.id?.toString() || ''),
          },
        }}
      />
    </div>
  );
};

export default AdminUsersList;
