import React from 'react';

import { anzaLogoHeader, linkedinLogo, twitterLogo } from '~/assets';
import ExternalLink from '~/components/ExternalLink';

import {
  CopyrightBox,
  FooterBox,
  FooterContent,
  InfoBox,
  SocialNetworksBox,
  StyledLink,
  UsefulLinksBox,
} from './styles';

const Footer = (): JSX.Element => (
  <FooterBox>
    <FooterContent>
      <InfoBox>
        <img src={anzaLogoHeader} alt="Anza logo. See more. Buy Better" />
        <CopyrightBox>
          {`© ${new Date().getFullYear()} Anza Renewables, LLC`}
        </CopyrightBox>
        <UsefulLinksBox>
          <StyledLink href="https://www.anzarenewables.com/buyers">
            For Buyers
          </StyledLink>
          <StyledLink href="https://www.anzarenewables.com/manufacturers">
            For Manufacturers
          </StyledLink>
        </UsefulLinksBox>
      </InfoBox>
      <SocialNetworksBox>
        <ExternalLink href="https://twitter.com/anzarenewables">
          <img src={twitterLogo} alt="Twitter" />
        </ExternalLink>
        <ExternalLink href="https://www.linkedin.com/company/anza-renewables">
          <img src={linkedinLogo} alt="LinkedIn" />
        </ExternalLink>
      </SocialNetworksBox>
    </FooterContent>
  </FooterBox>
);

export default Footer;
