import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '~/UI';
import { stringToAnchor } from '~/utils';

interface FormGroupProps {
  title?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const Container = styled.div`
  margin-bottom: 22px;
  width: 100%;
  max-width: 560px;
`;

const FormGroup: React.FC<FormGroupProps> = ({
  title,
  children,
  style,
  contentStyle,
}) => {
  return (
    <Container style={style}>
      {title && (
        <Typography.FormGroupHeading
          id={stringToAnchor(title).substring(1)}
          style={{ marginBottom: '10px' }}
        >
          {title}
        </Typography.FormGroupHeading>
      )}

      <div style={contentStyle}>{children}</div>
    </Container>
  );
};

FormGroup.defaultProps = {
  style: undefined,
  contentStyle: undefined,
};

export default FormGroup;
