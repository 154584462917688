import { RcFile } from 'antd/lib/upload';

/**
 * validateFileSize returns true if file size is valid and under
 * the allowed file size parameter in MBs, false otherwise.
 *
 * @param file
 * @param allowedFileSizeInMBs
 * @returns
 */
export const validateFileSize = (file: RcFile, allowedFileSizeInMBs: number) =>
  file.size / 1024 / 1024 < allowedFileSizeInMBs;

export const formatAllowedFileExtensions = (extensions: Array<string>) => {
  const clonedExtensions = [...extensions];
  const x = clonedExtensions.pop();
  return clonedExtensions.length
    ? `.${clonedExtensions.join(', .')} or .${x}`
    : `.${x}` || '';
};

export const getFailedUploadErrorMessage = (
  allowedFileSize: number,
  allowedExtensions: Array<string>
) =>
  `Error uploading the file. Confirm that the file extension is ${formatAllowedFileExtensions(
    allowedExtensions
  )} and its size is less than ${allowedFileSize}MB.`;

export const getFileExtension = (file: RcFile) => {
  const splitFileName = file.name.toLowerCase().split('.');
  return splitFileName[splitFileName.length - 1];
};
