import React from 'react';
import styled from '@emotion/styled';

import { Spinner } from '~/UI';

const Overlay = styled.div<{ background?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) =>
    props.background || 'rgba(255, 255, 255, 0.75)'};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

export const OverlayLoader: React.FC = () => {
  return (
    <Overlay style={{ position: 'fixed', height: '100vh', zIndex: 9999 }}>
      <Spinner />
    </Overlay>
  );
};

export default Overlay;
