import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import dayjs from 'dayjs';
import { FormInstance } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

import { UploadFile } from 'antd/lib/upload/interface';
import { RcFile } from 'rc-upload/lib/interface';
import {
  Form,
  ModalAnt as Modal,
  InputAnt as Input,
  InputMultine,
  Option,
  Typography,
  FormItemLabel,
  FormGroup,
  notification,
} from '~/UI';
import { Spinner } from '~/UI/index';
import InputFileFormItem from '~/UI/InputFileFormItem';
import { theme, catchError, fileTypes } from '~/utils';
import { getUploadUrl, uploadFile } from '~/services/api/files';
import { ModuleFile } from '~/types/file';
import {
  type UseGetSearchFilesQuery,
  useGetSearchFilesQuery as useDefaultGetSearchFilesQuery,
} from '~/store/api/admin/searchFilesApi';
import Autocomplete from '~/UI/Autocomplete';
import { getReadableFileSizeString } from '~/UI/FileGroup';
import { moduleFileCategories } from '~/utils/constants';
import { SelectFormItem } from '../Select';

const { Option: AutocompleteOption } = Autocomplete;

const requiredRule = {
  required: true,
  message: 'Please input a value for this field',
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-body {
      padding: 16px 24px;
    }
    .ant-modal-footer {
      padding-top: 0;
    }
  }
`;

const StyledModalBody = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  padding: 0 7px 0 4px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  .ant-select-selector {
    .ant-select-selection-search {
      position: relative !important;
      width: inherit !important;
      left: 0 !important;
    }
    .ant-select-selection-placeholder {
      position: absolute;
      width: 100%;
      color: ${theme.colors.stone};
    }
  }
`;

const globalStyle = css`
  .ant-select-item.ant-select-item-option.files-autocomplete-loading {
    .ant-select-item-option-content {
      display: flex;
      justify-content: center;
    }
  }
  .ant-select-item.ant-select-item-option-disabled.files-autocomplete-no-results {
    .ant-select-item-option-content {
      color: ${theme.colors.graphite};
    }
  }
  .ant-modal-body .input-placeholder {
    max-width: 482px;
  }
`;

const NewFile = styled.p`
  font-size: 16px;
  color: ${theme.colors.graphite};
  font-weight: 600;
`;

const Name = styled.p`
  font-size: 16px;
  color: ${theme.colors.charcoal};
  font-weight: 600;
`;

const Description = styled.span`
  display: block;
  font-size: 16px;
  color: ${theme.colors.stone};
  font-weight: 600;
`;

type FileData = {
  uuid: string;
  file_title?: string;
  file_name?: string;
  updated_at?: string;
  file_size_kb?: number;
};

const FileOptionLabel = styled(
  ({ className, data }: { className?: string; data: FileData }) => (
    <div className={className}>
      <Name>{data?.file_title}</Name>
      <Description>{data?.file_name}</Description>
      <Description>
        {data?.updated_at ? dayjs(data.updated_at).format('MMM YYYY') : ''}
        {getReadableFileSizeString(data?.file_size_kb)}
      </Description>
    </div>
  )
)``;

const getAutoCompleteOptions = (loading: boolean, filesList: FileData[]) => {
  if (loading) {
    return (
      <AutocompleteOption
        key="Spinner"
        value="-1"
        className="files-autocomplete-loading"
      >
        <Spinner />
      </AutocompleteOption>
    );
  }

  return filesList.map((item) => (
    <AutocompleteOption key={item.uuid} value={item.file_title}>
      <FileOptionLabel data={item} />
    </AutocompleteOption>
  ));
};

export interface FileModalProps {
  title?: string;
  onClose?: () => void;
  onOk: (value: ModuleFile) => void;
  okText?: string;
  visible: boolean;
  acceptedFileType?: Array<string>;
  fileType?: string;
  uuid?: string;
  file_title?: string;
  category?: string;
  file_name?: string;
  content_type?: string;
  file_size_kb?: number;
  file_location_path?: string;
  notes?: string;
  searchFileTypes?: Array<string>;
  associationId?: string;
  newlyAddedId?: number;
  confirmLoading: boolean;
  onUploadCallback?: (file: UploadFile, form: FormInstance) => void;
  useCustomGetSearchFilesQuery?: UseGetSearchFilesQuery;
}

const FileModal: React.FC<FileModalProps> = ({
  okText,
  onClose,
  onOk,
  title,
  visible,
  acceptedFileType,
  fileType,
  uuid,
  file_title,
  category,
  file_name,
  content_type,
  file_size_kb,
  file_location_path,
  notes,
  searchFileTypes,
  associationId,
  newlyAddedId,
  confirmLoading,
  onUploadCallback,
  useCustomGetSearchFilesQuery,
}) => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [file, setFile] = useState<UploadFile>();
  const [searchFileUuid, setSearchFileUuid] = React.useState<string>();
  const [searchFileName, setSearchFileName] = React.useState<string>();
  const [form] = Form.useForm();
  const [isNewFile, setIsNewFile] = React.useState<boolean>(false);

  const inputFileFormValidator = ({ isRequired = true }) => {
    return {
      validator: () => {
        if (!isRequired && (typeof file === undefined || file == null)) {
          return Promise.resolve();
        }

        if (!file) {
          return Promise.reject(new Error(`Please enter a file`));
        }

        return Promise.resolve();
      },
    };
  };

  const useGetSearchFilesQuery =
    useCustomGetSearchFilesQuery ?? useDefaultGetSearchFilesQuery;
  const {
    data: filesData,
    isFetching,
    isLoading,
  } = useGetSearchFilesQuery(
    `query=${searchValue}&file_type=${
      searchFileTypes ? searchFileTypes?.join(',') : fileType
    }`
  );

  const files: ModuleFile[] = (filesData as ModuleFile[]) || [];

  const handleSearchValueChange = (searchString: string) =>
    setSearchValue(searchString);

  const switchToAdd = () => {
    form.setFieldsValue({
      file_location_path: null,
      file_size_kb: null,
      content_type: undefined,
      file_name: null,
      updated_at: null,
      file_title: null,
      category: null,
      notes: null,
    });
    setSearchFileUuid(undefined);
    setSearchFileName('No file selected');
    setIsNewFile(true);
  };

  const handleFileSelect = async (
    _: any,
    option: BaseOptionType | DefaultOptionType
  ) => {
    const { key } = option || {};
    if (key === 'add') {
      switchToAdd();
      return;
    }

    const selectedFile = files.find((element) => element?.uuid === key);
    if (selectedFile) {
      form.setFieldsValue({
        file_name: selectedFile.file_name,
        file_size_kb: selectedFile.file_size_kb,
        content_type: selectedFile.content_type,
        file_location_path: selectedFile.file_location_path,
        updated_at: selectedFile.updated_at,
        file_title: selectedFile.file_title,
        category: selectedFile.category,
        notes: selectedFile.notes,
      });
      setSearchFileName(selectedFile.file_name);
      setSearchFileUuid(key);

      setIsNewFile(false);
    }
  };

  const checkInvalid = async () => {
    return form.validateFields().catch(() => Promise.reject());
  };

  const uploadCallback = (uploadedFile?: UploadFile) => {
    if (!uploadedFile) {
      form.setFieldsValue({ file_location_path: null });
      form.setFieldsValue({ file_size_kb: null });
      form.setFieldsValue({ content_type: undefined });
      form.setFieldsValue({ file_name: null });
      form.setFieldsValue({ updated_at: null });
    } else {
      if (onUploadCallback) onUploadCallback(uploadedFile, form);
      setSearchFileName(undefined);
    }
    setFile(uploadedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      return;
    }
    try {
      const {
        data: { url, fileName: resFileName },
      } = await getUploadUrl(file!.name);

      await uploadFile(url, file as RcFile);

      form.setFieldsValue({
        file_location_path: resFileName,
        file_size_kb: file!.size ? Math.round(file!.size / 1024) : null,
        content_type: file!.type ? file!.type : undefined,
        file_name: file!.name ? file!.name : null,
      });
    } catch (error) {
      catchError({
        location: 'UI/Modal/FileModal',
        method: 'handleFileUpload',
        error,
      });
      notification.error({
        message: 'There was an error uploading this file',
        description: 'Please check the file or try again later.',
      });
      throw error;
    }
  };

  const dataFetchOccuring = isLoading || isFetching;

  const isFileRequired =
    (!uuid && !searchFileUuid && !newlyAddedId) || isNewFile;

  const getNewFileValue = (
    isNewFile: boolean,
    value: string | number | null | undefined
  ) => (!isNewFile ? value : undefined);

  const [isHandlingSave, setIsHandlingSave] = useState(false);
  const handleSave = async () => {
    setIsHandlingSave(true);
    checkInvalid()
      .then(async () => {
        await handleFileUpload();
        const formValues = form.getFieldsValue(true);
        const newFileAdded = !!file;
        const getUndefinedIfNewFile = (
          value: string | number | null | undefined
        ) => getNewFileValue(newFileAdded, value);
        onOk({
          file_name,
          content_type,
          file_location_path,
          file_size_kb,
          associationId: getUndefinedIfNewFile(associationId),
          newlyAddedId: getUndefinedIfNewFile(newlyAddedId),
          ...formValues,
          file_type: fileType,
          uuid: getUndefinedIfNewFile(uuid),
          uuidExisting: uuid,
          searchFileUuid: getUndefinedIfNewFile(searchFileUuid),
          isNewFile: newFileAdded,
        });
      })
      .catch(() => {
        // do nothing
      })
      .finally(() => setIsHandlingSave(false));
  };
  return (
    <>
      <Global styles={globalStyle} />
      <StyledModal
        open={visible}
        onCancel={onClose}
        title={
          <Typography.FormGroupHeading>{title}</Typography.FormGroupHeading>
        }
        onOk={async () => {
          await handleSave();
        }}
        okText={okText}
        width="750px"
        confirmLoading={confirmLoading || isHandlingSave}
        maskClosable={!confirmLoading}
      >
        <StyledModalBody>
          <StyledAutocomplete
            style={{ width: '100%' }}
            placeholder="Search file by name or titile..."
            dropdownMatchSelectWidth={false}
            onSearch={handleSearchValueChange}
            onSelect={handleFileSelect}
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={confirmLoading || isHandlingSave}
          >
            <AutocompleteOption key="add" value="">
              <NewFile>New File</NewFile>
            </AutocompleteOption>
            {getAutoCompleteOptions(
              dataFetchOccuring,
              files as unknown as FileData[]
            )}
          </StyledAutocomplete>
          OR
          <Form
            form={form}
            style={{ width: '100%' }}
            onFinishFailed={checkInvalid}
            disabled={confirmLoading || isHandlingSave}
          >
            <FormGroup
              style={{
                maxWidth: '100%',
                overflowX: 'scroll',
              }}
            >
              <div style={{ minHeight: '100px', marginTop: '-15px' }}>
                <InputFileFormItem
                  name="file_location_path"
                  label="Upload file"
                  onFinishCallback={uploadCallback}
                  acceptedFileType={acceptedFileType}
                  form={form}
                  style={{ width: '50%' }}
                  allowedFileSizeInMBs={1024}
                  noFileSelectedText={searchFileName || file_name}
                  rules={[
                    {
                      required: isFileRequired,
                      validator: inputFileFormValidator({
                        isRequired: isFileRequired,
                      }).validator,
                    },
                  ]}
                  validateTrigger="onBlur"
                />
              </div>
              <Form.Item
                name="file_title"
                label={<FormItemLabel>File title</FormItemLabel>}
                labelCol={{ span: 24 }}
                initialValue={file_title}
                rules={[requiredRule]}
              >
                <Input required placeholder="File title" />
              </Form.Item>
              {fileType === fileTypes.MODULE_DATASHEET.type ? (
                <Form.Item name="category" initialValue="DATASHEET">
                  <Input type="hidden" />
                </Form.Item>
              ) : (
                <SelectFormItem
                  name="category"
                  initialValue={category}
                  label={<FormItemLabel>File Category</FormItemLabel>}
                  labelCol={{ span: 24 }}
                  rules={[requiredRule]}
                  selectProps={{
                    placeholder: 'Select a file category',
                  }}
                >
                  {Object.entries(moduleFileCategories).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </SelectFormItem>
              )}
              <Form.Item
                name="notes"
                label={<FormItemLabel>Notes</FormItemLabel>}
                labelCol={{ span: 24 }}
                initialValue={notes}
              >
                <InputMultine
                  placeholder="Add notes here..."
                  rows={4}
                  style={{ fontSize: '14px' }}
                />
              </Form.Item>
            </FormGroup>
          </Form>
        </StyledModalBody>
      </StyledModal>
    </>
  );
};

export default FileModal;
