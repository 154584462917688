import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ROUTER_PATHS } from '~/router/Paths';

import { useLazyGetProjectByDetailUuidQuery } from '../store/api';

/**
 * A hook used by the Anza app to handle the redirection of old routes (anza/modules, anza/storage).
 */
const useAnzaRedirectHandler = () => {
  const history = useHistory();
  const location = useLocation();
  const [trigger] = useLazyGetProjectByDetailUuidQuery();

  useEffect(() => {
    const isStorage = location.pathname.split('/')[2] === 'storage';

    // Extract the id from the URL.
    const id = location.pathname.split('/').pop();

    const projectRoutes = [
      ROUTER_PATHS.projects.ANZA_MODULES(id),
      ROUTER_PATHS.projects.ANZA_STORAGE(id),
    ];

    const match = projectRoutes.includes(location.pathname);

    if (match) {
      trigger({ id }, true)
        .then(({ data, isError }) => {
          if (isError) {
            return history.replace(ROUTER_PATHS.projects.PROJECTS());
          }

          if (data?.projectUuid) {
            if (isStorage) {
              return history.replace(
                ROUTER_PATHS.projects.storage.PROJECT_DETAILS(data.projectUuid)
              );
            }

            return history.replace(
              ROUTER_PATHS.projects.solar.PROJECT_DETAILS(data.projectUuid)
            );
          }

          return true;
        })
        .catch(() => {
          history.replace(ROUTER_PATHS.projects.PROJECTS());
        });
    }
  }, []);
};

export default useAnzaRedirectHandler;
