import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import { colors, theme } from '~/utils';

type TagSize = 'md' | 'sm';

const TagSizes: Record<TagSize, TagSize> = {
  md: 'md',
  sm: 'sm',
};

const TagDefault = styled.div<{ canBeHovered?: boolean }>`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px;
  height: fit-content;
  line-height: initial;
  border-radius: 2px;
  background-color: ${colors.infoMain}14;
  padding: 3px 2px 3px 2px;
  ${({ canBeHovered }) =>
    canBeHovered
      ? `&:hover {
    background-color: ${theme.colors.gray300};
  }`
      : ''}
`;

const TagSmall = styled(TagDefault)`
  font-size: 10px !important;
`;

const TagContent = styled.div<{ size: TagSize }>`
  color: ${theme.colors.infoMain};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  gap: ${({ size }) => (size === TagSizes.md ? '5px' : '3px')};
`;

const IconWrapped = styled.div<{ size: TagSize }>`
  width: ${({ size }) => (size === TagSizes.md ? '20px' : '11px')};
  height: ${({ size }) => (size === TagSizes.md ? '15px' : '8px')};
  display: flex;
`;

export type ModuleTagProps = {
  size: TagSize;
  icon?: JSX.Element;
  label?: string;
  description?: string;
  contentFontSize?: string;
} & HTMLAttributes<HTMLDivElement>;

const ModuleTag = ({
  size,
  icon,
  label,
  description,
  contentFontSize,
  ...rest
}: ModuleTagProps) => {
  const content = (
    <>
      <TagContent size={size}>
        {icon && <IconWrapped size={size}>{icon}</IconWrapped>}
        {label}
      </TagContent>
      {description && ` - ${description}`}
    </>
  );

  const canBeHovered = !!rest.onMouseEnter;

  if (size === TagSizes.sm)
    return (
      <TagSmall canBeHovered={canBeHovered} {...rest}>
        {content}
      </TagSmall>
    );

  return (
    <TagDefault canBeHovered={canBeHovered} {...rest}>
      {content}
    </TagDefault>
  );
};

export default ModuleTag;
