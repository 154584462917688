import * as R from 'ramda';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  SOLAR_CALCULATED_FIELDS,
  STORAGE_CALCULATED_FIELDS,
} from '~/constants/rankingTable';
import {
  EngineInputData,
  EngineOutputData,
  EngineResultModule,
  EngineResultSizingArchitecture,
  ProjectEngineData,
  SolarEngineOutputCalculatedFields,
  SolarRankingData,
  StorageEngineOutputCalculatedFields,
  StorageRanking,
} from '~/store/project';

dayjs.extend(quarterOfYear);

const sortByEffectivePrice = R.sortWith<EngineOutputData>([
  R.ascend((d) => d.module_effective_price_USD_per_W),
]);

const sortByRank = R.sortWith<EngineOutputData>([R.ascend((d) => d.rank)]);

const getModulesQuarterDataWithCalculatedValues = (
  modulesQuarterData: EngineOutputData[],
  maxNvpDeltaModule: EngineOutputData
) =>
  modulesQuarterData.map((moduleQuarterData) => {
    const calculatedValues = R.reduce(
      (accum, { calculate, field }) => ({
        ...accum,
        [field]: calculate(moduleQuarterData, maxNvpDeltaModule),
      }),
      {} as { [key in SolarEngineOutputCalculatedFields]: number },
      SOLAR_CALCULATED_FIELDS
    );

    const dataWithCalculatedFields: SolarRankingData = {
      ...moduleQuarterData,
      ...calculatedValues,
    };

    return dataWithCalculatedFields;
  });

export const parseEngineDataToTableData = (
  engineData: ProjectEngineData[],
  modules: Record<string, EngineResultModule>,
  projectArrivalDate: string
) => {
  const groupedByModuleAndQuarter = R.groupBy(
    (e) => `${e.moduleId}_${e.quarter}`,
    engineData
  );

  // Reducing array of module-quarter data to one single object (one object equals row in the table)
  const moduleQuarterCompleteData = R.mapObjIndexed((data, key) => {
    const [moduleId, quarter] = key.split('_');
    return R.reduce<ProjectEngineData, EngineOutputData>(
      (acc, e) => {
        return {
          ...acc,
          [e.field]: e.value,
        };
      },
      {
        moduleId,
        moduleName: modules[moduleId]?.name || '',
        manufacturer: modules[moduleId]?.manufacturer || '',
        wattClassKw: modules[moduleId]?.wattClassKw || 0,
        quarter,
        availability: modules[moduleId]?.availability || 0,
        tags: modules[moduleId]?.tags || [],
        logoData: modules[moduleId]?.logoData,
        quarterAvailability: modules[moduleId]?.quarterAvailability,
        analytics_data: modules[moduleId]?.analytics_data,
        cell_technology: modules[moduleId]?.cell_technology,
      } as EngineOutputData,
      data
    );
  }, groupedByModuleAndQuarter);

  const modulesByQuarter: {
    [key: string]: EngineOutputData[];
  } = {};
  Object.values(moduleQuarterCompleteData).forEach((mq) => {
    if (!modulesByQuarter[mq.quarter]) {
      modulesByQuarter[mq.quarter] = [];
    }
    modulesByQuarter[mq.quarter] = [...modulesByQuarter[mq.quarter], mq];
  });

  // ADD project quarter here, if it doesnt exist in the modules list
  const projectArrivalDateQuarterString = `${dayjs(
    projectArrivalDate
  ).year()}-${dayjs(projectArrivalDate).quarter()}`;

  if (!modulesByQuarter[projectArrivalDateQuarterString]) {
    modulesByQuarter[projectArrivalDateQuarterString] = [];
  }

  return R.mapObjIndexed((qm) => {
    const modulesQuarterData = R.values(qm).flat();

    // Sorting here by effective price to get the maxNpvDelta Module
    const sortedModulesQuarterDataByEffectivePrice = sortByEffectivePrice(
      modulesQuarterData as EngineOutputData[]
    );
    const maxNpvDeltaModule = sortedModulesQuarterDataByEffectivePrice[0];

    // Sorting here to show list of modules by 'rank' provided by the engine in the PDP UI
    const sortedModulesQuarterData = sortByRank(
      modulesQuarterData as EngineOutputData[]
    );

    return getModulesQuarterDataWithCalculatedValues(
      sortedModulesQuarterData,
      maxNpvDeltaModule
    );
  }, modulesByQuarter);
};

export const getStorageRankingWithCalculatedValues = (
  modules: EngineResultSizingArchitecture[],
  engineInputData: EngineInputData,
  discountRatePercent: number
) => {
  return modules.map((module) => {
    const calculatedValues = R.reduce(
      (accum, { calculate, field }) => ({
        ...accum,
        [field]: calculate(module, modules),
      }),
      {} as { [key in StorageEngineOutputCalculatedFields]: number },
      STORAGE_CALCULATED_FIELDS
    );

    const dataWithCalculatedFields: StorageRanking = {
      ...module,
      ...calculatedValues,
      engineInputData,
      discountRatePercent,
    };

    return dataWithCalculatedFields;
  });
};
