import React, { useCallback } from 'react';
import {
  GTM_EVENTS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';
import type { ExternalLinkProps } from '~/components/ExternalLink';

type ExternalLinkWithAnalyticsProps = ExternalLinkProps & {
  gtmId: string;
};

const ExternalLinkWithAnalytics = ({
  gtmId,
  onClick,
  onKeyDown,
  children,
  ...props
}: ExternalLinkWithAnalyticsProps) => {
  const onClickWithAnalytics = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      maybeSendPlatformEventToGTM(GTM_EVENTS.CLICK, gtmId);
      onClick?.(event);
    },
    [gtmId, onClick]
  );
  const onKeyDownWithAnalytics = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      maybeSendPlatformEventToGTM(GTM_EVENTS.CLICK, gtmId);
      onKeyDown?.(event);
    },
    [gtmId, onClick]
  );
  return (
    <a
      {...props}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClickWithAnalytics}
      onKeyDown={onKeyDownWithAnalytics}
      role="link"
      tabIndex={0}
      data-gtm-id={gtmId}
      data-gtm-type={GTM_SOURCE_TYPES.LINK}
    >
      {children}
    </a>
  );
};

export default ExternalLinkWithAnalytics;
