import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  AddressSearch,
  Form,
  InputAnt as Input,
  Switch,
  Typography,
  FormInstance,
  FormItemLabel,
} from '~/UI';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';
import { theme, isUserAdmin } from '~/utils';
import { generateTestId, TEST_DATA_COMPONENTS } from '~/utils/dataTestProps';
import { SearchProjectState, storeProjectType } from '~/store/searchProject';
import { RootState } from '~/store';
import useAuth from '~/hooks/useAuth';
import { ProjectLocationLabel } from '~/components/Projects/FormGroup';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';

import SolarFormGroup from './SolarFormGroup';
import StorageFormGroup from './StorageFormGroup';

interface CreateProjectFormContentProps {
  form: FormInstance;
  isEditing?: boolean;
  setIsFormFieldLoading?: (isLoading: boolean) => void | undefined;
}

const CreateProjectFormContent: React.FC<CreateProjectFormContentProps> = ({
  form,
  isEditing,
  setIsFormFieldLoading,
}) => {
  const { user } = useAuth();
  const searchProjectState = useSelector<RootState, SearchProjectState>(
    (state) => state.searchProject
  );

  const currentType = useSelector<RootState, string | null>(
    (state) => state.searchProject.type
  );
  const dispatch = useDispatch();
  const typeValue = Form.useWatch<'solar' | 'storage'>('project_type', form);

  React.useEffect(() => {
    if (typeValue && typeValue !== currentType) {
      dispatch(storeProjectType({ type: typeValue }));
    }
  }, [typeValue, currentType]);

  const [locationError, setLocationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (setIsFormFieldLoading) {
      setIsFormFieldLoading(isLoading);
    }
  }, [isLoading]);
  const handleSelectLocation = (location: AddressSearchResponseType) => {
    setLocationError('');
    if (location?.error?.message) {
      setLocationError(location?.error?.message);
      form.setFieldsValue({
        latitude: null,
        longitude: null,
        google_place_id: null,
        state: null,
      });
      form.validateFields(['project_address']);
      return;
    }
    form.setFieldsValue({
      project_address: location.address,
      latitude: location.lat,
      longitude: location.lng,
      google_place_id: location.placeId || '',
      state: location.state,
    });
    form.validateFields(['project_address']);
  };
  return (
    <Form
      form={form}
      initialValues={
        searchProjectState && isEditing
          ? {
              ...searchProjectState,
              arrival_date: searchProjectState.arrival_date
                ? dayjs(searchProjectState.arrival_date)
                : null,
              storage_arrival_date: searchProjectState
                ? dayjs(searchProjectState.storage_arrival_date)
                : null,
              anticipated_purchase_date:
                searchProjectState.anticipated_purchase_date
                  ? dayjs(searchProjectState.anticipated_purchase_date)
                  : null,
            }
          : {
              project_type: 'solar',
              target_ac_power_mw: 100,
              duration_hours: 4,
              power_factor: 0.95,
              annual_cycle_count: 365,
              capacity_maintenance_term: 10,
              project_topology: 'Standalone',
              metering_location: 'AC Output of Inverter',
              capacity_maintenance_strategy: 'Optimal',
            }
      }
      layout="vertical"
    >
      <div>
        <Form.Item
          name="name"
          label={
            <Typography.GenericText
              color={theme.colors.charcoal}
              size="12px"
              weight={700}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.LABEL}`,
                identifier: 'project-name',
              })}
            >
              Project Name
            </Typography.GenericText>
          }
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.name.rules}
          required={false}
        >
          <Input
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.INPUT}`,
              identifier: 'project-name',
            })}
            {...PROJECT_INPUT_CONFIG.name.inputProps}
          />
        </Form.Item>
        <Form.Item
          name="project_address"
          label={
            <ProjectLocationLabel
              testIdData={{ component: TEST_DATA_COMPONENTS.NEW_PROJECT_FORM }}
            />
          }
          rules={PROJECT_INPUT_CONFIG.projectAddress.applyRules(
            isLoading,
            form,
            locationError
          )}
          required={false}
        >
          <AddressSearch
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.AUTOCOMPLETE}`,
              identifier: 'address-search',
            })}
            onSelectLocation={handleSelectLocation}
            isLocationLoading={setIsLoading}
            onValueChange={(value) => {
              if (value) {
                form.setFieldsValue({
                  latitude: null,
                  longitude: null,
                  google_place_id: null,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item
          hidden={isEditing}
          name="project_type"
          rules={PROJECT_INPUT_CONFIG.projectType.rules}
          label={
            <Typography.GenericText
              color={theme.colors.charcoal}
              size="12px"
              weight={700}
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.LABEL}`,
                identifier: 'project-type',
              })}
            >
              Type of Project
            </Typography.GenericText>
          }
          required={false}
        >
          <Radio.Group
            data-testid={generateTestId({
              component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.RADIO_GROUP}`,
              identifier: 'project-type',
            })}
          >
            <Radio
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.RADIO_BUTTON}`,
                identifier: 'solar',
              })}
              value="solar"
            >
              Solar
            </Radio>
            <Radio
              data-testid={generateTestId({
                component: `${TEST_DATA_COMPONENTS.NEW_PROJECT_FORM}-${TEST_DATA_COMPONENTS.RADIO_BUTTON}`,
                identifier: 'storage',
              })}
              value="storage"
            >
              Storage
            </Radio>
          </Radio.Group>
        </Form.Item>
        {typeValue === 'solar' ? (
          <SolarFormGroup
            form={form}
            testIdData={{ component: TEST_DATA_COMPONENTS.NEW_PROJECT_FORM }}
          />
        ) : (
          <StorageFormGroup form={form} />
        )}
      </div>

      <Form.Item
        name="state"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="latitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="longitude"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="google_place_id"
        className="no-padding no-border"
        labelCol={{ span: 24 }}
        hidden
      >
        <Input />
      </Form.Item>

      {isUserAdmin(user) ? (
        <Form.Item
          name="is_test"
          label={<FormItemLabel>Is Test</FormItemLabel>}
          labelCol={{ span: 24 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default CreateProjectFormContent;
