import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { logoAnzaWhite } from '~/assets';

import {
  HeaderContainer,
  LogoContainer,
  NavContainer,
  RightMenu,
  SecondaryNav,
  SecondaryTabsContainer,
  StyledMenu,
} from './styles';

export type MenuItem = {
  title: string;
  action?: () => void;
};

export type TabItem = {
  title: string;
  path: string;
};

export interface HeaderProps {
  logoRedirect?: string;
  logoPath?: string;
  logoAlt?: string;
  tabItems?: Array<TabItem>;
  showInfo?: boolean;
  showMenu?: boolean;
  menuContent?: Array<React.ReactNode>;
  showScheduleCall?: boolean;
  mainMenuItems?: ReactNode | Array<ReactNode>;
  rightMenuItems: ReactNode | Array<ReactNode>;
  secondaryMenuItems?: ReactNode | Array<ReactNode>;
  showSecondaryMenu?: boolean;
  onSecondaryNavMouseOver?: () => void;
  onSecondaryNavMouseLeave?: () => void;
  style?: React.CSSProperties;
}

const Header: React.FC<HeaderProps> = ({
  logoAlt,
  logoRedirect,
  logoPath = logoAnzaWhite,
  mainMenuItems,
  rightMenuItems,
  secondaryMenuItems,
  showSecondaryMenu,
  onSecondaryNavMouseOver,
  onSecondaryNavMouseLeave,
  style,
}) => {
  const logo = <img src={logoPath} alt={logoAlt} />;
  return (
    <HeaderContainer style={style}>
      <NavContainer>
        <LogoContainer data-testid="anza-header-logo">
          {logoRedirect ? <Link to={logoRedirect}>{logo}</Link> : logo}
        </LogoContainer>
        <StyledMenu>{mainMenuItems}</StyledMenu>
        <RightMenu>{rightMenuItems}</RightMenu>
      </NavContainer>

      <SecondaryNav
        visible={showSecondaryMenu}
        onMouseOver={() => onSecondaryNavMouseOver && onSecondaryNavMouseOver()}
        onMouseLeave={() =>
          onSecondaryNavMouseLeave && onSecondaryNavMouseLeave()
        }
      >
        {showSecondaryMenu && (
          <SecondaryTabsContainer>{secondaryMenuItems}</SecondaryTabsContainer>
        )}
      </SecondaryNav>
    </HeaderContainer>
  );
};

export default Header;
