import React from 'react';
import styled from '@emotion/styled';
import { theme } from '~/utils';
import { XAxisGrouping } from './ResponsiveBarChart';

interface GroupedXAxisProps {
  axis: XAxisGrouping[] | undefined;
  numberOfColumns: number;
}

const AxisGroup = styled.div`
  height: 50px;
  justify-content: center;
  align-items: end;
  display: flex;
  position: relative;
  top: -30px;
  font-size: 13px;
  color: ${theme.colors.charcoal};

  .group-label {
    text-align: center;
  }

  :not(:last-child) .group-divider::after {
    content: '';
    position: absolute;
    right: -2px;
    top: -0px;
    height: 50px;
    width: 1px;
    background-color: rgb(51, 51, 51, 0.2);
  }
`;

const GroupedXAxis: React.FC<GroupedXAxisProps> = ({
  axis,
  numberOfColumns,
}) => (
  <>
    {axis && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          boxSizing: 'border-box',
        }}
      >
        {axis.map((el) => (
          <AxisGroup
            key={el.group}
            style={{
              width: `${(el.children.length / numberOfColumns) * 98}%`,
            }}
          >
            <div className="group-label">{el.group}</div>
            <div className="group-divider" />
          </AxisGroup>
        ))}
      </div>
    )}
  </>
);

export default GroupedXAxis;
