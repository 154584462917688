import { Alert } from 'antd';

import { AlertProps as AlertAntProps } from 'antd/lib/alert';
import styled from '@emotion/styled';

export type AlertProps = AlertAntProps;

export const StyledAlert = styled(Alert)`
  padding: 22px 32px;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  svg {
    margin-right: 6px !important;
    font-size: 16px !important;
    width: 20px;
    height: 20px;
  }
`;

export default Alert;
