import { AxiosResponse } from 'axios';

import { FileTypes } from '~/types/file';
import { catchError } from '~/utils';
import { api, apiV2, GenericResponseDataObject } from '~/api/axios';
import { routes } from '~/api/routes';
import { ModuleType } from '~/types/modules';

export interface ModelDuplicateRequestDataObject {
  project_id: string;
}

export interface ModuleResponseDataObject extends GenericResponseDataObject {
  data?: ModuleType[];
}
export interface ModuleConnectorTypesResponse {
  data?: string[];
}
export interface ModelFieldDataObjectResponse {
  [key: string]: {
    text: string | null;
    formula: string | number | null;
    value: string | number | null;
    isFormula: boolean | null;
  };
}

export const getModules = async (): Promise<
  AxiosResponse<ModuleResponseDataObject>
> => {
  try {
    return await apiV2.get<ModuleResponseDataObject>(routes.admin.modules());
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const downloadFile = async (
  moduleUuid: string,
  fileUuid: string,
  fileType?: FileTypes
): Promise<AxiosResponse> => {
  try {
    return await api.get<AxiosResponse>(
      routes.anza.module.downloadFile(moduleUuid, fileUuid, fileType)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};
