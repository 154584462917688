import React from 'react';
import styled from '@emotion/styled';
import { IconEye, IconEyeSlash } from '~/UI/Icons';
import ButtonWithAnalytics from '~/UI/ButtonWithAnalytics';
import { GTM_SOURCE_IDS } from '~/utils/gtm';
import { theme } from '~/utils';

const StyledButton = styled(ButtonWithAnalytics)`
  background: ${theme.colors.gray300};
  border: none;
  border-radius: 36px;
  cursor: pointer;
  min-width: 140px;
  height: 28px;
  padding: 4px 10px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.gray800};
  }
  svg {
    font-size: 20px;
  }
  &:hover {
    background: ${theme.colors.gray400};
  }
`;

const StyledCount = styled.div`
  background: ${theme.colors.gray350};
  border-radius: 10px;
  padding: 0 5px;
  font-size: 0.8em;
  line-height: 1.5;
`;

type HiddenModulesButtonProps = {
  active: boolean;
  toggleHiddenModules: () => void;
  hiddenModulesLength?: number;
};

export const HiddenModulesButton = ({
  active,
  toggleHiddenModules,
  hiddenModulesLength,
}: HiddenModulesButtonProps) => {
  return (
    <StyledButton
      onClick={toggleHiddenModules}
      gtmId={GTM_SOURCE_IDS.SOLAR_PROJECT.TOGGLE_HIDDEN_MODULES}
    >
      <>
        {!active ? (
          <>
            <IconEye />
            Show Hidden
          </>
        ) : (
          <>
            <IconEyeSlash />
            Hide Modules
          </>
        )}
        {hiddenModulesLength && (
          <StyledCount>{hiddenModulesLength}</StyledCount>
        )}
      </>
    </StyledButton>
  );
};
