import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { BoxTitle } from '~/UI/Typography';
import { ButtonAnt as Button, ButtonAnt, message, Table } from '~/UI';
import { Column } from '~/components/Admin/AdminTable/AdminTable';
import { BaseProject, SolarEngineResultType } from '~/store/project';
import { useLazyGetSolarEngineResultsDownloadUrlQuery } from '~/store/api/admin/anzaEngine';
import axios from 'axios';

interface HistoricEngineTableType {
  data: SolarEngineResultType[] | undefined;
  project?: BaseProject;
}

const HistoricEngineTableWrapper = styled.div`
  margin-top: 40px;
`;
const HistoricEngineTable: React.FC<HistoricEngineTableType> = ({
  project,
  data,
}) => {
  const [getSolarEngineResultsDownloadSignedUrl] =
    useLazyGetSolarEngineResultsDownloadUrlQuery();
  const handleDownloadEnginInputs = async (requestId: string) => {
    // Getting signed URl
    if (!project) return;
    const { data: urlData } = await getSolarEngineResultsDownloadSignedUrl({
      requestId,
    });
    if (!urlData) return;
    const { signedUrl } = urlData;
    if (!signedUrl) return;

    // Generating file to download
    const response = await axios.get(signedUrl, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `Engine result - ${requestId}.json`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const historicEngineRunsTableColumns: Column<SolarEngineResultType>[] = [
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '100px',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: '100px',
    },
    {
      title: 'Triggered By',
      dataIndex: 'triggered_by',
      key: 'triggered_by',
      width: '120px',
    },
    {
      title: 'Error',
      dataIndex: 'error',
      key: 'error',
      render: (errorObject: Record<string, string>) =>
        errorObject && (
          <ButtonAnt
            type="link"
            size="small"
            onClick={() => {
              navigator.clipboard
                .writeText(JSON.stringify(errorObject))
                .then(() => {
                  message.success('Engine error object copied to clipboard');
                });
            }}
          >
            Click to copy error
          </ButtonAnt>
        ),
      width: '100px',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) => dayjs(text).format('MM/DD/YYYY HH:mm:ss'),
      width: '120px',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: '120px',
      render: (text: string, record) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => handleDownloadEnginInputs(record.request_id)}
          >
            Download Engine Results
          </Button>
        );
      },
    },
  ];

  return (
    <HistoricEngineTableWrapper>
      <BoxTitle style={{ marginBottom: '20px' }}>
        Historic Engine Results
      </BoxTitle>
      <Table
        dataSource={data}
        columns={historicEngineRunsTableColumns}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: false,
        }}
      />
    </HistoricEngineTableWrapper>
  );
};

export default HistoricEngineTable;
