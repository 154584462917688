import React from 'react';

import { Layout } from '~/UI';
import Page from '~/router/Page';
import HeaderContainer from '~/components/HeaderContainer';
import { StyledBannerContainer } from '~/components/PageBanner';
import Dashboard from '~/components/DashboardView';

export const AdminDashboard: React.FC = () => {
  return (
    <Page title="Dashboard">
      <Layout header={<HeaderContainer />}>
        <StyledBannerContainer>
          <Dashboard />
        </StyledBannerContainer>
      </Layout>
    </Page>
  );
};

export default AdminDashboard;
