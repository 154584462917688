import styled from '@emotion/styled';
import { theme } from '../../utils';
import { colors } from '../../utils/theme';

export const Box = styled.div`
  background: white;
  border: 1px solid ${theme.colors.gray};
  border-radius: 12px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export const BoxHeader = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #eeeff0;
  display: flex;
  justify-content: space-between;
`;

export const BoxHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxContent = styled.div`
  padding: 32px 24px 40px 24px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 60px;
  img {
    margin-bottom: 1rem;
  }
  .ant-upload-drag-container {
    line-height: 2rem;
    h3 {
      font-weight: bold;
    }
  }
  h3 {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  div {
    flex-basis: 33%;
    flex-grow: 1;
  }
`;

export const NumberCircle = styled.div`
  border-radius: 50%;
  justify-self: flex-start;
  background-color: ${colors.tintBlue};
  color: ${colors.primary};
  width: 42px;
  height: 42px;
  letter-spacing: -0.05em;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-right: 1.5rem;
`;

export default Box;
