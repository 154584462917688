import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

import {
  Form,
  InputAnt as Input,
  Option,
  AddressSearch,
  FormItemLabel,
  FormInstance,
  InputNumber,
  Typography,
} from '~/UI';
import { AddressSearchResponseType } from '~/types/addressSearchTypes';
import {
  GenericLabel,
  ProjectLocationLabel,
} from '~/components/Projects/FormGroup';
import { decimalFormatter, formatAsPercentage, ProjectStatuses } from '~/utils';
import { SelectFormItem } from '~/UI/Select';
import PROJECT_INPUT_CONFIG from '~/constants/inputs/project/projectInputs';
import AutocompleteProjectBySalesforceOpportunity from '~/components/Inputs/AutocompleteProjectBySalesforceOpportunity';
import { useFlags } from '~/utils/launchdarkly';
import AdminProjectUserDropdown from './AdminProjectUserDropdown';

export interface User {
  id: number;
  email: string;
}

interface ProjectDataFormGroupProps {
  form: FormInstance;
  user: User | undefined;
  statuses: Array<{
    text: string;
    value: string;
  }>;
  projectTypes: Array<{
    text: string;
    value: string;
  }>;
  projectType: string;
  onProjectTypeChange?: (type: string) => void;
  onChange?: () => void;
}

const ProjectDataFormGroup: React.FC<ProjectDataFormGroupProps> = ({
  form,
  user,
  statuses,
  projectTypes,
  onProjectTypeChange,
  onChange,
}) => {
  const flags = useFlags();
  const [locationError, setLocationError] = useState<string | undefined>('');
  const onAssignUser = (id: number) => {
    form.setFieldsValue({ user_id: id });
  };

  const resetAddressFields = () => {
    form.setFieldsValue({
      latitude: null,
      longitude: null,
      state: null,
      google_place_id: null,
    });
    form.validateFields(['project_address']);
  };
  const handleSelectLocation = (location: AddressSearchResponseType) => {
    setLocationError('');
    if (location?.error?.message) {
      setLocationError(location?.error?.message);
      resetAddressFields();
      return;
    }
    form.setFieldsValue({
      project_address: location.address,
      latitude: location.lat,
      longitude: location.lng,
      state: location.state,
      google_place_id: location.placeId || '',
    });
    form.validateFields(['project_address']);
    if (onChange) {
      onChange();
    }
  };

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'auto',
          gap: '1rem',
        }}
      >
        <Form.Item
          name="name"
          label={<FormItemLabel>Project name</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.name.rules}
        >
          <Input
            required
            name="name"
            {...PROJECT_INPUT_CONFIG.name.inputProps}
          />
        </Form.Item>
        <SelectFormItem
          name="status"
          label={<FormItemLabel>Project Status</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.status.rules}
          selectProps={PROJECT_INPUT_CONFIG.status.selectProps}
        >
          {statuses?.map(({ value, text }) => (
            <Option value={value} key={`${Math.random()}`}>
              {text}
            </Option>
          ))}
        </SelectFormItem>
        <SelectFormItem
          name="project_type"
          label={<FormItemLabel>Project Type</FormItemLabel>}
          labelCol={{ span: 24 }}
          rules={PROJECT_INPUT_CONFIG.projectType.rules}
          hidden
          selectProps={{
            placeholder: 'Select a project type',
            onChange: onProjectTypeChange,
            disabled: true,
          }}
        >
          {projectTypes?.map(({ value, text }) => (
            <Option value={value} key={`${Math.random()}`}>
              {text}
            </Option>
          ))}
        </SelectFormItem>
        <AdminProjectUserDropdown
          label="Owner"
          name="user_id"
          initialValue={user?.email}
          selectedUser={user?.id}
          onAssignUser={onAssignUser}
        />
        <Form.Item
          name="sf_opportunity_id"
          label={<FormItemLabel>Salesforce Opportunity Id</FormItemLabel>}
          labelCol={{ span: 24 }}
        >
          <AutocompleteProjectBySalesforceOpportunity
            initialValue={form.getFieldValue('sf_opportunity_id')}
          />
        </Form.Item>
        <Form.Item
          name="project_address"
          className="no-padding no-border"
          label={<ProjectLocationLabel fontSize="inherit" />}
          labelCol={{ span: 24 }}
          validateTrigger="onBlur"
          rules={PROJECT_INPUT_CONFIG.projectAddress.applyRules(
            false,
            form,
            locationError
          )}
        >
          <AddressSearch
            onSelectLocation={handleSelectLocation}
            onValueChange={(value) => {
              if (value) {
                resetAddressFields();
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="latitude"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="longitude"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="state"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="google_place_id"
          className="no-padding no-border"
          labelCol={{ span: 24 }}
          hidden
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  );
};

export default ProjectDataFormGroup;
