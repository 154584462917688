import React from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { IntegerSettings, theme } from '~/utils';
import { Typography, Select, Option, Spinner } from '~/UI';
import ResponsiveBarChart, {
  quarterYearTickFormatter,
} from '~/UI/Metrics/ResponsiveBarChart';
import ResponsivePieChart from '~/UI/Metrics/ResponsivePieChart';
import BigValueLabel from '~/UI/Metrics/BigValueLabel';
import GeoMapUnitedStates, {
  RegionalData,
} from '~/UI/Metrics/GeoMapUnitedStates';
import {
  getXAxisGroupingsFromDeliveryDateData,
  DATA_SET,
  getEmptyDataSet,
} from '~/utils/dataProcessor';
import { CompanyType } from '~/types/users';

const { chartColors } = theme;
const DashboardContent = styled.div`
  display: flex;
  width: 100%;
`;

const DashboardHeading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 30px 20px;
  border-bottom: 1px solid ${theme.colors.lightGray};
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: fit-content;
`;

const StyledSelect = styled(Select)`
  // These are until we make App wide changes to how we render selects.
  .ant-select-selector {
    border: 1px solid #aaaeb3 !important;
    box-shadow: none !important;
  }
`;

const chartStyles: React.CSSProperties = {
  padding: '40px 30px 0',
};

const chartContainerStyles: React.CSSProperties = {
  flex: 1,
  height: '350px',
};

const spinnerStyles: React.CSSProperties = { margin: '50px auto' };

const chartContainerBorderStyles: React.CSSProperties = {
  ...chartContainerStyles,
  borderRight: theme.borders.lightGray,
};

const tooltipConfig = {
  leftValuePath: ['count'],
  rightValuePath: ['value'],
  leftLabel: 'Projects',
  rightLabel: 'MW',
};

export interface Metrics {
  count: number;
  id: string;
  label: string;
  value: number;
}

interface ModuleOption {
  value: string;
  text: string;
}

export interface ModuleMetricsProps {
  title: string;
  vendors?: CompanyType[];
  onSelectVendor: (value: string) => void;
  moduleId: string;
  moduleOptions: Array<ModuleOption>;
  activePipelineMw: number | undefined;
  activeProjectCount: string | number;
  byDeliveryDate: Metrics[];
  byRank: Metrics[];
  byProjectSize: Metrics[];
  byRegion: RegionalData;
  onModuleChange: (moduleId: string) => Promise<void>;
  isLoading: boolean;
  selectedVendorId?: string;
}

const VendorCompaniesList: React.FC<{
  vendors?: CompanyType[];
  onSelect: (value: string) => void;
}> = ({ vendors, onSelect }) => {
  const handleFilter = (input: string, option: { children: string }) => {
    return option!.children.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <StyledSelect
      onSelect={onSelect}
      defaultValue="all"
      style={{ width: '300px' }}
      optionFilterProp="children"
      filterOption={handleFilter as unknown as boolean}
      showSearch
    >
      <Option value="all">All Vendors</Option>
      {vendors &&
        vendors.length &&
        vendors.map((vendor) => (
          <Option key={vendor.uuid} value={vendor.uuid}>
            {vendor.name}
          </Option>
        ))}
    </StyledSelect>
  );
};

const ModuleMetrics: React.FC<ModuleMetricsProps> = ({
  title,
  vendors,
  moduleId,
  moduleOptions,
  activePipelineMw,
  activeProjectCount,
  byDeliveryDate,
  byRank,
  byProjectSize,
  byRegion,
  onModuleChange,
  onSelectVendor,
  isLoading,
  selectedVendorId,
}) => {
  const customizeRankData = (rankData: Metrics[]) =>
    !rankData.length
      ? []
      : rankData.map((rank) => ({
          ...rank,
          size: rank.value,
          value: rank.count,
        }));

  const data = [
    {
      title: 'Delivery Forecast - MW per Quarter',
      chartData: byDeliveryDate,
    },
    {
      title: 'Projects by NPV Ranking Table Position',
      chartData: customizeRankData(byRank),
      colors: R.reverse(chartColors.primaryGradient),
    },
    {
      title: 'Projects by Project Size in MW',
      chartData: byProjectSize,
    },
  ];

  const emptyDeliveryDateDataSet = getEmptyDataSet(DATA_SET.DELIVERY_DATE);

  return (
    <ChartContainer>
      <DashboardHeading>
        <Typography.VendorSectionHeader style={{ flexGrow: 3 }}>
          {vendors && vendors.length ? (
            <VendorCompaniesList vendors={vendors} onSelect={onSelectVendor} />
          ) : (
            title
          )}
        </Typography.VendorSectionHeader>
        <div>
          <StyledSelect
            style={{ width: '300px' }}
            placeholder="All Modules"
            defaultValue="All Modules"
            value={moduleId}
            onChange={onModuleChange}
            disabled={!moduleOptions.length}
          >
            {moduleOptions?.map(({ text, value }) => (
              <Option value={value} key={value}>
                {text}
              </Option>
            ))}
          </StyledSelect>
        </div>
      </DashboardHeading>

      {isLoading ? (
        <div
          style={{
            minHeight: '808px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner style={spinnerStyles} />
        </div>
      ) : (
        <>
          <DashboardContent
            style={{
              borderBottom: theme.borders.lightGray,
            }}
          >
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                minWidth: 430,
              }}
            >
              <BigValueLabel
                title="Active Pipeline in MW"
                value={
                  activePipelineMw === 0
                    ? ''
                    : IntegerSettings.formatter(activePipelineMw as number)
                }
                containerStyle={{
                  borderBottom: theme.borders.lightGray,
                  padding: '0px 32px',
                }}
                valueStyle={{ fontSize: 48 }}
              />
              <BigValueLabel
                title="Active Projects"
                value={activeProjectCount === 0 ? '' : activeProjectCount}
                containerStyle={{ padding: '0px 32px' }}
                valueStyle={{ fontSize: 48 }}
              />
            </div>
            <div style={{ flexGrow: 6, minWidth: 921 }}>
              <GeoMapUnitedStates regionalData={byRegion} />
            </div>
          </DashboardContent>

          {selectedVendorId && selectedVendorId !== 'all' ? (
            <DashboardContent
              style={{
                borderBottom: theme.borders.lightGray,
              }}
            >
              <div style={chartContainerBorderStyles} key={data[0].title}>
                <ResponsiveBarChart
                  title={data[0].title}
                  data={data[0].chartData}
                  keys={['value']}
                  style={chartStyles}
                  tooltipConfig={tooltipConfig}
                  axisBottomTickFormat={quarterYearTickFormatter}
                  customXAxis={getXAxisGroupingsFromDeliveryDateData(
                    R.isNil(data[0].chartData) || R.isEmpty(data[0].chartData)
                      ? emptyDeliveryDateDataSet
                      : data[0].chartData
                  )}
                  emptyDataSet={emptyDeliveryDateDataSet}
                />
              </div>
              <div style={chartContainerBorderStyles} key={data[1].title}>
                <ResponsivePieChart
                  title={data[1].title}
                  data={data[1].chartData}
                  colors={data[1].colors}
                  style={chartStyles}
                  tooltipConfig={{
                    leftValuePath: ['count'],
                    rightValuePath: ['size'],
                    leftLabel: 'Projects',
                    rightLabel: 'MW',
                  }}
                  legendConfig={{
                    leftValuePath: ['id'],
                    rightValuePath: ['count'],
                    leftLabel: 'Rank',
                    rightLabel: 'Projects',
                  }}
                  emptyDataSet={getEmptyDataSet(DATA_SET.RANK)}
                />
              </div>
              <div style={chartContainerStyles} key={data[2].title}>
                <ResponsiveBarChart
                  title={data[2].title}
                  data={data[2].chartData}
                  keys={['count']}
                  style={chartStyles}
                  tooltipConfig={tooltipConfig}
                  emptyDataSet={getEmptyDataSet(DATA_SET.PROJECT_SIZE)}
                />
              </div>
            </DashboardContent>
          ) : null}
        </>
      )}
    </ChartContainer>
  );
};

export default ModuleMetrics;
