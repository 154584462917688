import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';

import { REGION } from '~/utils/constants';
import { colors, theme } from '~/utils';
import {
  GoogleMapsProps,
  RegionDataItemType,
} from '~/UI/Metrics/GeoMapUnitedStates';

import UsMap from '~/UI/Images/USMap.svg';
import MapMarker from '~/assets/mapMarker-circle';
import { PopoverAnt as Popover } from '~/UI';
import { useResizeListener } from '~/hooks';

const MAP_SCREEN_SIZES = {
  max: 1630,
  min: 1300,
};

const ProjectsMapContainer = styled.div`
  max-width: 1214px;
  min-width: 921px;
  height: 456px;
  background: ${() => `url(${UsMap})`} no-repeat;
  background-size: cover;
  background-position-y: -90px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: ${MAP_SCREEN_SIZES.max}px) {
    background-position-y: 0;
  }

  .marker {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);

    &.west-marker {
      position: absolute;
      left: 25%;
      top: 43%;

      @media only screen and (max-width: ${MAP_SCREEN_SIZES.max}px) {
        left: 24%;
        top: 51%;
      }
    }

    &.midwest-marker {
      position: absolute;
      left: 55%;
      top: 40%;

      @media only screen and (max-width: ${MAP_SCREEN_SIZES.max}px) {
        left: 52%;
        top: 50%;
      }
    }

    &.south-marker {
      position: absolute;
      left: 66%;
      top: 73%;

      @media only screen and (max-width: ${MAP_SCREEN_SIZES.max}px) {
        left: 66%;
        top: 80%;
      }
    }

    &.northeast-marker {
      position: absolute;
      left: 78%;
      top: 35%;

      @media only screen and (max-width: ${MAP_SCREEN_SIZES.max}px) {
        left: 79%;
        top: 47%;
      }
    }

    .count {
      position: absolute;
      color: ${theme.colors.white};
      font-weight: 700;
      padding: 30px;
      cursor: pointer;
    }
  }
`;

const globalStyle = css`
  .ant-popover.region-data-popover:not(.ant-popconfirm) {
    &.ant-popover-placement-right .ant-popover-content {
      margin-left: -45px;
      margin-top: -115px;
    }

    &.ant-popover-placement-left .ant-popover-content {
      margin-right: -40px;
      margin-top: -110px;
    }

    .ant-popover-inner {
      border-radius: 4px;
      border: 1px solid #f2f2f2;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.28);
    }
    .ant-popover-inner-content {
      padding: 8px;
      border-radius: 4px;
    }
  }
`;

const StyledRegionData = styled.div`
  font-size: 14px;
  color: ${theme.colors.charcoal};

  strong {
    font-weight: 700;
  }

  .region-data {
    display: flex;
    margin-top: 8px;

    > div:last-child {
      padding-left: 8px;
      margin-left: 8px;
      border-left: solid 1px ${theme.colors.lightGray};
      margin-right: 20px;
    }
  }
`;

const StyledLabel = styled.div`
  position: absolute;
  border-radius: 8px;
  padding: 10px;
  margin: 30px;
  background-color: ${theme.colors.white};
  width: fit-content;
  color: ${theme.colors.charcoal};
  font-size: 16px;
`;

const ProjectsMap: React.FC<GoogleMapsProps> = ({ regionalData }) => {
  const windowSize = useResizeListener();
  const regions = {
    [`${REGION.WEST}`]: {
      className: 'west-marker',
      color: colors.primary,
    },
    [`${REGION.MIDWEST}`]: {
      className: 'midwest-marker',
      color: colors.success,
    },
    [`${REGION.SOUTH}`]: {
      className: 'south-marker',
      color: colors.secondary,
    },
    [`${REGION.NORTHEAST}`]: {
      className: 'northeast-marker',
      color: colors.pinkRed,
    },
  };

  const regionalDataForMap = regionalData.map((region: RegionDataItemType) => ({
    ...region,
    ...regions[region.id],
  }));

  const regionCounts = regionalDataForMap.map((r) => r.count!);
  const minRegionCount = Math.min(...regionCounts);
  const maxRegionCount = Math.max(...regionCounts);

  const [scaledRegionSize, setScaledRegionSize] = useState({
    min: 110,
    max: 200,
  });
  const [scaledFontSize, setScaledFontSize] = useState({ min: 20, max: 35 });

  useEffect(() => {
    const { width } = windowSize;
    if (width > MAP_SCREEN_SIZES.max) {
      setScaledRegionSize({ min: 100, max: 180 });
      setScaledFontSize({ min: 20, max: 35 });
    } else {
      setScaledRegionSize({ min: 85, max: 140 });
      setScaledFontSize({ min: 16, max: 24 });
    }
  }, [windowSize]);

  const getScaleForValue =
    (min: number, max: number, scaledMin: number, scaledMax: number) =>
    (num: number) => {
      if (min === max) {
        // if only 1 size, return med size for all
        return (scaledMax + scaledMin) / 2;
      }
      return ((scaledMax - scaledMin) * (num - min)) / (max - min) + scaledMin;
    };

  const getScaleValueForMarkerRadius = getScaleForValue(
    minRegionCount,
    maxRegionCount,
    scaledRegionSize.min,
    scaledRegionSize.max
  );

  const getScaleFont = getScaleForValue(
    minRegionCount,
    maxRegionCount,
    scaledFontSize.min,
    scaledFontSize.max
  );

  const roundNumberToSingleDecimal = (number: number) =>
    Math.round(number * 10) / 10;

  const regionData = (region: RegionDataItemType) => (
    <StyledRegionData>
      <div>
        <strong>{region.label}</strong>
      </div>
      <div className="region-data">
        <div style={{ width: 60 }}>
          <strong>{region.count}</strong> <br /> Projects
        </div>
        <div>
          <strong>{roundNumberToSingleDecimal(region.value)}</strong> <br /> MW
        </div>
      </div>
    </StyledRegionData>
  );

  return (
    <ProjectsMapContainer>
      <Global styles={globalStyle} />
      <StyledLabel>Active Pipeline by Region</StyledLabel>
      {regionalDataForMap.map((region) => (
        <div key={region.className} className={`marker ${region.className}`}>
          <MapMarker
            radius={getScaleValueForMarkerRadius(region.count)}
            color={region.color}
          />
          <Popover
            content={regionData(region)}
            title={null}
            placement="right"
            overlayClassName="region-data-popover"
          >
            <div
              className="count"
              style={{ fontSize: `${getScaleFont(region.count)}px` }}
            >
              {region.count}
            </div>
          </Popover>
        </div>
      ))}
    </ProjectsMapContainer>
  );
};

export default ProjectsMap;
