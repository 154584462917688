import React from 'react';
import styled from '@emotion/styled';
import { Slider as AntSlider, InputNumber } from 'antd';
import { theme } from '../../utils';
import { Label } from '../Typography';
import './customStyles.css';

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: column;
  height: 100%;
  .ant-form-item-explain {
    display: none;
  }
  .ant-form-item-children-icon {
    display: none;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  width: 100%;
`;

const Value = styled.span<SliderProps>`
  float: right;
  font-size: ${theme.fonts.sizes.sidetitle};
  color: ${theme.fonts.colors.charcoal};
  border-radius: 8px;
  padding: 0.2rem;
  border: 1px solid transparent;
  min-width: 172px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  :hover,
  :focus {
    cursor: pointer;
    border: 1px solid ${theme.colors.sterling};
  }
`;

const StyledInputNumber = styled(InputNumber)`
  font-size: ${theme.fonts.sizes.sidetitle};
  color: ${theme.fonts.colors.charcoal};
  border: none;
  box-shadow: none;
  flex-grow: 1;

  &.ant-input-number:focus,
  &.ant-input-number:hover,
  &.ant-input-number-focused {
    box-shadow: none;
  }

  .ant-input-number-input {
    padding: 0 0.4rem;
    width: 100%;
    text-align: right;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export interface SliderProps {
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  value?: number;
  multiplier?: number;
  onChange?: (value: number) => void;
  label?: string;
  suffix?: string;
  prefix?: string;
  textEditable?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  toFixedDecimal?: number;
}

const Slider: React.FC<SliderProps> = ({
  onChange,
  value = 0,
  defaultValue = 0,
  multiplier = 1,
  min = 0,
  max = 100,
  step = 1,
  label = '',
  suffix = '',
  prefix = '',
  disabled = false,
  style,
  toFixedDecimal = 2,
}) => {
  const inputNumberRef = React.useRef<HTMLInputElement>(null);
  const [textEditable, setTextEditable] = React.useState(false);

  const handleTextEditable = () => setTextEditable(!textEditable);

  const handleOnPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const numberValue = parseFloat((event.target as HTMLInputElement).value);
    if (
      typeof onChange !== 'undefined' &&
      !Number.isNaN(numberValue) &&
      numberValue >= min &&
      numberValue <= max
    ) {
      onChange(numberValue / multiplier);
    }
    setTextEditable(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputNumberRef.current !== event.composedPath()[0]) {
      setTextEditable(false);
    }
  };

  const handleSliderOnChange = (sliderValue: number) => {
    const numberValue = sliderValue / multiplier;
    if (typeof onChange !== 'undefined' && !Number.isNaN(numberValue))
      onChange(numberValue);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [inputNumberRef]);

  return (
    <Container style={style}>
      <Description>
        <Label style={{ float: 'left' }}>{label}</Label>
        {textEditable ? (
          <Value>
            {prefix}
            <StyledInputNumber
              ref={inputNumberRef}
              min={min}
              max={max}
              value={parseFloat((value * multiplier).toFixed(toFixedDecimal))}
              defaultValue={(defaultValue || value) * multiplier}
              onPressEnter={handleOnPressEnter}
              autoFocus
            />
            {suffix}
          </Value>
        ) : (
          <Value onClick={handleTextEditable}>
            {prefix}
            {(value * multiplier).toFixed(toFixedDecimal)} {suffix}
          </Value>
        )}
      </Description>
      <AntSlider
        disabled={disabled}
        style={{ width: '100%' }}
        onChange={handleSliderOnChange}
        value={parseFloat((value * multiplier).toFixed(2))}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
        tooltip={{ open: false }}
      />
    </Container>
  );
};

export default Slider;
