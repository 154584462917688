import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Col, Row, Typography } from 'antd';
import { getConfiguration } from '~/utils/configurations';

import { ButtonAnt as Button, Alert } from '~/UI';
import { IconCheckmarkCircle } from '~/UI/Icons';
import HelpButton from '~/components/CustomButtons/HelpButton';
import { loginBg, loginClock } from '~/assets';
import { catchError, Errors, MAILTO, theme } from '~/utils';
import { resendVerificationEmail } from '~/services/api/users';
import useAuth from '~/hooks/useAuth';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: url(${loginBg});
  background-size: cover;
  padding-top: ${theme.elements.header.height};
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  margin-top: 100px;
  max-width: 600px;
  padding: 50px;
`;

const StyledTitle = styled.h1`
  font-size: 92px;
  color: #ffcc43;
  font-weight: bold;
  letter-spacing: -4px;
  max-width: 590px;
  line-height: 92px;
  height: fit-content;
  padding: 2rem 0;
`;

const Square = styled.div`
  background: white;
  border-radius: 1.625em;
  border: 1px solid #eeeff0;
  padding: 2rem;
  height: 416px;
  max-height: 416px;
  width: 487px;

  display: flex;
  flex-direction: column;
`;

const SquareTitle = styled.h2`
  color: ${theme.colors.charcoal};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -2px;
`;

const Icon = styled.img`
  width: 66px;
  height: 66px;
`;

const ResendEmailBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  svg {
    color: ${theme.colors.primary};
    font-size: 2rem;
  }
`;

const SquareFooter = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface PendingVerificationProps {
  errors: string | undefined;
}

const CustomResendErrorDescription = () => (
  <p>
    Contact{' '}
    <a
      href={`mailto:${MAILTO}?subject=Anza - Help`}
      target="_blank"
      rel="noreferrer"
    >
      {MAILTO}
    </a>{' '}
    for help.
  </p>
);

const CustomResendErrorMessage = () => (
  <p style={{ fontSize: 14 }}>An error has occurred</p>
);

const PendingVerification: React.FC<PendingVerificationProps> = ({
  errors,
}) => {
  const { signOutUser, getAccessTokenSilently } = useAuth();

  const [emailResent, setEmailResent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onResendEmailButtonClicked = async () => {
    const token = await getAccessTokenSilently({
      audience: getConfiguration('REACT_APP_AUTH0_AUDIENCE'),
      scope: getConfiguration('REACT_APP_AUTH0_SCOPES'),
    });

    if (!token) return;

    try {
      setIsLoading(true);
      setEmailResent(false);
      const response = await resendVerificationEmail(token!);

      if (response.data.success) {
        setEmailResent(true);
      }
    } catch (error) {
      catchError({ error });
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogOut = async () => {
    try {
      await signOutUser();
    } catch (error) {
      catchError({ error });
    }
  };

  const getErrorMessage = (error: string): string => {
    switch (error) {
      case Errors.EMAIL_NOT_VERIFIED:
        return 'Please verify your email before signing in.';
      case Errors.NOT_ALLOWED:
        return 'Your account manager is reviewing your request.';
      default:
        return 'Something wrong happened. Please try again or contact an administrator.';
    }
  };

  const displayAdditionalErrorData = (error: string) => {
    switch (error) {
      case Errors.EMAIL_NOT_VERIFIED:
        return (
          <>
            <ResendEmailBox>
              <Button
                type="primary"
                onClick={onResendEmailButtonClicked}
                loading={isLoading}
              >
                Resend Email Verification
              </Button>
              {emailResent ? <IconCheckmarkCircle /> : null}
            </ResendEmailBox>
            {hasError && (
              <Alert
                message={<CustomResendErrorMessage />}
                description={<CustomResendErrorDescription />}
                type="error"
                showIcon
                style={{ padding: 8 }}
              />
            )}
          </>
        );
      case Errors.NOT_ALLOWED:
        return (
          <>
            <p>
              **Make sure you used your company email address since personal
              email won&apos;t work.
            </p>
            <p>
              Still need help? Contact{' '}
              <a
                href={`mailto:${MAILTO}?subject=Anza - Help`}
                target="_blank"
                rel="noreferrer"
              >
                {MAILTO}
              </a>
            </p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Row
        align="middle"
        justify="space-around"
        style={{ height: '100%', marginTop: '-100px' }}
      >
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <StyledTitle>
            Quickly find the best equipment for your project every time.
          </StyledTitle>
        </Col>
        <Col span={12}>
          <StyledContainer>
            <Square>
              <Icon src={loginClock} alt="clock-icon" />
              <div style={{ marginTop: '3rem' }}>
                <SquareTitle>Hang in there.</SquareTitle>
                <Typography.Text>
                  {getErrorMessage(errors || '')}
                </Typography.Text>
                <SquareFooter>
                  {displayAdditionalErrorData(errors || '')}
                  <Typography.Paragraph>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a onClick={handleLogOut}>Use a different account</a>
                  </Typography.Paragraph>
                </SquareFooter>
              </div>
            </Square>
          </StyledContainer>
        </Col>
      </Row>
      <HelpButton />
    </Container>
  );
};

export default PendingVerification;
