import React from 'react';
import type { PropsWithChildren } from 'react';

export interface IntroWrapperProps {
  title?: string;
  content?: string;
  step?: number;
  className?: string;
  tooltipClass?: string;
  highlightClass?: string;
  hideIntro?: boolean;
  style?: React.CSSProperties;
  position?:
    | 'top'
    | 'left'
    | 'bottom'
    | 'right'
    | 'bottom-left-aligned'
    | 'bottom-middle-aligned'
    | 'bottom-right-aligned'
    | 'auto';
}

const IntroWrapper = ({
  children,
  title,
  content,
  step,
  className,
  tooltipClass,
  highlightClass,
  hideIntro,
  position,
  style,
}: PropsWithChildren<IntroWrapperProps>): JSX.Element => {
  if (hideIntro) return <div className={className}>{children}</div>;
  return (
    <div
      data-title={title}
      data-intro={content}
      data-step={step}
      data-tooltip-class={tooltipClass}
      data-data-highlight-class={highlightClass}
      data-position={position}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
};

export default IntroWrapper;
