export const ProjectTypes: Record<string, string> = {
  STORAGE: 'storage',
  SOLAR: 'solar',
};

export const anzaProjectTypes: Array<{
  text: string;
  value: string;
}> = Object.keys(ProjectTypes).map((k) => ({
  text: ProjectTypes[k],
  value: ProjectTypes[k].toLowerCase(),
}));
